import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useAllFailedRefundPaytankOrdersQuery } from "../../../graphql"

import { Card } from "../../common"

export type AllFailedRefundOrdersProps = {
	sinceDate: Date
	untilDate: Date
}

const INR = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "INR",
})

export const AllFailedRefundOrders: React.FC<AllFailedRefundOrdersProps> = ({ sinceDate, untilDate }) => {
	const [{ data, fetching }] = useAllFailedRefundPaytankOrdersQuery({ variables: { sinceDate, untilDate } })

	return (
		<Card bgColor="red.100">
			<VStack align="flex-start" spacing="6">
				<VStack align="flex-start" spacing="0">
					{fetching ? (
						<Center py="2">
							<Spinner size="md" />
						</Center>
					) : data?.allFailedRefundPaytankOrders ? (
						<Text fontSize="3xl" fontWeight="semibold">
							{INR.format(data.allFailedRefundPaytankOrders.map((o) => o.amount).reduce((a, b) => a + b, 0))}
						</Text>
					) : (
						<Text fontSize="3xl" fontWeight="semibold">
							No data
						</Text>
					)}
					<Text fontSize="sm" color="text.400">
						<strong>{data?.allFailedRefundPaytankOrders.length || 0}</strong> Failed refunds
					</Text>
				</VStack>
			</VStack>
		</Card>
	)
}
