import { Checkbox, Heading, Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { format } from "date-fns"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { StaffFragment } from "../../graphql"
import { usePagination } from "../../hooks"
import { StaffTag } from "../staff"

export type StaffSelectorProps = {
	staffs: StaffFragment[]
	selectedStaffIds: string[]
	onChange: (staffIds: string[]) => void
	removeHeading?: boolean
}

export const StaffSelector: React.FC<StaffSelectorProps> = ({ staffs, selectedStaffIds, onChange, removeHeading }) => {
	const isAllChecked = useMemo(
		() => Boolean(selectedStaffIds.length) && staffs.map(({ _id }) => _id).every((id) => selectedStaffIds.includes(id)),
		[selectedStaffIds, staffs]
	)

	const isIndeterminate = useMemo(() => selectedStaffIds.some((id) => staffs.map(({ _id }) => _id).includes(id)) && !isAllChecked, [selectedStaffIds, staffs])

	const selectStaff = (staffId: string) => {
		const _selectStaffIds = new Set(selectedStaffIds)
		_selectStaffIds.add(staffId)

		onChange([..._selectStaffIds])
	}

	const deselectStaff = (staffId: string) => {
		const _selectStaffIds = [...selectedStaffIds]

		_selectStaffIds.splice(_selectStaffIds.indexOf(staffId), 1)

		onChange(_selectStaffIds)
	}

	const selectAllStaffs = () => {
		onChange(staffs.map(({ _id }) => _id) ?? [])
	}

	const isStaffSelected = useCallback((staffId: string) => selectedStaffIds.includes(staffId), [selectedStaffIds])

	useEffect(() => {
		onChange(selectedStaffIds)
	}, [selectedStaffIds])

	const { currentItems, loadMore, hasMore } = usePagination<StaffFragment>(staffs, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
				{removeHeading ? "" : "Select the staff you want to generate the report for"}
			</Heading>
			<VStack w="full" align="stretch" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th>
								<Checkbox
									colorScheme="blackAlpha"
									borderColor="black"
									isChecked={isAllChecked}
									isIndeterminate={isIndeterminate}
									onChange={(e) => (e.target.checked ? selectAllStaffs() : onChange([]))}
								/>
							</Th>
							<Th textTransform="capitalize">Teacher</Th>
							<Th textTransform="capitalize">Created</Th>
							<Th textTransform="capitalize">Updated</Th>
						</Tr>
					</Thead>
					<Tbody>
						{currentItems.map((staff) => (
							<Tr key={staff._id} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td>
									<Checkbox
										colorScheme="blackAlpha"
										borderColor="black"
										isChecked={isStaffSelected(staff._id)}
										onChange={(e) => (e.target.checked ? selectStaff(staff._id) : deselectStaff(staff._id))}
									/>
								</Td>
								<Td>
									<StaffTag staff={staff} />
								</Td>
								<Td>{format(new Date(staff.createdAt), "PP")}</Td>
								<Td>{format(new Date(staff.updatedAt), "PP")}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
				{hasMore && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
			</VStack>
		</VStack>
	)
}
