/* eslint-disable @typescript-eslint/ban-ts-comment */
import { cacheExchange as cE } from "@urql/exchange-graphcache"
import { betterQueryUpdate } from "../../utils"
import {
	ActivateStudentMutation,
	ActivateStudentMutationVariables,
	AddClassTeacherMutation,
	AddClassTeacherMutationVariables,
	AddReaderMutation,
	AddReaderToRestrictedZoneMutation,
	AddReaderToRestrictedZoneMutationVariables,
	AddUnauthorizedClassMutation,
	AddUnauthorizedClassMutationVariables,
	AddUnauthorizedDepartmentsMutation,
	AddUnauthorizedDepartmentsMutationVariables,
	AllBusRoutesDocument,
	AllBusRoutesQuery,
	AllBusRoutesQueryVariables,
	AllCafeteriaMealTypesDocument,
	AllCafeteriaMealTypesQuery,
	AllCafeteriaMealTypesQueryVariables,
	AllCafeteriaPaymentsReportsDocument,
	AllCafeteriaPaymentsReportsQuery,
	AllCafeteriaPaymentsReportsQueryVariables,
	AllCafeteriaRegistrationChargesDocument,
	AllCafeteriaRegistrationChargesQuery,
	AllCafeteriaRegistrationChargesQueryVariables,
	AllCafeteriaRegistrationsReportsDocument,
	AllCafeteriaRegistrationsReportsQuery,
	AllCafeteriaRegistrationsReportsQueryVariables,
	AllCircularsDocument,
	AllCircularsQuery,
	AllCircularsQueryVariables,
	AllClassesDocument,
	AllClassesQuery,
	AllClassesQueryVariables,
	AllDepartmentsDocument,
	AllDepartmentsQuery,
	AllDepartmentsQueryVariables,
	AllFeedPostsDocument,
	AllFeedPostsQuery,
	AllFeedPostsQueryVariables,
	AllGalleryDocument,
	AllGalleryQuery,
	AllGalleryQueryVariables,
	AllGeneratedCardsDocument,
	AllGeneratedCardsQuery,
	AllGeneratedCardsQueryVariables,
	AllHolidaysDocument,
	AllHolidaysQuery,
	AllHolidaysQueryVariables,
	AllPaytankMerchantsDocument,
	AllPaytankMerchantsQuery,
	AllPaytankMerchantsQueryVariables,
	AllPaytankMerchantTypesDocument,
	AllPaytankMerchantTypesQuery,
	AllPaytankMerchantTypesQueryVariables,
	AllPenaltyCategoriesForStaffDocument,
	AllPenaltyCategoriesForStaffQuery,
	AllPenaltyCategoriesForStaffQueryVariables,
	AllPenaltyCategoriesForStudentsDocument,
	AllPenaltyCategoriesForStudentsQuery,
	AllPenaltyCategoriesForStudentsQueryVariables,
	AllPointsRedeemItemsForStaffDocument,
	AllPointsRedeemItemsForStaffQuery,
	AllPointsRedeemItemsForStaffQueryVariables,
	AllPointsRedeemItemsForStudentsDocument,
	AllPointsRedeemItemsForStudentsQuery,
	AllPointsRedeemItemsForStudentsQueryVariables,
	AllResourcesDocument,
	AllResourcesQuery,
	AllResourcesQueryVariables,
	AllRestrictedZonesDocument,
	AllRestrictedZonesQuery,
	AllRestrictedZonesQueryVariables,
	AllRewardCategoriesForStaffDocument,
	AllRewardCategoriesForStaffQuery,
	AllRewardCategoriesForStaffQueryVariables,
	AllRewardCategoriesForStudentsDocument,
	AllRewardCategoriesForStudentsQuery,
	AllRewardCategoriesForStudentsQueryVariables,
	AllSchoolSessionsDocument,
	AllSchoolSessionsQuery,
	AllSchoolSessionsQueryVariables,
	AllSchoolTimingsDocument,
	AllSchoolTimingsQuery,
	AllSchoolTimingsQueryVariables,
	AllSubjectsDocument,
	AllSubjectsQuery,
	AllSubjectsQueryVariables,
	AllTopicsDocument,
	AllTopicsQuery,
	AllTopicsQueryVariables,
	AllTrendingSkillsDocument,
	AllTrendingSkillsQuery,
	AllTrendingSkillsQueryVariables,
	AssignNewTagMutation,
	AssignNewTagMutationVariables,
	AssignNewTagToStaffMutation,
	AssignNewTagToStaffMutationVariables,
	AuthorTypes,
	CafeteriaMealTypeFragment,
	CafeteriaMealTypeFragmentDoc,
	CafeteriaRegistrationChargeFragment,
	CafeteriaRegistrationChargeFragmentDoc,
	CafeteriaRegistrationsReportByIdDocument,
	CafeteriaRegistrationsReportByIdQuery,
	CafeteriaRegistrationsReportByIdQueryVariables,
	CafeteriaRegistrationsReportUpdateSubscription,
	CafeteriaRegistrationsReportUpdateSubscriptionVariables,
	ClassSectionFragment,
	ClassSectionFragmentDoc,
	CreateAndFinishManualCafeteriaRegistrationMutation,
	CreateBusRouteMutation,
	CreateCafeteriaMealTypeMutation,
	CreateCafeteriaRegistrationChargeMutation,
	CreateCircularMutation,
	CreateClassMutation,
	CreateClassSectionMutation,
	CreateClassSectionMutationVariables,
	CreateDepartmentMutation,
	CreateFeedPostMutation,
	CreateGalleryMutation,
	CreateHolidayMutation,
	CreatePaytankMerchantMutation,
	CreatePaytankMerchantTypeMutation,
	CreatePenaltyCategoryMutation,
	CreatePenaltyCategoryMutationVariables,
	CreatePenaltyItemMutation,
	CreatePenaltyItemMutationVariables,
	CreatePointsRedeemItemMutation,
	CreatePointsRedeemItemMutationVariables,
	CreateResourceMutation,
	CreateRestrictedZoneMutation,
	CreateRewardCategoryMutation,
	CreateRewardCategoryMutationVariables,
	CreateRewardItemMutation,
	CreateRewardItemMutationVariables,
	CreateSchoolSessionMutation,
	CreateSchoolTimingMutation,
	CreateSubjectMutation,
	CreateTopicMutation,
	CreateTrendingSkillMutation,
	DeactivateStudentMutation,
	DeactivateStudentMutationVariables,
	DeleteBusRouteMutationVariables,
	DeleteCafeteriaMealTypeMutation,
	DeleteCafeteriaMealTypeMutationVariables,
	DeleteCafeteriaRegistrationChargeMutation,
	DeleteCafeteriaRegistrationChargeMutationVariables,
	DeleteFeedPostMutation,
	DeleteFeedPostMutationVariables,
	DeleteGalleryMutationVariables,
	DeleteHolidayMutationVariables,
	DeleteManualCafeteriaRegistrationLogMutationVariables,
	DeleteResourceMutationVariables,
	DeleteSchoolSessionMutation,
	DeleteSchoolSessionMutationVariables,
	DeleteSchoolTimingMutationVariables,
	DeleteTrendingSkillMutationVariables,
	DislikeFeedPostMutation,
	DislikeFeedPostMutationVariables,
	EditCafeteriaMealTypeMutation,
	EditCafeteriaMealTypeMutationVariables,
	EditCafeteriaRegistrationChargeMutation,
	EditCafeteriaRegistrationChargeMutationVariables,
	EditClassOfStudentMutation,
	EditClassOfStudentMutationVariables,
	EditPaytankMerchantMutation,
	EditPaytankMerchantMutationVariables,
	EditPaytankMerchantTypeMutation,
	EditPaytankMerchantTypeMutationVariables,
	EditRestrictedZoneMutation,
	EditRestrictedZoneMutationVariables,
	EditSchoolSessionMutation,
	EditSchoolSessionMutationVariables,
	EditStaffMutation,
	EditStaffMutationVariables,
	EditStudentMutation,
	EditStudentMutationVariables,
	ExtendedClassFragment,
	ExtendedClassFragmentDoc,
	ExtendedFeedPostFragment,
	ExtendedFeedPostFragmentDoc,
	ExtendedGenerateCard,
	ExtendedGenerateCardFragment,
	ExtendedPaytankMerchantFragment,
	ExtendedPaytankMerchantFragmentDoc,
	ExtendedPenaltyCategoryFragment,
	ExtendedRestrictedZoneFragment,
	ExtendedRestrictedZoneFragmentDoc,
	ExtendedRewardCategoryFragment,
	ExtendedStaffAttendance,
	ExtendedStaffFragment,
	ExtendedStaffFragmentDoc,
	ExtendedStudentFragment,
	ExtendedStudentFragmentDoc,
	GenerateCafeteriaPaymentsReportMutation,
	GenerateCafeteriaRegistrationsReportMutation,
	GeneratedCardByIdDocument,
	GeneratedCardByIdQuery,
	GeneratedCardByIdQueryVariables,
	GeneratedCardUpdateSubscription,
	GeneratedCardUpdateSubscriptionVariables,
	GenerateStudentCardMutation,
	LikeFeedPostMutation,
	LikeFeedPostMutationVariables,
	LoginMutation,
	LogoutMutation,
	MarkStaffManualInMutation,
	MarkStaffManualInMutationVariables,
	MarkStaffManualOutMutation,
	MarkStaffManualOutMutationVariables,
	MeDocument,
	MeQuery,
	NotWhitelistedStudentsDocument,
	NotWhitelistedStudentsQuery,
	NotWhitelistedStudentsQueryVariables,
	PaytankMerchantTypeFragment,
	PaytankMerchantTypeFragmentDoc,
	PenaltyCategoryFragment,
	PenaltyCategoryFragmentDoc,
	Reader,
	ReadersHealthDocument,
	ReadersHealthQuery,
	RemoveClassMutationVariables,
	RemoveClassSectionMutationVariables,
	RemoveClassTeacherMutation,
	RemoveClassTeacherMutationVariables,
	RemoveDepartmentMutationVariables,
	RemoveReaderFromRestrictedZoneMutation,
	RemoveReaderFromRestrictedZoneMutationVariables,
	RemoveReaderMutation,
	RemoveReaderMutationVariables,
	RemoveSchoolTimingsOfClassSectionMutation,
	RemoveSchoolTimingsOfClassSectionMutationVariables,
	RemoveSubjectMutationVariables,
	RemoveTopicMutationVariables,
	RemoveUnauthorizedClassMutation,
	RemoveUnauthorizedClassMutationVariables,
	RemoveUnauthorizedDepartmentMutation,
	RemoveUnauthorizedDepartmentMutationVariables,
	RestrictedZoneByIdDocument,
	RestrictedZoneByIdQuery,
	RestrictedZoneByIdQueryVariables,
	RestrictedZoneFragment,
	RestrictedZoneFragmentDoc,
	RewardCategoryFragment,
	RewardCategoryFragmentDoc,
	SchoolSessionFragment,
	SchoolSessionFragmentDoc,
	SetStaffAvatarMutation,
	SetStaffAvatarMutationVariables,
	SetStudentAvatarMutation,
	SetStudentAvatarMutationVariables,
	StudentByIdDocument,
	StudentByIdQuery,
	StudentByIdQueryVariables,
	TodayAttendanceOfStaffDocument,
	TodayAttendanceOfStaffQuery,
	TodayAttendanceOfStaffQueryVariables,
	TodayAttendanceOfStudentDocument,
	TodayAttendanceOfStudentQuery,
	TodayAttendanceOfStudentQueryVariables,
	TodayAttendanceUpdateOfStaffSubscription,
	TodayAttendanceUpdateOfStaffSubscriptionVariables,
	TodayAttendanceUpdateOfStudentSubscription,
	TodayAttendanceUpdateOfStudentSubscriptionVariables,
	TodayBusAttendanceOfStaffDocument,
	TodayBusAttendanceOfStaffQuery,
	TodayBusAttendanceOfStaffQueryVariables,
	TodayBusAttendanceOfStudentDocument,
	TodayBusAttendanceOfStudentQuery,
	TodayBusAttendanceOfStudentQueryVariables,
	TodayBusAttendanceUpdateOfStaffSubscription,
	TodayBusAttendanceUpdateOfStaffSubscriptionVariables,
	TodayBusAttendanceUpdateOfStudentSubscription,
	TodayBusAttendanceUpdateOfStudentSubscriptionVariables,
	ToggleNotificationActivityStatusMutation,
	ToggleNotificationActivityStatusMutationVariables,
	TopicsWithoutPaginationDocument,
	TopicsWithoutPaginationQuery,
	UnauthorizedClassesDocument,
	UnauthorizedClassesQuery,
	UnauthorizedClassesQueryVariables,
	UnauthorizedDepartmentsDocument,
	UnauthorizedDepartmentsQuery,
	UnauthorizedDepartmentsQueryVariables,
	UpdateSchoolTimingOfStudentMutation,
	UpdateSchoolTimingOfStudentMutationVariables,
	UserTargets,
	WhitelistStudentsMutation,
} from "../generated"
import schema from "../generated/schema.json"
import { basicPagination } from "../pagination"

export const cacheExchange = cE({
	schema: schema as any,
	keys: {
		ExtendedUnauthorizedClass: (o) => o.classId as string,
		PaginatedCafeteriaMealTypesResponse: () => null,
		PaginatedSchoolSessionsResponse: () => null,
		PaginatedSchoolTimingsResponse: () => null,
		PaginatedStudentsResponse: () => null,
		AttendanceDataResponse: () => null,
		AttendanceStatsResponse: () => null,
		StatisticsResponse: () => null,
		ClassSection: (o) => o._id as string,
		EmbeddedPicture: (o) => o._id as string,
		EmbeddedAudio: (o) => o._id as string,
		Parent: (o) => o._id as string,
		AttendanceTiming: (o) => o._id as string,
		CafeteriaMessages: (o) => o._id as string,
		AmountBreakup: (o) => o._id as string,
		Location: (o) => o._id as string,
		CafeteriaRegistrationSessionQuote: (o) => o._id as string,
		SchoolSessionWithStatusResponse: (o) => o._id as string,
		PaginatedStudentAttendancesResponse: (o) => o._id as string,
		Student: (o) => o._id as string,
	},
	resolvers: {
		Query: {
			allFeedPosts: basicPagination("feedPosts", "PaginatedFeedPostsResponse"),
			allRewardCategoriesForStaff: basicPagination("rewardCategories", "PaginatedRewardCategoriesResponse"),
			allRewardCategoriesForStudents: basicPagination("rewardCategories", "PaginatedRewardCategoriesResponse"),
			allPenaltyCategoriesForStaff: basicPagination("penaltyCategories", "PaginatedPenaltyCategoriesResponse"),
			allPenaltyCategoriesForStudents: basicPagination("penaltyCategories", "PaginatedPenaltyCategoriesResponse"),
			allPointsRedeemItemsForStaff: basicPagination("pointsRedeemItems", "PaginatedPointsRedeemItemsResponse"),
			allPointsRedeemItemsForStudents: basicPagination("pointsRedeemItems", "PaginatedPointsRedeemItemsResponse"),
			allGeneratedCards: basicPagination("generatedCards", "PaginatedGeneratedCardResponse"),
			allSchoolTimings: basicPagination("schoolTimings", "PaginatedSchoolTimingsResponse"),
		},
	},
	updates: {
		Subscription: {
			todayAttendanceUpdateOfStudent: (_result, variables: TodayAttendanceUpdateOfStudentSubscriptionVariables, cache) => {
				betterQueryUpdate<TodayAttendanceUpdateOfStudentSubscription, TodayAttendanceOfStudentQuery>(
					cache,
					{
						query: TodayAttendanceOfStudentDocument,
						variables: {
							studentId: variables.studentId,
						} as TodayAttendanceOfStudentQueryVariables,
					},
					_result,
					(result, query) => {
						if (result.todayAttendanceUpdateOfStudent) {
							return {
								...query,
								todayAttendanceOfStudent: {
									...query?.todayAttendanceOfStudent,
									...result.todayAttendanceUpdateOfStudent,
								},
							}
						}

						return query
					}
				)
			},
			todayAttendanceUpdateOfStaff: (_result, variables: TodayAttendanceUpdateOfStaffSubscriptionVariables, cache) => {
				betterQueryUpdate<TodayAttendanceUpdateOfStaffSubscription, TodayAttendanceOfStaffQuery>(
					cache,
					{
						query: TodayAttendanceOfStaffDocument,
						variables: {
							staffId: variables.staffId,
						} as TodayAttendanceOfStaffQueryVariables,
					},
					_result,
					(result, query) => {
						if (result.todayAttendanceUpdateOfStaff) {
							return {
								...query,
								todayAttendanceOfStaff: {
									...query?.todayAttendanceOfStaff,
									...result.todayAttendanceUpdateOfStaff,
								},
							}
						}

						return query
					}
				)
			},
			todayBusAttendanceUpdateOfStudent: (_result, variables: TodayBusAttendanceUpdateOfStudentSubscriptionVariables, cache) => {
				betterQueryUpdate<TodayBusAttendanceUpdateOfStudentSubscription, TodayBusAttendanceOfStudentQuery>(
					cache,
					{
						query: TodayBusAttendanceOfStudentDocument,
						variables: {
							studentId: variables.studentId,
						} as TodayBusAttendanceOfStudentQueryVariables,
					},
					_result,
					(result, query) => {
						if (result.todayBusAttendanceUpdateOfStudent) {
							return {
								...query,
								todayBusAttendanceOfStudent: {
									...query?.todayBusAttendanceOfStudent,
									...result.todayBusAttendanceUpdateOfStudent,
								},
							}
						}

						return query
					}
				)
			},
			todayBusAttendanceUpdateOfStaff: (_result, variables: TodayBusAttendanceUpdateOfStaffSubscriptionVariables, cache) => {
				betterQueryUpdate<TodayBusAttendanceUpdateOfStaffSubscription, TodayBusAttendanceOfStaffQuery>(
					cache,
					{
						query: TodayBusAttendanceOfStaffDocument,
						variables: {
							staffId: variables.staffId,
						} as TodayBusAttendanceOfStaffQueryVariables,
					},
					_result,
					(result, query) => {
						if (result.todayBusAttendanceUpdateOfStaff) {
							return {
								...query,
								todayBusAttendanceOfStaff: {
									...query?.todayBusAttendanceOfStaff,
									...result.todayBusAttendanceUpdateOfStaff,
								},
							}
						}

						return query
					}
				)
			},
			// restrictedEntry: (_result, _, cache) => {
			// 	betterQueryUpdate<RestrictedEntrySubscription, ActiveRestrictedEntriesQuery>(
			// 		cache,
			// 		{
			// 			query: ActiveRestrictedEntriesDocument,
			// 		},
			// 		_result,
			// 		(result, query) => {
			// 			const activeEntries = (query.activeRestrictedEntries || []) as ExtendedRestrictedEntryRecord[]

			// 			const index = activeEntries?.findIndex((activeZoneRecord) => {
			// 				if (activeZoneRecord.author.authorType === AuthorTypes.Staff) {
			// 					return (
			// 						activeZoneRecord.restrictedZoneId === result.restrictedEntry.restrictedZoneId &&
			// 						activeZoneRecord.author.staffId === result.restrictedEntry.author.staffId
			// 					)
			// 				}

			// 				if (activeZoneRecord.author.authorType === AuthorTypes.Student) {
			// 					return (
			// 						activeZoneRecord.restrictedZoneId === result.restrictedEntry.restrictedZoneId &&
			// 						activeZoneRecord.author.studentId === result.restrictedEntry.author.studentId
			// 					)
			// 				}

			// 				return false
			// 			})

			// 			if (index === -1) {
			// 				activeEntries.unshift(result.restrictedEntry)
			// 			} else if (result.restrictedEntry.isFinal) {
			// 				activeEntries.splice(index, 1)
			// 			} else {
			// 				activeEntries[index] = result.restrictedEntry
			// 			}

			// 			return {
			// 				...query,
			// 				activeRestrictedEntries: activeEntries,
			// 			}
			// 		}
			// 	)
			// },

			generatedCardUpdate: (_result: GeneratedCardUpdateSubscription, variables: GeneratedCardUpdateSubscriptionVariables, cache) => {
				betterQueryUpdate<GeneratedCardUpdateSubscription, GeneratedCardByIdQuery>(
					cache,
					{
						query: GeneratedCardByIdDocument,
						variables: {
							generatedCardId: variables.generatedCardId,
						} as GeneratedCardByIdQueryVariables,
					},
					_result,
					(result, query) => {
						if (result) {
							return {
								...query,
								reportById: {
									...query?.generatedCardById,
									...{ ...(result.generatedCardUpdate as ExtendedGenerateCardFragment), __typename: "ExtendedGenerateCard" },
								},
							}
						}

						return query
					}
				)
			},
			cafeteriaRegistrationsReportUpdate: (_result, variables: CafeteriaRegistrationsReportUpdateSubscriptionVariables, cache) => {
				betterQueryUpdate<CafeteriaRegistrationsReportUpdateSubscription, CafeteriaRegistrationsReportByIdQuery>(
					cache,
					{
						query: CafeteriaRegistrationsReportByIdDocument,
						variables: {
							reportId: variables.reportId,
						} as CafeteriaRegistrationsReportByIdQueryVariables,
					},
					_result,
					(result, query) => {
						if (result.cafeteriaRegistrationsReportUpdate && query) {
							return {
								...query,
								cafeteriaRegistrationsReportById: {
									...query.cafeteriaRegistrationsReportById,
									...result.cafeteriaRegistrationsReportUpdate,
								},
							}
						}

						return query
					}
				)
			},
		},
		Mutation: {
			login: (_result, _, cache) => {
				betterQueryUpdate<LoginMutation, MeQuery>(cache, { query: MeDocument }, _result, (result, query) => {
					if (result.login.school) {
						return {
							me: result.login.school,
						}
					}

					return query
				})
			},
			logout: (result, _, cache) => {
				betterQueryUpdate<LogoutMutation, MeQuery>(cache, { query: MeDocument }, result, () => ({ me: null }))
			},
			addReader: (result, _, cache) => {
				betterQueryUpdate<AddReaderMutation, ReadersHealthQuery>(cache, { query: ReadersHealthDocument }, result, (result, query) => {
					if (result.addReader) {
						return {
							readersHealth: [...(query?.readersHealth || []), result.addReader],
						}
					}

					return query
				})
			},
			removeReader: (result, variables: RemoveReaderMutationVariables, cache) => {
				betterQueryUpdate<RemoveReaderMutation, ReadersHealthQuery>(cache, { query: ReadersHealthDocument }, result, (result, query) => {
					if (result.removeReader) {
						const readers: Reader[] = [...(query?.readersHealth ?? [])]

						readers.splice(
							readers.findIndex((r) => r.readerDecimalId === variables.readerDecimalId),
							1
						)

						return {
							readersHealth: readers,
						}
					}

					return query
				})
			},
			createClass: (result, _, cache) => {
				betterQueryUpdate<CreateClassMutation, AllClassesQuery>(
					cache,
					{ query: AllClassesDocument, variables: { pagination: { limit: 10, page: 1 } } as AllClassesQueryVariables },
					result,
					(result, query) => {
						if (result?.createClass?.class) {
							return {
								...query,
								allClasses: { ...query?.allClasses, classes: [result.createClass.class, ...(query?.allClasses?.classes ?? [])] },
							}
						}

						return query
					}
				)
			},
			removeClass: (_, { classId }: RemoveClassMutationVariables, cache) => {
				return cache.invalidate({ __typename: "ExtendedClass", _id: classId })
			},
			generateStudentCard: (result, _, cache) => {
				betterQueryUpdate<GenerateStudentCardMutation, AllGeneratedCardsQuery>(
					cache,
					{ query: AllGeneratedCardsDocument, variables: { pagination: { limit: 10, page: 1 } } as AllGeneratedCardsQueryVariables },
					result,
					(result, query) => {
						if (result?.generateStudentCard?.generateCard) {
							return {
								...query,
								allGeneratedCards: {
									...query?.allGeneratedCards,
									generatedCards: [
										result.generateStudentCard.generateCard as ExtendedGenerateCard,
										...(query?.allGeneratedCards?.generatedCards ?? []),
									],
								},
							}
						}

						return query
					}
				)
			},
			createTopic: (result, _, cache) => {
				betterQueryUpdate<CreateTopicMutation, AllTopicsQuery>(
					cache,
					{ query: AllTopicsDocument, variables: { pagination: { limit: 10, page: 1 } } as AllTopicsQueryVariables },
					result,
					(result, query) => {
						if (result.createTopic.topic) {
							return {
								allTopics: { ...query?.allTopics, topics: [result.createTopic.topic, ...(query?.allTopics.topics ?? [])] },
							}
						}

						return query
					}
				)
				betterQueryUpdate<CreateTopicMutation, TopicsWithoutPaginationQuery>(
					cache,
					{ query: TopicsWithoutPaginationDocument },
					result,
					(result, query) => {
						if (result.createTopic.topic) {
							return {
								topicsWithoutPagination: {
									...query?.topicsWithoutPagination,
									topics: [result.createTopic.topic, ...(query?.topicsWithoutPagination ?? [])],
								},
							}
						}

						return query
					}
				)
			},
			removeTopic: (_, { topicId }: RemoveTopicMutationVariables, cache) => {
				return cache.invalidate({ __typename: "Topic", _id: topicId })
			},
			createBusRoute: (result, _, cache) => {
				betterQueryUpdate<CreateBusRouteMutation, AllBusRoutesQuery>(
					cache,
					{ query: AllBusRoutesDocument, variables: { pagination: { limit: 10, page: 1 } } as AllBusRoutesQueryVariables },
					result,
					(result, query) => {
						if (result.createBusRoute.busRoute) {
							return {
								allBusRoutes: { ...query?.allBusRoutes, busRoutes: [result.createBusRoute.busRoute, ...(query?.allBusRoutes.busRoutes ?? [])] },
							}
						}

						return query
					}
				)
			},
			deleteBusRoute: (_, { busRouteId }: DeleteBusRouteMutationVariables, cache) => {
				return cache.invalidate({ __typename: "BusRoute", _id: busRouteId })
			},
			createHoliday: (result, _, cache) => {
				betterQueryUpdate<CreateHolidayMutation, AllHolidaysQuery>(
					cache,
					{ query: AllHolidaysDocument, variables: { pagination: { limit: 10, page: 1 } } as AllHolidaysQueryVariables },
					result,
					(result, query) => {
						if (result.createHoliday.holiday) {
							return {
								allHolidays: { ...query?.allHolidays, holidays: [result.createHoliday.holiday, ...(query?.allHolidays.holidays ?? [])] },
							}
						}

						return query
					}
				)
			},
			deleteHoliday: (_, { holidayId }: DeleteHolidayMutationVariables, cache) => {
				return cache.invalidate({ __typename: "Holiday", _id: holidayId })
			},
			createResource: (result, _, cache) => {
				betterQueryUpdate<CreateResourceMutation, AllResourcesQuery>(
					cache,
					{ query: AllResourcesDocument, variables: { pagination: { limit: 10, page: 1 } } as AllResourcesQueryVariables },
					result,
					(result, query) => {
						if (result.createResource.resource) {
							return {
								allResources: { ...query?.allResources, resources: [result.createResource.resource, ...(query?.allResources.resources ?? [])] },
							}
						}

						return query
					}
				)
			},
			deleteResource: (_, { resourceId }: DeleteResourceMutationVariables, cache) => {
				return cache.invalidate({ __typename: "ExtendedResource", _id: resourceId })
			},
			createTrendingSkill: (result, _, cache) => {
				betterQueryUpdate<CreateTrendingSkillMutation, AllTrendingSkillsQuery>(
					cache,
					{ query: AllTrendingSkillsDocument, variables: { pagination: { limit: 10, page: 1 } } as AllTrendingSkillsQueryVariables },
					result,
					(result, query) => {
						if (result.createTrendingSkill.trendingSkill) {
							return {
								allTrendingSkills: {
									...query?.allTrendingSkills,
									trendingSkills: [result.createTrendingSkill.trendingSkill, ...(query?.allTrendingSkills.trendingSkills ?? [])],
								},
							}
						}

						return query
					}
				)
			},
			deleteTrendingSkill: (_, { trendingSkillId }: DeleteTrendingSkillMutationVariables, cache) => {
				return cache.invalidate({ __typename: "ExtendedTrendingSkill", _id: trendingSkillId })
			},
			createGallery: (result, _, cache) => {
				betterQueryUpdate<CreateGalleryMutation, AllGalleryQuery>(
					cache,
					{ query: AllGalleryDocument, variables: { pagination: { limit: 10, page: 1 } } as AllGalleryQueryVariables },
					result,
					(result, query) => {
						if (result.createGallery.gallery) {
							return {
								allGallery: {
									...query?.allGallery,
									gallery: [result.createGallery.gallery, ...(query?.allGallery.gallery ?? [])],
								},
							}
						}

						return query
					}
				)
			},
			deleteGallery: (_, { galleryId }: DeleteGalleryMutationVariables, cache) => {
				return cache.invalidate({ __typename: "Gallery", _id: galleryId })
			},
			whitelistStudents: (result, _, cache) => {
				betterQueryUpdate<WhitelistStudentsMutation, NotWhitelistedStudentsQuery>(
					cache,
					{ query: NotWhitelistedStudentsDocument, variables: { pagination: { limit: 10, page: 1 } } as NotWhitelistedStudentsQueryVariables },
					result,
					(result, query) => {
						if (result.whitelistStudents) {
							return {
								notWhitelistedStudents: {
									...query?.notWhitelistedStudents,
									student: [result.whitelistStudents, ...(query?.notWhitelistedStudents ?? [])],
								},
							}
						}

						return query
					}
				)
			},
			createClassSection: (result: CreateClassSectionMutation, variables: CreateClassSectionMutationVariables, cache) => {
				if (!result.createClassSection.classSection) return

				const key = cache.keyOfEntity({ __typename: "ExtendedClass", _id: variables.input.classId })

				if (!key) return

				const _class = cache.readFragment<ExtendedClassFragment>(ExtendedClassFragmentDoc, key) as ExtendedClassFragment

				if (!_class) return

				return cache.writeFragment<ExtendedClassFragment>(ExtendedClassFragmentDoc, {
					..._class,
					sections: [...(_class.sections || []), result.createClassSection.classSection],
				})
			},
			removeClassSection: (_, { classSectionId }: RemoveClassSectionMutationVariables, cache) => {
				return cache.invalidate({ __typename: "ClassSection", _id: classSectionId })
			},
			assignNewTag: (result: AssignNewTagMutation, variables: AssignNewTagMutationVariables, cache) => {
				if (result.assignNewTag) {
					const key = cache.keyOfEntity({ __typename: "ExtendedStudent", _id: variables.studentId })

					if (key) {
						const student = cache.readFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, key) as ExtendedStudentFragment

						if (student) {
							return cache.writeFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, {
								...student,
								tag: result.assignNewTag,
								tagId: result.assignNewTag._id,
							})
						}
					}
				}
			},
			assignNewTagToStaff: (result: AssignNewTagToStaffMutation, variables: AssignNewTagToStaffMutationVariables, cache) => {
				if (result.assignNewTagToStaff) {
					const key = cache.keyOfEntity({ __typename: "ExtendedStaff", _id: variables.staffId })

					if (key) {
						const staff = cache.readFragment<ExtendedStaffFragment>(ExtendedStaffFragmentDoc, key) as ExtendedStaffFragment

						if (staff) {
							return cache.writeFragment<ExtendedStaffFragment>(ExtendedStaffFragmentDoc, {
								...staff,
								tag: result.assignNewTagToStaff,
								tagId: result.assignNewTagToStaff._id,
							})
						}
					}
				}
			},
			createSubject: (result, _, cache) => {
				betterQueryUpdate<CreateSubjectMutation, AllSubjectsQuery>(
					cache,
					{ query: AllSubjectsDocument, variables: { pagination: { limit: 10, page: 1 } } as AllSubjectsQueryVariables },
					result,
					(result, query) => {
						if (result.createSubject.subject) {
							return {
								allSubjects: { ...query?.allSubjects, subjects: [result.createSubject.subject, ...(query?.allSubjects.subjects ?? [])] },
							}
						}

						return query
					}
				)
			},
			removeSubject: (_, { subjectId }: RemoveSubjectMutationVariables, cache) => {
				return cache.invalidate({ __typename: "Subject", _id: subjectId })
			},
			addClassTeacher: (result: AddClassTeacherMutation, variables: AddClassTeacherMutationVariables, cache) => {
				if (!result.addClassTeacher) return

				const key = cache.keyOfEntity({ __typename: "ClassSection", _id: variables.classSectionId })

				if (!key) return

				const classSection = cache.readFragment<ClassSectionFragment>(ClassSectionFragmentDoc, key)

				if (!classSection) return

				return cache.writeFragment<ClassSectionFragment>(ClassSectionFragmentDoc, { ...classSection, ...result.addClassTeacher })
			},
			removeClassTeacher: (result: RemoveClassTeacherMutation, variables: RemoveClassTeacherMutationVariables, cache) => {
				if (!result.removeClassTeacher) return

				const key = cache.keyOfEntity({ __typename: "ClassSection", _id: variables.classSectionId })

				if (!key) return

				const classSection = cache.readFragment<ClassSectionFragment>(ClassSectionFragmentDoc, key) as ClassSectionFragment

				if (!classSection) return

				return cache.writeFragment<ClassSectionFragment>(ClassSectionFragmentDoc, { ...classSection, ...result.removeClassTeacher })
			},
			setStudentAvatar: (result: SetStudentAvatarMutation, variables: SetStudentAvatarMutationVariables, cache) => {
				if (!result.setStudentAvatar) return

				const key = cache.keyOfEntity({ __typename: "ExtendedStudent", _id: variables.studentId })

				if (!key) return

				const student = cache.readFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, key) as ExtendedStudentFragment

				if (!student) return

				return cache.writeFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, {
					...student,
					picture: { ...result.setStudentAvatar },
				})
			},
			setStaffAvatar: (result: SetStaffAvatarMutation, variables: SetStaffAvatarMutationVariables, cache) => {
				if (!result.setStaffAvatar) return

				const key = cache.keyOfEntity({ __typename: "ExtendedStaff", _id: variables.staffId })

				if (!key) return

				const staff = cache.readFragment<ExtendedStaffFragment>(ExtendedStaffFragmentDoc, key)

				if (!staff) return

				return cache.writeFragment<ExtendedStaffFragment>(ExtendedStaffFragmentDoc, {
					...staff,
					picture: { ...result.setStaffAvatar },
				})
			},
			editStudent: (result: EditStudentMutation, variables: EditStudentMutationVariables, cache) => {
				if (!result.editStudent.student) return

				const key = cache.keyOfEntity({ __typename: "ExtendedStudent", _id: variables.studentId })

				if (!key) return

				const student = cache.readFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, key)

				if (!student) return

				return cache.writeFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, {
					...student,
					...result.editStudent.student,
				})
			},
			editStaff: (result: EditStaffMutation, variables: EditStaffMutationVariables, cache) => {
				if (!result.editStaff.staff) return

				const key = cache.keyOfEntity({ __typename: "ExtendedStaff", _id: variables.staffId })

				if (!key) return

				const staff = cache.readFragment<ExtendedStaffFragment>(ExtendedStaffFragmentDoc, key)

				if (!staff) return

				return cache.writeFragment<ExtendedStaffFragment>(ExtendedStaffFragmentDoc, {
					...staff,
					...result.editStaff.staff,
				})
			},
			editClassOfStudent: (result: EditClassOfStudentMutation, variables: EditClassOfStudentMutationVariables, cache) => {
				if (!result.editClassOfStudent.student) return

				const key = cache.keyOfEntity({ __typename: "ExtendedStudent", _id: variables.studentId })

				if (!key) return

				const student = cache.readFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, key)

				if (!student) return

				return cache.writeFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, {
					...student,
					...result.editClassOfStudent.student,
				})
			},
			createFeedPost: (_result, _, cache) => {
				betterQueryUpdate<CreateFeedPostMutation, AllFeedPostsQuery>(
					cache,
					{
						query: AllFeedPostsDocument,
						variables: {
							pagination: { limit: 20, page: 1 },
						} as AllFeedPostsQueryVariables,
					},
					_result,
					(result, query) => {
						if (result.createFeedPost.feedPost) {
							return {
								allFeedPosts: {
									...query?.allFeedPosts,
									feedPosts: [result.createFeedPost?.feedPost, ...(query?.allFeedPosts?.feedPosts ?? [])],
								},
							}
						}

						return query
					}
				)
			},
			likeFeedPost: (_result: LikeFeedPostMutation, variables: LikeFeedPostMutationVariables, cache) => {
				const { feedPostId } = variables

				const key = cache.keyOfEntity({
					__typename: "ExtendedFeedPost",
					_id: feedPostId,
				})

				if (key) {
					const feedPost = cache.readFragment<ExtendedFeedPostFragment>(ExtendedFeedPostFragmentDoc, key)

					if (feedPost) {
						cache.writeFragment(ExtendedFeedPostFragmentDoc, {
							__typename: "ExtendedFeedPost",
							...feedPost,
							..._result.likeFeedPost,
						})
					}
				}
			},
			dislikeFeedPost: (_result: DislikeFeedPostMutation, variables: DislikeFeedPostMutationVariables, cache) => {
				const { feedPostId } = variables

				const key = cache.keyOfEntity({
					__typename: "ExtendedFeedPost",
					_id: feedPostId,
				})

				if (key) {
					const feedPost = cache.readFragment<ExtendedFeedPostFragment>(ExtendedFeedPostFragmentDoc, key)

					if (feedPost) {
						cache.writeFragment(ExtendedFeedPostFragmentDoc, {
							...feedPost,
							..._result.dislikeFeedPost,
						})
					}
				}
			},
			deleteFeedPost: (_result: DeleteFeedPostMutation, variables: DeleteFeedPostMutationVariables, cache) => {
				const { feedPostId } = variables

				const key = cache.keyOfEntity({
					__typename: "ExtendedFeedPost",
					_id: feedPostId,
				})

				if (key) {
					cache.invalidate(key)
				}
			},
			createCircular: (result, _, cache) => {
				betterQueryUpdate<CreateCircularMutation, AllCircularsQuery>(
					cache,
					{ query: AllCircularsDocument, variables: { pagination: { limit: 10, page: 1 } } as AllCircularsQueryVariables },
					result,
					(result, query) => {
						if (result?.createCircular?.circular) {
							return {
								...query,
								allCirculars: {
									...query?.allCirculars,
									circulars: [result.createCircular.circular, ...(query?.allCirculars?.circulars ?? [])],
								},
							}
						}

						return query
					}
				)
			},
			createDepartment: (result, _, cache) => {
				betterQueryUpdate<CreateDepartmentMutation, AllDepartmentsQuery>(
					cache,
					{ query: AllDepartmentsDocument, variables: { pagination: { limit: 10, page: 1 } } as AllDepartmentsQueryVariables },
					result,
					(result, query) => {
						if (result?.createDepartment?.department) {
							return {
								...query,
								allDepartments: {
									...query?.allDepartments,
									departments: [result.createDepartment.department, ...(query?.allDepartments?.departments ?? [])],
								},
							}
						}

						return query
					}
				)
			},
			removeDepartment: (_, { departmentId }: RemoveDepartmentMutationVariables, cache) => {
				return cache.invalidate({ __typename: "Department", _id: departmentId })
			},
			markStaffManualIn: (_result, variables: MarkStaffManualInMutationVariables, cache) => {
				betterQueryUpdate<MarkStaffManualInMutation, TodayAttendanceOfStaffQuery>(
					cache,
					{ query: TodayAttendanceOfStaffDocument, variables: { staffId: variables.staffId } as TodayAttendanceOfStaffQueryVariables },
					_result,
					(result, query) => {
						if (result?.markStaffManualIn) {
							return {
								...query,
								todayAttendanceOfStaff: {
									...query?.todayAttendanceOfStaff,
									...(result.markStaffManualIn as ExtendedStaffAttendance),
								},
							}
						}

						return query
					}
				)
			},
			markStaffManualOut: (_result, variables: MarkStaffManualOutMutationVariables, cache) => {
				betterQueryUpdate<MarkStaffManualOutMutation, TodayAttendanceOfStaffQuery>(
					cache,
					{ query: TodayAttendanceOfStaffDocument, variables: { staffId: variables.staffId } as TodayAttendanceOfStaffQueryVariables },
					_result,
					(result, query) => {
						if (result?.markStaffManualOut) {
							return {
								...query,
								todayAttendanceOfStaff: {
									...query?.todayAttendanceOfStaff,
									...(result.markStaffManualOut as ExtendedStaffAttendance),
								},
							}
						}

						return query
					}
				)
			},
			createRestrictedZone: (_result, _, cache) => {
				betterQueryUpdate<CreateRestrictedZoneMutation, AllRestrictedZonesQuery>(
					cache,
					{ query: AllRestrictedZonesDocument, variables: { pagination: { limit: 10, page: 1 } } as AllRestrictedZonesQueryVariables },
					_result,
					(result, query) => {
						if (result?.createRestrictedZone) {
							return {
								...query,
								allRestrictedZones: {
									...query?.allRestrictedZones,
									restrictedZones: [result.createRestrictedZone, ...(query?.allRestrictedZones.restrictedZones || [])],
								},
							}
						}

						return query
					}
				)
			},
			addUnauthorizedClass: (_result, variables: AddUnauthorizedClassMutationVariables, cache) => {
				betterQueryUpdate<AddUnauthorizedClassMutation, UnauthorizedClassesQuery>(
					cache,
					{ query: UnauthorizedClassesDocument, variables: { restrictedZoneId: variables.restrictedZoneId } as UnauthorizedClassesQueryVariables },
					_result,
					(result, query) => {
						if (result?.addUnauthorizedClass) {
							return {
								...query,
								unauthorizedClasses: [...(query?.unauthorizedClasses || []), result.addUnauthorizedClass],
							}
						}

						return query
					}
				)
			},
			addUnauthorizedDepartments: (_result, variables: AddUnauthorizedDepartmentsMutationVariables, cache) => {
				betterQueryUpdate<AddUnauthorizedDepartmentsMutation, UnauthorizedDepartmentsQuery>(
					cache,
					{
						query: UnauthorizedDepartmentsDocument,
						variables: { restrictedZoneId: variables.restrictedZoneId } as UnauthorizedDepartmentsQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.addUnauthorizedDepartments) {
							return {
								...query,
								unauthorizedDepartments: [...(query?.unauthorizedDepartments || []), ...result.addUnauthorizedDepartments],
							}
						}

						return query
					}
				)
			},
			removeUnauthorizedClass: (_result: RemoveUnauthorizedClassMutation, variables: RemoveUnauthorizedClassMutationVariables, cache) => {
				if (_result.removeUnauthorizedClass) {
					cache.invalidate({ __typename: "ExtendedUnauthorizedClass", classId: variables.classId })
				}
			},
			removeUnauthorizedDepartment: (_result: RemoveUnauthorizedDepartmentMutation, variables: RemoveUnauthorizedDepartmentMutationVariables, cache) => {
				if (_result.removeUnauthorizedDepartment) {
					cache.invalidate({ __typename: "Department", _id: variables.departmentId })
				}
			},
			addReaderToRestrictedZone: (result, variables: AddReaderToRestrictedZoneMutationVariables, cache) => {
				betterQueryUpdate<AddReaderToRestrictedZoneMutation, RestrictedZoneByIdQuery>(
					cache,
					{ query: RestrictedZoneByIdDocument, variables: { restrictedZoneId: variables.restrictedZoneId } as RestrictedZoneByIdQueryVariables },
					result,
					(result, query) => {
						if (result.addReaderToRestrictedZone && query.restrictedZoneById) {
							return {
								restrictedZoneById: {
									...query.restrictedZoneById,
									readers: [...(query.restrictedZoneById.readers || []), result.addReaderToRestrictedZone],
								},
							}
						}

						return query
					}
				)
			},
			removeReaderFromRestrictedZone: (result, variables: RemoveReaderFromRestrictedZoneMutationVariables, cache) => {
				betterQueryUpdate<RemoveReaderFromRestrictedZoneMutation, RestrictedZoneByIdQuery>(
					cache,
					{ query: RestrictedZoneByIdDocument, variables: { restrictedZoneId: variables.restrictedZoneId } as RestrictedZoneByIdQueryVariables },
					result,
					(result, query) => {
						if (result.removeReaderFromRestrictedZone && query?.restrictedZoneById) {
							const readers = [...(query.restrictedZoneById.readers ?? [])] as Reader[]

							readers.splice(
								readers.findIndex((r) => r._id === variables.readerId),
								1
							)

							return {
								restrictedZoneById: { ...query.restrictedZoneById, readers },
							}
						}

						return query
					}
				)
			},
			editRestrictedZone: (result: EditRestrictedZoneMutation, variables: EditRestrictedZoneMutationVariables, cache) => {
				const _key = cache.keyOfEntity({ __typename: "ExtendedRestrictedZone", _id: variables.restrictedZoneId })

				if (_key) {
					const restrictedZone = cache.readFragment<ExtendedRestrictedZoneFragment>(ExtendedRestrictedZoneFragmentDoc, _key)
					if (restrictedZone)
						cache.writeFragment<ExtendedRestrictedZoneFragment>(ExtendedRestrictedZoneFragmentDoc, {
							...restrictedZone,
							...variables.input,
						})
				}

				const key = cache.keyOfEntity({ __typename: "RestrictedZone", _id: variables.restrictedZoneId })

				if (key) {
					const restrictedZone = cache.readFragment<RestrictedZoneFragment>(RestrictedZoneFragmentDoc, key)
					if (restrictedZone) {
						cache.writeFragment<RestrictedZoneFragment>(RestrictedZoneFragmentDoc, { ...restrictedZone, ...result.editRestrictedZone })
					}
				}
			},
			createRewardCategory: (result: CreateRewardCategoryMutation, variables: CreateRewardCategoryMutationVariables, cache) => {
				if (result.createRewardCategory.rewardCategory?.target === UserTargets.Staffs) {
					return betterQueryUpdate<CreateRewardCategoryMutation, AllRewardCategoriesForStaffQuery>(
						cache,
						{
							query: AllRewardCategoriesForStaffDocument,
							variables: { pagination: { limit: 10, page: 1 } } as AllRewardCategoriesForStaffQueryVariables,
						},
						result,
						(result, query) => {
							if (result?.createRewardCategory.rewardCategory) {
								return {
									...query,
									allRewardCategoriesForStaff: {
										...query?.allRewardCategoriesForStaff,
										rewardCategories: [
											{
												...result.createRewardCategory.rewardCategory,
												__typename: "ExtendedRewardCategory",
											} as ExtendedRewardCategoryFragment,
											...(query?.allRewardCategoriesForStaff?.rewardCategories ?? []),
										],
									},
								}
							}

							return query
						}
					)
				} else if (result.createRewardCategory.rewardCategory?.target === UserTargets.Students) {
					return betterQueryUpdate<CreateRewardCategoryMutation, AllRewardCategoriesForStudentsQuery>(
						cache,
						{
							query: AllRewardCategoriesForStudentsDocument,
							variables: { pagination: { limit: 10, page: 1 } } as AllRewardCategoriesForStudentsQueryVariables,
						},
						result,
						(result, query) => {
							if (result?.createRewardCategory.rewardCategory) {
								return {
									...query,
									allRewardCategoriesForStudents: {
										...query?.allRewardCategoriesForStudents,
										rewardCategories: [
											{
												...result.createRewardCategory.rewardCategory,
												__typename: "ExtendedRewardCategory",
											} as ExtendedRewardCategoryFragment,
											...(query?.allRewardCategoriesForStudents?.rewardCategories ?? []),
										],
									},
								}
							}

							return query
						}
					)
				}
			},
			createRewardItem: (_result: CreateRewardItemMutation, variables: CreateRewardItemMutationVariables, cache) => {
				if (!_result.createRewardItem.rewardItem?.categoryId) return

				const key = cache.keyOfEntity({
					__typename: "RewardCategory",
					_id: _result.createRewardItem.rewardItem.categoryId,
				})

				if (key) {
					const category = cache.readFragment<RewardCategoryFragment>(RewardCategoryFragmentDoc, key)

					if (category?.target === UserTargets.Staffs) {
						return betterQueryUpdate<CreateRewardItemMutation, AllRewardCategoriesForStaffQuery>(
							cache,
							{
								query: AllRewardCategoriesForStaffDocument,
								variables: { pagination: { limit: 10, page: 1 } } as AllRewardCategoriesForStaffQueryVariables,
							},
							_result,
							(result, query) => {
								if (result?.createRewardItem.rewardItem) {
									const rewardCategories = query?.allRewardCategoriesForStaff.rewardCategories?.map((c) => {
										if (c._id === result.createRewardItem.rewardItem?.categoryId) {
											return { ...c, items: [result?.createRewardItem.rewardItem, ...(c.items || [])] }
										}

										return c
									})

									return {
										...query,
										allRewardCategoriesForStaff: {
											...query?.allRewardCategoriesForStaff,
											rewardCategories,
										},
									}
								}

								return query
							}
						)
					} else if (category?.target === UserTargets.Students) {
						return betterQueryUpdate<CreateRewardItemMutation, AllRewardCategoriesForStudentsQuery>(
							cache,
							{
								query: AllRewardCategoriesForStudentsDocument,
								variables: { pagination: { limit: 10, page: 1 } } as AllRewardCategoriesForStudentsQueryVariables,
							},
							_result,
							(result, query) => {
								if (result?.createRewardItem.rewardItem) {
									const rewardCategories = query?.allRewardCategoriesForStudents.rewardCategories?.map((c) => {
										if (c._id === result.createRewardItem.rewardItem?.categoryId) {
											return { ...c, items: [result?.createRewardItem.rewardItem, ...(c.items || [])] }
										}

										return c
									})

									return {
										...query,
										allRewardCategoriesForStudents: {
											...query?.allRewardCategoriesForStudents,
											rewardCategories,
										},
									}
								}

								return query
							}
						)
					}
				}
			},
			createPenaltyCategory: (result: CreatePenaltyCategoryMutation, variables: CreatePenaltyCategoryMutationVariables, cache) => {
				if (result.createPenaltyCategory.penaltyCategory?.target === UserTargets.Staffs) {
					return betterQueryUpdate<CreatePenaltyCategoryMutation, AllPenaltyCategoriesForStaffQuery>(
						cache,
						{
							query: AllPenaltyCategoriesForStaffDocument,
							variables: { pagination: { limit: 10, page: 1 } } as AllPenaltyCategoriesForStaffQueryVariables,
						},
						result,
						(result, query) => {
							if (result?.createPenaltyCategory.penaltyCategory) {
								return {
									...query,
									allPenaltyCategoriesForStaff: {
										...query?.allPenaltyCategoriesForStaff,
										penaltyCategories: [
											{
												...result.createPenaltyCategory.penaltyCategory,
												__typename: "ExtendedPenaltyCategory",
											} as ExtendedPenaltyCategoryFragment,
											...(query?.allPenaltyCategoriesForStaff?.penaltyCategories ?? []),
										],
									},
								}
							}

							return query
						}
					)
				} else if (result.createPenaltyCategory.penaltyCategory?.target === UserTargets.Students) {
					return betterQueryUpdate<CreatePenaltyCategoryMutation, AllPenaltyCategoriesForStudentsQuery>(
						cache,
						{
							query: AllPenaltyCategoriesForStudentsDocument,
							variables: { pagination: { limit: 10, page: 1 } } as AllPenaltyCategoriesForStudentsQueryVariables,
						},
						result,
						(result, query) => {
							if (result?.createPenaltyCategory.penaltyCategory) {
								return {
									...query,
									allPenaltyCategoriesForStudents: {
										...query?.allPenaltyCategoriesForStudents,
										penaltyCategories: [
											{
												...result.createPenaltyCategory.penaltyCategory,
												__typename: "ExtendedPenaltyCategory",
											} as ExtendedPenaltyCategoryFragment,
											...(query?.allPenaltyCategoriesForStudents?.penaltyCategories ?? []),
										],
									},
								}
							}

							return query
						}
					)
				}
			},
			createPenaltyItem: (_result: CreatePenaltyItemMutation, variables: CreatePenaltyItemMutationVariables, cache) => {
				if (!_result.createPenaltyItem.penaltyItem?.categoryId) return

				const key = cache.keyOfEntity({
					__typename: "PenaltyCategory",
					_id: _result.createPenaltyItem.penaltyItem.categoryId,
				})

				if (key) {
					const category = cache.readFragment<PenaltyCategoryFragment>(PenaltyCategoryFragmentDoc, key)

					if (category?.target === UserTargets.Staffs) {
						return betterQueryUpdate<CreatePenaltyItemMutation, AllPenaltyCategoriesForStaffQuery>(
							cache,
							{
								query: AllPenaltyCategoriesForStaffDocument,
								variables: { pagination: { limit: 10, page: 1 } } as AllPenaltyCategoriesForStaffQueryVariables,
							},
							_result,
							(result, query) => {
								if (result?.createPenaltyItem.penaltyItem) {
									const penaltyCategories = query?.allPenaltyCategoriesForStaff.penaltyCategories?.map((c) => {
										if (c._id === result.createPenaltyItem.penaltyItem?.categoryId) {
											return { ...c, items: [result?.createPenaltyItem.penaltyItem, ...(c.items || [])] }
										}

										return c
									})

									return {
										...query,
										allPenaltyCategoriesForStaff: {
											...query?.allPenaltyCategoriesForStaff,
											penaltyCategories,
										},
									}
								}

								return query
							}
						)
					} else if (category?.target === UserTargets.Students) {
						return betterQueryUpdate<CreatePenaltyItemMutation, AllPenaltyCategoriesForStudentsQuery>(
							cache,
							{
								query: AllPenaltyCategoriesForStudentsDocument,
								variables: { pagination: { limit: 10, page: 1 } } as AllPenaltyCategoriesForStudentsQueryVariables,
							},
							_result,
							(result, query) => {
								if (result?.createPenaltyItem.penaltyItem) {
									const penaltyCategories = query?.allPenaltyCategoriesForStudents.penaltyCategories?.map((c) => {
										if (c._id === result.createPenaltyItem.penaltyItem?.categoryId) {
											return { ...c, items: [result?.createPenaltyItem.penaltyItem, ...(c.items || [])] }
										}

										return c
									})

									return {
										...query,
										allPenaltyCategoriesForStudents: {
											...query?.allPenaltyCategoriesForStudents,
											penaltyCategories,
										},
									}
								}

								return query
							}
						)
					}
				}
			},
			createPointsRedeemItem: (_result: CreatePointsRedeemItemMutation, variables: CreatePointsRedeemItemMutationVariables, cache) => {
				if (_result.createPointsRedeemItem.pointsRedeemItem?.target === UserTargets.Staffs) {
					betterQueryUpdate<CreatePointsRedeemItemMutation, AllPointsRedeemItemsForStaffQuery>(
						cache,
						{
							query: AllPointsRedeemItemsForStaffDocument,
							variables: { pagination: { limit: 10, page: 1 } } as AllPointsRedeemItemsForStaffQueryVariables,
						},
						_result,
						(result, query) => {
							if (result?.createPointsRedeemItem.pointsRedeemItem) {
								return {
									...query,
									allPointsRedeemItemsForStaff: {
										...query.allPointsRedeemItemsForStaff,
										pointsRedeemItems: [
											result.createPointsRedeemItem.pointsRedeemItem,
											...(query.allPointsRedeemItemsForStaff.pointsRedeemItems || []),
										],
									},
								}
							}

							return query
						}
					)
				} else if (_result.createPointsRedeemItem.pointsRedeemItem?.target === UserTargets.Students) {
					betterQueryUpdate<CreatePointsRedeemItemMutation, AllPointsRedeemItemsForStudentsQuery>(
						cache,
						{
							query: AllPointsRedeemItemsForStudentsDocument,
							variables: { pagination: { limit: 10, page: 1 } } as AllPointsRedeemItemsForStudentsQueryVariables,
						},
						_result,
						(result, query) => {
							if (result?.createPointsRedeemItem.pointsRedeemItem) {
								return {
									...query,
									allPointsRedeemItemsForStudents: {
										...query.allPointsRedeemItemsForStudents,
										pointsRedeemItems: [
											result.createPointsRedeemItem.pointsRedeemItem,
											...(query.allPointsRedeemItemsForStudents.pointsRedeemItems || []),
										],
									},
								}
							}

							return query
						}
					)
				}
			},
			createPaytankMerchant: (_result: CreatePaytankMerchantMutation, _, cache) => {
				betterQueryUpdate<CreatePaytankMerchantMutation, AllPaytankMerchantsQuery>(
					cache,
					{
						query: AllPaytankMerchantsDocument,
						variables: { pagination: { limit: 10, page: 1 } } as AllPaytankMerchantsQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.createPaytankMerchant && query) {
							return {
								...query,
								allPaytankMerchants: {
									...query.allPaytankMerchants,
									merchants: [result.createPaytankMerchant, ...(query.allPaytankMerchants.merchants || [])],
								},
							}
						}

						return query
					}
				)
			},
			editPaytankMerchant: (result: EditPaytankMerchantMutation, variables: EditPaytankMerchantMutationVariables, cache) => {
				const key = cache.keyOfEntity({
					__typename: "ExtendedPaytankMerchant",
					_id: variables.paytankMerchantId,
				})

				if (!key) return

				const merchant = cache.readFragment<ExtendedPaytankMerchantFragment>(ExtendedPaytankMerchantFragmentDoc, key)

				if (!merchant) return

				cache.writeFragment<ExtendedPaytankMerchantFragment>(ExtendedPaytankMerchantFragmentDoc, {
					...merchant,
					...result.editPaytankMerchant,
					__typename: "ExtendedPaytankMerchant",
				})
			},
			createPaytankMerchantType: (_result: CreatePaytankMerchantTypeMutation, _, cache) => {
				betterQueryUpdate<CreatePaytankMerchantTypeMutation, AllPaytankMerchantTypesQuery>(
					cache,
					{
						query: AllPaytankMerchantTypesDocument,
						variables: { pagination: { limit: 10, page: 1 } } as AllPaytankMerchantTypesQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.createPaytankMerchantType && query) {
							return {
								...query,
								allPaytankMerchantTypes: {
									...query.allPaytankMerchantTypes,
									merchantTypes: [result.createPaytankMerchantType, ...(query.allPaytankMerchantTypes.merchantTypes || [])],
								},
							}
						}

						return query
					}
				)
			},
			editPaytankMerchantType: (result: EditPaytankMerchantTypeMutation, variables: EditPaytankMerchantTypeMutationVariables, cache) => {
				const key = cache.keyOfEntity({
					__typename: "PaytankMerchantType",
					_id: variables.paytankMerchantTypeId,
				})

				if (!key) return

				const merchantType = cache.readFragment<PaytankMerchantTypeFragment>(PaytankMerchantTypeFragmentDoc, key)

				if (!merchantType) return

				cache.writeFragment<PaytankMerchantTypeFragment>(PaytankMerchantTypeFragmentDoc, {
					...merchantType,
					...result.editPaytankMerchantType,
					__typename: "PaytankMerchantType",
				})
			},
			activateStudent: (result: ActivateStudentMutation, variables: ActivateStudentMutationVariables, cache) => {
				if (!result.activateStudent) return

				const key = cache.keyOfEntity({ __typename: "ExtendedStudent", _id: variables.studentId })

				if (!key) return

				const student = cache.readFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, key) as ExtendedStudentFragment

				if (!student) return

				return cache.writeFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, {
					...student,
					status: "Active",
				})
			},
			deactivateStudent: (result: DeactivateStudentMutation, variables: DeactivateStudentMutationVariables, cache) => {
				if (!result.deactivateStudent) return

				const key = cache.keyOfEntity({ __typename: "ExtendedStudent", _id: variables.studentId })

				if (!key) return

				const student = cache.readFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, key) as ExtendedStudentFragment

				if (!student) return

				return cache.writeFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, {
					...student,
					status: "Inactive",
				})
			},
			toggleNotificationActivityStatus: (
				result: ToggleNotificationActivityStatusMutation,
				variables: ToggleNotificationActivityStatusMutationVariables,
				cache
			) => {
				if (!result.toggleNotificationActivityStatus) return

				const key = cache.keyOfEntity({ __typename: "ExtendedStudent", _id: variables.studentId })

				if (!key) return

				const student = cache.readFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, key) as ExtendedStudentFragment

				if (!student) return

				return cache.writeFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, {
					...student,
					isTelegramMessagesEnabled: student.isTelegramMessagesEnabled ? false : true,
				})
			},
			createSchoolTiming: (_result: CreateSchoolTimingMutation, _, cache) => {
				betterQueryUpdate<CreateSchoolTimingMutation, AllSchoolTimingsQuery>(
					cache,
					{
						query: AllSchoolTimingsDocument,
						variables: { pagination: { limit: 20, page: 1 } } as AllSchoolTimingsQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.createSchoolTiming && query) {
							return {
								...query,
								allSchoolTimings: {
									...query.allSchoolTimings,
									schoolTimings: [result.createSchoolTiming, ...(query.allSchoolTimings.schoolTimings || [])],
								},
							}
						}

						return query
					}
				)
			},
			deleteSchoolTiming: (_, { schoolTimingId }: DeleteSchoolTimingMutationVariables, cache) => {
				return cache.invalidate({ __typename: "SchoolTiming", _id: schoolTimingId })
			},
			updateSchoolTimingOfStudent: (result: UpdateSchoolTimingOfStudentMutation, variables: UpdateSchoolTimingOfStudentMutationVariables, cache) => {
				if (!result.updateSchoolTimingOfStudent) return

				const key = cache.keyOfEntity({ __typename: "ExtendedStudent", _id: variables.studentId })

				if (!key) return

				const student = cache.readFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, key) as ExtendedStudentFragment

				if (!student) return

				return cache.writeFragment<ExtendedStudentFragment>(
					ExtendedStudentFragmentDoc,
					{
						...student,
						...result.updateSchoolTimingOfStudent,
						__typename: "ExtendedStudent",
					},
					{ _id: student._id }
				)
			},
			removeSchoolTimingsOfClassSection: (
				result: RemoveSchoolTimingsOfClassSectionMutation,
				variables: RemoveSchoolTimingsOfClassSectionMutationVariables,
				cache
			) => {
				if (!result.removeSchoolTimingsOfClassSection) return

				const key = cache.keyOfEntity({ __typename: "ClassSection", _id: variables.classSectionId })

				if (!key) return

				const classSection = cache.readFragment<ClassSectionFragment>(ClassSectionFragmentDoc, key) as ClassSectionFragment

				if (!classSection) return

				return cache.writeFragment<ClassSectionFragment>(
					ClassSectionFragmentDoc,
					{
						...classSection,
						schoolTimingId: null,
						schoolTiming: null,
					},
					{ _id: classSection._id }
				)
			},
			createCafeteriaMealType: (_result: CreateCafeteriaMealTypeMutation, _, cache) => {
				betterQueryUpdate<CreateCafeteriaMealTypeMutation, AllCafeteriaMealTypesQuery>(
					cache,
					{
						query: AllCafeteriaMealTypesDocument,
						variables: { pagination: { limit: 10, page: 1 } } as AllCafeteriaMealTypesQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.createCafeteriaMealType.cafeteriaMealType && query) {
							return {
								...query,
								allCafeteriaMealTypes: {
									...query.allCafeteriaMealTypes,
									cafeteriaMealTypes: [
										result.createCafeteriaMealType.cafeteriaMealType,
										...(query.allCafeteriaMealTypes.cafeteriaMealTypes || []),
									],
								},
							}
						}

						return query
					}
				)
			},
			editCafeteriaMealType: (result: EditCafeteriaMealTypeMutation, variables: EditCafeteriaMealTypeMutationVariables, cache) => {
				if (!result.editCafeteriaMealType.cafeteriaMealType) return

				cache.writeFragment<CafeteriaMealTypeFragment>(CafeteriaMealTypeFragmentDoc, result.editCafeteriaMealType.cafeteriaMealType, {
					_id: variables.cafeteriaMealTypeId,
				})
			},
			deleteCafeteriaMealType: (result: DeleteCafeteriaMealTypeMutation, variables: DeleteCafeteriaMealTypeMutationVariables, cache) => {
				cache.invalidate({ __typename: "CafeteriaMealType", _id: variables.cafeteriaMealTypeId })
			},
			createCafeteriaRegistrationCharge: (_result: CreateCafeteriaRegistrationChargeMutation, _, cache) => {
				betterQueryUpdate<CreateCafeteriaRegistrationChargeMutation, AllCafeteriaRegistrationChargesQuery>(
					cache,
					{
						query: AllCafeteriaRegistrationChargesDocument,
						variables: { pagination: { limit: 10, page: 1 } } as AllCafeteriaRegistrationChargesQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.createCafeteriaRegistrationCharge.cafeteriaRegistrationCharge && query) {
							return {
								...query,
								allCafeteriaRegistrationCharges: {
									...query.allCafeteriaRegistrationCharges,
									cafeteriaRegistrationCharges: [
										result.createCafeteriaRegistrationCharge.cafeteriaRegistrationCharge,
										...(query.allCafeteriaRegistrationCharges.cafeteriaRegistrationCharges || []),
									],
								},
							}
						}

						return query
					}
				)
			},
			editCafeteriaRegistrationCharge: (
				result: EditCafeteriaRegistrationChargeMutation,
				variables: EditCafeteriaRegistrationChargeMutationVariables,
				cache
			) => {
				if (!result.editCafeteriaRegistrationCharge.cafeteriaRegistrationCharge) return

				cache.writeFragment<CafeteriaRegistrationChargeFragment>(
					CafeteriaRegistrationChargeFragmentDoc,
					result.editCafeteriaRegistrationCharge.cafeteriaRegistrationCharge,
					{
						_id: variables.cafeteriaRegistrationChargeId,
					}
				)
			},
			deleteCafeteriaRegistrationCharge: (
				result: DeleteCafeteriaRegistrationChargeMutation,
				variables: DeleteCafeteriaRegistrationChargeMutationVariables,
				cache
			) => {
				cache.invalidate({ __typename: "CafeteriaRegistrationCharge", _id: variables.cafeteriaRegistrationChargeId })
			},
			createSchoolSession: (_result: CreateSchoolSessionMutation, _, cache) => {
				betterQueryUpdate<CreateSchoolSessionMutation, AllSchoolSessionsQuery>(
					cache,
					{
						query: AllSchoolSessionsDocument,
						variables: { pagination: { limit: 10, page: 1 } } as AllSchoolSessionsQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.createSchoolSession.schoolSession && query) {
							return {
								...query,
								allSchoolSessions: {
									...query.allSchoolSessions,
									schoolSessions: [result.createSchoolSession.schoolSession, ...(query.allSchoolSessions.schoolSessions || [])],
								},
							}
						}

						return query
					}
				)
			},
			editSchoolSession: (result: EditSchoolSessionMutation, variables: EditSchoolSessionMutationVariables, cache) => {
				if (!result.editSchoolSession.schoolSession) return

				cache.writeFragment<SchoolSessionFragment>(SchoolSessionFragmentDoc, result.editSchoolSession.schoolSession, {
					_id: variables.schoolSessionId,
				})
			},
			deleteSchoolSession: (result: DeleteSchoolSessionMutation, variables: DeleteSchoolSessionMutationVariables, cache) => {
				cache.invalidate({ __typename: "SchoolSession", _id: variables.schoolSessionId })
			},
			generateCafeteriaRegistrationsReport: (result, _, cache) => {
				betterQueryUpdate<GenerateCafeteriaRegistrationsReportMutation, AllCafeteriaRegistrationsReportsQuery>(
					cache,
					{
						query: AllCafeteriaRegistrationsReportsDocument,
						variables: { pagination: { limit: 20, page: 1 } } as AllCafeteriaRegistrationsReportsQueryVariables,
					},
					result,
					(result, query) => {
						if (result?.generateCafeteriaRegistrationsReport && query) {
							return {
								...query,
								allCafeteriaRegistrationsReports: {
									...query?.allCafeteriaRegistrationsReports,
									cafeteriaRegistrationsReports: [
										result.generateCafeteriaRegistrationsReport,
										...(query?.allCafeteriaRegistrationsReports?.cafeteriaRegistrationsReports ?? []),
									],
								},
							}
						}

						return query
					}
				)
			},
			generateCafeteriaPaymentsReport: (result, _, cache) => {
				betterQueryUpdate<GenerateCafeteriaPaymentsReportMutation, AllCafeteriaPaymentsReportsQuery>(
					cache,
					{
						query: AllCafeteriaPaymentsReportsDocument,
						variables: { pagination: { limit: 20, page: 1 } } as AllCafeteriaPaymentsReportsQueryVariables,
					},
					result,
					(result, query) => {
						if (result?.generateCafeteriaPaymentsReport && query) {
							return {
								...query,
								allCafeteriaPaymentsReports: {
									...query?.allCafeteriaPaymentsReports,
									cafeteriaPaymentsReports: [
										result.generateCafeteriaPaymentsReport,
										...(query?.allCafeteriaPaymentsReports?.cafeteriaPaymentsReports ?? []),
									],
								},
							}
						}

						return query
					}
				)
			},
			createAndFinishManualCafeteriaRegistration: (_result: CreateAndFinishManualCafeteriaRegistrationMutation, _, cache) => {
				betterQueryUpdate<CreateAndFinishManualCafeteriaRegistrationMutation, StudentByIdQuery>(
					cache,
					{
						query: StudentByIdDocument,
						variables: { studentId: _result.createAndFinishManualCafeteriaRegistration._id } as StudentByIdQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.createAndFinishManualCafeteriaRegistration && query) {
							return {
								...query,
								studentById: {
									...query.studentById,
									...result.createAndFinishManualCafeteriaRegistration,
									__typename: "ExtendedStudent",
								},
							}
						}

						return query
					}
				)
			},
			deleteManualCafeteriaRegistrationLog: (_, { cafeteriaRegistrationLogId }: DeleteManualCafeteriaRegistrationLogMutationVariables, cache) => {
				return cache.invalidate({ __typename: "CafeteriaRegistrationLog", _id: cafeteriaRegistrationLogId })
			},
		},
	},
	optimistic: {
		// @ts-ignore
		likeFeedPost: (variables: LikeFeedPostMutationVariables, cache) => {
			const { feedPostId } = variables as LikeFeedPostMutationVariables

			const key = cache.keyOfEntity({
				__typename: "ExtendedFeedPost",
				_id: feedPostId,
			})

			if (key) {
				const feedPost = cache.readFragment<ExtendedFeedPostFragment>(ExtendedFeedPostFragmentDoc, key)

				const meQuery = cache.readQuery<MeQuery>({
					query: MeDocument,
				}) as MeQuery

				if (!meQuery) return

				const { me } = meQuery

				if (feedPost && me) {
					return {
						__typename: "ExtendedFeedPost",
						...feedPost,
						isLiked: true,
						likes: [
							...(feedPost.likes ?? []),
							{
								__typename: "FeedPostLike",
								author: {
									authorType: AuthorTypes.School,
									schoolId: me._id,
									studentId: null,
									teacherId: null,
									alumnusId: null,
								},
								createdAt: new Date(),
								updatedAt: new Date(),
							},
						],
					}
				}
			}
		},
		// @ts-ignore
		dislikeFeedPost: (variables, cache) => {
			const { feedPostId } = variables as DislikeFeedPostMutationVariables

			const key = cache.keyOfEntity({
				__typename: "ExtendedFeedPost",
				_id: feedPostId,
			})

			if (key) {
				const feedPost = cache.readFragment<ExtendedFeedPostFragment>(ExtendedFeedPostFragmentDoc, key)

				const meQuery = cache.readQuery<MeQuery>({
					query: MeDocument,
				}) as MeQuery

				if (!meQuery) return

				const { me } = meQuery

				if (feedPost && me) {
					const index = feedPost.likes?.findIndex(
						({ author: { authorType, studentId } }) => authorType === AuthorTypes.Student && String(studentId) === String(me._id)
					)

					feedPost.likes?.splice(index || 0, 1)

					return {
						__typename: "ExtendedFeedPost",
						...feedPost,
						isLiked: false,
					}
				}
			}
		},
	},
})
