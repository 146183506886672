import { Center, CenterProps, Flex, Heading, Icon, IconButton, Link, Text, VStack } from "@chakra-ui/react"
import { faBell, faClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import { motion, MotionProps } from "framer-motion"
import React, { ComponentType, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AuthorTypes, ExtendedStudent, RestrictedEntrySubscriptionInput, useActiveRestrictedEntriesQuery, useRestrictedEntrySubscription } from "../../graphql"
import { StaffTag } from "../staff"
import { StudentTag } from "../student"

const MAX = 2

const MotionCenter = motion<MotionProps & Omit<CenterProps, "transition">>(Center as unknown as ComponentType<MotionProps & Omit<CenterProps, "transition">>)

export const RestrictedZoneAlert: React.FC = () => {
	const [{ data }] = useActiveRestrictedEntriesQuery()

	const [input, setInput] = useState<RestrictedEntrySubscriptionInput>({ receivedEntries: [] })

	useEffect(() => {
		const receivedEntries = data?.activeRestrictedEntries?.map(({ tagHexId, readerHexId }) => ({ tagHexId, readerHexId }))

		setInput({ receivedEntries })
	}, [data])

	useRestrictedEntrySubscription({ variables: { input } })

	const [isClosed, setIsClosed] = useState(false)

	const navigate = useNavigate()

	if (!data?.activeRestrictedEntries?.length || isClosed) return <></>

	return (
		<MotionCenter
			pos="fixed"
			bottom="0"
			right="0"
			bgColor="rgba(255, 0, 0, 0.4)"
			backdropFilter="blur(16px)"
			borderTopLeftRadius="100%"
			borderTopRightRadius="100%"
			borderBottomLeftRadius="100%"
			animate={{ left: 0, top: 0, borderRadius: 0 }}
			transition={{ duration: 0.3 }}
			zIndex={9999999}
		>
			<MotionCenter
				pos="relative"
				w="full"
				h="full"
				p="12"
				initial={{ backgroundColor: "#00000000" }}
				animate={{ backgroundColor: "#ff3e3e68" }}
				transition={{ repeat: Infinity, repeatType: "reverse", delay: 0.3 }}
			>
				<IconButton pos="absolute" top="4" right="4" variant="solid" colorScheme="blackAlpha" aria-label="close-btn" onClick={() => setIsClosed(true)}>
					<Icon as={(props: any) => <FontAwesomeIcon icon={faClose} {...props} />} />
				</IconButton>
				<MotionCenter w="full" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3 }}>
					<VStack w="full" spacing={12}>
						<VStack>
							<Icon as={(props: any) => <FontAwesomeIcon icon={faBell} {...props} />} color="#5a0000" fontSize="5xl" />
							<Heading fontSize="2xl" color="#5a0000">
								Restricted Entry
							</Heading>
						</VStack>
						<Flex w="full" justify="center" align="stretch" flexWrap="wrap">
							{data.activeRestrictedEntries.slice(0, MAX).map((entry) => (
								<VStack key={entry._id} m={1} w="full" maxW="72" align="stretch" px="8" py="4" bgColor="whiteAlpha.600" rounded="xl">
									<Heading fontSize="lg" color="blackAlpha.800">
										{entry.restrictedZone.name}
									</Heading>
									{entry.author.authorType === AuthorTypes.Staff
										? entry.author.staff && <StaffTag staff={entry.author.staff} />
										: entry.author.student && <StudentTag student={entry.author.student as ExtendedStudent} />}
									<Text color="blackAlpha.700">{format(new Date(entry.createdAt), "p")}</Text>
								</VStack>
							))}
						</Flex>
						{data.activeRestrictedEntries.length > MAX && (
							<VStack spacing={0}>
								<Text color="#5a0000">There are {data.activeRestrictedEntries.length - MAX} more entries</Text>
								<Link
									onClick={() => {
										setIsClosed(true)
										navigate("/restrictedEntries")
									}}
									color="#5a0000"
									fontWeight="semibold"
								>
									See all of them
								</Link>
							</VStack>
						)}
					</VStack>
				</MotionCenter>
			</MotionCenter>
		</MotionCenter>
	)
}
