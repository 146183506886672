import { Button, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from "@chakra-ui/react"
import React from "react"
import { AddReaderForm } from "./AddReaderForm"

export const AddReaderPopover: React.FC = () => {
	return (
		<Popover isLazy lazyBehavior="unmount">
			<PopoverTrigger>
				<Button colorScheme="purple" size="sm">
					Add New
				</Button>
			</PopoverTrigger>
			<PopoverContent bgColor="white">
				<PopoverArrow bgColor="white" />
				<PopoverBody>
					<AddReaderForm />
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
