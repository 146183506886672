import { Box, BoxProps, ButtonGroup, Heading, HeadingProps, HStack, Icon, IconButton, StackProps, VStack } from "@chakra-ui/react"
import { faAngleRight, faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion, MotionProps } from "framer-motion"
import React, { useEffect, useRef, useState } from "react"
import { useHorizontalScroll } from "../../hooks"

const MotionBox = motion<Omit<BoxProps, "transition"> & MotionProps>(Box as any)

export type HorizontalScrollContainerProps = {
	heading?: string
	headingProps?: HeadingProps
	onReachEnd?: () => any | Promise<any>
} & StackProps

export const HorizontalScrollContainer: React.FC<HorizontalScrollContainerProps> = ({ heading, headingProps, children, maxW, w, onReachEnd, ...props }) => {
	const ref = useRef() as React.MutableRefObject<HTMLDivElement>

	const { isDragging } = useHorizontalScroll(ref)

	const [totalScroll, setTotalScroll] = useState(0)

	const handleScrollLeft = () => {
		ref.current.scrollLeft -= 400
		setTotalScroll((prev) => Math.max(prev - ref.current.scrollLeft, ref.current?.scrollWidth))
	}

	const handleScrollRight = () => {
		ref.current.scrollLeft += 400
		setTotalScroll((prev) => Math.min(prev + ref.current.scrollLeft, ref.current?.scrollWidth))
	}

	const canShowArrows = ref.current?.scrollWidth > ref.current?.clientWidth

	useEffect(() => {
		if (totalScroll + ref.current?.clientWidth === ref.current?.scrollWidth) {
			onReachEnd?.()
		}
	}, [totalScroll, onReachEnd])

	return (
		<VStack pos="relative" w="full" align="stretch" spacing={2} maxW={maxW}>
			<HStack w="full" justify="space-between">
				{heading ? (
					<Heading fontSize={{ base: "xl", lg: "2xl" }} fontWeight="700" lineHeight="100%" color="text.400" {...headingProps}>
						{heading}
					</Heading>
				) : (
					<div />
				)}
				{canShowArrows && (
					<ButtonGroup size="sm" alignSelf="flex-end">
						<IconButton aria-label="prev-btn" onClick={handleScrollLeft} colorScheme="whiteAlpha">
							<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowLeft} {...props} />} color="text.500" />
						</IconButton>
						<IconButton aria-label="next-btn" onClick={handleScrollRight} colorScheme="whiteAlpha">
							<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} color="text.500" />
						</IconButton>
					</ButtonGroup>
				)}
			</HStack>
			<HStack
				className="horizontal-scroll-container"
				ref={ref}
				w={w}
				maxW={maxW ?? { base: "75%", md: "85%" }}
				overflowX="auto"
				{...props}
				py="2"
				px="2"
				cursor={isDragging ? "grabbing" : "grab"}
				align="flex-start"
				scrollBehavior="smooth"
				spacing={4}
			>
				<MotionBox
					alignSelf="center"
					mr="24px !important"
					whileInView={{
						translateX: [10, 1],
						scale: [1.2, 1],
						opacity: [1, 0.5],
					}}
					transition={{ repeat: Infinity, repeatType: "mirror" }}
				>
					<Icon as={(props: any) => <FontAwesomeIcon icon={faAngleRight} {...props} />} fontSize={{ base: "2xl", md: "3xl" }} color="primary.500" />
				</MotionBox>
				{children}
			</HStack>
		</VStack>
	)
}
