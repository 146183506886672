import {
	Button,
	Center,
	Container,
	Heading,
	HStack,
	Icon,
	Input,
	InputGroup,
	InputRightAddon,
	Select,
	Spinner,
	Stack,
	Text,
	useBreakpointValue,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { DashboardHeader, Nav, StudentsSelector } from "../../components"
import { GRADIENT } from "../../constants"
import { ExtendedClassFragment, ClassSectionFragment, useClassesQuery, useNotWhitelistedStudentsQuery, useWhitelistStudentsMutation } from "../../graphql"
import { useDrawer } from "../../hooks"

export const WhiteListStudent: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [{ data: classesData, fetching: fetchingClasses, error: classesError }] = useClassesQuery()

	const [schoolClass, setSchoolClass] = useState<ExtendedClassFragment | undefined>()

	const [classSection, setClassSection] = useState<ClassSectionFragment>()

	const [searchKeyword, setSearchKeyword] = useState("")

	useEffect(() => {
		if (classSection && !schoolClass?.sections.map((s) => s._id).includes(classSection._id)) {
			setClassSection(undefined)
		}
	}, [schoolClass])

	useEffect(() => {
		setSelectedStudentIds([])
	}, [schoolClass, classSection])

	const [{ data, fetching, error }] = useNotWhitelistedStudentsQuery({
		variables: { keyword: searchKeyword, classId: schoolClass?._id, classSectionId: classSection?._id },
	})

	const [selectedStudentIds, setSelectedStudentIds] = useState<string[]>([])

	const [, whitelistStudents] = useWhitelistStudentsMutation()

	const toast = useToast()

	const handleWhitelistStudent = async () => {
		if (selectedStudentIds.length > 0) {
			await whitelistStudents({
				studentIds: selectedStudentIds,
			})
			toast({ title: "Whitelisted", description: "Successfully whitelisted selected students", status: "success", duration: 2000 })
			setSelectedStudentIds([])
		} else {
			toast({ title: "Select at least one student", status: "error", duration: 2000 })
		}
	}

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<VStack align="flex-start">
						<Heading as="h1" fontSize="xl" color="text.500">
							Whitelist Student
						</Heading>
						<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
							{
								// TODO: add sub heading
							}
						</Heading>
					</VStack>

					<Stack w="full">
						{fetchingClasses ? (
							<Center>
								<VStack w="full" color="blackAlpha.800">
									<Text>Loading classes</Text>
									<Spinner size="sm" />
								</VStack>
							</Center>
						) : classesError ? (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
									Failed loading classes, try reloading the page
								</Text>
							</Center>
						) : classesData?.classes.length ? (
							<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
								<Button
									m="1"
									size="sm"
									colorScheme="primary"
									isLoading={fetching}
									onClick={handleWhitelistStudent}
									disabled={selectedStudentIds.length == 0}
								>
									Whitelist
								</Button>

								<HStack spacing={0}>
									<Select
										bg="white.500"
										roundedStart="xl"
										roundedEnd={schoolClass && schoolClass.sections?.length ? "none" : "xl"}
										border="none"
										_focus={{ border: "none" }}
										w="full"
										maxW="xs"
										placeholder="All"
										value={JSON.stringify(schoolClass)}
										onChange={(e) => {
											setSchoolClass(e.target.value ? JSON.parse(e.target.value) : undefined)
										}}
									>
										{classesData?.classes.map((c) => (
											<option key={c._id} value={JSON.stringify(c)}>
												{c.name}
											</option>
										))}
									</Select>
									{schoolClass && schoolClass.sections?.length && (
										<Select
											bg="white.500"
											roundedStart="none"
											roundedEnd="xl"
											border="none"
											_focus={{ border: "none" }}
											w="full"
											maxW="xs"
											placeholder="All"
											value={JSON.stringify(classSection)}
											onChange={(e) => {
												setClassSection(e.target.value ? JSON.parse(e.target.value) : undefined)
											}}
										>
											{schoolClass.sections.map((s) => (
												<option key={s.name} value={JSON.stringify(s)}>
													{s.name}
												</option>
											))}
										</Select>
									)}
								</HStack>

								<InputGroup maxW="xs" bg="white.500" rounded="xl" py="1">
									<Input
										placeholder="Search students"
										border="none"
										_focus={{ border: "none" }}
										onChange={(e) => setSearchKeyword(e.target.value)}
									/>
									<InputRightAddon bg="transparent" border="none">
										<Icon as={(props: any) => <FontAwesomeIcon icon={faSearch} {...props} />} color="text.100" fontSize="lg" />
									</InputRightAddon>
								</InputGroup>
							</Stack>
						) : (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any classes.
								</Text>
							</Center>
						)}
					</Stack>

					<VStack w="full" align="center">
						{fetching ? (
							<Center py="4">
								<Spinner color="text.400" />
							</Center>
						) : error ? (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
									Failed loading students, try reloading the page
								</Text>
							</Center>
						) : data?.notWhitelistedStudents && data?.notWhitelistedStudents.length > 0 ? (
							<StudentsSelector
								students={data?.notWhitelistedStudents || []}
								selectStudentIds={selectedStudentIds}
								onChange={setSelectedStudentIds}
								removeHeading={true}
							/>
						) : (
							<Center py="4">
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any students.
								</Text>
							</Center>
						)}
					</VStack>
				</VStack>
			</HStack>
		</Container>
	)
}
