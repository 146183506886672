import {
	Button,
	Center,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	HStack,
	Icon,
	Input,
	Select,
	SlideFade,
	Spinner,
	Text,
	Textarea,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faArrowRight, faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CUIAutoComplete } from "chakra-ui-autocomplete"
import { FormikHelpers, useFormik } from "formik"
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { FeedAttachments } from "../feed/FeedAttachments"
import { UploadMediaButton } from "../upload"
import {
	CreateResourceMutationVariables,
	MediaTypes,
	TopicFragment,
	useClassesQuery,
	useCreateResourceMutation,
	useCreateSubTopicMutation,
	useCreateTopicMutation,
	UserTargets,
	useSubjectsWithoutPaginationQuery,
	useTopicsWithoutPaginationQuery,
} from "../../graphql"
import { useUpload } from "../../hooks"

const validationSchema = Yup.object({
	name: Yup.string().label("Name"),
	description: Yup.string().label("Description"),
	targets: Yup.array().of(Yup.string()).required().label("Targets"),
})

export const NewResourceForm: React.FC = () => {
	const [{ fetching }, createNew] = useCreateResourceMutation()

	const toast = useToast()

	const navigate = useNavigate()

	// const isMobile = useBreakpointValue({ base: true, sm: false })

	const [{ data: classesData, fetching: fetchingClasses, error: classesError }] = useClassesQuery()

	const [{ data: subjectData, fetching: fetchingSubjects, error: subjectError }] = useSubjectsWithoutPaginationQuery()

	const [{ data: topicData, fetching: fetchingTopics, error: topicError }] = useTopicsWithoutPaginationQuery()

	const [selectedTopic, setSelectedTopic] = useState<TopicFragment>()

	interface Item {
		label: string
		value: string
	}

	const [, createNewTopic] = useCreateTopicMutation()

	const handleCreateTopic = async (item: Item) => {
		const topicName = item.label
		const { error, data } = await createNewTopic({ topicName })

		if (error) {
			return toast({
				title: "Topic Error",
				description: error.message,
				status: "error",
			})
		}

		if (data?.createTopic.topic?._id) {
			formik.values.topicId = data?.createTopic.topic?._id
		}
	}

	const [, createNewSubTopic] = useCreateSubTopicMutation()

	const handleCreateSubTopic = async (item: Item) => {
		const subTopicName = item.label
		const { error, data } = await createNewSubTopic({ topicId: selectedTopic!._id, subTopic: subTopicName })

		if (error) {
			return toast({
				title: "Topic Error",
				description: error.message,
				status: "error",
			})
		}

		if (data?.createSubTopic.topic) {
			setSelectedTopic(data.createSubTopic.topic)
		}
	}

	function capitalizeFirstLetter(string: string) {
		return string.charAt(0).toUpperCase() + string.slice(1)
	}

	const { clearUpload, clearAllUploads, uploads } = useUpload()

	const handleSubmit = async (values: CreateResourceMutationVariables["input"], helpers: FormikHelpers<CreateResourceMutationVariables["input"]>) => {
		const attachments: CreateResourceMutationVariables["input"]["attachments"] = {}

		if (uploads?.length) {
			if (uploads[0].type === MediaTypes.Image) {
				attachments.pictures = uploads
					.filter(({ type, key, url, isUploaded }) => isUploaded && type === MediaTypes.Image && typeof key === "string" && typeof url === "string")
					.map(({ key, url }) => ({ key: key!, url: url! }))
			}

			if (uploads[0].type === MediaTypes.Video) {
				attachments.videos = uploads
					.filter(({ type, key, url, isUploaded }) => isUploaded && type === MediaTypes.Video && typeof key === "string" && typeof url === "string")
					.map(({ key, url }) => ({ key: key!, url: url! }))
			}

			if (uploads[0].type === MediaTypes.Pdf) {
				attachments.pdfs = uploads
					.filter(({ type, key, url, isUploaded }) => isUploaded && type === MediaTypes.Pdf && typeof key === "string" && typeof url === "string")
					.map(({ key, url }) => ({ key: key!, url: url! }))
			}
		}

		const { error, data } = await createNew({ input: { ...values, attachments } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createResource.errors) {
			return data?.createResource.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		helpers.setFieldValue("text", "")
		clearAllUploads()
		navigate("/resources")
	}

	const formik = useFormik<CreateResourceMutationVariables["input"]>({
		initialValues: { targets: [], name: "", description: "", classId: "", classSectionId: "", subjectId: "", topicId: "", subTopic: "" } as any,
		validationSchema,
		onSubmit: handleSubmit,
	})

	const allowedMediaTypes = useMemo(() => {
		if (uploads.length > 0) {
			return [uploads[0].type]
		}

		return [MediaTypes.Image, MediaTypes.Pdf, MediaTypes.Video]
	}, [uploads])

	useEffect(() => {
		if (formik.values.topicId == undefined) {
			formik.values.subTopic = undefined
		}
	}, [formik.values.topicId])

	return (
		<VStack w="full" maxW="md" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.targets && formik.touched.targets)}>
				<CUIAutoComplete
					label=""
					placeholder="Targets"
					disableCreateItem
					hideToggleButton
					selectedItems={Object.values(UserTargets)
						.map((target) => ({ label: target, value: target }))
						.filter((target) => formik.values.targets?.includes(target.value))}
					items={Object.values(UserTargets).map((target) => ({
						label: target,
						value: target,
					}))}
					onSelectedItemsChange={(changes) =>
						formik.setFieldValue(
							"targets",
							changes.selectedItems?.map(({ value }) => value)
						)
					}
					inputStyleProps={{
						border: "none",
						_focus: { border: "none" },
						maxW: "md",
						bg: "white.500",
						rounded: "xl",
						py: "1",
					}}
					listStyleProps={{
						maxW: "md",
						bg: "whiteAlpha.400",
						backdropFilter: "blur(4px)",
					}}
				/>
				<FormErrorMessage>{formik.errors.targets}</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)} isRequired>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="name" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.description && formik.touched.description)} isRequired>
				<SlideFade in={Boolean(formik.values.description)} unmountOnExit>
					<FormLabel htmlFor="description" fontSize="sm">
						Description
					</FormLabel>
				</SlideFade>
				<Textarea
					placeholder="Description"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("description")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.description}</Text>
				</FormErrorMessage>
			</FormControl>

			{fetchingClasses ? (
				<Center w="full" py="1">
					<VStack>
						<Spinner size="sm" color="text.400" />
						<Text fontSize="xs" color="text.400">
							Loading classes
						</Text>
					</VStack>
				</Center>
			) : classesError ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							{classesError.message.replace("[GraphQL] ", "")}
						</Text>
					</VStack>
				</Center>
			) : (
				<HStack w="full" spacing={0} align="flex-end">
					<FormControl isInvalid={Boolean(formik.errors.classId && formik.touched.classId)} isRequired>
						<SlideFade in={Boolean(formik.values.classId)} unmountOnExit>
							<FormLabel htmlFor="classId" fontSize="sm">
								Class
							</FormLabel>
						</SlideFade>
						<Select
							placeholder="Select Class"
							border="none"
							_focus={{
								border: "none",
								borderRight: ".5px solid",
								borderRightColor: "blackAlpha.200",
							}}
							borderRight=".5px solid"
							borderRightColor="blackAlpha.200"
							bg="white.500"
							roundedLeft="xl"
							roundedRight={formik.values.classId ? "none" : "xl"}
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("classId")}
						>
							{classesData?.classes.map(({ _id, name }) => (
								<option key={_id} value={_id}>
									{name}
								</option>
							))}
						</Select>
						<FormErrorMessage>{formik.errors.classId}</FormErrorMessage>
					</FormControl>

					<FormControl
						isInvalid={Boolean(formik.errors.classSectionId && formik.touched.classSectionId)}
						display={formik.values.classId ? "block" : "none"}
						isRequired={false}
					>
						<SlideFade in={Boolean(formik.values.classSectionId)} unmountOnExit>
							<FormLabel htmlFor="classSectionId" fontSize="sm">
								Section
							</FormLabel>
						</SlideFade>
						<Select
							placeholder="All"
							border="none"
							_focus={{
								border: "none",
								borderLeft: ".5px solid",
								borderLeftColor: "blackAlpha.200",
							}}
							borderLeft=".5px solid"
							borderLeftColor="blackAlpha.200"
							bg="white.500"
							roundedRight="xl"
							roundedLeft="none"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("classSectionId")}
						>
							{classesData?.classes
								?.find((_class) => _class._id === formik.values.classId)
								?.sections?.map((section) => (
									<option key={section.name} value={section._id}>
										{section.name}
									</option>
								))}
						</Select>
						<FormErrorMessage>{formik.errors.classSectionId}</FormErrorMessage>
					</FormControl>
				</HStack>
			)}

			{fetchingSubjects ? (
				<Center w="full" py="1">
					<VStack>
						<Spinner size="sm" color="text.400" />
						<Text fontSize="xs" color="text.400">
							Loading subjects
						</Text>
					</VStack>
				</Center>
			) : subjectError ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							{subjectError.message.replace("[GraphQL] ", "")}
						</Text>
					</VStack>
				</Center>
			) : (
				<HStack w="full" spacing={0} align="flex-end">
					<FormControl isInvalid={Boolean(formik.errors.subjectId && formik.touched.subjectId)} isRequired>
						<SlideFade in={Boolean(formik.values.subjectId)} unmountOnExit>
							<FormLabel htmlFor="subjectId" fontSize="sm">
								Subject
							</FormLabel>
						</SlideFade>
						<Select
							placeholder="Select Subject"
							border="none"
							_focus={{
								border: "none",
								borderRight: ".5px solid",
								borderRightColor: "blackAlpha.200",
							}}
							borderRight=".5px solid"
							borderRightColor="blackAlpha.200"
							bg="white.500"
							roundedLeft="xl"
							roundedRight={formik.values.subjectId ? "none" : "xl"}
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("subjectId")}
						>
							{subjectData?.subjectsWithoutPagination.map(({ _id, name }) => (
								<option key={_id} value={_id}>
									{name}
								</option>
							))}
						</Select>
						<FormErrorMessage>{formik.errors.subjectId}</FormErrorMessage>
					</FormControl>
				</HStack>
			)}

			{fetchingTopics ? (
				<Center w="full" py="1">
					<VStack>
						<Spinner size="sm" color="text.400" />
						<Text fontSize="xs" color="text.400">
							Loading topics
						</Text>
					</VStack>
				</Center>
			) : topicError ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							{topicError.message.replace("[GraphQL] ", "")}
						</Text>
					</VStack>
				</Center>
			) : (
				<HStack w="full" spacing={1} align="center">
					<FormControl w="full" isInvalid={Boolean(formik.errors.topicId && formik.touched.topicId)}>
						<SlideFade in={Boolean(formik.values.topicId)} unmountOnExit>
							<FormLabel htmlFor="topicId" fontSize="sm">
								Topic
							</FormLabel>
						</SlideFade>
						<CUIAutoComplete
							label=""
							placeholder="Search Topic"
							hideToggleButton
							items={
								topicData?.topicsWithoutPagination.map(({ _id, name }) => ({
									label: name,
									value: _id,
								})) || []
							}
							selectedItems={topicData?.topicsWithoutPagination
								.map(({ _id, name }) => ({ label: capitalizeFirstLetter(name), value: _id }))
								.filter((target) => formik.values.topicId?.includes(target.value))}
							onSelectedItemsChange={(changes) => {
								const topicId = changes.selectedItems?.slice(0, 1).map(({ value }) => value)
								formik.setFieldValue("topicId", topicId?.[0])

								const topic = topicData?.topicsWithoutPagination.find((t) => t._id === topicId?.[0])

								if (topic) setSelectedTopic(topic)
							}}
							onCreateItem={handleCreateTopic}
							inputStyleProps={{
								border: "none",
								_focus: { border: "none" },

								maxW: "md",
								bg: "white.500",
								rounded: "xl",
								py: "1",
							}}
							listStyleProps={{
								maxW: "md",
								bg: "whiteAlpha.400",
								backdropFilter: "blur(4px)",
								textTransform: "capitalize",
							}}
							labelStyleProps={{ display: "none" }}
						/>
						<FormErrorMessage>{formik.errors.topicId}</FormErrorMessage>
					</FormControl>
				</HStack>
			)}

			{selectedTopic && (
				<HStack w="full" spacing={1} align="center" display={formik.values.topicId ? "block" : "none"}>
					<FormControl w="full" isInvalid={Boolean(formik.errors.topicId && formik.touched.topicId)} isRequired={false}>
						<SlideFade in={Boolean(formik.values.topicId)} unmountOnExit>
							<FormLabel htmlFor="subTopic" fontSize="sm">
								Sub Topic
							</FormLabel>
						</SlideFade>
						<CUIAutoComplete
							label=""
							placeholder="Search Sub Topic"
							hideToggleButton
							items={
								selectedTopic.subTopics?.map((item) => ({
									label: item,
									value: item,
								})) || []
							}
							selectedItems={
								selectedTopic.subTopics
									?.map((item: string) => ({ label: capitalizeFirstLetter(item), value: item }))
									.filter((target) => formik.values.subTopic?.includes(target.value)) || []
							}
							onSelectedItemsChange={(changes) =>
								formik.setFieldValue("subTopic", changes.selectedItems?.slice(0, 1).map(({ value }) => value)[0])
							}
							onCreateItem={handleCreateSubTopic}
							inputStyleProps={{
								border: "none",
								_focus: { border: "none" },

								maxW: "md",
								bg: "white.500",
								rounded: "xl",
								py: "1",
							}}
							listStyleProps={{
								maxW: "md",
								bg: "whiteAlpha.400",
								backdropFilter: "blur(4px)",
								textTransform: "capitalize",
							}}
							labelStyleProps={{ display: "none" }}
						/>
						<FormErrorMessage>{formik.errors.subTopic}</FormErrorMessage>
					</FormControl>
				</HStack>
			)}

			<VStack w="full" maxW="lg" align="flex-start" rounded="xl" py={2} px={4} spacing={0}>
				<FeedAttachments onRemove={(id) => clearUpload({ id })} />
				<HStack w="full" justify="space-between">
					<UploadMediaButton maxUploads={4} allowedTypes={allowedMediaTypes}>
						<Button iconSpacing={0} leftIcon={<UploadMediaButton />}>
							Attachments
						</Button>
					</UploadMediaButton>

					<Button
						type="submit"
						colorScheme="primary"
						size="md"
						rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} />}
						isDisabled={!uploads?.length}
						isLoading={fetching}
					>
						Create
					</Button>
				</HStack>
			</VStack>
		</VStack>
	)
}
