import {
	Button,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	HStack,
	Icon,
	IconButton,
	Input,
	SlideFade,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { ArrowRight, Pause, Play } from "react-feather"
import * as Yup from "yup"
import { UpdateCafeteriaApprovedMessageMutationVariables, useUpdateCafeteriaApprovedMessageMutation } from "../../../graphql"

const validationSchema = Yup.object({
	message: Yup.string().required().label("Message"),
})

type UpdateCafeteriaApprovedMessageFormProps = {
	message: string
	audioUrl?: string
}

export const UpdateCafeteriaApprovedMessageForm: React.FC<UpdateCafeteriaApprovedMessageFormProps> = ({ message, audioUrl }) => {
	const [{ fetching }, update] = useUpdateCafeteriaApprovedMessageMutation()

	const toast = useToast()

	const handleSubmit = async ({ message }: UpdateCafeteriaApprovedMessageMutationVariables) => {
		const { error } = await update({ message })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}
	}

	const formik = useFormik<UpdateCafeteriaApprovedMessageMutationVariables>({
		initialValues: { message },
		validationSchema,
		onSubmit: handleSubmit,
	})

	const audioRef = useRef<HTMLAudioElement>()

	const play = useCallback(() => audioRef.current?.play(), [audioRef.current])
	const pause = useCallback(() => audioRef.current?.pause(), [audioRef.current])

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current.onplay = () => {
				setIsAudioPlaying(true)
			}

			audioRef.current.onpause = () => {
				setIsAudioPlaying(false)
			}
		}
	}, [audioRef.current])

	const [isAudioPlaying, setIsAudioPlaying] = useState(false)

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.message && formik.touched.message)}>
				<SlideFade in={Boolean(formik.values.message)} unmountOnExit>
					<FormLabel htmlFor="title" fontSize="sm">
						Message
					</FormLabel>
				</SlideFade>
				<HStack>
					<Input
						placeholder="Message"
						border="none"
						_focus={{ border: "none" }}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						autoComplete="off"
						{...formik.getFieldProps("message")}
					/>
					{audioUrl && formik.values.message.trim().toLowerCase() === message.trim().toLowerCase() && (
						<IconButton
							aria-label="tts-play-btn"
							icon={isAudioPlaying ? <Icon as={Pause} /> : <Icon as={Play} />}
							rounded="full"
							colorScheme="primary"
							size="sm"
							onClick={isAudioPlaying ? pause : play}
						/>
					)}
				</HStack>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.message}</Text>
				</FormErrorMessage>
			</FormControl>

			{audioUrl && formik.values.message.trim().toLowerCase() === message.trim().toLowerCase() && <audio ref={audioRef as any} src={audioUrl} />}

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Save
			</Button>
		</VStack>
	)
}
