import { Center, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { SearchTopicQueryVariables, useSearchTopicQuery } from "../../graphql"
import { TopicsTable } from "./TopicsTable"

export type SearchResultTopicsProps = {
	searchKeyword: string
}

export const SearchResultTopics: React.FC<SearchResultTopicsProps> = ({ searchKeyword }) => {
	const [pagination, setPagination] = useState<SearchTopicQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useSearchTopicQuery({
		variables: { keyword: searchKeyword, pagination },
	})

	const prevPage = () => {
		if (data?.searchTopic.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.searchTopic.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="md" fontWeight="normal" color="text.500">
				Showing topics for search keyword <strong>&quot;{searchKeyword}&quot;</strong>
			</Heading>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.searchTopic.topics && data?.searchTopic.topics.length > 0 ? (
				<TopicsTable
					topics={data.searchTopic.topics}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data?.searchTopic.hasPrevPage}
					hasNextPage={data?.searchTopic.hasNextPage}
					currentPage={data?.searchTopic.currentPage}
					totalPages={data?.searchTopic.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any topics.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
