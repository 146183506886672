import { AspectRatio, Box, Button, ButtonGroup, Flex, Heading, Icon, Text, useDisclosure, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { ExtendedStudentFragment, StudentFragment } from "../../graphql"
import { usePagination } from "../../hooks"
import { Card } from "../common"
import { StudentTag } from "../student"
import { RewardStudentsModal } from "./RewardStudentsModal"
import { contrastColor } from "contrast-color"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export type StudentsSelectorProps = {
	students: ExtendedStudentFragment[]
	selectStudentIds: string[]
	onChange: (studentIds: string[]) => void
}

export const StudentsSelector: React.FC<StudentsSelectorProps> = ({ students, selectStudentIds, onChange }) => {
	const isAllSelected = useMemo(
		() => Boolean(selectStudentIds.length) && students.map(({ _id }) => _id).every((id) => selectStudentIds.includes(id)),
		[selectStudentIds, students]
	)

	const selectStudent = (studentId: string) => {
		const _selectStudentIds = new Set(selectStudentIds)
		_selectStudentIds.add(studentId)

		onChange(Array.from(_selectStudentIds))
	}

	const deselectStudent = (studentId: string) => {
		const _selectStudentIds = [...selectStudentIds]

		_selectStudentIds.splice(_selectStudentIds.indexOf(studentId), 1)

		onChange(_selectStudentIds)
	}

	const selectAllStudents = () => {
		onChange(students.map(({ _id }) => _id) ?? [])
	}

	const deselectAllStudents = () => {
		onChange([])
	}

	const isStudentSelected = useCallback((studentId: string) => selectStudentIds.includes(studentId), [selectStudentIds])

	const selectedStudents = useMemo(
		() => selectStudentIds.map((id) => students.find((s) => s._id === id)).filter(Boolean) as StudentFragment[],
		[selectStudentIds, students]
	)

	useEffect(() => {
		onChange(selectStudentIds)
	}, [selectStudentIds])

	const { currentItems, loadMore, hasMore } = usePagination<ExtendedStudentFragment>(students, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	const { getButtonProps, isOpen, onClose } = useDisclosure()

	return (
		<>
			<VStack w="full" align="stretch">
				<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
					Select the students you want to give reward
				</Heading>
				<ButtonGroup>
					{isAllSelected ? <Button onClick={deselectAllStudents}>Deselect All</Button> : <Button onClick={selectAllStudents}>Select All</Button>}

					<Button colorScheme="purple" isDisabled={!selectedStudents.length} {...getButtonProps()}>
						Reward
					</Button>
				</ButtonGroup>
				<Flex w="full" flexWrap="wrap" overflow="auto" py="2">
					{currentItems.map((student) => (
						<Card
							key={student._id}
							m={2}
							onClick={isStudentSelected(student._id) ? () => deselectStudent(student._id) : () => selectStudent(student._id)}
							bgColor={isStudentSelected(student._id) ? "green.50" : "white.200"}
							borderColor={student.house?.colorHex || "gray.500"}
							borderTopWidth={1}
							borderBottomWidth={6}
						>
							<AspectRatio w="full" maxW="6" ratio={1} pos="absolute" top="-1" right="-1">
								<Box p="1" bgColor={student.house?.colorHex || "gray.500"} rounded="md">
									<Text fontSize="xs" fontWeight="bold" color={contrastColor({ bgColor: student.house?.colorHex || "gray.500" })}>
										{student.pointsBalance || 0}
									</Text>
								</Box>
							</AspectRatio>
							{isStudentSelected(student._id) && (
								<>
									<Box
										pos="absolute"
										top="-0.5"
										left="-0.5"
										w=".8em"
										h=".8em"
										bgColor={contrastColor({ bgColor: student.house?.colorHex || "gray.500" })}
										rounded="full"
									/>
									<Icon
										pos="absolute"
										top="-1"
										left="-1"
										color={student.house?.colorHex || "gray.500"}
										fontSize="lg"
										as={(props: any) => <FontAwesomeIcon icon={faCheckCircle} {...props} />}
									/>
								</>
							)}
							<VStack w="full" align="stretch">
								<VStack spacing={0}>
									<StudentTag student={student} direction="column" isLinkable={false} showFirstName />
								</VStack>
								<Text fontSize="xs" fontWeight="medium" color="blackAlpha.600" textAlign="center">
									{student.house?.name}
								</Text>
							</VStack>
						</Card>
					))}

					{hasMore && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
				</Flex>
			</VStack>
			<RewardStudentsModal isOpen={isOpen} onClose={onClose} students={selectedStudents} />
		</>
	)
}
