import { Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useNavigate } from "react-router-dom"
import { ExtendedClassFragment, useRemoveClassMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type RemoveClassButtonProps = {
	schoolClass: ExtendedClassFragment
}

export const RemoveClassButton: React.FC<RemoveClassButtonProps> = ({ schoolClass: _class }) => {
	const [{ fetching }, removeClass] = useRemoveClassMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleRemoveClass = async () => {
		const { error } = await removeClass({ classId: _class._id })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		return navigate("/classes", { replace: true })
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<Tooltip label={`Remove class ${_class.name}`} fontSize="sm">
				<IconButton aria-label="remove-class-btn" variant="ghost" colorScheme="blackAlpha" size="sm" {...getButtonProps()}>
					<Icon as={(props: any) => <FontAwesomeIcon icon={faTrash} {...props} />} />
				</IconButton>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Remove Class"
				message="Removing this class may affect students and/or teachers associated with this class."
				confirmLabel="Remove"
				onConfirm={handleRemoveClass}
				isLoading={fetching}
			/>
		</>
	)
}
