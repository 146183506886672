/* eslint-disable @typescript-eslint/indent */
import {
	Badge,
	Button,
	Center,
	Container,
	HStack,
	Heading,
	Icon,
	Link,
	Spinner,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	VStack,
	useBreakpointValue,
} from "@chakra-ui/react"
import { faFileDownload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { DashboardHeader, Nav } from "../../../../components"
import { GRADIENT } from "../../../../constants"
import { ReportStatus, useCafeteriaPaymentsReportByIdQuery, useCafeteriaPaymentsReportUpdateSubscription } from "../../../../graphql"
import { useDrawer } from "../../../../hooks"

export const CafeteriaPaymentsReport: React.FC = () => {
	const { reportId = "" } = useParams<{ reportId: string }>()

	const [{ data, fetching, error }] = useCafeteriaPaymentsReportByIdQuery({
		variables: { reportId },
		pause: !reportId,
	})

	useCafeteriaPaymentsReportUpdateSubscription({ variables: { reportId } })

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [reportJSONFetching, setReportJSONFetching] = useState(false)
	const [reportJSONError, setReportJSONError] = useState("")
	const [reportJSON, setReportJSON] = useState<Record<string, string>[]>()

	useEffect(() => {
		if (data?.cafeteriaPaymentsReportById.json?.url) {
			setReportJSONFetching(true)
			fetch(data.cafeteriaPaymentsReportById.json.url)
				.then((o) => o.json())
				.then(setReportJSON)
				.catch((err: any) => setReportJSONError(err.message))
				.finally(() => setReportJSONFetching(false))
		}
	}, [data?.cafeteriaPaymentsReportById.json?.url])

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.cafeteriaPaymentsReportById ? (
						<VStack w="full" align="flex-start" spacing="4">
							<HStack w="full" py="4" align="flex-start" justify="space-between">
								<VStack align="flex-start">
									<HStack>
										<Heading as="h1" fontSize="xl" color="text.500">
											{data.cafeteriaPaymentsReportById.name}
										</Heading>
										<Badge
											colorScheme={
												data.cafeteriaPaymentsReportById.status === ReportStatus.Generated
													? "green"
													: data.cafeteriaPaymentsReportById.status === ReportStatus.Failed
													? "red"
													: "yellow"
											}
										>
											{data.cafeteriaPaymentsReportById.status}
										</Badge>
										{data.cafeteriaPaymentsReportById.csv?.url && (
											<Button
												as={Link}
												href={data.cafeteriaPaymentsReportById.csv.url}
												size="xs"
												rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faFileDownload} {...props} />} />}
											>
												Download as CSV
											</Button>
										)}
									</HStack>

									<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
										Created {format(new Date(data.cafeteriaPaymentsReportById.createdAt), "PPPp")}
									</Heading>
								</VStack>
							</HStack>
							{data.cafeteriaPaymentsReportById.status === ReportStatus.Generated &&
								(reportJSONFetching ? (
									<Center w="full" py="4">
										<Spinner color="text.400" />
									</Center>
								) : reportJSONError ? (
									<Center w="full" py="4">
										<Text fontSize="md" fontWeight="semibold" color="text.400">
											{reportJSONError}
										</Text>
									</Center>
								) : reportJSON ? (
									<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
										<Thead>
											<Tr>
												<Th textTransform="capitalize">Student</Th>
												<Th textTransform="capitalize">Merchant</Th>
												<Th textTransform="capitalize">Meal Type</Th>
												<Th textTransform="capitalize">Status</Th>
												<Th textTransform="capitalize">Registration</Th>
												<Th textTransform="capitalize">Timestamp</Th>
											</Tr>
										</Thead>
										<Tbody>
											{reportJSON.map((json, i) => (
												<Tr key={i}>
													<Td>{json["Student"]}</Td>
													<Td>{json["Merchant"]}</Td>
													<Td>{json["Meal Type"]}</Td>
													<Td>{json["Status"]}</Td>
													<Td>{json["Registration"]}</Td>
													<Td>{json["Timestamp"]}</Td>
												</Tr>
											))}
										</Tbody>
									</Table>
								) : (
									<Center w="full" py="4">
										<Text fontSize="md" fontWeight="semibold" color="text.400">
											Report could not be found
										</Text>
									</Center>
								))}
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the report
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
