import { VStack, HStack, Icon, Text, chakra } from "@chakra-ui/react"
import { faStar, faStarHalf } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

export type RatingProps = {
	rating: number
	total: number
	variant?: "complete" | "subtle"
}

export const Rating: React.FC<RatingProps> = ({ rating, total, variant = "complete" }) => {
	return (
		<VStack py="2" align="stretch" pos="relative">
			<HStack spacing={0.5}>
				<Icon as={(props: any) => <FontAwesomeIcon icon={faStar} {...props} />} fontSize="sm" color="gray.300" />
				<Icon as={(props: any) => <FontAwesomeIcon icon={faStar} {...props} />} fontSize="sm" color="gray.300" />
				<Icon as={(props: any) => <FontAwesomeIcon icon={faStar} {...props} />} fontSize="sm" color="gray.300" />
				<Icon as={(props: any) => <FontAwesomeIcon icon={faStar} {...props} />} fontSize="sm" color="gray.300" />
				<Icon as={(props: any) => <FontAwesomeIcon icon={faStar} {...props} />} fontSize="sm" color="gray.300" />
			</HStack>
			<HStack spacing={1} pos="absolute" top="0">
				{Array(Math.ceil(rating))
					.fill(0)
					.map((_, index, array) => (
						<Icon
							key={index}
							as={(props: any) => (
								<FontAwesomeIcon icon={index === array.length - 1 && rating.toString().includes(".") ? faStarHalf : faStar} {...props} />
							)}
							color="#D1D100"
						/>
					))}
			</HStack>
			{variant === "complete" && (
				<Text fontSize="xs" color="blackAlpha.600">
					<strong>
						<chakra.span color="purple.500" fontSize="sm">
							{rating}
						</chakra.span>
						/5
					</strong>{" "}
					({total} ratings)
				</Text>
			)}
		</VStack>
	)
}
