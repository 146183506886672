/* eslint-disable @typescript-eslint/indent */
import format from "date-fns/format"
import { LabelledStaffAttendancesFragment } from "../graphql"

export const parseStaffAttendanceReportAsCSV = (labelledAttendances: LabelledStaffAttendancesFragment[]) => {
	let data = "Date, Staff, In, Out, Status"

	labelledAttendances?.forEach(({ label, attendances }) => {
		attendances.forEach((attendance) => {
			data = data.concat("\n")
			data = data.concat(
				`${label}, ${attendance.staff.name} @${attendance.staff.username}, ${attendance.isAbsent ? "-" : format(new Date(attendance.inAt), "p")}, ${
					attendance.isAbsent ? "-" : attendance.isFinal ? format(new Date(attendance.outAt), "p") : "-"
				}, ${
					attendance.isAbsent
						? "-"
						: attendance.isLate === null
						? ""
						: attendance.isLate
						? `late ${attendance.lateByMinutes && attendance.lateByMinutes >= 0 ? `by ${attendance.lateByMinutes || 0} minutes` : ""}`
						: "on time"
				}`
			)
		})
	})

	return data
}
