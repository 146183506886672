import { Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useNavigate } from "react-router-dom"
import { CafeteriaRegistrationLogFragment, useDeleteManualCafeteriaRegistrationLogMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type DeleteManualCafeteriaRegistrationLogButtonProps = {
	cafeteriaRegistrationLog: CafeteriaRegistrationLogFragment
}

export const DeleteManualCafeteriaRegistrationLogButton: React.FC<DeleteManualCafeteriaRegistrationLogButtonProps> = ({ cafeteriaRegistrationLog }) => {
	const [{ fetching }, deleteManualCafeteriaLog] = useDeleteManualCafeteriaRegistrationLogMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleDelete = async () => {
		const { error } = await deleteManualCafeteriaLog({ cafeteriaRegistrationLogId: cafeteriaRegistrationLog._id })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		return navigate("/cafeteria/registrations", { replace: true })
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<Tooltip label="Delete cafeteria registration" fontSize="sm">
				<IconButton aria-label="delete-cafelog-btn" variant="ghost" colorScheme="blackAlpha" size="sm" {...getButtonProps()}>
					<Icon as={(props: any) => <FontAwesomeIcon icon={faTrash} {...props} />} />
				</IconButton>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Delete Cafeteria Registration"
				message="You cannot undo this action after deleting this cafeteria registration."
				confirmLabel="Delete"
				onConfirm={handleDelete}
				isLoading={fetching}
			/>
		</>
	)
}
