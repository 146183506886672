import { Button, FormControl, FormErrorMessage, FormLabel, Icon, Input, useToast, VStack } from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { CreateClassSectionMutationVariables, useCreateClassSectionMutation } from "../../graphql"

const validationSchema = Yup.object({
	name: Yup.string().required().label("Name"),
})

export type NewClassSectionFormProps = {
	classId: string
}

export const NewClassSectionForm: React.FC<NewClassSectionFormProps> = ({ classId }) => {
	const [{ fetching }, createNew] = useCreateClassSectionMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (
		{ name }: Omit<CreateClassSectionMutationVariables["input"], "classId">,
		helpers: FormikHelpers<Omit<CreateClassSectionMutationVariables["input"], "classId">>
	) => {
		const { error, data } = await createNew({ input: { classId, name } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		if (data?.createClassSection.errors) {
			return data?.createClassSection.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		if (!data?.createClassSection.classSection) {
			return toast({
				description: "Could not create the class section",
				status: "error",
			})
		}

		return navigate("/classes/" + data.createClassSection.classSection.classId)
	}

	const formik = useFormik<Omit<CreateClassSectionMutationVariables["input"], "classId">>({
		initialValues: {
			name: "",
		},
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<form onSubmit={formik.handleSubmit}>
			<VStack w="full" maxW="lg" align="stretch" spacing={4}>
				<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)}>
					<FormLabel>Name</FormLabel>
					<Input
						border="none"
						_focus={{ border: "none" }}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						autoComplete="off"
						placeholder="Enter class section name"
						{...formik.getFieldProps("name")}
					/>
					<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
				</FormControl>

				<Button size="sm" type="submit" colorScheme="primary" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
					Add
				</Button>
			</VStack>
		</form>
	)
}
