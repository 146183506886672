import { Center, Heading, VStack } from "@chakra-ui/react"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import random from "lodash.random"
import React, { useMemo } from "react"
import { Bar } from "react-chartjs-2"
import { useStudentsWithMostPointsQuery } from "../../graphql"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const TopStudents: React.FC = () => {
	const [{ data }] = useStudentsWithMostPointsQuery()

	const backgroundColor = useMemo(() => {
		const colors = ["#8676E5AA", "#D3C44DAA", "#CC2829AA", "#008F91AA", "#9E6F01AA"]

		const color1 = colors[random(0, colors.length - 1)]
		const color2 = colors.filter((color) => color !== color1)[random(0, colors.length - 2)]
		const color3 = colors.filter((color) => color !== color1 && color !== color2)[random(0, colors.length - 3)]
		const color4 = colors.filter((color) => color !== color1 && color !== color2 && color !== color3)[random(0, colors.length - 4)]
		const color5 = colors.filter((color) => color !== color1 && color !== color2 && color !== color3 && color !== color4)[0]

		return [color1, color2, color3, color4, color5]
	}, [])

	const labels = data?.studentsWithMostPoints.map((s) => s.name) || []

	const chartData = {
		labels,
		datasets: [
			{
				label: "Points",
				data: data?.studentsWithMostPoints.map((s) => s.pointsBalance || 0) || [],
				backgroundColor,
			},
		],
	}

	return (
		<VStack>
			<Heading fontSize="sm">Top Students</Heading>
			<Center w="full" maxW={{ base: "sm", md: "lg" }}>
				<Bar data={chartData} />
			</Center>
		</VStack>
	)
}
