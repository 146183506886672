import { Checkbox, Heading, Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { format } from "date-fns"
import React, { useCallback, useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { StudentTag } from "../../../components/student"
import { ExtendedStudentFragment } from "../../../graphql"
import { usePagination } from "../../../hooks"

export type StudentsSelectorProps = {
	students: ExtendedStudentFragment[]
	selectStudentId: string
	onChange: (studentId: string) => void
	removeHeading?: boolean
	hideTimestamps?: boolean
}

export const StudentsSelector: React.FC<StudentsSelectorProps> = ({ students, selectStudentId, onChange, removeHeading, hideTimestamps }) => {
	const selectStudent = (studentId: string) => {
		onChange(studentId)
	}

	const deselectStudent = () => {
		onChange("")
	}

	const isStudentSelected = useCallback((studentId: string) => selectStudentId.includes(studentId), [selectStudentId])

	useEffect(() => {
		onChange(selectStudentId)
	}, [selectStudentId])

	const { currentItems, loadMore, hasMore } = usePagination<ExtendedStudentFragment>(students, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
				{removeHeading ? "" : "Select the student"}
			</Heading>
			<VStack w="full" align="stretch" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th></Th>
							<Th textTransform="capitalize">Student</Th>
							<Th textTransform="capitalize">Class</Th>
							{!hideTimestamps && <Th textTransform="capitalize">Created</Th>}
							{!hideTimestamps && <Th textTransform="capitalize">Updated</Th>}
						</Tr>
					</Thead>
					<Tbody>
						{currentItems.map((student) => (
							<Tr key={student._id} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td>
									<Checkbox
										colorScheme="blackAlpha"
										borderColor="black"
										isChecked={isStudentSelected(student._id)}
										onChange={(e) => (e.target.checked ? selectStudent(student._id) : deselectStudent())}
									/>
								</Td>
								<Td>
									<StudentTag student={student} />
								</Td>
								<Td>
									{student.class.name} - {student.classSection.name}
								</Td>
								{!hideTimestamps && <Td>{format(new Date(student.createdAt), "PP")}</Td>}
								{!hideTimestamps && <Td>{format(new Date(student.updatedAt), "PP")}</Td>}
							</Tr>
						))}
					</Tbody>
				</Table>
				{hasMore && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
			</VStack>
		</VStack>
	)
}
