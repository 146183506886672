import { Button, Icon, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useDeactivateWorkshopSessionMutation } from "../../graphql"

import { ConfirmationAlertDialog } from "../common"

export type DeactivateWorkshopSessionButtonProps = {
	workshopSessionId: string
}

export const DeactivateWorkshopSessionButton: React.FC<DeactivateWorkshopSessionButtonProps> = ({ workshopSessionId }) => {
	const [{ fetching }, deactivateWorkshopSession] = useDeactivateWorkshopSessionMutation()

	const toast = useToast()

	const handleDeactivateWorkshopSession = async () => {
		const { error } = await deactivateWorkshopSession({ workshopSessionId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		onClose()
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<Tooltip label="Deactivate" fontSize="sm">
				<Button
					size="xs"
					colorScheme="red"
					rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faXmarkCircle} {...props} />} fontSize="sm" />}
					{...getButtonProps()}
				>
					Deactivate this session
				</Button>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Deactivate session"
				message="Are you sure you want to deactivate this session?"
				confirmLabel="Deactivate"
				onConfirm={handleDeactivateWorkshopSession}
				isLoading={fetching}
			/>
		</>
	)
}
