import {
	AspectRatio,
	Button,
	ButtonGroup,
	Center,
	Container,
	Heading,
	HStack,
	Icon,
	IconButton,
	Spinner,
	Text,
	Tooltip,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react"
import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { contrastColor } from "contrast-color"
import format from "date-fns/format"
import React from "react"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { DashboardHeader, Nav, RemoveHouseButton, RemoveHouseCaptainButton } from "../../components"
import { StudentTag } from "../../components/student"
import { GRADIENT } from "../../constants"
import { useHouseByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const House: React.FC = () => {
	const { houseId = "" } = useParams<{ houseId: string }>()

	const [{ data, fetching, error }] = useHouseByIdQuery({
		variables: { houseId },
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })
	const navigate = useNavigate()

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.houseById ? (
						<VStack w="full" align="flex-start" spacing="4">
							<HStack w="full" maxW="sm" align="flex-start" justify="space-between">
								<VStack w="full" align="flex-start">
									<HStack w="full" justify="space-between">
										<Heading as="h1" fontSize="xl" color="text.500">
											{data.houseById.name}
										</Heading>
										<ButtonGroup>
											<Tooltip label={`Edit house ${data.houseById.name}`} fontSize="sm">
												<IconButton
													as={NavLink}
													to="edit"
													aria-label="edit-house-btn"
													variant="ghost"
													colorScheme="blackAlpha"
													size="sm"
												>
													<Icon as={(props: any) => <FontAwesomeIcon icon={faPencil} {...props} />} />
												</IconButton>
											</Tooltip>

											<RemoveHouseButton house={data.houseById} />
										</ButtonGroup>
									</HStack>
									<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
										Created {format(new Date(data.houseById.createdAt), "PPPp")}
									</Heading>
								</VStack>
							</HStack>
							<AspectRatio w="full" maxW="sm" ratio={15 / 9}>
								<VStack
									w="full"
									h="full"
									align="flex-start !important"
									justify="space-between !important"
									bgColor={data.houseById.colorHex}
									rounded="xl"
									p="4"
									shadow="lg"
								>
									<Text fontSize="3xl" fontWeight="bold" color={contrastColor({ bgColor: data.houseById.colorHex })}>
										{data.houseById.name}
									</Text>
									{data.houseById.captain ? (
										<HStack spacing={4}>
											<VStack align="flex-start">
												<Heading fontSize="xs" fontWeight="semibold" color={contrastColor({ bgColor: data.houseById.colorHex })}>
													Captain
												</Heading>
												<StudentTag student={data.houseById.captain} />
											</VStack>
											<RemoveHouseCaptainButton houseId={data.houseById._id} captain={data.houseById.captain!} />
										</HStack>
									) : (
										<Button
											variant="link"
											color={contrastColor({ bgColor: data.houseById.colorHex })}
											size="sm"
											rightIcon={<FontAwesomeIcon icon={faPlus} />}
											onClick={() => navigate(`/houses/${data.houseById._id}/addCaptain`)}
										>
											Add Captain
										</Button>
									)}
								</VStack>
							</AspectRatio>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the house.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
