import { Center, Heading, HStack, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { StockTagsQueryVariables, useStockTagsQuery } from "../../graphql"
import { TagsTable } from "./TagsTable"

export const AllTags: React.FC = () => {
	const [pagination, setPagination] = useState<StockTagsQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useStockTagsQuery({
		variables: { pagination },
	})

	const prevPage = () => {
		if (data?.stockTags.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.stockTags.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" justify="space-between">
				<Heading as="h2" fontSize={isMobile ? "sm" : "md"} fontWeight="normal" color="text.500">
					Showing {data?.stockTags.total || "all"} tags
				</Heading>
			</HStack>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.stockTags.tags && data?.stockTags.tags.length > 0 ? (
				<TagsTable
					tags={data.stockTags.tags}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.stockTags.hasPrevPage}
					hasNextPage={data.stockTags.hasNextPage}
					currentPage={data.stockTags.currentPage}
					totalPages={data.stockTags.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any tags.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
