import { Center, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { AllAttendancesOfStaffQueryVariables, useAllAttendancesOfStaffQuery } from "../../graphql"
import { StaffAttendancesTable } from "./StaffAttendancesTable"

export type AllAttendancesOfStaffProps = {
	staffId: string
}

export const AllAttendancesOfStaff: React.FC<AllAttendancesOfStaffProps> = ({ staffId }) => {
	const [pagination, setPagination] = useState<AllAttendancesOfStaffQueryVariables["pagination"]>({ limit: 30, page: 1 })

	const [{ data, fetching }] = useAllAttendancesOfStaffQuery({
		variables: { staffId, pagination },
	})

	const prevPage = () => {
		if (data?.allAttendancesOfStaff.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.allAttendancesOfStaff.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
				Showing {data?.allAttendancesOfStaff.attendances?.length || 0} of {data?.allAttendancesOfStaff.total || "all"} attendances
			</Heading>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.allAttendancesOfStaff.attendances && data?.allAttendancesOfStaff.attendances.length > 0 ? (
				<StaffAttendancesTable
					attendances={data.allAttendancesOfStaff.attendances}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.allAttendancesOfStaff.hasPrevPage}
					hasNextPage={data.allAttendancesOfStaff.hasNextPage}
					currentPage={data.allAttendancesOfStaff.currentPage}
					totalPages={data.allAttendancesOfStaff.totalPages}
				/>
			) : (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="red.600">
						Attendances not available
					</Text>
				</Center>
			)}
		</VStack>
	)
}
