import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Badge,
	Button,
	Center,
	chakra,
	Checkbox,
	Divider,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	HStack,
	Icon,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Spinner,
	Text,
	Textarea,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isValid, startOfDay } from "date-fns"
import { useFormik } from "formik"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import DatePicker from "react-date-picker"
import { ArrowRight } from "react-feather"
import * as Yup from "yup"
import {
	AdmissionTypes,
	CafeteriaRegistrationSessionQuoteFragment,
	CreateAndFinishManualCafeteriaRegistrationMutationVariables,
	ExtendedStudentFragment,
	ManualCafeteriaRegistrationDetailsFragment,
	SchoolSession,
	useCreateAndFinishManualCafeteriaRegistrationMutation,
	useCurrentAndUpcomingSessionQuery,
	useManualCafeteriaRegistrationQuoteQuery,
} from "../../graphql"
import { getMonthForIndex } from "../../utils"

enum PaymentModes {
	NEFT = "NEFT",
	RTGS = "RTGS",
	Cash = "Cash",
	Other = "Other",
}

export type CreateManualCafeteriaRegistrationOrderModalProps = {
	isOpen: boolean
	onClose: () => void
	student: ExtendedStudentFragment
}

export const CreateManualCafeteriaRegistrationOrderModal: React.FC<CreateManualCafeteriaRegistrationOrderModalProps> = ({ isOpen, onClose, student }) => {
	const [{ data: sessionsData, fetching: sessionsFetching, error: sessionsError }] = useCurrentAndUpcomingSessionQuery({
		pause: student.admissionType === AdmissionTypes.Staff,
	})

	const [selectedSessionIds, setSelectedSessionIds] = useState<string[]>([])

	const selectSessionId = (sessionId: string) => {
		setSelectedSessionIds((prev) => [...prev, sessionId])
	}

	const deselectSessionId = (sessionId: string) => {
		setSelectedSessionIds((prev) => prev.filter((sId) => sId !== sessionId))
	}

	const isSessionSelected = useCallback((sessionId: string) => selectedSessionIds.includes(sessionId), [selectedSessionIds])

	const [{ data: quoteData }] = useManualCafeteriaRegistrationQuoteQuery({
		variables: { studentId: student._id, sessionIds: selectedSessionIds },
		pause: student.admissionType === AdmissionTypes.Staff,
	})

	const [, createAndFinishOrder] = useCreateAndFinishManualCafeteriaRegistrationMutation()

	const [error, setError] = useState("")
	const [isLoading, setIsLoading] = useState(false)

	const toast = useToast()

	const handlePayment = async (values: CreateAndFinishManualCafeteriaRegistrationMutationVariables["input"] & { otherPaymentMode?: string }) => {
		if (!quoteData) return

		setIsLoading(true)

		const input: CreateAndFinishManualCafeteriaRegistrationMutationVariables["input"] = {
			paymentDate: startOfDay(values.paymentDate),
			paymentReferenceNo: values.paymentReferenceNo,
			amountPaid: values.amountPaid,
			paymentMode: values.paymentMode === PaymentModes.Other.toString() && values.otherPaymentMode ? values.otherPaymentMode : values.paymentMode,
			validSince: startOfDay(values.validSince),
			remarks: values.remarks,
		}

		const { error, data } = await createAndFinishOrder({ studentId: student._id, quoteId: quoteData.manualCafeteriaRegistrationQuote._id, input })

		if (error) {
			setIsLoading(false)
			return setError(error.message)
		}

		if (!data?.createAndFinishManualCafeteriaRegistration) {
			setIsLoading(false)
			return setError("Something went wrong, try again")
		}

		toast({
			title: "Payment successfull",
			description: "Successfully registered for Cafeteria",
			status: "success",
		})

		setIsLoading(false)
		onClose()
		return
	}

	const sessionsWithQuote = useMemo(() => {
		if (!sessionsData) return

		return {
			currentSession: sessionsData.currentAndUpcomingSession.currentSession
				? ({
						...sessionsData.currentAndUpcomingSession.currentSession,
						quote: quoteData?.manualCafeteriaRegistrationQuote.sessionWiseQuotes.find(
							(q) => q.schoolSessionId === sessionsData.currentAndUpcomingSession.currentSession?._id
						),
				  } as SchoolSession & { quote?: CafeteriaRegistrationSessionQuoteFragment })
				: undefined,
			upcomingSession: sessionsData.currentAndUpcomingSession.upcomingSession
				? ({
						...sessionsData.currentAndUpcomingSession.upcomingSession,
						quote: quoteData?.manualCafeteriaRegistrationQuote.sessionWiseQuotes.find(
							(q) => q.schoolSessionId === sessionsData.currentAndUpcomingSession.upcomingSession?._id
						),
				  } as SchoolSession & { quote?: CafeteriaRegistrationSessionQuoteFragment })
				: undefined,
		}
	}, [JSON.stringify(sessionsData), JSON.stringify(quoteData)])

	const formik = useFormik<CreateAndFinishManualCafeteriaRegistrationMutationVariables["input"] & { otherPaymentMode?: string }>({
		initialValues: {
			paymentDate: new Date(),
			paymentReferenceNo: "",
			amountPaid: quoteData?.manualCafeteriaRegistrationQuote.totalAmount || 0,
			paymentMode: "",
			remarks: "",
		} as ManualCafeteriaRegistrationDetailsFragment,
		validationSchema: Yup.object({
			paymentDate: Yup.date().required().label("Payment Date"),
			paymentReferenceNo: Yup.string().min(4).required().label("Payment Reference Number"),
			amountPaid: Yup.number().min(0).required().label("Amount Paid"),
			paymentMode: Yup.string().required().label("Payment Mode"),
			otherPaymentMode: Yup.string().nullable().label("Other Payment Mode"),
			validSince: Yup.date().nullable().label("Valid Since"),
			remarks: Yup.string().min(5).required().label("Remarks"),
		}),
		onSubmit: handlePayment,
	})

	useEffect(() => {
		if (quoteData?.manualCafeteriaRegistrationQuote.totalAmount) {
			formik.setFieldValue("amountPaid", quoteData.manualCafeteriaRegistrationQuote.totalAmount)
		}
	}, [JSON.stringify(quoteData)])

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="md">
				<ModalOverlay>
					<ModalContent>
						<ModalHeader>Cafeteria Registration</ModalHeader>
						<ModalCloseButton />

						{student.admissionType === AdmissionTypes.Staff ? (
							<ModalBody as={VStack} w="full" align="stretch" px="8" pb="8">
								<Alert variant="subtle" status="info" flexDirection="column" rounded="xl">
									<AlertIcon />
									<AlertTitle>Not Applicable</AlertTitle>
									<AlertDescription fontSize="sm" textAlign="center">
										Cafeteria registration is not applicable for students with <strong>Staff</strong> admission type
									</AlertDescription>
								</Alert>
							</ModalBody>
						) : (
							<ModalBody as={VStack} w="full" align="stretch" px="8">
								{error && (
									<Alert variant="subtle" status="error" flexDirection="column" rounded="xl">
										<AlertIcon />
										<AlertTitle>Payment Failed</AlertTitle>
										<AlertDescription fontSize="sm" textAlign="center">
											{error}
										</AlertDescription>
									</Alert>
								)}

								{sessionsFetching ? (
									<Center w="full" py="4">
										<Spinner color="text.400" />
									</Center>
								) : sessionsError ? (
									<Center w="full" py="4">
										<Text fontSize="md" fontWeight="semisemibold" color="text.400">
											{sessionsError.message.replace("[GraphQL] ", "")}
										</Text>
									</Center>
								) : sessionsData?.currentAndUpcomingSession && sessionsWithQuote ? (
									<VStack w="full" align="stretch" spacing={12}>
										{sessionsWithQuote.currentSession && (
											<VStack>
												<VStack w="full" align="flex-end">
													{student.cafeteriaRegistration && (
														<Badge variant="solid" colorScheme="primary">
															<HStack>
																<Text>Already registered</Text>
																<Icon as={(props: any) => <FontAwesomeIcon icon={faCheckCircle} {...props} />} />
															</HStack>
														</Badge>
													)}
													<Checkbox
														w="full"
														display="flex"
														justifyContent="space-between"
														colorScheme="primary"
														isDisabled={!!student.cafeteriaRegistration}
														isChecked={isSessionSelected(sessionsWithQuote.currentSession._id)}
														onChange={(e) =>
															e.target.checked
																? selectSessionId(sessionsWithQuote.currentSession!._id)
																: deselectSessionId(sessionsWithQuote.currentSession!._id)
														}
													>
														<VStack spacing={0} align="flex-end">
															<Text fontSize="sm">
																{getMonthForIndex(sessionsWithQuote.currentSession.startMonth)}{" "}
																{sessionsWithQuote.currentSession.startDate.toString().padStart(2, "0")} -{" "}
																{getMonthForIndex(sessionsWithQuote.currentSession.endMonth)}{" "}
																{sessionsWithQuote.currentSession.endDate.toString().padStart(2, "0")}
															</Text>
															<HStack>
																<Text fontSize="md" fontWeight="semibold">
																	{sessionsWithQuote.currentSession.name}
																</Text>
																<Badge variant="solid" colorScheme="green">
																	Current
																</Badge>
															</HStack>
														</VStack>
													</Checkbox>
												</VStack>
												<Divider />
												{sessionsWithQuote.currentSession.quote && (
													<VStack w="full">
														<VStack w="full" spacing={4}>
															{sessionsWithQuote.currentSession.quote.breakup.map((item, index) => (
																<HStack key={index} w="full" justify="space-between">
																	<Text w="full" maxW="44" fontSize="sm">
																		{item.label}
																	</Text>
																	<Text fontWeight="semibold" color="primary.500">
																		{item.payable ? (
																			<chakra.span
																				textDecoration="line-through"
																				fontSize="smaller"
																				fontWeight="normal"
																				mr="2"
																			>
																				₹ {item.amount}
																			</chakra.span>
																		) : (
																			`₹ ${item.amount}`
																		)}{" "}
																		{item.payable ? `₹ ${item.payable}` : ""}
																	</Text>
																</HStack>
															))}
														</VStack>
														<Divider />

														<Text alignSelf="flex-end" fontSize="xl" color="primary.500" fontWeight="semibold">
															₹ {sessionsWithQuote.currentSession.quote.totalAmount.toFixed(2)}
														</Text>
													</VStack>
												)}
											</VStack>
										)}

										{sessionsWithQuote.upcomingSession && (
											<VStack>
												<VStack w="full" align="flex-end">
													{student.upcomingCafeteriaRegistration && (
														<Badge variant="solid" colorScheme="primary">
															<HStack>
																<Text>Already registered</Text>
																<Icon as={(props: any) => <FontAwesomeIcon icon={faCheckCircle} {...props} />} />
															</HStack>
														</Badge>
													)}
													<Checkbox
														w="full"
														display="flex"
														justifyContent="space-between"
														colorScheme="primary"
														isDisabled={!!student.upcomingCafeteriaRegistration}
														isChecked={isSessionSelected(sessionsWithQuote.upcomingSession._id)}
														onChange={(e) =>
															e.target.checked
																? selectSessionId(sessionsWithQuote.upcomingSession!._id)
																: deselectSessionId(sessionsWithQuote.upcomingSession!._id)
														}
													>
														<VStack spacing={0} align="flex-end">
															<Text fontSize="sm">
																{getMonthForIndex(sessionsWithQuote.upcomingSession.startMonth)}{" "}
																{sessionsWithQuote.upcomingSession.startDate.toString().padStart(2, "0")} -{" "}
																{getMonthForIndex(sessionsWithQuote.upcomingSession.endMonth)}{" "}
																{sessionsWithQuote.upcomingSession.endDate.toString().padStart(2, "0")}
															</Text>
															<HStack>
																<Text fontSize="md" fontWeight="semibold">
																	{sessionsWithQuote.upcomingSession.name}
																</Text>
																<Badge variant="solid" colorScheme="orange">
																	Upcoming
																</Badge>
															</HStack>
														</VStack>
													</Checkbox>
												</VStack>
												<Divider />
												{sessionsWithQuote.upcomingSession.quote && (
													<VStack w="full">
														<VStack w="full" spacing={4}>
															{sessionsWithQuote.upcomingSession.quote.breakup.map((item, index) => (
																<HStack key={index} w="full" justify="space-between">
																	<Text w="full" maxW="44" fontSize="sm">
																		{item.label}
																	</Text>
																	<Text fontWeight="semibold" color="primary.500">
																		{item.payable ? (
																			<chakra.span
																				textDecoration="line-through"
																				fontSize="smaller"
																				fontWeight="normal"
																				mr="2"
																			>
																				₹ {item.amount}
																			</chakra.span>
																		) : (
																			`₹ ${item.amount}`
																		)}{" "}
																		{item.payable ? `₹ ${item.payable}` : ""}
																	</Text>
																</HStack>
															))}
														</VStack>
														<Divider />
														<Text alignSelf="flex-end" fontSize="xl" color="primary.500" fontWeight="semibold">
															₹{sessionsWithQuote.upcomingSession.quote.totalAmount.toFixed(2)}
														</Text>
													</VStack>
												)}
											</VStack>
										)}
									</VStack>
								) : (
									<Center w="full" py="4">
										<Text fontSize="md" fontWeight="semisemibold" color="text.400">
											Couldn&apos;t find the quote for you.
										</Text>
									</Center>
								)}
							</ModalBody>
						)}

						{quoteData?.manualCafeteriaRegistrationQuote.sessionWiseQuotes.length ? (
							<ModalFooter w="full" as="form" onSubmit={formik.handleSubmit as any}>
								<VStack w="full" align="stretch" spacing="8">
									<VStack w="full" spacing="4">
										<HStack w="full">
											<FormControl flex={1} isInvalid={Boolean(formik.touched.paymentDate && formik.errors.paymentDate)} isRequired>
												<FormLabel htmlFor="paymentDate" fontSize="sm">
													Payment Date
												</FormLabel>

												<DatePicker
													onChange={(value: Date) => isValid(value) && formik.setFieldValue("paymentDate", value as Date)}
													value={formik.values.paymentDate}
													format="MMM dd, y"
													clearIcon={null}
												/>

												<FormErrorMessage>{formik.errors.paymentDate as string}</FormErrorMessage>
											</FormControl>

											{sessionsWithQuote?.currentSession && (
												<FormControl flex={1} isInvalid={Boolean(formik.touched.validSince && formik.errors.validSince)}>
													<FormLabel htmlFor="validSince" fontSize="sm">
														Effective From
													</FormLabel>

													<DatePicker
														onChange={(value: Date) =>
															isValid(value)
																? formik.setFieldValue("validSince", value as Date)
																: formik.setFieldValue("validSince", undefined)
														}
														value={formik.values.validSince}
														format="MMM dd, y"
													/>

													<FormErrorMessage>{formik.errors.validSince as string}</FormErrorMessage>
												</FormControl>
											)}
										</HStack>

										<FormControl isInvalid={Boolean(formik.errors.paymentReferenceNo && formik.touched.paymentReferenceNo)} isRequired>
											<FormLabel htmlFor="name" fontSize="sm">
												Payment Reference Number
											</FormLabel>

											<Input
												placeholder="Enter Payment Reference Number"
												border="none"
												_focus={{ border: "none" }}
												maxW="md"
												bg="white.500"
												rounded="xl"
												py="1"
												autoComplete="off"
												{...formik.getFieldProps("paymentReferenceNo")}
											/>
											<FormErrorMessage>
												<FormErrorIcon />
												<Text>{formik.errors.paymentReferenceNo}</Text>
											</FormErrorMessage>
										</FormControl>

										<FormControl isInvalid={Boolean(formik.errors.paymentMode && formik.touched.paymentMode)} isRequired>
											<FormLabel htmlFor="paymentMode" fontSize="sm">
												Payment Mode
											</FormLabel>

											<Select
												placeholder="Select Payment Mode"
												border="none"
												_focus={{ border: "none" }}
												maxW="md"
												bg="white.500"
												rounded="xl"
												py="1"
												{...formik.getFieldProps("paymentMode")}
											>
												{Object.values(PaymentModes).map((s) => (
													<option key={s} value={s}>
														{s}
													</option>
												))}
											</Select>
											<FormErrorMessage>
												<FormErrorIcon />
												<Text>{formik.errors.paymentMode}</Text>
											</FormErrorMessage>
										</FormControl>

										{formik.values.paymentMode === "Other" && (
											<FormControl isInvalid={Boolean(formik.errors.otherPaymentMode && formik.touched.otherPaymentMode)}>
												<Input
													placeholder="Enter Other Payment Mode"
													border="none"
													_focus={{ border: "none" }}
													maxW="md"
													bg="white.500"
													rounded="xl"
													py="1"
													autoComplete="off"
													{...formik.getFieldProps("otherPaymentMode")}
												/>
												<FormErrorMessage>
													<FormErrorIcon />
													<Text>{formik.errors.otherPaymentMode}</Text>
												</FormErrorMessage>
											</FormControl>
										)}

										<FormControl isInvalid={Boolean(formik.errors.amountPaid && formik.touched.amountPaid)} isRequired>
											<FormLabel htmlFor="amountPaid" fontSize="sm">
												Amount Paid
											</FormLabel>

											<Input
												placeholder="Enter Amount Paid"
												type="number"
												border="none"
												_focus={{ border: "none" }}
												maxW="md"
												bg="white.500"
												rounded="xl"
												py="1"
												autoComplete="off"
												{...formik.getFieldProps("amountPaid")}
											/>
											<FormErrorMessage>
												<FormErrorIcon />
												<Text>{formik.errors.amountPaid}</Text>
											</FormErrorMessage>
										</FormControl>

										<FormControl isInvalid={Boolean(formik.errors.remarks && formik.touched.remarks)} isRequired>
											<FormLabel htmlFor="remarks" fontSize="sm">
												Remarks
											</FormLabel>

											<Textarea
												placeholder="Enter remarks"
												border="none"
												_focus={{ border: "none" }}
												maxW="md"
												bg="white.500"
												rounded="xl"
												py="1"
												autoComplete="off"
												{...formik.getFieldProps("remarks")}
											/>
											<FormErrorMessage>
												<FormErrorIcon />
												<Text>{formik.errors.remarks}</Text>
											</FormErrorMessage>
										</FormControl>
									</VStack>

									<Button type="submit" colorScheme="green" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={isLoading}>
										Save
									</Button>
								</VStack>
							</ModalFooter>
						) : (
							<></>
						)}
					</ModalContent>
				</ModalOverlay>
			</Modal>
		</>
	)
}
