import { Badge, Flex, Heading, HStack, LinkBox, LinkOverlay, StackProps, Tag, Text, VStack } from "@chakra-ui/react"
import { motion, MotionProps } from "framer-motion"
import React from "react"
import { NavLink } from "react-router-dom"
import { SchoolTimingFragment } from "../../graphql"

const MotionVStack = motion<StackProps & MotionProps>(VStack)

export type SchoolTimingCardProps = {
	schoolTiming: SchoolTimingFragment
} & Omit<StackProps, "transition">

export const SchoolTimingCard: React.FC<SchoolTimingCardProps> = ({ schoolTiming, ...props }) => {
	return (
		<MotionVStack
			as={LinkBox}
			align="flex-start"
			bg="white.500"
			rounded="2xl"
			p={4}
			spacing={4}
			shadow="sm"
			whileHover={{ scale: 1.04 }}
			cursor="pointer"
			{...(props as any)}
		>
			<VStack w="full" align="flex-start">
				<HStack w="full" justify="space-between">
					<Heading fontSize="md" fontWeight="semibold">
						{schoolTiming.name}
					</Heading>
				</HStack>
				{schoolTiming.isDefault && (
					<Badge colorScheme="primary" rounded="sm">
						Default
					</Badge>
				)}
			</VStack>
			<VStack w="full" align="start" spacing={0}>
				<HStack>
					<Text fontSize="xs">Timing</Text>
				</HStack>
				<Text fontSize="xs" color="blackAlpha.700" fontWeight="semibold">
					{schoolTiming.attendanceTiming.inAt} - {schoolTiming.attendanceTiming.outAt}
				</Text>
			</VStack>
			<VStack w="full" align="start" spacing={0}>
				<HStack>
					<Text fontSize="xs">Working weekdays</Text>
				</HStack>
				<Flex flexWrap="wrap">
					{schoolTiming.workingWeekdays?.length ? (
						schoolTiming.workingWeekdays.map((day) => (
							<Tag key={day} m="0.5" size="sm" colorScheme="purple">
								{day}
							</Tag>
						))
					) : (
						<Text fontSize="xs" color="blackAlpha.700" fontWeight="semibold">
							Not set
						</Text>
					)}
				</Flex>
			</VStack>
			<LinkOverlay as={NavLink} to={`/schoolTimings/${schoolTiming._id}`} />
		</MotionVStack>
	)
}
