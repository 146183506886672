import { Button, Center, Container, Heading, HStack, Icon, LinkBox, LinkOverlay, Spinner, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React from "react"
import { ArrowRight } from "react-feather"
import { NavLink } from "react-router-dom"
import { Card, DashboardHeader, Nav } from "../../components"
import { GRADIENT } from "../../constants"
import { useInventoryQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const Inventory: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [{ data, fetching }] = useInventoryQuery()

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Inventory
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								{
									// TODO: add sub heading
								}
							</Heading>
						</VStack>
					</Stack>
					<VStack w="full" align="center">
						<HStack w="full">
							<LinkBox>
								<Card>
									<LinkOverlay as={NavLink} to="/inventory/readers">
										<VStack align="flex-start" spacing="6">
											<VStack align="flex-start" spacing="0">
												{fetching ? (
													<Center py="2">
														<Spinner size="md" />
													</Center>
												) : (
													<Text fontSize="3xl" fontWeight="semibold">
														{data?.inventory.stockReaders}
													</Text>
												)}
												<Text fontSize="xs" color="text.400">
													Readers
												</Text>
											</VStack>
											<Button colorScheme="primary" size="xs" variant="link" rightIcon={<Icon as={ArrowRight} />}>
												See all
											</Button>
										</VStack>
									</LinkOverlay>
								</Card>
							</LinkBox>
							<LinkBox>
								<Card>
									<LinkOverlay as={NavLink} to="/inventory/tags">
										<VStack align="flex-start" spacing="6">
											<VStack align="flex-start" spacing="0">
												{fetching ? (
													<Center py="2">
														<Spinner size="md" />
													</Center>
												) : (
													<Text fontSize="3xl" fontWeight="semibold">
														{data?.inventory.stockTags}
													</Text>
												)}
												<Text fontSize="xs" color="text.400">
													Tags
												</Text>
											</VStack>
											<Button colorScheme="primary" size="xs" variant="link" rightIcon={<Icon as={ArrowRight} />}>
												See all
											</Button>
										</VStack>
									</LinkOverlay>
								</Card>
							</LinkBox>
						</HStack>
					</VStack>
				</VStack>
			</HStack>
		</Container>
	)
}
