/* eslint-disable @typescript-eslint/indent */
import { Center, Heading, HStack, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { SearchCafeteriaRegistrationLogsQueryVariables, useSearchCafeteriaRegistrationLogsQuery } from "../../../graphql"
import { CafeteriaRegistrationsTable } from "./CafeteriaRegistrationsTable"

export type SearchResultCafeteriaRegistrationsProps = {
	searchKeyword: string
}

export const SearchResultCafeteriaRegistrations: React.FC<SearchResultCafeteriaRegistrationsProps> = ({ searchKeyword }) => {
	const [pagination, setPagination] = useState<SearchCafeteriaRegistrationLogsQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching }] = useSearchCafeteriaRegistrationLogsQuery({
		variables: { keyword: searchKeyword, pagination },
	})

	const prevPage = () => {
		if (data?.searchCafeteriaRegistrationLogs.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.searchCafeteriaRegistrationLogs.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" justify="space-between">
				<Heading as="h2" fontSize="md" fontWeight="normal" color="text.500">
					Showing {data?.searchCafeteriaRegistrationLogs.total || "all"} registrations
				</Heading>
			</HStack>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.searchCafeteriaRegistrationLogs.cafeteriaRegistrationLogs &&
			  data?.searchCafeteriaRegistrationLogs.cafeteriaRegistrationLogs.length > 0 ? (
				<CafeteriaRegistrationsTable
					cafeteriaRegistrationLogs={data.searchCafeteriaRegistrationLogs.cafeteriaRegistrationLogs}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.searchCafeteriaRegistrationLogs.hasPrevPage}
					hasNextPage={data.searchCafeteriaRegistrationLogs.hasNextPage}
					currentPage={data.searchCafeteriaRegistrationLogs.currentPage}
					totalPages={data.searchCafeteriaRegistrationLogs.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any cafeteria registration.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
