import { Center, Heading, HStack, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { AllPointsRedeemTransactionsQueryVariables, useAllPointsRedeemTransactionsQuery } from "../../graphql"
import { PointsRedeemTransactionsGrid } from "./PointsRedeemTransactionsGrid"

export const AllPointsRedeemTransactions: React.FC = () => {
	const [pagination, setPagination] = useState<AllPointsRedeemTransactionsQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useAllPointsRedeemTransactionsQuery({
		variables: { pagination },
	})

	const prevPage = () => {
		if (data?.allPointsRedeemTransactions.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.allPointsRedeemTransactions.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	return (
		<VStack w="full" h="full" align="flex-start">
			<HStack w="full" justify="space-between">
				<Heading as="h2" fontSize="md" fontWeight="normal" color="text.500">
					Showing {data?.allPointsRedeemTransactions.total || "all"} transactions of your school
				</Heading>
			</HStack>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.allPointsRedeemTransactions.transactions && data?.allPointsRedeemTransactions.transactions.length > 0 ? (
				<PointsRedeemTransactionsGrid
					transactions={data.allPointsRedeemTransactions.transactions}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.allPointsRedeemTransactions.hasPrevPage}
					hasNextPage={data.allPointsRedeemTransactions.hasNextPage}
					currentPage={data.allPointsRedeemTransactions.currentPage}
					totalPages={data.allPointsRedeemTransactions.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any transaction.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
