import { Button, ButtonGroup, FormControl, FormErrorMessage, HStack, Icon, Textarea, useToast, VStack } from "@chakra-ui/react"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormikHelpers, useFormik } from "formik"
import React, { useMemo } from "react"
import * as Yup from "yup"
import { CreateFeedPostMutationVariables, MediaTypes, useCreateFeedPostMutation } from "../../graphql"
import { useUpload } from "../../hooks"
import { UploadMediaButton } from "../upload"
import { FeedAttachments } from "./FeedAttachments"

const validationSchema = Yup.object({
	text: Yup.string().label("Text"),
})

export const CreateFeedPost: React.FC = () => {
	const [{ fetching }, createNew] = useCreateFeedPostMutation()

	const toast = useToast()

	const { clearUpload, clearAllUploads, uploads } = useUpload()

	const handleSubmit = async ({ text }: CreateFeedPostMutationVariables["input"], helpers: FormikHelpers<CreateFeedPostMutationVariables["input"]>) => {
		const attachments: CreateFeedPostMutationVariables["input"]["attachments"] = {}

		if (uploads?.length) {
			if (uploads[0].type === MediaTypes.Image) {
				attachments.pictures = uploads
					.filter(({ type, key, url, isUploaded }) => isUploaded && type === MediaTypes.Image && typeof key === "string" && typeof url === "string")
					.map(({ key, url }) => ({ key: key!, url: url! }))
			}

			if (uploads[0].type === MediaTypes.Video) {
				attachments.videos = uploads
					.filter(({ type, key, url, isUploaded }) => isUploaded && type === MediaTypes.Video && typeof key === "string" && typeof url === "string")
					.map(({ key, url }) => ({ key: key!, url: url! }))
			}

			if (uploads[0].type === MediaTypes.Pdf) {
				attachments.pdfs = uploads
					.filter(({ type, key, url, isUploaded }) => isUploaded && type === MediaTypes.Pdf && typeof key === "string" && typeof url === "string")
					.map(({ key, url }) => ({ key: key!, url: url! }))
			}
		}

		const { error, data } = await createNew({ input: { text, attachments } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createFeedPost.errors) {
			return data?.createFeedPost.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		helpers.setFieldValue("text", "")
		clearAllUploads()
	}

	const formik = useFormik<CreateFeedPostMutationVariables["input"]>({
		initialValues: { text: "" },
		validationSchema,
		onSubmit: handleSubmit,
	})

	const allowedMediaTypes = useMemo(() => {
		if (uploads.length > 0) {
			return [uploads[0].type]
		}

		return [MediaTypes.Image, MediaTypes.Pdf, MediaTypes.Video]
	}, [uploads])

	return (
		<VStack as="form" onSubmit={formik.handleSubmit as any} w="full" maxW="lg" align="flex-start" bg="white.500" rounded="xl" py={2} px={4} spacing={0}>
			<FormControl isInvalid={Boolean(formik.errors.text && formik.touched.text)}>
				<Textarea
					placeholder="Write a post"
					border="none !important"
					outline="none !important"
					_focus={{ border: "none !important", outline: "none !important" }}
					_invalid={{ borded: "none" }}
					maxW="lg"
					bg="white.500"
					p="0"
					resize="none"
					{...formik.getFieldProps("text")}
				/>
				<FormErrorMessage>{formik.errors.text}</FormErrorMessage>
			</FormControl>
			<FeedAttachments onRemove={(id) => clearUpload({ id })} />
			<HStack w="full" justify="space-between">
				<ButtonGroup>
					<UploadMediaButton maxUploads={4} allowedTypes={allowedMediaTypes} />
				</ButtonGroup>
				<Button
					type="submit"
					colorScheme="primary"
					size="sm"
					rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} />}
					isDisabled={!formik.values.text && !uploads?.length}
					isLoading={fetching}
				>
					Post
				</Button>
			</HStack>
		</VStack>
	)
}
