/* eslint-disable indent */

export const getDurationInWords = (seconds: number) => {
	switch (true) {
		case seconds < 60: {
			return `${seconds} second${seconds === 1 ? "" : "s"}`
		}

		case seconds < 60 * 60: {
			return `${Math.round(seconds / 60)} minute${Math.round(seconds / 60) === 1 ? "" : "s"}`
		}

		default: {
			const [hours, minsPercent] = (seconds / (60 * 60)).toFixed(2).split(".").map(Number)

			if (!minsPercent) return `${hours} hours`

			return `${hours} hour${hours === 1 ? "" : "s"} ${Math.round((minsPercent / 100) * 60)} minute${(minsPercent / 100) * 60 === 1 ? "" : "s"}`
		}
	}
}
