/* eslint-disable @typescript-eslint/indent */
import { Badge, Box, ButtonGroup, HStack, Icon, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { useNavigate } from "react-router-dom"
import { CafeteriaRegistrationLogFragment } from "../../../graphql"
import { getMonthForIndex } from "../../../utils"
import { StudentTag } from "../../student"

export type CafeteriaRegistrationsTableProps = {
	cafeteriaRegistrationLogs: CafeteriaRegistrationLogFragment[]
	prevPage: () => void
	nextPage: () => void
	hasPrevPage: boolean
	hasNextPage: boolean
	currentPage: number
	totalPages: number
}

export const CafeteriaRegistrationsTable: React.FC<CafeteriaRegistrationsTableProps> = ({
	cafeteriaRegistrationLogs,
	prevPage,
	nextPage,
	hasPrevPage,
	hasNextPage,
	currentPage,
	totalPages,
}) => {
	const navigate = useNavigate()

	return (
		<VStack w="full" spacing="0">
			<HStack alignSelf="flex-end">
				<Text fontSize="xs" color="text.400">
					{currentPage}/{totalPages}
				</Text>
				<ButtonGroup size="xs" p="4">
					<IconButton aria-label="prev-btn" onClick={prevPage} isDisabled={!hasPrevPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowLeft} {...props} />} color="text.500" />
					</IconButton>
					<IconButton aria-label="next-btn" onClick={nextPage} isDisabled={!hasNextPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} color="text.500" />
					</IconButton>
				</ButtonGroup>
			</HStack>
			<Box w="full" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th textTransform="capitalize">Student</Th>
							<Th textTransform="capitalize">Session</Th>
							<Th textTransform="capitalize">Validity</Th>
							<Th textTransform="capitalize">Charge</Th>
							<Th textTransform="capitalize">Amount Paid</Th>
							<Th textTransform="capitalize">Type</Th>
							<Th textTransform="capitalize">Payment #</Th>
							<Th textTransform="capitalize">Created</Th>
						</Tr>
					</Thead>
					<Tbody>
						{cafeteriaRegistrationLogs.map((cafeteriaRegistration) => (
							<Tr
								key={cafeteriaRegistration._id}
								cursor="pointer"
								fontSize="sm"
								onClick={() => navigate(`/cafeteria/registrations/${cafeteriaRegistration._id}`)}
							>
								<Td py="0">
									<StudentTag student={cafeteriaRegistration.student} />
								</Td>
								<Td>
									<VStack align="flex-start">
										<Badge>{cafeteriaRegistration.schoolSession.name}</Badge>
										<Text>
											{getMonthForIndex(cafeteriaRegistration.schoolSession.startMonth)}{" "}
											{cafeteriaRegistration.schoolSession.startDate.toString().padStart(2, "0")} -{" "}
											{getMonthForIndex(cafeteriaRegistration.schoolSession.endMonth)}{" "}
											{cafeteriaRegistration.schoolSession.endDate.toString().padStart(2, "0")}
										</Text>
									</VStack>
								</Td>
								<Td>
									{format(new Date(cafeteriaRegistration.since), "PP")} - {format(new Date(cafeteriaRegistration.until), "PP")}
								</Td>
								<Td>
									{Intl.NumberFormat("en-IN", {
										style: "currency",
										currency: "INR",
									}).format(cafeteriaRegistration.cafeteriaRegistrationOrder.quote.cafeteriaRegistrationCharge.amount)}
								</Td>
								<Td>
									{Intl.NumberFormat("en-IN", {
										style: "currency",
										currency: "INR",
									}).format(
										cafeteriaRegistration.cafeteriaRegistrationOrder.isManual
											? cafeteriaRegistration.manualCafeteriaRegistrationDetails?.amountPaid ?? 0
											: cafeteriaRegistration.cafeteriaRegistrationOrder.quote.sessionWiseQuotes.find(
													(q) => q.schoolSessionId === cafeteriaRegistration.schoolSessionId
											  )?.totalAmount ?? 0
									)}
								</Td>
								<Td>
									{cafeteriaRegistration.cafeteriaRegistrationOrder.isManual && cafeteriaRegistration.manualCafeteriaRegistrationDetails ? (
										<Text color="primary.500" fontWeight="bold">
											Manual
										</Text>
									) : (
										<Text
											color={
												cafeteriaRegistration.cafeteriaRegistrationOrder.quote.sessionWiseQuotes.find(
													(q) => q.schoolSessionId === cafeteriaRegistration.schoolSessionId
												)?.isPartial
													? "yellow.600"
													: "green.600"
											}
											fontWeight="bold"
										>
											{cafeteriaRegistration.cafeteriaRegistrationOrder.quote.sessionWiseQuotes.find(
												(q) => q.schoolSessionId === cafeteriaRegistration.schoolSessionId
											)?.isPartial
												? "Partial"
												: "Full"}
										</Text>
									)}
								</Td>
								<Td>
									{cafeteriaRegistration.cafeteriaRegistrationOrder.isManual && cafeteriaRegistration.manualCafeteriaRegistrationDetails ? (
										<VStack w="full" align="flex-start" spacing="0">
											<Badge fontSize="x-small" py="0" colorScheme="primary">
												{cafeteriaRegistration.manualCafeteriaRegistrationDetails.paymentMode}
											</Badge>
											<Text>{cafeteriaRegistration.manualCafeteriaRegistrationDetails.paymentReferenceNo}</Text>
											<Text fontSize="xs" color="orange.600">
												{cafeteriaRegistration.manualCafeteriaRegistrationDetails.remarks}
											</Text>
										</VStack>
									) : (
										cafeteriaRegistration.cafeteriaRegistrationOrder.paymentId
									)}
								</Td>
								<Td>{format(new Date(cafeteriaRegistration.createdAt), "PPp")}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
		</VStack>
	)
}
