import { Button, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from "@chakra-ui/react"
import React from "react"
import { ExtendedStudentFragment } from "../../graphql"
import { EditStudentClassForm } from "./EditStudentClassForm"

export type EditStudentClassProps = { student: ExtendedStudentFragment }

export const EditStudentClass: React.FC<EditStudentClassProps> = ({ student }) => {
	return (
		<Popover isLazy lazyBehavior="unmount">
			<PopoverTrigger>
				<Button size="xs" variant="outline" colorScheme="orange">
					Class {student.class.name} - {student.classSection.name}
				</Button>
			</PopoverTrigger>
			<PopoverContent bgColor="white">
				<PopoverArrow bgColor="white" />
				<PopoverBody>
					<EditStudentClassForm student={student} />
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
