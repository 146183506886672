import { Box, ButtonGroup, HStack, Icon, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { useNavigate } from "react-router-dom"
import { Trainer } from "../../graphql"

export type TrainersTableProps = {
	trainers: Trainer[]
	prevPage: () => void
	nextPage: () => void
	hasPrevPage: boolean
	hasNextPage: boolean
	currentPage: number
	totalPages: number
}

export const TrainersTable: React.FC<TrainersTableProps> = ({ trainers, prevPage, nextPage, hasPrevPage, hasNextPage, currentPage, totalPages }) => {
	const navigate = useNavigate()

	return (
		<VStack w="full" spacing="0">
			<HStack alignSelf="flex-end">
				<Text fontSize="xs" color="text.400">
					{currentPage}/{totalPages}
				</Text>
				<ButtonGroup size="xs" p="4">
					<IconButton aria-label="prev-btn" onClick={prevPage} isDisabled={!hasPrevPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowLeft} {...props} />} color="text.500" />
					</IconButton>
					<IconButton aria-label="next-btn" onClick={nextPage} isDisabled={!hasNextPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} color="text.500" />
					</IconButton>
				</ButtonGroup>
			</HStack>
			<Box w="full" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th textTransform="capitalize">Name</Th>
							<Th textTransform="capitalize">Description</Th>

							<Th textTransform="capitalize">Created</Th>
						</Tr>
					</Thead>
					<Tbody>
						{trainers.map((trainer) => (
							<Tr
								key={trainer._id}
								_hover={{ bg: "gray.100" }}
								transition="background-color 200ms ease-in"
								cursor="pointer"
								fontSize="sm"
								onClick={() => navigate(`/trainers/${trainer._id}`)}
							>
								<Td>{trainer.name}</Td>
								<Td>
									{trainer.description.substring(0, 100)}
									{trainer.description.length > 100 ? "..." : ""}
								</Td>
								<Td>{format(new Date(trainer.createdAt), "PP")}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
		</VStack>
	)
}
