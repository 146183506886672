/* eslint-disable @typescript-eslint/indent */
import { Button, Center, Container, Flex, Heading, HStack, Select, Spinner, Stack, Text, useBreakpointValue, useToast, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import { useNavigate } from "react-router-dom"
import { DashboardHeader, Nav } from "../../../components"
import { GRADIENT } from "../../../constants"
import { CardTypes, DepartmentFragment, useDepartmentsQuery, useGenerateStaffCardMutation, useStaffsWithoutPaginationQuery } from "../../../graphql"
import { useDrawer } from "../../../hooks"
import { StaffSelector } from "./StaffSelector"

export const GenerateStaffsCard: React.FC = () => {
	const { isShown } = useDrawer()
	const navigate = useNavigate()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [{ data: departmentsData, fetching: fetchingDepartments, error: departmentsError }] = useDepartmentsQuery()

	const [department, setDepartment] = useState<DepartmentFragment>()

	const [{ data, fetching, error }] = useStaffsWithoutPaginationQuery({ variables: { departmentId: department?._id } })

	const [selectedStaffId, setSelectedStaffId] = useState<string>("")

	const [{ fetching: fetchingGenerateCard }, generateStaffCard] = useGenerateStaffCardMutation()

	const [isGenerated, setIsGenerated] = useState(false)
	const [showCard, setShowCard] = useState(false)

	const toast = useToast()

	const handleCardGeneration = async () => {
		setShowCard(true)

		const { error } = await generateStaffCard({ input: { cardType: CardTypes.Staff, selectedStaffId: selectedStaffId } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}
		setIsGenerated(true)
		navigate("/generateCards/generated")
	}

	useEffect(() => {
		setIsGenerated(false)
	}, [selectedStaffId])

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Generate Staffs Card
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								{
									// TODO: add sub heading
								}
							</Heading>
						</VStack>

						{fetchingDepartments ? (
							<Center>
								<VStack w="full" color="blackAlpha.800">
									<Text>Loading departments</Text>
									<Spinner size="sm" />
								</VStack>
							</Center>
						) : departmentsError ? (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
									Failed loading departments, try reloading the page
								</Text>
							</Center>
						) : departmentsData?.departments.length ? (
							<Flex flexWrap="wrap">
								<Button m="1" size="sm" colorScheme="primary" isLoading={fetching} onClick={handleCardGeneration} disabled={!selectedStaffId}>
									Generate
								</Button>
								<Button
									m="1"
									size="sm"
									colorScheme="primary"
									variant="link"
									onClick={() => {
										setShowCard(false)
										setSelectedStaffId("")
									}}
									disabled={!selectedStaffId}
								>
									Clear
								</Button>

								<HStack spacing={0}>
									{departmentsData && (
										<Select
											bg="white.500"
											rounded="xl"
											border="none"
											_focus={{ border: "none" }}
											w="full"
											maxW="xs"
											placeholder="All"
											value={JSON.stringify(department)}
											onChange={(e) => {
												setDepartment(e.target.value ? JSON.parse(e.target.value) : undefined)
											}}
											disabled={showCard && isGenerated}
										>
											{departmentsData.departments.map((d) => (
												<option key={d.name} value={JSON.stringify(d)}>
													{d.name}
												</option>
											))}
										</Select>
									)}
								</HStack>
							</Flex>
						) : (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any departments.
								</Text>
							</Center>
						)}
					</Stack>
					<VStack w="full" align="center">
						{showCard && fetchingGenerateCard ? (
							<VStack w="full" maxW="2xl">
								<Spinner />
								<Text textAlign="center">Generating card</Text>
							</VStack>
						) : fetching ? (
							<Center py="4">
								<Spinner color="text.400" />
							</Center>
						) : error ? (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
									Failed loading staffs, try reloading the page
								</Text>
							</Center>
						) : data?.staffsWithoutPagination && data?.staffsWithoutPagination.length > 0 ? (
							<StaffSelector
								staffs={data?.staffsWithoutPagination || []}
								selectedStaffId={selectedStaffId}
								onChange={setSelectedStaffId}
								removeHeading
							/>
						) : (
							<Center py="4">
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any staff.
								</Text>
							</Center>
						)}
					</VStack>
				</VStack>
			</HStack>
		</Container>
	)
}
