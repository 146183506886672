import { Avatar, AvatarGroup, Button, Heading, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack } from "@chakra-ui/react"
import React, { useMemo, useState } from "react"
import { PenaltyItemFragment, StudentFragment, usePenaliseStudentsMutation } from "../../graphql"
import { PenaltyItemsSelectorForStudents } from "./PenaltyItemsSelectorForStudents"

export type PenaliseStudentsModalProps = {
	isOpen: boolean
	onClose: () => void
	students: StudentFragment[]
}

export const PenaliseStudentsModal: React.FC<PenaliseStudentsModalProps> = ({ isOpen, onClose, students }) => {
	const [selectedItems, setSelectedItems] = useState<PenaltyItemFragment[]>([])

	const totalPoints = useMemo(() => selectedItems.map((i) => i.points).reduce((a, b) => a + b, 0), [selectedItems])

	const [{ fetching: penalising, error: penaltyError, data: penaltyData }, penalise] = usePenaliseStudentsMutation()

	const handlePenalty = async () => {
		await penalise({ studentIds: students.map((s) => s._id), penaltyItemIds: selectedItems.map((i) => i._id) })
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered scrollBehavior="inside">
			<ModalOverlay />
			{penaltyData?.penaliseStudents ? (
				<ModalContent bgColor="white.400">
					<ModalBody>
						<VStack alignItems="center" py="6">
							<Heading fontSize="xl">Penalised</Heading>
							<Heading fontSize="3xl" color="red.500" textAlign="center">
								-{penaltyData.penaliseStudents.totalPoints} points
							</Heading>

							<AvatarGroup max={3} gap={2}>
								{penaltyData.penaliseStudents.to.map(
									({ student }) => student && <Avatar rounded="2xl" key={student._id} src={student.picture?.url} name={student.name} />
								)}
							</AvatarGroup>
							<Text fontWeight="semibold" color="blackAlpha.600">
								for
							</Text>
							<Heading fontSize="xl" color="purple.500" textAlign="center">
								{penaltyData.penaliseStudents.penaltyItems.map((i) => i.name).join(", ")}
							</Heading>
						</VStack>
					</ModalBody>
				</ModalContent>
			) : (
				<ModalContent bgColor="white.400">
					<ModalHeader>
						<VStack alignItems="center" py="2">
							<AvatarGroup max={3} gap={2}>
								{students.map((student) => (
									<Avatar rounded="2xl" key={student._id} src={student.picture?.url} name={student.name} />
								))}
							</AvatarGroup>
							<Heading fontSize="2xl" color="red.500">
								-{totalPoints} points
							</Heading>
						</VStack>
					</ModalHeader>
					<ModalBody>
						<VStack alignItems="center">
							<VStack w="full" align="stretch">
								<PenaltyItemsSelectorForStudents onChange={setSelectedItems} />
								{penaltyError ? (
									<Text fontSize="sm" color="red.500" textAlign="center">
										{penaltyError.message.replace("[GraphQL] ", "")}
									</Text>
								) : (
									penaltyData &&
									!penaltyData.penaliseStudents && (
										<Text fontSize="sm" color="red.500" textAlign="center">
											Some error occurred
										</Text>
									)
								)}
							</VStack>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<Button w="full" colorScheme="primary" onClick={handlePenalty} isLoading={penalising} isDisabled={!selectedItems.length}>
							Penalise
						</Button>
					</ModalFooter>
				</ModalContent>
			)}
		</Modal>
	)
}
