import { Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { BusRouteFragment, useDeleteBusRouteMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type DeleteBusRouteButtonProps = {
	busRoute: BusRouteFragment
}

export const DeleteBusRouteButton: React.FC<DeleteBusRouteButtonProps> = ({ busRoute }) => {
	const [{ fetching }, deleteBusRoute] = useDeleteBusRouteMutation()

	const toast = useToast()

	const handleDeleteBusRoute = async () => {
		const { error } = await deleteBusRoute({ busRouteId: busRoute._id })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}
		onClose()
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<Tooltip label={`Delete bus route ${busRoute.routeName}`} fontSize="sm">
				<IconButton aria-label="delete-busRoute-btn" variant="ghost" colorScheme="blackAlpha" size="sm" {...getButtonProps()}>
					<Icon as={(props: any) => <FontAwesomeIcon icon={faTrash} {...props} />} />
				</IconButton>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Delete Bus Route"
				message=""
				confirmLabel="Delete"
				onConfirm={handleDeleteBusRoute}
				isLoading={fetching}
			/>
		</>
	)
}
