import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Heading,
	Icon,
	IconButton,
	Tooltip,
	useDisclosure,
	useToast,
} from "@chakra-ui/react"
import { FocusableElement } from "@chakra-ui/utils"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { RefObject, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { SchoolSessionFragment, useDeleteSchoolSessionMutation } from "../../graphql"

export type DeleteSchoolSessionButtonProps = {
	schoolSession: SchoolSessionFragment
}

export const DeleteSchoolSessionButton: React.FC<DeleteSchoolSessionButtonProps> = ({ schoolSession }) => {
	const [, deleteSchoolSession] = useDeleteSchoolSessionMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleDeleteSchoolSession = async () => {
		const { error } = await deleteSchoolSession({ schoolSessionId: schoolSession._id })

		if (error) {
			return toast({
				description: error.message,
				status: "error",
			})
		}

		return navigate("/schoolSessions", { replace: true })
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	const leastDestructiveRef = useRef() as RefObject<FocusableElement>

	return (
		<>
			<Tooltip label={`Delete School Session ${schoolSession.name}`} fontSize="sm">
				<IconButton aria-label="delete-school-session-btn" variant="ghost" colorScheme="blackAlpha" size="sm" {...getButtonProps()}>
					<Icon as={(props: any) => <FontAwesomeIcon icon={faTrash} {...props} />} />
				</IconButton>
			</Tooltip>
			<AlertDialog leastDestructiveRef={leastDestructiveRef} isOpen={isOpen} onClose={onClose}>
				<AlertDialogOverlay backdropFilter="blur(16px)">
					<AlertDialogContent>
						<AlertDialogHeader>
							<Heading fontSize="lg" color="text.500">
								Are you sure?
							</Heading>
							<AlertDialogCloseButton colorScheme="text" />
						</AlertDialogHeader>

						<AlertDialogBody fontSize="sm">Removing this school session may affect logs associated with this.</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={leastDestructiveRef as React.LegacyRef<HTMLButtonElement>} size="sm" onClick={onClose}>
								Cancel
							</Button>
							<Button colorScheme="red" size="sm" onClick={handleDeleteSchoolSession} ml={3}>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	)
}
