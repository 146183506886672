import { Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Heading, Icon, Input, useToast, VStack } from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import * as Yup from "yup"
import { useAssignNewTagMutation } from "../../graphql"

const validationSchema = Yup.object({
	tagHexOrDecId: Yup.string().min(8).required().label("Tag ID"),
})

type AssignNewTagFormValues = {
	tagHexOrDecId: string
}

const initialValues: AssignNewTagFormValues = {
	tagHexOrDecId: "",
}

export type AssignNewTagFormProps = {
	studentId: string
}

export const AssignNewTagForm: React.FC<AssignNewTagFormProps> = ({ studentId }) => {
	const [{ fetching }, assignNewTag] = useAssignNewTagMutation()

	const toast = useToast()

	const onSubmit = async ({ tagHexOrDecId }: AssignNewTagFormValues, helpers: FormikHelpers<AssignNewTagFormValues>) => {
		const { error } = await assignNewTag({ studentId, tagHexOrDecId })

		if (error) {
			return helpers.setFieldError("tagHexOrDecId", error.message.replace("[GraphQL] ", ""))
		}

		toast({ description: "Assigned new tag", status: "success" })
	}

	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	})

	return (
		<form onSubmit={formik.handleSubmit}>
			<VStack w="full" align="stretch">
				<Heading fontSize="sm">Assign New Tag</Heading>
				<FormControl isInvalid={Boolean(formik.errors.tagHexOrDecId && formik.touched.tagHexOrDecId)}>
					<FormLabel>Tag ID</FormLabel>
					<Input
						border="none"
						_focus={{ border: "none" }}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						autoComplete="off"
						{...formik.getFieldProps("tagHexOrDecId")}
					/>
					<FormHelperText>
						Enter the tag&apos;s <strong>ID</strong>
					</FormHelperText>
					<FormErrorMessage>{formik.errors.tagHexOrDecId}</FormErrorMessage>
				</FormControl>
				<Button size="sm" type="submit" colorScheme="primary" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
					Assign
				</Button>
			</VStack>
		</form>
	)
}
