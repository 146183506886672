import { Avatar, Heading, Link, Stack, StackDirection, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedStaffFragment, StaffFragment } from "../../graphql"

export type StaffTagProps = {
	staff: StaffFragment | ExtendedStaffFragment
	direction?: StackDirection
	isLinkable?: boolean
	showFirstName?: boolean
}

export const StaffTag: React.FC<StaffTagProps> = ({ staff, direction = "row", isLinkable = true, showFirstName }) => {
	return (
		<Stack direction={direction} align="center" as={isLinkable ? (props: any) => <Link as={NavLink} {...props} /> : "div"} to={`/staff/${staff._id}`}>
			<Avatar src={staff.picture?.url} name={staff.name} size={direction === "column" || direction === "column-reverse" ? "md" : "sm"} />
			<VStack w="full" align={direction === "column" || direction === "column-reverse" ? "center" : "stretch"} spacing={0}>
				<Heading fontSize="sm" lineHeight="100%">
					{showFirstName ? staff.name.split(" ")[0] : staff.name}
				</Heading>
				<Heading fontSize="xs" color="blackAlpha.600">
					@{staff.username}
				</Heading>
			</VStack>
		</Stack>
	)
}
