import { Box, ButtonGroup, HStack, Icon, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { useNavigate } from "react-router-dom"
import { ExtendedResourceFragment } from "../../graphql"

export type ResourcesTableProps = {
	resources: ExtendedResourceFragment[]
	prevPage: () => void
	nextPage: () => void
	hasPrevPage: boolean
	hasNextPage: boolean
	currentPage: number
	totalPages: number
}

export const ResourcesTable: React.FC<ResourcesTableProps> = ({ resources, prevPage, nextPage, hasPrevPage, hasNextPage, currentPage, totalPages }) => {
	const navigate = useNavigate()

	return (
		<VStack w="full" spacing="0">
			<HStack alignSelf="flex-end">
				<Text fontSize="xs" color="text.400">
					{currentPage}/{totalPages}
				</Text>
				<ButtonGroup size="xs" p="4">
					<IconButton aria-label="prev-btn" onClick={prevPage} isDisabled={!hasPrevPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowLeft} {...props} />} color="text.500" />
					</IconButton>
					<IconButton aria-label="next-btn" onClick={nextPage} isDisabled={!hasNextPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} color="text.500" />
					</IconButton>
				</ButtonGroup>
			</HStack>
			<Box w="full" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th textTransform="capitalize">Name</Th>
							<Th textTransform="capitalize">Targets</Th>
							<Th textTransform="capitalize">Description</Th>
							<Th textTransform="capitalize">Class</Th>
							<Th textTransform="capitalize">Subject</Th>
							<Th textTransform="capitalize">Topic</Th>
							<Th textTransform="capitalize">Sub Topic</Th>
							<Th textTransform="capitalize">Author</Th>
							<Th textTransform="capitalize">Created</Th>
						</Tr>
					</Thead>
					<Tbody>
						{resources.map((resource) => (
							<Tr
								key={resource._id}
								_hover={{ bg: "gray.100" }}
								transition="background-color 200ms ease-in"
								cursor="pointer"
								fontSize="sm"
								onClick={() => navigate(`/resources/${resource._id}`)}
							>
								<Td>{resource.name}</Td>
								<Td>
									<Text>
										{resource.targets?.map((target, index) => `${target}${index === (resource.targets?.length || 0) - 1 ? "" : ", "}`)}
									</Text>
								</Td>
								<Td>
									{resource.description.substring(0, 50)}
									{resource.description.length > 50 ? "..." : ""}
								</Td>
								<Td>
									<Text>
										{resource.class.name}
										{resource.classSection?.name ? " - " + resource.classSection.name : ""}
									</Text>
								</Td>
								<Td>{resource.subject.name}</Td>
								<Td textTransform="capitalize">{resource.topic.name}</Td>
								<Td textTransform="capitalize">{resource.subTopic}</Td>
								<Td>{resource.author.authorType === "School" ? resource.author.school?.name : resource.author.staff?.name}</Td>
								<Td>{format(new Date(resource.createdAt), "PP")}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
		</VStack>
	)
}
