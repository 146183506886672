import { Badge, HStack, Switch, Text } from "@chakra-ui/react"
import React from "react"
import { SchoolTimingFragment, useUpdateDefaultSchoolTimingMutation } from "../../graphql"

export type UpdateDefaultSchoolTimingProps = { schoolTiming: SchoolTimingFragment }

export const UpdateDefaultSchoolTiming: React.FC<UpdateDefaultSchoolTimingProps> = ({ schoolTiming }) => {
	const [, updateDefaultSchoolTiming] = useUpdateDefaultSchoolTimingMutation()

	const toggleSwitch = () => updateDefaultSchoolTiming({ schoolTimingId: schoolTiming._id })

	if (schoolTiming.isDefault) {
		return (
			<Badge colorScheme="primary" rounded="sm">
				Default
			</Badge>
		)
	}

	return (
		<HStack m="1" onClick={toggleSwitch} zIndex={2}>
			<Text>Set as default</Text>
			<Switch size="sm" zIndex="0" onChange={() => toggleSwitch()} />
		</HStack>
	)
}
