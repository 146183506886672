import {
	Button,
	Center,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	Icon,
	Input,
	Select,
	SlideFade,
	Spinner,
	Text,
	Textarea,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CUIAutoComplete } from "chakra-ui-autocomplete"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import DatePicker from "react-date-picker"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import {
	BloodGroups,
	DepartmentTypes,
	EditStaffMutationVariables,
	ExtendedStaffFragment,
	Genders,
	useEditStaffMutation,
	useSubjectsWithoutPaginationQuery,
} from "../../graphql"

const validationSchema = Yup.object({
	name: Yup.string().min(3).required().label("Name"),
	email: Yup.string().email().nullable().label("Email"),
	phone: Yup.string().length(10).required().label("Phone"),
})

export type EditStaffFormProps = { staff: ExtendedStaffFragment }

export const EditStaffForm: React.FC<EditStaffFormProps> = ({ staff }) => {
	const [{ fetching }, editStaff] = useEditStaffMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (values: EditStaffMutationVariables["input"], helpers: FormikHelpers<EditStaffMutationVariables["input"]>) => {
		const { error, data } = await editStaff({ staffId: staff._id, input: { ...values } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		if (data?.editStaff.errors?.length) {
			return data?.editStaff.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		if (!data?.editStaff.staff) {
			return toast({
				description: "Could not edit the staff",
				status: "error",
			})
		}

		return navigate("/staff/" + staff._id, { replace: true })
	}

	const formik = useFormik<EditStaffMutationVariables["input"]>({
		initialValues: {
			name: staff.name,
			email: staff.email,
			phone: staff.phone || "",
			designation: staff.designation || "",
			gender: staff.gender,
			dateOfBirth: staff.dateOfBirth,
			bloodGroup: staff.bloodGroup,
			address: staff.address,
			subjectIds: staff.subjectIds,
		},
		validationSchema,
		onSubmit: handleSubmit,
	})

	const [{ data: subjectsData, fetching: fetchingSubjects, error: subjectsError }] = useSubjectsWithoutPaginationQuery({
		pause: staff.department.type !== DepartmentTypes.Teaching,
	})

	return (
		<VStack w="full" maxW="md" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)}>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="name" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.email && formik.touched.email)}>
				<SlideFade in={Boolean(formik.values.email)} unmountOnExit>
					<FormLabel htmlFor="email" fontSize="sm">
						Email
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Email"
					type="email"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("email")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.email}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.phone && formik.touched.phone)}>
				<SlideFade in={Boolean(formik.values.phone)} unmountOnExit>
					<FormLabel htmlFor="phone" fontSize="sm">
						Phone
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Phone"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("phone")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.phone}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.designation && formik.touched.designation)}>
				<SlideFade in={Boolean(formik.values.designation)} unmountOnExit>
					<FormLabel htmlFor="designation" fontSize="sm">
						Designation
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Designation"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("designation")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.designation}</Text>
				</FormErrorMessage>
			</FormControl>

			{staff.department.type === DepartmentTypes.Teaching &&
				(fetchingSubjects ? (
					<Center w="full" py="1">
						<VStack>
							<Spinner size="sm" color="text.400" />
							<Text fontSize="xs" color="text.400">
								Loading subjects
							</Text>
						</VStack>
					</Center>
				) : subjectsError ? (
					<Center w="full" py="1">
						<VStack>
							<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
							<Text fontSize="xs" color="text.400">
								{subjectsError.message.replace("[GraphQL] ", "")}
							</Text>
						</VStack>
					</Center>
				) : !subjectsData?.subjectsWithoutPagination ? (
					<Center w="full" py="1">
						<VStack>
							<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
							<Text fontSize="xs" color="text.400">
								Could not load subjects
							</Text>
						</VStack>
					</Center>
				) : (
					<FormControl isInvalid={Boolean(formik.errors.subjectIds && formik.touched.subjectIds)} mb="-24px !important">
						<SlideFade in={Boolean(formik.values.subjectIds)} unmountOnExit>
							<FormLabel htmlFor="subjectIds" fontSize="sm" mb="-.5rem">
								Subjects
							</FormLabel>
						</SlideFade>
						<CUIAutoComplete
							label=""
							placeholder="Subjects"
							disableCreateItem
							hideToggleButton
							selectedItems={subjectsData.subjectsWithoutPagination
								.map(({ _id, name }) => ({ label: name, value: _id }))
								.filter((target) => formik.values.subjectIds?.includes(target.value))}
							items={subjectsData.subjectsWithoutPagination.map(({ _id, name }) => ({
								label: name,
								value: _id,
							}))}
							onSelectedItemsChange={(changes) =>
								formik.setFieldValue(
									"subjectIds",
									changes.selectedItems?.map(({ value }) => value)
								)
							}
							inputStyleProps={{
								border: "none",
								_focus: { border: "none" },

								maxW: "md",
								bg: "white.500",
								rounded: "xl",
								py: "1",
							}}
							listStyleProps={{
								maxW: "md",
								bg: "whiteAlpha.400",
								backdropFilter: "blur(4px)",
							}}
							labelStyleProps={{ display: "none" }}
						/>
						<FormErrorMessage>{formik.errors.subjectIds}</FormErrorMessage>
					</FormControl>
				))}

			<FormControl isInvalid={Boolean(formik.errors.address && formik.touched.address)}>
				<SlideFade in={Boolean(formik.values.address)} unmountOnExit>
					<FormLabel htmlFor="address" fontSize="sm">
						Address
					</FormLabel>
				</SlideFade>
				<Textarea
					placeholder="Address"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("address")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.address}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.dateOfBirth && formik.touched.dateOfBirth)}>
				<FormLabel htmlFor="dateOfBirth" fontSize="sm">
					Date of birth
				</FormLabel>
				<DatePicker
					{...formik.getFieldProps("dateOfBirth")}
					value={formik.values.dateOfBirth}
					onChange={(date: Date) => formik.setFieldValue("dateOfBirth", date)}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.dateOfBirth as string}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.bloodGroup && formik.touched.bloodGroup)}>
				<SlideFade in={Boolean(formik.values.bloodGroup)} unmountOnExit>
					<FormLabel htmlFor="bloodGroup" fontSize="sm">
						Blood Group
					</FormLabel>
				</SlideFade>
				<Select
					placeholder="Blood Group"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("bloodGroup")}
				>
					{Object.values(BloodGroups).map((bg) => (
						<option key={bg} value={bg}>
							{bg}
						</option>
					))}
				</Select>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.bloodGroup}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.gender && formik.touched.gender)}>
				<SlideFade in={Boolean(formik.values.gender)} unmountOnExit>
					<FormLabel htmlFor="gender" border="none" _focus={{ border: "none" }} maxW="md" bg="white.500" rounded="xl" py="1" fontSize="sm">
						Gender
					</FormLabel>
				</SlideFade>
				<Select
					placeholder="Gender"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("gender")}
				>
					{Object.values(Genders).map((g) => (
						<option key={g} value={g}>
							{g}
						</option>
					))}
				</Select>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.gender}</Text>
				</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Save
			</Button>
		</VStack>
	)
}
