import { useToast } from "@chakra-ui/react"
import axios, { AxiosProgressEvent } from "axios"
import { nanoid } from "nanoid"
import React, { PropsWithChildren, useCallback, useMemo } from "react"
import { MediaTypes, useFinishVideoUploadMutation, useMeQuery, useSignVideoUploadMutation } from "../../graphql"
import { useUpload } from "../../hooks"
import { getFileFormat } from "../../utils"

export type UploadVideoProps = {
	max?: number
	maxSize?: number
	maxDuration?: number
}

export const UploadVideo: React.FC<UploadVideoProps & PropsWithChildren> = ({ max = 4, maxSize, maxDuration, children }) => {
	const id = useMemo(() => nanoid(), [])

	const { uploadStarted, uploadCancelFnCreated, uploadFailed, uploadFinished, uploadProgress } = useUpload()

	const [, signUpload] = useSignVideoUploadMutation()
	const [, finishUpload] = useFinishVideoUploadMutation()

	const toast = useToast()

	const [{ data: meData }] = useMeQuery()

	const onDrop = useCallback(async (acceptedFiles?: FileList | null) => {
		try {
			await Promise.all(
				[...(acceptedFiles ?? [])].map(async (file) => {
					if (!file) {
						return
					}

					const id = nanoid()

					uploadStarted({ id, type: MediaTypes.Video, file })

					if (maxSize && Math.floor(file.size) > maxSize) {
						toast({
							title: "Upload Failed",
							description: `The file size exceeds the limit of ${maxSize / 1e6} MBs`,
							status: "error",
						})

						return uploadFailed({
							id,
							error: {
								message: `The file size exceeds the limit of ${maxSize / 1e6} MBs`,
							},
						})
					}

					if (maxDuration) {
						const { duration } = await getVideoDuration(file)

						if (duration) {
							if (Math.floor(duration) > maxDuration) {
								toast({
									title: "Upload Failed",
									description: `The video file duration exceeds the limit of ${maxDuration} seconds`,
									status: "error",
								})

								return uploadFailed({
									id,
									error: {
										message: `The video file duration exceeds the limit of ${maxDuration} seconds`,
									},
								})
							}
						}
					}

					if (!meData?.me) {
						toast({
							title: "Upload Failed",
							description: "You are not allowed to perform this action",
							status: "error",
						})

						return uploadFailed({
							id,
							error: { message: "You are not allowed to perform this action" },
						})
					}

					const { data, error } = await signUpload({
						format: getFileFormat(file),
					})

					if (error) {
						toast({
							title: "Upload Failed",
							description: error.message,
							status: "error",
						})

						return uploadFailed({ id, error })
					}

					if (!data) {
						toast({
							title: "Upload Failed",
							description: "Upload URL could not be generated",
							status: "error",
						})

						return uploadFailed({
							id,
							error: { message: "Upload URL could not be generated" },
						})
					}

					const {
						signVideoUpload: { key, signedUrl },
					} = data

					const source = axios.CancelToken.source()
					const cancelToken = source.token

					uploadCancelFnCreated({
						id,
						onCancel: () => source.cancel("You cancelled the upload"),
					})

					const onUploadProgress = ({ loaded, total = 0 }: AxiosProgressEvent) => {
						uploadProgress({
							id,
							progress: Math.floor((100 * loaded) / total),
						})
					}

					await axios
						.put(signedUrl, file, {
							headers: { "Content-Type": file.type },
							onUploadProgress,
							cancelToken,
						})
						.then(({ status }) => {
							if (status !== 200) {
								toast({
									title: "Upload Failed",
									description: "The video could not be uploaded correctly",
									status: "error",
								})

								return uploadFailed({
									id,
									error: {
										message: "The video could not be uploaded correctly",
									},
								})
							}

							return finishUpload({ input: { key } })
								.then(({ data, error }) => {
									if (error) {
										toast({
											title: "Upload Failed",
											description: error.message,
											status: "error",
										})

										uploadFailed({ id, error })

										return
									}

									if (!data) {
										toast({
											title: "Upload Failed",
											description: "Upload could not be finished",
											status: "error",
										})

										uploadFailed({
											id,
											error: { message: "Upload could not be finished" },
										})

										return
									}

									const {
										finishVideoUpload: { url },
									} = data

									uploadFinished({ id, key, url })
								})
								.catch((error) => {
									toast({
										title: "Upload Failed",
										description: error.message,
										status: "error",
									})

									return uploadFailed({ id, error })
								})
						})
						.catch((error) => {
							toast({
								title: "Upload Failed",
								description: error.message,
								status: "error",
							})

							return uploadFailed({ id, error })
						})
				})
			)
		} catch (error: any) {
			toast({
				title: "Upload Failed",
				description: error.message,
				status: "error",
			})

			return uploadFailed({ id, error })
		}
	}, [])

	return (
		<>
			<input
				id={id}
				type="file"
				accept={[
					"video/mp4",
					"video/mpeg",
					"video/avi",
					"video/wmv",
					"video/ogg",
					"video/webm",
					"video/3gpp",
					"video/quicktime",
					"video/x-msvideo",
				]?.join(",")}
				max={max}
				multiple={max > 1}
				style={{ display: "none" }}
				onChange={(e) => onDrop(e.target.files)}
			/>
			<label htmlFor={id}>{children}</label>
		</>
	)
}

const getVideoDuration = async (f: File) => {
	const fileCallbackToPromise = (fileObj: HTMLVideoElement | HTMLImageElement) => {
		return Promise.race([
			new Promise((resolve) => {
				if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve
				else fileObj.onloadedmetadata = resolve
			}),
			new Promise((_, reject) => {
				setTimeout(reject, 1000)
			}),
		])
	}

	const objectUrl = URL.createObjectURL(f)

	const fileExtension = f.name.split(".").pop()

	const video = document.createElement("video")
	video.src = objectUrl

	await fileCallbackToPromise(video)

	return {
		size: f.size,
		duration: video.duration,
		width: video.videoWidth,
		height: video.videoHeight,
		fileExtension,
	}
}
