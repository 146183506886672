import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { usePenaltiesOfStudentQuery } from "../../graphql"
import { StudentPenaltyListItem } from "./StudentPenaltyListItem"

export type StudentPenaltiesListProps = {
	studentId: string
}

export const StudentPenaltiesList: React.FC<StudentPenaltiesListProps> = ({ studentId }) => {
	const [{ data, fetching, error }] = usePenaltiesOfStudentQuery({ variables: { studentId } })

	return (
		<VStack w="full" align="stretch">
			{fetching ? (
				<Center w="full" py="4">
					<Spinner color="text.400" />
				</Center>
			) : error ? (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="text.400">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.penaltiesOfStudent.length ? (
				<VStack spacing={4}>
					{data.penaltiesOfStudent.map((penalty) => (
						<StudentPenaltyListItem key={penalty._id} penalty={penalty} />
					))}
				</VStack>
			) : (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="text.400">
						Couldn&apos;t find any penalties.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
