import { useCallback, useEffect, useState } from "react"

export function usePagination<T>(items: T[], limit = 20, initialPage = 0) {
	const [page, setPage] = useState(initialPage)
	const [hasMore, setHasMore] = useState(items.length > 20)
	const [currentItems, setCurrentItems] = useState(items.slice(page * limit, limit))

	useEffect(() => {
		setPage(0)
		setHasMore(items.length > 20)
		setCurrentItems(items.slice(page * limit, limit))
	}, [items])

	const loadMore = useCallback(() => {
		if (hasMore) {
			setPage((prev) => prev + 1)
			const nextItems = items.slice((page + 1) * limit, (page + 2) * limit + 1)

			setHasMore(nextItems.length > limit)
			setCurrentItems((prev) => [...prev, ...nextItems.slice(0, limit)])
		}
	}, [hasMore, items, page, limit])

	return { currentItems, loadMore, hasMore, page }
}
