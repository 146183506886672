import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Icon, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { DepartmentFragment, UpdateWorkingdaysOfDepartmentMutationVariables, useUpdateWorkingdaysOfDepartmentMutation } from "../../graphql"

const validationSchema = Yup.object({
	workingWeekdays: Yup.array().of(Yup.string()).required().min(1).label("Working weekdays"),
})

export type UpdateWorkingWeekdaysOfDepartmentFormProps = {
	department: DepartmentFragment
}

export const UpdateWorkingWeekdaysOfDepartmentForm: React.FC<UpdateWorkingWeekdaysOfDepartmentFormProps> = ({ department }) => {
	const [{ fetching }, updateWorkingWeekdays] = useUpdateWorkingdaysOfDepartmentMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async ({ workingWeekdays }: Pick<UpdateWorkingdaysOfDepartmentMutationVariables, "workingWeekdays">) => {
		const { error, data } = await updateWorkingWeekdays({ departmentId: department._id, workingWeekdays })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		if (!data?.updateWorkingWeekdaysOfDepartment) {
			return toast({
				description: "Could not update the working weekdays",
				status: "error",
			})
		}

		return navigate("/departments/" + data.updateWorkingWeekdaysOfDepartment._id)
	}

	const formik = useFormik<Pick<UpdateWorkingdaysOfDepartmentMutationVariables, "workingWeekdays">>({
		initialValues: {
			workingWeekdays: department.workingWeekdays || ["mon", "tue", "wed", "thu", "fri"],
		},
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<form onSubmit={formik.handleSubmit}>
			<VStack w="full" maxW="lg" align="stretch" spacing={4}>
				<FormControl isInvalid={Boolean(formik.errors.workingWeekdays && formik.touched.workingWeekdays)}>
					<FormLabel>Working Weekdays</FormLabel>
					<Flex flexWrap="wrap">
						{["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map((day) => (
							<Button
								key={day}
								m="1"
								size="sm"
								shadow="none"
								variant="outline"
								borderWidth={formik.values.workingWeekdays.includes(day) ? 2 : 1}
								colorScheme={formik.values.workingWeekdays.includes(day) ? "green" : "red"}
								opacity={formik.values.workingWeekdays.includes(day) ? 1 : 0.4}
								onClick={() => {
									if (formik.values.workingWeekdays.includes(day)) {
										const workingWeekdays = [...formik.values.workingWeekdays]

										workingWeekdays.splice(workingWeekdays.indexOf(day), 1)

										formik.setFieldValue("workingWeekdays", workingWeekdays)
									} else {
										const updated = new Set(formik.values.workingWeekdays).add(day)

										formik.setFieldValue("workingWeekdays", [...updated])
									}
								}}
							>
								{day}
							</Button>
						))}
					</Flex>
					<FormErrorMessage>{formik.errors.workingWeekdays}</FormErrorMessage>
				</FormControl>
				<Button size="sm" type="submit" colorScheme="primary" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
					Save
				</Button>
			</VStack>
		</form>
	)
}
