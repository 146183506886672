import { Checkbox, Heading, Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { ExtendedClassFragment } from "../../graphql"
import { usePagination } from "../../hooks"

export type ClassesSelectorProps = {
	classes: ExtendedClassFragment[]
	selectClassIds: string[]
	onChange: (classIds: string[]) => void
}

export const ClassesSelector: React.FC<ClassesSelectorProps> = ({ classes, selectClassIds, onChange }) => {
	const isAllChecked = useMemo(
		() => Boolean(selectClassIds.length) && classes.map(({ _id }) => _id).every((id) => selectClassIds.includes(id)),
		[selectClassIds, classes]
	)

	const isIndeterminate = useMemo(() => selectClassIds.some((id) => classes.map(({ _id }) => _id).includes(id)) && !isAllChecked, [selectClassIds, classes])

	const selectClass = (classId: string) => {
		const _selectClassIds = new Set(selectClassIds)
		_selectClassIds.add(classId)

		onChange(Array.from(_selectClassIds))
	}

	const deselectClass = (classId: string) => {
		const _selectClassIds = [...selectClassIds]

		_selectClassIds.splice(_selectClassIds.indexOf(classId), 1)

		onChange(_selectClassIds)
	}

	const selectAllClasses = () => {
		onChange(classes.map(({ _id }) => _id) ?? [])
	}

	const isClassSelected = useCallback((classId: string) => selectClassIds.includes(classId), [selectClassIds])

	useEffect(() => {
		onChange(selectClassIds)
	}, [selectClassIds])

	const { currentItems, loadMore, hasMore } = usePagination<ExtendedClassFragment>(classes, 25)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
				Select the class you want to generate the report for
			</Heading>
			<VStack w="full" align="stretch" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th>
								<Checkbox
									colorScheme="blackAlpha"
									borderColor="black"
									isChecked={isAllChecked}
									isIndeterminate={isIndeterminate}
									onChange={(e) => (e.target.checked ? selectAllClasses() : onChange([]))}
								/>
							</Th>
							<Th textTransform="capitalize">Class</Th>
							<Th textTransform="capitalize">Sections</Th>
						</Tr>
					</Thead>
					<Tbody>
						{currentItems.map((schoolClass) => (
							<Tr key={schoolClass._id} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td>
									<Checkbox
										colorScheme="blackAlpha"
										borderColor="black"
										isChecked={isClassSelected(schoolClass._id)}
										onChange={(e) => (e.target.checked ? selectClass(schoolClass._id) : deselectClass(schoolClass._id))}
									/>
								</Td>
								<Td>{schoolClass.name}</Td>
								<Td>{schoolClass.sections.map((section) => section.name).join(", ")}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
				{hasMore && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
			</VStack>
		</VStack>
	)
}
