import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useAllFailedPaytankOrdersQuery } from "../../../graphql"
import { Card } from "../../common"

export type AllFailedOrdersProps = {
	sinceDate: Date
	untilDate: Date
}

const INR = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "INR",
})

export const AllFailedOrders: React.FC<AllFailedOrdersProps> = ({ sinceDate, untilDate }) => {
	const [{ data, fetching }] = useAllFailedPaytankOrdersQuery({ variables: { sinceDate, untilDate } })

	return (
		<Card bgColor="red.100">
			<VStack align="flex-start" spacing="6">
				<VStack align="flex-start" spacing="0">
					{fetching ? (
						<Center py="2">
							<Spinner size="md" />
						</Center>
					) : data?.allFailedPaytankOrders ? (
						<Text fontSize="3xl" fontWeight="semibold">
							{INR.format(data.allFailedPaytankOrders.map((o) => o.amount).reduce((a, b) => a + b, 0))}
						</Text>
					) : (
						<Text fontSize="3xl" fontWeight="semibold">
							No data
						</Text>
					)}
					<Text fontSize="sm" color="text.400">
						<strong>{data?.allFailedPaytankOrders.length || 0}</strong> Failed transactions
					</Text>
				</VStack>
			</VStack>
		</Card>
	)
}
