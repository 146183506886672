import { Checkbox, Heading, Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { format } from "date-fns"
import React, { useCallback, useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { StaffTag } from "../../../components/staff"
import { StaffFragment } from "../../../graphql"
import { usePagination } from "../../../hooks"

export type StaffSelectorProps = {
	staffs: StaffFragment[]
	selectedStaffId: string
	onChange: (staffId: string) => void
	removeHeading?: boolean
}

export const StaffSelector: React.FC<StaffSelectorProps> = ({ staffs, selectedStaffId, onChange, removeHeading }) => {
	const selectStaff = (staffId: string) => {
		onChange(staffId)
	}

	const deselectStaff = () => {
		onChange("")
	}

	const isStaffSelected = useCallback((staffId: string) => selectedStaffId.includes(staffId), [selectedStaffId])

	useEffect(() => {
		onChange(selectedStaffId)
	}, [selectedStaffId])

	const { currentItems, loadMore, hasMore } = usePagination<StaffFragment>(staffs, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
				{removeHeading ? "" : "Select the staff you want to generate the report for"}
			</Heading>
			<VStack w="full" align="stretch" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th textTransform="capitalize">Teacher</Th>
							<Th textTransform="capitalize">Created</Th>
							<Th textTransform="capitalize">Updated</Th>
						</Tr>
					</Thead>
					<Tbody>
						{currentItems.map((staff) => (
							<Tr key={staff._id} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td>
									<Checkbox
										colorScheme="blackAlpha"
										borderColor="black"
										isChecked={isStaffSelected(staff._id)}
										onChange={(e) => (e.target.checked ? selectStaff(staff._id) : deselectStaff())}
									/>
								</Td>
								<Td>
									<StaffTag staff={staff} />
								</Td>
								<Td>{format(new Date(staff.createdAt), "PP")}</Td>
								<Td>{format(new Date(staff.updatedAt), "PP")}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
				{hasMore && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
			</VStack>
		</VStack>
	)
}
