import { Center, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { SearchPointsRedeemItemsForStudentsQueryVariables, useSearchPointsRedeemItemsForStudentsQuery } from "../../graphql"
import { PointsRedeemItemsGrid } from "./PointsRedeemItemsGrid"

export type SearchResultPointsRedeemItemsForStudentsProps = {
	searchKeyword: string
}

export const SearchResultPointsRedeemItemsForStudents: React.FC<SearchResultPointsRedeemItemsForStudentsProps> = ({ searchKeyword }) => {
	const [pagination, setPagination] = useState<SearchPointsRedeemItemsForStudentsQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useSearchPointsRedeemItemsForStudentsQuery({
		variables: { pagination, keyword: searchKeyword },
	})

	const prevPage = () => {
		if (data?.searchPointsRedeemItemsForStudents.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.searchPointsRedeemItemsForStudents.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="md" fontWeight="normal" color="text.500">
				Showing {data?.searchPointsRedeemItemsForStudents.total || "all"} items for students for search keyword{" "}
				<strong>&quot;{searchKeyword}&quot;</strong>
			</Heading>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.searchPointsRedeemItemsForStudents.pointsRedeemItems && data?.searchPointsRedeemItemsForStudents.pointsRedeemItems.length > 0 ? (
				<PointsRedeemItemsGrid
					pointsRedeemItems={data.searchPointsRedeemItemsForStudents.pointsRedeemItems}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.searchPointsRedeemItemsForStudents.hasPrevPage}
					hasNextPage={data.searchPointsRedeemItemsForStudents.hasNextPage}
					currentPage={data.searchPointsRedeemItemsForStudents.currentPage}
					totalPages={data.searchPointsRedeemItemsForStudents.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any items.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
