import { HStack, Icon, VStack } from "@chakra-ui/react"
import {
	faBarChart,
	faBook,
	faBookReader,
	faBowlFood,
	faBoxesStacked,
	faBus,
	faCalendar,
	faChalkboard,
	faChalkboardTeacher,
	faChartColumn,
	faClipboardList,
	faClock,
	faCoins,
	faColumns,
	faGrip,
	faHouse,
	faIdCard,
	faMoneyBill,
	faPeopleGroup,
	faPhotoVideo,
	faSchoolLock,
	faTrophy,
	faUsers,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useActiveRestrictedEntriesQuery } from "../../graphql"
import { Logo } from "../common"
import { NavButton } from "./NavButton"

export const Nav: React.FC = () => {
	const [{ data }] = useActiveRestrictedEntriesQuery()

	return (
		<VStack
			pos="absolute"
			top="0"
			left="0"
			w={{ md: "280px", lg: "360px" }}
			h="100vh"
			bg="linear-gradient(165.59deg, #F7F6F4 0%, #F3F3F4 9.99%, #7F84EA 30.98%, #7F84EA 61.45%, #61C6FF 95.92%)"
			align="stretch"
			justify="space-between"
		>
			<VStack align="stretch" overflow="hidden">
				<HStack pl={{ base: 2, lg: 8 }} py="6">
					<Logo />
				</HStack>
				<VStack spacing={1} align="flex-start" overflow="auto" pt="16" pb="16">
					<NavButton to="/attendance" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faClipboardList} {...props} />} />}>
						Attendance
					</NavButton>

					<NavButton to="/dashboard" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faColumns} {...props} />} />}>
						Dashboard
					</NavButton>

					<NavButton to="/schoolSessions" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faClock} {...props} />} />}>
						School Sessions
					</NavButton>

					<NavButton to="/schoolTimings" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faClock} {...props} />} />}>
						School Timings
					</NavButton>

					<NavButton to="/students" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faUsers} {...props} />} />}>
						Students
					</NavButton>

					<NavButton to="/paytank" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faMoneyBill} {...props} />} />}>
						Paytank
					</NavButton>

					<NavButton to="/cafeteria" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faBowlFood} {...props} />} />}>
						Cafeteria
					</NavButton>

					<NavButton to="/classes" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faChalkboard} {...props} />} />}>
						Classes
					</NavButton>

					<NavButton to="/staff" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faChalkboardTeacher} {...props} />} />}>
						Staff
					</NavButton>

					<NavButton to="/feed" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faGrip} {...props} />} />}>
						Feed
					</NavButton>

					<NavButton to="/generateCards" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faIdCard} {...props} />} />}>
						Generate Cards
					</NavButton>

					<NavButton to="/reports" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faChartColumn} {...props} />} />}>
						Reports
					</NavButton>

					<NavButton to="/subjects" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faBook} {...props} />} />}>
						Subjects
					</NavButton>

					<NavButton to="/houses" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faHouse} {...props} />} />}>
						Houses
					</NavButton>

					<NavButton to="/departments" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faPeopleGroup} {...props} />} />}>
						Departments
					</NavButton>

					{data?.activeRestrictedEntries.length ? (
						<NavButton
							to="/restrictedZones"
							leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faSchoolLock} {...props} />} />}
							bgColor="red.600"
							color="white"
						>
							Restricted
						</NavButton>
					) : (
						<NavButton to="/restrictedZones" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faSchoolLock} {...props} />} />}>
							Restricted
						</NavButton>
					)}

					<NavButton to="/points" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faCoins} {...props} />} />}>
						Points
					</NavButton>

					<NavButton to="/showcases" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faTrophy} {...props} />} />}>
						Showcases
					</NavButton>

					<NavButton to="/workshops" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faBoxesStacked} {...props} />} />}>
						Workshops
					</NavButton>

					<NavButton to="/trainers" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faChalkboardTeacher} {...props} />} />}>
						Trainers
					</NavButton>

					<NavButton to="/resources" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faBookReader} {...props} />} />}>
						Learning Resources
					</NavButton>
					<NavButton to="/trendingSkills" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faBarChart} {...props} />} />}>
						Trending Skills
					</NavButton>
					<NavButton to="/calendar" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faCalendar} {...props} />} />}>
						Calendar
					</NavButton>
					<NavButton to="/gallery" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faPhotoVideo} {...props} />} />}>
						Gallery
					</NavButton>
					<NavButton to="/busRoutes" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faBus} {...props} />} />}>
						Bus Routes
					</NavButton>

					<NavButton to="/inventory" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faBoxesStacked} {...props} />} />}>
						Inventory
					</NavButton>
				</VStack>
			</VStack>
		</VStack>
	)
}
