import { Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Icon, Input, VStack } from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import * as Yup from "yup"
import { EditReaderLabelMutationVariables, ReaderFragment, useEditReaderLabelMutation } from "../../graphql"

const validationSchema = Yup.object({
	label: Yup.string().required().label("Reader Label"),
})

export type EditReaderLabelFormProps = { reader: ReaderFragment }

export const EditReaderLabelForm: React.FC<EditReaderLabelFormProps> = ({ reader }) => {
	const [{ fetching }, editReaderLabel] = useEditReaderLabelMutation()

	const onSubmit = async (values: EditReaderLabelMutationVariables, helpers: FormikHelpers<EditReaderLabelMutationVariables>) => {
		const { error } = await editReaderLabel(values)

		if (error) {
			helpers.setFieldError("label", error.message.replace("[GraphQL] ", ""))
		}
	}

	const formik = useFormik({
		initialValues: {
			label: reader.label || "",
			readerId: reader._id,
		},
		onSubmit,
		validationSchema,
	})

	return (
		<form onSubmit={formik.handleSubmit}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.errors.label && formik.touched.label)}>
					<FormLabel>Reader Label</FormLabel>
					<Input
						border="none"
						_focus={{ border: "none" }}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						autoComplete="off"
						{...formik.getFieldProps("label")}
					/>
					<FormHelperText>Ex: Gate No.1</FormHelperText>
					<FormErrorMessage>{formik.errors.label}</FormErrorMessage>
				</FormControl>

				<Button size="sm" type="submit" colorScheme="primary" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
					Save
				</Button>
			</VStack>
		</form>
	)
}
