import { Button, Icon, IconButton, Popover, PopoverArrow, PopoverContent, PopoverTrigger, VStack } from "@chakra-ui/react"
import { faFilePdf, faImage, faPaperclip, faVideo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { PropsWithChildren } from "react"
import { MediaTypes } from "../../graphql"
import { UploadPDF } from "./UploadPDF"
import { UploadPhoto } from "./UploadPhoto"
import { UploadVideo } from "./UploadVideo"

export type UploadMediaButtonProps = {
	allowedTypes?: MediaTypes[]
	maxUploads?: number
	hidePDFButton?: boolean
}

export const UploadMediaButton: React.FC<UploadMediaButtonProps & PropsWithChildren> = ({
	children,
	allowedTypes = [MediaTypes.Image, MediaTypes.Pdf, MediaTypes.Video],
	maxUploads,
	hidePDFButton,
}) => {
	return (
		<Popover placement="auto-end">
			<PopoverTrigger>
				{children || (
					<IconButton aria-label="upload-media-btn" variant="ghost" colorScheme="primary">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faPaperclip} {...props} />} />
					</IconButton>
				)}
			</PopoverTrigger>
			<PopoverContent maxW="48" bg="white" shadow="2xl" _focus={{ shadow: "2xl" }}>
				<PopoverArrow />
				<VStack w="full" align="stretch" spacing={0}>
					<UploadPhoto maxSize={1e8} max={maxUploads}>
						<Button
							w="full"
							as="span"
							rounded="none"
							justifyContent="flex-start"
							leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faImage} {...props} />} />}
							isDisabled={!allowedTypes.includes(MediaTypes.Image)}
						>
							Photo
						</Button>
					</UploadPhoto>
					<UploadVideo maxSize={1e8} max={maxUploads}>
						<Button
							w="full"
							as="span"
							rounded="none"
							justifyContent="flex-start"
							leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faVideo} {...props} />} />}
							isDisabled={!allowedTypes.includes(MediaTypes.Video)}
						>
							Video
						</Button>
					</UploadVideo>
					<UploadPDF maxSize={1e8} max={maxUploads}>
						<Button
							w="full"
							as="span"
							rounded="none"
							justifyContent="flex-start"
							leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faFilePdf} {...props} />} />}
							isDisabled={!allowedTypes.includes(MediaTypes.Pdf)}
							display={hidePDFButton ? "none" : "flex"}
						>
							PDF
						</Button>
					</UploadPDF>
				</VStack>
			</PopoverContent>
		</Popover>
	)
}
