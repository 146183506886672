import { Container, Heading, HStack, Icon, Input, InputGroup, InputRightAddon, Stack, useBreakpointValue, VStack } from "@chakra-ui/react"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { Nav, DashboardHeader } from "../../components"
import { AllTopics } from "../../components/topic"
import { SearchResultTopics } from "../../components/topic/SearchResultTopic"
import { GRADIENT } from "../../constants"
import { useDrawer } from "../../hooks"

export const Topics: React.FC = () => {
	const { isShown } = useDrawer()

	const [searchKeyword, setSearchKeyword] = useState("")

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Topics
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								{
									// TODO: add sub heading
								}
							</Heading>
						</VStack>
						<InputGroup maxW="sm" bg="white.500" rounded="xl" py="1">
							<Input placeholder="Search Topics" border="none" _focus={{ border: "none" }} onChange={(e) => setSearchKeyword(e.target.value)} />
							<InputRightAddon bg="transparent" border="none">
								<Icon as={(props: any) => <FontAwesomeIcon icon={faSearch} {...props} />} color="text.100" fontSize="lg" />
							</InputRightAddon>
						</InputGroup>
					</Stack>
					{searchKeyword ? <SearchResultTopics searchKeyword={searchKeyword} /> : <AllTopics />}
				</VStack>
			</HStack>
		</Container>
	)
}
