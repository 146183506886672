import { Center, Container, HStack, Spinner, Text, useBreakpointValue, VStack, Stack } from "@chakra-ui/react"
import React from "react"
import { useParams } from "react-router-dom"
import { DashboardHeader, Nav } from "../../components"
import { Resource } from "../../components/resource"
import { GRADIENT } from "../../constants"
import { useResourceByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const ResourcePage: React.FC = () => {
	const { resourceId = "" } = useParams<{ resourceId: string }>()

	const [{ data, fetching, error }] = useResourceByIdQuery({
		variables: { resourceId },
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })
	const isLarge = useBreakpointValue({ lg: true })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.resourceById ? (
						<Stack direction={isLarge ? "row" : "column"} w="full" maxW={isMobile ? "lg" : "unset"} align="flex-start" spacing="4">
							<Resource resource={data.resourceById} isLinkable={false} />
						</Stack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the resource.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
