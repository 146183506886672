import { Box, ButtonGroup, HStack, Icon, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { faArrowLeft, faArrowRight, faCheckCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { useNavigate } from "react-router-dom"
import { ExtendedWorkshopFragment } from "../../graphql"
import { Rating } from "../common"

export type WorkshopsTableProps = {
	workshops: ExtendedWorkshopFragment[]
	prevPage: () => void
	nextPage: () => void
	hasPrevPage: boolean
	hasNextPage: boolean
	currentPage: number
	totalPages: number
}

export const WorkshopsTable: React.FC<WorkshopsTableProps> = ({ workshops, prevPage, nextPage, hasPrevPage, hasNextPage, currentPage, totalPages }) => {
	const navigate = useNavigate()

	return (
		<VStack w="full" spacing="0">
			<HStack alignSelf="flex-end">
				<Text fontSize="xs" color="text.400">
					{currentPage}/{totalPages}
				</Text>
				<ButtonGroup size="xs" p="4">
					<IconButton aria-label="prev-btn" onClick={prevPage} isDisabled={!hasPrevPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowLeft} {...props} />} color="text.500" />
					</IconButton>
					<IconButton aria-label="next-btn" onClick={nextPage} isDisabled={!hasNextPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} color="text.500" />
					</IconButton>
				</ButtonGroup>
			</HStack>
			<Box w="full" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th textTransform="capitalize">Title</Th>
							<Th textTransform="capitalize">Description</Th>
							<Th textTransform="capitalize">Sessions</Th>
							<Th textTransform="capitalize">Status</Th>
							<Th textTransform="capitalize">Type</Th>
							<Th textTransform="capitalize">Price</Th>
							<Th textTransform="capitalize">Attendees</Th>
							<Th textTransform="capitalize">Ratings</Th>
							<Th textTransform="capitalize">Created</Th>
						</Tr>
					</Thead>
					<Tbody>
						{workshops.map((workshop) => (
							<Tr
								key={workshop._id}
								_hover={{ bg: "gray.100" }}
								transition="background-color 200ms ease-in"
								cursor="pointer"
								fontSize="sm"
								onClick={() => navigate(`/workshops/${workshop._id}`)}
							>
								<Td>{workshop.title}</Td>
								<Td>
									{workshop.description.substring(0, 100)}
									{workshop.description.length > 100 ? "..." : ""}
								</Td>
								<Td>{workshop.sessions?.length || 0}</Td>
								<Td>
									{workshop.isActive ? (
										<HStack>
											<Icon as={(props: any) => <FontAwesomeIcon icon={faCheckCircle} {...props} />} color="green.400" />
											<Text>Active</Text>
										</HStack>
									) : (
										<HStack>
											<Icon as={(props: any) => <FontAwesomeIcon icon={faXmarkCircle} {...props} />} color="red.400" />
											<Text>Inactive</Text>
										</HStack>
									)}
								</Td>
								<Td>
									{workshop.isLive ? "Live" : "Recorded"} | {workshop.isPublic ? "Public" : "Private"}
								</Td>
								<Td>{workshop.isPaid ? `₹${workshop.priceInINR}` : "Free"}</Td>
								<Td>{workshop.enrolledUsers?.length || 0}</Td>
								<Td>
									<Rating
										rating={
											(workshop.ratings?.map((a) => a.rating)?.reduce((a, b) => a + b, 0) || 0) / (workshop.ratings?.length || 0) || 0
										}
										total={workshop.ratings?.length || 0}
									/>
								</Td>
								<Td>{format(new Date(workshop.createdAt), "PP")}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
		</VStack>
	)
}
