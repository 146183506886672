import { Button, chakra, Icon, useToast } from "@chakra-ui/react"
import { faHeart as faSolidHeart } from "@fortawesome/free-solid-svg-icons"
import { faHeart as faRegularHeart } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { ExtendedFeedPostFragment, useDislikeFeedPostMutation, useLikeFeedPostMutation } from "../../graphql"

export type LikeFeedPostButtonProps = {
	post: ExtendedFeedPostFragment
}

export const LikeFeedPostButton: React.FC<LikeFeedPostButtonProps> = ({ post }) => {
	const [, likePost] = useLikeFeedPostMutation()
	const [, dislikePost] = useDislikeFeedPostMutation()

	const toast = useToast()

	const handleClick = async () => {
		const fn = post.isLiked ? dislikePost : likePost

		const { error } = await fn({ feedPostId: post._id })

		if (error) {
			toast({
				title: "Feed Post Error",
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}
	}

	return (
		<Button
			variant="ghost"
			colorScheme="blackAlpha"
			px="3"
			leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={post.isLiked ? faSolidHeart : faRegularHeart} {...props} />} color="#df70c6" />}
			onClick={handleClick}
		>
			<chakra.span fontSize="sm">{post.likes?.length || 0}</chakra.span>
		</Button>
	)
}
