import {
	Button,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	HStack,
	Icon,
	Input,
	SlideFade,
	Text,
	Textarea,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormikHelpers, useFormik } from "formik"
import React, { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { CreateGalleryMutationVariables, MediaTypes, useCreateGalleryMutation } from "../../graphql"
import { useUpload } from "../../hooks"
import { FeedAttachments } from "../feed/FeedAttachments"
import { UploadMediaButton } from "../upload"

const validationSchema = Yup.object({
	title: Yup.string().label("Title"),
	description: Yup.string().label("Description"),
})

export const NewGalleryForm: React.FC = () => {
	const [{ fetching }, createNew] = useCreateGalleryMutation()

	const toast = useToast()

	const navigate = useNavigate()

	// const isMobile = useBreakpointValue({ base: true, sm: false })

	const { clearUpload, clearAllUploads, uploads } = useUpload()

	const handleSubmit = async (values: CreateGalleryMutationVariables["input"], helpers: FormikHelpers<CreateGalleryMutationVariables["input"]>) => {
		const attachments: CreateGalleryMutationVariables["input"]["attachments"] = {}

		if (uploads?.length) {
			if (uploads[0].type === MediaTypes.Image) {
				attachments.pictures = uploads
					.filter(({ type, key, url, isUploaded }) => isUploaded && type === MediaTypes.Image && typeof key === "string" && typeof url === "string")
					.map(({ key, url }) => ({ key: key!, url: url! }))
			}

			if (uploads[0].type === MediaTypes.Video) {
				attachments.videos = uploads
					.filter(({ type, key, url, isUploaded }) => isUploaded && type === MediaTypes.Video && typeof key === "string" && typeof url === "string")
					.map(({ key, url }) => ({ key: key!, url: url! }))
			}

			if (uploads[0].type === MediaTypes.Pdf) {
				attachments.pdfs = uploads
					.filter(({ type, key, url, isUploaded }) => isUploaded && type === MediaTypes.Pdf && typeof key === "string" && typeof url === "string")
					.map(({ key, url }) => ({ key: key!, url: url! }))
			}
		}

		const { error, data } = await createNew({ input: { ...values, attachments } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createGallery.errors) {
			return data?.createGallery.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		helpers.setFieldValue("text", "")
		clearAllUploads()
		navigate("/gallery")
	}

	const formik = useFormik<CreateGalleryMutationVariables["input"]>({
		initialValues: { title: "", description: "" } as any,
		validationSchema,
		onSubmit: handleSubmit,
	})

	const allowedMediaTypes = useMemo(() => {
		if (uploads.length > 0) {
			return [uploads[0].type]
		}

		return [MediaTypes.Image, MediaTypes.Pdf, MediaTypes.Video]
	}, [uploads])

	return (
		<VStack w="full" maxW="md" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.title && formik.touched.title)} isRequired>
				<SlideFade in={Boolean(formik.values.title)} unmountOnExit>
					<FormLabel htmlFor="name" fontSize="sm">
						Title
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Title"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("title")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.title}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.description && formik.touched.description)} isRequired>
				<SlideFade in={Boolean(formik.values.description)} unmountOnExit>
					<FormLabel htmlFor="description" fontSize="sm">
						Description
					</FormLabel>
				</SlideFade>
				<Textarea
					placeholder="Description"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("description")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.description}</Text>
				</FormErrorMessage>
			</FormControl>

			<VStack w="full" maxW="lg" align="flex-start" rounded="xl" py={2} px={4} spacing={0}>
				<FeedAttachments onRemove={(id) => clearUpload({ id })} />
				<HStack w="full" justify="space-between">
					<UploadMediaButton hidePDFButton={true} allowedTypes={allowedMediaTypes}>
						<Button iconSpacing={0} leftIcon={<UploadMediaButton />}>
							Attachments
						</Button>
					</UploadMediaButton>

					<Button
						type="submit"
						colorScheme="primary"
						size="md"
						rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} />}
						isDisabled={!uploads?.length}
						isLoading={fetching}
					>
						Create
					</Button>
				</HStack>
			</VStack>
		</VStack>
	)
}
