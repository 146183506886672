import { Button, Center, FormControl, FormErrorMessage, FormLabel, Icon, Select, SlideFade, Spinner, Text, useToast, VStack } from "@chakra-ui/react"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { AddWorkshopSessionTrainerMutationVariables, useAddWorkshopSessionTrainerMutation, useTrainersWithoutPaginationQuery } from "../../graphql"

const validationSchema = Yup.object({
	name: Yup.string().label("name"),
	description: Yup.string().label("Description"),
})

export type AddWorkshopSessionTrainerFormProps = {
	workshopId: string
	workshopSessionId: string
}

export const AddWorkshopSessionTrainerForm: React.FC<AddWorkshopSessionTrainerFormProps> = ({ workshopId, workshopSessionId }) => {
	const [{ fetching }, addTrainer] = useAddWorkshopSessionTrainerMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async ({ trainerId }: Pick<AddWorkshopSessionTrainerMutationVariables, "trainerId">) => {
		const { error } = await addTrainer({ workshopSessionId, trainerId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		navigate(`/workshops/${workshopId}/sessions/${workshopSessionId}`)
	}

	const formik = useFormik<Pick<AddWorkshopSessionTrainerMutationVariables, "trainerId">>({
		initialValues: { trainerId: "" },
		validationSchema,
		onSubmit: handleSubmit,
	})

	const [{ data: trainersData, fetching: fetchingTrainers, error: trainersError }] = useTrainersWithoutPaginationQuery()

	return (
		<VStack w="full" maxW="md" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			{fetchingTrainers ? (
				<Center w="full" py="1">
					<VStack>
						<Spinner size="sm" color="text.400" />
						<Text fontSize="xs" color="text.400">
							Loading staff
						</Text>
					</VStack>
				</Center>
			) : trainersError ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							{trainersError.message.replace("[GraphQL] ", "")}
						</Text>
					</VStack>
				</Center>
			) : (
				<FormControl isInvalid={Boolean(formik.errors.trainerId && formik.touched.trainerId)}>
					<SlideFade in={Boolean(formik.values.trainerId)} unmountOnExit>
						<FormLabel htmlFor="trainerId" fontSize="sm">
							Trainer
						</FormLabel>
					</SlideFade>
					<Select
						placeholder="Select trainer"
						border="none"
						_focus={{
							border: "none",
						}}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						autoComplete="off"
						{...formik.getFieldProps("trainerId")}
					>
						{trainersData?.trainersWithoutPagination.map(({ _id, name }) => (
							<option key={_id} value={_id}>
								{name}
							</option>
						))}
					</Select>
					<FormErrorMessage>{formik.errors.trainerId}</FormErrorMessage>
				</FormControl>
			)}

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Add
			</Button>
		</VStack>
	)
}
