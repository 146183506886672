import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	AlertDialogProps,
	Button,
	Heading,
} from "@chakra-ui/react"
import { FocusableElement } from "@chakra-ui/utils"
import React, { RefObject, useRef } from "react"

export type DeleteFeedPostAlertProps = { handleDelete: () => void } & Omit<AlertDialogProps, "children" | "leastDestructiveRef">

export const DeleteFeedPostAlert: React.FC<DeleteFeedPostAlertProps> = ({ handleDelete, ...props }) => {
	const leastDestructiveRef = useRef() as RefObject<FocusableElement>

	return (
		<AlertDialog leastDestructiveRef={leastDestructiveRef} {...props}>
			<AlertDialogOverlay backdropFilter="blur(16px)">
				<AlertDialogContent>
					<AlertDialogHeader>
						<Heading fontSize="md" color="text.500">
							Are you sure?
						</Heading>
						<AlertDialogCloseButton colorScheme="text" />
					</AlertDialogHeader>

					<AlertDialogBody fontSize="sm">You cannot undo after deleting this post.</AlertDialogBody>

					<AlertDialogFooter>
						<Button ref={leastDestructiveRef as React.LegacyRef<HTMLButtonElement>} size="sm" onClick={props.onClose}>
							Cancel
						</Button>
						<Button colorScheme="red" size="sm" onClick={handleDelete} ml={3}>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	)
}
