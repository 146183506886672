import { StudentTelegramMessageReportResponse } from "../graphql"

export const parseStudentTelegramMessagesReportAsCSV = (labelledMessages: StudentTelegramMessageReportResponse) => {
	let data = "Student, Class, Transport Mode"

	labelledMessages.labels.forEach((label) => {
		data = data.concat("," + label)
	})

	labelledMessages.messages.forEach(({ student, messages }) => {
		data = data.concat("\n")
		data = data.concat(
			`${student.name} @${student.username}, ${student.class.name}-${student.classSection.name}, ${
				student.transportMode === "School Bus" ? student.busRoute?.routeName : "Walker"
			}, ${messages.map(({ messages }) => `${[...new Set(messages.map((m) => m.type))].join(" | ")}`)}`
		)
	})

	return data
}
