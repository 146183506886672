import {
	Button,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	HStack,
	Icon,
	Input,
	Select,
	Text,
	Textarea,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { isValid, startOfDay } from "date-fns"
import { useFormik } from "formik"
import React from "react"
import DatePicker from "react-date-picker"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import {
	ManualCafeteriaRegistrationDetails,
	ManualCafeteriaRegistrationDetailsFragment,
	ManualCafeteriaRegistrationDetailsInput,
	useUpdateManualCafeteriaRegistrationLogMutation,
} from "../../graphql"

enum PaymentModes {
	NEFT = "NEFT",
	RTGS = "RTGS",
	Cash = "Cash",
	Other = "Other",
}

export type UpdateManualCafeteriaRegistrationLogFormProps = {
	cafeteriaRegistrationId: string
	manualCafeteriaRegistrationDetails: ManualCafeteriaRegistrationDetails
}

export const UpdateManualCafeteriaRegistrationLogForm: React.FC<UpdateManualCafeteriaRegistrationLogFormProps> = ({
	cafeteriaRegistrationId,
	manualCafeteriaRegistrationDetails,
}) => {
	const [{ fetching }, updateCafeteriaRegistration] = useUpdateManualCafeteriaRegistrationLogMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handlePayment = async (values: ManualCafeteriaRegistrationDetailsInput & { otherPaymentMode?: string }) => {
		const input: ManualCafeteriaRegistrationDetailsInput = {
			paymentDate: startOfDay(values.paymentDate),
			paymentReferenceNo: values.paymentReferenceNo,
			amountPaid: values.amountPaid,
			paymentMode: values.paymentMode === PaymentModes.Other.toString() && values.otherPaymentMode ? values.otherPaymentMode : values.paymentMode,
			validSince: startOfDay(values.validSince),
			remarks: values.remarks,
		}

		const { error } = await updateCafeteriaRegistration({ cafeteriaRegistrationLogId: cafeteriaRegistrationId, input })

		if (error) {
			return toast({ description: error.message.replace("[GraphQL] ", ""), status: "error" })
		}

		toast({
			title: "Updated",
			description: "Successfully updated manual cafeteria registration",
			status: "success",
		})

		navigate(`/cafeteria/registrations/${cafeteriaRegistrationId}`, { replace: true })

		return
	}

	const formik = useFormik<ManualCafeteriaRegistrationDetailsInput & { otherPaymentMode?: string }>({
		initialValues: {
			paymentDate: new Date(manualCafeteriaRegistrationDetails.paymentDate),
			paymentReferenceNo: manualCafeteriaRegistrationDetails.paymentReferenceNo,
			amountPaid: manualCafeteriaRegistrationDetails.amountPaid,
			paymentMode: Object.keys(PaymentModes).includes(manualCafeteriaRegistrationDetails.paymentMode)
				? manualCafeteriaRegistrationDetails.paymentMode
				: "Other",
			otherPaymentMode: !Object.keys(PaymentModes).includes(manualCafeteriaRegistrationDetails.paymentMode)
				? manualCafeteriaRegistrationDetails.paymentMode
				: undefined,
			remarks: manualCafeteriaRegistrationDetails.remarks,
		} as ManualCafeteriaRegistrationDetailsFragment,
		validationSchema: Yup.object({
			paymentDate: Yup.date().required().label("Payment Date"),
			paymentReferenceNo: Yup.string().min(4).required().label("Payment Reference Number"),
			amountPaid: Yup.number().min(0).required().label("Amount Paid"),
			paymentMode: Yup.string().required().label("Payment Mode"),
			otherPaymentMode: Yup.string().nullable().label("Other Payment Mode"),
			validSince: Yup.date().nullable().label("Valid Since"),
			remarks: Yup.string().min(5).required().label("Remarks"),
		}),
		onSubmit: handlePayment,
	})

	return (
		<VStack w="full" maxW="md" align="stretch" spacing="8" as="form" onSubmit={formik.handleSubmit as any}>
			<VStack w="full" spacing="4">
				<HStack w="full">
					<FormControl flex={1} isInvalid={Boolean(formik.touched.paymentDate && formik.errors.paymentDate)} isRequired>
						<FormLabel htmlFor="paymentDate" fontSize="sm">
							Payment Date
						</FormLabel>

						<DatePicker
							onChange={(value: Date) => isValid(value) && formik.setFieldValue("paymentDate", value as Date)}
							value={formik.values.paymentDate}
							format="MMM dd, y"
							clearIcon={null}
						/>

						<FormErrorMessage>{formik.errors.paymentDate as string}</FormErrorMessage>
					</FormControl>

					{manualCafeteriaRegistrationDetails?.validSince && (
						<FormControl flex={1} isInvalid={Boolean(formik.touched.validSince && formik.errors.validSince)}>
							<FormLabel htmlFor="validSince" fontSize="sm">
								Effective From
							</FormLabel>

							<DatePicker
								onChange={(value: Date) =>
									isValid(value) ? formik.setFieldValue("validSince", value as Date) : formik.setFieldValue("validSince", undefined)
								}
								value={formik.values.validSince}
								format="MMM dd, y"
							/>

							<FormErrorMessage>{formik.errors.validSince as string}</FormErrorMessage>
						</FormControl>
					)}
				</HStack>

				<FormControl isInvalid={Boolean(formik.errors.paymentReferenceNo && formik.touched.paymentReferenceNo)} isRequired>
					<FormLabel htmlFor="name" fontSize="sm">
						Payment Reference Number
					</FormLabel>

					<Input
						placeholder="Enter Payment Reference Number"
						border="none"
						_focus={{ border: "none" }}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						autoComplete="off"
						{...formik.getFieldProps("paymentReferenceNo")}
					/>
					<FormErrorMessage>
						<FormErrorIcon />
						<Text>{formik.errors.paymentReferenceNo}</Text>
					</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.errors.paymentMode && formik.touched.paymentMode)} isRequired>
					<FormLabel htmlFor="paymentMode" fontSize="sm">
						Payment Mode
					</FormLabel>

					<Select
						placeholder="Select Payment Mode"
						border="none"
						_focus={{ border: "none" }}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						{...formik.getFieldProps("paymentMode")}
					>
						{Object.values(PaymentModes).map((s) => (
							<option key={s} value={s}>
								{s}
							</option>
						))}
					</Select>
					<FormErrorMessage>
						<FormErrorIcon />
						<Text>{formik.errors.paymentMode}</Text>
					</FormErrorMessage>
				</FormControl>

				{formik.values.paymentMode === "Other" && (
					<FormControl isInvalid={Boolean(formik.errors.otherPaymentMode && formik.touched.otherPaymentMode)}>
						<Input
							placeholder="Enter Other Payment Mode"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("otherPaymentMode")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{formik.errors.otherPaymentMode}</Text>
						</FormErrorMessage>
					</FormControl>
				)}

				<FormControl isInvalid={Boolean(formik.errors.amountPaid && formik.touched.amountPaid)} isRequired>
					<FormLabel htmlFor="amountPaid" fontSize="sm">
						Amount Paid
					</FormLabel>

					<Input
						placeholder="Enter Amount Paid"
						type="number"
						border="none"
						_focus={{ border: "none" }}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						autoComplete="off"
						{...formik.getFieldProps("amountPaid")}
					/>
					<FormErrorMessage>
						<FormErrorIcon />
						<Text>{formik.errors.amountPaid}</Text>
					</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.errors.remarks && formik.touched.remarks)} isRequired>
					<FormLabel htmlFor="remarks" fontSize="sm">
						Remarks
					</FormLabel>

					<Textarea
						placeholder="Enter remarks"
						border="none"
						_focus={{ border: "none" }}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						autoComplete="off"
						{...formik.getFieldProps("remarks")}
					/>
					<FormErrorMessage>
						<FormErrorIcon />
						<Text>{formik.errors.remarks}</Text>
					</FormErrorMessage>
				</FormControl>
			</VStack>

			<Button type="submit" colorScheme="green" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Save
			</Button>
		</VStack>
	)
}
