import { Container, Heading, HStack, Link, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import { format } from "date-fns"
import React, { useState } from "react"
import DateTimePicker from "react-datetime-picker"
import { NavLink } from "react-router-dom"
import { ActiveRestrictedEntries, DashboardHeader, Nav, StaffMonthlyAttendanceGraph, TodayStaffStats } from "../../components"
import { GRADIENT } from "../../constants"
import { useDrawer } from "../../hooks"

export const StaffAttendance: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [date, setDate] = useState(new Date(format(new Date(), "MM/dd/yyyy")))

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Staff Attendance
							</Heading>
							<Text fontSize="sm">
								See attendance for{" "}
								<Link as={NavLink} to="/attendance/students" fontWeight="semibold" color="purple" textDecor="underline">
									students
								</Link>
							</Text>
						</VStack>
						<Stack alignSelf="flex-end" maxW="xs" mr="5" pr="5">
							<DateTimePicker onChange={setDate} value={date} format="MMM dd, y" maxDate={new Date()} clearIcon={null} />
						</Stack>
					</Stack>
					<ActiveRestrictedEntries />
					<TodayStaffStats date={date} />
					<StaffMonthlyAttendanceGraph />
				</VStack>
			</HStack>
		</Container>
	)
}
