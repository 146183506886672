import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useRewardsOfStudentQuery } from "../../graphql"
import { StudentRewardListItem } from "./StudentRewardListItem"

export type StudentRewardsListProps = {
	studentId: string
}

export const StudentRewardsList: React.FC<StudentRewardsListProps> = ({ studentId }) => {
	const [{ data, fetching, error }] = useRewardsOfStudentQuery({ variables: { studentId } })

	return (
		<VStack w="full" align="stretch">
			{fetching ? (
				<Center w="full" py="4">
					<Spinner color="text.400" />
				</Center>
			) : error ? (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="text.400">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.rewardsOfStudent.length ? (
				<VStack spacing={4}>
					{data.rewardsOfStudent.map((reward) => (
						<StudentRewardListItem key={reward._id} reward={reward} />
					))}
				</VStack>
			) : (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="text.400">
						Couldn&apos;t find any rewards.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
