import {
	Button,
	Center,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	Icon,
	NumberInput,
	NumberInputField,
	SlideFade,
	Spinner,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React, { useState } from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { CreateCafeteriaRegistrationChargeMutationVariables, useCreateCafeteriaRegistrationChargeMutation, useSchoolTimingsQuery } from "../../../graphql"
import { SchoolTimingSelector } from "../../schoolTiming"

const validationSchema = Yup.object({
	amount: Yup.string().min(0).required().label("Amount"),
})

export const NewCafeteriaRegistrationChargeForm: React.FC = () => {
	const [{ fetching }, createNew] = useCreateCafeteriaRegistrationChargeMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const [selectedSchoolTimingId, setSelectedSchoolTimingId] = useState<string>("")

	const [{ data: schoolTimingsData, fetching: fetchingSchoolTimings, error: schoolTimingsError }] = useSchoolTimingsQuery()

	const handleSubmit = async (
		{ amount }: CreateCafeteriaRegistrationChargeMutationVariables["input"],
		helpers: FormikHelpers<CreateCafeteriaRegistrationChargeMutationVariables["input"]>
	) => {
		const { error, data } = await createNew({ input: { amount }, schoolTimingId: selectedSchoolTimingId })

		if (error) {
			return toast({
				description: error.message,
				status: "error",
			})
		}

		if (data?.createCafeteriaRegistrationCharge.errors) {
			return data?.createCafeteriaRegistrationCharge.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		return navigate("/cafeteria/registrationCharges", { replace: true })
	}

	const formik = useFormik<CreateCafeteriaRegistrationChargeMutationVariables["input"]>({
		initialValues: { amount: 0 },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<VStack w="full" align="flex-start" maxW="xl">
				{fetchingSchoolTimings ? (
					<VStack w="full" maxW="2xl">
						<Spinner />
						<Text textAlign="center">Fetching school timings</Text>
					</VStack>
				) : schoolTimingsError ? (
					<Center>
						<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
							Failed loading school timings, try reloading the page
						</Text>
					</Center>
				) : schoolTimingsData?.schoolTimings && schoolTimingsData.schoolTimings.length > 0 ? (
					<SchoolTimingSelector
						schoolTimings={schoolTimingsData.schoolTimings}
						selectedSchoolTimingId={selectedSchoolTimingId}
						onChange={setSelectedSchoolTimingId}
					/>
				) : (
					<Center py="4">
						<Text fontSize="md" fontWeight="semibold" color="text.400">
							Couldn&apos;t find any school timings.
						</Text>
					</Center>
				)}
			</VStack>

			<FormControl isInvalid={Boolean(formik.errors.amount && formik.touched.amount)}>
				<SlideFade in={!Number.isNaN(formik.values.amount)} unmountOnExit>
					<FormLabel htmlFor="title" fontSize="sm">
						Amount
					</FormLabel>
				</SlideFade>
				<NumberInput
					min={1}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("amount")}
					onChange={(_, value) => formik.setFieldValue("amount", value || 0)}
				>
					<NumberInputField />
				</NumberInput>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.amount}</Text>
				</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
