import {
	AspectRatio,
	AspectRatioProps,
	Box,
	BoxProps,
	Grid,
	Heading,
	HStack,
	Icon,
	IconButton,
	LinkBox,
	LinkOverlay,
	Spinner,
	Stack,
	Text,
	VStack,
} from "@chakra-ui/react"
import { faArrowRight, faExclamationCircle, faExternalLink } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion, MotionProps } from "framer-motion"
import React, { ComponentType, useState } from "react"
import { NavLink } from "react-router-dom"
import { useAttendanceStatsQuery } from "../../graphql"
import { useDrawer } from "../../hooks"
import { ClassWiseAttendance } from "./ClassWiseAttendance"

const MotionAspectRatio = motion<MotionProps & Omit<AspectRatioProps, "transition">>(
	AspectRatio as unknown as ComponentType<MotionProps & Omit<AspectRatioProps, "transition">>
)

const MotionBox = motion<MotionProps & Omit<AspectRatioProps, "transition">>(Box as unknown as ComponentType<MotionProps & Omit<BoxProps, "transition">>)

export type TodayStatsProps = {
	date: Date
	classId?: string
	classSectionId?: string
}

export const TodayStats: React.FC<TodayStatsProps> = ({ date, classId, classSectionId }) => {
	const [{ data, fetching, error }] = useAttendanceStatsQuery({
		variables: { date, classId, classSectionId },
	})

	const [isTotalHovered, setIsTotalHovered] = useState(false)
	const [isPresentHovered, setIsPresentHovered] = useState(false)
	const [isAbsentHovered, setIsAbsentHovered] = useState(false)
	const [isInHovered, setIsInHovered] = useState(false)
	const [isOutHovered, setIsOutHovered] = useState(false)

	const { isShown } = useDrawer()

	return (
		<VStack w="full" align="stretch">
			<HStack>
				<Heading fontSize="md" fontWeight="semibold">
					Today
				</Heading>
				<Icon fontSize="md" as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} />
			</HStack>
			<Stack direction={{ base: "column", [isShown ? "lg" : "md"]: "row" }} w="full" align={{ base: "center", [isShown ? "lg" : "md"]: "stretch" }}>
				{fetching ? (
					<VStack flex={8} w="full" maxW="2xl">
						<Spinner />
						<Text textAlign="center">Loading today&apos;s attendance</Text>
					</VStack>
				) : error ? (
					<VStack flex={8} w="full" maxW="2xl" color="red.800">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />} />
						<Text textAlign="center">{error?.message.replace("[GraphQL] ", "")}</Text>
					</VStack>
				) : data?.attendanceStats ? (
					<VStack flex={8} w="full" maxW="2xl" align="stretch">
						<Grid w="full" h="full" templateColumns={{ base: "repeat(4, 1fr)" }} gap={2}>
							<MotionAspectRatio
								w="full"
								minW="28"
								maxW="52"
								ratio={1}
								initial={{ opacity: 0, translateY: "24px" }}
								animate={{ opacity: 1, translateY: 0 }}
							>
								<VStack as={LinkBox} w="full" bgColor="blackAlpha.200" backdropFilter="blur(16px)" rounded="xl">
									<LinkOverlay
										as={NavLink}
										to="/students"
										onMouseOver={() => setIsTotalHovered(true)}
										onMouseOut={() => setIsTotalHovered(false)}
									/>
									<HStack>
										<Text fontSize="sm">Total</Text>
										<IconButton
											aria-label="see-total-students-btn"
											size="xs"
											variant="unstyled"
											transform={isTotalHovered ? "translate(4px, -4px) scale(1.1)" : ""}
										>
											<Icon as={(props: any) => <FontAwesomeIcon icon={faExternalLink} {...props} />} />
										</IconButton>
									</HStack>
									<Heading fontSize="3xl" textAlign="center">
										{data.attendanceStats.total}
									</Heading>
								</VStack>
							</MotionAspectRatio>

							<MotionAspectRatio
								w="full"
								minW="28"
								maxW="52"
								ratio={1}
								initial={{ opacity: 0, translateY: "24px" }}
								animate={{ opacity: 1, translateY: 0 }}
								transition={{ delay: 0.1 }}
							>
								<Box as={LinkBox} pos="relative" w="full" bgColor="blackAlpha.200" backdropFilter="blur(16px)" rounded="xl">
									<LinkOverlay
										as={NavLink}
										to="/attendance/students/present"
										onMouseOver={() => setIsPresentHovered(true)}
										onMouseOut={() => setIsPresentHovered(false)}
									/>
									<MotionBox
										pos="absolute"
										top="100%"
										animate={{
											top: `${(data.attendanceStats.absent.length / data.attendanceStats.total) * 100}%`,
										}}
										transition={{ delay: 0.4, duration: 0.4 }}
										bottom="0"
										left="0"
										right="0"
										bgColor="green"
										opacity={0.1}
										zIndex={-1}
									/>
									<VStack>
										<HStack color="green.800">
											<Text fontSize="sm">Present</Text>
											<IconButton
												aria-label="see-today-attendances-btn"
												size="xs"
												variant="unstyled"
												transform={isPresentHovered ? "translate(4px, -4px) scale(1.1)" : ""}
											>
												<Icon as={(props: any) => <FontAwesomeIcon icon={faExternalLink} {...props} />} />
											</IconButton>
										</HStack>
										<Heading fontSize="3xl" textAlign="center">
											{data.attendanceStats.present.length}
										</Heading>
									</VStack>
								</Box>
							</MotionAspectRatio>

							<MotionAspectRatio
								w="full"
								minW="28"
								maxW="52"
								ratio={1}
								initial={{ opacity: 0, translateY: "24px" }}
								animate={{ opacity: 1, translateY: 0 }}
								transition={{ delay: 0.2 }}
							>
								<Box as={LinkBox} w="full" bgColor="blackAlpha.200" backdropFilter="blur(16px)" rounded="xl">
									<LinkOverlay
										as={NavLink}
										to="/attendance/students/absent"
										onMouseOver={() => setIsAbsentHovered(true)}
										onMouseOut={() => setIsAbsentHovered(false)}
									/>
									<MotionBox
										pos="absolute"
										top="0%"
										animate={{
											top: `${
												data.attendanceStats.absent.length
													? 100 - (data.attendanceStats.absent.length / data.attendanceStats.total) * 100
													: 100
											}%`,
										}}
										bottom="0"
										transition={{ delay: 0.5, duration: 0.4 }}
										left="0"
										right="0"
										bgColor="red"
										opacity={0.1}
										zIndex={-1}
									/>
									<VStack>
										<HStack color="red.800">
											<Text fontSize="sm">Absent</Text>
											<IconButton
												aria-label="see-today-attendances-btn"
												size="xs"
												variant="unstyled"
												transform={isAbsentHovered ? "translate(4px, -4px) scale(1.1)" : ""}
											>
												<Icon as={(props: any) => <FontAwesomeIcon icon={faExternalLink} {...props} />} />
											</IconButton>
										</HStack>
										<Heading fontSize="3xl" textAlign="center">
											{data.attendanceStats.absent.length}
										</Heading>
									</VStack>
								</Box>
							</MotionAspectRatio>

							<Grid w="full" h="full" gap={2}>
								<MotionAspectRatio
									w="full"
									minW="28"
									maxW="52"
									ratio={1}
									initial={{ opacity: 0, translateY: "24px" }}
									animate={{ opacity: 1, translateY: 0 }}
								>
									<VStack as={LinkBox} w="full" bgColor="blackAlpha.200" backdropFilter="blur(16px)" rounded="xl">
										<LinkOverlay
											as={NavLink}
											to="/attendance/students/in"
											onMouseOver={() => setIsInHovered(true)}
											onMouseOut={() => setIsInHovered(false)}
										/>
										<HStack>
											<Text fontSize="sm">Students In</Text>
											<IconButton
												aria-label="see-in-students-btn"
												size="xs"
												variant="unstyled"
												transform={isInHovered ? "translate(4px, -4px) scale(1.1)" : ""}
											>
												<Icon as={(props: any) => <FontAwesomeIcon icon={faExternalLink} {...props} />} />
											</IconButton>
										</HStack>
										<Heading fontSize="3xl" textAlign="center">
											{data.attendanceStats.inStudents.length}
										</Heading>
									</VStack>
								</MotionAspectRatio>
								<MotionAspectRatio
									w="full"
									minW="28"
									maxW="52"
									ratio={1}
									initial={{ opacity: 0, translateY: "24px" }}
									animate={{ opacity: 1, translateY: 0 }}
									transition={{ delay: 0.1 }}
								>
									<Box as={LinkBox} pos="relative" w="full" bgColor="blackAlpha.200" backdropFilter="blur(16px)" rounded="xl">
										<LinkOverlay
											as={NavLink}
											to="/attendance/students/out"
											onMouseOver={() => setIsOutHovered(true)}
											onMouseOut={() => setIsOutHovered(false)}
										/>
										<MotionBox
											pos="absolute"
											top="100%"
											animate={{
												top: `${100 - (data.attendanceStats.outStudents.length / data.attendanceStats.inStudents.length) * 100}%`,
											}}
											transition={{ delay: 0.4, duration: 0.4 }}
											bottom="0"
											left="0"
											right="0"
											bgColor="green"
											opacity={0.1}
											zIndex={-1}
										/>
										<VStack>
											<HStack color="green.800">
												<Text fontSize="sm">Students Out</Text>
												<IconButton
													aria-label="see-out-attendances-btn"
													size="xs"
													variant="unstyled"
													transform={isOutHovered ? "translate(4px, -4px) scale(1.1)" : ""}
												>
													<Icon as={(props: any) => <FontAwesomeIcon icon={faExternalLink} {...props} />} />
												</IconButton>
											</HStack>
											<Heading fontSize="3xl" textAlign="center">
												{data.attendanceStats.outStudents.length}
											</Heading>
										</VStack>
									</Box>
								</MotionAspectRatio>
							</Grid>
						</Grid>
					</VStack>
				) : (
					<VStack flex={8} w="full" maxW="2xl" color="red.800">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />} />
						<Text textAlign="center">Today&apos;s attendance not available</Text>
					</VStack>
				)}

				<VStack flex={2} bgColor="blackAlpha.200" backdropFilter="blur(16px)" p="4" rounded="xl" maxW={{ base: 48, lg: "full" }}>
					<ClassWiseAttendance date={date} />
				</VStack>
			</Stack>
		</VStack>
	)
}
