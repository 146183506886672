import {
	AspectRatio,
	Button,
	ButtonGroup,
	Center,
	chakra,
	Container,
	Divider,
	Flex,
	Heading,
	HStack,
	Icon,
	IconButton,
	Spinner,
	Stack,
	Tag,
	Text,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react"
import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { NavLink, useParams } from "react-router-dom"
import { ActivateWorkshopSessionButton, DashboardHeader, DeactivateWorkshopSessionButton, Image, Nav, Rating, WorkshopTrainer } from "../../components"
import { UploadWorkshopSessionThumbnail } from "../../components/upload"
import { GRADIENT } from "../../constants"
import { useWorkshopSessionByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const WorkshopSession: React.FC = () => {
	const { workshopId = "", workshopSessionId = "" } = useParams<{ workshopId: string; workshopSessionId: string }>()

	const [{ data, fetching, error }] = useWorkshopSessionByIdQuery({
		variables: { workshopSessionId },
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.workshopSessionById ? (
						<VStack w="full" align="stretch">
							<Stack direction={{ base: "column", lg: "row" }} w="full" align="stretch" spacing={6}>
								<VStack w="full" align="flex-start" spacing="4">
									<UploadWorkshopSessionThumbnail workshopSessionId={data.workshopSessionById._id}>
										<AspectRatio w="full" maxW="xl" cursor="pointer" ratio={16 / 9}>
											<Image
												src={data.workshopSessionById.thumbnail?.url}
												bgColor="gray.200"
												rounded="xl"
												alt={data.workshopSessionById.title}
											/>
										</AspectRatio>
									</UploadWorkshopSessionThumbnail>
									<VStack w="full" maxW="xl" align="flex-start">
										{data.workshopSessionById.isActive ? <Tag colorScheme="green">Active</Tag> : <Tag colorScheme="red">Inactive</Tag>}

										<Heading as="h1" fontSize="xl" color="purple.500">
											<chakra.span fontSize="md" color="blackAlpha.600" fontWeight="semibold">
												Session:{" "}
											</chakra.span>
											{data.workshopSessionById.title}
										</Heading>

										<HStack w="full" justify="space-between">
											<HStack as={NavLink} to={`/workshops/${data.workshopSessionById.workshopId}`} w="full" align="flex-start">
												<AspectRatio w="full" maxW="32" ratio={16 / 9}>
													<Image src={data.workshopSessionById.workshop.thumbnail?.url} rounded="lg" />
												</AspectRatio>
												<VStack w="full" align="stretch" spacing={0}>
													<Heading fontSize="sm">
														{data.workshopSessionById.workshop.title.slice(0, 10)}
														{data.workshopSessionById.workshop.title.length > 10 ? "..." : ""}
													</Heading>
													<Rating
														rating={
															(data.workshopSessionById.ratings?.map((a) => a.rating)?.reduce((a, b) => a + b, 0) || 0) /
																(data.workshopSessionById.ratings?.length || 0) || 0
														}
														total={data.workshopSessionById.ratings?.length || 0}
													/>
												</VStack>
											</HStack>
											<Rating
												rating={
													(data.workshopSessionById.ratings?.map((a) => a.rating)?.reduce((a, b) => a + b, 0) || 0) /
														(data.workshopSessionById.ratings?.length || 0) || 0
												}
												total={data.workshopSessionById.ratings?.length || 0}
											/>
										</HStack>
									</VStack>

									<VStack w="full" align="stretch" spacing={12}>
										<VStack align="flex-start" spacing={0}>
											<Text fontSize="xs" color="blackAlpha.700" fontWeight="semibold">
												About the session
											</Text>
											<Text fontSize="sm" maxW="xl" whiteSpace="pre-line">
												{data.workshopSessionById.description}
											</Text>
										</VStack>

										<VStack align="flex-start" spacing={0}>
											<Text fontSize="xs" color="blackAlpha.700" fontWeight="semibold">
												Benefit of this session
											</Text>
											<Text fontSize="sm" maxW="xl" whiteSpace="pre-line">
												{data.workshopSessionById.benefit}
											</Text>
										</VStack>

										<VStack align="flex-start" spacing={0}>
											<Text fontSize="xs" color="blackAlpha.700" fontWeight="semibold">
												Trainers
											</Text>

											<Flex flexWrap="wrap" alignItems="center">
												{data.workshopSessionById.trainers?.map((trainer) => (
													<WorkshopTrainer
														key={trainer._id}
														workshopId={workshopId}
														workshopSessionId={workshopSessionId}
														trainer={trainer}
													/>
												))}

												<IconButton
													m="1"
													aria-label="add-trainer-btn"
													variant="outline"
													colorScheme="blackAlpha"
													size="sm"
													as={NavLink}
													to="addTrainer"
												>
													<Icon as={(props: any) => <FontAwesomeIcon icon={faPlus} {...props} />} />
												</IconButton>
											</Flex>
										</VStack>
									</VStack>
								</VStack>
							</Stack>
							<VStack w="full" maxW="xl" align="stretch" spacing={4}>
								<Divider borderColor="purple.200" />
								<Heading fontSize="sm">Actions</Heading>
								<ButtonGroup>
									<Button
										size="xs"
										variant="outline"
										colorScheme="purple"
										rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faPencil} {...props} />} fontSize="sm" />}
										as={NavLink}
										to="edit"
									>
										Edit
									</Button>
									{data.workshopSessionById.isActive ? (
										<DeactivateWorkshopSessionButton workshopSessionId={workshopSessionId} />
									) : (
										<ActivateWorkshopSessionButton workshopSessionId={workshopSessionId} />
									)}
								</ButtonGroup>
							</VStack>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the workshop session.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
