/* eslint-disable @typescript-eslint/indent */
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import React from "react"
import { StudentTelegramMessageReportResponse } from "../../graphql"
import { StudentTag } from "../student"

export type StudentTelegramMessagesTableProps = {
	labelledMessages: StudentTelegramMessageReportResponse
}

export const StudentTelegramMessagesTable: React.FC<StudentTelegramMessagesTableProps> = ({ labelledMessages }) => {
	return (
		<Box w="full" overflow="auto">
			<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
				<Thead>
					<Tr>
						<Th textTransform="capitalize">Student</Th>
						{labelledMessages.labels.map((label) => (
							<Th key={label} textTransform="capitalize">
								{label}
							</Th>
						))}
					</Tr>
				</Thead>
				<Tbody>
					{labelledMessages.messages.map(({ student, messages }) => (
						<Tr key={student._id} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
							<Td>
								<StudentTag student={student} />
							</Td>
							{messages.map(({ label, messages }) => (
								<Td key={label}>{[...new Set(messages.map((m) => m.type))].join(", ")}</Td>
							))}
						</Tr>
					))}
				</Tbody>
			</Table>
		</Box>
	)
}
