import { Box, Table, Thead, Tr, Th, Tbody, Td, VStack, Heading, Link } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { NavLink } from "react-router-dom"
import { AuthorTypes, ExtendedStudent, useActiveRestrictedEntriesQuery } from "../../graphql"
import { StaffTag } from "../staff"
import { StudentTag } from "../student"

export const ActiveRestrictedEntries = () => {
	const [{ data }] = useActiveRestrictedEntriesQuery()

	if (!data?.activeRestrictedEntries.length) return <></>

	return (
		<VStack w="full" align="stretch">
			<Heading fontSize="sm">Restricted Entries</Heading>
			<Box w="full" overflow="auto">
				<Table size="sm" overflow="hidden" colorScheme="red" bg="red.100" rounded="xl">
					<Thead>
						<Tr>
							<Th textTransform="capitalize">Zone</Th>
							<Th textTransform="capitalize">Type</Th>
							<Th textTransform="capitalize">Person</Th>
							<Th textTransform="capitalize">First Seen</Th>
						</Tr>
					</Thead>
					<Tbody>
						{data.activeRestrictedEntries.map((entry) => (
							<Tr
								key={entry._id}
								_hover={{ bg: "gray.100" }}
								transition="background-color 200ms ease-in"
								cursor="pointer"
								fontSize="sm"
								// onClick={() => navigate(`/restrictedZones/${zone._id}`)}
							>
								<Td>
									<Link as={NavLink} to={`/restrictedZones/${entry.restrictedZoneId}`}>
										{entry.restrictedZone.name}
									</Link>
								</Td>
								<Td>{entry.author.authorType}</Td>
								<Td>
									{entry.author.authorType === AuthorTypes.Staff
										? entry.author.staff && <StaffTag staff={entry.author.staff} />
										: entry.author.student && <StudentTag student={entry.author.student as ExtendedStudent} />}
								</Td>
								<Td>{format(new Date(entry.inAt), "PPp")}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
		</VStack>
	)
}
