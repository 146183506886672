import { Button, Center, Grid, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { SearchGalleryQueryVariables, useSearchGalleryQuery } from "../../graphql"
import { useDrawer } from "../../hooks"
import { Gallery } from "./Gallery"

export type SearchResultGalleryProps = {
	searchKeyword: string
}

export const SearchResultGallery: React.FC<SearchResultGalleryProps> = ({ searchKeyword }) => {
	const [pagination, setPagination] = useState<SearchGalleryQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useSearchGalleryQuery({
		variables: { pagination, keyword: searchKeyword },
	})

	const nextPage = () => {
		if (data?.searchGallery.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	const { isShown } = useDrawer()

	return (
		<VStack w="full" align="stretch" gap={5}>
			<Heading as="h2" fontSize="md" fontWeight="normal" color="text.500">
				Showing {data?.searchGallery.total || "all"} gallery for search keyword <strong>&quot;{searchKeyword}&quot;</strong>
			</Heading>
			<Grid w="full" templateColumns={{ base: "1fr", lg: isShown ? "1fr" : "1fr", xl: "1fr" }} gap={4}>
				{data?.searchGallery?.gallery?.map((gallery) => (
					<Gallery key={gallery._id} gallery={gallery} />
				))}
			</Grid>
			{fetching ? (
				<Center w="full" maxW="6xl" py="8">
					<Spinner color="text.400" />
				</Center>
			) : data?.searchGallery.gallery && data?.searchGallery.gallery.length <= 0 ? (
				<Center w="full" maxW="6xl" py="8">
					<Text fontSize="sm" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any trending skills.
					</Text>
				</Center>
			) : data?.searchGallery.hasNextPage ? (
				<Center w="full" maxW="lg" py="8">
					<Button variant="link" size="sm" colorScheme="blackAlpha" onClick={nextPage}>
						Show more
					</Button>
				</Center>
			) : (
				<Center w="full" maxW="6xl" py="8">
					<Text fontSize="sm" fontWeight="semibold" color="text.400">
						You have seen it all
					</Text>
				</Center>
			)}
		</VStack>
	)
}
