import { Center, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
// import { SearchCircularQueryVariables, useSearchCircularQuery } from "../../graphql"
import { SearchResourceQueryVariables, useSearchResourceQuery } from "../../graphql"
import { ResourcesTable } from "./ResourcesTable"

export type SearchResultResourcesProps = {
	searchKeyword: string
}

export const SearchResultResources: React.FC<SearchResultResourcesProps> = ({ searchKeyword }) => {
	const [pagination, setPagination] = useState<SearchResourceQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useSearchResourceQuery({
		variables: { pagination, keyword: searchKeyword },
	})

	const prevPage = () => {
		if (data?.searchResource.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.searchResource.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="md" fontWeight="normal" color="text.500">
				Showing {data?.searchResource.total || "all"} resources for search keyword <strong>&quot;{searchKeyword}&quot;</strong>
			</Heading>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.searchResource.resources && data?.searchResource.resources.length > 0 ? (
				<ResourcesTable
					resources={data.searchResource.resources}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.searchResource.hasPrevPage}
					hasNextPage={data.searchResource.hasNextPage}
					currentPage={data.searchResource.currentPage}
					totalPages={data.searchResource.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any resources.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
