import {
	Button,
	Center,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	Grid,
	Heading,
	HStack,
	Icon,
	Input,
	SlideFade,
	Spinner,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { useState } from "react"
import DateTimePicker from "react-datetime-picker"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import {
	CafeteriaPaymentStatus,
	CafeteriaRegistrationStatus,
	GenerateCafeteriaPaymentsReportMutationVariables,
	useCafeteriaMealTypesQuery,
	useGenerateCafeteriaPaymentsReportMutation,
	usePaytankMerchantsWithoutPaginationQuery,
} from "../../../../graphql"
import { PaytankMerchantsSelector } from "../../../paytank"
import { CafeteriaMealTypeSelector } from "../../mealTypes"
import { CafeteriaPaymentStatusSelector, CafeteriaRegistrationStatusSelector } from "../../../common"

const validationSchema = Yup.object({
	name: Yup.string().required().label("Name"),
})

export const GenerateCafeteriaPaymentsReportForm: React.FC = () => {
	const [{ fetching }, generate] = useGenerateCafeteriaPaymentsReportMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const [sinceDate, setSinceDate] = useState<Date>()
	const [untilDate, setUntilDate] = useState<Date>()

	const [selectedCafeteriaMealTypeIds, setSelectedCafeteriaMealTypeIds] = useState<string[]>([])

	const [{ data: cafeteriaMealTypesData, fetching: fetchingCafeteriaMealTypes, error: cafeteriaMealTypesError }] = useCafeteriaMealTypesQuery()

	const [selectedPaytankMerchantIds, setSelectedPaytankMerchantIds] = useState<string[]>([])

	const [{ data: paytankMerchantsData, fetching: fetchingPaytankMerchants, error: paytankMerchantsError }] = usePaytankMerchantsWithoutPaginationQuery()

	const [selectedCafeteriaRegistrationStatuses, setSelectedCafeteriaRegistrationStatuses] = useState<CafeteriaRegistrationStatus[]>([])
	const [selectedCafeteriaPaymentStatuses, setSelectedCafeteriaPaymentStatuses] = useState<CafeteriaPaymentStatus[]>([])

	const handleSubmit = async ({ name }: GenerateCafeteriaPaymentsReportMutationVariables["input"]) => {
		const { error, data } = await generate({
			input: {
				name,
				sinceDate,
				untilDate,
				cafeteriaMealTypeIds: selectedCafeteriaMealTypeIds,
				paytankMerchantIds: selectedPaytankMerchantIds,
				cafeteriaPaymentStatus: selectedCafeteriaPaymentStatuses,
				cafeteriaRegistrationStatus: selectedCafeteriaRegistrationStatuses,
			},
		})

		if (error) {
			return toast({
				description: error.message,
				status: "error",
			})
		}

		if (data?.generateCafeteriaPaymentsReport) {
			return navigate(`/cafeteria/reports/transactions/${data.generateCafeteriaPaymentsReport._id}`, { replace: true })
		}

		return navigate("/cafeteria/reports/transactions", { replace: true })
	}

	const formik = useFormik<GenerateCafeteriaPaymentsReportMutationVariables["input"]>({
		initialValues: { name: "" },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)}>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="title" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>
			<HStack>
				<VStack w="full" maxW="2xl" align="flex-start">
					<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
						Since
					</Heading>
					<DateTimePicker onChange={setSinceDate} value={sinceDate} format="MMM dd, y" maxDate={new Date()} clearIcon={null} />
				</VStack>
				<VStack w="full" maxW="2xl" align="flex-start">
					<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
						Until
					</Heading>
					<DateTimePicker onChange={setUntilDate} value={untilDate} format="MMM dd, y" maxDate={new Date()} clearIcon={null} />
				</VStack>
			</HStack>

			<Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4}>
				<VStack w="full" align="flex-start" maxW="sm">
					{fetchingCafeteriaMealTypes ? (
						<VStack w="full" maxW="2xl">
							<Spinner />
							<Text textAlign="center">Fetching meal types</Text>
						</VStack>
					) : cafeteriaMealTypesError ? (
						<Center>
							<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
								Failed loading meal types, try reloading the page
							</Text>
						</Center>
					) : cafeteriaMealTypesData?.cafeteriaMealTypes && cafeteriaMealTypesData.cafeteriaMealTypes.length > 0 ? (
						<CafeteriaMealTypeSelector
							cafeteriaMealTypes={cafeteriaMealTypesData.cafeteriaMealTypes}
							selectedCafeteriaMealTypeIds={selectedCafeteriaMealTypeIds}
							onChange={setSelectedCafeteriaMealTypeIds}
							isDefaultAllSelected
						/>
					) : (
						<Center py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find any meal types.
							</Text>
						</Center>
					)}
				</VStack>

				<VStack w="full" align="flex-start" maxW="sm">
					{fetchingPaytankMerchants ? (
						<VStack w="full" maxW="2xl">
							<Spinner />
							<Text textAlign="center">Fetching paytank merchants</Text>
						</VStack>
					) : paytankMerchantsError ? (
						<Center>
							<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
								Failed loading meal types, try reloading the page
							</Text>
						</Center>
					) : paytankMerchantsData?.paytankMerchantsWithoutPagination && paytankMerchantsData?.paytankMerchantsWithoutPagination.length > 0 ? (
						<PaytankMerchantsSelector
							paytankMerchants={paytankMerchantsData.paytankMerchantsWithoutPagination}
							selectedPaytankMerchantIds={selectedPaytankMerchantIds}
							onChange={setSelectedPaytankMerchantIds}
							isDefaultAllSelected
						/>
					) : (
						<Center py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find any paytank merchant.
							</Text>
						</Center>
					)}
				</VStack>

				<VStack w="full" align="flex-start" maxW="sm">
					<CafeteriaRegistrationStatusSelector
						statuses={Object.values(CafeteriaRegistrationStatus)}
						selectedStatuses={selectedCafeteriaRegistrationStatuses}
						onChange={setSelectedCafeteriaRegistrationStatuses}
						isDefaultAllSelected
					/>
				</VStack>

				<VStack w="full" align="flex-start" maxW="sm">
					<CafeteriaPaymentStatusSelector
						statuses={Object.values(CafeteriaPaymentStatus)}
						selectedStatuses={selectedCafeteriaPaymentStatuses}
						onChange={setSelectedCafeteriaPaymentStatuses}
						isDefaultAllSelected
					/>
				</VStack>
			</Grid>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
