import { Box, Icon, IconButton, useDisclosure, useToast } from "@chakra-ui/react"
import React from "react"
import { Trash } from "react-feather"
import { useRemoveReaderFromRestrictedZoneMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type RemoveReaderFromRestrictedZoneProps = {
	restrictedZoneId: string
	readerId: string
}

export const RemoveReaderFromRestrictedZone: React.FC<RemoveReaderFromRestrictedZoneProps> = ({ restrictedZoneId, readerId }) => {
	const { isOpen, onClose, getButtonProps } = useDisclosure()

	const [{ fetching }, removeReader] = useRemoveReaderFromRestrictedZoneMutation()

	const toast = useToast()

	const handleRemoveReader = async () => {
		const { error } = await removeReader({ restrictedZoneId, readerId })

		if (error) {
			toast({ description: error.message.replace("[GraphQL ]", ""), status: "error" })
		} else {
			onClose()
		}
	}

	return (
		<Box>
			<IconButton aria-label="remove-reader-btn" colorScheme="red" variant="ghost" {...getButtonProps()}>
				<Icon as={Trash} />
			</IconButton>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Remove Reader"
				message="Are you sure you want to remove this reader, this will stop reading tags by this reader"
				confirmLabel="Remove"
				onConfirm={handleRemoveReader}
				isLoading={fetching}
			/>
		</Box>
	)
}
