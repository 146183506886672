import { Checkbox, Heading, Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { SchoolSessionFragment } from "../../graphql"
import { usePagination } from "../../hooks"
import { getMonthForIndex } from "../../utils"

export type SchoolSessionSelectorProps = {
	schoolSessions: SchoolSessionFragment[]
	selectedSchoolSessionIds: string[]
	onChange: (schoolSessionIds: string[]) => void
	removeHeading?: boolean
	isDefaultAllSelected?: boolean
}

export const SchoolSessionSelector: React.FC<SchoolSessionSelectorProps> = ({
	schoolSessions,
	selectedSchoolSessionIds,
	onChange,
	removeHeading,
	isDefaultAllSelected,
}) => {
	const selectSchoolSession = (schoolSessionId: string) => {
		onChange([...selectedSchoolSessionIds, schoolSessionId])
	}

	const deselectSchoolSession = (schoolSessionId: string) => {
		onChange(selectedSchoolSessionIds.filter((_schoolSessionId) => _schoolSessionId !== schoolSessionId))
	}

	const isSchoolSessionSelected = useCallback((schoolSessionId: string) => selectedSchoolSessionIds.includes(schoolSessionId), [selectedSchoolSessionIds])

	useEffect(() => {
		if (isDefaultAllSelected) onChange(schoolSessions.map(({ _id }) => _id))
	}, [JSON.stringify(schoolSessions), isDefaultAllSelected])

	const selectAllSchoolSessions = () => {
		onChange(schoolSessions.map(({ _id }) => _id) ?? [])
	}

	const isAllChecked = useMemo(
		() => Boolean(selectedSchoolSessionIds.length) && schoolSessions.map(({ _id }) => _id).every((id) => selectedSchoolSessionIds.includes(id)),
		[selectedSchoolSessionIds, schoolSessions]
	)

	const isIndeterminate = useMemo(
		() => selectedSchoolSessionIds.some((id) => schoolSessions.map(({ _id }) => _id).includes(id)) && !isAllChecked,
		[selectedSchoolSessionIds, schoolSessions]
	)

	const { currentItems, loadMore, hasMore } = usePagination<SchoolSessionFragment>(schoolSessions, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
				{removeHeading ? "" : "Select the school sessions"}
			</Heading>
			<VStack w="full" align="stretch" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th>
								<Checkbox
									colorScheme="blackAlpha"
									borderColor="black"
									isChecked={isAllChecked}
									isIndeterminate={isIndeterminate}
									onChange={(e) => (e.target.checked ? selectAllSchoolSessions() : onChange([]))}
								/>
							</Th>
							<Th textTransform="capitalize">Name</Th>
							<Th textTransform="capitalize">Session</Th>
						</Tr>
					</Thead>
					<Tbody>
						{currentItems.map((schoolSession) => (
							<Tr key={schoolSession._id} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td>
									<Checkbox
										colorScheme="blackAlpha"
										borderColor="black"
										isChecked={isSchoolSessionSelected(schoolSession._id)}
										onChange={(e) => (e.target.checked ? selectSchoolSession(schoolSession._id) : deselectSchoolSession(schoolSession._id))}
									/>
								</Td>

								<Td>{schoolSession.name}</Td>
								<Td>
									{getMonthForIndex(schoolSession.startMonth)} {schoolSession.startDate.toString().padStart(2, "0")} -{" "}
									{getMonthForIndex(schoolSession.endMonth)} {schoolSession.endDate.toString().padStart(2, "0")}
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
				{hasMore && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
			</VStack>
		</VStack>
	)
}
