import { Box, Button, Icon, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from "@chakra-ui/react"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { contrastColor } from "contrast-color"
import React from "react"
import { ExtendedStudentFragment } from "../../graphql"
import { EditStudentHouseForm } from "./EditStudentHouseForm"

export type EditStudentHouseProps = { student: ExtendedStudentFragment }

export const EditStudentHouse: React.FC<EditStudentHouseProps> = ({ student }) => {
	return (
		<Popover isLazy lazyBehavior="unmount">
			<PopoverTrigger>
				<Box pos="relative">
					<Button
						size="xs"
						variant="solid"
						colorScheme="orange"
						bgColor={student.house?.colorHex}
						color={contrastColor({ bgColor: student.house?.colorHex })}
					>
						House {student.house?.name || ""}
					</Button>
					{student.house?.captainId === student._id && (
						<>
							<Icon
								as={(props: any) => <FontAwesomeIcon icon={faStar} {...props} />}
								pos="absolute"
								top="-1.5"
								right="-1.5"
								fontSize="24"
								color={contrastColor({ bgColor: student.house?.colorHex })}
							/>

							<Icon
								as={(props: any) => <FontAwesomeIcon icon={faStar} {...props} />}
								pos="absolute"
								top="-1"
								right="-1"
								fontSize="20"
								color={student.house?.colorHex}
							/>
						</>
					)}
				</Box>
			</PopoverTrigger>
			<PopoverContent bgColor="white">
				<PopoverArrow bgColor="white" />
				<PopoverBody>
					<EditStudentHouseForm student={student} />
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
