import React from "react"
import { DrawerContextProvider } from "./drawer"
import { UploadContextProvider } from "./upload"

export const ContextProvider: React.FC<{ children: any }> = ({ children }) => {
	return (
		<DrawerContextProvider>
			<UploadContextProvider>{children}</UploadContextProvider>
		</DrawerContextProvider>
	)
}

export * from "./drawer"
export * from "./upload"
