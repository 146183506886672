import { chakra, Container, HStack, Icon, Link } from "@chakra-ui/react"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { STUDENT_SITE_URL, STAFF_SITE_URL } from "../../constants"
import { Logo } from "./Logo"

export const Header: React.FC = () => {
	return (
		<Container maxW="container.xl">
			<HStack justify="space-between" py="3">
				<Logo />
				<HStack as="nav" display={{ base: "none", md: "flex" }} spacing="6">
					<HStack fontSize="xs" color="primary.500">
						<chakra.span>Log in as</chakra.span>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} />
					</HStack>
					<Link href={STUDENT_SITE_URL}>Student</Link>
					<Link href={STAFF_SITE_URL}>Staff</Link>
				</HStack>
			</HStack>
		</Container>
	)
}
