import React from "react"
import { BottomNavigationTabs, RestrictedZoneAlert } from "./components"
import { useMeQuery } from "./graphql"
import { RootRouter } from "./routes"

export const App: React.FC = () => {
	const [{ data }] = useMeQuery()

	return (
		<div id="app">
			<RootRouter />
			{data?.me && <BottomNavigationTabs />}
			{data?.me && <RestrictedZoneAlert />}
		</div>
	)
}
