/* eslint-disable @typescript-eslint/indent */
import { MediaTypes } from "../../graphql"

export const UPLOAD_STARTED = "UPLOAD_STARTED"
export const UPLOAD_PROGRESS = "UPLOAD_PROGRESS"
export const UPLOAD_FAILED = "UPLOAD_FAILED"
export const UPLOAD_FINISHED = "UPLOAD_FINISHED"
export const UPLOAD_CANCEL_FN_CREATED = "UPLOAD_CANCEL_FN_CREATED"
export const CLEAR_UPLOAD = "CLEAR_UPLOAD"
export const CLEAR_ALL_UPLOADS = "CLEAR_ALL_UPLOADS"

export type UploadState = {
	uploads: {
		id: string
		file: File
		type: MediaTypes
		isUploading?: boolean
		isUploaded?: boolean
		onCancel?: () => void
		progress: number
		error?: any & { message?: string }
		key?: string
		url?: string
	}[]
}

export type UploadActionPayload = Omit<UploadState["uploads"][0], "type" | "progress" | "file"> & {
	file?: File
	type?: MediaTypes
	progress?: number
}

export type UploadAction = {
	type:
		| typeof UPLOAD_STARTED
		| typeof UPLOAD_PROGRESS
		| typeof UPLOAD_FAILED
		| typeof UPLOAD_FINISHED
		| typeof UPLOAD_CANCEL_FN_CREATED
		| typeof CLEAR_UPLOAD
		| typeof CLEAR_ALL_UPLOADS
	payload?: UploadActionPayload
}
