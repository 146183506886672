import { Button, Container, Heading, HStack, Icon, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import { faBowlFood, faCreditCard, faFileLines, faIdCard, faMessage, faMoneyBills } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { NavLink } from "react-router-dom"
import { Card, DashboardHeader, Nav } from "../../components"
import { GRADIENT } from "../../constants"
import { useDrawer } from "../../hooks"

export const Cafeteria: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					<VStack align="flex-start">
						<Heading as="h1" fontSize="xl" color="text.500">
							Cafeteria
						</Heading>
						<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.400" maxW="xs">
							{
								// TODO: add sub heading
							}
						</Heading>
					</VStack>
					<Stack w="full" direction={{ base: "column", md: "row" }} align="stretch" spacing={4}>
						<VStack spacing={4}>
							<Stack w="full" direction={{ base: "column", md: "row" }} align="stretch" spacing={4}>
								<Card w="full" bgColor="primary.100" as={NavLink} to="/cafeteria/mealTypes">
									<VStack w="full">
										<Icon color="primary.600" fontSize="4xl" as={(props: any) => <FontAwesomeIcon icon={faBowlFood} {...props} />} />
										<Text color="primary.600" fontSize="sm" fontWeight="semibold" textAlign="center">
											Meal Types
										</Text>
									</VStack>
								</Card>

								<Card w="full" bgColor="primary.100" as={NavLink} to="/cafeteria/registrationCharges">
									<VStack w="full">
										<Icon color="primary.600" fontSize="4xl" as={(props: any) => <FontAwesomeIcon icon={faMoneyBills} {...props} />} />
										<Text color="primary.600" fontSize="sm" fontWeight="semibold" textAlign="center">
											Registration Charges
										</Text>
									</VStack>
								</Card>

								<Card w="full" bgColor="primary.100" as={NavLink} to="/cafeteria/messages">
									<VStack w="full">
										<Icon color="primary.600" fontSize="4xl" as={(props: any) => <FontAwesomeIcon icon={faMessage} {...props} />} />
										<Text color="primary.600" fontSize="sm" fontWeight="semibold" textAlign="center">
											Audio Messages
										</Text>
									</VStack>
								</Card>
							</Stack>
							<Stack w="full" direction={{ base: "column", md: "row" }} align="stretch" spacing={4}>
								<Card w="full" bgColor="primary.100" as={NavLink} to="/cafeteria/registrations">
									<VStack w="full">
										<Icon color="primary.600" fontSize="4xl" as={(props: any) => <FontAwesomeIcon icon={faIdCard} {...props} />} />
										<Text color="primary.600" fontSize="sm" fontWeight="semibold" textAlign="center">
											Registrations
										</Text>
									</VStack>
								</Card>

								<Card w="full" bgColor="primary.100" as={NavLink} to="/cafeteria/transactions">
									<VStack w="full">
										<Icon color="primary.600" fontSize="4xl" as={(props: any) => <FontAwesomeIcon icon={faCreditCard} {...props} />} />
										<Text color="primary.600" fontSize="sm" fontWeight="semibold" textAlign="center">
											Transactions
										</Text>
									</VStack>
								</Card>
							</Stack>
						</VStack>

						<Card w="full" bgColor="primary.100" alignSelf="top">
							<VStack w="full" align="stretch" spacing={4}>
								<VStack>
									<Icon color="primary.600" fontSize="4xl" as={(props: any) => <FontAwesomeIcon icon={faFileLines} {...props} />} />
									<Text color="primary.600" fontSize="sm" fontWeight="semibold">
										Reports
									</Text>
								</VStack>

								<Button
									w="full"
									colorScheme="primary"
									size="sm"
									variant="ghost"
									justifyContent="space-between"
									as={NavLink}
									to="/cafeteria/reports/registrations"
								>
									Registrations
								</Button>

								<Button
									w="full"
									colorScheme="primary"
									size="sm"
									variant="ghost"
									justifyContent="space-between"
									as={NavLink}
									to="/cafeteria/reports/transactions"
								>
									Transactions
								</Button>
							</VStack>
						</Card>
					</Stack>
					{/* <Grid w="full" templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} mt="12 !important">
						<TopStudents />
						<TopHouses />
						<TopStaffs />
						<TopDepartments />
					</Grid> */}
				</VStack>
			</HStack>
		</Container>
	)
}
