import { Button, FormControl, FormErrorIcon, FormErrorMessage, FormLabel, Icon, Input, SlideFade, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { EditShowcaseMutationVariables, ExtendedShowcaseFragment, useEditShowcaseMutation } from "../../graphql"

const validationSchema = Yup.object({
	title: Yup.string().label("Title"),
	description: Yup.string().label("Description"),
})

export type EditShowcaseFormProps = {
	showcase: ExtendedShowcaseFragment
}

export const EditShowcaseForm: React.FC<EditShowcaseFormProps> = ({ showcase }) => {
	const [{ fetching }, editShowcase] = useEditShowcaseMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (
		{ title, description }: EditShowcaseMutationVariables["input"],
		helpers: FormikHelpers<EditShowcaseMutationVariables["input"]>
	) => {
		const { error, data } = await editShowcase({ showcaseId: showcase._id, input: { title, description } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.editShowcase.errors) {
			return data?.editShowcase.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		navigate(`/showcases/${data?.editShowcase.showcase?._id}`)
	}

	const formik = useFormik<EditShowcaseMutationVariables["input"]>({
		initialValues: { title: showcase.title, description: showcase.description },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" maxW="md" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.title && formik.touched.title)}>
				<SlideFade in={Boolean(formik.values.title)} unmountOnExit>
					<FormLabel htmlFor="title" fontSize="sm">
						Title
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Title"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("title")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.title}</Text>
				</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={Boolean(formik.errors.description && formik.touched.description)}>
				<SlideFade in={Boolean(formik.values.description)} unmountOnExit>
					<FormLabel htmlFor="description" fontSize="sm">
						Description
					</FormLabel>
				</SlideFade>
				<Textarea
					placeholder="Description"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("description")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.description}</Text>
				</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Save
			</Button>
		</VStack>
	)
}
