import { Button, Icon, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from "@chakra-ui/react"
import { faBatteryFull, faBatteryQuarter } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { ExtendedStudentFragment } from "../../graphql"
import { AssignNewTagForm } from "./AssignNewTagForm"

export type AssignNewTagPopoverProps = { student: ExtendedStudentFragment }

export const AssignNewTagPopover: React.FC<AssignNewTagPopoverProps> = ({ student }) => {
	return (
		<Popover isLazy lazyBehavior="unmount">
			<PopoverTrigger>
				<Button
					size="xs"
					variant="outline"
					colorScheme="orange"
					rightIcon={
						student.tag ? (
							<Icon
								as={(props: any) => (
									<FontAwesomeIcon
										icon={!student.tag?.tagStatus || student.tag.tagStatus === 0 ? faBatteryFull : faBatteryQuarter}
										{...props}
									/>
								)}
								color={!student.tag?.tagStatus || student.tag?.tagStatus === 0 ? "green" : "red"}
								fontSize="lg"
							/>
						) : undefined
					}
				>
					{student.tag ? student.tag.tagDecimalId : "Assign Tag"}
				</Button>
			</PopoverTrigger>
			<PopoverContent bgColor="white">
				<PopoverArrow bgColor="white" />
				<PopoverBody>
					<AssignNewTagForm studentId={student._id} />
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
