import {
	Button,
	Center,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	Icon,
	Input,
	Select,
	SlideFade,
	Spinner,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CUIAutoComplete } from "chakra-ui-autocomplete"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { CreateStaffMutationVariables, DepartmentTypes, useCreateStaffMutation, useDepartmentsQuery, useSubjectsWithoutPaginationQuery } from "../../graphql"

const validationSchema = Yup.object({
	name: Yup.string().min(3).required().label("Name"),
	email: Yup.string().email().nullable().label("Email"),
	phone: Yup.string().length(10).required().label("Phone"),
	subjectIds: Yup.array().of(Yup.string()).required().label("Subjects"),
	tagHexOrDecId: Yup.string().min(8).nullable().label("Tag ID"),
	departmentId: Yup.string().required().label("Department ID"),
	staffId: Yup.string().required().label("Staff ID"),
	designation: Yup.string().required().label("Designation"),
})

export const NewStaffForm: React.FC = () => {
	const [{ fetching }, createNew] = useCreateStaffMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (
		{ name, email, phone, subjectIds, tagHexOrDecId, departmentId, staffId, designation }: CreateStaffMutationVariables["input"],
		helpers: FormikHelpers<CreateStaffMutationVariables["input"]>
	) => {
		const { error, data } = await createNew({
			input: {
				name,
				email,
				phone,
				subjectIds,
				tagHexOrDecId,
				departmentId,
				staffId,
				designation,
			},
		})

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createStaff.errors) {
			return data?.createStaff.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		return navigate("/staff", { replace: true })
	}

	const formik = useFormik<CreateStaffMutationVariables["input"]>({
		initialValues: {
			name: "",
			email: "",
			phone: "",
			subjectIds: [],
			tagHexOrDecId: "",
			departmentId: "",
			staffId: "",
			designation: "",
		},
		validationSchema,
		onSubmit: handleSubmit,
	})

	const [{ data: departmentsData, fetching: fetchingDepartments, error: departmentsError }] = useDepartmentsQuery()
	const [{ data: subjectsData, fetching: fetchingSubjects, error: subjectsError }] = useSubjectsWithoutPaginationQuery({
		pause: departmentsData?.departments.find((d) => d._id === formik.values.departmentId)?.type !== DepartmentTypes.Teaching,
	})

	return (
		<VStack w="full" maxW="md" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)} isRequired>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="name" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={Boolean(formik.errors.email && formik.touched.email)}>
				<SlideFade in={Boolean(formik.values.email)} unmountOnExit>
					<FormLabel htmlFor="email" fontSize="sm">
						Email
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Email"
					border="none"
					_focus={{ border: "none" }}
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("email")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.email}</Text>
				</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={Boolean(formik.errors.phone && formik.touched.phone)} isRequired>
				<SlideFade in={Boolean(formik.values.phone)} unmountOnExit>
					<FormLabel htmlFor="phone" fontSize="sm">
						Phone
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Phone"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("phone")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.phone}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.staffId && formik.touched.staffId)} isRequired>
				<SlideFade in={Boolean(formik.values.staffId)} unmountOnExit>
					<FormLabel htmlFor="staffId" fontSize="sm">
						Staff ID
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Staff ID"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("staffId")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.staffId}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.designation && formik.touched.designation)} isRequired>
				<SlideFade in={Boolean(formik.values.designation)} unmountOnExit>
					<FormLabel htmlFor="designation" fontSize="sm">
						Designation
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Designation"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("designation")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.designation}</Text>
				</FormErrorMessage>
			</FormControl>

			{fetchingDepartments ? (
				<Center w="full" py="1">
					<VStack>
						<Spinner size="sm" color="text.400" />
						<Text fontSize="xs" color="text.400">
							Loading departments
						</Text>
					</VStack>
				</Center>
			) : departmentsError ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							{departmentsError.message.replace("[GraphQL] ", "")}
						</Text>
					</VStack>
				</Center>
			) : !departmentsData?.departments ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							Could not load departments
						</Text>
					</VStack>
				</Center>
			) : (
				<FormControl isInvalid={Boolean(formik.errors.departmentId && formik.touched.departmentId)} isRequired>
					<SlideFade in={Boolean(formik.values.departmentId)} unmountOnExit>
						<FormLabel htmlFor="departmentId" fontSize="sm">
							Department
						</FormLabel>
					</SlideFade>
					<Select
						w="full"
						bg="white.500"
						rounded="xl"
						border="none"
						_focus={{ border: "none" }}
						placeholder="Select Department"
						{...formik.getFieldProps("departmentId")}
					>
						{departmentsData.departments.map((department) => (
							<option key={department._id} value={department._id}>
								{department.name}
							</option>
						))}
					</Select>
					<FormErrorMessage>{formik.errors.departmentId}</FormErrorMessage>
				</FormControl>
			)}

			{departmentsData?.departments.find((d) => d._id === formik.values.departmentId)?.type === DepartmentTypes.Teaching &&
				(fetchingSubjects ? (
					<Center w="full" py="1">
						<VStack>
							<Spinner size="sm" color="text.400" />
							<Text fontSize="xs" color="text.400">
								Loading subjects
							</Text>
						</VStack>
					</Center>
				) : subjectsError ? (
					<Center w="full" py="1">
						<VStack>
							<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
							<Text fontSize="xs" color="text.400">
								{subjectsError.message.replace("[GraphQL] ", "")}
							</Text>
						</VStack>
					</Center>
				) : !subjectsData?.subjectsWithoutPagination ? (
					<Center w="full" py="1">
						<VStack>
							<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
							<Text fontSize="xs" color="text.400">
								Could not load subjects
							</Text>
						</VStack>
					</Center>
				) : (
					<FormControl isInvalid={Boolean(formik.errors.subjectIds && formik.touched.subjectIds)} mb="-24px !important">
						<SlideFade in={Boolean(formik.values.subjectIds)} unmountOnExit>
							<FormLabel htmlFor="subjectIds" fontSize="sm" mb="-.5rem">
								Subjects
							</FormLabel>
						</SlideFade>
						<CUIAutoComplete
							label=""
							placeholder="Subjects"
							disableCreateItem
							hideToggleButton
							selectedItems={subjectsData.subjectsWithoutPagination
								.map(({ _id, name }) => ({ label: name, value: _id }))
								.filter((target) => formik.values.subjectIds?.includes(target.value))}
							items={subjectsData.subjectsWithoutPagination.map(({ _id, name }) => ({
								label: name,
								value: _id,
							}))}
							onSelectedItemsChange={(changes) =>
								formik.setFieldValue(
									"subjectIds",
									changes.selectedItems?.map(({ value }) => value)
								)
							}
							inputStyleProps={{
								border: "none",
								_focus: { border: "none" },

								maxW: "md",
								bg: "white.500",
								rounded: "xl",
								py: "1",
							}}
							listStyleProps={{
								maxW: "md",
								bg: "whiteAlpha.400",
								backdropFilter: "blur(4px)",
							}}
							labelStyleProps={{ display: "none" }}
						/>
						<FormErrorMessage>{formik.errors.subjectIds}</FormErrorMessage>
					</FormControl>
				))}

			<FormControl isInvalid={Boolean(formik.errors.tagHexOrDecId && formik.touched.tagHexOrDecId)}>
				<SlideFade in={Boolean(formik.values.tagHexOrDecId)} unmountOnExit>
					<FormLabel htmlFor="tagHexOrDecId" fontSize="sm">
						Tag ID
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Tag ID"
					border="none"
					_focus={{ border: "none" }}
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("tagHexOrDecId")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.tagHexOrDecId}</Text>
				</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Save
			</Button>
		</VStack>
	)
}
