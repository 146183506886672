import {
	AspectRatio,
	Button,
	ButtonGroup,
	Center,
	Container,
	Flex,
	Grid,
	Heading,
	HStack,
	Icon,
	IconButton,
	Img,
	Link,
	Popover,
	PopoverArrow,
	PopoverContent,
	PopoverTrigger,
	Spinner,
	Stack,
	Text,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react"
import { faFilePdf, faPaperclip, faPencil, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { NavLink, useParams } from "react-router-dom"
import { DashboardHeader, DeleteShowcaseButton, Nav, ShowcaseAuthor } from "../../components"
import { GRADIENT } from "../../constants"
import { useShowcaseByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const Showcase: React.FC = () => {
	const { showcaseId = "" } = useParams<{ showcaseId: string }>()

	const [{ data, fetching, error }] = useShowcaseByIdQuery({
		variables: { showcaseId },
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.showcaseById ? (
						<Stack direction={{ base: "column", lg: "row" }} w="full" align="stretch" spacing={6}>
							<VStack w="full" align="stretch" spacing="4" maxW="lg">
								<HStack w="full" align="flex-start" justify="space-between">
									<VStack w="full" align="flex-start">
										<HStack w="full" justify="space-between">
											<Heading as="h1" fontSize="xl" color="text.500">
												{data.showcaseById.title}
											</Heading>
											<ButtonGroup>
												<IconButton
													aria-label="edit-showcase-btn"
													variant="ghost"
													colorScheme="blackAlpha"
													size="sm"
													as={NavLink}
													to="edit"
												>
													<Icon as={(props: any) => <FontAwesomeIcon icon={faPencil} {...props} />} />
												</IconButton>

												<DeleteShowcaseButton showcase={data.showcaseById} />

												<Popover placement="auto-end">
													<PopoverTrigger>
														<IconButton aria-label="upload-media-btn" variant="ghost" colorScheme="blackAlpha" size="sm">
															<Icon as={(props: any) => <FontAwesomeIcon icon={faPaperclip} {...props} />} />
														</IconButton>
													</PopoverTrigger>
													<PopoverContent maxW="48" bg="white" shadow="2xl" _focus={{ shadow: "2xl" }}>
														<PopoverArrow />
														<VStack w="full" align="stretch" spacing={0}>
															<Button
																w="full"
																as={NavLink}
																size="sm"
																to="addAttachments"
																rounded="none"
																justifyContent="flex-start"
																leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faPlus} {...props} />} />}
															>
																Add Attachments
															</Button>
															<Button
																w="full"
																as={NavLink}
																size="sm"
																to="deleteAttachments"
																rounded="none"
																justifyContent="flex-start"
																leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faTrash} {...props} />} />}
															>
																Delete Attachments
															</Button>
														</VStack>
													</PopoverContent>
												</Popover>
											</ButtonGroup>
										</HStack>
										<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
											Created {format(new Date(data.showcaseById.createdAt), "PPPp")}
										</Heading>
									</VStack>
								</HStack>
								<VStack w="full" align="stretch">
									<Heading fontSize="sm">Author</Heading>

									<Flex flexWrap="wrap" alignItems="center">
										{data.showcaseById.authors?.map((author) => (
											<ShowcaseAuthor key={author._id} showcaseId={showcaseId} author={author} />
										))}

										<IconButton
											m="1"
											aria-label="add-author-btn"
											variant="outline"
											colorScheme="blackAlpha"
											size="sm"
											as={NavLink}
											to="addAuthor"
										>
											<Icon as={(props: any) => <FontAwesomeIcon icon={faPlus} {...props} />} />
										</IconButton>
									</Flex>
								</VStack>

								{data.showcaseById.attachments?.pdfs?.length ? (
									<Grid templateColumns={getGridTemplateColumns(data.showcaseById.attachments.pdfs.length || 0)} gap={1}>
										{data.showcaseById.attachments.pdfs.slice(0, 4).map((pdf, index) => (
											<AspectRatio
												pos="relative"
												key={index}
												bg="primary.100"
												rounded="xl"
												ratio={1}
												cursor="pointer"
												onClick={() => window.open(pdf.url, "#")}
												zIndex={1}
											>
												<>
													<Center rounded="xl" bg="primary.100">
														<Icon
															color="primary.600"
															fontSize="5xl"
															as={(props: any) => <FontAwesomeIcon icon={faFilePdf} {...props} />}
														/>
													</Center>

													{index === 3 && (data.showcaseById?.attachments?.pdfs?.length || 0) > 4 && (
														<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
															<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
																+{(data.showcaseById?.attachments?.pdfs?.length || 0) - 4}
															</Text>
														</Center>
													)}
												</>
											</AspectRatio>
										))}
									</Grid>
								) : data.showcaseById.attachments?.videos?.length ? (
									<Grid templateColumns={getGridTemplateColumns(data.showcaseById.attachments.videos.length)} gap={1}>
										{data.showcaseById.attachments.videos.slice(0, 4).map((video, index) => (
											<AspectRatio pos="relative" key={index} bg="primary.100" rounded="xl" ratio={1} zIndex={1}>
												<>
													<video style={{ borderRadius: "12px" }} width="100%" src={video.url} controls />
													{index === 3 && (data.showcaseById?.attachments?.videos?.length || 0) > 4 && (
														<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
															<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
																+{(data.showcaseById?.attachments?.videos?.length || 0) - 4}
															</Text>
														</Center>
													)}
												</>
											</AspectRatio>
										))}
									</Grid>
								) : data.showcaseById.attachments?.pictures?.length ? (
									<Grid templateColumns={getGridTemplateColumns(data.showcaseById.attachments.pictures.length)} gap={1}>
										{data.showcaseById.attachments.pictures.slice(0, 4).map((picture, index) => (
											<AspectRatio key={index} pos="relative" bg="primary.100" rounded="xl" ratio={1} zIndex={1}>
												<>
													<Img rounded="xl" src={picture.url} />
													{index === 3 && (data.showcaseById?.attachments?.pictures?.length || 0) > 4 && (
														<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
															<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
																+{(data.showcaseById?.attachments?.pictures?.length || 0) - 4}
															</Text>
														</Center>
													)}
												</>
											</AspectRatio>
										))}
									</Grid>
								) : (
									<></>
								)}

								<Text fontSize="sm" color="text.400">
									{data.showcaseById.description}
								</Text>
							</VStack>
							<VStack w="full" align="stretch" spacing="4" maxW="lg">
								<HStack w="full" justify="space-between">
									<Heading as="h4" fontSize="md" color="text.500">
										Download the QR Code for this showcase
									</Heading>
									{data.showcaseById.qrCode && (
										<Button
											as={(props: any) => <Link {...props} isExternal />}
											colorScheme="purple"
											size="sm"
											href={data.showcaseById.qrCode.url}
										>
											Download
										</Button>
									)}
								</HStack>
								{data.showcaseById.qrCode ? (
									<AspectRatio w="full" ratio={1}>
										<Img w="full" rounded="xl" src={data.showcaseById.qrCode.url} alt="qr code for showcase" />
									</AspectRatio>
								) : (
									<Center w="full" py="4">
										<Text fontSize="md" fontWeight="semibold" color="text.400">
											QR Code for this showcase has not been generated
										</Text>
									</Center>
								)}
							</VStack>
						</Stack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the showcase.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}

const getGridTemplateColumns = (length: number) => {
	if (length < 2) {
		return "1fr"
	}

	return "repeat(2, 1fr)"
}
