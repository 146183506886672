import { Button, Icon, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, useToast, VStack } from "@chakra-ui/react"
import React from "react"
import { ArrowRight, CheckCircle, XCircle } from "react-feather"
import { ExtendedStudentFragment, useActivateStudentMutation, useDeactivateStudentMutation } from "../../graphql"

export type ActivateAndDeactivateStudentPopoverProps = { student: ExtendedStudentFragment }

export const ActivateAndDeactivateStudentPopover: React.FC<ActivateAndDeactivateStudentPopoverProps> = ({ student }) => {
	const toast = useToast()

	const [{ fetching: fetchingActivate }, activateStudent] = useActivateStudentMutation()
	const handleActivateStudent = async () => {
		await activateStudent({ studentId: student._id })
		toast({ description: "Student activated", status: "success" })
	}
	const [{ fetching: fetchingDeactivate }, deactivateStudent] = useDeactivateStudentMutation()
	const handleDeactivateStudent = async () => {
		await deactivateStudent({ studentId: student._id })
		toast({ description: "Student deactivated", status: "error" })
	}
	return (
		<Popover isLazy lazyBehavior="unmount">
			<PopoverTrigger>
				{student && student.status !== "Active" ? (
					<Button size="xs" variant="solid" colorScheme="green" rightIcon={<Icon as={CheckCircle} />}>
						{"Activate Student"}
					</Button>
				) : (
					<Button size="xs" variant="solid" colorScheme="red" rightIcon={<Icon as={XCircle} />}>
						{"Deactivate Student"}
					</Button>
				)}
			</PopoverTrigger>
			<PopoverContent bgColor="white">
				<PopoverArrow bgColor="white" />
				<PopoverBody>
					{student && student.status !== "Active" ? (
						<VStack w="full">
							<Button
								w="full"
								onClick={handleActivateStudent}
								colorScheme="green"
								rightIcon={<Icon as={ArrowRight} />}
								isLoading={fetchingActivate}
							>
								Activate
							</Button>
						</VStack>
					) : (
						<VStack w="full">
							<Button
								w="full"
								onClick={handleDeactivateStudent}
								colorScheme="red"
								rightIcon={<Icon as={ArrowRight} />}
								isLoading={fetchingDeactivate}
							>
								Deactivate
							</Button>
						</VStack>
					)}
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
