export const HIDE_DRAWER = "HIDE_DRAWER"
export const SHOW_DRAWER = "SHOW_DRAWER"

export type DrawerState = {
	isShown: boolean
}

export type DrawerActions = {
	type: typeof HIDE_DRAWER | typeof SHOW_DRAWER
}
