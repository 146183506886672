import { Button, Container, Heading, HStack, Icon, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import { faCashRegister, faWallet } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { NavLink } from "react-router-dom"
import { Card, DashboardHeader, Nav, PaytankStats } from "../../components"
import { GRADIENT } from "../../constants"
import { useDrawer } from "../../hooks"

export const Paytank: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					<VStack align="flex-start">
						<Heading as="h1" fontSize="xl" color="text.500">
							Paytank
						</Heading>
						<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.400" maxW="xs">
							{
								// TODO: add sub heading
							}
						</Heading>
					</VStack>

					<Stack w="full" direction={{ base: "column", md: "row" }} align="stretch" spacing={4}>
						<VStack>
							<Stack w="full" direction={{ base: "column", md: "row" }} align="stretch" spacing={4}>
								<Card w="full" bgColor="primary.100">
									<VStack w="full" align="stretch" spacing={4}>
										<VStack>
											<Icon
												color="primary.600"
												fontSize="4xl"
												as={(props: any) => <FontAwesomeIcon icon={faCashRegister} {...props} />}
											/>
											<Text color="primary.600" fontSize="sm" fontWeight="semibold">
												Merchant
											</Text>
										</VStack>
										<HStack w="full" justify="space-between">
											<VStack w="full" align="flex-start" spacing="0">
												<Button
													w="full"
													as={NavLink}
													to="/paytank/merchants"
													colorScheme="primary"
													size="sm"
													variant="ghost"
													justifyContent="space-between"
												>
													Merchants
												</Button>
												<Button
													w="full"
													as={NavLink}
													to="/paytank/merchants/types"
													colorScheme="primary"
													size="sm"
													variant="ghost"
													justifyContent="space-between"
												>
													Merchant Types
												</Button>
											</VStack>
										</HStack>
									</VStack>
								</Card>
								<Card w="full" bgColor="primary.100">
									<VStack w="full" align="stretch" spacing={4}>
										<VStack>
											<Icon color="primary.600" fontSize="4xl" as={(props: any) => <FontAwesomeIcon icon={faWallet} {...props} />} />
											<Text color="primary.600" fontSize="sm" fontWeight="semibold">
												Wallet
											</Text>
										</VStack>
										<HStack w="full" justify="space-between">
											<VStack w="full" align="flex-start" spacing="0">
												<Button
													w="full"
													as={NavLink}
													to="/paytank/transactions"
													colorScheme="primary"
													size="sm"
													variant="ghost"
													justifyContent="space-between"
												>
													Transactions
												</Button>
											</VStack>
										</HStack>
									</VStack>
								</Card>
							</Stack>
						</VStack>
					</Stack>
					<PaytankStats />
				</VStack>
			</HStack>
		</Container>
	)
}
