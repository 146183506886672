/* eslint-disable @typescript-eslint/indent */
import format from "date-fns/format"
import { StudentAttendanceReportResponse } from "../graphql"

export const parseStudentCompleteAttendanceReportAsCSV = (labelledAttendances: StudentAttendanceReportResponse) => {
	let data = "Student, Class"

	labelledAttendances.labels.forEach((label) => {
		data = data.concat("," + label)
	})

	labelledAttendances.attendances.forEach(({ student, attendances }) => {
		data = data.concat("\n")
		data = data.concat(
			`${student.name} @${student.username}, ${student.class.name}-${student.classSection.name}, ${attendances.map(
				({ attendance }) =>
					`${
						attendance.isAbsent
							? "-"
							: `${format(new Date(attendance.inAt), "p")} - ${format(new Date(attendance.outAt), "p")} ${
									attendance.isFinal ? "" : "(last seen)"
							  } - ${attendance.isLate && attendance.lateByMinutes ? `late by ${attendance.lateByMinutes} minutes` : "on time"}`
					}`
			)}`
		)
	})

	return data
}
