/* eslint-disable @typescript-eslint/indent */
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import React from "react"
import { LabelledStudentAttendanceClassWiseStatsFragment } from "../../graphql"

export type StudentAttendanceClassWiseStatsTableProps = {
	stats: LabelledStudentAttendanceClassWiseStatsFragment
}

export const StudentAttendanceClassWiseStatsTable: React.FC<StudentAttendanceClassWiseStatsTableProps> = ({ stats }) => {
	return (
		<Box w="full" overflow="auto">
			<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
				<Thead>
					<Tr>
						<Th textTransform="capitalize">Class</Th>
						<Th textTransform="capitalize">Total</Th>
						<Th textTransform="capitalize">Present</Th>
						<Th textTransform="capitalize">Absent</Th>
						<Th textTransform="capitalize">Absentee Names</Th>
					</Tr>
				</Thead>
				<Tbody>
					{stats.classWiseStats.map(({ label, total, present, absent, absentStudents }) => (
						<Tr key={label} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
							<Td>{label}</Td>
							<Td>{total}</Td>
							<Td>{present}</Td>
							<Td>{absent}</Td>
							<Td>{absentStudents.map((s) => s.name).join(", ")}</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</Box>
	)
}
