import { Button, Center, Grid, Heading, HStack, Icon, Spinner, Text, VStack } from "@chakra-ui/react"
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { AllGalleryQueryVariables, useAllGalleryQuery } from "../../graphql"
import { useDrawer } from "../../hooks"
import { Gallery } from "./Gallery"

export const AllGallery: React.FC = () => {
	const [pagination, setPagination] = useState<AllGalleryQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useAllGalleryQuery({
		variables: { pagination },
	})

	const nextPage = () => {
		if (data?.allGallery.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	const navigate = useNavigate()

	const { isShown } = useDrawer()

	return (
		<VStack w="full" maxW="6xl" align="stretch" gap={5}>
			<HStack w="full" justify="space-between">
				<Heading as="h2" fontSize="md" fontWeight="normal" color="text.500">
					Showing {data?.allGallery.total || "all"} gallery of your school
				</Heading>
				<Button
					colorScheme="whiteAlpha"
					color="text.500"
					rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faAdd} {...props} />} />}
					onClick={() => navigate("/gallery/new")}
				>
					Create New
				</Button>
			</HStack>
			<Grid w="full" templateColumns={{ base: "1fr", lg: isShown ? "1fr" : "1fr", xl: "1fr" }} gap={4}>
				{data?.allGallery?.gallery?.map((gallery) => (
					<Gallery key={gallery._id} gallery={gallery} />
				))}
			</Grid>
			{fetching ? (
				<Center w="full" maxW="6xl" py="8">
					<Spinner color="text.400" />
				</Center>
			) : data?.allGallery.gallery && data?.allGallery.gallery.length <= 0 ? (
				<Center w="full" maxW="6xl" py="8">
					<Text fontSize="sm" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any gallery.
					</Text>
				</Center>
			) : data?.allGallery?.hasNextPage ? (
				<Center w="full" maxW="lg" py="8">
					<Button variant="link" size="sm" colorScheme="blackAlpha" onClick={nextPage}>
						Show more
					</Button>
				</Center>
			) : (
				<Center w="full" maxW="6xl" py="8">
					<Text fontSize="sm" fontWeight="semibold" color="text.400">
						You have seen it all
					</Text>
				</Center>
			)}
		</VStack>
	)
}
