import {
	AspectRatio,
	Button,
	ButtonGroup,
	Center,
	Container,
	Divider,
	Flex,
	Heading,
	HStack,
	Icon,
	Spinner,
	Stack,
	Tag,
	Text,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react"
import { faPencil } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { NavLink, useParams } from "react-router-dom"
import { ActivateWorkshopButton, DashboardHeader, DeactivateWorkshopButton, Image, Nav, Rating, WorkshopSessionsList, WorkshopTrainer } from "../../components"
import { UploadWorkshopThumbnail } from "../../components/upload"
import { GRADIENT } from "../../constants"
import { useWorkshopByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const Workshop: React.FC = () => {
	const { workshopId = "" } = useParams<{ workshopId: string }>()

	const [{ data, fetching, error }] = useWorkshopByIdQuery({
		variables: { workshopId },
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.workshopById ? (
						<VStack w="full" align="stretch">
							<Stack direction={{ base: "column", xl: "row" }} w="full" align="stretch" spacing={6}>
								<VStack flex="6" w="full" align="flex-start" spacing="4">
									<UploadWorkshopThumbnail workshopId={data.workshopById._id}>
										<AspectRatio w="full" maxW="xl" cursor="pointer" ratio={16 / 9}>
											<Image src={data.workshopById.thumbnail?.url} bgColor="gray.200" rounded="xl" alt={data.workshopById.title} />
										</AspectRatio>
									</UploadWorkshopThumbnail>
									<VStack w="full" maxW="xl" align="flex-start">
										<HStack w="full">
											{data.workshopById.isLive && <Tag colorScheme="purple">Live</Tag>}
											{data.workshopById.isActive ? <Tag colorScheme="green">Active</Tag> : <Tag colorScheme="red">Inactive</Tag>}
										</HStack>

										<Heading as="h1" fontSize="xl" color="purple.500">
											{data.workshopById.title}
										</Heading>

										<Flex w="full" justify="space-between" align="center" flexWrap="wrap">
											<VStack align="flex-start" spacing={0}>
												<Text fontSize="xs" color="blackAlpha.700" fontWeight="semibold">
													Price
												</Text>
												<Text fontSize="lg" fontWeight="bold" color="purple.500">
													{data.workshopById.isPaid && data.workshopById.priceInINR ? `₹${data.workshopById.priceInINR}` : "Free"}
												</Text>
											</VStack>
											<VStack align="flex-start" spacing={0}>
												<Text fontSize="xs" color="blackAlpha.700" fontWeight="semibold">
													Sessions
												</Text>
												<Text fontSize="lg" fontWeight="bold" color="purple.500">
													{data.workshopById.sessions?.length || 0}
												</Text>
											</VStack>
											<VStack align="flex-start" spacing={0}>
												<Text fontSize="xs" color="blackAlpha.700" fontWeight="semibold">
													Attendees
												</Text>
												<Text fontSize="lg" fontWeight="bold" color="purple.500">
													{data.workshopById.enrolledUsers?.length || 0}
												</Text>
											</VStack>
											<Rating
												rating={
													(data.workshopById.ratings?.map((a) => a.rating)?.reduce((a, b) => a + b, 0) || 0) /
														(data.workshopById.ratings?.length || 0) || 0
												}
												total={data.workshopById.ratings?.length || 0}
											/>
										</Flex>
									</VStack>

									<VStack w="full" align="stretch" spacing={12}>
										<VStack align="flex-start" spacing={0}>
											<Text fontSize="xs" color="blackAlpha.700" fontWeight="semibold">
												About the workshop
											</Text>
											<Text fontSize="sm" maxW="xl" whiteSpace="pre-line">
												{data.workshopById.description}
											</Text>
										</VStack>
										<VStack align="flex-start" spacing={0}>
											<Text fontSize="xs" color="blackAlpha.700" fontWeight="semibold">
												Benefit of this workshop
											</Text>
											<Text fontSize="sm" maxW="xl" whiteSpace="pre-line">
												{data.workshopById.benefit}
											</Text>
										</VStack>
										{data.workshopById.trainers?.length && (
											<VStack align="flex-start" spacing={0}>
												<Text fontSize="xs" color="blackAlpha.700" fontWeight="semibold">
													Trainers
												</Text>

												<Flex flexWrap="wrap" alignItems="center">
													{data.workshopById.trainers.map((trainer) => (
														<WorkshopTrainer key={trainer._id} workshopId={workshopId} trainer={trainer} />
													))}
												</Flex>
											</VStack>
										)}
									</VStack>
								</VStack>
								<VStack
									pos={{ lg: isShown ? "relative" : "absolute", xl: "absolute" }}
									w={{ base: "full", lg: isShown ? "full" : "sm", xl: isShown ? "md" : "lg" }}
									right={{ lg: isShown ? 0 : 4, xl: 4 }}
									align="flex-start"
									bgColor="whiteAlpha.600"
									rounded="xl"
									maxH="85vh"
									shadow="xl"
									spacing={0}
								>
									<VStack pos="relative" w="full" align="flex-start">
										<VStack w="full" bgColor="purple.100" roundedTop="xl" pb="12">
											<Heading fontSize="lg" mt="4" color="blackAlpha.600">
												Sessions
											</Heading>
										</VStack>
										<Button
											pos="absolute"
											as={NavLink}
											to="addSession"
											top="3.2rem"
											left="2"
											right="2"
											h="12"
											colorScheme="purple"
											zIndex={10}
										>
											Add New Session
										</Button>
									</VStack>
									<WorkshopSessionsList workshop={data.workshopById} />
								</VStack>
							</Stack>
							<VStack w="full" maxW="xl" align="stretch" spacing={4}>
								<Divider borderColor="purple.200" />
								<Heading fontSize="sm">Actions</Heading>
								<ButtonGroup>
									<Button
										size="xs"
										variant="outline"
										colorScheme="purple"
										rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faPencil} {...props} />} fontSize="sm" />}
										as={NavLink}
										to="edit"
									>
										Edit
									</Button>
									{data.workshopById.isActive ? (
										<DeactivateWorkshopButton workshopId={workshopId} />
									) : (
										<ActivateWorkshopButton workshopId={workshopId} />
									)}
								</ButtonGroup>
							</VStack>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the workshop.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
