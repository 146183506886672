/* eslint-disable @typescript-eslint/indent */
import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import format from "date-fns/format"
import React from "react"
import { StaffAttendanceReportResponse } from "../../graphql"

import { StaffTag } from "../staff"

export type StaffCompleteAttendancesTableProps = {
	labelledAttendances: StaffAttendanceReportResponse
}

export const StaffCompleteAttendancesTable: React.FC<StaffCompleteAttendancesTableProps> = ({ labelledAttendances }) => {
	return (
		<Box w="full" overflow="auto">
			<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
				<Thead>
					<Tr>
						<Th textTransform="capitalize">Staff</Th>
						{labelledAttendances.labels.map((label) => (
							<Th key={label} textTransform="capitalize">
								{label}
							</Th>
						))}
					</Tr>
				</Thead>
				<Tbody>
					{labelledAttendances.attendances.map(({ staff, attendances }) => (
						<Tr key={staff._id} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
							<Td>
								<StaffTag staff={staff} />
							</Td>
							{attendances.map(
								({
									label,
									attendance: { inAt, outAt, isInManual, isInRecorded, isFinal, isLate, lateByMinutes, isAbsent, inReader, outReader },
								}) => (
									<Td key={label}>
										{isAbsent ? (
											"-"
										) : (
											<>
												<Text as="span">
													{isInManual || isInRecorded
														? `${format(new Date(inAt), "p")} ${
																inReader
																	? inReader.label
																		? `(${inReader.label})`
																		: "(Reader " + `${inReader.readerDecimalId})`
																	: ""
														  }`
														: "-"}
												</Text>
												{" | "}
												<Text as="span">
													{isAbsent
														? "-"
														: `${format(new Date(outAt), "p")} ${isFinal ? "" : "(last seen)"} ${
																outReader
																	? outReader.label
																		? `(${outReader.label})`
																		: "(Reader " + `${outReader.readerDecimalId})`
																	: ""
														  }`}
												</Text>
												{" | "}
												<Text as="span">
													{isInManual || isInRecorded
														? isLate && lateByMinutes
															? `Late by ${lateByMinutes} minutes`
															: "On time"
														: "-"}
												</Text>
											</>
										)}
									</Td>
								)
							)}
						</Tr>
					))}
				</Tbody>
			</Table>
		</Box>
	)
}
