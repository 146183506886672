import { Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useNavigate } from "react-router-dom"
import { DepartmentFragment, useRemoveDepartmentMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type RemoveDepartmentButtonProps = {
	department: DepartmentFragment
}

export const RemoveDepartmentButton: React.FC<RemoveDepartmentButtonProps> = ({ department }) => {
	const [{ fetching }, removeDepartment] = useRemoveDepartmentMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	const handleRemoveDepartment = async () => {
		const { error } = await removeDepartment({ departmentId: department._id })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		onClose()
		return navigate("/departments", { replace: true })
	}

	return (
		<>
			<Tooltip label={`Remove department ${department.name}`} fontSize="sm">
				<IconButton aria-label="remove-department-btn" variant="ghost" colorScheme="blackAlpha" size="sm" {...getButtonProps()}>
					<Icon as={(props: any) => <FontAwesomeIcon icon={faTrash} {...props} />} />
				</IconButton>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Remove Department"
				message="Removing this department may affect staffs associated with this department."
				confirmLabel="Remove"
				onConfirm={handleRemoveDepartment}
				isLoading={fetching}
			/>
		</>
	)
}
