/* eslint-disable @typescript-eslint/indent */
import {
	Button,
	Center,
	Container,
	Heading,
	HStack,
	Icon,
	Input,
	InputGroup,
	InputRightAddon,
	Select,
	Spinner,
	Stack,
	Text,
	useBreakpointValue,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import { useNavigate } from "react-router-dom"
import { DashboardHeader, Nav } from "../../../components"
import { GRADIENT } from "../../../constants"
import {
	CardTypes,
	ExtendedClassFragment,
	ClassSectionFragment,
	useClassesQuery,
	useGenerateStudentCardMutation,
	useStudentsAtGenerateCardPageQuery,
} from "../../../graphql"
import { useDrawer } from "../../../hooks"
import { StudentsSelector } from "./StudentSelector"

export const GenerateStudentsCard: React.FC = () => {
	const { isShown } = useDrawer()
	const navigate = useNavigate()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [searchKeyword, setSearchKeyword] = useState("")

	const [{ data: classesData, fetching: fetchingClasses, error: classesError }] = useClassesQuery()

	const [schoolClass, setSchoolClass] = useState<ExtendedClassFragment | undefined>()

	const [classSection, setClassSection] = useState<ClassSectionFragment>()

	useEffect(() => {
		if (classSection && !schoolClass?.sections.map((s) => s._id).includes(classSection._id)) {
			setClassSection(undefined)
		}
	}, [schoolClass])

	useEffect(() => {
		setSelectedStudentId("")
	}, [schoolClass, classSection])

	const [{ data, fetching, error }] = useStudentsAtGenerateCardPageQuery({
		variables: { keyword: searchKeyword, classId: schoolClass?._id, classSectionId: classSection?._id },
	})

	const [selectedStudentId, setSelectedStudentId] = useState<string>("")

	const [{ fetching: fetchingGenerateCard }, generateStudentCard] = useGenerateStudentCardMutation()

	const [isGenerated, setIsGenerated] = useState(false)
	const [showCard, setShowCard] = useState(false)

	const toast = useToast()

	const handleCardGeneration = async () => {
		setShowCard(true)

		const { data, error } = await generateStudentCard({ input: { cardType: CardTypes.Student, selectedStudentId: selectedStudentId } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}
		setIsGenerated(true)
		navigate(`/generateCards/generated/${data?.generateStudentCard.generateCard?._id}`)
	}

	useEffect(() => {
		setIsGenerated(false)
	}, [selectedStudentId])

	const handleExeatCardGeneration = async () => {
		setShowCard(true)

		const { data, error } = await generateStudentCard({ input: { cardType: CardTypes.StudentExeat, selectedStudentId: selectedStudentId } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}
		setIsGenerated(true)
		navigate(`/generateCards/generated/${data?.generateStudentCard.generateCard?._id}`)
	}

	useEffect(() => {
		setIsGenerated(false)
	}, [selectedStudentId])

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Generate Students Card
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								{
									// TODO: add sub heading
								}
							</Heading>
						</VStack>
						<InputGroup maxW="sm" bg="white.500" rounded="xl" py={isMobile ? 0.5 : 1}>
							<Input
								placeholder="Search Students"
								border="none"
								_focus={{ border: "none !important" }}
								_placeholder={{ fontSize: isMobile ? "sm" : "md" }}
								onChange={(e) => setSearchKeyword(e.target.value)}
							/>
							<InputRightAddon bg="transparent" border="none">
								<Icon as={(props: any) => <FontAwesomeIcon icon={faSearch} {...props} />} color="text.100" fontSize={isMobile ? "md" : "lg"} />
							</InputRightAddon>
						</InputGroup>
					</Stack>
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-end" justify="space-between">
						{fetchingClasses ? (
							<Center>
								<VStack w="full" color="blackAlpha.800">
									<Text>Loading classes</Text>
									<Spinner size="sm" />
								</VStack>
							</Center>
						) : classesError ? (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
									Failed loading classes, try reloading the page
								</Text>
							</Center>
						) : classesData?.classes.length ? (
							<HStack w="full" align="flex-end" justify="flex-end" spacing={5} alignItems="center">
								<Stack w="full" direction={isMobile ? "column" : "row"} align="center">
									<Button
										m="1"
										size="sm"
										colorScheme="primary"
										isLoading={fetching}
										onClick={handleCardGeneration}
										disabled={!selectedStudentId}
									>
										Generate Card
									</Button>
									<Button
										m="1"
										size="sm"
										colorScheme="primary"
										isLoading={fetching}
										onClick={handleExeatCardGeneration}
										disabled={!selectedStudentId}
									>
										Generate Exeat Card
									</Button>
								</Stack>
								<Button
									m="1"
									size="sm"
									colorScheme="primary"
									variant="link"
									onClick={() => {
										setShowCard(false)
										setSelectedStudentId("")
									}}
									disabled={!selectedStudentId}
								>
									Clear
								</Button>

								<HStack spacing={0}>
									<Select
										bg="white.500"
										roundedStart="xl"
										roundedEnd={schoolClass && schoolClass.sections?.length ? "none" : "xl"}
										border="none"
										_focus={{ border: "none" }}
										w="full"
										maxW="xs"
										placeholder="All"
										value={JSON.stringify(schoolClass)}
										onChange={(e) => {
											setSchoolClass(e.target.value ? JSON.parse(e.target.value) : undefined)
										}}
										disabled={showCard && isGenerated}
									>
										{classesData?.classes.map((c) => (
											<option key={c._id} value={JSON.stringify(c)}>
												{c.name}
											</option>
										))}
									</Select>
									{schoolClass && schoolClass.sections?.length && (
										<Select
											bg="white.500"
											roundedStart="none"
											roundedEnd="xl"
											border="none"
											_focus={{ border: "none" }}
											w="full"
											maxW="xs"
											placeholder="All"
											value={JSON.stringify(classSection)}
											onChange={(e) => {
												setClassSection(e.target.value ? JSON.parse(e.target.value) : undefined)
											}}
											disabled={showCard && isGenerated}
										>
											{schoolClass.sections.map((s) => (
												<option key={s.name} value={JSON.stringify(s)}>
													{s.name}
												</option>
											))}
										</Select>
									)}
								</HStack>
							</HStack>
						) : (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any classes.
								</Text>
							</Center>
						)}
					</Stack>
					<VStack w="full" align="center">
						{showCard && fetchingGenerateCard ? (
							<VStack w="full" maxW="2xl">
								<Spinner />
								<Text textAlign="center">Generating card</Text>
							</VStack>
						) : fetching ? (
							<Center py="4">
								<Spinner color="text.400" />
							</Center>
						) : error ? (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
									Failed loading students, try reloading the page
								</Text>
							</Center>
						) : data?.studentsAtGenerateCardPage && data?.studentsAtGenerateCardPage.length > 0 ? (
							<StudentsSelector
								students={data?.studentsAtGenerateCardPage}
								selectStudentId={selectedStudentId}
								onChange={setSelectedStudentId}
								removeHeading
							/>
						) : (
							<Center py="4">
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any students.
								</Text>
							</Center>
						)}
					</VStack>
				</VStack>
			</HStack>
		</Container>
	)
}
