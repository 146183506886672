import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	AlertDialogProps,
	Button,
	Text,
	VStack,
} from "@chakra-ui/react"
import React, { useRef } from "react"

export type ConfirmationAlertDialogProps = {
	title: string
	message: string
	onConfirm: () => void
	confirmLabel: string
	onCancel?: () => void
	cancelLabel?: string
	isLoading?: boolean
	isDanger?: boolean
	children?: React.ReactNode
} & Omit<AlertDialogProps, "leastDestructiveRef" | "children">

export const ConfirmationAlertDialog: React.FC<ConfirmationAlertDialogProps> = ({
	title,
	message,
	onConfirm,
	onCancel,
	confirmLabel,
	cancelLabel,
	isLoading,
	isDanger = true,
	children,
	...props
}) => {
	const cancelRef = useRef() as any

	return (
		<AlertDialog leastDestructiveRef={cancelRef} {...props}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						{title}
					</AlertDialogHeader>

					<AlertDialogBody>
						<VStack w="full" align="flex-start">
							<Text>{message}</Text>
							{children}
						</VStack>
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button ref={cancelRef} size="sm" onClick={onCancel ?? props.onClose}>
							{cancelLabel || "Cancel"}
						</Button>
						<Button colorScheme={isDanger ? "red" : "purple"} size="sm" onClick={onConfirm} ml={3} isLoading={isLoading}>
							{confirmLabel}
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	)
}
