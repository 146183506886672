import { Checkbox, Heading, Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { SchoolTimingFragment } from "../../graphql"
import { usePagination } from "../../hooks"

export type SchoolTimingSelectorProps = {
	schoolTimings: SchoolTimingFragment[]
	selectedSchoolTimingId: string
	onChange: (schoolTimingId: string) => void
	removeHeading?: boolean
}

export const SchoolTimingSelector: React.FC<SchoolTimingSelectorProps> = ({ schoolTimings, selectedSchoolTimingId, onChange, removeHeading }) => {
	const selectSchoolTiming = (schoolTimingId: string) => {
		onChange(schoolTimingId)
	}

	const deselectSchoolTiming = () => {
		onChange("")
	}

	const isSchoolTimingSelected = useCallback((schoolTimingId: string) => selectedSchoolTimingId.includes(schoolTimingId), [selectedSchoolTimingId])

	useEffect(() => {
		onChange(selectedSchoolTimingId)
	}, [selectedSchoolTimingId])

	const { currentItems, loadMore, hasMore } = usePagination<SchoolTimingFragment>(schoolTimings, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
				{removeHeading ? "" : "Select the school timing"}
			</Heading>
			<VStack w="full" align="stretch" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th></Th>
							<Th textTransform="capitalize">Name</Th>
							<Th textTransform="capitalize">Timing</Th>
						</Tr>
					</Thead>
					<Tbody>
						{currentItems.map((schoolTiming) => (
							<Tr key={schoolTiming._id} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td>
									<Checkbox
										colorScheme="blackAlpha"
										borderColor="black"
										isChecked={isSchoolTimingSelected(schoolTiming._id)}
										onChange={(e) => (e.target.checked ? selectSchoolTiming(schoolTiming._id) : deselectSchoolTiming())}
									/>
								</Td>

								<Td>{schoolTiming.name}</Td>
								<Td>
									{schoolTiming.attendanceTiming.inAt} - {schoolTiming.attendanceTiming.outAt}
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
				{hasMore && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
			</VStack>
		</VStack>
	)
}
