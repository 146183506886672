import {
	Button,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	Icon,
	NumberInput,
	NumberInputField,
	SlideFade,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import {
	CafeteriaRegistrationChargeFragment,
	CreateCafeteriaRegistrationChargeMutationVariables,
	useEditCafeteriaRegistrationChargeMutation,
} from "../../../graphql"

const validationSchema = Yup.object({
	amount: Yup.number().min(0).required().label("Amount"),
})

type EditCafeteriaRegistrationChargeFormProps = {
	cafeteriaRegistrationCharge: CafeteriaRegistrationChargeFragment
}

export const EditCafeteriaRegistrationChargeForm: React.FC<EditCafeteriaRegistrationChargeFormProps> = ({ cafeteriaRegistrationCharge }) => {
	const [{ fetching }, edit] = useEditCafeteriaRegistrationChargeMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (
		{ amount }: CreateCafeteriaRegistrationChargeMutationVariables["input"],
		helpers: FormikHelpers<CreateCafeteriaRegistrationChargeMutationVariables["input"]>
	) => {
		const { error, data } = await edit({ cafeteriaRegistrationChargeId: cafeteriaRegistrationCharge._id, input: { amount } })

		if (error) {
			return toast({
				description: error.message,
				status: "error",
			})
		}

		if (data?.editCafeteriaRegistrationCharge.errors) {
			return data?.editCafeteriaRegistrationCharge.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		return navigate("/cafeteria/registrationCharges", { replace: true })
	}

	const formik = useFormik<CreateCafeteriaRegistrationChargeMutationVariables["input"]>({
		initialValues: { amount: cafeteriaRegistrationCharge.amount },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.amount && formik.touched.amount)}>
				<SlideFade in={Boolean(formik.values.amount)} unmountOnExit>
					<FormLabel htmlFor="title" fontSize="sm">
						Amount
					</FormLabel>
				</SlideFade>
				<NumberInput
					min={1}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("amount")}
					onChange={(_, value) => formik.setFieldValue("amount", value)}
				>
					<NumberInputField />
				</NumberInput>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.amount}</Text>
				</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Save
			</Button>
		</VStack>
	)
}
