import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Flex,
	Heading,
	HStack,
	StackProps,
	Tag,
	Text,
	useDisclosure,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { FocusableElement } from "@chakra-ui/utils"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { RefObject, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { ClassSectionFragment, ExtendedClassFragment, useRemoveClassSectionMutation } from "../../graphql"
import { RemoveClassTeacherButton } from "../class"
import { StaffTag } from "../staff"
import { RemoveClassSectionButton } from "./RemoveClassSectionButton"
import { RemoveSchoolTimingButton } from "./RemoveSchoolTimingButton"

export type ClassSectionCardProps = {
	schoolClass: ExtendedClassFragment
	classSection: ClassSectionFragment
} & StackProps

export const ClassSectionCard: React.FC<ClassSectionCardProps> = ({ schoolClass, classSection, ...props }) => {
	const [, removeSection] = useRemoveClassSectionMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleRemoveSection = async () => {
		const { error } = await removeSection({
			classId: schoolClass._id,
			classSectionId: classSection._id,
		})

		if (error) {
			return toast({
				title: "Section Error",
				description: error.message,
				status: "error",
			})
		}
	}

	const { isOpen, onClose } = useDisclosure()

	const leastDestructiveRef = useRef<FocusableElement>() as RefObject<FocusableElement>

	return (
		<>
			<VStack align="flex-start" bg="white.500" rounded="2xl" p={4} maxW="44" spacing={4} shadow="sm" {...props}>
				<VStack w="full" align="flex-start">
					<HStack w="full" justify="space-between">
						<Heading fontSize="md" fontWeight="semibold">
							{classSection.name}
						</Heading>
						<RemoveClassSectionButton schoolClass={schoolClass} classSection={classSection} />
					</HStack>
				</VStack>

				{classSection && classSection.schoolTiming && (
					<VStack w="full" align="start" spacing={0}>
						<HStack>
							<Text fontSize="xs">Timing</Text>
						</HStack>
						<Text fontSize="xs" color="blackAlpha.700" fontWeight="semibold">
							{classSection.schoolTiming.attendanceTiming.inAt} - {classSection.schoolTiming.attendanceTiming.outAt}
						</Text>
					</VStack>
				)}

				{classSection && classSection.schoolTiming && (
					<VStack w="full" align="start" spacing={0}>
						<HStack>
							<Text fontSize="xs">Working weekdays</Text>
						</HStack>
						<Flex flexWrap="wrap">
							{classSection.schoolTiming.workingWeekdays?.length ? (
								classSection.schoolTiming.workingWeekdays.map((day) => (
									<Tag key={day} m="0.5" size="sm" colorScheme="purple">
										{day}
									</Tag>
								))
							) : (
								<Text fontSize="xs" color="blackAlpha.700" fontWeight="semibold">
									Not set
								</Text>
							)}
						</Flex>
					</VStack>
				)}

				{classSection.schoolTiming ? (
					<HStack spacing={4}>
						<VStack align="flex-start" spacing={0}>
							<Heading fontSize="xs" fontWeight="semibold" color="text.400">
								School Timing
							</Heading>
							<VStack align="flex-start" spacing="0">
								<Heading fontSize="xs">{classSection.schoolTiming.name}</Heading>
								<Text fontSize="xs">
									{classSection.schoolTiming.attendanceTiming.inAt} - {classSection.schoolTiming.attendanceTiming.outAt}
								</Text>
							</VStack>
						</VStack>
						<RemoveSchoolTimingButton classSectionId={classSection._id} schoolTiming={classSection.schoolTiming} />
					</HStack>
				) : (
					<Button
						colorScheme="primary"
						variant="link"
						size="sm"
						rightIcon={<FontAwesomeIcon icon={faPlus} />}
						onClick={() => navigate(`/classes/${classSection.classId}/${classSection._id}/addSchoolTiming`)}
					>
						School Timing
					</Button>
				)}

				{classSection.classTeacher ? (
					<HStack spacing={4}>
						<VStack align="flex-start" spacing={0}>
							<Heading fontSize="xs" fontWeight="semibold" color="text.400">
								Class Teacher
							</Heading>
							<StaffTag staff={classSection.classTeacher!} />
						</VStack>
						<RemoveClassTeacherButton classId={classSection.classId} classSectionId={classSection._id} teacher={classSection.classTeacher!} />
					</HStack>
				) : (
					<Button
						colorScheme="primary"
						variant="link"
						size="sm"
						rightIcon={<FontAwesomeIcon icon={faPlus} />}
						onClick={() => navigate(`/classes/${classSection.classId}/${classSection._id}/addClassTeacher`)}
					>
						Class Teacher
					</Button>
				)}
			</VStack>
			<AlertDialog leastDestructiveRef={leastDestructiveRef} isOpen={isOpen} onClose={onClose}>
				<AlertDialogOverlay backdropFilter="blur(16px)">
					<AlertDialogContent>
						<AlertDialogHeader>
							<Heading fontSize="lg" color="text.500">
								Are you sure?
							</Heading>
							<AlertDialogCloseButton colorScheme="text" />
						</AlertDialogHeader>

						<AlertDialogBody fontSize="sm">Removing this section may affect students and/or teachers associated with this section.</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={leastDestructiveRef as React.LegacyRef<HTMLButtonElement>} size="sm" onClick={onClose}>
								Cancel
							</Button>
							<Button colorScheme="red" size="sm" onClick={handleRemoveSection} ml={3}>
								Remove
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	)
}
