import { Center, Container, Heading, HStack, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React from "react"
import {
	DashboardHeader,
	Nav,
	UpdateCafeteriaApprovedMessageForm,
	UpdateCafeteriaDeniedMessageForm,
	UpdateCafeteriaInactiveRegistrationMessageForm,
} from "../../../components"
import { GRADIENT } from "../../../constants"
import { useMeQuery } from "../../../graphql"
import { useDrawer } from "../../../hooks"

export const UpdateCafeteriaMessages: React.FC = () => {
	const [{ data, fetching, error }] = useMeQuery()

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.me ? (
						<VStack w="full" align="flex-start" spacing="8">
							<Heading as="h1" fontSize="xl" color="text.500">
								Cafeteria Messages
							</Heading>
							<VStack w="full" align="flex-start" spacing="2">
								<Heading as="h1" fontSize="md" color="text.500">
									Inactive Registration
								</Heading>

								<UpdateCafeteriaInactiveRegistrationMessageForm
									message={data.me.cafeteriaMessages?.inactiveRegistrationMessage || ""}
									audioUrl={data.me.cafeteriaMessages?.inactiveRegistrationAudio?.url}
								/>
							</VStack>

							<VStack w="full" align="flex-start" spacing="2">
								<Heading as="h1" fontSize="md" color="text.500">
									Approval
								</Heading>

								<UpdateCafeteriaApprovedMessageForm
									message={data.me.cafeteriaMessages?.approvedMessage || ""}
									audioUrl={data.me.cafeteriaMessages?.approvedAudio?.url}
								/>
							</VStack>

							<VStack w="full" align="flex-start" spacing="2">
								<Heading as="h1" fontSize="md" color="text.500">
									Denial
								</Heading>

								<UpdateCafeteriaDeniedMessageForm
									message={data.me.cafeteriaMessages?.deniedMessage || ""}
									audioUrl={data.me.cafeteriaMessages?.deniedAudio?.url}
								/>
							</VStack>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the cafeteria registration charge.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
