import { BoxProps, HStack, LinkBox, LinkOverlay, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { Department } from "../../graphql"
import { Card, CardProps } from "../common"
import { RemoveUnauthorizedDepartmentButton } from "./RemoveUnauthorizedDepartmentButton"

export type UnauthorizedDepartmentCardProps = {
	restrictedZoneId: string
	department: Department
} & BoxProps

export const UnauthorizedDepartmentCard: React.FC<UnauthorizedDepartmentCardProps> = ({ restrictedZoneId, department, ...props }) => {
	const [isHovered, setIsHovered] = useState(false)

	const isMobile = useBreakpointValue({ base: true, md: false })

	return (
		<LinkBox onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
			<Card position="relative" {...(props as unknown as CardProps)}>
				{(isMobile || isHovered) && <RemoveUnauthorizedDepartmentButton restrictedZoneId={restrictedZoneId} departmentId={department._id} />}
				<LinkOverlay href={`/departments/${department._id}`}>
					<HStack align="flex-start" spacing="6">
						<VStack align="flex-start" spacing="0">
							<Text fontSize="md" fontWeight="semibold">
								{department.name}
							</Text>
							<Text fontSize="xs" color="text.400">
								Department
							</Text>
						</VStack>
					</HStack>
				</LinkOverlay>
			</Card>
		</LinkBox>
	)
}
