import { Box, Drawer, DrawerContent, DrawerOverlay, DrawerProps, Stack, useBreakpointValue, VStack } from "@chakra-ui/react"
import React from "react"

export type BottomActionSheetProps = DrawerProps

export const BottomActionSheet: React.FC<BottomActionSheetProps> = (props) => {
	const isMobile = useBreakpointValue({ base: true, sm: false })

	const roundedProps = isMobile ? { roundedTop: "xl" } : { roundedLeft: "xl" }

	return (
		<Drawer {...props} placement={isMobile ? "bottom" : "right"}>
			<DrawerOverlay backdropFilter="blur(2px)" />
			<DrawerContent {...roundedProps}>
				<Stack w="full" h="full" direction={isMobile ? "column" : "row"}>
					<Box
						h={isMobile ? 1 : "full"}
						w={isMobile ? "full" : 1}
						maxW="28"
						maxH="28"
						bg="primary.100"
						mx={isMobile ? "auto !important" : "12px !important"}
						my={isMobile ? "12px !important" : "auto"}
						rounded="full"
					/>
					<VStack w="full" align="stretch" pb={isMobile ? 12 : 0} pt={!isMobile ? 6 : 0}>
						{props.children}
					</VStack>
				</Stack>
			</DrawerContent>
		</Drawer>
	)
}
