/* eslint-disable @typescript-eslint/indent */
import {
	AspectRatio,
	Button,
	ButtonGroup,
	Center,
	chakra,
	Divider,
	DrawerBody,
	Grid,
	Heading,
	HStack,
	Icon,
	IconButton,
	Img,
	LinkBox,
	LinkOverlay,
	Switch,
	Text,
	useDisclosure,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faCancel, faEllipsis, faFilePdf, faShare, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import formatDistanceToNow from "date-fns/formatDistanceToNow"
import React, { useMemo, useState } from "react"
import { NavLink } from "react-router-dom"
import YouTube from "react-youtube"
import { SITE_URL } from "../../constants"
import { AuthorTypes, ExtendedFeedPostFragment, useApproveFeedPostMutation, useDeleteFeedPostMutation, useRemoveFeedPostMutation } from "../../graphql"
import { BottomActionSheet, Image } from "../common"
import { DeleteFeedPostAlert } from "./DeleteFeedPostAlert"
import { LikeFeedPostButton } from "./LikeFeedPostButton"
import { RemoveFeedPostAlert } from "./RemoveFeedPostAlert"
import { SharePostActionSheet } from "./SharePostActionSheet"

const colors = {
	[AuthorTypes.School]: "#ecf6fd",
	[AuthorTypes.Staff]: "#fafafa",
	[AuthorTypes.Student]: "#fff3e2",
}

export type FeedPostProps = {
	post: ExtendedFeedPostFragment
	isLinkable?: boolean
}

export const UnApprovedFeedPost: React.FC<FeedPostProps> = ({ post }) => {
	const author = useMemo<{
		title: string
		subtitle: string
		picture: string
		link: string
	}>(
		() => ({
			title:
				post.author.authorType === AuthorTypes.Student
					? `${post.author.student?.name}`
					: post.author.authorType === AuthorTypes.School
					? `${post.author.school?.name}`
					: post.author.authorType === AuthorTypes.Staff
					? `${post.author.staff?.name}`
					: "",
			subtitle:
				post.author.authorType === AuthorTypes.Student
					? `@${post.author.student?.username}`
					: post.author.authorType === AuthorTypes.School
					? `${post.author.school?.location.city}, ${post.author.school?.location.state}, ${post.author.school?.location.address} - ${post.author.school?.location.pin}`
					: post.author.authorType === AuthorTypes.Staff
					? `@${post.author.staff?.username}`
					: "",
			picture:
				post.author.authorType === AuthorTypes.Student
					? post.author.student?.picture?.variants?.thumbnail || post.author.student?.picture?.url || ""
					: post.author.authorType === AuthorTypes.School
					? post.author.school?.picture?.variants?.thumbnail || post.author.school?.picture?.url || ""
					: post.author.authorType === AuthorTypes.Staff
					? post.author.staff?.picture?.variants?.thumbnail || post.author.staff?.picture?.url || ""
					: "",
			link:
				post.author.authorType === AuthorTypes.Student && post.author.student
					? `/students/${post.author.student._id}`
					: post.author.authorType === AuthorTypes.School
					? "/dashboard"
					: post.author.authorType === AuthorTypes.Staff
					? `/staff/${post.author.staffId}`
					: "",
		}),
		[post]
	)

	const { isOpen: isMainSheetOpen, onClose: onMainSheetClose, getButtonProps: getMainSheetButtonProps } = useDisclosure()

	const { isOpen: isDeleteAlertOpen, onClose: onDeleteAlertClose, getButtonProps: getDeleteAlertButtonProps } = useDisclosure()

	const { isOpen: isRemoveAlertOpen, onClose: onRemoveAlertClose, getButtonProps: getRemoveAlertButtonProps } = useDisclosure()

	const [, deletePost] = useDeleteFeedPostMutation()
	const [, removePost] = useRemoveFeedPostMutation()

	const toast = useToast()

	const handleDeletePost = async () => {
		const { error } = await deletePost({ feedPostId: post._id })

		if (error) {
			return toast({
				title: "Feed Post Error",
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		onDeleteAlertClose()

		return toast({
			title: "Feed Post Deleted",
			description: "The feed post is successfully deleted",
			status: "success",
		})
	}

	const handleRemovePost = async () => {
		const { error } = await removePost({ feedPostId: post._id })

		if (error) {
			return toast({
				title: "Feed Post Error",
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		onRemoveAlertClose()

		return toast({
			title: "Feed Post Removed",
			description: "The feed post is successfully removed",
			status: "success",
		})
	}

	const [, approveFeedPost] = useApproveFeedPostMutation()

	const [checked, setChecked] = useState<boolean>(false)

	const toogleSwitch = () => {
		if (checked) setChecked(false)
		else {
			setChecked(true)
			approveFeedPost({ feedPostId: post._id })
		}
	}

	const { isOpen: isShareSheetOpen, onClose: onShareSheetClose, onOpen: onShareSheetOpen } = useDisclosure()

	return (
		<>
			{!post.isApproved && (
				<>
					<VStack w="full" maxW="lg" align="flex-start" bg={colors[post.author.authorType]} shadow="sm" rounded="xl" py={2} px={4}>
						<VStack w="full" align="flex-start" spacing={0}>
							<HStack w="full" justify="space-between">
								<HStack flex={1} as={LinkBox} spacing={0}>
									<LinkOverlay as={NavLink} to={author.link} />
									<AspectRatio ratio={1} w="12" rounded="xl" shadow="xl">
										<Image rounded="xl" src={author.picture || "https://picsum.photos/200"} alt={author.title} />
									</AspectRatio>
									<VStack w="full" align="flex-start" spacing={0} pl={2}>
										<Heading fontSize="sm" fontWeight="semibold">
											{author.title}
										</Heading>
										{author.subtitle && (
											<Heading fontSize="xs" fontWeight="normal" color="blackAlpha.700">
												{author.subtitle}
											</Heading>
										)}
									</VStack>
								</HStack>
								<HStack alignItems="center" align="center" textAlign="center">
									<Switch onChange={toogleSwitch} colorScheme="green" alignItems="center" />
									<Text>Approve</Text>
								</HStack>

								<IconButton aria-label="like-feed-post-btn" variant="unstyled" colorScheme="blackAlpha" {...getMainSheetButtonProps()}>
									<Icon as={(props: any) => <FontAwesomeIcon icon={faEllipsis} {...props} />} />
								</IconButton>
							</HStack>
						</VStack>

						<VStack as="div" w="full" align="stretch">
							<Text fontSize="12" color="blackAlpha.700">
								<chakra.span>{post.author.authorType}</chakra.span>
								<chakra.span fontWeight="bold"> • </chakra.span>
								<chakra.span>
									{formatDistanceToNow(new Date(post.createdAt), {
										addSuffix: true,
									})}
								</chakra.span>
							</Text>
							{post.text && (
								<Text fontSize="xs" whiteSpace="pre-line">
									{post.text}
								</Text>
							)}
							{post.attachments?.pdfs?.length ? (
								<Grid templateColumns={getGridTemplateColumns(post.attachments.pdfs.length || 0)} gap={1}>
									{post.attachments.pdfs.slice(0, 4).map((pdf, index) => (
										<AspectRatio
											pos="relative"
											key={index}
											bg="primary.100"
											rounded="xl"
											ratio={1}
											cursor="pointer"
											onClick={() => window.open(pdf.url, "#")}
											zIndex={1}
										>
											<>
												<Center rounded="xl" bg="primary.100">
													<Icon
														color="primary.600"
														fontSize="5xl"
														as={(props: any) => <FontAwesomeIcon icon={faFilePdf} {...props} />}
													/>
												</Center>

												{index === 3 && (post.attachments?.pdfs?.length || 0) > 4 && (
													<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
														<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
															+{(post.attachments?.pdfs?.length || 0) - 4}
														</Text>
													</Center>
												)}
											</>
										</AspectRatio>
									))}
								</Grid>
							) : post.attachments?.videos?.length ? (
								<Grid templateColumns={getGridTemplateColumns(post.attachments.videos.length)} gap={1}>
									{post.attachments.videos.slice(0, 4).map((video, index) => (
										<AspectRatio pos="relative" key={index} bg="primary.100" rounded="xl" ratio={1} zIndex={1}>
											<>
												<video style={{ borderRadius: "12px" }} width="100%" src={video.url} controls />
												{index === 3 && (post.attachments?.videos?.length || 0) > 4 && (
													<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
														<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
															+{(post.attachments?.videos?.length || 0) - 4}
														</Text>
													</Center>
												)}
											</>
										</AspectRatio>
									))}
								</Grid>
							) : post.attachments?.pictures?.length ? (
								<Grid templateColumns={getGridTemplateColumns(post.attachments.pictures.length)} gap={1}>
									{post.attachments.pictures.slice(0, 4).map((picture, index) => (
										<AspectRatio key={index} pos="relative" bg="primary.100" rounded="xl" ratio={1} zIndex={1}>
											<>
												<Img rounded="xl" src={picture.url} />
												{index === 3 && (post.attachments?.pictures?.length || 0) > 4 && (
													<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
														<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
															+{(post.attachments?.pictures?.length || 0) - 4}
														</Text>
													</Center>
												)}
											</>
										</AspectRatio>
									))}
								</Grid>
							) : (
								post.links?.youtube?.[0] && (
									<AspectRatio w="full" ratio={16 / 9} zIndex={99}>
										<YouTube videoId={post.links.youtube[0]} opts={{ width: "100%", height: "100%" }} />
									</AspectRatio>
								)
							)}
						</VStack>
						<ButtonGroup>
							<LikeFeedPostButton post={post} />
						</ButtonGroup>
					</VStack>
					<BottomActionSheet isOpen={isMainSheetOpen} onClose={onMainSheetClose}>
						<DrawerBody as={VStack} px="0" align="stretch">
							{post.author.authorType === AuthorTypes.School && (
								<>
									<Button
										variant="link"
										colorScheme="red"
										fontSize="xs"
										rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faTrash} {...props} />} />}
										{...getDeleteAlertButtonProps()}
									>
										Delete Post
									</Button>
									<Divider maxW="32" mx="auto !important" py="0" />
								</>
							)}

							{post.author.authorType !== AuthorTypes.School && (
								<>
									<Button
										variant="link"
										colorScheme="red"
										fontSize="xs"
										rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faCancel} {...props} />} />}
										{...getRemoveAlertButtonProps()}
									>
										Remove Post
									</Button>
									<Divider maxW="32" mx="auto !important" py="0" />
								</>
							)}

							<Button
								variant="link"
								colorScheme="primary"
								fontSize="xs"
								rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faShare} {...props} />} />}
								onClick={() => {
									onShareSheetOpen()
									onMainSheetClose()
								}}
							>
								Share Post
							</Button>
						</DrawerBody>
					</BottomActionSheet>

					<SharePostActionSheet
						isOpen={isShareSheetOpen}
						onClose={onShareSheetClose}
						title={post.text?.slice(0, 100) || `Post on learntank by ${author.title}`}
						url={`${SITE_URL}/feed/${post._id}`}
					/>

					<DeleteFeedPostAlert isOpen={isDeleteAlertOpen} onClose={onDeleteAlertClose} handleDelete={handleDeletePost} />

					<RemoveFeedPostAlert isOpen={isRemoveAlertOpen} onClose={onRemoveAlertClose} handleRemove={handleRemovePost} />
				</>
			)}
		</>
	)
}

const getGridTemplateColumns = (length: number) => {
	if (length < 2) {
		return "1fr"
	}

	return "repeat(2, 1fr)"
}
