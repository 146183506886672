import { Button, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from "@chakra-ui/react"
import React from "react"
import { AddReaderToRestrictedZoneForm } from "./AddReaderToRestrictedZoneForm"

export type AddReaderToRestrictedZonePopoverProps = {
	restrictedZoneId: string
}

export const AddReaderToRestrictedZonePopover: React.FC<AddReaderToRestrictedZonePopoverProps> = ({ restrictedZoneId }) => {
	return (
		<Popover isLazy lazyBehavior="unmount">
			<PopoverTrigger>
				<Button colorScheme="purple" size="sm">
					Add New
				</Button>
			</PopoverTrigger>
			<PopoverContent bgColor="white">
				<PopoverArrow bgColor="white" />
				<PopoverBody>
					<AddReaderToRestrictedZoneForm restrictedZoneId={restrictedZoneId} />
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
