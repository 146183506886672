import { Button, Center, FormControl, FormErrorMessage, FormLabel, HStack, Icon, Select, SlideFade, Spinner, Text, useToast, VStack } from "@chakra-ui/react"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { AddUnauthorizedClassMutationVariables, useAddUnauthorizedClassMutation, useClassesQuery } from "../../graphql"

const validationSchema = Yup.object({
	classId: Yup.string().required().label("Class"),
	classSectionId: Yup.string().required().label("Section"),
})

export type AddUnauthorizedClassFormProps = {
	restrictedZoneId: string
}

export const AddUnauthorizedClassForm: React.FC<AddUnauthorizedClassFormProps> = ({ restrictedZoneId }) => {
	const [{ fetching }, addUnauthorizedClass] = useAddUnauthorizedClassMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async ({ classId, classSectionId }: Omit<AddUnauthorizedClassMutationVariables, "restrictedZoneId">) => {
		const { error } = await addUnauthorizedClass({ classId, classSectionId, restrictedZoneId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		return navigate(`/restrictedZones/${restrictedZoneId}`, { replace: true })
	}

	const formik = useFormik<Omit<AddUnauthorizedClassMutationVariables, "restrictedZoneId">>({
		initialValues: { classId: "", classSectionId: "" },
		validationSchema,
		onSubmit: handleSubmit,
	})

	const [{ data: classesData, fetching: fetchingClasses, error: classesError }] = useClassesQuery()

	return (
		<VStack w="full" maxW="md" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			{fetchingClasses ? (
				<Center w="full" py="1">
					<VStack>
						<Spinner size="sm" color="text.400" />
						<Text fontSize="xs" color="text.400">
							Loading classes
						</Text>
					</VStack>
				</Center>
			) : classesError ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							{classesError.message.replace("[GraphQL] ", "")}
						</Text>
					</VStack>
				</Center>
			) : (
				<HStack w="full" spacing={0} align="flex-end">
					<FormControl isInvalid={Boolean(formik.errors.classId && formik.touched.classId)}>
						<SlideFade in={Boolean(formik.values.classId)} unmountOnExit>
							<FormLabel htmlFor="classId" fontSize="sm">
								Class
							</FormLabel>
						</SlideFade>
						<Select
							placeholder="Select Class"
							border="none"
							_focus={{
								border: "none",
								borderRight: ".5px solid",
								borderRightColor: "blackAlpha.200",
							}}
							borderRight=".5px solid"
							borderRightColor="blackAlpha.200"
							bg="white.500"
							roundedLeft="xl"
							roundedRight={formik.values.classId ? "none" : "xl"}
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("classId")}
						>
							{classesData?.classes.map(({ _id, name }) => (
								<option key={_id} value={_id}>
									{name}
								</option>
							))}
						</Select>
						<FormErrorMessage>{formik.errors.classId}</FormErrorMessage>
					</FormControl>

					<FormControl
						isInvalid={Boolean(formik.errors.classSectionId && formik.touched.classSectionId)}
						display={formik.values.classId ? "block" : "none"}
					>
						<SlideFade in={Boolean(formik.values.classSectionId)} unmountOnExit>
							<FormLabel htmlFor="classSectionId" fontSize="sm">
								Section
							</FormLabel>
						</SlideFade>
						<Select
							placeholder="Select Section"
							border="none"
							_focus={{
								border: "none",
								borderLeft: ".5px solid",
								borderLeftColor: "blackAlpha.200",
							}}
							borderLeft=".5px solid"
							borderLeftColor="blackAlpha.200"
							bg="white.500"
							roundedRight="xl"
							roundedLeft="none"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("classSectionId")}
						>
							{classesData?.classes
								?.find((_class) => _class._id === formik.values.classId)
								?.sections?.map((section) => (
									<option key={section.name} value={section._id}>
										{section.name}
									</option>
								))}
						</Select>
						<FormErrorMessage>{formik.errors.classSectionId}</FormErrorMessage>
					</FormControl>
				</HStack>
			)}

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Add
			</Button>
		</VStack>
	)
}
