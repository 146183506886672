import { Center, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { SearchPointsRedeemItemsForStaffQueryVariables, useSearchPointsRedeemItemsForStaffQuery } from "../../graphql"
import { PointsRedeemItemsGrid } from "./PointsRedeemItemsGrid"

export type SearchResultPointsRedeemItemsForStaffProps = {
	searchKeyword: string
}

export const SearchResultPointsRedeemItemsForStaff: React.FC<SearchResultPointsRedeemItemsForStaffProps> = ({ searchKeyword }) => {
	const [pagination, setPagination] = useState<SearchPointsRedeemItemsForStaffQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useSearchPointsRedeemItemsForStaffQuery({
		variables: { pagination, keyword: searchKeyword },
	})

	const prevPage = () => {
		if (data?.searchPointsRedeemItemsForStaff.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.searchPointsRedeemItemsForStaff.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="md" fontWeight="normal" color="text.500">
				Showing {data?.searchPointsRedeemItemsForStaff.total || "all"} items for staff for search keyword <strong>&quot;{searchKeyword}&quot;</strong>
			</Heading>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.searchPointsRedeemItemsForStaff.pointsRedeemItems && data?.searchPointsRedeemItemsForStaff.pointsRedeemItems.length > 0 ? (
				<PointsRedeemItemsGrid
					pointsRedeemItems={data.searchPointsRedeemItemsForStaff.pointsRedeemItems}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.searchPointsRedeemItemsForStaff.hasPrevPage}
					hasNextPage={data.searchPointsRedeemItemsForStaff.hasNextPage}
					currentPage={data.searchPointsRedeemItemsForStaff.currentPage}
					totalPages={data.searchPointsRedeemItemsForStaff.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any items.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
