import { Center, Container, Heading, HStack, Icon, Select, Spinner, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns"
import React, { useEffect, useState } from "react"
import DateTimePicker from "react-datetime-picker"
import { DashboardHeader, Nav, StudentAttendancesTable } from "../../components"
import { GRADIENT } from "../../constants"
import { ClassSectionFragment, ExtendedClassFragment, useAttendanceStatsQuery, useClassesQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const StudentsOut: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [{ data: classesData, fetching: fetchingClasses, error: classesError }] = useClassesQuery()

	const [schoolClass, setSchoolClass] = useState<ExtendedClassFragment | undefined>()

	const [classSection, setClassSection] = useState<ClassSectionFragment>()

	const [date, setDate] = useState(new Date(format(new Date(), "MM/dd/yyyy")))

	useEffect(() => {
		setClassSection(undefined)
	}, [schoolClass])

	const [{ data, fetching, error }] = useAttendanceStatsQuery({
		variables: { date, classId: schoolClass?._id, classSectionId: classSection?._id },
	})

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Students Out
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								Showing data for <strong>{schoolClass?.name || "all"}</strong> class{schoolClass ? "" : "es"}
							</Heading>
						</VStack>
						{fetchingClasses ? (
							<Center>
								<VStack w="full" color="blackAlpha.800">
									<Text>Loading classes</Text>
									<Spinner size="sm" />
								</VStack>
							</Center>
						) : classesError ? (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
									Failed loading classes, try reloading the page
								</Text>
							</Center>
						) : classesData?.classes.length ? (
							<HStack alignSelf="flex-end" spacing={0}>
								<Stack w="full" mr="5" pr="5">
									<DateTimePicker onChange={setDate} value={date} format="MMM dd, y" maxDate={new Date()} clearIcon={null} />
								</Stack>
								<Select
									bg="white.500"
									roundedStart="xl"
									roundedEnd={schoolClass && schoolClass.sections?.length ? "none" : "xl"}
									border="none"
									_focus={{ border: "none" }}
									w="full"
									maxW="xs"
									placeholder="All"
									value={JSON.stringify(schoolClass)}
									onChange={(e) => {
										setSchoolClass(e.target.value ? JSON.parse(e.target.value) : undefined)
									}}
								>
									{classesData?.classes.map((c) => (
										<option key={c._id} value={JSON.stringify(c)}>
											{c.name}
										</option>
									))}
								</Select>
								{schoolClass && schoolClass.sections?.length && (
									<Select
										bg="white.500"
										roundedStart="none"
										roundedEnd="xl"
										border="none"
										_focus={{ border: "none" }}
										w="full"
										maxW="xs"
										placeholder="All"
										value={JSON.stringify(classSection)}
										onChange={(e) => {
											setClassSection(e.target.value ? JSON.parse(e.target.value) : undefined)
										}}
									>
										{schoolClass.sections.map((s) => (
											<option key={s.name} value={JSON.stringify(s)}>
												{s.name}
											</option>
										))}
									</Select>
								)}
							</HStack>
						) : (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any classes.
								</Text>
							</Center>
						)}
					</Stack>
					{fetching ? (
						<VStack flex={8} w="full" maxW="2xl">
							<Spinner />
							<Text textAlign="center">Loading today&apos;s attendance</Text>
						</VStack>
					) : error ? (
						<VStack flex={8} w="full" maxW="2xl" color="red.800">
							<Icon as={(props: any) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />} />
							<Text textAlign="center">{error?.message.replace("[GraphQL] ", "")}</Text>
						</VStack>
					) : data?.attendanceStats.outStudents.length ? (
						<StudentAttendancesTable attendances={data.attendanceStats.outStudents} />
					) : (
						<VStack flex={8} w="full" maxW="2xl" color="red.800">
							<Icon as={(props: any) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />} />
							<Text textAlign="center">No present students found</Text>
						</VStack>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
