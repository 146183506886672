import { Button, Container, Grid, Heading, HStack, Icon, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import { faHandHoldingDollar, faPersonCircleExclamation, faReceipt, faShapes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { NavLink } from "react-router-dom"
import { Card, DashboardHeader, Nav, TopDepartments, TopHouses, TopStaffs, TopStudents } from "../../components"
import { GRADIENT } from "../../constants"
import { useDrawer } from "../../hooks"

export const Points: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					<VStack align="flex-start">
						<Heading as="h1" fontSize="xl" color="text.500">
							Points
						</Heading>
						<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.400" maxW="xs">
							{
								// TODO: add sub heading
							}
						</Heading>
					</VStack>
					<Stack w="full" direction={{ base: "column", md: "row" }} align="stretch" spacing={4}>
						<VStack>
							<Stack w="full" direction={{ base: "column", md: "row" }} align="stretch" spacing={4}>
								<Card w="full" bgColor="green.100">
									<VStack w="full" align="stretch" spacing={4}>
										<VStack>
											<Icon
												color="green.600"
												fontSize="4xl"
												as={(props: any) => <FontAwesomeIcon icon={faHandHoldingDollar} {...props} />}
											/>
											<Text color="green.600" fontSize="sm" fontWeight="semibold">
												Reward
											</Text>
										</VStack>
										<HStack w="full" justify="space-between">
											<VStack w="full" align="flex-start" spacing="0">
												<Button
													w="full"
													as={NavLink}
													to="/points/reward/add/students"
													colorScheme="green"
													size="sm"
													variant="ghost"
													justifyContent="space-between"
												>
													Students
												</Button>
												<Button
													w="full"
													as={NavLink}
													to="/points/reward/add/houses"
													colorScheme="green"
													size="sm"
													variant="ghost"
													justifyContent="space-between"
												>
													Houses
												</Button>
											</VStack>
											<VStack w="full" align="flex-start" spacing="0">
												<Button
													w="full"
													as={NavLink}
													to="/points/reward/add/staffs"
													colorScheme="green"
													size="sm"
													variant="ghost"
													justifyContent="space-between"
												>
													Staff
												</Button>
												<Button
													w="full"
													as={NavLink}
													to="/points/reward/add/departments"
													colorScheme="green"
													size="sm"
													variant="ghost"
													justifyContent="space-between"
												>
													Departments
												</Button>
											</VStack>
										</HStack>
									</VStack>
								</Card>

								<Card w="full" bgColor="green.100">
									<VStack w="full" align="stretch" spacing={4}>
										<VStack>
											<Icon color="green.600" fontSize="4xl" as={(props: any) => <FontAwesomeIcon icon={faShapes} {...props} />} />
											<Text color="green.600" fontSize="sm" fontWeight="semibold">
												Reward Categories
											</Text>
										</VStack>
										<HStack w="full" justify="space-between" spacing={8}>
											<Button
												w="full"
												as={NavLink}
												to="/points/reward/categories/students"
												colorScheme="green"
												size="sm"
												variant="ghost"
												justifyContent="space-between"
											>
												Students
											</Button>
											<Button
												w="full"
												as={NavLink}
												to="/points/reward/categories/staffs"
												colorScheme="green"
												size="sm"
												variant="ghost"
												justifyContent="space-between"
											>
												Staff
											</Button>
										</HStack>
									</VStack>
								</Card>
							</Stack>
							<Stack w="full" direction={{ base: "column", md: "row" }} align="stretch" spacing={4}>
								<Card w="full" bgColor="red.100">
									<VStack w="full" align="stretch" spacing={4}>
										<VStack>
											<Icon
												color="red.600"
												fontSize="4xl"
												as={(props: any) => <FontAwesomeIcon icon={faPersonCircleExclamation} {...props} />}
											/>
											<Text color="red.600" fontSize="sm" fontWeight="semibold">
												Penalty
											</Text>
										</VStack>
										<HStack w="full" justify="space-between" spacing={8}>
											<VStack w="full" align="flex-start" spacing="0">
												<Button
													w="full"
													as={NavLink}
													to="/points/penalty/add/students"
													colorScheme="red"
													size="sm"
													variant="ghost"
													justifyContent="space-between"
												>
													Students
												</Button>
												<Button
													w="full"
													as={NavLink}
													to="/points/penalty/add/houses"
													colorScheme="red"
													size="sm"
													variant="ghost"
													justifyContent="space-between"
												>
													Houses
												</Button>
											</VStack>
											<VStack w="full" align="flex-start" spacing="0">
												<Button
													w="full"
													as={NavLink}
													to="/points/penalty/add/staffs"
													colorScheme="red"
													size="sm"
													variant="ghost"
													justifyContent="space-between"
												>
													Staff
												</Button>
												<Button
													w="full"
													as={NavLink}
													to="/points/penalty/add/departments"
													colorScheme="red"
													size="sm"
													variant="ghost"
													justifyContent="space-between"
												>
													Departments
												</Button>
											</VStack>
										</HStack>
									</VStack>
								</Card>

								<Card w="full" bgColor="red.100">
									<VStack w="full" align="stretch" spacing={4}>
										<VStack>
											<Icon color="red.600" fontSize="4xl" as={(props: any) => <FontAwesomeIcon icon={faShapes} {...props} />} />
											<Text color="red.600" fontSize="sm" fontWeight="semibold">
												Penalty Categories
											</Text>
										</VStack>
										<HStack w="full" justify="space-between" spacing={8}>
											<Button
												w="full"
												as={NavLink}
												to="/points/penalty/categories/students"
												colorScheme="red"
												size="sm"
												variant="ghost"
												justifyContent="space-between"
											>
												Students
											</Button>
											<Button
												w="full"
												as={NavLink}
												to="/points/penalty/categories/staffs"
												colorScheme="red"
												size="sm"
												variant="ghost"
												justifyContent="space-between"
											>
												Staff
											</Button>
										</HStack>
									</VStack>
								</Card>
							</Stack>
						</VStack>

						<Card w="full" bgColor="purple.100" alignSelf="top">
							<VStack w="full" align="stretch" spacing={4}>
								<VStack>
									<Icon color="purple.600" fontSize="4xl" as={(props: any) => <FontAwesomeIcon icon={faReceipt} {...props} />} />
									<Text color="purple.600" fontSize="sm" fontWeight="semibold">
										Redeem
									</Text>
								</VStack>

								<Button
									w="full"
									as={NavLink}
									to="/points/redeem/items/students"
									colorScheme="purple"
									size="sm"
									variant="ghost"
									justifyContent="space-between"
								>
									Items for students
								</Button>

								<Button
									w="full"
									as={NavLink}
									to="/points/redeem/items/staffs"
									colorScheme="purple"
									size="sm"
									variant="ghost"
									justifyContent="space-between"
								>
									Items for staff
								</Button>
								<Button
									w="full"
									as={NavLink}
									to="/points/redeem/transactions"
									colorScheme="purple"
									size="sm"
									variant="ghost"
									justifyContent="space-between"
								>
									Transactions
								</Button>
							</VStack>
						</Card>
					</Stack>
					<Grid w="full" templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} mt="12 !important">
						<TopStudents />
						<TopHouses />
						<TopStaffs />
						<TopDepartments />
					</Grid>
				</VStack>
			</HStack>
		</Container>
	)
}
