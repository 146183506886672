import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useRewardsOfStaffQuery } from "../../graphql"
import { StaffRewardListItem } from "./StaffRewardListItem"

export type StaffRewardsListProps = {
	staffId: string
}

export const StaffRewardsList: React.FC<StaffRewardsListProps> = ({ staffId }) => {
	const [{ data, fetching, error }] = useRewardsOfStaffQuery({ variables: { staffId } })

	return (
		<VStack w="full" align="stretch">
			{fetching ? (
				<Center w="full" py="4">
					<Spinner color="text.400" />
				</Center>
			) : error ? (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="text.400">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.rewardsOfStaff.length ? (
				<VStack spacing={4}>
					{data.rewardsOfStaff.map((reward) => (
						<StaffRewardListItem key={reward._id} reward={reward} />
					))}
				</VStack>
			) : (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="text.400">
						Couldn&apos;t find any rewards.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
