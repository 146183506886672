/* eslint-disable @typescript-eslint/indent */
import { Grid, Heading, Img, LinkBox, LinkOverlay, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { GalleryFragment } from "../../graphql"
import { Navigation, Pagination, Scrollbar } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"
import "./styles.css"

export type GalleryProps = {
	gallery: GalleryFragment
}

export const Gallery: React.FC<GalleryProps> = ({ gallery }) => {
	return (
		<>
			<VStack w="full" maxW="lg" align="flex-start" shadow="sm" rounded="xl">
				<VStack w="full" align="stretch" spacing={0} bg="whiteAlpha.600" rounded="xl" p={3}>
					<VStack align="flex-start" pb={3} as={LinkBox} rounded="xl">
						<LinkOverlay as={NavLink} to={`/gallery/${gallery._id}`} />
						{gallery.title && (
							<Heading fontSize="x-large" fontWeight="medium" whiteSpace="pre-line">
								{gallery.title}
							</Heading>
						)}
					</VStack>
					{gallery.attachments?.videos?.length ? (
						<Grid>
							<Swiper
								pagination={{
									type: "progressbar",
									clickable: true,
								}}
								navigation={true}
								modules={[Pagination, Navigation, Scrollbar]}
								className="mySwiper"
								keyboard={{
									enabled: true,
								}}
							>
								{gallery.attachments.videos.map((video, index) => (
									<SwiperSlide key={index}>
										<video key={index} style={{ borderRadius: "12px" }} src={video.url} controls />
									</SwiperSlide>
								))}
							</Swiper>
						</Grid>
					) : (
						gallery.attachments?.pictures?.length && (
							<Grid>
								<Swiper
									pagination={{
										type: "progressbar",
										clickable: true,
									}}
									navigation={true}
									modules={[Pagination, Navigation, Scrollbar]}
									className="mySwiper"
									keyboard={{
										enabled: true,
									}}
								>
									{gallery?.attachments.pictures.map((picture, index) => (
										<SwiperSlide key={index}>
											<Img key={index} src={picture.url} />
										</SwiperSlide>
									))}
								</Swiper>
							</Grid>
						)
					)}
				</VStack>
			</VStack>
		</>
	)
}
