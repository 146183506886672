import { SettingsIcon } from "@chakra-ui/icons"
import { Button, Container, HStack, Link, Popover, PopoverArrow, PopoverContent, PopoverTrigger, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { AllFeedPosts, CreateFeedPost, DashboardHeader, Nav } from "../../components"
import { GRADIENT } from "../../constants"
import { useDrawer } from "../../hooks"

export const Feed: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const navigate = useNavigate()

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0" overflow="hidden">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 0}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<VStack w="full" maxW="lg" align="flex-start" spacing={4}>
						<HStack w="full" maxW="lg" rounded="xl" py={2} px={4} spacing={4} justify="space-between">
							<Text fontSize="sm">
								See unapproved{" "}
								<Link as={NavLink} to="/feed/unApproved" fontWeight="semibold" color="purple" textDecor="underline">
									posts
								</Link>
							</Text>
							<Popover>
								<PopoverTrigger>
									<Button size="sm" leftIcon={<SettingsIcon />} bgColor="transparent" variant="solid">
										Settings
									</Button>
								</PopoverTrigger>
								<PopoverContent maxW="48" bg="white" shadow="2xl" _focus={{ shadow: "2xl" }}>
									<PopoverArrow />
									<Button colorScheme="primary" size="sm" rounded="xl" onClick={() => navigate("/whitelist")}>
										Whitelist
									</Button>
								</PopoverContent>
							</Popover>
						</HStack>
						<CreateFeedPost />
						<AllFeedPosts />
					</VStack>
				</VStack>
			</HStack>
		</Container>
	)
}
