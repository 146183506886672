import { Button, Heading, Icon, IconButton, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, useToast, VStack } from "@chakra-ui/react"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import DateTimePicker from "react-datetime-picker"
import { useMarkStaffManualInMutation } from "../../graphql"

export type MarkStaffManualInProps = {
	staffId: string
}

export const MarkStaffManualIn: React.FC<MarkStaffManualInProps> = ({ staffId }) => {
	const [date, setDate] = useState(new Date())

	const [{ fetching }, markStaffManualIn] = useMarkStaffManualInMutation()

	const toast = useToast()

	const handleSave = async () => {
		const { error } = await markStaffManualIn({ staffId, inAt: date })

		if (error) {
			return toast({ description: error.message.replace("[GraphQL ]", ""), status: "error" })
		}

		toast({ description: "Marked manual entry", status: "success" })
	}

	return (
		<Popover isLazy lazyBehavior="unmount">
			<PopoverTrigger>
				<IconButton aria-label="mark-entry-manually-btn" variant="ghost" colorScheme="purple" size="sm">
					<Icon as={(props: any) => <FontAwesomeIcon icon={faEdit} {...props} />} />
				</IconButton>
			</PopoverTrigger>
			<PopoverContent bgColor="white">
				<PopoverArrow bgColor="white" />
				<PopoverBody>
					<VStack w="full" align="stretch" spacing={6}>
						<Heading fontSize="sm">Mark manual entry</Heading>
						<DateTimePicker disableClock onChange={setDate} value={date} />

						<Button colorScheme="purple" size="sm" isLoading={fetching} onClick={handleSave}>
							Save
						</Button>
					</VStack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
