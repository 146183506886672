import { Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import React from "react"
import { X } from "react-feather"
import { useRemoveUnauthorizedDepartmentMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type RemoveUnauthorizedDepartmentButtonProps = {
	restrictedZoneId: string
	departmentId: string
}

export const RemoveUnauthorizedDepartmentButton: React.FC<RemoveUnauthorizedDepartmentButtonProps> = ({ restrictedZoneId, departmentId }) => {
	const [{ fetching }, removeDepartment] = useRemoveUnauthorizedDepartmentMutation()

	const toast = useToast()

	const handleRemoveDepartment = async () => {
		const { error } = await removeDepartment({ restrictedZoneId, departmentId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		onClose()
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<Tooltip label="Remove unauthorized department" fontSize="sm">
				<IconButton aria-label="remove-department" position="absolute" top="-1" right="-1" colorScheme="red" size="xs" zIndex={1} {...getButtonProps()}>
					<Icon as={X} />
				</IconButton>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Remove Department"
				message="Are you sure you want to remove this department from unauthorized departments?"
				confirmLabel="Remove"
				onConfirm={handleRemoveDepartment}
				isLoading={fetching}
			/>
		</>
	)
}
