import { Center, Spinner } from "@chakra-ui/react"
import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { useMeQuery } from "../graphql/generated"
import {
	Attendance,
	BusRoutes,
	ChangePassword,
	Classes,
	Dashboard,
	Departments,
	Feed,
	Gallery,
	GenerateCards,
	Holidays,
	Inventory,
	Points,
	Resources,
	RestrictedZones,
	Showcases,
	Students,
	Topics,
	Trainers,
	TrendingSkills,
	Workshops,
} from "../pages"
import { Absent } from "../pages/Attendance/Absent"
import { Present } from "../pages/Attendance/Present"
import { EditBusRoute } from "../pages/BusRoute/EditBusRoute"
import { NewBusRoute } from "../pages/BusRoute/NewBusRoute"
import { NewClassSection } from "../pages/Classes/AddClassSection"
import { AddClassTeacher } from "../pages/Classes/AddClassTeacher"
import { Class } from "../pages/Classes/Class"
import { NewClass } from "../pages/Classes/NewClass"
import { Department } from "../pages/Department/Department"
import { NewDepartment } from "../pages/Department/NewDepartment"
import { UpdateAttendanceTimingForDepartment } from "../pages/Department/UpdateAttendanceTimingForDepartment"
import { FeedPostPage } from "../pages/Feed/FeedPost"
import { UnApprovedFeedPost } from "../pages/Feed/UnApprovedFeedPost"
import { AddGalleryAttachments } from "../pages/Gallery/addGalleryAttachments"
import { DeleteGalleryAttachments } from "../pages/Gallery/deleteGalleryAttachments"
import { EditGallery } from "../pages/Gallery/EditGallery"
import { GalleryById } from "../pages/Gallery/Gallery"
import { NewGallery } from "../pages/Gallery/NewGallery"
import { Houses } from "../pages/House"
import { AddHouseCaptain } from "../pages/House/AddHouseCaptain"
import { EditHouse } from "../pages/House/EditHouse"
import { House } from "../pages/House/House"
import { NewHouse } from "../pages/House/NewHouse"
import { Readers } from "../pages/Inventory/Readers"
import { Tags } from "../pages/Inventory/Tags"
import { LandingPage } from "../pages/Landing"
import { Reports } from "../pages/Reports"
import { StaffAbsentsAttendanceReport } from "../pages/Reports/staff/attendance/Absents"
import { StaffCompleteAttendanceReport } from "../pages/Reports/staff/attendance/Complete"
import { StaffLateComersAttendanceReport } from "../pages/Reports/staff/attendance/LateComers"
import { StaffRestrictedEntriesReport } from "../pages/Reports/staff/RestrictedEntries"
import { StudentAbsentsAttendanceReport } from "../pages/Reports/students/attendance/Absents"
import { StudentCompleteAttendanceReport } from "../pages/Reports/students/attendance/Complete"
import { StudentLateComersAttendanceReport } from "../pages/Reports/students/attendance/LateComers"
import { AddResourceAttachments } from "../pages/Resource/addResourceAttachments"
import { DeleteResourceAttachments } from "../pages/Resource/deleteResourceAttachments"
import { EditResource } from "../pages/Resource/EditResource"
import { NewResource } from "../pages/Resource/NewResource"
import { ResourcePage } from "../pages/Resource/Resource"
import { AddUnauthorizedClass } from "../pages/RestrictedZones/AddUnauthorizedClass"
import { AddUnauthorizedDepartments } from "../pages/RestrictedZones/AddUnauthorizedDepartments"
import { EditRestrictedZone } from "../pages/RestrictedZones/EditRestrictedZone"
import { NewRestrictedZone } from "../pages/RestrictedZones/NewRestrictedZone"
import { RestrictedEntries } from "../pages/RestrictedZones/RestrictedEntries"
import { RestrictedZone } from "../pages/RestrictedZones/RestrictedZone"
import { AddShowcaseAttachments } from "../pages/Showcase/AddShowcaseAttachments"
import { AddShowcaseAuthor } from "../pages/Showcase/AddShowcaseAuthor"
import { DeleteShowcaseAttachments } from "../pages/Showcase/DeleteShowcaseAttachments"
import { EditShowcase } from "../pages/Showcase/EditShowcase"
import { NewShowcase } from "../pages/Showcase/NewShowcase"
import { Showcase } from "../pages/Showcase/Showcase"
import { StaffAttendance } from "../pages/StaffAttendance"
import { StaffAbsent } from "../pages/StaffAttendance/Absent"
import { StaffPresent } from "../pages/StaffAttendance/Present"
import { Staffs } from "../pages/Staffs"
import { EditStaff } from "../pages/Staffs/EditStaff"
import { NewStaff } from "../pages/Staffs/NewStaff"
import { Staff } from "../pages/Staffs/Staff"
import { EditStudent } from "../pages/Students/EditStudent"
import { NewStudent } from "../pages/Students/NewStudent"
import { Student } from "../pages/Students/Student"
import { Subjects } from "../pages/Subjects"
import { NewSubject } from "../pages/Subjects/NewSubject"
import { Subject } from "../pages/Subjects/Subject"
import { NewTopic } from "../pages/Topics/NewTopic"
import { Topic } from "../pages/Topics/Topic"
import { NewTrainer } from "../pages/Trainer/NewTrainer"
import { Trainer } from "../pages/Trainer/Trainer"
import { EditTrendingSkill } from "../pages/TrendingSkill/EditTrendingSkill"
import { NewTrendingSkill } from "../pages/TrendingSkill/NewTrendingSkill"
import { TrendingSkill } from "../pages/TrendingSkill/TrendingSkill"
import { Uaoth } from "../pages/Uaoth"
import { Whitelist } from "../pages/Whitelist"
import { WhitelistedStaff } from "../pages/Whitelist/WhitelistedStaff"
import { WhiteListedStudent } from "../pages/Whitelist/WhitelistedStudent"
import { WhitelistStaff } from "../pages/Whitelist/WhitelistStaff"
import { WhiteListStudent } from "../pages/Whitelist/WhitelistStudent"
import { AddWorkshopSessionTrainer } from "../pages/Workshop/AddWorkshopSessionTrainer"
import { EditWorkshop } from "../pages/Workshop/EditWorkshop"
import { NewWorkshop } from "../pages/Workshop/NewWorkshop"
import { NewWorkshopSession } from "../pages/Workshop/NewWorkshopSession"
import { Workshop } from "../pages/Workshop/Workshop"
import { WorkshopSession } from "../pages/Workshop/WorkshopSession"

import { AddPenaltyToDepartments } from "../pages/Points/Penalty/AddPenaltyToDepartments"
import { AddPenaltyToHouses } from "../pages/Points/Penalty/AddPenaltyToHouses"
import { AddPenaltyToStaff } from "../pages/Points/Penalty/AddPenaltyToStaff"
import { AddPenaltyToStudents } from "../pages/Points/Penalty/AddPenaltyToStudents"
import { EditPenaltyCategory } from "../pages/Points/Penalty/categories/EditPenaltyCategory"
import { EditPenaltyItem } from "../pages/Points/Penalty/categories/items/EditPenaltyItem"
import { NewPenaltyItem } from "../pages/Points/Penalty/categories/items/NewPenaltyItem"
import { NewPenaltyCategory } from "../pages/Points/Penalty/categories/NewPenaltyCategory"
import { Penalty } from "../pages/Points/Penalty/Penalty"
import { AddRewardsToDepartments } from "../pages/Points/Reward/AddRewardToDepartments"
import { AddRewardsToHouses } from "../pages/Points/Reward/AddRewardToHouses"
import { AddRewardsToStaff } from "../pages/Points/Reward/AddRewardToStaff"
import { AddRewardsToStudents } from "../pages/Points/Reward/AddRewardToStudents"
import { EditRewardCategory } from "../pages/Points/Reward/categories/EditRewardCategory"
import { EditRewardItem } from "../pages/Points/Reward/categories/items/EditRewardItem"
import { NewRewardItem } from "../pages/Points/Reward/categories/items/NewRewardItem"
import { NewRewardCategory } from "../pages/Points/Reward/categories/NewRewardCategory"
import { RewardCategoriesForStaff } from "../pages/Points/Reward/categories/RewardCategoriesForStaff"
import { RewardCategoriesForStudents } from "../pages/Points/Reward/categories/RewardCategoriesForStudents"
import { Reward } from "../pages/Points/Reward/Reward"

import { StudentsIn } from "../pages/Attendance/StudentsIn"
import { StudentsOut } from "../pages/Attendance/StudentsOut"
import { Cafeteria } from "../pages/Cafeteria"
import { UpdateCafeteriaMessages } from "../pages/Cafeteria/Messages"
import { CafeteriaMealTypes } from "../pages/Cafeteria/MealTypes"
import { CafeteriaMealType } from "../pages/Cafeteria/MealTypes/CafeteriaMealType"
import { EditCafeteriaMealType } from "../pages/Cafeteria/MealTypes/EditCafeteriaMealType"
import { NewCafeteriaMealType } from "../pages/Cafeteria/MealTypes/NewCafeteriaMealType"
import { CafeteriaRegistrationCharges } from "../pages/Cafeteria/RegistrationCharges"
import { CafeteriaRegistrationCharge } from "../pages/Cafeteria/RegistrationCharges/CafeteriaRegistrationCharge"
import { EditCafeteriaRegistrationCharge } from "../pages/Cafeteria/RegistrationCharges/EditCafeteriaRegistrationCharge"
import { NewCafeteriaRegistrationCharge } from "../pages/Cafeteria/RegistrationCharges/NewCafeteriaRegistrationCharge"
import { CafeteriaRegistrations } from "../pages/Cafeteria/Registrations"
import { CafeteriaRegistration } from "../pages/Cafeteria/Registrations/CafeteriaRegistration"
import { CafeteriaRegistrationsReports } from "../pages/Cafeteria/Reports/Registrations"
import { CafeteriaRegistrationsReport } from "../pages/Cafeteria/Reports/Registrations/CafeteriaRegistrationsReport"
import { GenerateCafeteriaRegistrationsReport } from "../pages/Cafeteria/Reports/Registrations/GenerateCafeteriaRegistrationsReport"
import { CafeteriaTransactions } from "../pages/Cafeteria/Transactions"
import { AddSchoolTiming } from "../pages/Classes/AddClassSchoolTiming"
import { DeactivatedStudents } from "../pages/DeactivatedStudents"
import { UpdateWorkingWeekdaysOfDepartment } from "../pages/Department/UpdateWorkingWeekdaysForDepartment"
import { GeneratedCard } from "../pages/GenerateCards/GeneratedCard"
import { GeneratedCards } from "../pages/GenerateCards/GeneratedCards"
import { GenerateStaffsCard } from "../pages/GenerateCards/staff/GenerateStaffsCard"
import { GenerateStudentsCard } from "../pages/GenerateCards/students/GenerateStudentsCard"
import { Paytank } from "../pages/Paytank"
import { PaytankMerchants } from "../pages/Paytank/Merchants"
import { EditPaytankMerchant } from "../pages/Paytank/Merchants/EditPaytankMerchant"
import { NewPaytankMerchant } from "../pages/Paytank/Merchants/NewPaytankMerchant"
import { PaytankMerchant } from "../pages/Paytank/Merchants/PaytankMerchant"
import { PaytankMerchantTypes } from "../pages/Paytank/MerchantTypes"
import { EditPaytankMerchantType } from "../pages/Paytank/MerchantTypes/EditPaytankMerchantType"
import { NewPaytankMerchantType } from "../pages/Paytank/MerchantTypes/NewPaytankMerchantType"
import { PaytankMerchantType } from "../pages/Paytank/MerchantTypes/PaytankMerchantType"
import { PaytankOrders } from "../pages/Paytank/Orders"
import { PenaltyCategoriesForStaff } from "../pages/Points/Penalty/categories/PenaltyCategoriesForStaff"
import { PenaltyCategoriesForStudents } from "../pages/Points/Penalty/categories/PenaltyCategoriesForStudents"
import { EditPointsRedeemItem } from "../pages/Points/Redeem/Items/EditPointsRedeemItem"
import { NewPointsRedeemItem } from "../pages/Points/Redeem/Items/NewPointsRedeemItem"
import { PointsRedeemItemsForStaff } from "../pages/Points/Redeem/Items/PointsRedeemItemsForStaff"
import { PointsRedeemItemsForStudents } from "../pages/Points/Redeem/Items/PointsRedeemItemsForStudents"
import { PointsRedeemTransactions } from "../pages/Points/Redeem/Transactions"
import { PaytankTransactionsReport } from "../pages/Reports/paytank/Transactions"
import { StudentAttendanceClassWiseStatsReport } from "../pages/Reports/students/attendance/ClassWiseStats"
import { StudentPresentsAttendanceReport } from "../pages/Reports/students/attendance/Present"
import { StudentTelegramMessagesReport } from "../pages/Reports/students/telegramMessage/StudentTelegramMessages"
import { SchoolSessions } from "../pages/SchoolSessions"
import { EditSchoolSession } from "../pages/SchoolSessions/EditSchoolSession"
import { NewSchoolSession } from "../pages/SchoolSessions/NewSchoolSession"
import { SchoolSession } from "../pages/SchoolSessions/SchoolSession"
import { SchoolTimings } from "../pages/SchoolTiming"
import { NewSchoolTiming } from "../pages/SchoolTiming/NewSchoolTiming"
import { SchoolTiming } from "../pages/SchoolTiming/SchoolTiming"
import { UpdateSchoolTiming } from "../pages/SchoolTiming/UpdateSchoolTiming"
import { CafeteriaPaymentsReports } from "../pages/Cafeteria/Reports/Payments"
import { CafeteriaPaymentsReport } from "../pages/Cafeteria/Reports/Payments/CafeteriaPaymentsReport"
import { GenerateCafeteriaPaymentsReport } from "../pages/Cafeteria/Reports/Payments/GenerateCafeteriaPaymentsReport"
import { UpdateManualCafeteriaRegistration } from "../pages/Cafeteria/Registrations/UpdateManualCafeteriaRegistration"

export const RootRouter: React.FC = () => {
	const [{ data, fetching }] = useMeQuery()

	if (fetching) {
		return (
			<Center w="full" h="100vh">
				<Spinner />
			</Center>
		)
	}

	if (!data?.me) {
		return (
			<Routes>
				<Route path="/" element={<LandingPage />} />
				<Route path="/uaoth" element={<Uaoth />} />
				<Route path="*" element={<Navigate to="/" replace />} />
			</Routes>
		)
	}

	return (
		<Routes>
			<Route path="/" element={<Navigate to="/attendance" replace />} />
			<Route path="/attendance/staff/present" element={<StaffPresent />} />
			<Route path="/attendance/staff/absent" element={<StaffAbsent />} />
			<Route path="/attendance/staff" element={<StaffAttendance />} />
			<Route path="/attendance/students/out" element={<StudentsOut />} />
			<Route path="/attendance/students/in" element={<StudentsIn />} />
			<Route path="/attendance/students/present" element={<Present />} />
			<Route path="/attendance/students/absent" element={<Absent />} />
			<Route path="/attendance/students" element={<Attendance />} />
			<Route path="/attendance" element={<Navigate to="/attendance/students" replace />} />
			<Route path="/feed/:feedPostId" element={<FeedPostPage />} />
			<Route path="/feed/unApproved" element={<UnApprovedFeedPost />} />
			<Route path="/feed" element={<Feed />} />
			<Route path="/dashboard" element={<Dashboard />} />
			<Route path="/classes/:classId/:classSectionId/addClassTeacher" element={<AddClassTeacher />} />
			<Route path="/classes/:classId/addClassSection" element={<NewClassSection />} />
			<Route path="/classes/:classId" element={<Class />} />
			<Route path="/classes/new" element={<NewClass />} />
			<Route path="/classes" element={<Classes />} />
			<Route path="/subjects/:subjectId" element={<Subject />} />
			<Route path="/subjects/new" element={<NewSubject />} />
			<Route path="/subjects" element={<Subjects />} />
			<Route path="/staff/:staffId/edit" element={<EditStaff />} />
			<Route path="/staff/:staffId" element={<Staff />} />
			<Route path="/staff/new" element={<NewStaff />} />
			<Route path="/staff" element={<Staffs />} />
			<Route path="/students/:studentId/edit" element={<EditStudent />} />
			<Route path="/students/:studentId" element={<Student />} />
			<Route path="/students/new" element={<NewStudent />} />
			<Route path="/students" element={<Students />} />
			<Route path="/deactivatedStudents" element={<DeactivatedStudents />} />
			<Route path="/reports/staffRestrictedEntries" element={<StaffRestrictedEntriesReport />} />
			<Route path="/reports/staffAttendances/complete" element={<StaffCompleteAttendanceReport />} />
			<Route path="/reports/staffAttendances/lateComers" element={<StaffLateComersAttendanceReport />} />
			<Route path="/reports/staffAttendances/absents" element={<StaffAbsentsAttendanceReport />} />
			<Route path="/reports/studentTelegramMessages" element={<StudentTelegramMessagesReport />} />
			<Route path="/reports/studentAttendances/complete" element={<StudentCompleteAttendanceReport />} />
			<Route path="/reports/studentAttendances/lateComers" element={<StudentLateComersAttendanceReport />} />
			<Route path="/reports/studentAttendances/absents" element={<StudentAbsentsAttendanceReport />} />
			<Route path="/reports/studentAttendances/presents" element={<StudentPresentsAttendanceReport />} />
			<Route path="/reports/studentAttendances/classWiseStats" element={<StudentAttendanceClassWiseStatsReport />} />
			<Route path="/reports/paytankTransactions" element={<PaytankTransactionsReport />} />
			<Route path="/reports" element={<Reports />} />
			<Route path="/departments/:departmentId/updateWorkingWeekdays" element={<UpdateWorkingWeekdaysOfDepartment />} />
			<Route path="/departments/:departmentId/updateAttendanceTiming" element={<UpdateAttendanceTimingForDepartment />} />
			<Route path="/departments/:departmentId" element={<Department />} />
			<Route path="/departments/new" element={<NewDepartment />} />
			<Route path="/departments" element={<Departments />} />
			<Route path="/changePassword" element={<ChangePassword />} />
			<Route path="/inventory/tags" element={<Tags />} />
			<Route path="/inventory/readers" element={<Readers />} />
			<Route path="/inventory" element={<Inventory />} />
			<Route path="/restrictedZones/:restrictedZoneId/edit" element={<EditRestrictedZone />} />
			<Route path="/restrictedZones/:restrictedZoneId/addUnauthorizedDepartments" element={<AddUnauthorizedDepartments />} />
			<Route path="/restrictedZones/:restrictedZoneId/addUnauthorizedClass" element={<AddUnauthorizedClass />} />
			<Route path="/restrictedZones/:restrictedZoneId" element={<RestrictedZone />} />
			<Route path="/restrictedZones/new" element={<NewRestrictedZone />} />
			<Route path="/restrictedZones" element={<RestrictedZones />} />
			<Route path="/restrictedEntries" element={<RestrictedEntries />} />
			<Route path="/showcases/:showcaseId/addAuthor" element={<AddShowcaseAuthor />} />
			<Route path="/showcases/:showcaseId/deleteAttachments" element={<DeleteShowcaseAttachments />} />
			<Route path="/showcases/:showcaseId/addAttachments" element={<AddShowcaseAttachments />} />
			<Route path="/showcases/:showcaseId/edit" element={<EditShowcase />} />
			<Route path="/showcases/:showcaseId" element={<Showcase />} />
			<Route path="/showcases/new" element={<NewShowcase />} />
			<Route path="/showcases" element={<Showcases />} />
			<Route path="/houses/:houseId/addCaptain" element={<AddHouseCaptain />} />
			<Route path="/houses/:houseId/edit" element={<EditHouse />} />
			<Route path="/houses/:houseId" element={<House />} />
			<Route path="/houses/new" element={<NewHouse />} />
			<Route path="/houses" element={<Houses />} />
			<Route path="/trainers/:trainerId" element={<Trainer />} />
			<Route path="/trainers/new" element={<NewTrainer />} />
			<Route path="/trainers" element={<Trainers />} />
			<Route path="/workshops/:workshopId/sessions/:workshopSessionId/addTrainer" element={<AddWorkshopSessionTrainer />} />
			<Route path="/workshops/:workshopId/sessions/:workshopSessionId" element={<WorkshopSession />} />
			<Route path="/workshops/:workshopId/addSession" element={<NewWorkshopSession />} />
			<Route path="/workshops/:workshopId/edit" element={<EditWorkshop />} />
			<Route path="/workshops/:workshopId" element={<Workshop />} />
			<Route path="/workshops/new" element={<NewWorkshop />} />
			<Route path="/workshops" element={<Workshops />} />
			<Route path="/resources/:resourceId/deleteAttachments" element={<DeleteResourceAttachments />} />
			<Route path="/resources/:resourceId/addAttachments" element={<AddResourceAttachments />} />
			<Route path="/resources/:resourceId/edit" element={<EditResource />} />
			<Route path="/resources/:resourceId" element={<ResourcePage />} />
			<Route path="/resources/new" element={<NewResource />} />
			<Route path="/resources" element={<Resources />} />
			<Route path="/topics/:topicId" element={<Topic />} />
			<Route path="/topics/new" element={<NewTopic />} />
			<Route path="/topics" element={<Topics />} />
			<Route path="/points/reward/add/students" element={<AddRewardsToStudents />} />
			<Route path="/points/reward/add/staffs" element={<AddRewardsToStaff />} />
			<Route path="/points/reward/add/houses" element={<AddRewardsToHouses />} />
			<Route path="/points/reward/add/departments" element={<AddRewardsToDepartments />} />
			<Route path="/points/penalty/add/students" element={<AddPenaltyToStudents />} />
			<Route path="/points/penalty/add/staffs" element={<AddPenaltyToStaff />} />
			<Route path="/points/penalty/add/houses" element={<AddPenaltyToHouses />} />
			<Route path="/points/penalty/add/departments" element={<AddPenaltyToDepartments />} />
			<Route path="/points/reward" element={<Reward />} />
			<Route path="/points/penalty" element={<Penalty />} />
			<Route path="/points" element={<Points />} />
			<Route path="/points/reward/categories/:rewardCategoryId/items/:rewardItemId/edit" element={<EditRewardItem />} />
			<Route path="/points/reward/categories/:rewardCategoryId/items/new" element={<NewRewardItem />} />
			<Route path="/points/reward/categories/:rewardCategoryId/edit" element={<EditRewardCategory />} />
			<Route path="/points/reward/categories/:target/new" element={<NewRewardCategory />} />
			<Route path="/points/reward/categories/staffs" element={<RewardCategoriesForStaff />} />
			<Route path="/points/reward/categories/students" element={<RewardCategoriesForStudents />} />
			<Route path="/points/penalty/categories/:penaltyCategoryId/items/:penaltyItemId/edit" element={<EditPenaltyItem />} />
			<Route path="/points/penalty/categories/:penaltyCategoryId/items/new" element={<NewPenaltyItem />} />
			<Route path="/points/penalty/categories/:penaltyCategoryId/edit" element={<EditPenaltyCategory />} />
			<Route path="/points/penalty/categories/:target/new" element={<NewPenaltyCategory />} />
			<Route path="/points/penalty/categories/staffs" element={<PenaltyCategoriesForStaff />} />
			<Route path="/points/penalty/categories/students" element={<PenaltyCategoriesForStudents />} />

			<Route path="/points/redeem/items/:pointsRedeemItemId/edit" element={<EditPointsRedeemItem />} />
			<Route path="/points/redeem/items/:target/new" element={<NewPointsRedeemItem />} />
			<Route path="/points/redeem/items/staffs" element={<PointsRedeemItemsForStaff />} />
			<Route path="/points/redeem/items/students" element={<PointsRedeemItemsForStudents />} />

			<Route path="/points/redeem/transactions" element={<PointsRedeemTransactions />} />

			<Route path="/trendingSkills/:trendingSkillId/edit" element={<EditTrendingSkill />} />
			<Route path="/trendingSkills/:trendingSkillId" element={<TrendingSkill />} />
			<Route path="/trendingSkills/new" element={<NewTrendingSkill />} />
			<Route path="/trendingSkills" element={<TrendingSkills />} />
			<Route path="/calendar" element={<Holidays />} />
			<Route path="/gallery/:galleryId/deleteAttachments" element={<DeleteGalleryAttachments />} />
			<Route path="/gallery/:galleryId/addAttachments" element={<AddGalleryAttachments />} />
			<Route path="/gallery/:galleryId/edit" element={<EditGallery />} />
			<Route path="/gallery/:galleryId" element={<GalleryById />} />
			<Route path="/gallery/new" element={<NewGallery />} />
			<Route path="/gallery" element={<Gallery />} />
			<Route path="/whitelist/whitelistedStudent" element={<WhiteListedStudent />} />
			<Route path="/whitelist/whitelistedStaff" element={<WhitelistedStaff />} />
			<Route path="/whitelist/student" element={<WhiteListStudent />} />
			<Route path="/whitelist/staff" element={<WhitelistStaff />} />
			<Route path="/whitelist" element={<Whitelist />} />
			<Route path="/busRoutes/:busRouteId/edit" element={<EditBusRoute />} />
			<Route path="/busRoutes/new" element={<NewBusRoute />} />
			<Route path="/busRoutes" element={<BusRoutes />} />
			<Route path="generateCards/generated/:generatedCardId" element={<GeneratedCard />} />
			<Route path="generateCards/generated" element={<GeneratedCards />} />
			<Route path="generateCards/staffs" element={<GenerateStaffsCard />} />
			<Route path="generateCards/students" element={<GenerateStudentsCard />} />
			<Route path="generateCards" element={<GenerateCards />} />

			<Route path="/paytank/merchants/types/:paytankMerchantTypeId/edit" element={<EditPaytankMerchantType />} />
			<Route path="/paytank/merchants/types/:paytankMerchantTypeId" element={<PaytankMerchantType />} />
			<Route path="/paytank/merchants/types/new" element={<NewPaytankMerchantType />} />
			<Route path="/paytank/merchants/types" element={<PaytankMerchantTypes />} />

			<Route path="/paytank/merchants/:paytankMerchantId/edit" element={<EditPaytankMerchant />} />
			<Route path="/paytank/merchants/:paytankMerchantId" element={<PaytankMerchant />} />
			<Route path="/paytank/merchants/new" element={<NewPaytankMerchant />} />
			<Route path="/paytank/merchants" element={<PaytankMerchants />} />
			<Route path="/paytank/transactions" element={<PaytankOrders />} />

			<Route path="/paytank" element={<Paytank />} />

			<Route path="/classes/:classId/:classSectionId/addSchoolTiming" element={<AddSchoolTiming />} />
			<Route path="/schoolTimings/new" element={<NewSchoolTiming />} />
			<Route path="/schoolTimings" element={<SchoolTimings />} />
			<Route path="/schoolTimings/:schoolTimingId/update" element={<UpdateSchoolTiming />} />
			<Route path="/schoolTimings/:schoolTimingId" element={<SchoolTiming />} />
			<Route path="/cafeteria/mealTypes/:cafeteriaMealTypeId/edit" element={<EditCafeteriaMealType />} />
			<Route path="/cafeteria/mealTypes/:cafeteriaMealTypeId" element={<CafeteriaMealType />} />
			<Route path="/cafeteria/mealTypes/new" element={<NewCafeteriaMealType />} />
			<Route path="/cafeteria/mealTypes" element={<CafeteriaMealTypes />} />

			<Route path="/cafeteria/registrationCharges/:cafeteriaRegistrationChargeId/edit" element={<EditCafeteriaRegistrationCharge />} />
			<Route path="/cafeteria/registrationCharges/:cafeteriaRegistrationChargeId" element={<CafeteriaRegistrationCharge />} />
			<Route path="/cafeteria/registrationCharges/new" element={<NewCafeteriaRegistrationCharge />} />
			<Route path="/cafeteria/registrationCharges" element={<CafeteriaRegistrationCharges />} />

			<Route path="/cafeteria/registrations/:cafeteriaRegistrationId/update" element={<UpdateManualCafeteriaRegistration />} />
			<Route path="/cafeteria/registrations/:cafeteriaRegistrationId" element={<CafeteriaRegistration />} />
			<Route path="/cafeteria/registrations" element={<CafeteriaRegistrations />} />

			<Route path="/cafeteria/transactions" element={<CafeteriaTransactions />} />

			<Route path="/cafeteria/messages" element={<UpdateCafeteriaMessages />} />

			<Route path="/cafeteria/reports/registrations/generate" element={<GenerateCafeteriaRegistrationsReport />} />
			<Route path="/cafeteria/reports/registrations/:reportId" element={<CafeteriaRegistrationsReport />} />
			<Route path="/cafeteria/reports/registrations" element={<CafeteriaRegistrationsReports />} />

			<Route path="/cafeteria/reports/transactions/generate" element={<GenerateCafeteriaPaymentsReport />} />
			<Route path="/cafeteria/reports/transactions/:reportId" element={<CafeteriaPaymentsReport />} />
			<Route path="/cafeteria/reports/transactions" element={<CafeteriaPaymentsReports />} />

			<Route path="/cafeteria" element={<Cafeteria />} />

			<Route path="/schoolSessions/:schoolSessionId/edit" element={<EditSchoolSession />} />
			<Route path="/schoolSessions/:schoolSessionId" element={<SchoolSession />} />
			<Route path="/schoolSessions/new" element={<NewSchoolSession />} />
			<Route path="/schoolSessions" element={<SchoolSessions />} />

			<Route path="*" element={<Navigate to="/attendance" replace />} />
		</Routes>
	)
}
