import { Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useNavigate } from "react-router-dom"
import { ExtendedShowcaseFragment, useDeleteShowcaseMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type DeleteShowcaseButtonProps = {
	showcase: ExtendedShowcaseFragment
}

export const DeleteShowcaseButton: React.FC<DeleteShowcaseButtonProps> = ({ showcase }) => {
	const [{ fetching }, deleteShowcase] = useDeleteShowcaseMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleDeleteShowcase = async () => {
		const { error } = await deleteShowcase({ showcaseId: showcase._id })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		return navigate("/showcases", { replace: true })
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<Tooltip label="Delete showcase" fontSize="sm">
				<IconButton aria-label="delete-showcase-btn" variant="ghost" colorScheme="blackAlpha" size="sm" {...getButtonProps()}>
					<Icon as={(props: any) => <FontAwesomeIcon icon={faTrash} {...props} />} />
				</IconButton>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Delete Showcase"
				message="Are you sure you want to delete this showcase?"
				confirmLabel="Delete"
				onConfirm={handleDeleteShowcase}
				isLoading={fetching}
			/>
		</>
	)
}
