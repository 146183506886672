import { Center, Progress, useToast } from "@chakra-ui/react"
import axios, { AxiosProgressEvent } from "axios"
import { nanoid } from "nanoid"
import React, { HTMLAttributes, PropsWithChildren, useCallback, useMemo, useState } from "react"
import {} from "../../context"
import { MediaTypes, useFinishPictureUploadMutation, useMeQuery, useSetCoverMutation, useSignPictureUploadMutation } from "../../graphql"
import { useUpload } from "../../hooks"
import { getFileFormat } from "../../utils"

export const UploadCover: React.FC<PropsWithChildren & HTMLAttributes<any>> = ({ children, ...props }) => {
	const id = useMemo(() => nanoid(), [])

	const { uploadStarted, uploadCancelFnCreated, uploadFailed, uploadFinished, uploadProgress } = useUpload()

	const [, signUpload] = useSignPictureUploadMutation()
	const [, finishUpload] = useFinishPictureUploadMutation()
	const [, setCover] = useSetCoverMutation()

	const toast = useToast()

	const [{ data: meData }] = useMeQuery()

	const [progress, setProgress] = useState(0)
	const [isFinished, setIsFinished] = useState(false)

	const onDrop = useCallback(async (acceptedFiles?: FileList | null) => {
		try {
			const file = acceptedFiles?.[0]

			if (!file) {
				return
			}

			const id = nanoid()

			uploadStarted({ id, type: MediaTypes.Image, file })

			if (Math.floor(file.size) > 1e8) {
				toast({
					title: "Upload Failed",
					description: `The file size exceeds the limit of ${1e8 / 1e6} MBs`,
					status: "error",
				})

				return uploadFailed({
					id,
					error: {
						message: `The file size exceeds the limit of ${1e8 / 1e6} MBs`,
					},
				})
			}

			if (!meData?.me) {
				toast({
					title: "Upload Failed",
					description: "You are not allowed to perform this action",
					status: "error",
				})

				return uploadFailed({
					id,
					error: { message: "You are not allowed to perform this action" },
				})
			}

			const { data, error } = await signUpload({
				format: getFileFormat(file),
			})

			if (error) {
				toast({
					title: "Upload Failed",
					description: error.message,
					status: "error",
				})

				return uploadFailed({ id, error })
			}

			if (!data) {
				toast({
					title: "Upload Failed",
					description: "Upload URL could not be generated",
					status: "error",
				})

				return uploadFailed({
					id,
					error: { message: "Upload URL could not be generated" },
				})
			}

			const {
				signPictureUpload: { key, signedUrl },
			} = data

			const source = axios.CancelToken.source()
			const cancelToken = source.token

			uploadCancelFnCreated({
				id,
				onCancel: () => source.cancel("You cancelled the upload"),
			})

			const onUploadProgress = ({ loaded, total = 0 }: AxiosProgressEvent) => {
				setProgress(Math.floor((100 * loaded) / total))
				uploadProgress({
					id,
					progress: Math.floor((100 * loaded) / total),
				})
			}

			await axios
				.put(signedUrl, file, {
					headers: { "Content-Type": file.type },
					onUploadProgress,
					cancelToken,
				})
				.then(({ status }) => {
					if (status !== 200) {
						toast({
							title: "Upload Failed",
							description: "The photo could not be uploaded correctly",
							status: "error",
						})

						return uploadFailed({
							id,
							error: {
								message: "The photo could not be uploaded correctly",
							},
						})
					}

					return finishUpload({ input: { key } })
						.then(async ({ data, error }) => {
							if (error) {
								toast({
									title: "Upload Failed",
									description: error.message,
									status: "error",
								})

								uploadFailed({ id, error })

								return
							}

							if (!data) {
								toast({
									title: "Upload Failed",
									description: "Upload could not be finished",
									status: "error",
								})

								uploadFailed({
									id,
									error: { message: "Upload could not be finished" },
								})

								return
							}

							const {
								finishPictureUpload: { url },
							} = data

							const { error: _error, data: _data } = await setCover({ key, url })

							if (_error) {
								toast({
									description: _error.message,
									status: "error",
								})

								uploadFailed({ id, error })

								return
							}

							if (!_data) {
								toast({
									description: "Upload could not be finished",
									status: "error",
								})

								uploadFailed({
									id,
									error: { message: "Upload could not be finished" },
								})

								return
							}

							uploadFinished({ id, key, url })
						})
						.catch((error) => {
							toast({
								title: "Upload Failed",
								description: error.message,
								status: "error",
							})

							return uploadFailed({ id, error })
						})
				})
				.catch((error) => {
					toast({
						title: "Upload Failed",
						description: error.message,
						status: "error",
					})

					return uploadFailed({ id, error })
				})
		} catch (error: any) {
			toast({
				title: "Upload Failed",
				description: error.message,
				status: "error",
			})
			return uploadFailed({ id, error })
		}

		setIsFinished(true)
	}, [])

	return (
		<>
			<input
				id={id}
				type="file"
				accept={["image/jpeg", "image/png", "image/webp"]?.join(",")}
				max={1}
				multiple={false}
				style={{ display: "none" }}
				onChange={(e) => onDrop(e.target.files)}
			/>
			<label {...props} htmlFor={id}>
				{children}
				{progress && !isFinished ? (
					<Center pos="absolute" top="0" left="0" right="0" bottom="0">
						<Progress rounded="xl" w="full" maxW="sm" h="2" value={progress} colorScheme="primary" />{" "}
					</Center>
				) : (
					<></>
				)}
			</label>
		</>
	)
}
