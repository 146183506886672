import { Container, Heading, HStack, useBreakpointValue, VStack } from "@chakra-ui/react"
import React from "react"
import { AllCafeteriaRegistrationsReports, DashboardHeader, Nav } from "../../../../components"
import { GRADIENT } from "../../../../constants"
import { useDrawer } from "../../../../hooks"

export const CafeteriaRegistrationsReports: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					<VStack align="flex-start">
						<Heading as="h1" fontSize="xl" color="text.500">
							Cafeteria Registrations Reports
						</Heading>
						<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
							{
								// TODO: add sub heading
							}
						</Heading>
					</VStack>

					<AllCafeteriaRegistrationsReports />
				</VStack>
			</HStack>
		</Container>
	)
}
