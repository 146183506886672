import { Button } from "@chakra-ui/react"
import React from "react"
import { NavLink, NavLinkProps, useLocation } from "react-router-dom"

export const BottomNavigationTab: React.FC<Pick<NavLinkProps, "to"> & { children: any }> = ({ children, to }) => {
	const isActive = new RegExp(`^${to}(/|)`).test(useLocation().pathname)

	return (
		<Button
			as={NavLink}
			to={to}
			borderTopWidth={2}
			borderTopStyle="solid"
			borderTopColor={isActive ? "primary.500" : "transparent"}
			_focus={{
				shadow: "none",
			}}
			rounded="none"
			variant={isActive ? "solid" : "ghost"}
			colorScheme="whiteAlpha"
			color={isActive ? "primary.500" : "blackAlpha.700"}
			size="lg"
		>
			{children}
		</Button>
	)
}
