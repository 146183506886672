import { Center, Container, Heading, HStack, Select, Spinner, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { Nav, DashboardHeader } from "../../../components"
import { StudentsSelector } from "../../../components/reward"
import { GRADIENT } from "../../../constants"
import { useClassesQuery, ExtendedClassFragment, ClassSectionFragment, useStudentsQuery } from "../../../graphql"
import { useDrawer } from "../../../hooks"

export const AddRewardsToStudents: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [{ data: classesData, fetching: fetchingClasses, error: classesError }] = useClassesQuery()

	const [schoolClass, setSchoolClass] = useState<ExtendedClassFragment | undefined>()

	const [classSection, setClassSection] = useState<ClassSectionFragment>()

	useEffect(() => {
		if (classSection && !schoolClass?.sections.map((s) => s._id).includes(classSection._id)) {
			setClassSection(undefined)
		}
	}, [schoolClass])

	useEffect(() => {
		setSelectedStudentIds([])
	}, [schoolClass, classSection])

	const [{ data, fetching, error }] = useStudentsQuery({
		variables: { classId: schoolClass?._id, classSectionId: classSection?._id },
	})

	const [selectedStudentIds, setSelectedStudentIds] = useState<string[]>([])

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Add rewards to students
							</Heading>

							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								Showing data for <strong>{schoolClass?.name || "all"}</strong> class{schoolClass ? "" : "es"}
							</Heading>
						</VStack>
						{fetchingClasses ? (
							<Center>
								<VStack w="full" color="blackAlpha.800">
									<Text>Loading classes</Text>
									<Spinner size="sm" />
								</VStack>
							</Center>
						) : classesError ? (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
									Failed loading classes, try reloading the page
								</Text>
							</Center>
						) : classesData?.classes.length ? (
							<HStack spacing={0}>
								<Select
									bg="white.500"
									roundedStart="xl"
									roundedEnd={schoolClass && schoolClass.sections?.length ? "none" : "xl"}
									border="none"
									_focus={{ border: "none" }}
									w="full"
									maxW="xs"
									placeholder="All"
									value={JSON.stringify(schoolClass)}
									onChange={(e) => {
										setSchoolClass(e.target.value ? JSON.parse(e.target.value) : undefined)
									}}
								>
									{classesData?.classes.map((c) => (
										<option key={c._id} value={JSON.stringify(c)}>
											{c.name}
										</option>
									))}
								</Select>
								{schoolClass && schoolClass.sections?.length && (
									<Select
										bg="white.500"
										roundedStart="none"
										roundedEnd="xl"
										border="none"
										_focus={{ border: "none" }}
										w="full"
										maxW="xs"
										placeholder="All"
										value={JSON.stringify(classSection)}
										onChange={(e) => {
											setClassSection(e.target.value ? JSON.parse(e.target.value) : undefined)
										}}
									>
										{schoolClass.sections.map((s) => (
											<option key={s.name} value={JSON.stringify(s)}>
												{s.name}
											</option>
										))}
									</Select>
								)}
							</HStack>
						) : (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any classes.
								</Text>
							</Center>
						)}
					</Stack>
					{fetching ? (
						<Center py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center>
							<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
								Failed loading students, try reloading the page
							</Text>
						</Center>
					) : data?.students && data?.students.length > 0 ? (
						<StudentsSelector students={data?.students} selectStudentIds={selectedStudentIds} onChange={setSelectedStudentIds} />
					) : (
						<Center py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find any students.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
