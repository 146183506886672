import { Center, Heading, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { SearchDeactivatedStudentsQueryVariables, useSearchDeactivatedStudentsQuery } from "../../graphql"
import { DeactivatedStudentsTable } from "./DeactivatedStudentsTable"

export type SearchResultDeactivatedStudentsProps = {
	searchKeyword: string
}

export const SearchResultDeactivatedStudents: React.FC<SearchResultDeactivatedStudentsProps> = ({ searchKeyword }) => {
	const [pagination, setPagination] = useState<SearchDeactivatedStudentsQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useSearchDeactivatedStudentsQuery({
		variables: { keyword: searchKeyword, pagination },
	})

	const prevPage = () => {
		if (data?.searchDeactivatedStudents.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.searchDeactivatedStudents.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize={isMobile ? "sm" : "md"} fontWeight="normal" color="text.500">
				Showing students for search keyword <strong>&quot;{searchKeyword}&quot;</strong>
			</Heading>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.searchDeactivatedStudents.students && data?.searchDeactivatedStudents.students.length > 0 ? (
				<DeactivatedStudentsTable
					students={data.searchDeactivatedStudents.students}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.searchDeactivatedStudents.hasPrevPage}
					hasNextPage={data.searchDeactivatedStudents.hasNextPage}
					currentPage={data.searchDeactivatedStudents.currentPage}
					totalPages={data.searchDeactivatedStudents.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any students.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
