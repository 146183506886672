import { Button, FormControl, FormErrorIcon, FormErrorMessage, FormLabel, Icon, Input, SlideFade, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { EditPaytankMerchantTypeMutationVariables, PaytankMerchantTypeFragment, useEditPaytankMerchantTypeMutation } from "../../../graphql"

const validationSchema = Yup.object({
	name: Yup.string().required().label("Name"),
	description: Yup.string().nullable().label("Description"),
})
export type EditPaytankMerchantTypeTypeForm = {
	paytankMerchantType: PaytankMerchantTypeFragment
}

export const EditPaytankMerchantTypeForm: React.FC<EditPaytankMerchantTypeTypeForm> = ({ paytankMerchantType }) => {
	const [{ fetching }, edit] = useEditPaytankMerchantTypeMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (values: EditPaytankMerchantTypeMutationVariables["input"]) => {
		const { error } = await edit({ paytankMerchantTypeId: paytankMerchantType._id, input: values })

		if (error) {
			return toast({
				description: error.message,
				status: "error",
			})
		}

		return navigate("/paytank/merchants/types/" + paytankMerchantType._id, { replace: true })
	}

	const formik = useFormik<EditPaytankMerchantTypeMutationVariables["input"]>({
		initialValues: { name: paytankMerchantType.name, description: paytankMerchantType.description || "" },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)}>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="title" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.description && formik.touched.description)}>
				<SlideFade in={Boolean(formik.values.description)} unmountOnExit>
					<FormLabel htmlFor="description" fontSize="sm">
						Description
					</FormLabel>
				</SlideFade>
				<Textarea
					placeholder="Description"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("description")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.description}</Text>
				</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Save
			</Button>
		</VStack>
	)
}
