import { Button, Icon, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from "@chakra-ui/react"
import { faBatteryFull, faBatteryQuarter } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { ExtendedStaffFragment } from "../../graphql"
import { AssignNewTagToStaffForm } from "./AssignNewTagToStaffForm"

export type AssignNewTagToStaffPopoverProps = { staff: ExtendedStaffFragment }

export const AssignNewTagToStaffPopover: React.FC<AssignNewTagToStaffPopoverProps> = ({ staff }) => {
	return (
		<Popover isLazy lazyBehavior="unmount">
			<PopoverTrigger>
				<Button
					m="1"
					size="xs"
					variant="outline"
					colorScheme="orange"
					rightIcon={
						<Icon
							as={(props: any) => (
								<FontAwesomeIcon
									icon={staff.tag ? (!staff.tag?.tagStatus || staff.tag.tagStatus === 0 ? faBatteryFull : faBatteryQuarter) : ""}
									{...props}
								/>
							)}
							color={!staff.tag?.tagStatus || staff.tag?.tagStatus === 0 ? "green" : "red"}
							fontSize="lg"
						/>
					}
				>
					{staff.tagId && staff.tag ? staff.tag.tagDecimalId : "Assign Tag"}
				</Button>
			</PopoverTrigger>
			<PopoverContent bgColor="white">
				<PopoverArrow bgColor="white" />
				<PopoverBody>
					<AssignNewTagToStaffForm staffId={staff._id} />
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
