import { useContext } from "react"
import { DrawerContext, hideDrawer, showDrawer } from "../../context"

export const useDrawer = () => {
	const [state, dispatch] = useContext(DrawerContext)

	const show = () => dispatch(showDrawer())

	const hide = () => dispatch(hideDrawer())

	const toggle = () => (state.isShown ? hide() : show())

	return { ...state, show, hide, toggle }
}
