import { Grid, Icon, IconButton, Popover, PopoverBody, PopoverContent, PopoverTrigger, Tag, Text, VStack, HStack, Heading } from "@chakra-ui/react"
import { faCalendar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format, subWeeks } from "date-fns"
import React, { useState } from "react"
import { DateRange, Range } from "react-date-range"
import { useDrawer } from "../../../hooks"
import { AllCreditOrders } from "./AllCreditOrders"
import { AllDebitOrders } from "./AllDebitOrders"
import { AllFailedOrders } from "./AllFailedOrders"
import { AllSuccessOrders } from "./AllSuccessOrders"
import { AllInitiatedOrders } from "./AllInitiatedOrders"
import { MerchantWiseOrders } from "./MerchantWiseOrders"
import { MerchantTypeWiseOrders } from "./MerchantTypeWiseOrders"
import { DateWisePaytankOrders } from "./DateWisePaytankOrders"
import { AllProcessedRefundOrders } from "./AllProcessedRefundOrders"
import { AllFailedRefundOrders } from "./AllFailedRefundOrders"
import { AllRefundOrders } from "./AllRefundOrders"

export const PaytankStats: React.FC = () => {
	const [dateState, setDateState] = useState<Required<Omit<Range, "color" | "autoFocus" | "disabled" | "showDateDisplay">>>({
		startDate: subWeeks(new Date(format(new Date(), "yyyy-MM-dd")), 1),
		endDate: new Date(format(new Date(), "yyyy-MM-dd")),
		key: "selection",
	})

	const { isShown } = useDrawer()

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" justify="space-between">
				<Heading as="h2" fontSize="lg" color="text.400">
					Statistics
				</Heading>
				<Popover placement="auto-end">
					<PopoverTrigger>
						{dateState.startDate && dateState.endDate && (
							<Tag m="1" bg="white.500" px="4" py="2" rounded="xl" cursor="pointer">
								<Text fontSize="sm">
									{format(dateState.startDate, "dd/MM/yyyy")} - {format(dateState.endDate, "dd/MM/yyyy")}
								</Text>
								<IconButton aria-label="calendar-button" size="xs" variant="ghost" colorScheme="whiteAlpha">
									<Icon
										color="blackAlpha.600"
										_hover={{ color: "blackAlpha.700" }}
										as={(props: any) => <FontAwesomeIcon icon={faCalendar} {...props} />}
									/>
								</IconButton>
							</Tag>
						)}
					</PopoverTrigger>
					<PopoverContent w="full" _focus={{ shadow: "none" }}>
						<PopoverBody w="full" p="0">
							<DateRange
								editableDateInputs={true}
								onChange={(item) =>
									setDateState(item.selection as Required<Omit<Range, "color" | "autoFocus" | "disabled" | "showDateDisplay">>)
								}
								moveRangeOnFirstSelection={false}
								ranges={[dateState]}
								maxDate={new Date()}
							/>
						</PopoverBody>
					</PopoverContent>
				</Popover>
			</HStack>
			<VStack w="full" align="stretch" spacing={4}>
				<VStack w="full" align="stretch">
					<Heading fontSize="sm" color="text.300">
						Payments
					</Heading>
					<Grid
						templateColumns={{
							base: "repeat(2, 1fr)",
							md: isShown ? "repeat(2, 1fr)" : "repeat(4, 1fr)",
							lg: "repeat(4, 1fr)",
							xl: isShown ? "repeat(4, 1fr)" : "repeat(5, 1fr)",
						}}
						gap="4"
					>
						<AllDebitOrders sinceDate={dateState.startDate} untilDate={dateState.endDate} />
						<AllCreditOrders sinceDate={dateState.startDate} untilDate={dateState.endDate} />
						<AllSuccessOrders sinceDate={dateState.startDate} untilDate={dateState.endDate} />
						<AllFailedOrders sinceDate={dateState.startDate} untilDate={dateState.endDate} />
						<AllInitiatedOrders sinceDate={dateState.startDate} untilDate={dateState.endDate} />
					</Grid>
				</VStack>
				<VStack w="full" align="stretch">
					<Heading fontSize="sm" color="text.300">
						Refunds
					</Heading>
					<Grid
						templateColumns={{
							base: "repeat(2, 1fr)",
							md: isShown ? "repeat(2, 1fr)" : "repeat(4, 1fr)",
							lg: "repeat(4, 1fr)",
							xl: "repeat(5, 1fr)",
						}}
						gap="4"
					>
						<AllRefundOrders sinceDate={dateState.startDate} untilDate={dateState.endDate} />
						<AllProcessedRefundOrders sinceDate={dateState.startDate} untilDate={dateState.endDate} />
						<AllFailedRefundOrders sinceDate={dateState.startDate} untilDate={dateState.endDate} />
					</Grid>
				</VStack>
				<Grid
					templateColumns={{
						base: "1fr",
						md: isShown ? "1fr" : "repeat(2, 1fr)",
						lg: "repeat(2, 1fr)",
					}}
					gap="4"
				>
					<MerchantWiseOrders sinceDate={dateState.startDate} untilDate={dateState.endDate} />
					<MerchantTypeWiseOrders sinceDate={dateState.startDate} untilDate={dateState.endDate} />
				</Grid>
				<DateWisePaytankOrders sinceDate={dateState.startDate} untilDate={dateState.endDate} />
			</VStack>
		</VStack>
	)
}
