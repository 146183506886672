import { Center, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { SearchStaffsQueryVariables, useSearchStaffsQuery } from "../../graphql"
import { StaffsTable } from "./StaffsTable"

export type SearchResultStaffsProps = {
	searchKeyword: string
}

export const SearchResultStaffs: React.FC<SearchResultStaffsProps> = ({ searchKeyword }) => {
	const [pagination, setPagination] = useState<SearchStaffsQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useSearchStaffsQuery({
		variables: { pagination, keyword: searchKeyword },
	})

	const prevPage = () => {
		if (data?.searchStaffs.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.searchStaffs.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="md" fontWeight="normal" color="text.500">
				Showing {data?.searchStaffs.total} staff for search keyword <strong>&quot;{searchKeyword}&quot;</strong>
			</Heading>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.searchStaffs.staffs && data?.searchStaffs.staffs.length > 0 ? (
				<StaffsTable
					staffs={data.searchStaffs.staffs}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.searchStaffs.hasPrevPage}
					hasNextPage={data.searchStaffs.hasNextPage}
					currentPage={data.searchStaffs.currentPage}
					totalPages={data.searchStaffs.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any staff
					</Text>
				</Center>
			)}
		</VStack>
	)
}
