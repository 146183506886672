import { Button, Icon, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useDeactivateWorkshopMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type DeactivateWorkshopButtonProps = {
	workshopId: string
}

export const DeactivateWorkshopButton: React.FC<DeactivateWorkshopButtonProps> = ({ workshopId }) => {
	const [{ fetching }, deactivateWorkshop] = useDeactivateWorkshopMutation()

	const toast = useToast()

	const handleDeactivateWorkshop = async () => {
		const { error } = await deactivateWorkshop({ workshopId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		onClose()
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<Tooltip label="Deactivate" fontSize="sm">
				<Button
					size="xs"
					colorScheme="red"
					rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faXmarkCircle} {...props} />} fontSize="sm" />}
					{...getButtonProps()}
				>
					Deactivate this workshop
				</Button>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Deactivate workshop"
				message="Are you sure you want to deactivate this workshop?"
				confirmLabel="Deactivate"
				onConfirm={handleDeactivateWorkshop}
				isLoading={fetching}
			/>
		</>
	)
}
