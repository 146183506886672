import { Center, Spinner } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { SESSION_ID_KEY } from "../constants"

export const Uaoth: React.FC = () => {
	const [searchParams] = useSearchParams()

	useEffect(() => {
		const sessionId = searchParams.get("sessionId")

		if (sessionId) {
			localStorage.setItem(SESSION_ID_KEY, sessionId)
			window.location.replace("/")
		}
	}, [searchParams])

	return (
		<Center w="full" h="100vh">
			<Spinner />
		</Center>
	)
}
