import {
	Badge,
	ButtonGroup,
	Center,
	Container,
	Grid,
	Heading,
	HStack,
	Icon,
	IconButton,
	Link,
	Spinner,
	Text,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react"
import { faPencil } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { NavLink, useParams } from "react-router-dom"
import { DashboardHeader, DeleteManualCafeteriaRegistrationLogButton, Nav } from "../../../components"
import { StudentTag } from "../../../components/student"
import { GRADIENT } from "../../../constants"
import { useCafeteriaRegistrationLogByIdQuery } from "../../../graphql"
import { useDrawer } from "../../../hooks"
import { getMonthForIndex } from "../../../utils"

export const CafeteriaRegistration: React.FC = () => {
	const { cafeteriaRegistrationId } = useParams<{ cafeteriaRegistrationId: string }>()

	const [{ data, fetching, error }] = useCafeteriaRegistrationLogByIdQuery({
		variables: { cafeteriaRegistrationLogId: cafeteriaRegistrationId! },
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.cafeteriaRegistrationLogById ? (
						<VStack w="full" align="flex-start" spacing="6">
							<HStack w="full" py="4" align="flex-start" justify="space-between">
								<VStack align="flex-start">
									<HStack spacing={6}>
										<Heading as="h1" fontSize="xl" color="text.500">
											Cafeteria Registration
										</Heading>

										{data.cafeteriaRegistrationLogById.manualCafeteriaRegistrationDetails && (
											<ButtonGroup alignItems="center">
												<IconButton
													aria-label="edit-school-timing-btn"
													variant="ghost"
													colorScheme="blackAlpha"
													size="sm"
													as={(props: any) => <Link as={NavLink} {...props} />}
													to={`/cafeteria/registrations/${data.cafeteriaRegistrationLogById._id}/update`}
												>
													<Icon as={(props: any) => <FontAwesomeIcon icon={faPencil} {...props} />} />
												</IconButton>

												<DeleteManualCafeteriaRegistrationLogButton cafeteriaRegistrationLog={data.cafeteriaRegistrationLogById} />
											</ButtonGroup>
										)}
									</HStack>
									<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
										Created {format(new Date(data.cafeteriaRegistrationLogById.createdAt), "PPPp")}
									</Heading>
								</VStack>
							</HStack>

							<VStack w="full" maxW="xl" align="flex-start" bg="white.500" rounded="2xl" p={4} shadow="sm" spacing={4}>
								<StudentTag student={data.cafeteriaRegistrationLogById.student} />

								<Grid gridTemplateColumns="repeat(2, 1fr)" gap="4">
									<VStack w="full" align="start" spacing={0}>
										<Text fontSize="sm" color="blackAlpha.700">
											Session
										</Text>
										<Text fontWeight="medium">
											{getMonthForIndex(data.cafeteriaRegistrationLogById.schoolSession.startMonth)}{" "}
											{data.cafeteriaRegistrationLogById.schoolSession.startDate.toString().padStart(2, "0")} -{" "}
											{getMonthForIndex(data.cafeteriaRegistrationLogById.schoolSession.endMonth)}{" "}
											{data.cafeteriaRegistrationLogById.schoolSession.endDate.toString().padStart(2, "0")}
										</Text>
									</VStack>

									<VStack w="full" align="start" spacing={0}>
										<Text fontSize="sm" color="blackAlpha.700">
											Validity
										</Text>
										<Text fontWeight="medium">
											{format(new Date(data.cafeteriaRegistrationLogById.since), "PP")} -{" "}
											{format(new Date(data.cafeteriaRegistrationLogById.until), "PP")}
										</Text>
									</VStack>

									<VStack w="full" align="start" spacing={0}>
										<Text fontSize="sm" color="blackAlpha.700">
											Charge
										</Text>
										<Text fontWeight="medium">
											{Intl.NumberFormat("en-IN", {
												style: "currency",
												currency: "INR",
											}).format(data.cafeteriaRegistrationLogById.cafeteriaRegistrationOrder.quote.cafeteriaRegistrationCharge.amount)}
										</Text>
									</VStack>

									<VStack w="full" align="start" spacing={0}>
										<Text fontSize="sm" color="blackAlpha.700">
											Amount
										</Text>
										<Text fontWeight="medium">
											{Intl.NumberFormat("en-IN", {
												style: "currency",
												currency: "INR",
											}).format(
												data.cafeteriaRegistrationLogById.cafeteriaRegistrationOrder.isManual
													? data.cafeteriaRegistrationLogById.manualCafeteriaRegistrationDetails?.amountPaid ?? 0
													: data.cafeteriaRegistrationLogById.cafeteriaRegistrationOrder.quote.sessionWiseQuotes.find(
															(q) => q.schoolSessionId === data.cafeteriaRegistrationLogById?.schoolSessionId
													  )?.totalAmount ?? 0
											)}
										</Text>
									</VStack>

									<VStack w="full" align="start" spacing={0}>
										<Text fontSize="sm" color="blackAlpha.700">
											Type
										</Text>
										<Text fontWeight="medium">
											{data.cafeteriaRegistrationLogById.cafeteriaRegistrationOrder.isManual &&
											data.cafeteriaRegistrationLogById.manualCafeteriaRegistrationDetails ? (
												<Text color="primary.500" fontWeight="bold">
													Manual
												</Text>
											) : (
												<Text
													color={
														data.cafeteriaRegistrationLogById.cafeteriaRegistrationOrder.quote.sessionWiseQuotes.find(
															(q) => q.schoolSessionId === data.cafeteriaRegistrationLogById?.schoolSessionId
														)?.isPartial
															? "yellow.600"
															: "green.600"
													}
													fontWeight="bold"
												>
													{data.cafeteriaRegistrationLogById.cafeteriaRegistrationOrder.quote.sessionWiseQuotes.find(
														(q) => q.schoolSessionId === data.cafeteriaRegistrationLogById?.schoolSessionId
													)?.isPartial
														? "Partial"
														: "Full"}
												</Text>
											)}
										</Text>
									</VStack>

									<VStack w="full" align="start" spacing={0}>
										<Text fontSize="sm" color="blackAlpha.700">
											Payment #
										</Text>
										<Text fontWeight="medium">
											{data.cafeteriaRegistrationLogById.cafeteriaRegistrationOrder.isManual &&
											data.cafeteriaRegistrationLogById.manualCafeteriaRegistrationDetails ? (
												<VStack w="full" align="flex-start" spacing="0">
													<Badge fontSize="x-small" py="0" colorScheme="primary">
														{data.cafeteriaRegistrationLogById.manualCafeteriaRegistrationDetails.paymentMode}
													</Badge>
													<Text>{data.cafeteriaRegistrationLogById.manualCafeteriaRegistrationDetails.paymentReferenceNo}</Text>
													<Text fontSize="xs" color="orange.600">
														{data.cafeteriaRegistrationLogById.manualCafeteriaRegistrationDetails.remarks}
													</Text>
												</VStack>
											) : (
												data.cafeteriaRegistrationLogById.cafeteriaRegistrationOrder.paymentId
											)}
										</Text>
									</VStack>
								</Grid>
							</VStack>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the cafeteria registration.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
