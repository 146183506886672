import {
	Button,
	Flex,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	HStack,
	Icon,
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
	SlideFade,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import TimePicker from "react-time-picker"
import * as Yup from "yup"
import { CreateDepartmentMutationVariables, DepartmentTypes, useCreateDepartmentMutation } from "../../graphql"

const validationSchema = Yup.object({
	name: Yup.string().required().label("Name"),
	type: Yup.string().oneOf(Object.values(DepartmentTypes)).required().label("Department Type"),
	workingWeekdays: Yup.array().of(Yup.string()).required().min(1).label("Working weekdays"),
})

export const NewDepartmentForm: React.FC = () => {
	const [{ fetching }, createNew] = useCreateDepartmentMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (
		{ name, type, attendanceTiming, workingWeekdays }: CreateDepartmentMutationVariables["input"],
		helpers: FormikHelpers<CreateDepartmentMutationVariables["input"]>
	) => {
		const { error, data } = await createNew({
			input: { name, type, attendanceTiming, workingWeekdays },
		})

		if (error) {
			return toast({
				description: error.message,
				status: "error",
			})
		}

		if (data?.createDepartment.errors) {
			return data?.createDepartment.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		return navigate("/departments", { replace: true })
	}

	const formik = useFormik<CreateDepartmentMutationVariables["input"]>({
		initialValues: {
			name: "",
			type: undefined as any,
			attendanceTiming: { inAt: "07:00", inStartBeforeMinutes: 120, outAt: "13:00", outStartBeforeMinutes: 5 },
			workingWeekdays: ["mon", "tue", "wed", "thu", "fri"],
		},
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack as="form" w="full" maxW="lg" align="stretch" spacing={4} onSubmit={formik.handleSubmit as any}>
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)}>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="name" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={Boolean(formik.errors.type && formik.touched.type)}>
				<SlideFade in={Boolean(formik.values.type)} unmountOnExit>
					<FormLabel htmlFor="type" fontSize="sm">
						Type
					</FormLabel>
				</SlideFade>
				<Select
					placeholder="Type"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("type")}
				>
					{Object.values(DepartmentTypes).map((type) => (
						<option key={type} value={type}>
							{type}
						</option>
					))}
				</Select>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.type}</Text>
				</FormErrorMessage>
			</FormControl>

			<HStack>
				<FormControl isInvalid={Boolean(formik.errors.attendanceTiming?.inAt && formik.touched.attendanceTiming?.inAt)}>
					<FormLabel>School In</FormLabel>
					<TimePicker
						className="default-time-picker"
						disableClock
						format="hh:mm a"
						clearIcon={null}
						{...formik.getFieldProps("attendanceTiming.inAt")}
						onChange={(time) => formik.setFieldValue("attendanceTiming.inAt", time)}
					/>

					<FormErrorMessage>{formik.errors.attendanceTiming?.inAt}</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.errors.attendanceTiming?.outAt && formik.touched.attendanceTiming?.outAt)}>
					<FormLabel>School Out</FormLabel>
					<TimePicker
						className="default-time-picker"
						disableClock
						format="hh:mm a"
						clearIcon={null}
						{...formik.getFieldProps("attendanceTiming.outAt")}
						onChange={(time) => formik.setFieldValue("attendanceTiming.outAt", time)}
					/>

					<FormErrorMessage>{formik.errors.attendanceTiming?.outAt}</FormErrorMessage>
				</FormControl>
			</HStack>

			<HStack w="full">
				<FormControl isInvalid={Boolean(formik.errors.attendanceTiming?.inStartBeforeMinutes && formik.touched.attendanceTiming?.inStartBeforeMinutes)}>
					<FormLabel>Reader Start Time for School-In (in Minutes)</FormLabel>
					<NumberInput
						min={0}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						{...formik.getFieldProps("attendanceTiming.inStartBeforeMinutes")}
						onChange={(valueStr) => formik.setFieldValue("attendanceTiming.inStartBeforeMinutes", Number(valueStr))}
					>
						<NumberInputField />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>
					<FormErrorMessage>{formik.errors.attendanceTiming?.inStartBeforeMinutes}</FormErrorMessage>
					<FormHelperText>Enter amount of minutes the reader should start reading before school-in time</FormHelperText>
				</FormControl>
				<FormControl
					isInvalid={Boolean(formik.errors.attendanceTiming?.outStartBeforeMinutes && formik.touched.attendanceTiming?.outStartBeforeMinutes)}
				>
					<FormLabel>Reader Start Time for School-Out (in Minutes)</FormLabel>
					<NumberInput
						min={0}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						{...formik.getFieldProps("attendanceTiming.outStartBeforeMinutes")}
						onChange={(valueStr) => formik.setFieldValue("attendanceTiming.outStartBeforeMinutes", Number(valueStr))}
					>
						<NumberInputField />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>
					<FormErrorMessage>{formik.errors.attendanceTiming?.outStartBeforeMinutes}</FormErrorMessage>
					<FormHelperText>Enter amount of minutes the reader should start reading before school-out time</FormHelperText>
				</FormControl>
			</HStack>

			<FormControl isInvalid={Boolean(formik.errors.workingWeekdays && formik.touched.workingWeekdays)}>
				<FormLabel>Working Weekdays</FormLabel>
				<Flex flexWrap="wrap">
					{["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map((day) => (
						<Button
							key={day}
							m="1"
							size="sm"
							shadow="none"
							variant="outline"
							borderWidth={formik.values.workingWeekdays.includes(day) ? 2 : 1}
							colorScheme={formik.values.workingWeekdays.includes(day) ? "green" : "red"}
							opacity={formik.values.workingWeekdays.includes(day) ? 1 : 0.4}
							onClick={() => {
								if (formik.values.workingWeekdays.includes(day)) {
									const workingWeekdays = [...formik.values.workingWeekdays]

									workingWeekdays.splice(workingWeekdays.indexOf(day), 1)

									formik.setFieldValue("workingWeekdays", workingWeekdays)
								} else {
									const updated = new Set(formik.values.workingWeekdays).add(day)

									formik.setFieldValue("workingWeekdays", [...updated])
								}
							}}
						>
							{day}
						</Button>
					))}
				</Flex>
				<FormErrorMessage>{formik.errors.workingWeekdays}</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Save
			</Button>
		</VStack>
	)
}
