import { Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import differenceInSeconds from "date-fns/differenceInSeconds"
import format from "date-fns/format"
import React from "react"
import { AuthorTypes, ExtendedRestrictedEntryRecordFragment, ExtendedStudent } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { StaffTag } from "../staff"
import { StudentTag } from "../student"

export type RestrictedEntriesTableProps = {
	restrictedEntries: ExtendedRestrictedEntryRecordFragment[]
}

export const RestrictedEntriesTable: React.FC<RestrictedEntriesTableProps> = ({ restrictedEntries }) => {
	return (
		<VStack w="full" align="stretch" bgColor="white" backdropFilter="blur(24px)" rounded="xl" roundedTopLeft="none" overflowX="auto">
			<Table colorScheme="blackAlpha" size="sm">
				<Thead>
					<Tr>
						<Th>Person</Th>
						<Th>Zone</Th>
						<Th>In</Th>
						<Th>Out</Th>
						<Th>Duration</Th>
					</Tr>
				</Thead>
				<Tbody>
					{restrictedEntries.map((entry) => (
						<Tr key={entry._id}>
							<Td>
								{entry.author.authorType === AuthorTypes.Staff
									? entry.author.staff && <StaffTag staff={entry.author.staff} />
									: entry.author.student && <StudentTag student={entry.author.student as ExtendedStudent} />}
							</Td>
							<Td>{entry.restrictedZone.name}</Td>
							<Td>{format(new Date(entry.inAt), "p")}</Td>
							<Td>{entry.isFinal ? format(new Date(entry.outAt), "p") : "-"}</Td>
							<Td>{entry.isFinal ? getDurationInWords(differenceInSeconds(new Date(entry.outAt), new Date(entry.inAt))) : "-"}</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</VStack>
	)
}
