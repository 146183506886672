/* eslint-disable @typescript-eslint/indent */
import { Box, Center, Grid, Heading, HStack, Icon, Spinner, Text, VStack } from "@chakra-ui/react"
import { faCheckCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import {
	useTodayAttendanceOfStudentQuery,
	useTodayAttendanceUpdateOfStudentSubscription,
	useTodayBusAttendanceOfStudentQuery,
	useTodayBusAttendanceUpdateOfStudentSubscription,
} from "../../graphql"
import { MarkManualIn } from "./MarkManualIn"
import { MarkManualOut } from "./MarkManualOut"

export type TodayAttendanceOfStudentProps = {
	studentId: string
}

export const TodayAttendanceOfStudent: React.FC<TodayAttendanceOfStudentProps> = ({ studentId }) => {
	const [{ data, fetching, error }] = useTodayAttendanceOfStudentQuery({
		variables: { studentId },
	})

	useTodayAttendanceUpdateOfStudentSubscription({ variables: { studentId } })

	const [{ data: busAttendanceData, fetching: fetchingBusAttendance, error: busAttendanceError }] = useTodayBusAttendanceOfStudentQuery({
		variables: { studentId },
	})

	useTodayBusAttendanceUpdateOfStudentSubscription({ variables: { studentId } })

	return (
		<VStack>
			{fetching ? (
				<Center w="full" py="4">
					<Spinner color="text.400" />
				</Center>
			) : error ? (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="text.400">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : (
				<VStack w="full" maxW="xl" align="flex-start" spacing={{ lg: 5 }}>
					<Heading as="h2" fontSize="md" fontWeight="semisemibold" color="text.400">
						Today&apos;s attendance
					</Heading>
					<Box bg="white.500" rounded="2xl" px="6" py="4" w="full" shadow="sm">
						{fetchingBusAttendance ? (
							<Center w="full" py="4">
								<Spinner color="text.400" />
							</Center>
						) : busAttendanceError ? (
							<Center w="full" py="4">
								<Text fontSize="md" fontWeight="semisemibold" color="text.400">
									{busAttendanceError.message.replace("[GraphQL] ", "")}
								</Text>
							</Center>
						) : (
							<Grid w="full" templateColumns="repeat(2, 1fr)" gap={4}>
								<VStack align="flex-start" spacing={0}>
									<Text fontSize="xs" color="text.400">
										Pickup from Home
									</Text>
									<HStack>
										<VStack align="flex-start" spacing={0}>
											<Text fontSize="sm" color="text.400" fontWeight="semibold">
												{busAttendanceData?.todayBusAttendanceOfStudent?.homePickupAt
													? format(new Date(busAttendanceData.todayBusAttendanceOfStudent.homePickupAt), "p")
													: "-"}
											</Text>
										</VStack>
									</HStack>
								</VStack>

								<VStack align="flex-start" spacing={0}>
									<Text fontSize="xs" color="text.400">
										Dropped at School
									</Text>
									<HStack>
										<VStack align="flex-start" spacing={0}>
											<VStack align="flex-start" spacing={0}>
												<Text fontSize="sm" color="text.400" fontWeight="semibold">
													{busAttendanceData?.todayBusAttendanceOfStudent?.schoolDropAt
														? format(new Date(busAttendanceData.todayBusAttendanceOfStudent.schoolDropAt), "p")
														: "-"}
												</Text>
											</VStack>
										</VStack>
									</HStack>
								</VStack>

								<VStack align="flex-start" spacing={0}>
									<Text fontSize="xs" color="text.400">
										Pickup from School
									</Text>
									<HStack>
										<VStack align="flex-start" spacing={0}>
											<Text fontSize="sm" color="text.400" fontWeight="semibold">
												{busAttendanceData?.todayBusAttendanceOfStudent?.schoolPickupAt
													? format(new Date(busAttendanceData.todayBusAttendanceOfStudent.schoolPickupAt), "p")
													: "-"}
											</Text>
										</VStack>
									</HStack>
								</VStack>

								<VStack align="flex-start" spacing={0}>
									<Text fontSize="xs" color="text.400">
										Dropped at Home
									</Text>
									<HStack>
										<VStack align="flex-start" spacing={0}>
											<VStack align="flex-start" spacing={0}>
												<Text fontSize="sm" color="text.400" fontWeight="semibold">
													{busAttendanceData?.todayBusAttendanceOfStudent?.homeDropAt
														? format(new Date(busAttendanceData.todayBusAttendanceOfStudent.homeDropAt), "p")
														: "-"}
												</Text>
											</VStack>
										</VStack>
									</HStack>
								</VStack>
							</Grid>
						)}

						<Grid w="full" templateColumns="repeat(2, 1fr)" gap={4} mt="4">
							<VStack align="flex-start" spacing={0}>
								<Text fontSize="xs" color="text.400">
									School In
								</Text>
								<HStack>
									{data?.todayAttendanceOfStudent ? (
										<VStack align="flex-start" spacing={0}>
											<Text fontSize="sm" color="text.400" fontWeight="semibold">
												{data?.todayAttendanceOfStudent &&
												(data.todayAttendanceOfStudent.isInRecorded || data.todayAttendanceOfStudent.isInManual)
													? format(new Date(data.todayAttendanceOfStudent.inAt), "p")
													: "-"}
											</Text>
											<Text fontSize="xs" color="text.100">
												{data?.todayAttendanceOfStudent &&
													(data.todayAttendanceOfStudent.isInManual
														? "set manually"
														: data.todayAttendanceOfStudent.isInRecorded
														? "as recorded"
														: "")}
											</Text>
											{data.todayAttendanceOfStudent.inReader && (
												<Text fontSize="xs" color="text.100">
													{data.todayAttendanceOfStudent.inReader.label
														? data.todayAttendanceOfStudent.inReader.label
														: "Reader " + data.todayAttendanceOfStudent.inReader.readerDecimalId}
												</Text>
											)}
										</VStack>
									) : (
										<Text fontSize="sm" color="text.400" fontWeight="semibold">
											-
										</Text>
									)}
									<MarkManualIn studentId={studentId} />
								</HStack>
							</VStack>

							<VStack align="flex-start" spacing={0}>
								<Text fontSize="xs" color="text.400">
									School Out
								</Text>
								<HStack>
									{data?.todayAttendanceOfStudent ? (
										<VStack align="flex-start" spacing={0}>
											<Text fontSize="sm" color="text.400" fontWeight="semibold">
												{format(new Date(data.todayAttendanceOfStudent.outAt), "p")}
											</Text>
											<Text fontSize="xs" color="text.100">
												{data.todayAttendanceOfStudent.isFinal
													? data.todayAttendanceOfStudent.isOutManual
														? "set manually"
														: data.todayAttendanceOfStudent.isOutRecorded
														? "as recorded"
														: ""
													: "last seen"}
											</Text>
											{data.todayAttendanceOfStudent.outReader && (
												<Text fontSize="xs" color="text.100">
													{data.todayAttendanceOfStudent.outReader.label
														? data.todayAttendanceOfStudent.outReader.label
														: "Reader " + data.todayAttendanceOfStudent.outReader.readerDecimalId}
												</Text>
											)}
										</VStack>
									) : (
										<Text fontSize="sm" color="text.400" fontWeight="semibold">
											-
										</Text>
									)}
									<MarkManualOut studentId={studentId} />
								</HStack>
							</VStack>

							<VStack align="flex-start" spacing={0}>
								<Text fontSize="xs" color="text.400">
									Status
								</Text>

								{data?.todayAttendanceOfStudent && data.todayAttendanceOfStudent.isLate !== null ? (
									<HStack>
										{" "}
										<Icon
											color={data.todayAttendanceOfStudent.isLate ? "red" : "green"}
											as={(props: any) => (
												<FontAwesomeIcon icon={data.todayAttendanceOfStudent!.isLate ? faXmarkCircle : faCheckCircle} {...props} />
											)}
										/>
										<Text fontSize="sm" color="text.400" fontWeight="semibold">
											{data.todayAttendanceOfStudent!.isLate
												? `Late ${
														data.todayAttendanceOfStudent.lateByMinutes && data.todayAttendanceOfStudent.lateByMinutes >= 0
															? `by ${data.todayAttendanceOfStudent.lateByMinutes || 0} minutes`
															: ""
												  }`
												: "On time"}
										</Text>
									</HStack>
								) : (
									<Text>-</Text>
								)}
							</VStack>
						</Grid>
					</Box>
				</VStack>
			)}
		</VStack>
	)
}
