import { FormLabel, Icon, IconButton, SlideFade, Textarea, useDisclosure, useToast, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { X } from "react-feather"
import { ExtendedPointsRedeemTransactionFragment, useRejectPointsRedeemTransactionMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type RejectTransactionButtonProps = {
	transaction: ExtendedPointsRedeemTransactionFragment
}

export const RejectTransactionButton: React.FC<RejectTransactionButtonProps> = ({ transaction }) => {
	const [{ fetching }, reject] = useRejectPointsRedeemTransactionMutation()

	const toast = useToast()

	const [remarks, setRemarks] = useState("")

	const handleReject = async () => {
		const { error, data } = await reject({ transactionId: transaction._id, remarks })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.rejectPointsRedeemTransaction) {
			return toast({
				description: "Rejected successfully",
				status: "success",
			})
		}
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<IconButton aria-label="approve-btn" size="xs" icon={<Icon as={X} />} colorScheme="red" variant="outline" {...getButtonProps()} />
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Reject"
				message="Please write a reason for this rejection"
				confirmLabel="Reject"
				onConfirm={handleReject}
				isLoading={fetching}
			>
				<VStack w="full" align="stretch" spacing={0}>
					<SlideFade in={Boolean(remarks)} unmountOnExit>
						<FormLabel htmlFor="remarks" fontSize="sm">
							Remarks
						</FormLabel>
					</SlideFade>
					<Textarea
						placeholder="Remarks"
						border="none"
						_focus={{ border: "none" }}
						maxW="md"
						size="sm"
						bg="white.500"
						rounded="xl"
						py="1"
						autoComplete="off"
						value={remarks}
						onChange={(e) => setRemarks(e.target.value)}
					/>
				</VStack>
			</ConfirmationAlertDialog>
		</>
	)
}
