import { BoxProps, HStack, LinkBox, LinkOverlay, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { Class, ClassSection } from "../../graphql"
import { Card, CardProps } from "../common"
import { RemoveUnauthorizedClassButton } from "./RemoveUnauthorizedClassButton"

export type UnauthorizedClassCardProps = {
	restrictedZoneId: string
	class: Class
	classSection: ClassSection
} & BoxProps

export const UnauthorizedClassCard: React.FC<UnauthorizedClassCardProps> = ({ restrictedZoneId, class: _class, classSection, ...props }) => {
	const [isHovered, setIsHovered] = useState(false)

	const isMobile = useBreakpointValue({ base: true, md: false })

	return (
		<LinkBox onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
			<Card position="relative" {...(props as unknown as CardProps)}>
				{(isMobile || isHovered) && (
					<RemoveUnauthorizedClassButton restrictedZoneId={restrictedZoneId} classId={_class._id} classSectionId={classSection._id} />
				)}
				<LinkOverlay href={`/classes/${_class._id}`}>
					<HStack align="flex-start" spacing="6">
						<VStack align="flex-start" spacing="0">
							<Text fontSize="md" fontWeight="semibold">
								{_class.name}
							</Text>
							<Text fontSize="xs" color="text.400">
								Class
							</Text>
						</VStack>
						<VStack align="flex-start" spacing="0">
							<Text fontSize="md" fontWeight="semibold">
								{classSection.name}
							</Text>
							<Text fontSize="xs" color="text.400">
								Section
							</Text>
						</VStack>
					</HStack>
				</LinkOverlay>
			</Card>
		</LinkBox>
	)
}
