import { AspectRatio, Box, Button, ButtonGroup, Flex, Heading, Icon, Text, useDisclosure, VStack } from "@chakra-ui/react"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { contrastColor } from "contrast-color"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { HouseFragment } from "../../graphql"
import { usePagination } from "../../hooks"
import { Card } from "../common"
import { PenaliseHousesModal } from "./PenaliseHousesModal"

export type HousesSelectorProps = {
	houses: HouseFragment[]
	selectedHouseIds: string[]
	onChange: (houseIds: string[]) => void
}

export const HousesSelector: React.FC<HousesSelectorProps> = ({ houses, selectedHouseIds, onChange }) => {
	const isAllSelected = useMemo(
		() => Boolean(selectedHouseIds.length) && houses.map(({ _id }) => _id).every((id) => selectedHouseIds.includes(id)),
		[selectedHouseIds, houses]
	)

	const selectHouse = (houseId: string) => {
		const _selectedHouseIds = new Set(selectedHouseIds)
		_selectedHouseIds.add(houseId)

		onChange(Array.from(_selectedHouseIds))
	}

	const deselectHouse = (houseId: string) => {
		const _selectedHouseIds = [...selectedHouseIds]

		_selectedHouseIds.splice(_selectedHouseIds.indexOf(houseId), 1)

		onChange(_selectedHouseIds)
	}

	const selectAllHouses = () => {
		onChange(houses.map(({ _id }) => _id) ?? [])
	}

	const deselectAllHouses = () => {
		onChange([])
	}

	const isHouseSelected = useCallback((houseId: string) => selectedHouseIds.includes(houseId), [selectedHouseIds])

	const selectedHouses = useMemo(
		() => selectedHouseIds.map((id) => houses.find((s) => s._id === id)).filter(Boolean) as HouseFragment[],
		[selectedHouseIds, houses]
	)

	useEffect(() => {
		onChange(selectedHouseIds)
	}, [selectedHouseIds])

	const { currentItems, loadMore, hasMore } = usePagination<HouseFragment>(houses, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	const { getButtonProps, isOpen, onClose } = useDisclosure()

	return (
		<>
			<VStack w="full" align="stretch">
				<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
					Select the houses you want to give penalise
				</Heading>
				<ButtonGroup>
					{isAllSelected ? <Button onClick={deselectAllHouses}>Deselect All</Button> : <Button onClick={selectAllHouses}>Select All</Button>}

					<Button colorScheme="purple" isDisabled={!selectedHouses.length} {...getButtonProps()}>
						Penalise
					</Button>
				</ButtonGroup>
				<Flex w="full" flexWrap="wrap" overflow="auto" py="2">
					{currentItems.map((house) => (
						<Card
							key={house._id}
							m={2}
							p={{ base: 6, md: 12 }}
							onClick={isHouseSelected(house._id) ? () => deselectHouse(house._id) : () => selectHouse(house._id)}
							bgColor={isHouseSelected(house._id) ? "green.50" : "white.200"}
							borderColor={house?.colorHex || "gray.500"}
							borderTopWidth={1}
							borderBottomWidth={6}
						>
							<AspectRatio w="full" maxW="6" ratio={1} pos="absolute" top="-1" right="-1">
								<Box p="1" bgColor={house?.colorHex || "gray.500"} rounded="md">
									<Text fontSize="xs" fontWeight="bold" color={contrastColor({ bgColor: house?.colorHex || "gray.500" })}>
										{house.pointsBalance || 0}
									</Text>
								</Box>
							</AspectRatio>
							{isHouseSelected(house._id) && (
								<>
									<Box
										pos="absolute"
										top="-0.5"
										left="-0.5"
										w=".8em"
										h=".8em"
										bgColor={contrastColor({ bgColor: house?.colorHex || "gray.500" })}
										rounded="full"
									/>
									<Icon
										pos="absolute"
										top="-1"
										left="-1"
										color={house?.colorHex || "gray.500"}
										fontSize="lg"
										as={(props: any) => <FontAwesomeIcon icon={faCheckCircle} {...props} />}
									/>
								</>
							)}
							<Heading fontSize="sm" lineHeight="100%">
								{house.name}
							</Heading>
						</Card>
					))}

					{hasMore && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
				</Flex>
			</VStack>
			<PenaliseHousesModal isOpen={isOpen} onClose={onClose} houses={selectedHouses} />
		</>
	)
}
