/* eslint-disable @typescript-eslint/indent */
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { ExtendedStudentAttendanceFragment } from "../../graphql"
import { usePagination } from "../../hooks"
import { StudentTag } from "../student"

export type StudentAttendancesTableProps = {
	attendances: ExtendedStudentAttendanceFragment[]
}

export const StudentAttendancesTable: React.FC<StudentAttendancesTableProps> = ({ attendances }) => {
	const { currentItems, loadMore, hasMore } = usePagination<ExtendedStudentAttendanceFragment>(attendances, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<Box w="full" overflow="auto">
			<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
				<Thead>
					<Tr>
						<Th textTransform="capitalize">Student</Th>
						<Th textTransform="capitalize">Class</Th>
						<Th textTransform="capitalize">First seen</Th>
						<Th textTransform="capitalize">Last seen</Th>
						<Th textTransform="capitalize">Status</Th>
					</Tr>
				</Thead>
				<Tbody>
					{currentItems.map(
						({
							student,
							inAt,
							outAt,
							isInManual,
							isInRecorded,
							isFinal,
							isLate,
							lateByMinutes,
							class: _class,
							classSection,
							isAbsent,
							inReader,
							outReader,
						}) => (
							<Tr key={student._id} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td>
									<StudentTag student={student} />
								</Td>
								<Td>
									{_class.name} - {classSection.name}
								</Td>
								<Td>
									{!isAbsent && (isInManual || isInRecorded)
										? `${format(new Date(inAt), "p")} ${
												inReader ? (inReader.label ? `(${inReader.label})` : "(Reader " + `${inReader.readerDecimalId})`) : ""
										  }`
										: "-"}
								</Td>
								<Td>
									{isAbsent
										? "-"
										: `${format(new Date(outAt), "p")} ${isFinal ? "" : "(last seen)"} ${
												outReader ? (outReader.label ? `(${outReader.label})` : "(Reader " + `${outReader.readerDecimalId})`) : ""
										  }`}
								</Td>
								<Td>
									{!isAbsent && (isInManual || isInRecorded)
										? isLate
											? `Late ${lateByMinutes && lateByMinutes >= 0 ? `by ${lateByMinutes || 0} minutes` : ""}`
											: "On time"
										: "-"}
								</Td>
							</Tr>
						)
					)}
				</Tbody>
			</Table>
			{hasMore && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
		</Box>
	)
}
