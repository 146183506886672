/* eslint-disable @typescript-eslint/indent */
import { Alert, Box, ButtonGroup, Heading, HStack, Icon, IconButton, Link, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import { startCase } from "lodash"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedPaytankOrderFragment, PaymentOrderStatus, PaytankOrderTypes } from "../../../graphql"
import { StudentTag } from "../../student"

export type PaytankOrdersTableProps = {
	paytankOrders: ExtendedPaytankOrderFragment[]
	prevPage: () => void
	nextPage: () => void
	hasPrevPage: boolean
	hasNextPage: boolean
	currentPage: number
	totalPages: number
}

export const PaytankOrdersTable: React.FC<PaytankOrdersTableProps> = ({
	paytankOrders,
	prevPage,
	nextPage,
	hasPrevPage,
	hasNextPage,
	currentPage,
	totalPages,
}) => {
	return (
		<VStack w="full" spacing="0">
			<HStack alignSelf="flex-end">
				<Text fontSize="xs" color="text.400">
					{currentPage}/{totalPages}
				</Text>
				<ButtonGroup size="xs" p="4">
					<IconButton aria-label="prev-btn" onClick={prevPage} isDisabled={!hasPrevPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowLeft} {...props} />} color="text.500" />
					</IconButton>
					<IconButton aria-label="next-btn" onClick={nextPage} isDisabled={!hasNextPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} color="text.500" />
					</IconButton>
				</ButtonGroup>
			</HStack>
			<Box w="full" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th textTransform="capitalize">Order #</Th>
							<Th textTransform="capitalize">Payment #</Th>
							<Th textTransform="capitalize">Student</Th>
							<Th textTransform="capitalize">Amount</Th>
							<Th textTransform="capitalize">Merchant</Th>
							<Th textTransform="capitalize">Type</Th>
							<Th textTransform="capitalize">Status</Th>
							<Th textTransform="capitalize">Created</Th>
						</Tr>
					</Thead>
					<Tbody>
						{paytankOrders.map((order) => (
							<Tr
								key={order._id}
								bgColor={
									order.status === PaymentOrderStatus.Initiated
										? "yellow.50"
										: order.status === PaymentOrderStatus.Failed
										? "red.50"
										: "green.50"
								}
								cursor="pointer"
								fontSize="sm"
							>
								<Td>{order.type === PaytankOrderTypes.Credit && order.orderId ? order.orderId : order._id}</Td>
								<Td>{order.paymentId}</Td>
								<Td py="0">
									<StudentTag student={order.student} />
								</Td>

								<Td>
									<VStack align="flex-start">
										<Text color={order.type === PaytankOrderTypes.Credit ? "green.600" : "red.600"} fontWeight="bold">
											{order.type === PaytankOrderTypes.Credit ? "+" : "-"} ₹{order.amount}{" "}
											{order.description ? `(${order.description})` : ""}
										</Text>
										{(order.refundId || order.refundAmount || order.refundCreatedAt || order.refundFailedAt || order.refundProcessedAt) && (
											<Alert as={VStack} variant="subtle" status="error" spacing={0} mt="1" p="2" rounded="xl">
												<Heading fontSize="xs" color="red.600">
													Refund {order.refundId ? order.refundId : ""} {order.refundAmount ? " of ₹" + order.refundAmount : ""}{" "}
													{order.refundProcessedAt ? "processed" : order.refundFailedAt ? "failed" : "created"}
												</Heading>
												<Text fontSize="xs" color="red.600">
													{[order.refundCreatedAt, order.refundProcessedAt, order.refundFailedAt]
														.filter(Boolean)
														.map((date) => format(new Date(date), "MMM dd, yyyy p"))
														.join(" -> ")}
												</Text>
											</Alert>
										)}
									</VStack>
								</Td>
								<Td>
									{order.type === PaytankOrderTypes.Credit ? (
										"Razorpay"
									) : order.merchant?.name ? (
										<Link as={NavLink} to={`/paytank/merchants/${order.merchantId}`}>
											{order.merchant.name}
										</Link>
									) : (
										""
									)}
								</Td>
								<Td>{order.type}</Td>
								<Td>
									<VStack align="flex-start" spacing={0}>
										<Text
											color={
												order.status === PaymentOrderStatus.Success
													? "green.600"
													: order.status === PaymentOrderStatus.Initiated
													? "yellow.600"
													: "red.600"
											}
											fontWeight="bold"
										>
											{order.status}
										</Text>
										{order.failureReason ? (
											<Text color="red.600" fontSize="xs">
												{startCase(order.failureReason)}
											</Text>
										) : (
											<></>
										)}
									</VStack>
								</Td>
								<Td>{format(new Date(order.createdAt), "dd/MM/yyyy p")}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
		</VStack>
	)
}
