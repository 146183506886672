import { AspectRatio, AspectRatioProps, Avatar, ButtonGroup, HStack, Icon, IconButton, StackProps, useBreakpointValue } from "@chakra-ui/react"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion, MotionProps } from "framer-motion"
import React from "react"
import { useMeQuery } from "../../graphql"
import { useDrawer } from "../../hooks"
import { AccountMenu } from "./AccountMenu"
import { Logo } from "./Logo"

const MotionAspectRatio = motion<MotionProps & AspectRatioProps>(AspectRatio)

export type DashboardHeaderProps = StackProps

export const DashboardHeader: React.FC<DashboardHeaderProps> = (props) => {
	const [{ data }] = useMeQuery()

	const { isShown, show, hide } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<HStack w="full" justify="space-between" px={isMobile ? 2 : 0} zIndex={9} {...props}>
			<HStack spacing={4}>
				{isMobile ? (
					<Logo />
				) : (
					<ButtonGroup>
						<IconButton aria-label="hide-drawer-btn" onClick={hide} isDisabled={!isShown} colorScheme="whiteAlpha">
							<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowLeft} {...props} />} color="text.500" />
						</IconButton>
						<IconButton aria-label="show-drawer-btn" onClick={show} isDisabled={isShown} colorScheme="whiteAlpha">
							<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} color="text.500" />
						</IconButton>
					</ButtonGroup>
				)}
			</HStack>
			<AccountMenu>
				<MotionAspectRatio ratio={1} w="10" rounded="xl" shadow="xl" cursor="pointer" whileHover={{ scale: 1.1 }}>
					<Avatar src={data?.me?.picture?.url} name={data?.me?.name} size="lg" />
				</MotionAspectRatio>
			</AccountMenu>
		</HStack>
	)
}
