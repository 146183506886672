import {
	Button,
	Center,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Icon,
	Input,
	SlideFade,
	Spinner,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { useState } from "react"
import DateTimePicker from "react-datetime-picker"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import {
	GenerateCafeteriaRegistrationsReportMutationVariables,
	useGenerateCafeteriaRegistrationsReportMutation,
	useSchoolSessionsQuery,
} from "../../../../graphql"
import { SchoolSessionSelector } from "../../../schoolSessions"

const validationSchema = Yup.object({
	name: Yup.string().required().label("Name"),
})

export const GenerateCafeteriaRegistrationsReportForm: React.FC = () => {
	const [{ fetching }, generate] = useGenerateCafeteriaRegistrationsReportMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const [sinceDate, setSinceDate] = useState<Date>()
	const [untilDate, setUntilDate] = useState<Date>()
	const [selectedSchoolSessionIds, setSelectedSchoolSessionIds] = useState<string[]>([])

	const [{ data: schoolSessionsData, fetching: fetchingSchoolSessions, error: schoolSessionsError }] = useSchoolSessionsQuery()

	const handleSubmit = async ({ name }: GenerateCafeteriaRegistrationsReportMutationVariables["input"]) => {
		const { error, data } = await generate({ input: { name, schoolSessionIds: selectedSchoolSessionIds, sinceDate, untilDate } })

		if (error) {
			return toast({
				description: error.message,
				status: "error",
			})
		}

		if (data?.generateCafeteriaRegistrationsReport) {
			return navigate(`/cafeteria/reports/registrations/${data.generateCafeteriaRegistrationsReport._id}`, { replace: true })
		}

		return navigate("/cafeteria/reports/registrations", { replace: true })
	}

	const formik = useFormik<GenerateCafeteriaRegistrationsReportMutationVariables["input"]>({
		initialValues: { name: "" },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)}>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="title" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>

			<HStack>
				<VStack w="full" maxW="2xl" align="flex-start">
					<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
						Since
					</Heading>
					<DateTimePicker onChange={setSinceDate} value={sinceDate} format="MMM dd, y" maxDate={new Date()} clearIcon={null} />
				</VStack>
				<VStack w="full" maxW="2xl" align="flex-start">
					<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
						Until
					</Heading>
					<DateTimePicker onChange={setUntilDate} value={untilDate} format="MMM dd, y" maxDate={new Date()} clearIcon={null} />
				</VStack>
			</HStack>

			<VStack w="full" align="flex-start" maxW="xl">
				{fetchingSchoolSessions ? (
					<VStack w="full" maxW="2xl">
						<Spinner />
						<Text textAlign="center">Fetching school timings</Text>
					</VStack>
				) : schoolSessionsError ? (
					<Center>
						<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
							Failed loading school timings, try reloading the page
						</Text>
					</Center>
				) : schoolSessionsData?.schoolSessions && schoolSessionsData.schoolSessions.length > 0 ? (
					<SchoolSessionSelector
						schoolSessions={schoolSessionsData.schoolSessions}
						selectedSchoolSessionIds={selectedSchoolSessionIds}
						onChange={setSelectedSchoolSessionIds}
						isDefaultAllSelected
					/>
				) : (
					<Center py="4">
						<Text fontSize="md" fontWeight="semibold" color="text.400">
							Couldn&apos;t find any school timings.
						</Text>
					</Center>
				)}
			</VStack>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
