import {
	Button,
	Center,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Icon,
	Select,
	SlideFade,
	Spinner,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import * as Yup from "yup"
import { EditClassOfStudentMutationVariables, ExtendedStudentFragment, useClassesQuery, useEditClassOfStudentMutation } from "../../graphql"

const validationSchema = Yup.object({
	classId: Yup.string().required().label("Class"),
	classSectionId: Yup.string().required().label("Section"),
})

export type EditStudentClassFormProps = {
	student: ExtendedStudentFragment
}

export const EditStudentClassForm: React.FC<EditStudentClassFormProps> = ({ student }) => {
	const [{ fetching }, editStudentClass] = useEditClassOfStudentMutation()

	const toast = useToast()

	const onSubmit = async (
		{ classId, classSectionId }: EditClassOfStudentMutationVariables["input"],
		helpers: FormikHelpers<EditClassOfStudentMutationVariables["input"]>
	) => {
		const { error, data } = await editStudentClass({ studentId: student._id, input: { classId, classSectionId } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		if (data?.editClassOfStudent.errors) {
			return data?.editClassOfStudent.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		if (!data?.editClassOfStudent.student) {
			return toast({
				description: "Could not edit class of the student",
				status: "error",
			})
		}

		toast({ description: "Updated student's class", status: "success" })
	}

	const formik = useFormik({
		initialValues: { classId: student.classId, classSectionId: student.classSectionId },
		onSubmit,
		validationSchema,
	})

	const [{ data: classesData, fetching: fetchingClasses, error: classesError }] = useClassesQuery()

	return (
		<form onSubmit={formik.handleSubmit}>
			<VStack w="full" align="stretch">
				<Heading fontSize="sm">Update Class</Heading>
				{fetchingClasses ? (
					<Center w="full" py="1">
						<VStack>
							<Spinner size="sm" color="text.400" />
							<Text fontSize="xs" color="text.400">
								Loading classes
							</Text>
						</VStack>
					</Center>
				) : classesError ? (
					<Center w="full" py="1">
						<VStack>
							<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
							<Text fontSize="xs" color="text.400">
								{classesError.message.replace("[GraphQL] ", "")}
							</Text>
						</VStack>
					</Center>
				) : (
					<HStack w="full" spacing={0} align="flex-end">
						<FormControl isInvalid={Boolean(formik.errors.classId && formik.touched.classId)}>
							<SlideFade in={Boolean(formik.values.classId)} unmountOnExit>
								<FormLabel htmlFor="classId" fontSize="sm">
									Class
								</FormLabel>
							</SlideFade>
							<Select
								placeholder="Select Class"
								border="none"
								_focus={{
									border: "none",
									borderRight: ".5px solid",
									borderRightColor: "blackAlpha.200",
								}}
								borderRight=".5px solid"
								borderRightColor="blackAlpha.200"
								bg="white.500"
								roundedLeft="xl"
								roundedRight={formik.values.classId ? "none" : "xl"}
								py="1"
								autoComplete="off"
								{...formik.getFieldProps("classId")}
							>
								{classesData?.classes.map(({ _id, name }) => (
									<option key={_id} value={_id}>
										{name}
									</option>
								))}
							</Select>
							<FormErrorMessage>{formik.errors.classId}</FormErrorMessage>
						</FormControl>

						<FormControl
							isInvalid={Boolean(formik.errors.classSectionId && formik.touched.classSectionId)}
							display={formik.values.classId ? "block" : "none"}
						>
							<SlideFade in={Boolean(formik.values.classSectionId)} unmountOnExit>
								<FormLabel htmlFor="classSectionId" fontSize="sm">
									Section
								</FormLabel>
							</SlideFade>
							<Select
								placeholder="Select Section"
								border="none"
								_focus={{
									border: "none",
									borderLeft: ".5px solid",
									borderLeftColor: "blackAlpha.200",
								}}
								borderLeft=".5px solid"
								borderLeftColor="blackAlpha.200"
								bg="white.500"
								roundedRight="xl"
								roundedLeft="none"
								py="1"
								autoComplete="off"
								{...formik.getFieldProps("classSectionId")}
							>
								{classesData?.classes
									?.find((_class) => _class._id === formik.values.classId)
									?.sections?.map((section) => (
										<option key={section.name} value={section._id}>
											{section.name}
										</option>
									))}
							</Select>
							<FormErrorMessage>{formik.errors.classSectionId}</FormErrorMessage>
						</FormControl>
					</HStack>
				)}
				<Button size="sm" type="submit" colorScheme="primary" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
					Update
				</Button>
			</VStack>
		</form>
	)
}
