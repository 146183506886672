import { Container, Flex, Heading, HStack, Icon, LinkBox, LinkOverlay, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import { faChalkboardTeacher, faHouse, faPeopleGroup, faUsers } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { NavLink } from "react-router-dom"
import { Card, DashboardHeader, Nav } from "../../../components"
import { GRADIENT } from "../../../constants"
import { useDrawer } from "../../../hooks"

export const Reward: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					<VStack align="flex-start">
						<Heading as="h1" fontSize="xl" color="text.500">
							Reward
						</Heading>
						<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
							{
								// TODO: add sub heading
							}
						</Heading>
					</VStack>
					<Flex w="full" flexWrap="wrap">
						<LinkBox m="1">
							<Card>
								<LinkOverlay as={NavLink} to="/points/reward/add/students" />
								<VStack spacing="4">
									<Icon fontSize="xl" as={(props: any) => <FontAwesomeIcon icon={faUsers} {...props} />} />{" "}
									<Text fontSize="sm" color="text.700" fontWeight="semibold">
										Students
									</Text>
								</VStack>
							</Card>
						</LinkBox>

						<LinkBox m="1">
							<Card>
								<LinkOverlay as={NavLink} to="/points/reward/add/staff" />
								<VStack spacing="4">
									<Icon fontSize="xl" as={(props: any) => <FontAwesomeIcon icon={faChalkboardTeacher} {...props} />} />{" "}
									<Text fontSize="sm" color="text.700" fontWeight="semibold">
										Staff
									</Text>
								</VStack>
							</Card>
						</LinkBox>

						<LinkBox m="1">
							<Card>
								<LinkOverlay as={NavLink} to="/points/reward/add/houses" />
								<VStack spacing="4">
									<Icon fontSize="xl" as={(props: any) => <FontAwesomeIcon icon={faHouse} {...props} />} />{" "}
									<Text fontSize="sm" color="text.700" fontWeight="semibold">
										Houses
									</Text>
								</VStack>
							</Card>
						</LinkBox>

						<LinkBox m="1">
							<Card>
								<LinkOverlay as={NavLink} to="/points/reward/add/departments" />
								<VStack spacing="4">
									<Icon fontSize="xl" as={(props: any) => <FontAwesomeIcon icon={faPeopleGroup} {...props} />} />{" "}
									<Text fontSize="sm" color="text.700" fontWeight="semibold">
										Departments
									</Text>
								</VStack>
							</Card>
						</LinkBox>
					</Flex>
				</VStack>
			</HStack>
		</Container>
	)
}
