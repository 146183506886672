import { Box, Button, ButtonGroup, HStack, Icon, IconButton, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, VStack } from "@chakra-ui/react"
import { faArrowLeft, faArrowRight, faPencil } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import { BusRoute } from "../../graphql"
import { DeleteBusRouteButton } from "./DeleteBusRouteButton"

export type BusRoutesTableProps = {
	busRoutes: BusRoute[]
	prevPage: () => void
	nextPage: () => void
	hasPrevPage: boolean
	hasNextPage: boolean
	currentPage: number
	totalPages: number
}

export const BusRoutesTable: React.FC<BusRoutesTableProps> = ({ busRoutes, prevPage, nextPage, hasPrevPage, hasNextPage, currentPage, totalPages }) => {
	return (
		<VStack w="full" spacing="0">
			<HStack alignSelf="flex-end">
				<Text fontSize="xs" color="text.400">
					{currentPage}/{totalPages}
				</Text>
				<ButtonGroup size="xs" p="4">
					<IconButton aria-label="prev-btn" onClick={prevPage} isDisabled={!hasPrevPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowLeft} {...props} />} color="text.500" />
					</IconButton>
					<IconButton aria-label="next-btn" onClick={nextPage} isDisabled={!hasNextPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} color="text.500" />
					</IconButton>
				</ButtonGroup>
			</HStack>
			<Box w="full" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th textTransform="capitalize">Route Name</Th>
							<Th textTransform="capitalize">Vehicle No</Th>
							<Th textTransform="capitalize">Driver Name</Th>
							<Th textTransform="capitalize">Driver Phone</Th>
							<Th textTransform="capitalize">Conductor Name</Th>
							<Th textTransform="capitalize">Conductor Phone</Th>
							<Th textTransform="capitalize">Created</Th>
							<Th textTransform="capitalize">Edit</Th>
							<Th textTransform="capitalize">Delete</Th>
						</Tr>
					</Thead>
					<Tbody>
						{busRoutes.map((busRoute, index) => (
							<Tr key={index} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td>{busRoute.routeName}</Td>
								<Td>{busRoute.vehicleNo}</Td>
								<Td>{busRoute.busDriverName}</Td>
								<Td>{busRoute.busDriverPhone}</Td>
								<Td>{busRoute.busConductorName}</Td>
								<Td>{busRoute.busConductorPhone}</Td>
								<Td>{format(new Date(busRoute.createdAt), "PP")}</Td>
								<Td>
									<Tooltip label="Edit" fontSize="sm">
										<Button
											as={NavLink}
											to={`${busRoute._id}/edit`}
											colorScheme="blackAlpha"
											variant="ghost"
											size="sm"
											py="0"
											m="0"
											rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faPencil} {...props} />} />}
										></Button>
									</Tooltip>
								</Td>
								<Td>
									<DeleteBusRouteButton busRoute={busRoute} />
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
		</VStack>
	)
}
