import { Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useNavigate } from "react-router-dom"
import { SchoolTimingFragment, useDeleteSchoolTimingMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type DeleteSchoolTimingButtonProps = {
	schoolTiming: SchoolTimingFragment
}

export const DeleteSchoolTimingButton: React.FC<DeleteSchoolTimingButtonProps> = ({ schoolTiming }) => {
	const [{ fetching }, deleteSchoolTiming] = useDeleteSchoolTimingMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	const handleDeleteSchoolTiming = async () => {
		const { error } = await deleteSchoolTiming({ schoolTimingId: schoolTiming._id })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		onClose()
		return navigate("/schoolTimings", { replace: true })
	}

	return (
		<>
			<Tooltip label={`Delete school timing ${schoolTiming.name}`} fontSize="sm">
				<IconButton aria-label="remove-section-btn" variant="ghost" colorScheme="blackAlpha" size="sm" {...getButtonProps()}>
					<Icon as={(props: any) => <FontAwesomeIcon icon={faTrash} {...props} />} />
				</IconButton>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Delete School Timing"
				message="Deleting this school timing may affect students or class section associated with this timing."
				confirmLabel="Delete"
				onConfirm={handleDeleteSchoolTiming}
				isLoading={fetching}
			/>
		</>
	)
}
