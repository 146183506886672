import {
	Button,
	ButtonGroup,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	HStack,
	Icon,
	Input,
	SlideFade,
	Text,
	Textarea,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React, { useMemo } from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { CreateShowcaseMutationVariables, MediaTypes, useCreateShowcaseMutation } from "../../graphql"
import { useUpload } from "../../hooks"
import { UploadMediaButton } from "../upload"
import { ShowcaseAttachments } from "./ShowcaseAttachments"

const validationSchema = Yup.object({
	title: Yup.string().label("Title"),
	description: Yup.string().label("Description"),
})

export const NewShowcaseForm: React.FC = () => {
	const [{ fetching }, createNew] = useCreateShowcaseMutation()

	const toast = useToast()

	const { clearUpload, clearAllUploads, uploads } = useUpload()
	const navigate = useNavigate()

	const handleSubmit = async (
		{ title, description }: CreateShowcaseMutationVariables["input"],
		helpers: FormikHelpers<CreateShowcaseMutationVariables["input"]>
	) => {
		const attachments: CreateShowcaseMutationVariables["input"]["attachments"] = {}

		if (uploads?.length) {
			if (uploads[0].type === MediaTypes.Image) {
				attachments.pictures = uploads
					.filter(({ type, key, url, isUploaded }) => isUploaded && type === MediaTypes.Image && typeof key === "string" && typeof url === "string")
					.map(({ key, url }) => ({ key: key!, url: url! }))
			}

			if (uploads[0].type === MediaTypes.Video) {
				attachments.videos = uploads
					.filter(({ type, key, url, isUploaded }) => isUploaded && type === MediaTypes.Video && typeof key === "string" && typeof url === "string")
					.map(({ key, url }) => ({ key: key!, url: url! }))
			}

			if (uploads[0].type === MediaTypes.Pdf) {
				attachments.pdfs = uploads
					.filter(({ type, key, url, isUploaded }) => isUploaded && type === MediaTypes.Pdf && typeof key === "string" && typeof url === "string")
					.map(({ key, url }) => ({ key: key!, url: url! }))
			}
		}

		const { error, data } = await createNew({ input: { title, description, attachments } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createShowcase.errors) {
			return data?.createShowcase.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		clearAllUploads()
		navigate(`/showcases/${data?.createShowcase.showcase?._id}`)
	}

	const formik = useFormik<CreateShowcaseMutationVariables["input"]>({
		initialValues: { title: "", description: "" },
		validationSchema,
		onSubmit: handleSubmit,
	})

	const allowedMediaTypes = useMemo(() => {
		if (uploads.length > 0) {
			return [uploads[0].type]
		}

		return [MediaTypes.Image, MediaTypes.Pdf, MediaTypes.Video]
	}, [uploads])

	return (
		<VStack w="full" maxW="md" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.title && formik.touched.title)}>
				<SlideFade in={Boolean(formik.values.title)} unmountOnExit>
					<FormLabel htmlFor="title" fontSize="sm">
						Title
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Title"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("title")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.title}</Text>
				</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={Boolean(formik.errors.description && formik.touched.description)}>
				<SlideFade in={Boolean(formik.values.description)} unmountOnExit>
					<FormLabel htmlFor="description" fontSize="sm">
						Description
					</FormLabel>
				</SlideFade>
				<Textarea
					placeholder="Description"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("description")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.description}</Text>
				</FormErrorMessage>
			</FormControl>
			<ShowcaseAttachments onRemove={(id) => clearUpload({ id })} />
			<HStack w="full" justify="space-between">
				<ButtonGroup>
					<UploadMediaButton maxUploads={4} allowedTypes={allowedMediaTypes} />
				</ButtonGroup>
				<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
					Publish
				</Button>
			</HStack>
		</VStack>
	)
}
