import { Center, Container, Heading, HStack, Spinner, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React from "react"
import { useParams } from "react-router-dom"
import { DashboardHeader, Nav, UpdateWorkingWeekdaysOfDepartmentForm } from "../../components"
import { GRADIENT } from "../../constants"
import { useDepartmentByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const UpdateWorkingWeekdaysOfDepartment: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })
	const { departmentId = "" } = useParams<{ departmentId: string }>()

	const [{ data, fetching, error }] = useDepartmentByIdQuery({ variables: { departmentId } })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Update Working Weekdays
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								{
									// TODO: add sub heading
								}
							</Heading>
						</VStack>
					</Stack>
					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.departmentById ? (
						<UpdateWorkingWeekdaysOfDepartmentForm department={data.departmentById} />
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Could not find the department
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
