import { Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Icon, Input, VStack } from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import * as Yup from "yup"
import { useAddReaderMutation } from "../../graphql"

const validationSchema = Yup.object({
	readerDecimalId: Yup.string().length(15).required().label("Reader ID"),
})

type AddReaderFormValues = {
	readerDecimalId: string
}

const initialValues: AddReaderFormValues = {
	readerDecimalId: "",
}

export const AddReaderForm: React.FC = () => {
	const [{ fetching }, addReader] = useAddReaderMutation()

	const onSubmit = async (values: AddReaderFormValues, helpers: FormikHelpers<AddReaderFormValues>) => {
		const { error } = await addReader(values)

		if (error) {
			helpers.setFieldError("readerDecimalId", error.message.replace("[GraphQL] ", ""))
		}
	}

	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	})

	return (
		<form onSubmit={formik.handleSubmit}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.errors.readerDecimalId && formik.touched.readerDecimalId)}>
					<FormLabel>Reader ID</FormLabel>
					<Input
						border="none"
						_focus={{ border: "none" }}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						autoComplete="off"
						{...formik.getFieldProps("readerDecimalId")}
					/>
					<FormHelperText>
						Enter the reader&apos;s 15-digits <strong>SN</strong>
					</FormHelperText>
					<FormErrorMessage>{formik.errors.readerDecimalId}</FormErrorMessage>
				</FormControl>
				<Button size="sm" type="submit" colorScheme="primary" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
					Add
				</Button>
			</VStack>
		</form>
	)
}
