import { Button, FormControl, FormErrorIcon, FormErrorMessage, FormLabel, Icon, Input, SlideFade, Text, useToast, VStack } from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { CreateCafeteriaMealTypeMutationVariables, useCreateCafeteriaMealTypeMutation } from "../../../graphql"

const validationSchema = Yup.object({
	name: Yup.string().required().label("Name"),
})

export const NewCafeteriaMealTypeForm: React.FC = () => {
	const [{ fetching }, createNew] = useCreateCafeteriaMealTypeMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (
		{ name }: CreateCafeteriaMealTypeMutationVariables["input"],
		helpers: FormikHelpers<CreateCafeteriaMealTypeMutationVariables["input"]>
	) => {
		const { error, data } = await createNew({ input: { name } })

		if (error) {
			return toast({
				description: error.message,
				status: "error",
			})
		}

		if (data?.createCafeteriaMealType.errors) {
			return data?.createCafeteriaMealType.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		return navigate("/cafeteria/mealTypes", { replace: true })
	}

	const formik = useFormik<CreateCafeteriaMealTypeMutationVariables["input"]>({
		initialValues: { name: "" },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)}>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="title" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
