import {
	Button,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	Icon,
	IconButton,
	Input,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	SlideFade,
	Tag,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faCalendar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns"
import { FormikHelpers, useFormik } from "formik"
import React, { useMemo } from "react"
import { DateRange } from "react-date-range"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { CreateSchoolSessionMutationVariables, SchoolSessionFragment, useEditSchoolSessionMutation } from "../../graphql"

const validationSchema = Yup.object({
	name: Yup.string().required().label("Name"),
})

type EditSchoolSessionFormProps = {
	schoolSession: SchoolSessionFragment
}

export const EditSchoolSessionForm: React.FC<EditSchoolSessionFormProps> = ({ schoolSession }) => {
	const [{ fetching }, edit] = useEditSchoolSessionMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (
		{ name, endAt, startAt }: CreateSchoolSessionMutationVariables["input"],
		helpers: FormikHelpers<CreateSchoolSessionMutationVariables["input"]>
	) => {
		const { error, data } = await edit({ schoolSessionId: schoolSession._id, input: { name, endAt, startAt } })

		if (error) {
			return toast({
				description: error.message,
				status: "error",
			})
		}

		if (data?.editSchoolSession.errors) {
			return data?.editSchoolSession.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		return navigate("/schoolSessions", { replace: true })
	}

	const startAt = useMemo(() => {
		const date = new Date()
		date.setMonth(schoolSession.startMonth - 1)
		date.setDate(schoolSession.startDate)

		return date
	}, [schoolSession.startMonth, schoolSession.startDate])

	const endAt = useMemo(() => {
		const date = new Date()
		date.setMonth(schoolSession.endMonth - 1)
		date.setDate(schoolSession.endDate)

		return date
	}, [schoolSession.endMonth, schoolSession.endDate])

	const formik = useFormik<CreateSchoolSessionMutationVariables["input"]>({
		initialValues: { name: schoolSession.name, startAt, endAt },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)}>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="title" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>

			<Popover placement="auto-end">
				<PopoverTrigger>
					{!!formik.values.startAt && !!formik.values.endAt && (
						<Tag m="1" bg="white.500" px="4" py="2" rounded="xl" cursor="pointer">
							<Text fontSize="sm">
								{format(formik.values.startAt, "MMM dd")} - {format(formik.values.endAt, "MMM dd")}
							</Text>
							<IconButton aria-label="calendar-button" size="xs" variant="ghost" colorScheme="whiteAlpha">
								<Icon
									color="blackAlpha.600"
									_hover={{ color: "blackAlpha.700" }}
									as={(props: any) => <FontAwesomeIcon icon={faCalendar} {...props} />}
								/>
							</IconButton>
						</Tag>
					)}
				</PopoverTrigger>
				<PopoverContent w="full" _focus={{ shadow: "none" }}>
					<PopoverBody w="full" p="0">
						<DateRange
							editableDateInputs={true}
							onChange={(item) => {
								item.selection?.startDate && formik.setFieldValue("startAt", item.selection.startDate)
								item.selection?.endDate && formik.setFieldValue("endAt", item.selection.endDate)
							}}
							moveRangeOnFirstSelection={false}
							ranges={[{ startDate: formik.values.startAt, endDate: formik.values.endAt, key: "selection" }]}
						/>
					</PopoverBody>
				</PopoverContent>
			</Popover>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Save
			</Button>
		</VStack>
	)
}
