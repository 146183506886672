import { Center, Heading, HStack, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { StockReadersQueryVariables, useStockReadersQuery } from "../../graphql"
import { ReadersTable } from "./ReadersTable"

export const AllReaders: React.FC = () => {
	const [pagination, setPagination] = useState<StockReadersQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useStockReadersQuery({
		variables: { pagination },
	})

	const prevPage = () => {
		if (data?.stockReaders.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.stockReaders.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" justify="space-between">
				<Heading as="h2" fontSize={isMobile ? "sm" : "md"} fontWeight="normal" color="text.500">
					Showing {data?.stockReaders.total || "all"} readers
				</Heading>
			</HStack>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.stockReaders.readers && data?.stockReaders.readers.length > 0 ? (
				<ReadersTable
					readers={data.stockReaders.readers}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.stockReaders.hasPrevPage}
					hasNextPage={data.stockReaders.hasNextPage}
					currentPage={data.stockReaders.currentPage}
					totalPages={data.stockReaders.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any readers.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
