import { Button, Center, FormControl, FormErrorMessage, FormLabel, Icon, Select, SlideFade, Spinner, Text, useToast, VStack } from "@chakra-ui/react"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { AddClassTeacherMutationVariables, DepartmentTypes, useAddClassTeacherMutation, useStaffsWithoutPaginationQuery } from "../../graphql"

const validationSchema = Yup.object({
	teacherId: Yup.string().required().label("Teacher"),
})

export type AddClassTeacherFormProps = {
	classId: string
	classSectionId: string
}

export const AddClassTeacherForm: React.FC<AddClassTeacherFormProps> = ({ classId, classSectionId }) => {
	const [{ fetching }, addTeacher] = useAddClassTeacherMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async ({ teacherId }: Omit<AddClassTeacherMutationVariables, "classId" | "classSectionId">) => {
		const { error } = await addTeacher({ classId, classSectionId, teacherId })

		if (error) {
			return toast({
				title: "Class Error",
				description: error.message,
				status: "error",
			})
		}

		return navigate(`/classes/${classId}`, { replace: true })
	}

	const formik = useFormik<Omit<AddClassTeacherMutationVariables, "classId" | "classSectionId">>({
		initialValues: { teacherId: "" },
		validationSchema,
		onSubmit: handleSubmit,
	})

	const [{ data: staffData, fetching: fetchingStaff, error: staffError }] = useStaffsWithoutPaginationQuery({
		variables: { departmentType: DepartmentTypes.Teaching },
	})

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			{fetchingStaff ? (
				<Center w="full" py="1">
					<VStack>
						<Spinner size="sm" color="text.400" />
						<Text fontSize="xs" color="text.400">
							Loading staff
						</Text>
					</VStack>
				</Center>
			) : staffError ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							{staffError.message.replace("[GraphQL] ", "")}
						</Text>
					</VStack>
				</Center>
			) : (
				<FormControl isInvalid={Boolean(formik.errors.teacherId && formik.touched.teacherId)}>
					<SlideFade in={Boolean(formik.values.teacherId)} unmountOnExit>
						<FormLabel htmlFor="teacherId" fontSize="sm">
							Class Teacher
						</FormLabel>
					</SlideFade>
					<Select
						placeholder="Select teacher"
						border="none"
						_focus={{
							border: "none",
						}}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						autoComplete="off"
						{...formik.getFieldProps("teacherId")}
					>
						{staffData?.staffsWithoutPagination.map(({ _id, name }) => (
							<option key={_id} value={_id}>
								{name}
							</option>
						))}
					</Select>
					<FormErrorMessage>{formik.errors.teacherId}</FormErrorMessage>
				</FormControl>
			)}

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Add
			</Button>
		</VStack>
	)
}
