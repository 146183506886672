import { DrawerBody, DrawerHeader, Flex, Heading, HStack, Icon, IconButton, VStack } from "@chakra-ui/react"
import { faShare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TelegramIcon,
	TelegramShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from "react-share"
import { BottomActionSheet, BottomActionSheetProps } from "../common"

export type SharePostActionSheetProps = {
	title: string
	text?: string
	url: string
} & Omit<BottomActionSheetProps, "children">

export const SharePostActionSheet: React.FC<SharePostActionSheetProps> = ({ title, text, url, ...props }) => {
	return (
		<BottomActionSheet {...props}>
			<DrawerHeader fontSize="sm" py="0" px="4">
				<HStack color="blackAlpha.600">
					<Heading fontSize="sm" fontWeight="semibold">
						Share this post
					</Heading>
					<Icon fontSize="xs" as={(props: any) => <FontAwesomeIcon icon={faShare} {...props} />} />
				</HStack>
			</DrawerHeader>
			<DrawerBody as={VStack} spacing={4} px="0" align="stretch">
				<Flex flexWrap="wrap" justify="center" px={2}>
					<FacebookShareButton url={url} quote={title + "\n" + (text ?? "")}>
						<IconButton m={4} size="sm" variant="ghost" colorScheme="blackAlpha" aria-label="facebook-share">
							<Icon as={FacebookIcon} fontSize="3xl" />
						</IconButton>
					</FacebookShareButton>
					<TwitterShareButton url={url} title={title + "\n" + (text ?? "")}>
						<IconButton m={4} size="sm" variant="ghost" colorScheme="blackAlpha" aria-label="twitter-share">
							<Icon as={TwitterIcon} fontSize="3xl" />
						</IconButton>
					</TwitterShareButton>
					<TelegramShareButton url={url} title={title + "\n" + (text ?? "")}>
						<IconButton m={4} size="sm" variant="ghost" colorScheme="blackAlpha" aria-label="telegram-share">
							<Icon as={TelegramIcon} fontSize="3xl" />
						</IconButton>
					</TelegramShareButton>
					<WhatsappShareButton url={url} title={title + "\n" + (text ?? "")} separator=" - ">
						<IconButton m={4} size="sm" variant="ghost" colorScheme="blackAlpha" aria-label="whatsapp-share">
							<Icon as={WhatsappIcon} fontSize="3xl" />
						</IconButton>
					</WhatsappShareButton>
					<LinkedinShareButton url={url} title={title} summary={text} source="Learntank">
						<IconButton m={4} size="sm" variant="ghost" colorScheme="blackAlpha" aria-label="linkedin-share">
							<Icon as={LinkedinIcon} fontSize="3xl" />
						</IconButton>
					</LinkedinShareButton>
					<EmailShareButton url={url} subject={title} body={title + "\n" + (text ?? "")} separator=" - ">
						<IconButton m={4} size="sm" variant="ghost" colorScheme="blackAlpha" aria-label="email-share">
							<Icon as={EmailIcon} fontSize="3xl" />
						</IconButton>
					</EmailShareButton>
				</Flex>
			</DrawerBody>
		</BottomActionSheet>
	)
}
