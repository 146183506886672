import {
	Button,
	Checkbox,
	Heading,
	HStack,
	Icon,
	IconButton,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import DateTimePicker from "react-datetime-picker"
import { useMarkManualInMutation } from "../../graphql"

export type MarkManualInProps = {
	studentId: string
}

export const MarkManualIn: React.FC<MarkManualInProps> = ({ studentId }) => {
	const [date, setDate] = useState(new Date())
	const [shouldNotifyIn, setShouldNotifyIn] = useState(true)

	const [{ fetching }, markManualIn] = useMarkManualInMutation()

	const toast = useToast()

	const handleSave = async () => {
		const { error } = await markManualIn({ studentId, inAt: date, shouldNotifyIn })

		if (error) {
			return toast({ description: error.message.replace("[GraphQL ]", ""), status: "error" })
		}

		toast({ description: "Marked manual entry", status: "success" })
	}

	return (
		<Popover isLazy lazyBehavior="unmount">
			<PopoverTrigger>
				<IconButton aria-label="mark-entry-manually-btn" variant="ghost" colorScheme="purple" size="sm">
					<Icon as={(props: any) => <FontAwesomeIcon icon={faEdit} {...props} />} />
				</IconButton>
			</PopoverTrigger>
			<PopoverContent bgColor="white">
				<PopoverArrow bgColor="white" />
				<PopoverBody>
					<VStack w="full" align="stretch" spacing={6}>
						<Heading fontSize="sm">Mark manual entry</Heading>
						<DateTimePicker disableClock onChange={setDate} value={date} />
						<HStack>
							<Text fontSize="sm">Should Notify?</Text>
							<Checkbox isChecked={shouldNotifyIn} onChange={(e) => setShouldNotifyIn(e.target.checked)} />
						</HStack>
						<Button colorScheme="purple" size="sm" isLoading={fetching} onClick={handleSave}>
							Save
						</Button>
					</VStack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
