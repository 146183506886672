import { Button, Center, FormControl, FormErrorMessage, FormLabel, Heading, Icon, Select, SlideFade, Spinner, Text, useToast, VStack } from "@chakra-ui/react"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import * as Yup from "yup"
import {
	UpdateSchoolTimingOfStudentMutationVariables,
	ExtendedStudentFragment,
	useUpdateSchoolTimingOfStudentMutation,
	useSchoolTimingsQuery,
} from "../../graphql"

const validationSchema = Yup.object({
	schoolTimingId: Yup.string().label("School Timing"),
})

export type EditStudentSchoolTimingFormProps = {
	student: ExtendedStudentFragment
}

export const EditStudentSchoolTimingForm: React.FC<EditStudentSchoolTimingFormProps> = ({ student }) => {
	const [{ fetching }, editStudentSchoolTiming] = useUpdateSchoolTimingOfStudentMutation()

	const toast = useToast()

	const onSubmit = async ({ schoolTimingId }: Omit<UpdateSchoolTimingOfStudentMutationVariables, "studentId">) => {
		const { error, data } = await editStudentSchoolTiming({ studentId: student._id, schoolTimingId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		if (!data?.updateSchoolTimingOfStudent) {
			return toast({
				description: "Could not edit school timing of the student",
				status: "error",
			})
		}

		toast({ description: "Updated student's school timing", status: "success" })
	}

	const formik = useFormik<Omit<UpdateSchoolTimingOfStudentMutationVariables, "studentId">>({
		initialValues: { schoolTimingId: student.schoolTimingId || "" },
		onSubmit,
		validationSchema,
	})

	const [{ data: schoolTimingsData, fetching: fetchingSchoolTimings, error: schoolTimingsError }] = useSchoolTimingsQuery()

	return (
		<form onSubmit={formik.handleSubmit}>
			<VStack w="full" align="stretch">
				<Heading fontSize="sm">Update School Timing</Heading>
				{fetchingSchoolTimings ? (
					<Center w="full" py="1">
						<VStack>
							<Spinner size="sm" color="text.400" />
							<Text fontSize="xs" color="text.400">
								Loading schol timings
							</Text>
						</VStack>
					</Center>
				) : schoolTimingsError ? (
					<Center w="full" py="1">
						<VStack>
							<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
							<Text fontSize="xs" color="text.400">
								{schoolTimingsError.message.replace("[GraphQL] ", "")}
							</Text>
						</VStack>
					</Center>
				) : !schoolTimingsData?.schoolTimings ? (
					<Center w="full" py="1">
						<VStack>
							<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
							<Text fontSize="xs" color="text.400">
								Could not load school timings
							</Text>
						</VStack>
					</Center>
				) : (
					<FormControl isInvalid={Boolean(formik.errors.schoolTimingId && formik.touched.schoolTimingId)}>
						<SlideFade in={Boolean(formik.values.schoolTimingId)} unmountOnExit>
							<FormLabel htmlFor="schoolTimingId" fontSize="sm">
								School Timing
							</FormLabel>
						</SlideFade>
						<Select
							w="full"
							bg="white.500"
							rounded="xl"
							border="none"
							_focus={{ border: "none" }}
							placeholder="Select School Timing"
							{...formik.getFieldProps("schoolTimingId")}
						>
							{schoolTimingsData.schoolTimings.map((schoolTiming) => (
								<option key={schoolTiming._id} value={schoolTiming._id}>
									{schoolTiming.name} ({schoolTiming.attendanceTiming.inAt} - {schoolTiming.attendanceTiming.outAt})
								</option>
							))}
						</Select>
						<FormErrorMessage>{formik.errors.schoolTimingId}</FormErrorMessage>
					</FormControl>
				)}

				<Button size="sm" type="submit" colorScheme="primary" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
					Update
				</Button>
			</VStack>
		</form>
	)
}
