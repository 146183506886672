import { Avatar, Button, Divider, Icon, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text, useToast, VStack } from "@chakra-ui/react"
import {
	faArrowRightFromBracket,
	faBook,
	faBowlFood,
	faBoxesStacked,
	faChalkboard,
	faCoins,
	faHouse,
	faLock,
	faMoneyBill,
	faPeopleGroup,
	faSchoolLock,
	faTrophy,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { useLogoutMutation, useMeQuery } from "../../graphql"

export const AccountMenu: React.FC<any> = ({ children }) => {
	const [{ data }] = useMeQuery()

	const [{ fetching }, logout] = useLogoutMutation()
	const toast = useToast()

	const navigate = useNavigate()

	const push = (path: string) => () => {
		navigate(path)
	}

	const handleLogout = async () => {
		const { error } = await logout()

		if (error) {
			return toast({
				title: "Logout Error",
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		return navigate("/", { replace: true })
	}

	return (
		<Popover isLazy placement="bottom-end">
			<PopoverTrigger>{children}</PopoverTrigger>
			<PopoverContent bg="whitesmoke" shadow="2xl" _focus={{ shadow: "2xl", outline: "none" }}>
				<PopoverBody p="0">
					<VStack w="full" fontSize="sm" py="4">
						<Avatar src={data?.me?.picture?.url} name={data?.me?.name} size="lg" />

						{data?.me && (
							<VStack>
								<Text>Signed in as</Text>
								<Button variant="link" onClick={push("/dashboard")}>
									{data.me.name}
								</Button>
							</VStack>
						)}

						<VStack w="full" align="stretch">
							<Button
								as={NavLink}
								to="/classes"
								w="full"
								variant="ghost"
								colorScheme="primary"
								size="sm"
								rounded="none"
								leftIcon={<Icon fontSize="lg" as={(props: any) => <FontAwesomeIcon icon={faChalkboard} {...props} />} />}
							>
								<Text w="full" textAlign="left" fontWeight="normal">
									Classes
								</Text>
							</Button>
							<Button
								as={NavLink}
								to="/subjects"
								w="full"
								variant="ghost"
								colorScheme="primary"
								size="sm"
								rounded="none"
								leftIcon={<Icon fontSize="lg" as={(props: any) => <FontAwesomeIcon icon={faBook} {...props} />} />}
							>
								<Text w="full" textAlign="left" fontWeight="normal">
									Subjects
								</Text>
							</Button>
							<Button
								as={NavLink}
								to="/houses"
								w="full"
								variant="ghost"
								colorScheme="primary"
								size="sm"
								rounded="none"
								leftIcon={<Icon fontSize="lg" as={(props: any) => <FontAwesomeIcon icon={faHouse} {...props} />} />}
							>
								<Text w="full" textAlign="left" fontWeight="normal">
									Houses
								</Text>
							</Button>
							<Button
								as={NavLink}
								to="/departments"
								w="full"
								variant="ghost"
								colorScheme="primary"
								size="sm"
								rounded="none"
								leftIcon={<Icon fontSize="lg" as={(props: any) => <FontAwesomeIcon icon={faPeopleGroup} {...props} />} />}
							>
								<Text w="full" textAlign="left" fontWeight="normal">
									Departments
								</Text>
							</Button>

							<Button
								as={NavLink}
								to="/paytank"
								w="full"
								variant="ghost"
								colorScheme="primary"
								size="sm"
								rounded="none"
								leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faMoneyBill} {...props} />} />}
							>
								<Text w="full" textAlign="left" fontWeight="normal">
									Paytank
								</Text>
							</Button>

							<Button
								as={NavLink}
								to="/cafeteria"
								w="full"
								variant="ghost"
								colorScheme="primary"
								size="sm"
								rounded="none"
								leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faBowlFood} {...props} />} />}
							>
								<Text w="full" textAlign="left" fontWeight="normal">
									Cafeteria
								</Text>
							</Button>

							<Button
								as={NavLink}
								to="/restrictedZones"
								w="full"
								variant="ghost"
								colorScheme="primary"
								size="sm"
								rounded="none"
								leftIcon={<Icon fontSize="lg" as={(props: any) => <FontAwesomeIcon icon={faSchoolLock} {...props} />} />}
							>
								<Text w="full" textAlign="left" fontWeight="normal">
									Restricted Zones
								</Text>
							</Button>

							<Button
								as={NavLink}
								to="/points"
								w="full"
								variant="ghost"
								colorScheme="primary"
								size="sm"
								rounded="none"
								leftIcon={<Icon fontSize="lg" as={(props: any) => <FontAwesomeIcon icon={faCoins} {...props} />} />}
							>
								<Text w="full" textAlign="left" fontWeight="normal">
									Points
								</Text>
							</Button>

							<Button
								as={NavLink}
								to="/showcases"
								w="full"
								variant="ghost"
								colorScheme="primary"
								size="sm"
								rounded="none"
								leftIcon={<Icon fontSize="lg" as={(props: any) => <FontAwesomeIcon icon={faTrophy} {...props} />} />}
							>
								<Text w="full" textAlign="left" fontWeight="normal">
									Showcases
								</Text>
							</Button>
							<Button
								as={NavLink}
								to="/inventory"
								w="full"
								variant="ghost"
								colorScheme="primary"
								size="sm"
								rounded="none"
								leftIcon={<Icon fontSize="lg" as={(props: any) => <FontAwesomeIcon icon={faBoxesStacked} {...props} />} />}
							>
								<Text w="full" textAlign="left" fontWeight="normal">
									Inventory
								</Text>
							</Button>
							<Divider />
							<Button
								as={NavLink}
								to="/changePassword"
								w="full"
								variant="ghost"
								colorScheme="primary"
								size="sm"
								rounded="none"
								leftIcon={<Icon fontSize="lg" as={(props: any) => <FontAwesomeIcon icon={faLock} {...props} />} />}
							>
								<Text w="full" textAlign="left" fontWeight="normal">
									Change Password
								</Text>
							</Button>
							<Button
								w="full"
								variant="ghost"
								colorScheme="primary"
								size="sm"
								rounded="none"
								leftIcon={<Icon fontSize="lg" as={(props: any) => <FontAwesomeIcon icon={faArrowRightFromBracket} {...props} />} />}
								onClick={handleLogout}
								isLoading={fetching}
							>
								<Text w="full" textAlign="left" fontWeight="normal">
									Log Out
								</Text>
							</Button>
						</VStack>
					</VStack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
