import {
	Button,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	Icon,
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	SlideFade,
	Text,
	Textarea,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { EditPointsRedeemItemMutationVariables, PointsRedeemItemFragment, useEditPointsRedeemItemMutation } from "../../graphql"

const validationSchema = Yup.object({
	name: Yup.string().required().label("Name"),
	description: Yup.string().required().label("Description"),
	points: Yup.number().min(1).required().label("Points"),
})

export type EditPointsRedeemItemFormProps = {
	pointsRedeemItem: PointsRedeemItemFragment
}

export const EditPointsRedeemItemForm: React.FC<EditPointsRedeemItemFormProps> = ({ pointsRedeemItem }) => {
	const [{ fetching }, edit] = useEditPointsRedeemItemMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (
		{ name, description, points }: EditPointsRedeemItemMutationVariables["input"],
		helpers: FormikHelpers<EditPointsRedeemItemMutationVariables["input"]>
	) => {
		const { error, data } = await edit({
			pointsRedeemItemId: pointsRedeemItem._id,
			input: { name, description, points },
		})

		if (error) {
			return toast({
				description: error.message,
				status: "error",
			})
		}

		if (data?.editPointsRedeemItem.errors) {
			return data?.editPointsRedeemItem.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		return navigate("/points/redeem/items", { replace: true })
	}

	const formik = useFormik<EditPointsRedeemItemMutationVariables["input"]>({
		initialValues: { name: pointsRedeemItem.name, description: pointsRedeemItem.description, points: pointsRedeemItem.points },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)}>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="name" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={Boolean(formik.errors.description && formik.touched.description)}>
				<SlideFade in={Boolean(formik.values.description)} unmountOnExit>
					<FormLabel htmlFor="description" fontSize="sm">
						Description
					</FormLabel>
				</SlideFade>
				<Textarea
					placeholder="Description"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("description")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.description}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.points && formik.touched.points)}>
				<FormLabel>Points</FormLabel>
				<NumberInput
					min={1}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("points")}
					onChange={(valueStr) => formik.setFieldValue("points", Number(valueStr))}
				>
					<NumberInputField />
					<NumberInputStepper>
						<NumberIncrementStepper />
						<NumberDecrementStepper />
					</NumberInputStepper>
				</NumberInput>
				<FormErrorMessage>{formik.errors.points}</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Save
			</Button>
		</VStack>
	)
}
