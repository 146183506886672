import { Avatar, AvatarGroup, Button, Heading, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack } from "@chakra-ui/react"
import React, { useMemo, useState } from "react"
import { PenaltyItemFragment, StaffFragment, usePenaliseStaffMutation } from "../../graphql"
import { PenaltyItemsSelectorForStaff } from "./PenaltyItemsSelectorForStaff"

export type PenaliseStaffModalProps = {
	isOpen: boolean
	onClose: () => void
	staffs: StaffFragment[]
}

export const PenaliseStaffModal: React.FC<PenaliseStaffModalProps> = ({ isOpen, onClose, staffs }) => {
	const [selectedItems, setSelectedItems] = useState<PenaltyItemFragment[]>([])

	const totalPoints = useMemo(() => selectedItems.map((i) => i.points).reduce((a, b) => a + b, 0), [selectedItems])

	const [{ fetching: penalising, error: penaltyError, data: penaltyData }, penalty] = usePenaliseStaffMutation()

	const handlePenalty = async () => {
		await penalty({ staffIds: staffs.map((s) => s._id), penaltyItemIds: selectedItems.map((i) => i._id) })
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered scrollBehavior="inside">
			<ModalOverlay />
			{penaltyData?.penaliseStaff ? (
				<ModalContent bgColor="white.400">
					<ModalBody>
						<VStack alignItems="center" py="6">
							<Heading fontSize="xl">Penalised</Heading>
							<Heading fontSize="3xl" color="red.500" textAlign="center">
								-{penaltyData.penaliseStaff.totalPoints} points
							</Heading>

							<AvatarGroup max={3} gap={2}>
								{penaltyData.penaliseStaff.to.map(
									({ staff }) => staff && <Avatar rounded="2xl" key={staff._id} src={staff.picture?.url} name={staff.name} />
								)}
							</AvatarGroup>
							<Text fontWeight="semibold" color="blackAlpha.600">
								for
							</Text>
							<Heading fontSize="xl" color="purple.500" textAlign="center">
								{penaltyData.penaliseStaff.penaltyItems.map((i) => i.name).join(", ")}
							</Heading>
						</VStack>
					</ModalBody>
				</ModalContent>
			) : (
				<ModalContent bgColor="white.400">
					<ModalHeader>
						<VStack alignItems="center" py="2">
							<AvatarGroup max={3} gap={2}>
								{staffs.map((staff) => (
									<Avatar rounded="2xl" key={staff._id} src={staff.picture?.url} name={staff.name} />
								))}
							</AvatarGroup>
							<Heading fontSize="2xl" color="red.500">
								-{totalPoints} points
							</Heading>
						</VStack>
					</ModalHeader>
					<ModalBody>
						<VStack alignItems="center">
							<VStack w="full" align="stretch">
								<PenaltyItemsSelectorForStaff onChange={setSelectedItems} />
								{penaltyError ? (
									<Text fontSize="sm" color="red.500" textAlign="center">
										{penaltyError.message.replace("[GraphQL] ", "")}
									</Text>
								) : (
									penaltyData &&
									!penaltyData.penaliseStaff && (
										<Text fontSize="sm" color="red.500" textAlign="center">
											Some error occurred
										</Text>
									)
								)}
							</VStack>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<Button w="full" colorScheme="primary" onClick={handlePenalty} isLoading={penalising} isDisabled={!selectedItems.length}>
							Penalise
						</Button>
					</ModalFooter>
				</ModalContent>
			)}
		</Modal>
	)
}
