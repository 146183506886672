import {
	AspectRatio,
	AspectRatioProps,
	Box,
	BoxProps,
	Grid,
	Heading,
	HStack,
	Icon,
	IconButton,
	LinkBox,
	LinkOverlay,
	Spinner,
	Stack,
	Text,
	VStack,
} from "@chakra-ui/react"
import { faArrowRight, faExclamationCircle, faExternalLink } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion, MotionProps } from "framer-motion"
import React, { ComponentType, useState } from "react"
import { NavLink } from "react-router-dom"
import { useStaffAttendanceStatsQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

const MotionAspectRatio = motion<MotionProps & Omit<AspectRatioProps, "transition">>(
	AspectRatio as unknown as ComponentType<MotionProps & Omit<AspectRatioProps, "transition">>
)

const MotionBox = motion<MotionProps & Omit<AspectRatioProps, "transition">>(Box as unknown as ComponentType<MotionProps & Omit<BoxProps, "transition">>)

export type TodayStaffStatsProps = {
	date: Date
}

export const TodayStaffStats: React.FC<TodayStaffStatsProps> = ({ date }) => {
	const [{ data, fetching, error }] = useStaffAttendanceStatsQuery({ variables: { date } })

	const [isTotalHovered, setIsTotalHovered] = useState(false)
	const [isPresentHovered, setIsPresentHovered] = useState(false)
	const [isAbsentHovered, setIsAbsentHovered] = useState(false)

	const { isShown } = useDrawer()

	return (
		<VStack w="full" align="stretch">
			<HStack>
				<Heading fontSize="md" fontWeight="semibold">
					Today
				</Heading>
				<Icon fontSize="md" as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} />
			</HStack>
			<Stack direction={{ base: "column", [isShown ? "lg" : "md"]: "row" }} w="full" align={{ base: "center", [isShown ? "lg" : "md"]: "stretch" }}>
				{fetching ? (
					<VStack flex={8} w="full" maxW="2xl">
						<Spinner />
						<Text textAlign="center">Loading today&apos;s attendance</Text>
					</VStack>
				) : error ? (
					<VStack flex={8} w="full" maxW="2xl" color="red.800">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />} />
						<Text textAlign="center">{error?.message.replace("[GraphQL] ", "")}</Text>
					</VStack>
				) : data?.staffAttendanceStats ? (
					<VStack flex={8} w="full" maxW="2xl" align="stretch">
						<Grid w="full" h="full" templateColumns={{ base: "repeat(3, 1fr)" }} gap={2}>
							<MotionAspectRatio
								w="full"
								minW="28"
								maxW="52"
								ratio={1}
								initial={{ opacity: 0, translateY: "24px" }}
								animate={{ opacity: 1, translateY: 0 }}
							>
								<VStack as={LinkBox} w="full" bgColor="blackAlpha.200" backdropFilter="blur(16px)" rounded="xl">
									<LinkOverlay
										as={NavLink}
										to="/staff"
										onMouseOver={() => setIsTotalHovered(true)}
										onMouseOut={() => setIsTotalHovered(false)}
									/>
									<HStack>
										<Text fontSize="sm">Total</Text>
										<IconButton
											aria-label="see-total-staff-btn"
											size="xs"
											variant="unstyled"
											transform={isTotalHovered ? "translate(4px, -4px) scale(1.1)" : ""}
										>
											<Icon as={(props: any) => <FontAwesomeIcon icon={faExternalLink} {...props} />} />
										</IconButton>
									</HStack>
									<Heading fontSize="3xl" textAlign="center">
										{data.staffAttendanceStats.total}
									</Heading>
								</VStack>
							</MotionAspectRatio>

							<MotionAspectRatio
								w="full"
								minW="28"
								maxW="52"
								ratio={1}
								initial={{ opacity: 0, translateY: "24px" }}
								animate={{ opacity: 1, translateY: 0 }}
								transition={{ delay: 0.1 }}
							>
								<Box as={LinkBox} pos="relative" w="full" bgColor="blackAlpha.200" backdropFilter="blur(16px)" rounded="xl">
									<LinkOverlay
										as={NavLink}
										to="/attendance/staff/present"
										onMouseOver={() => setIsPresentHovered(true)}
										onMouseOut={() => setIsPresentHovered(false)}
									/>
									<MotionBox
										pos="absolute"
										top="100%"
										animate={{
											top: `${(data.staffAttendanceStats.absent.length / data.staffAttendanceStats.total) * 100}%`,
										}}
										transition={{ delay: 0.4, duration: 0.4 }}
										bottom="0"
										left="0"
										right="0"
										bgColor="green"
										opacity={0.1}
										zIndex={-1}
									/>
									<VStack>
										<HStack color="green.800">
											<Text fontSize="sm">Present</Text>
											<IconButton
												aria-label="see-today-attendances-btn"
												size="xs"
												variant="unstyled"
												transform={isPresentHovered ? "translate(4px, -4px) scale(1.1)" : ""}
											>
												<Icon as={(props: any) => <FontAwesomeIcon icon={faExternalLink} {...props} />} />
											</IconButton>
										</HStack>
										<Heading fontSize="3xl" textAlign="center">
											{data.staffAttendanceStats.present.length}
										</Heading>
									</VStack>
								</Box>
							</MotionAspectRatio>

							<MotionAspectRatio
								w="full"
								minW="28"
								maxW="52"
								ratio={1}
								initial={{ opacity: 0, translateY: "24px" }}
								animate={{ opacity: 1, translateY: 0 }}
								transition={{ delay: 0.2 }}
							>
								<Box as={LinkBox} w="full" bgColor="blackAlpha.200" backdropFilter="blur(16px)" rounded="xl">
									<LinkOverlay
										as={NavLink}
										to="/attendance/staff/absent"
										onMouseOver={() => setIsAbsentHovered(true)}
										onMouseOut={() => setIsAbsentHovered(false)}
									/>
									<MotionBox
										pos="absolute"
										top="0%"
										animate={{
											top: `${
												data.staffAttendanceStats.absent.length
													? 100 - (data.staffAttendanceStats.absent.length / data.staffAttendanceStats.total) * 100
													: 100
											}%`,
										}}
										bottom="0"
										transition={{ delay: 0.5, duration: 0.4 }}
										left="0"
										right="0"
										bgColor="red"
										opacity={0.1}
										zIndex={-1}
									/>
									<VStack>
										<HStack color="red.800">
											<Text fontSize="sm">Absent</Text>
											<IconButton
												aria-label="see-today-attendances-btn"
												size="xs"
												variant="unstyled"
												transform={isAbsentHovered ? "translate(4px, -4px) scale(1.1)" : ""}
											>
												<Icon as={(props: any) => <FontAwesomeIcon icon={faExternalLink} {...props} />} />
											</IconButton>
										</HStack>
										<Heading fontSize="3xl" textAlign="center">
											{data.staffAttendanceStats.absent.length}
										</Heading>
									</VStack>
								</Box>
							</MotionAspectRatio>
						</Grid>
					</VStack>
				) : (
					<VStack flex={8} w="full" maxW="2xl" color="red.800">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />} />
						<Text textAlign="center">Today&apos;s attendance not available</Text>
					</VStack>
				)}
			</Stack>
		</VStack>
	)
}
