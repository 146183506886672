import {
	Button,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	HStack,
	Icon,
	Input,
	SlideFade,
	Text,
	Textarea,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { EditGalleryMutationVariables, Gallery, useEditGalleryMutation } from "../../graphql"

const validationSchema = Yup.object({
	title: Yup.string().label("Title"),
	description: Yup.string().label("Description"),
})

export type EditGalleryFormProps = { gallery: Gallery }

export const EditGalleryForm: React.FC<EditGalleryFormProps> = ({ gallery }) => {
	const [{ fetching }, editGallery] = useEditGalleryMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (values: EditGalleryMutationVariables["input"], helpers: FormikHelpers<EditGalleryMutationVariables["input"]>) => {
		const { error, data } = await editGallery({ galleryId: gallery._id, input: { ...values } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		if (data?.editGallery.errors?.length) {
			return data?.editGallery.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		if (!data?.editGallery.gallery) {
			return toast({
				description: "Could not edit the gallery",
				status: "error",
			})
		}
		return navigate("/gallery/" + data.editGallery.gallery._id, { replace: true })
	}

	const formik = useFormik<EditGalleryMutationVariables["input"]>({
		initialValues: {
			title: gallery.title,
			description: gallery.description,
		},
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" maxW="md" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.title && formik.touched.title)}>
				<SlideFade in={Boolean(formik.values.title)} unmountOnExit>
					<FormLabel htmlFor="name" fontSize="sm">
						Title
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Title"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("title")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.title}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.description && formik.touched.description)}>
				<SlideFade in={Boolean(formik.values.description)} unmountOnExit>
					<FormLabel htmlFor="description" fontSize="sm">
						Description
					</FormLabel>
				</SlideFade>
				<Textarea
					placeholder="Description"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("description")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.description}</Text>
				</FormErrorMessage>
			</FormControl>

			<VStack w="full" maxW="lg" align="flex-start" rounded="xl" py={2} px={4} spacing={0}>
				<HStack w="full" justify="space-between">
					<Button
						type="submit"
						colorScheme="primary"
						size="md"
						rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} />}
						isLoading={fetching}
					>
						Save
					</Button>
				</HStack>
			</VStack>
		</VStack>
	)
}
