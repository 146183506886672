import {
	Button,
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	HStack,
	Icon,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import TimePicker from "react-time-picker"
import * as Yup from "yup"
import { DepartmentFragment, StaffAttendanceTimingInput, useUpdateAttendanceTimingOfDepartmentMutation } from "../../graphql"

const validationSchema = Yup.object({
	inAt: Yup.string().required().label("Arrival Time"),
	inStartBeforeMinutes: Yup.number()
		.min(0)
		.max(12 * 60)
		.required()
		.label("Arrival Start Buffer"),
	outAt: Yup.string().required().label("Departure Time"),
	outStartBeforeMinutes: Yup.number()
		.min(0)
		.max(12 * 60)
		.required()
		.label("Departure Start Buffer"),
})

export type UpdateAttendanceTimingForDepartmentFormProps = {
	department: DepartmentFragment
}

export const UpdateAttendanceTimingForDepartmentForm: React.FC<UpdateAttendanceTimingForDepartmentFormProps> = ({ department }) => {
	const [{ fetching }, updateAttendanceTiming] = useUpdateAttendanceTimingOfDepartmentMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (attendanceTiming: StaffAttendanceTimingInput) => {
		const { error, data } = await updateAttendanceTiming({ departmentId: department._id, attendanceTiming })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		if (!data?.updateAttendanceTimingOfDepartment) {
			return toast({
				description: "Could not update the attendance timing",
				status: "error",
			})
		}

		return navigate(`/departments/${department._id}`)
	}

	const formik = useFormik<StaffAttendanceTimingInput>({
		initialValues: {
			inAt: department.attendanceTiming.inAt,
			outAt: department.attendanceTiming.outAt,
			inStartBeforeMinutes: department.attendanceTiming.inStartBeforeMinutes,
			outStartBeforeMinutes: department.attendanceTiming.outStartBeforeMinutes,
		},
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<form onSubmit={formik.handleSubmit}>
			<VStack w="full" maxW="lg" align="stretch" spacing={4}>
				<HStack>
					<FormControl isInvalid={Boolean(formik.errors.inAt && formik.touched.inAt)}>
						<FormLabel>School In</FormLabel>
						<TimePicker
							className="default-time-picker"
							disableClock
							format="hh:mm a"
							clearIcon={null}
							{...formik.getFieldProps("inAt")}
							onChange={(time) => formik.setFieldValue("inAt", time)}
						/>

						<FormErrorMessage>{formik.errors.inAt}</FormErrorMessage>
					</FormControl>
					<FormControl isInvalid={Boolean(formik.errors.outAt && formik.touched.outAt)}>
						<FormLabel>School Out</FormLabel>
						<TimePicker
							className="default-time-picker"
							disableClock
							format="hh:mm a"
							clearIcon={null}
							{...formik.getFieldProps("outAt")}
							onChange={(time) => formik.setFieldValue("outAt", time)}
						/>

						<FormErrorMessage>{formik.errors.outAt}</FormErrorMessage>
					</FormControl>
				</HStack>

				<HStack w="full">
					<FormControl isInvalid={Boolean(formik.errors.inStartBeforeMinutes && formik.touched.inStartBeforeMinutes)}>
						<FormLabel>Reader Start Time for School-In (in Minutes)</FormLabel>
						<NumberInput
							min={0}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							{...formik.getFieldProps("inStartBeforeMinutes")}
							onChange={(valueStr) => formik.setFieldValue("inStartBeforeMinutes", Number(valueStr))}
						>
							<NumberInputField />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput>
						<FormErrorMessage>{formik.errors.inStartBeforeMinutes}</FormErrorMessage>
						<FormHelperText>Enter amount of minutes the reader should start reading before school-in time</FormHelperText>
					</FormControl>
					<FormControl isInvalid={Boolean(formik.errors.outStartBeforeMinutes && formik.touched.outStartBeforeMinutes)}>
						<FormLabel>Reader Start Time for School-Out (in Minutes)</FormLabel>
						<NumberInput
							min={0}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							{...formik.getFieldProps("outStartBeforeMinutes")}
							onChange={(valueStr) => formik.setFieldValue("outStartBeforeMinutes", Number(valueStr))}
						>
							<NumberInputField />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput>
						<FormErrorMessage>{formik.errors.outStartBeforeMinutes}</FormErrorMessage>
						<FormHelperText>Enter amount of minutes the reader should start reading before school-out time</FormHelperText>
					</FormControl>
				</HStack>
				<Button size="sm" type="submit" colorScheme="primary" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
					Save
				</Button>
			</VStack>
		</form>
	)
}
