import { Modal, ModalBody, ModalContent, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import React from "react"
import { StaffPenaltiesList } from "./StaffPenaltiesList"
import { StaffRewardsList } from "./StaffRewardsList"

export type StaffPointsModalProps = {
	isOpen: boolean
	onClose: () => void
	staffId: string
}

export const StaffPointsModal: React.FC<StaffPointsModalProps> = ({ isOpen, onClose, staffId }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size="xl">
			<ModalOverlay>
				<ModalContent>
					<ModalBody>
						<Tabs variant="soft-rounded" colorScheme="primary">
							<TabList>
								<Tab>Rewards</Tab>
								<Tab>Penalties</Tab>
							</TabList>
							<TabPanels>
								<TabPanel>
									<StaffRewardsList staffId={staffId} />
								</TabPanel>
								<TabPanel>
									<StaffPenaltiesList staffId={staffId} />
								</TabPanel>
							</TabPanels>
						</Tabs>
					</ModalBody>
				</ModalContent>
			</ModalOverlay>
		</Modal>
	)
}
