import { Center, Container, Heading, HStack, Spinner, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { Nav, DashboardHeader } from "../../../components"
import { DepartmentsSelector } from "../../../components/penalty"
import { GRADIENT } from "../../../constants"
import { useDepartmentsQuery } from "../../../graphql"
import { useDrawer } from "../../../hooks"

export const AddPenaltyToDepartments: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [{ data, fetching, error }] = useDepartmentsQuery()
	const [selectedDepartmentIds, setSelectedDepartmentIds] = useState<string[]>([])

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Add penalty to departments
							</Heading>
						</VStack>
					</Stack>
					{fetching ? (
						<Center py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center>
							<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
								Failed loading departments, try reloading the page
							</Text>
						</Center>
					) : data?.departments && data?.departments.length > 0 ? (
						<DepartmentsSelector
							departments={data?.departments}
							selectedDepartmentIds={selectedDepartmentIds}
							onChange={setSelectedDepartmentIds}
						/>
					) : (
						<Center py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find any department.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
