import { AspectRatio, Center, Container, Grid, Heading, HStack, Icon, Img, Spinner, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import { faFilePdf } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useParams } from "react-router-dom"
import { DashboardHeader, Nav } from "../../components"
import { DeleteAttachementFromResource } from "../../components/resource"
import { GRADIENT } from "../../constants"
import { useResourceByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const DeleteResourceAttachments: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const { resourceId = "" } = useParams<{ resourceId: string }>()

	const [{ data, fetching, error }] = useResourceByIdQuery({
		variables: { resourceId },
	})

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Delete Attachments
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								{
									// TODO: add sub heading
								}
							</Heading>
						</VStack>
					</Stack>
					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.resourceById ? (
						<VStack w="full" align="stretch" maxW="lg">
							{data.resourceById.attachments?.pdfs?.length ? (
								<Grid templateColumns={getGridTemplateColumns(data.resourceById.attachments.pdfs.length || 0)} gap={1}>
									{data.resourceById.attachments.pdfs.slice(0, 4).map((pdf, index) => (
										<AspectRatio
											pos="relative"
											key={index}
											bg="primary.100"
											rounded="xl"
											ratio={1}
											cursor="pointer"
											onClick={() => window.open(pdf.url, "#")}
											zIndex={1}
										>
											<>
												<Center rounded="xl" bg="primary.100">
													<Icon
														color="primary.600"
														fontSize="5xl"
														as={(props: any) => <FontAwesomeIcon icon={faFilePdf} {...props} />}
													/>
												</Center>
												{index === 3 && (data.resourceById?.attachments?.pdfs?.length || 0) > 4 && (
													<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
														<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
															+{(data.resourceById?.attachments?.pdfs?.length || 0) - 4}
														</Text>
													</Center>
												)}
												<DeleteAttachementFromResource
													resourceId={resourceId}
													attachments={{ pdfs: [{ key: pdf.key, url: pdf.url }] }}
												/>
											</>
										</AspectRatio>
									))}
								</Grid>
							) : data.resourceById.attachments?.videos?.length ? (
								<Grid templateColumns={getGridTemplateColumns(data.resourceById.attachments.videos.length)} gap={1}>
									{data.resourceById.attachments.videos.slice(0, 4).map((video, index) => (
										<AspectRatio pos="relative" key={index} bg="primary.100" rounded="xl" ratio={1} zIndex={1}>
											<>
												<video style={{ borderRadius: "12px" }} width="100%" src={video.url} controls />
												{index === 3 && (data.resourceById?.attachments?.videos?.length || 0) > 4 && (
													<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
														<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
															+{(data.resourceById?.attachments?.videos?.length || 0) - 4}
														</Text>
													</Center>
												)}
												<DeleteAttachementFromResource
													resourceId={resourceId}
													attachments={{ videos: [{ key: video.key, url: video.url }] }}
												/>
											</>
										</AspectRatio>
									))}
								</Grid>
							) : data.resourceById.attachments?.pictures?.length ? (
								<Grid templateColumns={getGridTemplateColumns(data.resourceById.attachments.pictures.length)} gap={1}>
									{data.resourceById.attachments.pictures.slice(0, 4).map((picture, index) => (
										<AspectRatio key={index} pos="relative" bg="primary.100" rounded="xl" ratio={1} zIndex={1}>
											<>
												<Img rounded="xl" src={picture.url} />
												{index === 3 && (data.resourceById?.attachments?.pictures?.length || 0) > 4 && (
													<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
														<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
															+{(data.resourceById?.attachments?.pictures?.length || 0) - 4}
														</Text>
													</Center>
												)}
												<DeleteAttachementFromResource
													resourceId={resourceId}
													attachments={{ pictures: [{ key: picture.key, url: picture.url }] }}
												/>
											</>
										</AspectRatio>
									))}
								</Grid>
							) : (
								<Center w="full" py="4">
									<Text fontSize="md" fontWeight="semibold" color="text.400">
										There are no attachments
									</Text>
								</Center>
							)}
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the resource.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}

const getGridTemplateColumns = (length: number) => {
	if (length < 2) {
		return "1fr"
	}

	return "repeat(2, 1fr)"
}
