import { Box, ButtonGroup, HStack, Icon, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { useNavigate } from "react-router-dom"
import { CafeteriaRegistrationCharge } from "../../../graphql"

export type CafeteriaRegistrationChargesTableProps = {
	cafeteriaRegistrationCharges: CafeteriaRegistrationCharge[]
	prevPage: () => void
	nextPage: () => void
	hasPrevPage: boolean
	hasNextPage: boolean
	currentPage: number
	totalPages: number
}

export const CafeteriaRegistrationChargesTable: React.FC<CafeteriaRegistrationChargesTableProps> = ({
	cafeteriaRegistrationCharges,
	prevPage,
	nextPage,
	hasPrevPage,
	hasNextPage,
	currentPage,
	totalPages,
}) => {
	const navigate = useNavigate()

	return (
		<VStack w="full" spacing="0">
			<HStack alignSelf="flex-end">
				<Text fontSize="xs" color="text.400">
					{currentPage}/{totalPages}
				</Text>
				<ButtonGroup size="xs" p="4">
					<IconButton aria-label="prev-btn" onClick={prevPage} isDisabled={!hasPrevPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowLeft} {...props} />} color="text.500" />
					</IconButton>
					<IconButton aria-label="next-btn" onClick={nextPage} isDisabled={!hasNextPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} color="text.500" />
					</IconButton>
				</ButtonGroup>
			</HStack>
			<Box w="full" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th textTransform="capitalize">School Timing</Th>
							<Th textTransform="capitalize">Amount</Th>
							<Th textTransform="capitalize">Created</Th>
						</Tr>
					</Thead>
					<Tbody>
						{cafeteriaRegistrationCharges.map((cafeteriaRegistrationCharge, index) => (
							<Tr
								key={index}
								_hover={{ bg: "gray.100" }}
								transition="background-color 200ms ease-in"
								cursor="pointer"
								fontSize="sm"
								onClick={() => navigate(`/cafeteria/registrationCharges/${cafeteriaRegistrationCharge._id}`)}
							>
								<Td>{cafeteriaRegistrationCharge.schoolTiming.name}</Td>
								<Td>{cafeteriaRegistrationCharge.amount}</Td>
								<Td>{format(new Date(cafeteriaRegistrationCharge.createdAt), "PP")}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
		</VStack>
	)
}
