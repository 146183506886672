import {
	Button,
	Center,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	HStack,
	Icon,
	Input,
	RangeSlider,
	RangeSliderFilledTrack,
	RangeSliderMark,
	RangeSliderThumb,
	RangeSliderTrack,
	SlideFade,
	Spinner,
	Text,
	Textarea,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CUIAutoComplete } from "chakra-ui-autocomplete"
import { FormikHelpers, useFormik } from "formik"
import React, { useState } from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import {
	CreateTrendingSkillMutationVariables,
	TrendingSkillInput,
	useCreateTopicMutation,
	useCreateTrendingSkillMutation,
	useTopicsWithoutPaginationQuery,
} from "../../graphql"

const validationSchema = Yup.object({
	name: Yup.string().label("Name"),
	description: Yup.string().label("Description"),
	links: Yup.string().label("Links"),
	minAge: Yup.number().label("Min Age"),
	maxAge: Yup.number().label("Max Age"),
})

export const NewTrendingSkillForm: React.FC = () => {
	const [{ fetching }, createNew] = useCreateTrendingSkillMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const [{ data: topicData, fetching: fetchingTopics, error: topicError }] = useTopicsWithoutPaginationQuery()

	interface Item {
		label: string
		value: string
	}

	const [, createNewTopic] = useCreateTopicMutation()

	const handleCreateTopic = async (item: Item) => {
		const topicName = item.label
		const { error } = await createNewTopic({ topicName })

		if (error) {
			return toast({
				title: "Topic Error",
				description: error.message,
				status: "error",
			})
		}
	}

	function capitalizeFirstLetter(string: string) {
		return string.charAt(0).toUpperCase() + string.slice(1)
	}

	const handleSubmit = async (
		values: CreateTrendingSkillMutationVariables["input"],
		helpers: FormikHelpers<CreateTrendingSkillMutationVariables["input"]>
	) => {
		const { error, data } = await createNew({ input: { ...values, topicId: values.topicId[0], minAge: minSliderValue, maxAge: maxSliderValue } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createTrendingSkill.errors) {
			return data?.createTrendingSkill.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		return navigate("/trendingSkills", { replace: true })
	}

	const [minSliderValue, setMinSliderValue] = useState(1)
	const [maxSliderValue, setMaxSliderValue] = useState(50)

	const labelStyles = {
		mt: "5",
		ml: "-2.5",
		fontSize: "sm",
	}

	const formik = useFormik<CreateTrendingSkillMutationVariables["input"]>({
		initialValues: { name: "", description: "", topicId: "", links: "", minAge: 1, maxAge: 50 } as TrendingSkillInput,
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="7">
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)} isRequired>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="title" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.description && formik.touched.description)} isRequired>
				<SlideFade in={Boolean(formik.values.description)} unmountOnExit>
					<FormLabel htmlFor="description" fontSize="sm">
						Description
					</FormLabel>
				</SlideFade>
				<Textarea
					placeholder="Description"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("description")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.description}</Text>
				</FormErrorMessage>
			</FormControl>

			{fetchingTopics ? (
				<Center w="full" py="1">
					<VStack>
						<Spinner size="sm" color="text.400" />
						<Text fontSize="xs" color="text.400">
							Loading topics
						</Text>
					</VStack>
				</Center>
			) : topicError ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							{topicError.message.replace("[GraphQL] ", "")}
						</Text>
					</VStack>
				</Center>
			) : (
				<HStack w="full" spacing={3} align="center">
					<FormControl w="md" isInvalid={Boolean(formik.errors.topicId && formik.touched.topicId)}>
						<SlideFade in={Boolean(formik.values.topicId)} unmountOnExit>
							<FormLabel htmlFor="classId" fontSize="sm">
								Topic
							</FormLabel>
						</SlideFade>
						<CUIAutoComplete
							label=""
							placeholder="Search Topic"
							hideToggleButton
							items={topicData!.topicsWithoutPagination.map(({ _id, name }) => ({
								label: name,
								value: _id,
							}))}
							selectedItems={topicData?.topicsWithoutPagination
								.map(({ _id, name }) => ({ label: capitalizeFirstLetter(name), value: _id }))
								.filter((target) => formik.values.topicId?.includes(target.value))}
							onSelectedItemsChange={(changes) =>
								formik.setFieldValue(
									"topicId",
									changes.selectedItems?.slice(0, 1).map(({ value }) => value)
								)
							}
							onCreateItem={handleCreateTopic}
							inputStyleProps={{
								border: "none",
								_focus: { border: "none" },

								maxW: "md",
								bg: "white.500",
								rounded: "xl",
								py: "1",
							}}
							listStyleProps={{
								maxW: "md",
								bg: "whiteAlpha.400",
								backdropFilter: "blur(4px)",
								textTransform: "capitalize",
							}}
							labelStyleProps={{ display: "none" }}
						/>
						<FormErrorMessage>{formik.errors.topicId}</FormErrorMessage>
					</FormControl>
				</HStack>
			)}

			<FormControl isInvalid={Boolean(formik.errors.minAge && formik.touched.minAge)}>
				<SlideFade in={Boolean(formik.values.minAge)} unmountOnExit>
					<FormLabel htmlFor="minAge" fontSize="sm">
						Age Group
					</FormLabel>
				</SlideFade>
				<RangeSlider
					w="md"
					min={1}
					max={50}
					aria-label={["minAge", "maxAge"]}
					defaultValue={[1, 50]}
					mt={10}
					onChange={(val) => {
						setMinSliderValue(val[0])
						setMaxSliderValue(val[1])
					}}
				>
					<RangeSliderMark value={5} {...labelStyles}>
						5
					</RangeSliderMark>
					<RangeSliderMark value={10} {...labelStyles}>
						10
					</RangeSliderMark>
					<RangeSliderMark value={15} {...labelStyles}>
						15
					</RangeSliderMark>
					<RangeSliderMark value={20} {...labelStyles}>
						20
					</RangeSliderMark>
					<RangeSliderMark value={25} {...labelStyles}>
						25
					</RangeSliderMark>
					<RangeSliderMark value={30} {...labelStyles}>
						30
					</RangeSliderMark>
					<RangeSliderMark value={35} {...labelStyles}>
						35
					</RangeSliderMark>
					<RangeSliderMark value={40} {...labelStyles}>
						40
					</RangeSliderMark>
					<RangeSliderMark value={45} {...labelStyles}>
						45
					</RangeSliderMark>
					<RangeSliderMark value={50} {...labelStyles}>
						50
					</RangeSliderMark>
					<RangeSliderMark tabIndex={0} value={minSliderValue} textAlign="center" bg="blue.500" color="white" mt="-10" ml="-5" w="12">
						{minSliderValue}
					</RangeSliderMark>
					<RangeSliderMark tabIndex={1} value={maxSliderValue} textAlign="center" bg="blue.500" color="white" mt="-10" ml="-5" w="12">
						{maxSliderValue}
					</RangeSliderMark>
					<RangeSliderTrack>
						<RangeSliderFilledTrack />
					</RangeSliderTrack>
					<RangeSliderThumb index={0} boxSize={5} />
					<RangeSliderThumb index={1} boxSize={5} />
				</RangeSlider>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.minAge}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.links && formik.touched.links)} isRequired>
				<SlideFade in={Boolean(formik.values.links)} unmountOnExit>
					<FormLabel htmlFor="link" fontSize="sm">
						Link
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Links"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("links")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.links}</Text>
				</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
