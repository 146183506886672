import { Center, Heading, HStack, Select, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import {
	AllDeactivatedStudentsQueryVariables,
	ExtendedClassFragment,
	ClassSectionFragment,
	useAllDeactivatedStudentsQuery,
	useClassesQuery,
} from "../../graphql"
import { DeactivatedStudentsTable } from "./DeactivatedStudentsTable"

export const AllDeactivatedStudents: React.FC = () => {
	const [{ data: classesData, fetching: fetchingClasses, error: classesError }] = useClassesQuery()

	const [schoolClass, setSchoolClass] = useState<ExtendedClassFragment | undefined>()

	const [classSection, setClassSection] = useState<ClassSectionFragment>()

	useEffect(() => {
		if (classSection && !schoolClass?.sections.map((s) => s._id).includes(classSection._id)) {
			setClassSection(undefined)
		}
	}, [schoolClass])

	const [pagination, setPagination] = useState<AllDeactivatedStudentsQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useAllDeactivatedStudentsQuery({
		variables: { pagination, classId: schoolClass?._id, classSectionId: classSection?._id },
	})

	const prevPage = () => {
		if (data?.allDeactivatedStudents.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.allDeactivatedStudents.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" justify="space-between">
				<Heading as="h2" fontSize={isMobile ? "sm" : "md"} fontWeight="normal" color="text.500">
					Showing {data?.allDeactivatedStudents.total || "all"} students of{" "}
					<strong>
						{schoolClass?.name || "all"} {classSection?.name || ""}
					</strong>{" "}
					class{schoolClass ? "" : "es"}
				</Heading>
				<HStack>
					{fetchingClasses ? (
						<Center>
							<VStack w="full" color="blackAlpha.800">
								<Text>Loading classes</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					) : classesError ? (
						<Center>
							<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
								Failed loading classes, try reloading the page
							</Text>
						</Center>
					) : classesData?.classes.length ? (
						<HStack spacing={0}>
							<Select
								bg="white.500"
								roundedStart="xl"
								roundedEnd={schoolClass && schoolClass.sections?.length ? "none" : "xl"}
								border="none"
								_focus={{ border: "none" }}
								w="full"
								maxW="xs"
								placeholder="All"
								value={JSON.stringify(schoolClass)}
								onChange={(e) => {
									setSchoolClass(e.target.value ? JSON.parse(e.target.value) : undefined)
								}}
							>
								{classesData?.classes.map((c) => (
									<option key={c._id} value={JSON.stringify(c)}>
										{c.name}
									</option>
								))}
							</Select>
							{schoolClass && schoolClass.sections?.length && (
								<Select
									bg="white.500"
									roundedStart="none"
									roundedEnd="xl"
									border="none"
									_focus={{ border: "none" }}
									w="full"
									maxW="xs"
									placeholder="All"
									value={JSON.stringify(classSection)}
									onChange={(e) => {
										setClassSection(e.target.value ? JSON.parse(e.target.value) : undefined)
									}}
								>
									{schoolClass.sections.map((s) => (
										<option key={s.name} value={JSON.stringify(s)}>
											{s.name}
										</option>
									))}
								</Select>
							)}
						</HStack>
					) : (
						<Center>
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find any classes.
							</Text>
						</Center>
					)}
				</HStack>
			</HStack>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.allDeactivatedStudents.students && data?.allDeactivatedStudents.students.length > 0 ? (
				<DeactivatedStudentsTable
					students={data.allDeactivatedStudents.students}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.allDeactivatedStudents.hasPrevPage}
					hasNextPage={data.allDeactivatedStudents.hasNextPage}
					currentPage={data.allDeactivatedStudents.currentPage}
					totalPages={data.allDeactivatedStudents.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any students.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
