import { Checkbox, Heading, Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { CafeteriaMealTypeFragment } from "../../../graphql"
import { usePagination } from "../../../hooks"

export type CafeteriaMealTypeSelectorProps = {
	cafeteriaMealTypes: CafeteriaMealTypeFragment[]
	selectedCafeteriaMealTypeIds: string[]
	onChange: (cafeteriaMealTypeIds: string[]) => void
	removeHeading?: boolean
	isDefaultAllSelected?: boolean
}

export const CafeteriaMealTypeSelector: React.FC<CafeteriaMealTypeSelectorProps> = ({
	cafeteriaMealTypes,
	selectedCafeteriaMealTypeIds,
	onChange,
	removeHeading,
	isDefaultAllSelected,
}) => {
	const selectCafeteriaMealType = (mealTypeId: string) => {
		onChange([...selectedCafeteriaMealTypeIds, mealTypeId])
	}

	const deselectCafeteriaMealType = (mealTypeId: string) => {
		onChange(selectedCafeteriaMealTypeIds.filter((_mealTypeId) => _mealTypeId !== mealTypeId))
	}

	const isCafeteriaMealTypeSelected = useCallback((mealTypeId: string) => selectedCafeteriaMealTypeIds.includes(mealTypeId), [selectedCafeteriaMealTypeIds])

	const selectAllCafeteriaMealTypes = () => {
		onChange(cafeteriaMealTypes.map(({ _id }) => _id) ?? [])
	}

	const isAllChecked = useMemo(
		() => Boolean(selectedCafeteriaMealTypeIds.length) && cafeteriaMealTypes.map(({ _id }) => _id).every((id) => selectedCafeteriaMealTypeIds.includes(id)),
		[selectedCafeteriaMealTypeIds, cafeteriaMealTypes]
	)

	const isIndeterminate = useMemo(
		() => selectedCafeteriaMealTypeIds.some((id) => cafeteriaMealTypes.map(({ _id }) => _id).includes(id)) && !isAllChecked,
		[selectedCafeteriaMealTypeIds, cafeteriaMealTypes]
	)

	useEffect(() => {
		if (isDefaultAllSelected) onChange(cafeteriaMealTypes.map(({ _id }) => _id))
	}, [JSON.stringify(cafeteriaMealTypes), isDefaultAllSelected])

	const { currentItems, loadMore, hasMore } = usePagination<CafeteriaMealTypeFragment>(cafeteriaMealTypes, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
				{removeHeading ? "" : "Select the meal types"}
			</Heading>
			<VStack w="full" align="stretch" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th>
								<Checkbox
									colorScheme="blackAlpha"
									borderColor="black"
									isChecked={isAllChecked}
									isIndeterminate={isIndeterminate}
									onChange={(e) => (e.target.checked ? selectAllCafeteriaMealTypes() : onChange([]))}
								/>
							</Th>
							<Th textTransform="capitalize">Name</Th>
						</Tr>
					</Thead>
					<Tbody>
						{currentItems.map((mealType) => (
							<Tr key={mealType._id} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td>
									<Checkbox
										colorScheme="blackAlpha"
										borderColor="black"
										isChecked={isCafeteriaMealTypeSelected(mealType._id)}
										onChange={(e) => (e.target.checked ? selectCafeteriaMealType(mealType._id) : deselectCafeteriaMealType(mealType._id))}
									/>
								</Td>

								<Td>{mealType.name}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
				{hasMore && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
			</VStack>
		</VStack>
	)
}
