import { As, Box, BoxProps, ComponentWithAs } from "@chakra-ui/react"
import { motion, MotionProps } from "framer-motion"
import React from "react"

const MotionBox = motion<BoxProps & MotionProps>(Box)

export type CardProps = { children: any; disableAnimation?: boolean }

// eslint-disable-next-line react/display-name
export const Card: ComponentWithAs<As<BoxProps & MotionProps & CardProps>> = React.forwardRef<unknown, CardProps>(
	({ children, disableAnimation, ...props }, ref) => {
		return (
			<MotionBox
				ref={ref as any}
				pos="relative"
				bg="white.500"
				rounded="2xl"
				px="8"
				py="6"
				maxW="xs"
				cursor={disableAnimation ? "auto" : "pointer"}
				whileHover={disableAnimation ? {} : { scale: 1.05 }}
				{...props}
			>
				{children}
			</MotionBox>
		)
	}
) as any
