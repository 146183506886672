import { AspectRatio, Box, Button, ButtonGroup, Flex, Heading, Icon, Text, useDisclosure, VStack } from "@chakra-ui/react"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { DepartmentFragment } from "../../graphql"
import { usePagination } from "../../hooks"
import { Card } from "../common"
import { RewardDepartmentsModal } from "./RewardDepartmentsModal"

export type DepartmentsSelectorProps = {
	departments: DepartmentFragment[]
	selectedDepartmentIds: string[]
	onChange: (departmentIds: string[]) => void
}

export const DepartmentsSelector: React.FC<DepartmentsSelectorProps> = ({ departments, selectedDepartmentIds, onChange }) => {
	const isAllSelected = useMemo(
		() => Boolean(selectedDepartmentIds.length) && departments.map(({ _id }) => _id).every((id) => selectedDepartmentIds.includes(id)),
		[selectedDepartmentIds, departments]
	)

	const selectDepartment = (departmetnId: string) => {
		const _selectedDepartmentIds = new Set(selectedDepartmentIds)
		_selectedDepartmentIds.add(departmetnId)

		onChange(Array.from(_selectedDepartmentIds))
	}

	const deselectDepartment = (departmetnId: string) => {
		const _selectedDepartmentIds = [...selectedDepartmentIds]

		_selectedDepartmentIds.splice(_selectedDepartmentIds.indexOf(departmetnId), 1)

		onChange(_selectedDepartmentIds)
	}

	const selectAllDepartments = () => {
		onChange(departments.map(({ _id }) => _id) ?? [])
	}

	const deselectAllDepartments = () => {
		onChange([])
	}

	const isDepartmentselected = useCallback((departmetnId: string) => selectedDepartmentIds.includes(departmetnId), [selectedDepartmentIds])

	const selectedDepartments = useMemo(
		() => selectedDepartmentIds.map((id) => departments.find((s) => s._id === id)).filter(Boolean) as DepartmentFragment[],
		[selectedDepartmentIds, departments]
	)

	useEffect(() => {
		onChange(selectedDepartmentIds)
	}, [selectedDepartmentIds])

	const { currentItems, loadMore, hasMore } = usePagination<DepartmentFragment>(departments, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	const { getButtonProps, isOpen, onClose } = useDisclosure()

	return (
		<>
			<VStack w="full" align="stretch">
				<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
					Select the departments you want to give reward
				</Heading>
				<ButtonGroup>
					{isAllSelected ? (
						<Button onClick={deselectAllDepartments}>Deselect All</Button>
					) : (
						<Button onClick={selectAllDepartments}>Select All</Button>
					)}

					<Button colorScheme="purple" isDisabled={!selectedDepartments.length} {...getButtonProps()}>
						Reward
					</Button>
				</ButtonGroup>
				<Flex w="full" flexWrap="wrap" overflow="auto" py="2">
					{currentItems.map((department) => (
						<Card
							key={department._id}
							m={2}
							p={{ base: 6, md: 12 }}
							onClick={isDepartmentselected(department._id) ? () => deselectDepartment(department._id) : () => selectDepartment(department._id)}
							bgColor={isDepartmentselected(department._id) ? "green.50" : "white.200"}
							borderColor="green.400"
							borderTopWidth={1}
							borderBottomWidth={6}
						>
							<AspectRatio w="full" maxW="6" ratio={1} pos="absolute" top="-2" right="-2">
								<Box p="1" bgColor="green.400" rounded="md">
									<Text fontSize="xs" fontWeight="bold" color="whiteAlpha.800">
										{department.pointsBalance || 0}
									</Text>
								</Box>
							</AspectRatio>
							{isDepartmentselected(department._id) && (
								<>
									<Box pos="absolute" top="-0.5" left="-0.5" w=".8em" h=".8em" bgColor="white" rounded="full" />
									<Icon
										pos="absolute"
										top="-1"
										left="-1"
										color="green.400"
										fontSize="lg"
										as={(props: any) => <FontAwesomeIcon icon={faCheckCircle} {...props} />}
									/>
								</>
							)}
							<Heading fontSize="sm" lineHeight="100%">
								{department.name}
							</Heading>
						</Card>
					))}

					{hasMore && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
				</Flex>
			</VStack>
			<RewardDepartmentsModal isOpen={isOpen} onClose={onClose} departments={selectedDepartments} />
		</>
	)
}
