import { Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useNavigate } from "react-router-dom"
import { ExtendedClassFragment, ClassSectionFragment, useRemoveClassSectionMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type RemoveClassSectionButtonProps = {
	schoolClass: ExtendedClassFragment
	classSection: ClassSectionFragment
}

export const RemoveClassSectionButton: React.FC<RemoveClassSectionButtonProps> = ({ schoolClass: _class, classSection }) => {
	const [{ fetching }, removeClassSection] = useRemoveClassSectionMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	const handleRemoveClassSection = async () => {
		const { error } = await removeClassSection({ classId: _class._id, classSectionId: classSection._id })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		onClose()
		return navigate(`/classes/${_class._id}`, { replace: true })
	}

	return (
		<>
			<Tooltip label={`Remove section ${_class.name} - ${classSection.name}`} fontSize="sm">
				<IconButton aria-label="remove-section-btn" variant="ghost" colorScheme="blackAlpha" size="sm" {...getButtonProps()}>
					<Icon as={(props: any) => <FontAwesomeIcon icon={faTrash} {...props} />} />
				</IconButton>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Remove Section"
				message="Removing this section may affect students and/or teachers associated with this section."
				confirmLabel="Remove"
				onConfirm={handleRemoveClassSection}
				isLoading={fetching}
			/>
		</>
	)
}
