import { Button, Heading, Icon, IconButton, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, useToast, VStack } from "@chakra-ui/react"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import DateTimePicker from "react-datetime-picker"
import { useMarkManualOutMutation } from "../../graphql"

export type MarkManualOutProps = {
	studentId: string
}

export const MarkManualOut: React.FC<MarkManualOutProps> = ({ studentId }) => {
	const [date, setDate] = useState(new Date())

	const [{ fetching }, markManualOut] = useMarkManualOutMutation()

	const toast = useToast()

	const handleSave = async () => {
		const { error } = await markManualOut({ studentId, outAt: date })

		if (error) {
			return toast({ description: error.message.replace("[GraphQL ]", ""), status: "error" })
		}

		toast({ description: "Marked manual exit", status: "success" })
	}

	return (
		<Popover isLazy lazyBehavior="unmount">
			<PopoverTrigger>
				<IconButton aria-label="mark-entry-manually-btn" variant="ghost" colorScheme="purple" size="sm">
					<Icon as={(props: any) => <FontAwesomeIcon icon={faEdit} {...props} />} />
				</IconButton>
			</PopoverTrigger>
			<PopoverContent bgColor="white">
				<PopoverArrow bgColor="white" />
				<PopoverBody>
					<VStack w="full" align="stretch" spacing={6}>
						<Heading fontSize="sm">Mark manual exit</Heading>
						<DateTimePicker disableClock onChange={setDate} value={date} />
						<Button colorScheme="purple" size="sm" isLoading={fetching} onClick={handleSave}>
							Save
						</Button>
					</VStack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
