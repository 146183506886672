/* eslint-disable @typescript-eslint/indent */
import differenceInSeconds from "date-fns/differenceInSeconds"
import format from "date-fns/format"
import { AuthorTypes, LabelledRestrictedEntriesFragment } from "../graphql"
import { getDurationInWords } from "./getDurationInWords"

export const parseRestrictedEntriesReportAsCSV = (labelledRestrictedEntries: LabelledRestrictedEntriesFragment[], authorType: AuthorTypes) => {
	let data = `Date, ${authorType}, In, Out, Duration`

	labelledRestrictedEntries?.forEach(({ label, restrictedEntries }) => {
		restrictedEntries.forEach((entry) => {
			data = data.concat("\n")
			data = data.concat(
				`${label}, ${entry.author[getKey(authorType)]!.name} @${entry.author[getKey(authorType)]!.username}, ${format(new Date(entry.inAt), "p")}, ${
					entry.isFinal ? format(new Date(entry.outAt), "p") : "-"
				}, ${entry.isFinal ? getDurationInWords(differenceInSeconds(new Date(entry.outAt), new Date(entry.inAt))) : "-"}`
			)
		})
	})

	return data
}

const getKey = (authorType: AuthorTypes) => {
	switch (authorType) {
		case AuthorTypes.School:
			return "school"
		case AuthorTypes.Staff:
			return "staff"
		default:
			return "student"
	}
}
