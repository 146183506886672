import { Center, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { AllPointsRedeemItemsForStaffQueryVariables, useAllPointsRedeemItemsForStaffQuery } from "../../graphql"
import { PointsRedeemItemsGrid } from "./PointsRedeemItemsGrid"

export const AllPointsRedeemItemsForStaff: React.FC = () => {
	const [pagination, setPagination] = useState<AllPointsRedeemItemsForStaffQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useAllPointsRedeemItemsForStaffQuery({
		variables: { pagination },
	})

	const prevPage = () => {
		if (data?.allPointsRedeemItemsForStaff.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.allPointsRedeemItemsForStaff.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	return (
		<VStack align="flex-start">
			<Heading as="h2" fontSize="md" fontWeight="normal" color="text.500">
				Showing {data?.allPointsRedeemItemsForStaff.total || "all"} items for staff
			</Heading>

			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.allPointsRedeemItemsForStaff.pointsRedeemItems ? (
				<PointsRedeemItemsGrid
					pointsRedeemItems={data.allPointsRedeemItemsForStaff.pointsRedeemItems}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.allPointsRedeemItemsForStaff.hasPrevPage}
					hasNextPage={data.allPointsRedeemItemsForStaff.hasNextPage}
					currentPage={data.allPointsRedeemItemsForStaff.currentPage}
					totalPages={data.allPointsRedeemItemsForStaff.totalPages}
					createNewLink="/points/redeem/items/staffs/new"
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any items.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
