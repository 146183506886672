import { Button, FormControl, FormErrorIcon, FormErrorMessage, FormLabel, Icon, Input, SlideFade, Text, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { EditRestrictedZoneMutationVariables, RestrictedZoneFragment, useEditRestrictedZoneMutation } from "../../graphql"

const validationSchema = Yup.object({
	name: Yup.string().required().label("Name"),
})

export type EditRestrictedZoneFormProps = {
	restrictedZone: RestrictedZoneFragment
}

export const EditRestrictedZoneForm: React.FC<EditRestrictedZoneFormProps> = ({ restrictedZone }) => {
	const [{ fetching }, edit] = useEditRestrictedZoneMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async ({ name }: EditRestrictedZoneMutationVariables["input"]) => {
		const { error, data } = await edit({ restrictedZoneId: restrictedZone._id, input: { name } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		if (!data?.editRestrictedZone) {
			return toast({
				description: "Could not edit the restricted zone",
				status: "error",
			})
		}

		return navigate("/restrictedZones/" + data.editRestrictedZone._id)
	}

	const formik = useFormik<EditRestrictedZoneMutationVariables["input"]>({
		initialValues: { name: restrictedZone.name },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)}>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="name" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Save
			</Button>
		</VStack>
	)
}
