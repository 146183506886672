import { useContext } from "react"
import { UploadActionPayload, UploadContext } from "../../context"
import * as uploadActions from "../../context/upload/actions"

export const useUpload = () => {
	const [state, dispatch] = useContext(UploadContext)

	const uploadStarted = ({ id, type, file }: UploadActionPayload) => dispatch(uploadActions.uploadStarted({ id, type, file }))

	const uploadCancelFnCreated = ({ id, onCancel }: UploadActionPayload) => dispatch(uploadActions.uploadCancelFnCreated({ id, onCancel }))

	const uploadProgress = ({ id, progress }: UploadActionPayload) => dispatch(uploadActions.uploadProgress({ id, progress }))

	const uploadFailed = ({ id, error }: UploadActionPayload) => dispatch(uploadActions.uploadFailed({ id, error }))

	const uploadFinished = ({ id, key, url }: UploadActionPayload) => dispatch(uploadActions.uploadFinished({ id, key, url }))

	const clearUpload = ({ id }: UploadActionPayload) => dispatch(uploadActions.clearUpload({ id }))

	const clearAllUploads = () => dispatch(uploadActions.clearAllUploads())

	return {
		...state,
		uploadStarted,
		uploadCancelFnCreated,
		uploadProgress,
		uploadFailed,
		uploadFinished,
		clearUpload,
		clearAllUploads,
	}
}
