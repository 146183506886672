/* eslint-disable @typescript-eslint/indent */
import format from "date-fns/format"
import { StaffAttendanceReportResponse } from "../graphql"

export const parseStaffCompleteAttendanceReportAsCSV = (labelledAttendances: StaffAttendanceReportResponse) => {
	let data = "Staff"

	labelledAttendances.labels.forEach((label) => {
		data = data.concat("," + label)
	})

	labelledAttendances.attendances.forEach(({ staff, attendances }) => {
		data = data.concat("\n")
		data = data.concat(
			`${staff.name} @${staff.username}, ${attendances.map(
				({ attendance }) =>
					`${
						attendance.isAbsent
							? "-"
							: `${format(new Date(attendance.inAt), "p")} - ${format(new Date(attendance.outAt), "p")} ${
									attendance.isFinal ? "" : "(last seen)"
							  } - ${attendance.isLate && attendance.lateByMinutes ? `late by ${attendance.lateByMinutes} minutes` : "on time"}`
					}`
			)}`
		)
	})

	return data
}
