/* eslint-disable @typescript-eslint/indent */
import { Box, Center, Grid, Heading, HStack, Icon, Spinner, Text, VStack } from "@chakra-ui/react"
import { faCheckCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import {
	useTodayAttendanceOfStaffQuery,
	useTodayAttendanceUpdateOfStaffSubscription,
	useTodayBusAttendanceOfStaffQuery,
	useTodayBusAttendanceUpdateOfStaffSubscription,
} from "../../graphql"
import { MarkStaffManualIn } from "./MarkStaffManualIn"
import { MarkStaffManualOut } from "./MarkStaffManualOut"

export type TodayAttendanceOfStaffProps = {
	staffId: string
}

export const TodayAttendanceOfStaff: React.FC<TodayAttendanceOfStaffProps> = ({ staffId }) => {
	const [{ data, fetching, error }] = useTodayAttendanceOfStaffQuery({
		variables: { staffId },
	})

	useTodayAttendanceUpdateOfStaffSubscription({ variables: { staffId } })

	const [{ data: busAttendanceData, fetching: fetchingBusAttendance, error: busAttendanceError }] = useTodayBusAttendanceOfStaffQuery({
		variables: { staffId },
	})

	useTodayBusAttendanceUpdateOfStaffSubscription({ variables: { staffId } })

	return (
		<VStack>
			{fetching ? (
				<Center w="full" py="4">
					<Spinner color="text.400" />
				</Center>
			) : error ? (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="text.400">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : (
				<VStack w="full" maxW="xl" align="flex-start" spacing={{ lg: 5 }}>
					<Heading as="h2" fontSize="md" fontWeight="semisemibold" color="text.400">
						Today&apos;s attendance
					</Heading>
					<Box bg="white.500" rounded="2xl" px="6" py="4" w="full" shadow="sm">
						{fetchingBusAttendance ? (
							<Center w="full" py="4">
								<Spinner color="text.400" />
							</Center>
						) : busAttendanceError ? (
							<Center w="full" py="4">
								<Text fontSize="md" fontWeight="semisemibold" color="text.400">
									{busAttendanceError.message.replace("[GraphQL] ", "")}
								</Text>
							</Center>
						) : (
							<Grid w="full" templateColumns="repeat(2, 1fr)" gap={4}>
								<VStack align="flex-start" spacing={0}>
									<Text fontSize="xs" color="text.400">
										Bus In
									</Text>
									<HStack>
										<VStack align="flex-start" spacing={0}>
											<Text fontSize="sm" color="text.400" fontWeight="semibold">
												{busAttendanceData?.todayBusAttendanceOfStaff && busAttendanceData?.todayBusAttendanceOfStaff.isInRecorded
													? format(new Date(busAttendanceData.todayBusAttendanceOfStaff.inAt), "p")
													: "-"}
											</Text>
										</VStack>
									</HStack>
								</VStack>

								<VStack align="flex-start" spacing={0}>
									<Text fontSize="xs" color="text.400">
										Bus Out
									</Text>
									<HStack>
										<VStack align="flex-start" spacing={0}>
											<VStack align="flex-start" spacing={0}>
												<Text fontSize="sm" color="text.400" fontWeight="semibold">
													{busAttendanceData?.todayBusAttendanceOfStaff && busAttendanceData?.todayBusAttendanceOfStaff.isOutRecorded
														? format(new Date(busAttendanceData.todayBusAttendanceOfStaff.outAt), "p")
														: "-"}
												</Text>
											</VStack>
										</VStack>
									</HStack>
								</VStack>
							</Grid>
						)}

						<Grid w="full" templateColumns="repeat(2, 1fr)" gap={4}>
							<VStack align="flex-start" spacing={0}>
								<Text fontSize="xs" color="text.400">
									First seen
								</Text>
								<HStack>
									{data?.todayAttendanceOfStaff ? (
										<VStack align="flex-start" spacing={0}>
											<Text fontSize="sm" color="text.400" fontWeight="semibold">
												{data?.todayAttendanceOfStaff &&
												(data.todayAttendanceOfStaff.isInRecorded || data.todayAttendanceOfStaff.isInManual)
													? format(new Date(data.todayAttendanceOfStaff.inAt), "p")
													: "-"}
											</Text>
											<Text fontSize="xs" color="text.100">
												{data?.todayAttendanceOfStaff &&
													(data.todayAttendanceOfStaff.isInManual
														? "set manually"
														: data.todayAttendanceOfStaff.isInRecorded
														? "as recorded"
														: "")}
											</Text>

											{data.todayAttendanceOfStaff.inReader && (
												<Text fontSize="xs" color="text.100">
													{data.todayAttendanceOfStaff.inReader.label
														? data.todayAttendanceOfStaff.inReader.label
														: "Reader " + data.todayAttendanceOfStaff.inReader.readerDecimalId}
												</Text>
											)}
										</VStack>
									) : (
										<Text fontSize="sm" color="text.400" fontWeight="semibold">
											-
										</Text>
									)}
									<MarkStaffManualIn staffId={staffId} />
								</HStack>
							</VStack>

							<VStack align="flex-start" spacing={0}>
								<Text fontSize="xs" color="text.400">
									Last seen
								</Text>
								<HStack>
									{data?.todayAttendanceOfStaff ? (
										<VStack align="flex-start" spacing={0}>
											<Text fontSize="sm" color="text.400" fontWeight="semibold">
												{format(new Date(data.todayAttendanceOfStaff.outAt), "p")}
											</Text>
											<Text fontSize="xs" color="text.100">
												{data.todayAttendanceOfStaff.isFinal
													? data.todayAttendanceOfStaff.isOutManual
														? "set manually"
														: data.todayAttendanceOfStaff.isOutRecorded
														? "as recorded"
														: ""
													: "last seen"}
											</Text>

											{data.todayAttendanceOfStaff.outReader && (
												<Text fontSize="xs" color="text.100">
													{data.todayAttendanceOfStaff.outReader.label
														? data.todayAttendanceOfStaff.outReader.label
														: "Reade " + data.todayAttendanceOfStaff.outReader.readerDecimalId}
												</Text>
											)}
										</VStack>
									) : (
										<Text fontSize="sm" color="text.400" fontWeight="semibold">
											-
										</Text>
									)}
									<MarkStaffManualOut staffId={staffId} />
								</HStack>
							</VStack>

							<VStack align="flex-start" spacing={0}>
								<Text fontSize="xs" color="text.400">
									Status
								</Text>

								{data?.todayAttendanceOfStaff && data.todayAttendanceOfStaff.isLate !== null ? (
									<HStack>
										{" "}
										<Icon
											color={data.todayAttendanceOfStaff.isLate ? "red" : "green"}
											as={(props: any) => (
												<FontAwesomeIcon icon={data.todayAttendanceOfStaff!.isLate ? faXmarkCircle : faCheckCircle} {...props} />
											)}
										/>
										<Text fontSize="sm" color="text.400" fontWeight="semibold">
											{data.todayAttendanceOfStaff!.isLate
												? `Late ${
														data.todayAttendanceOfStaff.lateByMinutes && data.todayAttendanceOfStaff.lateByMinutes >= 0
															? `by ${data.todayAttendanceOfStaff.lateByMinutes || 0} minutes`
															: ""
												  }`
												: "On time"}
										</Text>
									</HStack>
								) : (
									<Text>-</Text>
								)}
							</VStack>
						</Grid>
					</Box>
				</VStack>
			)}
		</VStack>
	)
}
