/* eslint-disable @typescript-eslint/indent */
import { Avatar, chakra, Divider, Heading, HStack, LinkBox, LinkOverlay, Text, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { AuthorTypes, ExtendedRewardFragment } from "../../graphql"

export type StudentRewardListItemProps = {
	reward: ExtendedRewardFragment
}

export const StudentRewardListItem: React.FC<StudentRewardListItemProps> = ({ reward }) => {
	const from = useMemo<{
		title: string

		picture: string
		link: string
	}>(
		() => ({
			title:
				reward.from.authorType === AuthorTypes.Student
					? `${reward.from.student?.name}`
					: reward.from.authorType === AuthorTypes.School
					? `${reward.from.school?.name}`
					: reward.from.authorType === AuthorTypes.Staff
					? `${reward.from.staff?.name}`
					: "",

			picture:
				reward.from.authorType === AuthorTypes.Student
					? reward.from.student?.picture?.variants?.thumbnail || reward.from.student?.picture?.url || ""
					: reward.from.authorType === AuthorTypes.School
					? reward.from.school?.picture?.variants?.thumbnail || reward.from.school?.picture?.url || ""
					: reward.from.authorType === AuthorTypes.Staff
					? reward.from.staff?.picture?.variants?.thumbnail || reward.from.staff?.picture?.url || ""
					: "",
			link:
				reward.from.authorType === AuthorTypes.Student && reward.from.student
					? `/students/${reward.from.student._id}`
					: reward.from.authorType === AuthorTypes.School
					? "/dashboard"
					: reward.from.authorType === AuthorTypes.Staff
					? `/staff/${reward.from.staffId}`
					: "",
		}),
		[reward]
	)
	return (
		<VStack w="full" align="stretch">
			<HStack flex={1} as={LinkBox} spacing={0}>
				<LinkOverlay as={NavLink} to={from.link} />
				<Avatar src={from.picture} name={from.title} size="sm" />
				<VStack w="full" align="flex-start" spacing={0} pl={2}>
					<Heading fontSize="sm" fontWeight="semibold">
						{from.title}
					</Heading>

					<Text fontSize="xs" color="blackAlpha.700">
						{format(new Date(reward.createdAt), "dd/MM/yyyy p")}
					</Text>
				</VStack>
			</HStack>
			<VStack w="full" align="stretch" spacing={0}>
				<Text fontSize="sm">
					<chakra.span fontSize="md" fontWeight="semibold" color="purple.500">
						+{reward.totalPoints}
					</chakra.span>{" "}
					points
				</Text>

				<Text fontSize="sm" color="blackAlpha.700">
					{reward.rewardItems.map((i) => `${i.name} (${i.category.name})`).join(", ")}
				</Text>
			</VStack>

			<Divider />
		</VStack>
	)
}
