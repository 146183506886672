import { Center, Container, Heading, HStack, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React from "react"
import { useParams } from "react-router-dom"
import { DashboardHeader, EditPointsRedeemItemForm, Nav } from "../../../../components"
import { GRADIENT } from "../../../../constants"
import { usePointsRedeemItemByIdQuery } from "../../../../graphql"
import { useDrawer } from "../../../../hooks"

export const EditPointsRedeemItem: React.FC = () => {
	const { pointsRedeemItemId = "" } = useParams<{ pointsRedeemItemId: string }>()

	const [{ data, fetching, error }] = usePointsRedeemItemByIdQuery({
		variables: { pointsRedeemItemId },
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<VStack w="full" align="flex-start">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Edit Points Redeem Item
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								{
									// TODO: add sub heading
								}
							</Heading>
						</VStack>
					</VStack>
					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.pointsRedeemItemById ? (
						<EditPointsRedeemItemForm pointsRedeemItem={data.pointsRedeemItemById} />
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the item.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
