import { Box, Button, ButtonGroup, HStack, Icon, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, useToast, VStack } from "@chakra-ui/react"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { ArrowRight } from "react-feather"
import { ExtendedStudentFragment, useActivateStudentMutation } from "../../graphql"
import { DeactivatedStudentTag } from "./DeactivatedStudentTag"

export type DeactivatedStudentsTableProps = {
	students: ExtendedStudentFragment[]
	prevPage: () => void
	nextPage: () => void
	hasPrevPage: boolean
	hasNextPage: boolean
	currentPage: number
	totalPages: number
}

export const DeactivatedStudentsTable: React.FC<DeactivatedStudentsTableProps> = ({
	students,
	prevPage,
	nextPage,
	hasPrevPage,
	hasNextPage,
	currentPage,
	totalPages,
}) => {
	const toast = useToast()
	const [{ fetching: fetchingActivate }, activateStudent] = useActivateStudentMutation()
	const handleActivateStudent = async (student: ExtendedStudentFragment) => {
		await activateStudent({ studentId: student._id })
		toast({ description: "Student activated", status: "success" })
	}
	return (
		<VStack w="full" spacing="0">
			<HStack alignSelf="flex-end">
				<Text fontSize="xs" color="text.400">
					{currentPage}/{totalPages}
				</Text>
				<ButtonGroup size="xs" p="4">
					<IconButton aria-label="prev-btn" onClick={prevPage} isDisabled={!hasPrevPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowLeft} {...props} />} color="text.500" />
					</IconButton>
					<IconButton aria-label="next-btn" onClick={nextPage} isDisabled={!hasNextPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} color="text.500" />
					</IconButton>
				</ButtonGroup>
			</HStack>
			<Box w="full" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th textTransform="capitalize">Student</Th>
							<Th textTransform="capitalize">Tag</Th>
							<Th textTransform="capitalize">Email</Th>
							<Th textTransform="capitalize">Phone</Th>
							<Th textTransform="capitalize">Class</Th>
							<Th textTransform="capitalize">Mode of Transport</Th>
							<Th textTransform="capitalize"></Th>
						</Tr>
					</Thead>
					<Tbody>
						{students.map((student) => (
							<Tr key={student._id} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td>
									<DeactivatedStudentTag student={student} />
								</Td>
								<Td>{student.tag?.tagDecimalId || "-"}</Td>
								<Td>{student.email || "-"}</Td>
								<Td>{student.phone}</Td>
								<Td>
									{student.class.name} - {student.classSection.name}
								</Td>
								<Td>{student.transportMode || "-"}</Td>
								<Td>
									<Button
										w="full"
										size="xs"
										onClick={() => handleActivateStudent(student)}
										colorScheme="green"
										rightIcon={<Icon as={ArrowRight} />}
										isLoading={fetchingActivate}
									>
										Activate
									</Button>
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
		</VStack>
	)
}
