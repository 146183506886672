import {
	CLEAR_ALL_UPLOADS,
	CLEAR_UPLOAD,
	UploadAction,
	UploadState,
	UPLOAD_CANCEL_FN_CREATED,
	UPLOAD_FAILED,
	UPLOAD_FINISHED,
	UPLOAD_PROGRESS,
	UPLOAD_STARTED,
} from "./types"

export const initialUploadState: UploadState = {
	uploads: [],
}
export const uploadReducer = (state: UploadState = initialUploadState, action: UploadAction): UploadState => {
	switch (action.type) {
		case UPLOAD_STARTED: {
			const { payload } = action

			if (!payload) return state

			const updatedUploads = [
				{
					...payload,
					isUploading: true,
					progress: 0,
					type: payload.type!,
					file: payload.file!,
				},
				...state.uploads,
			]

			return { ...state, uploads: updatedUploads }
		}

		case UPLOAD_CANCEL_FN_CREATED: {
			const { payload } = action

			if (!payload) return state

			const updatedUploads = state.uploads.map((upload) => {
				if (upload.id === payload.id) {
					return {
						...upload,
						onCancel: payload.onCancel,
					}
				}

				return upload
			})

			return { ...state, uploads: updatedUploads }
		}

		case UPLOAD_PROGRESS: {
			const { payload } = action

			if (!payload) return state

			const updatedUploads = state.uploads.map((upload) => {
				if (upload.id === payload.id) {
					return {
						...upload,
						progress: payload.progress!,
					}
				}

				return upload
			})

			return { ...state, uploads: updatedUploads }
		}

		case UPLOAD_FINISHED: {
			const { payload } = action

			if (!payload) return state

			const updatedUploads = state.uploads.map((upload) => {
				if (upload.id === payload.id) {
					return {
						...upload,
						isUploading: false,
						isUploaded: true,
						progress: 100,
						key: payload.key,
						url: payload.url,
					}
				}

				return upload
			})

			return { ...state, uploads: updatedUploads }
		}

		case UPLOAD_FAILED: {
			const { payload } = action

			if (!payload) return state

			const updatedUploads = state.uploads.map((upload) => {
				if (upload.id === payload.id) {
					return {
						...upload,
						isUploading: false,
						onCancel: undefined,
						error: action.payload?.error,
						progress: 100,
					}
				}

				return upload
			})

			return { ...state, uploads: updatedUploads }
		}

		case CLEAR_UPLOAD: {
			const { payload } = action

			if (!payload) return state

			const updatedUploads = state.uploads.filter((upload) => upload.id !== payload.id)

			return { ...state, uploads: updatedUploads }
		}

		case CLEAR_ALL_UPLOADS: {
			return initialUploadState
		}

		default: {
			return state
		}
	}
}
