/* eslint-disable @typescript-eslint/indent */
import { Button, chakra, Divider, Grid, Heading, HStack, Icon, Link, Text, VStack } from "@chakra-ui/react"
import { faPencil } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedStaffFragment } from "../../graphql"
import { StaffPasswordResetPopover } from "./StaffPasswordResetPopover"

export type StaffDetailsProps = {
	staff: ExtendedStaffFragment
}

export const StaffDetails: React.FC<StaffDetailsProps> = ({ staff }) => {
	return (
		<VStack w="full" maxW="xl" align="flex-start">
			<HStack w="full">
				<Heading as="h2" fontSize="md" fontWeight="semisemibold" color="text.400">
					Staff Details
				</Heading>
				<Button
					as={NavLink}
					to="edit"
					colorScheme="purple"
					variant="ghost"
					size="sm"
					py="0"
					m="0"
					rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faPencil} {...props} />} />}
				>
					Edit
				</Button>
				<StaffPasswordResetPopover staff={staff} />
			</HStack>
			<VStack spacing={4} bg="white.500" rounded="2xl" px="6" py="4" w="full" shadow="sm">
				<Grid w="full" templateColumns="repeat(2, 1fr)" gap={4}>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Staff ID
						</Text>
						<Text fontSize="sm" color="text.400" fontWeight="semibold">
							{staff.staffId}
						</Text>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Designation
						</Text>
						<Text fontSize="sm" color="text.400" fontWeight="semibold">
							{staff.designation}
						</Text>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Department
						</Text>
						<Link as={NavLink} to={`/departments/${staff.department._id}`} fontSize="sm" color="text.400" fontWeight="semibold">
							{staff.department.name}
						</Link>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Type
						</Text>
						<Text fontSize="sm" color="text.400" fontWeight="semibold">
							{staff.department.type}
						</Text>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Current Role
						</Text>
						<Text fontSize="sm" color="text.400" fontWeight="semibold">
							{staff.roles && staff.roles.length > 0 ? (
								staff.roles.map((role, index) => (
									<chakra.span key={index}>
										{role.role} {role.class ? "|" : ""} {role.class?.name} - {role.classSection?.name}
										{index === (staff.roles?.length || 0) - 1 && !staff.roles?.[index + 1] ? "" : ", "}
									</chakra.span>
								))
							) : (
								<chakra.span>Not Assigned</chakra.span>
							)}
						</Text>
					</VStack>
				</Grid>
				<Divider />
				<Grid w="full" templateColumns="repeat(2, 1fr)" gap={4}>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Email
						</Text>
						<Text fontSize="sm" color="text.400" fontWeight="semibold">
							{staff.email || "-"}
						</Text>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Phone
						</Text>
						<Text fontSize="sm" color="text.400" fontWeight="semibold">
							{staff.phone}
						</Text>
					</VStack>
				</Grid>
				<Divider />
				<Grid w="full" templateColumns="repeat(2, 1fr)" gap={4}>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Date of birth
						</Text>
						<HStack>
							<Text fontSize="sm" color="text.400" fontWeight="semibold">
								{staff.dateOfBirth ? format(new Date(staff.dateOfBirth), "P") : "-"}
							</Text>
						</HStack>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Blood Group
						</Text>
						<HStack>
							<Text fontSize="sm" color="text.400" fontWeight="semibold">
								{staff.bloodGroup || "-"}
							</Text>
						</HStack>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Gender
						</Text>
						<HStack>
							<Text fontSize="sm" color="text.400" fontWeight="semibold">
								{staff.gender || "-"}
							</Text>
						</HStack>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Address
						</Text>
						<Text fontSize="sm" color="text.400" fontWeight="semibold">
							{staff.address || "-"}
						</Text>
					</VStack>
				</Grid>
			</VStack>
		</VStack>
	)
}
