import {
	Button,
	Checkbox,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	Icon,
	Input,
	NumberInput,
	NumberInputField,
	SlideFade,
	Text,
	Textarea,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { CreateWorkshopMutationVariables, useCreateWorkshopMutation } from "../../graphql"

const validationSchema = Yup.object({
	title: Yup.string().required().label("Title"),
	description: Yup.string().required().label("Description"),
	benefit: Yup.string().required().label("Benefit"),
})

export const NewWorkshopForm: React.FC = () => {
	const [{ fetching }, createNew] = useCreateWorkshopMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (
		{ title, description, benefit, isLive, isPaid, isPublic, priceInINR }: CreateWorkshopMutationVariables["input"],
		helpers: FormikHelpers<CreateWorkshopMutationVariables["input"]>
	) => {
		const { error, data } = await createNew({
			input: { title, description, benefit, isLive, isPaid, isPublic, priceInINR },
		})

		if (error) {
			return toast({
				description: error.message,
				status: "error",
			})
		}

		if (data?.createWorkshop.errors) {
			return data?.createWorkshop.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		return navigate(`/workshops/${data?.createWorkshop.workshop?._id}`, { replace: true })
	}

	const formik = useFormik<CreateWorkshopMutationVariables["input"]>({
		initialValues: { title: "", description: "", benefit: "", isLive: false, isPaid: false, isPublic: false, priceInINR: 0 },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.title && formik.touched.title)}>
				<SlideFade in={Boolean(formik.values.title)} unmountOnExit>
					<FormLabel htmlFor="title" fontSize="sm">
						Title
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Title"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("title")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.title}</Text>
				</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={Boolean(formik.errors.description && formik.touched.description)}>
				<SlideFade in={Boolean(formik.values.description)} unmountOnExit>
					<FormLabel htmlFor="description" fontSize="sm">
						Description
					</FormLabel>
				</SlideFade>
				<Textarea
					placeholder="Description"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("description")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.description}</Text>
				</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={Boolean(formik.errors.benefit && formik.touched.benefit)}>
				<SlideFade in={Boolean(formik.values.benefit)} unmountOnExit>
					<FormLabel htmlFor="benefit" fontSize="sm">
						Benefit
					</FormLabel>
				</SlideFade>
				<Textarea
					placeholder="Benefit"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("benefit")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.benefit}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.isLive && formik.touched.isLive)}>
				<Checkbox
					maxW="sm"
					rounded="xl"
					borderColor="purple.400"
					{...formik.getFieldProps("isLive")}
					isChecked={formik.values.isLive || false}
					onChange={(e) => formik.setFieldValue("isLive", e.target.checked)}
				>
					Is this a livestream?
				</Checkbox>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.isLive}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.isPaid && formik.touched.isPaid)}>
				<Checkbox
					maxW="sm"
					rounded="xl"
					borderColor="purple.400"
					{...formik.getFieldProps("isPaid")}
					isChecked={formik.values.isPaid || false}
					onChange={(e) => formik.setFieldValue("isPaid", e.target.checked)}
				>
					Is this a paid workshop?
				</Checkbox>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.isPaid}</Text>
				</FormErrorMessage>
			</FormControl>

			{formik.values.isPaid && (
				<FormControl isInvalid={Boolean(formik.errors.priceInINR && formik.touched.priceInINR)}>
					<FormLabel>Price (in INR)</FormLabel>
					<NumberInput
						min={0}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						{...formik.getFieldProps("priceInINR")}
						onChange={(valueStr) => formik.setFieldValue("priceInINR", Number(valueStr))}
					>
						<NumberInputField />
					</NumberInput>
					<FormErrorMessage>{formik.errors.priceInINR}</FormErrorMessage>
				</FormControl>
			)}

			<FormControl isInvalid={Boolean(formik.errors.isPublic && formik.touched.isPublic)}>
				<Checkbox
					maxW="sm"
					rounded="xl"
					borderColor="purple.400"
					{...formik.getFieldProps("isPublic")}
					isChecked={formik.values.isPublic || false}
					onChange={(e) => formik.setFieldValue("isPublic", e.target.checked)}
				>
					Is this a public workshop?
				</Checkbox>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.isPublic}</Text>
				</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Publish
			</Button>
		</VStack>
	)
}
