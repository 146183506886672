import {
	Box,
	Center,
	Flex,
	Heading,
	HStack,
	Icon,
	IconButton,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Spinner,
	Text,
	VStack,
} from "@chakra-ui/react"
import { faCheckCircle, faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useInViewport } from "react-in-viewport"
import { AllRewardCategoriesForStaffQueryVariables, RewardItemFragment, useAllRewardCategoriesForStaffQuery } from "../../graphql"

export type RewardItemsSelectorForStaffProps = {
	onChange: (itemIds: RewardItemFragment[]) => void
}

export const RewardItemsSelectorForStaff: React.FC<RewardItemsSelectorForStaffProps> = ({ onChange }) => {
	const [pagination, setPagination] = useState<AllRewardCategoriesForStaffQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useAllRewardCategoriesForStaffQuery({
		variables: { pagination },
	})

	const nextPage = () => {
		if (data?.allRewardCategoriesForStaff.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	const [selectedItems, setSelectedItems] = useState<RewardItemFragment[]>([])

	useEffect(() => {
		if (inViewport && data?.allRewardCategoriesForStaff.hasNextPage) nextPage()
	}, [inViewport, nextPage, data?.allRewardCategoriesForStaff.hasNextPage])

	const selectItem = (item: RewardItemFragment) => {
		const _selectedItems = [...selectedItems]

		const index = _selectedItems.findIndex((i) => i._id === item._id)

		if (index === -1) {
			setSelectedItems((prev) => [...prev, item])
		}
	}

	const deselectItem = (itemId: string) => {
		const _selectedItems = [...selectedItems]

		const index = _selectedItems.findIndex((i) => i._id === itemId)

		if (index >= 0) {
			_selectedItems.splice(index, 1)
		}

		setSelectedItems(_selectedItems)
	}

	const isItemSelected = useCallback((itemId: string) => selectedItems.map((i) => i._id).includes(itemId), [selectedItems])

	useEffect(() => {
		onChange(selectedItems)
	}, [selectedItems])

	return (
		<VStack>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.allRewardCategoriesForStaff.rewardCategories && data?.allRewardCategoriesForStaff.rewardCategories.length > 0 ? (
				<VStack w="full" align="stretch" spacing={8}>
					{data.allRewardCategoriesForStaff.rewardCategories.map(
						(category) =>
							category.items?.length && (
								<VStack key={category._id} w="full" align="center">
									<HStack position="relative" maxW="sm">
										<Heading fontSize="md" color="primary.500">
											{category.name}
										</Heading>

										<Popover>
											<PopoverTrigger>
												<IconButton
													aria-label="description"
													variant="ghost"
													size="2xs"
													color="blackAlpha.700"
													as={(props: any) => <FontAwesomeIcon icon={faCircleInfo} {...props} />}
												/>
											</PopoverTrigger>
											<PopoverContent bgColor="white">
												<PopoverBody>
													<Text textAlign="center" fontSize="sm">
														{category.description}
													</Text>
												</PopoverBody>
											</PopoverContent>
										</Popover>
									</HStack>

									<Flex h="full" overflowX="auto" overflowY="hidden" py="4">
										{category.items?.map((item) => (
											<Center
												key={item._id}
												position="relative"
												m={1}
												rounded="2xl"
												px="8"
												py="6"
												cursor="pointer"
												onClick={isItemSelected(item._id) ? () => deselectItem(item._id) : () => selectItem(item)}
												bgColor={isItemSelected(item._id) ? "purple.100" : "white.500"}
											>
												{isItemSelected(item._id) && (
													<>
														<Box pos="absolute" top="-0.5" left="-0.5" w=".8em" h=".8em" bgColor="white" rounded="full" />
														<Icon
															pos="absolute"
															top="-1"
															left="-1"
															color="purple.400"
															fontSize="lg"
															as={(props: any) => <FontAwesomeIcon icon={faCheckCircle} {...props} />}
														/>
													</>
												)}
												<Popover>
													<PopoverTrigger>
														<IconButton
															zIndex={10}
															pos="absolute"
															top="2"
															right="2"
															aria-label="description"
															size="2xs"
															color="blackAlpha.700"
															as={(props: any) => <FontAwesomeIcon icon={faCircleInfo} {...props} />}
														/>
													</PopoverTrigger>
													<PopoverContent bgColor="white">
														<PopoverBody>
															<Text textAlign="center" fontSize="sm">
																{item.description}
															</Text>
														</PopoverBody>
													</PopoverContent>
												</Popover>
												<VStack spacing={0}>
													<Text fontSize="2xl" color="purple.500" fontWeight="bold">
														+{item.points}
													</Text>
													<Heading fontSize="sm" color="blackAlpha.700">
														{item.name}
													</Heading>
												</VStack>
											</Center>
										))}
									</Flex>

									{data.allRewardCategoriesForStaff.hasNextPage && (
										<div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />
									)}
								</VStack>
							)
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any reward categories.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
