import { Button, Center, Heading, HStack, Icon, Spinner, Text, VStack } from "@chakra-ui/react"
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { AllCafeteriaMealTypesQueryVariables, useAllCafeteriaMealTypesQuery } from "../../../graphql"
import { CafeteriaMealTypesTable } from "./CafeteriaMealTypesTable"

export const AllCafeteriaMealTypes: React.FC = () => {
	const [pagination, setPagination] = useState<AllCafeteriaMealTypesQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useAllCafeteriaMealTypesQuery({
		variables: { pagination },
	})

	const navigate = useNavigate()

	const prevPage = () => {
		if (data?.allCafeteriaMealTypes.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.allCafeteriaMealTypes.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" justify="space-between">
				<Heading as="h2" fontSize="md" fontWeight="normal" color="text.500">
					Showing {data?.allCafeteriaMealTypes.total || "all"} cafeteria meal types of your school
				</Heading>
				<Button
					colorScheme="whiteAlpha"
					color="text.500"
					rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faAdd} {...props} />} />}
					onClick={() => navigate("/cafeteria/mealTypes/new")}
				>
					Create New
				</Button>
			</HStack>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.allCafeteriaMealTypes.cafeteriaMealTypes && data?.allCafeteriaMealTypes.cafeteriaMealTypes.length > 0 ? (
				<CafeteriaMealTypesTable
					cafeteriaMealTypes={data.allCafeteriaMealTypes.cafeteriaMealTypes}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.allCafeteriaMealTypes.hasPrevPage}
					hasNextPage={data.allCafeteriaMealTypes.hasNextPage}
					currentPage={data.allCafeteriaMealTypes.currentPage}
					totalPages={data.allCafeteriaMealTypes.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any cafeteria meal type.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
