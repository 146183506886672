import { ButtonGroup, Flex, Heading, HStack, Icon, IconButton, Link, StackProps, Tag, Text, VStack } from "@chakra-ui/react"
import { faPencil } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { NavLink } from "react-router-dom"
import { SchoolTimingFragment } from "../../graphql"
import { DeleteSchoolTimingButton } from "./DeleteSchoolTimingButton"
import { UpdateDefaultSchoolTiming } from "./UpdateDefaultSchoolTiming"

export type SchoolTimingItemProps = {
	schoolTiming: SchoolTimingFragment
} & StackProps

export const SchoolTimingItem: React.FC<SchoolTimingItemProps> = ({ schoolTiming, ...props }) => {
	return (
		<>
			<VStack align="flex-start" bg="white.500" rounded="2xl" p={4} spacing={4} shadow="sm" {...props}>
				<VStack w="full" align="flex-start">
					<HStack w="full" justify="space-between" spacing={6}>
						<Heading fontSize="md" fontWeight="semibold">
							{schoolTiming.name}
						</Heading>

						<ButtonGroup alignItems="center">
							<IconButton
								aria-label="edit-school-timing-btn"
								variant="ghost"
								colorScheme="blackAlpha"
								size="sm"
								as={(props: any) => <Link as={NavLink} {...props} />}
								to={`/schoolTimings/${schoolTiming._id}/update`}
							>
								<Icon as={(props: any) => <FontAwesomeIcon icon={faPencil} {...props} />} />
							</IconButton>

							<DeleteSchoolTimingButton schoolTiming={schoolTiming} />
						</ButtonGroup>
					</HStack>
					<UpdateDefaultSchoolTiming schoolTiming={schoolTiming} />
				</VStack>

				<VStack w="full" align="start" spacing={0}>
					<Text fontSize="sm" color="blackAlpha.700">
						Timing
					</Text>
					<Text fontSize="sm" color="blackAlpha.800" fontWeight="semibold">
						{schoolTiming.attendanceTiming.inAt} - {schoolTiming.attendanceTiming.outAt}
					</Text>
				</VStack>

				<HStack w="full">
					<VStack w="full" align="start" spacing="0">
						<Text fontSize="sm" maxW="36" color="blackAlpha.700">
							Reader Start Time for School-In
						</Text>
						<Text fontSize="sm" color="blackAlpha.800" fontWeight="semibold">
							{schoolTiming.attendanceTiming.inStartBeforeMinutes} mins.
						</Text>
					</VStack>
					<VStack w="full" align="start" spacing="0">
						<Text fontSize="sm" maxW="36" color="blackAlpha.700">
							Reader Start Time for School-Out
						</Text>
						<Text fontSize="sm" color="blackAlpha.800" fontWeight="semibold">
							{schoolTiming.attendanceTiming.outStartBeforeMinutes} mins.
						</Text>
					</VStack>
				</HStack>

				<VStack w="full" align="start" spacing="0">
					<Text fontSize="sm" maxW="36" color="blackAlpha.700">
						Notify teacher after arrival time
					</Text>
					<Text fontSize="sm" color="blackAlpha.800" fontWeight="semibold">
						{schoolTiming.attendanceTiming.notifyTeacherAfterMinutes} mins.
					</Text>
				</VStack>

				<VStack w="full" align="start" spacing={0}>
					<Text fontSize="sm" maxW="36" color="blackAlpha.700">
						Working weekdays
					</Text>
					<Flex flexWrap="wrap">
						{schoolTiming.workingWeekdays?.length ? (
							schoolTiming.workingWeekdays.map((day) => (
								<Tag key={day} m="1" size="sm" colorScheme="purple">
									{day}
								</Tag>
							))
						) : (
							<Text fontSize="xs" color="blackAlpha.700" fontWeight="semibold">
								Not set
							</Text>
						)}
					</Flex>
				</VStack>
			</VStack>
		</>
	)
}
