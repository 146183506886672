import { Avatar, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { ShowcaseAuthor as Author } from "../../graphql"
import { DeleteShowcaseAuthorButton } from "./DeleteShowcaseAuthorButton"

export type ShowcaseAuthorProps = {
	showcaseId: string
	author: Author
}

export const ShowcaseAuthor: React.FC<ShowcaseAuthorProps> = ({ showcaseId, author }) => {
	const [isHovered, setIsHovered] = useState(false)

	const isMobile = useBreakpointValue({ base: true, md: false })

	return (
		<VStack position="relative" key={author._id} m="1" spacing={0} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
			<Avatar size="lg" src={author.picture.url} name={author.name} />
			<Text fontSize="sm" fontWeight="semibold">
				{author.name}
			</Text>
			{(isMobile || isHovered) && <DeleteShowcaseAuthorButton showcaseId={showcaseId} authorId={author._id} />}
		</VStack>
	)
}
