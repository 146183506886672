import {
	Box,
	Button,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	Icon,
	Input,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	SlideFade,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { SketchPicker } from "react-color"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { EditHouseMutationVariables, ExtendedHouseFragment, useEditHouseMutation } from "../../graphql"

const validationSchema = Yup.object({
	name: Yup.string().required().label("Name"),
	colorHex: Yup.string().required().label("Color"),
})

export type EditHouseFormProps = {
	house: ExtendedHouseFragment
}

export const EditHouseForm: React.FC<EditHouseFormProps> = ({ house }) => {
	const [{ fetching }, editHouse] = useEditHouseMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async ({ name, colorHex }: EditHouseMutationVariables["input"], helpers: FormikHelpers<EditHouseMutationVariables["input"]>) => {
		const { error, data } = await editHouse({
			houseId: house._id,
			input: { name, colorHex },
		})

		if (error) {
			return toast({
				description: error.message,
				status: "error",
			})
		}

		if (data?.editHouse.errors) {
			return data?.editHouse.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		return navigate("/houses/" + data?.editHouse.house?._id, { replace: true })
	}

	const formik = useFormik<EditHouseMutationVariables["input"]>({
		initialValues: {
			name: house.name,
			colorHex: house.colorHex,
		},
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack as="form" w="full" maxW="lg" align="stretch" spacing={4} onSubmit={formik.handleSubmit as any}>
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)}>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="name" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={Boolean(formik.errors.colorHex && formik.touched.colorHex)}>
				<FormLabel htmlFor="colorHex" fontSize="sm">
					Color
				</FormLabel>

				<Popover>
					<PopoverTrigger>
						<Box w="24" h="10" rounded="xl" bgColor={formik.values.colorHex} />
					</PopoverTrigger>
					<PopoverContent w="fit-content">
						<PopoverBody p="0">
							<SketchPicker color={formik.values.colorHex} onChange={({ hex }) => formik.setFieldValue("colorHex", hex)} />
						</PopoverBody>
					</PopoverContent>
				</Popover>

				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.colorHex}</Text>
				</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Save
			</Button>
		</VStack>
	)
}
