import {
	AspectRatio,
	Avatar,
	Box,
	Button,
	Center,
	chakra,
	Container,
	Flex,
	Grid,
	Heading,
	HStack,
	Link,
	Spinner,
	Stack,
	Text,
	useBreakpointValue,
	useDisclosure,
	VStack,
} from "@chakra-ui/react"
import format from "date-fns/format"
import React from "react"
import { NavLink, useParams } from "react-router-dom"
import { DashboardHeader, Nav } from "../../components"
import { AllAttendancesOfStaff, AssignNewTagToStaffPopover, StaffDetails, StaffPointsModal, TodayAttendanceOfStaff } from "../../components/staff"
import { UploadStaffAvatar } from "../../components/upload"
import { GRADIENT } from "../../constants"
import { DepartmentTypes, useStaffByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const Staff: React.FC = () => {
	const { staffId = "" } = useParams<{ staffId: string }>()

	const [{ data, fetching, error }] = useStaffByIdQuery({
		variables: { staffId },
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const { getButtonProps, isOpen, onClose } = useDisclosure()

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 0}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.staffById ? (
						<VStack w="full" align="flex-start" spacing="4">
							<HStack w="full" align="flex-start" spacing={4}>
								<UploadStaffAvatar staffId={staffId}>
									<AspectRatio w="full" maxW={{ base: "24", md: "36" }} cursor="pointer" ratio={1}>
										<Avatar src={data.staffById.picture?.url} name={data.staffById.name} />
									</AspectRatio>
								</UploadStaffAvatar>
								<VStack align="flex-start">
									<VStack align="flex-start" spacing="0">
										<Heading as="h1" fontSize="xl" color="text.400">
											{data.staffById.name}
										</Heading>
										<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.400" maxW="xs">
											@{data.staffById.username}
										</Heading>
										<Flex flexWrap="wrap">
											<AssignNewTagToStaffPopover staff={data.staffById} />
										</Flex>
										<Button m="1" size="xs" variant="outline" colorScheme="purple" {...getButtonProps()}>
											{data.staffById.pointsBalance || 0} points
										</Button>
										{isOpen && <StaffPointsModal isOpen={isOpen} onClose={onClose} staffId={staffId} />}
									</VStack>
									{data.staffById.department.type === DepartmentTypes.Teaching && data.staffById.subjectIds?.length && (
										<VStack align="flex-start" spacing={0}>
											<Text fontSize="xs" color="text.400">
												Teaches
											</Text>

											<Text fontSize="sm" color="text.400" fontWeight="semibold">
												{data.staffById.subjects.map((subject, index) => (
													<Link key={subject._id} as={NavLink} to={`/subjects/${subject._id}`}>
														{subject.name}
														{index === (data.staffById.subjects.length || 0) - 1 && !data.staffById.subjects[index + 1] ? "" : ", "}
													</Link>
												))}
											</Text>
										</VStack>
									)}
								</VStack>
							</HStack>
							<Stack direction={{ base: "column", lg: "row" }} w="full" align="stretch" spacing={6}>
								<VStack w="full" maxW="xl" align="flex-start">
									<StaffDetails staff={data.staffById} />
									<VStack w="full" maxW="xl" align="flex-start">
										<Heading as="h2" fontSize="md" fontWeight="semibold" color="text.400">
											Past Roles
										</Heading>
										<Box bg="white.500" rounded="2xl" px="6" py="4" w="full" shadow="sm">
											<Grid w="full" templateColumns="repeat(2, 1fr)" gap={4}>
												{data.staffById.pastRoles && data.staffById.pastRoles.length > 0 ? (
													data.staffById.pastRoles.map((pastRole, index) => (
														<VStack key={index} align="flex-start" spacing={0}>
															<Text fontSize="sm" color="text.400" fontWeight="semibold">
																{pastRole.role}
															</Text>
															{pastRole.class && (
																<Text fontSize="xs" color="text.400" fontWeight="semibold">
																	{pastRole.class?.name} - {pastRole.classSection?.name}
																</Text>
															)}
															<Text fontSize="xs" color="text.400">
																{format(new Date(pastRole.assignedAt), "P")} - {format(new Date(pastRole.removedAt), "P")}
															</Text>
														</VStack>
													))
												) : (
													<chakra.span>Not Assigned</chakra.span>
												)}
											</Grid>
										</Box>
									</VStack>
								</VStack>
								<VStack w="full" maxW="xl" align="stretch" spacing={6}>
									<TodayAttendanceOfStaff staffId={data.staffById._id} />
									<AllAttendancesOfStaff staffId={data.staffById._id} />
								</VStack>
							</Stack>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the staff.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
