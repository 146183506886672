import { AspectRatio, Box, Button, ButtonGroup, Flex, Heading, Icon, Text, useDisclosure, VStack } from "@chakra-ui/react"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { StaffFragment } from "../../graphql"
import { usePagination } from "../../hooks"
import { Card } from "../common"
import { StaffTag } from "../staff"
import { RewardStaffModal } from "./RewardStaffsModal"

export type StaffsSelectorProps = {
	staffs: StaffFragment[]
	selectStaffIds: string[]
	onChange: (staffIds: string[]) => void
}

export const StaffsSelector: React.FC<StaffsSelectorProps> = ({ staffs, selectStaffIds, onChange }) => {
	const isAllSelected = useMemo(
		() => Boolean(selectStaffIds.length) && staffs.map(({ _id }) => _id).every((id) => selectStaffIds.includes(id)),
		[selectStaffIds, staffs]
	)

	const selectStaff = (staffId: string) => {
		const _selectStaffIds = new Set(selectStaffIds)
		_selectStaffIds.add(staffId)

		onChange(Array.from(_selectStaffIds))
	}

	const deselectStaff = (staffId: string) => {
		const _selectStaffIds = [...selectStaffIds]

		_selectStaffIds.splice(_selectStaffIds.indexOf(staffId), 1)

		onChange(_selectStaffIds)
	}

	const selectAllStaffs = () => {
		onChange(staffs.map(({ _id }) => _id) ?? [])
	}

	const deselectAllStaffs = () => {
		onChange([])
	}

	const isStaffSelected = useCallback((staffId: string) => selectStaffIds.includes(staffId), [selectStaffIds])

	const selectedStaffs = useMemo(
		() => selectStaffIds.map((id) => staffs.find((s) => s._id === id)).filter(Boolean) as StaffFragment[],
		[selectStaffIds, staffs]
	)

	useEffect(() => {
		onChange(selectStaffIds)
	}, [selectStaffIds])

	const { currentItems, loadMore, hasMore } = usePagination<StaffFragment>(staffs, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	const { getButtonProps, isOpen, onClose } = useDisclosure()

	return (
		<>
			<VStack w="full" align="stretch">
				<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
					Select the staff you want to give reward
				</Heading>
				<ButtonGroup>
					{isAllSelected ? <Button onClick={deselectAllStaffs}>Deselect All</Button> : <Button onClick={selectAllStaffs}>Select All</Button>}

					<Button colorScheme="purple" isDisabled={!selectedStaffs.length} {...getButtonProps()}>
						Reward
					</Button>
				</ButtonGroup>
				<Flex w="full" flexWrap="wrap" overflow="auto" py="2">
					{currentItems.map((staff) => (
						<Card
							key={staff._id}
							m={2}
							onClick={isStaffSelected(staff._id) ? () => deselectStaff(staff._id) : () => selectStaff(staff._id)}
							bgColor={isStaffSelected(staff._id) ? "green.50" : "white.200"}
							borderColor="green.400"
							borderTopWidth={1}
							borderBottomWidth={6}
						>
							<AspectRatio w="full" maxW="6" ratio={1} pos="absolute" top="-2" right="-2">
								<Box p="1" bgColor="green.400" rounded="md">
									<Text fontSize="xs" fontWeight="bold" color="whiteAlpha.800">
										{staff.pointsBalance || 0}
									</Text>
								</Box>
							</AspectRatio>
							{isStaffSelected(staff._id) && (
								<>
									<Box pos="absolute" top="-0.5" left="-0.5" w=".8em" h=".8em" bgColor="white" rounded="full" />
									<Icon
										pos="absolute"
										top="-1"
										left="-1"
										color="green.400"
										fontSize="lg"
										as={(props: any) => <FontAwesomeIcon icon={faCheckCircle} {...props} />}
									/>
								</>
							)}
							<VStack spacing={0} textAlign="center">
								<StaffTag staff={staff} direction="column" isLinkable={false} showFirstName />
							</VStack>
						</Card>
					))}

					{hasMore && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
				</Flex>
			</VStack>
			<RewardStaffModal isOpen={isOpen} onClose={onClose} staffs={selectedStaffs} />
		</>
	)
}
