import { AspectRatio, Avatar, Center, Container, Heading, HStack, Spinner, Tag, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React from "react"
import { useParams } from "react-router-dom"
import { DashboardHeader, Nav, Rating } from "../../components"
import { UploadTrainerAvatar } from "../../components/upload"
import { GRADIENT } from "../../constants"
import { useTrainerByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const Trainer: React.FC = () => {
	const { trainerId = "" } = useParams<{ trainerId: string }>()

	const [{ data, fetching, error }] = useTrainerByIdQuery({
		variables: { trainerId },
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={16}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.trainerById ? (
						<VStack w="full" align="flex-start" spacing="4">
							<HStack w="full" align="flex-start" spacing={4}>
								<UploadTrainerAvatar trainerId={trainerId}>
									<AspectRatio w="full" maxW={{ base: "28", md: "36" }} cursor="pointer" ratio={1}>
										<Avatar src={data.trainerById.picture?.url} name={data.trainerById.name} />
									</AspectRatio>
								</UploadTrainerAvatar>
								<VStack align="flex-start">
									<VStack align="flex-start" spacing="0">
										<Tag variant="solid" rounded="lg" size="sm" colorScheme="gray">
											Trainer
										</Tag>
										<Heading as="h1" fontSize="xl" color="text.400">
											{data.trainerById.name}
										</Heading>
										<Rating
											rating={
												(data?.trainerById?.ratings?.map((a) => a.rating)?.reduce((a, b) => a + b, 0) || 0) /
													(data?.trainerById?.ratings?.length || 0) || 0
											}
											total={data.trainerById.ratings?.length || 0}
										/>
									</VStack>
								</VStack>
							</HStack>
							<VStack bg="white.500" rounded="2xl" p="4" spacing={4} shadow="sm" w="full" maxW="lg" align="flex-start">
								<VStack align="flex-start" spacing={0}>
									<Text fontSize="sm" color="text.400" whiteSpace="pre-wrap">
										{data.trainerById.description}
									</Text>
								</VStack>
							</VStack>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the trainer.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
