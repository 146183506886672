import { Checkbox, Heading, Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { PaytankMerchantFragment } from "../../../graphql"
import { usePagination } from "../../../hooks"

export type PaytankMerchantsSelectorProps = {
	paytankMerchants: PaytankMerchantFragment[]
	selectedPaytankMerchantIds: string[]
	onChange: (paytankMerchantIds: string[]) => void
	removeHeading?: boolean
	isDefaultAllSelected?: boolean
}

export const PaytankMerchantsSelector: React.FC<PaytankMerchantsSelectorProps> = ({
	paytankMerchants,
	selectedPaytankMerchantIds,
	onChange,
	removeHeading,
	isDefaultAllSelected,
}) => {
	const selectPaytankMerchant = (merchantId: string) => {
		onChange([...selectedPaytankMerchantIds, merchantId])
	}

	const deselectPaytankMerchant = (merchantId: string) => {
		onChange(selectedPaytankMerchantIds.filter((_merchantId) => _merchantId !== merchantId))
	}

	const isPaytankMerchantSelected = useCallback((merchantId: string) => selectedPaytankMerchantIds.includes(merchantId), [selectedPaytankMerchantIds])

	const selectAllPaytankMerchants = () => {
		onChange(paytankMerchants.map(({ _id }) => _id) ?? [])
	}

	const isAllChecked = useMemo(
		() => Boolean(selectedPaytankMerchantIds.length) && paytankMerchants.map(({ _id }) => _id).every((id) => selectedPaytankMerchantIds.includes(id)),
		[selectedPaytankMerchantIds, paytankMerchants]
	)

	const isIndeterminate = useMemo(
		() => selectedPaytankMerchantIds.some((id) => paytankMerchants.map(({ _id }) => _id).includes(id)) && !isAllChecked,
		[selectedPaytankMerchantIds, paytankMerchants]
	)

	useEffect(() => {
		if (isDefaultAllSelected) onChange(paytankMerchants.map(({ _id }) => _id))
	}, [JSON.stringify(paytankMerchants), isDefaultAllSelected])

	const { currentItems, loadMore, hasMore } = usePagination<PaytankMerchantFragment>(paytankMerchants, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
				{removeHeading ? "" : "Select the paytank merchants"}
			</Heading>
			<VStack w="full" align="stretch" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th>
								<Checkbox
									colorScheme="blackAlpha"
									borderColor="black"
									isChecked={isAllChecked}
									isIndeterminate={isIndeterminate}
									onChange={(e) => (e.target.checked ? selectAllPaytankMerchants() : onChange([]))}
								/>
							</Th>
							<Th textTransform="capitalize">Name</Th>
							<Th textTransform="capitalize">Username</Th>
						</Tr>
					</Thead>
					<Tbody>
						{currentItems.map((merchant) => (
							<Tr key={merchant._id} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td>
									<Checkbox
										colorScheme="blackAlpha"
										borderColor="black"
										isChecked={isPaytankMerchantSelected(merchant._id)}
										onChange={(e) => (e.target.checked ? selectPaytankMerchant(merchant._id) : deselectPaytankMerchant(merchant._id))}
									/>
								</Td>

								<Td>{merchant.name}</Td>
								<Td>@{merchant.username}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
				{hasMore && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
			</VStack>
		</VStack>
	)
}
