import React, { createContext, Dispatch, useReducer } from "react"
import { initialUploadState, uploadReducer } from "./reducer"
import { UploadAction, UploadState } from "./types"

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const UploadContext = createContext<[UploadState, Dispatch<UploadAction>]>([initialUploadState, () => {}])

export const UploadContextProvider: React.FC<{ children: any }> = ({ children }) => {
	const [state, dispatch] = useReducer(uploadReducer, initialUploadState)

	return <UploadContext.Provider value={[state, dispatch]}>{children}</UploadContext.Provider>
}

export * from "./actions"
export * from "./reducer"
export * from "./types"
