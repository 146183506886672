import { useBreakpointValue } from "@chakra-ui/react"
import { CategoryScale, Chart as ChartJS, ChartData, Filler, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from "chart.js"
import { format } from "date-fns"
import React, { useMemo } from "react"
import { Line } from "react-chartjs-2"
import { PaytankOrderTypes, useDateWisePaytankOrdersQuery } from "../../../graphql"
import { Card } from "../../common"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend)

export type DateWisePaytankOrdersProps = {
	sinceDate: Date
	untilDate: Date
}

export const DateWisePaytankOrders: React.FC<DateWisePaytankOrdersProps> = ({ sinceDate, untilDate }) => {
	const [{ data }] = useDateWisePaytankOrdersQuery({ variables: { sinceDate, untilDate } })

	const chartData = useMemo(
		() =>
			({
				labels: data?.dateWisePaytankOrders.map((o) => format(new Date(o.date), "MMM dd")) || [],
				datasets: [
					{
						fill: true,
						label: "Credit",
						data:
							data?.dateWisePaytankOrders.map(({ orders }) =>
								orders
									.filter((o) => o.type === PaytankOrderTypes.Credit)
									.map((o) => o.amount)
									.reduce((a, b) => a + b, 0)
							) || [],
						backgroundColor: "#95D76B70",
						borderColor: "#95D76B",
					},
					{
						fill: true,
						label: "Debit",
						data:
							data?.dateWisePaytankOrders.map(({ orders }) =>
								orders
									.filter((o) => o.type === PaytankOrderTypes.Debit)
									.map((o) => o.amount)
									.reduce((a, b) => a + b, 0)
							) || [],
						backgroundColor: "#EA655E70",
						borderColor: "#EA655E",
					},
				],
			} as ChartData<"line", number[], string>),
		[data]
	)

	const isMobile = useBreakpointValue({ base: true, md: false })

	return (
		<Card maxW="full" disableAnimation>
			<Line
				options={{
					responsive: true,
					plugins: {
						legend: {
							position: "top" as const,
						},
						title: {
							display: true,
							text: "Credit Vs. Debit transactions (in ₹)",
						},
					},
					aspectRatio: isMobile ? 4 / 3 : 20 / 9,
				}}
				data={chartData}
			/>
		</Card>
	)
}
