import { Button, Container, Heading, HStack, Icon, LinkBox, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React from "react"
import { ArrowRight } from "react-feather"
import { NavLink } from "react-router-dom"
import { Card, DashboardHeader, Nav } from "../../components"
import { GRADIENT } from "../../constants"
import { useDrawer } from "../../hooks"

export const Reports: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Reports
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								{
									// TODO: add sub heading
								}
							</Heading>
						</VStack>
					</Stack>
					<VStack w="full" align="center" spacing={8}>
						<VStack w="full" align="flex-start">
							<Heading fontSize="sm" color="blackAlpha.700">
								Students
							</Heading>
							<HStack w="full" align="stretch">
								<LinkBox>
									<Card>
										<VStack align="flex-start" spacing="6">
											<Text fontSize="sm" color="text.700" fontWeight="semibold">
												Attendances
											</Text>

											<VStack align="flex-start" spacing="0">
												<Button
													as={NavLink}
													to="/reports/studentAttendances/complete"
													colorScheme="primary"
													size="xs"
													variant="link"
													rightIcon={<Icon as={ArrowRight} />}
												>
													Complete
												</Button>
												<Button
													as={NavLink}
													to="/reports/studentAttendances/lateComers"
													colorScheme="primary"
													size="xs"
													variant="link"
													rightIcon={<Icon as={ArrowRight} />}
												>
													Late Comers
												</Button>
												<Button
													as={NavLink}
													to="/reports/studentAttendances/absents"
													colorScheme="primary"
													size="xs"
													variant="link"
													rightIcon={<Icon as={ArrowRight} />}
												>
													Absents
												</Button>
												<Button
													as={NavLink}
													to="/reports/studentAttendances/presents"
													colorScheme="primary"
													size="xs"
													variant="link"
													rightIcon={<Icon as={ArrowRight} />}
												>
													Presents
												</Button>
												<Button
													as={NavLink}
													to="/reports/studentAttendances/classWiseStats"
													colorScheme="primary"
													size="xs"
													variant="link"
													rightIcon={<Icon as={ArrowRight} />}
												>
													Class Wise Stats
												</Button>
											</VStack>
										</VStack>
									</Card>
								</LinkBox>
								<LinkBox>
									<Card>
										<VStack align="flex-start" spacing="6">
											<VStack align="flex-start" spacing="0">
												<Text fontSize="sm" color="text.700" fontWeight="semibold">
													Restricted Entries
												</Text>
											</VStack>
											<Button
												as={NavLink}
												to="/reports/studentRestrictedEntries"
												colorScheme="primary"
												size="xs"
												variant="link"
												rightIcon={<Icon as={ArrowRight} />}
											>
												Generate now
											</Button>
										</VStack>
									</Card>
								</LinkBox>
								<LinkBox>
									<Card>
										<VStack align="flex-start" spacing="6">
											<VStack align="flex-start" spacing="0">
												<Text fontSize="sm" color="text.700" fontWeight="semibold">
													Telegram Messages
												</Text>
											</VStack>
											<Button
												as={NavLink}
												to="/reports/studentTelegramMessages"
												colorScheme="primary"
												size="xs"
												variant="link"
												rightIcon={<Icon as={ArrowRight} />}
											>
												Generate now
											</Button>
										</VStack>
									</Card>
								</LinkBox>
							</HStack>
						</VStack>
						<VStack w="full" align="flex-start">
							<Heading fontSize="sm" color="blackAlpha.700">
								Staff
							</Heading>
							<HStack w="full" align="stretch">
								<LinkBox>
									<Card>
										<VStack align="flex-start" spacing="6">
											<Text fontSize="sm" color="text.700" fontWeight="semibold">
												Attendances
											</Text>

											<VStack align="flex-start" spacing="0">
												<Button
													as={NavLink}
													to="/reports/staffAttendances/complete"
													colorScheme="primary"
													size="xs"
													variant="link"
													rightIcon={<Icon as={ArrowRight} />}
												>
													Complete
												</Button>
												<Button
													as={NavLink}
													to="/reports/staffAttendances/lateComers"
													colorScheme="primary"
													size="xs"
													variant="link"
													rightIcon={<Icon as={ArrowRight} />}
												>
													Late Comers
												</Button>
												<Button
													as={NavLink}
													to="/reports/staffAttendances/absents"
													colorScheme="primary"
													size="xs"
													variant="link"
													rightIcon={<Icon as={ArrowRight} />}
												>
													Absents
												</Button>
											</VStack>
										</VStack>
									</Card>
								</LinkBox>
								<LinkBox>
									<Card>
										<VStack align="flex-start" spacing="6">
											<VStack align="flex-start" spacing="0">
												<Text fontSize="sm" color="text.700" fontWeight="semibold">
													Restricted Entries
												</Text>
											</VStack>
											<Button
												as={NavLink}
												to="/reports/staffAttendances"
												colorScheme="primary"
												size="xs"
												variant="link"
												rightIcon={<Icon as={ArrowRight} />}
											>
												Generate now
											</Button>
										</VStack>
									</Card>
								</LinkBox>
							</HStack>
						</VStack>
						<VStack w="full" align="flex-start">
							<Heading fontSize="sm" color="blackAlpha.700">
								Paytank
							</Heading>
							<HStack w="full" align="stretch">
								<LinkBox>
									<Card>
										<VStack align="flex-start" spacing="6">
											<Text fontSize="sm" color="text.700" fontWeight="semibold">
												Transactions
											</Text>

											<VStack align="flex-start" spacing="0">
												<Button
													as={NavLink}
													to="/reports/paytankTransactions"
													colorScheme="primary"
													size="xs"
													variant="link"
													rightIcon={<Icon as={ArrowRight} />}
												>
													Generate now
												</Button>
											</VStack>
										</VStack>
									</Card>
								</LinkBox>
							</HStack>
						</VStack>
					</VStack>
				</VStack>
			</HStack>
		</Container>
	)
}
