import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	HStack,
	Icon,
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import TimePicker from "react-time-picker"
import * as Yup from "yup"
import { SchoolTimingFragment, UpdateSchoolTimingMutationVariables, useUpdateSchoolTimingMutation } from "../../graphql"

export type UpdateSchoolTimingFormProps = {
	schoolTiming: SchoolTimingFragment
}

const validationSchema = Yup.object({
	name: Yup.string().required().label("Name"),
	attendanceTiming: Yup.object({
		inAt: Yup.string().required().label("Arrival Time"),
		inStartBeforeMinutes: Yup.number()
			.min(0)
			.max(12 * 60)
			.required()
			.label("Arrival Start Buffer"),
		outAt: Yup.string().required().label("Departure Time"),
		outStartBeforeMinutes: Yup.number()
			.min(0)
			.max(12 * 60)
			.required()
			.label("Departure Start Buffer"),
	})
		.required()
		.label("Attendance Timing"),
	workingWeekdays: Yup.array().of(Yup.string()).required().min(1).label("Working weekdays"),
})

export const UpdateSchoolTimingForm: React.FC<UpdateSchoolTimingFormProps> = ({ schoolTiming }) => {
	const [{ fetching }, updateSchoolTiming] = useUpdateSchoolTimingMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async ({ name, attendanceTiming, workingWeekdays }: UpdateSchoolTimingMutationVariables["input"]) => {
		const { error, data } = await updateSchoolTiming({ schoolTimingId: schoolTiming._id, input: { name, attendanceTiming, workingWeekdays } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		if (!data?.updateSchoolTiming) {
			return toast({
				description: "Could not update the school timing",
				status: "error",
			})
		}

		return navigate("/schoolTimings/" + data.updateSchoolTiming._id)
	}

	const formik = useFormik<UpdateSchoolTimingMutationVariables["input"]>({
		initialValues: {
			name: schoolTiming.name,
			attendanceTiming: {
				inAt: schoolTiming.attendanceTiming.inAt,
				inStartBeforeMinutes: schoolTiming.attendanceTiming.inStartBeforeMinutes,
				notifyTeacherAfterMinutes: schoolTiming.attendanceTiming.notifyTeacherAfterMinutes,
				outAt: schoolTiming.attendanceTiming.outAt,
				outStartBeforeMinutes: schoolTiming.attendanceTiming.outStartBeforeMinutes,
			},
			workingWeekdays: schoolTiming.workingWeekdays || [],
		},
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<form onSubmit={formik.handleSubmit}>
			<VStack w="full" maxW="lg" align="stretch" spacing={4}>
				<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)}>
					<FormLabel>Name</FormLabel>
					<Input
						border="none"
						_focus={{ border: "none" }}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						autoComplete="off"
						{...formik.getFieldProps("name")}
					/>
					<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
				</FormControl>
				<HStack>
					<FormControl isInvalid={Boolean(formik.errors.attendanceTiming?.inAt && formik.touched.attendanceTiming?.inAt)}>
						<FormLabel>School In</FormLabel>
						<TimePicker
							className="default-time-picker"
							disableClock
							format="hh:mm a"
							clearIcon={null}
							{...formik.getFieldProps("attendanceTiming.inAt")}
							onChange={(time) => formik.setFieldValue("attendanceTiming.inAt", time)}
						/>

						<FormErrorMessage>{formik.errors.attendanceTiming?.inAt}</FormErrorMessage>
					</FormControl>
					<FormControl isInvalid={Boolean(formik.errors.attendanceTiming?.outAt && formik.touched.attendanceTiming?.outAt)}>
						<FormLabel>School Out</FormLabel>
						<TimePicker
							className="default-time-picker"
							disableClock
							format="hh:mm a"
							clearIcon={null}
							{...formik.getFieldProps("attendanceTiming.outAt")}
							onChange={(time) => formik.setFieldValue("attendanceTiming.outAt", time)}
						/>

						<FormErrorMessage>{formik.errors.attendanceTiming?.outAt}</FormErrorMessage>
					</FormControl>
				</HStack>
				<FormControl
					isInvalid={Boolean(formik.errors.attendanceTiming?.notifyTeacherAfterMinutes && formik.touched.attendanceTiming?.notifyTeacherAfterMinutes)}
				>
					<FormLabel>Notify to teacher after arrival time (in Minutes)</FormLabel>
					<NumberInput
						min={0}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						{...formik.getFieldProps("attendanceTiming.notifyTeacherAfterMinutes")}
						onChange={(valueStr) => formik.setFieldValue("attendanceTiming.notifyTeacherAfterMinutes", Number(valueStr))}
					>
						<NumberInputField />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>
					<FormErrorMessage>{formik.errors.attendanceTiming?.notifyTeacherAfterMinutes}</FormErrorMessage>
					<FormHelperText>Enter amount of minutes after which the class teacher should get notified</FormHelperText>
				</FormControl>
				<HStack w="full">
					<FormControl
						isInvalid={Boolean(formik.errors.attendanceTiming?.inStartBeforeMinutes && formik.touched.attendanceTiming?.inStartBeforeMinutes)}
					>
						<FormLabel>Reader Start Time for School-In (in Minutes)</FormLabel>
						<NumberInput
							min={0}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							{...formik.getFieldProps("attendanceTiming.inStartBeforeMinutes")}
							onChange={(valueStr) => formik.setFieldValue("attendanceTiming.inStartBeforeMinutes", Number(valueStr))}
						>
							<NumberInputField />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput>
						<FormErrorMessage>{formik.errors.attendanceTiming?.inStartBeforeMinutes}</FormErrorMessage>
						<FormHelperText>Enter amount of minutes the reader should start reading before school-in time</FormHelperText>
					</FormControl>
					<FormControl
						isInvalid={Boolean(formik.errors.attendanceTiming?.outStartBeforeMinutes && formik.touched.attendanceTiming?.outStartBeforeMinutes)}
					>
						<FormLabel>Reader Start Time for School-Out (in Minutes)</FormLabel>
						<NumberInput
							min={0}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							{...formik.getFieldProps("attendanceTiming.outStartBeforeMinutes")}
							onChange={(valueStr) => formik.setFieldValue("attendanceTiming.outStartBeforeMinutes", Number(valueStr))}
						>
							<NumberInputField />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput>
						<FormErrorMessage>{formik.errors.attendanceTiming?.outStartBeforeMinutes}</FormErrorMessage>
						<FormHelperText>Enter amount of minutes the reader should start reading before school-out time</FormHelperText>
					</FormControl>
				</HStack>
				<FormControl isInvalid={Boolean(formik.errors.workingWeekdays && formik.touched.workingWeekdays)}>
					<FormLabel>Working Weekdays</FormLabel>
					<Flex flexWrap="wrap">
						{["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map((day) => (
							<Button
								key={day}
								m="1"
								size="sm"
								shadow="none"
								variant="outline"
								borderWidth={formik.values.workingWeekdays.includes(day) ? 2 : 1}
								colorScheme={formik.values.workingWeekdays.includes(day) ? "green" : "red"}
								opacity={formik.values.workingWeekdays.includes(day) ? 1 : 0.4}
								onClick={() => {
									if (formik.values.workingWeekdays.includes(day)) {
										const workingWeekdays = [...formik.values.workingWeekdays]

										workingWeekdays.splice(workingWeekdays.indexOf(day), 1)

										formik.setFieldValue("workingWeekdays", workingWeekdays)
									} else {
										const updated = new Set(formik.values.workingWeekdays).add(day)

										formik.setFieldValue("workingWeekdays", [...updated])
									}
								}}
							>
								{day}
							</Button>
						))}
					</Flex>
					<FormErrorMessage>{formik.errors.workingWeekdays}</FormErrorMessage>
				</FormControl>
				<Button size="sm" type="submit" colorScheme="primary" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
					Update
				</Button>
			</VStack>
		</form>
	)
}
