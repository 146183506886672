import { Button, Icon, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useActivateWorkshopSessionMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type ActivateWorkshopSessionButtonProps = {
	workshopSessionId: string
}

export const ActivateWorkshopSessionButton: React.FC<ActivateWorkshopSessionButtonProps> = ({ workshopSessionId }) => {
	const [{ fetching }, activateWorkshopSession] = useActivateWorkshopSessionMutation()

	const toast = useToast()

	const handleActivateWorkshopSession = async () => {
		const { error } = await activateWorkshopSession({ workshopSessionId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		onClose()
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<Tooltip label="Activate" fontSize="sm">
				<Button
					size="xs"
					colorScheme="green"
					rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faCheckCircle} {...props} />} fontSize="sm" />}
					{...getButtonProps()}
				>
					Activate this session
				</Button>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Activate session"
				message="Are you sure you want to activate this session?"
				confirmLabel="Activate"
				onConfirm={handleActivateWorkshopSession}
				isLoading={fetching}
				isDanger={false}
			/>
		</>
	)
}
