import { AspectRatio, Box, Center, HStack, Icon, IconButton, Img, Progress, VStack } from "@chakra-ui/react"
import { faClose, faFilePdf } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { MediaTypes } from "../../graphql"
import { useUpload } from "../../hooks"

export type FeedAttachmentsProps = {
	onRemove: (id: string) => void
}

export const FeedAttachments: React.FC<FeedAttachmentsProps> = ({ onRemove }) => {
	const { uploads } = useUpload()

	if (uploads.length < 1) return <></>

	return (
		<Box w="full" overflowX="hidden">
			<HStack w="full" overflowX="auto">
				{uploads.map((upload) => {
					if (upload.error) return <></>

					const objectUrl = URL.createObjectURL(upload.file)

					return (
						<Box key={upload.id} pos="relative" w="20">
							{upload.type === MediaTypes.Image ? (
								<AspectRatio bg="primary.100" rounded="xl" w="20" ratio={1}>
									<Img rounded="xl" src={objectUrl} />
								</AspectRatio>
							) : upload.type === MediaTypes.Video ? (
								<AspectRatio bg="primary.100" rounded="xl" w="20" ratio={1}>
									<video style={{ borderRadius: "15%" }} width="100%" src={objectUrl} />
								</AspectRatio>
							) : upload.type === MediaTypes.Pdf ? (
								<Center rounded="xl" w="20" h="20" bg="primary.100">
									<Icon color="primary.600" fontSize="5xl" as={(props: any) => <FontAwesomeIcon icon={faFilePdf} {...props} />} />
								</Center>
							) : (
								<></>
							)}

							<Center rounded="xl" pos="absolute" inset={0} bg="whiteAlpha.400" px="1">
								{upload.isUploading ? (
									<VStack w="full" align="center">
										<Progress rounded="xl" w="full" h="2" value={upload.progress} colorScheme="primary" />
										{upload.onCancel && (
											<IconButton
												aria-label="cancel-upload-btn"
												variant="solid"
												colorScheme="blackAlpha"
												size="sm"
												onClick={upload.onCancel}
											>
												<Icon color="white" fontSize="2xl" as={(props: any) => <FontAwesomeIcon icon={faClose} {...props} />} />
											</IconButton>
										)}
									</VStack>
								) : (
									upload.isUploaded && (
										<IconButton aria-label="remove-upload-btn" variant="solid" colorScheme="blackAlpha" onClick={() => onRemove(upload.id)}>
											<Icon color="white" fontSize="2xl" as={(props: any) => <FontAwesomeIcon icon={faClose} {...props} />} />
										</IconButton>
									)
								)}
							</Center>
						</Box>
					)
				})}
			</HStack>
		</Box>
	)
}
