import { Avatar, Heading, HStack, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { ExtendedStudentFragment, StudentFragment } from "../../graphql"

export type DeactivatedStudentTagProps = {
	student: ExtendedStudentFragment | StudentFragment
	withAddress?: boolean
}

export const DeactivatedStudentTag: React.FC<DeactivatedStudentTagProps> = ({ student, withAddress }) => {
	return (
		<HStack align="flex-start">
			<Avatar src={student.picture?.url} name={student.name} size="sm" />
			<VStack w="full" align="stretch" spacing={0}>
				<Heading fontSize="sm" lineHeight="100%">
					{student.name}
				</Heading>
				<Heading fontSize="xs" color="blackAlpha.600">
					@{student.username}
				</Heading>
				{withAddress && (
					<Text fontSize="x-small" color="blackAlpha.600" maxW="48" lineHeight="100%" pt="1">
						{student.address}
					</Text>
				)}
			</VStack>
		</HStack>
	)
}
