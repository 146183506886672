import {
	CLEAR_ALL_UPLOADS,
	CLEAR_UPLOAD,
	UploadAction,
	UploadActionPayload,
	UPLOAD_CANCEL_FN_CREATED,
	UPLOAD_FAILED,
	UPLOAD_FINISHED,
	UPLOAD_PROGRESS,
	UPLOAD_STARTED,
} from "./types"

export const uploadStarted = ({ id, type, file }: UploadActionPayload): UploadAction => ({
	type: UPLOAD_STARTED,
	payload: { id, type, file },
})

export const uploadProgress = ({ id, progress }: UploadActionPayload): UploadAction => ({
	type: UPLOAD_PROGRESS,
	payload: { id, progress },
})

export const uploadFailed = ({ id, error }: UploadActionPayload): UploadAction => ({
	type: UPLOAD_FAILED,
	payload: { id, error },
})

export const uploadFinished = ({ id, key, url }: UploadActionPayload): UploadAction => ({
	type: UPLOAD_FINISHED,
	payload: { id, key, url },
})

export const uploadCancelFnCreated = ({ id, onCancel }: UploadActionPayload): UploadAction => ({
	type: UPLOAD_CANCEL_FN_CREATED,
	payload: { id, onCancel },
})

export const clearUpload = ({ id }: UploadActionPayload): UploadAction => ({
	type: CLEAR_UPLOAD,
	payload: { id },
})

export const clearAllUploads = (): UploadAction => ({
	type: CLEAR_ALL_UPLOADS,
})
