import {
	Box,
	Center,
	Container,
	Heading,
	HStack,
	Icon,
	Input,
	InputGroup,
	InputRightAddon,
	Spinner,
	Stack,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns"
import React, { useState } from "react"
import { DashboardHeader, Nav } from "../../components"
import { StaffTag } from "../../components/staff"
import { GRADIENT } from "../../constants"
import { useWhitelistedStaffsWithoutPaginationQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const WhitelistedStaff: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [searchKeyword, setSearchKeyword] = useState("")

	const [{ data, fetching }] = useWhitelistedStaffsWithoutPaginationQuery({ variables: { keyword: searchKeyword } })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" maxW="xl" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Whitelisted Staffs
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								{
									// TODO: add sub heading
								}
							</Heading>
						</VStack>
						<InputGroup maxW="xs" bg="white.500" rounded="xl" py="1">
							<Input placeholder="Search students" border="none" _focus={{ border: "none" }} onChange={(e) => setSearchKeyword(e.target.value)} />
							<InputRightAddon bg="transparent" border="none">
								<Icon as={(props: any) => <FontAwesomeIcon icon={faSearch} {...props} />} color="text.100" fontSize="lg" />
							</InputRightAddon>
						</InputGroup>
					</Stack>
					<VStack w="full" align="center">
						{fetching ? (
							<Center py="4">
								<Spinner color="text.400" />
							</Center>
						) : data?.whitelistedStaffsWithoutPagination && data?.whitelistedStaffsWithoutPagination.length > 0 ? (
							<Box w="full" overflow="auto">
								<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
									<Thead>
										<Tr>
											<Th textTransform="capitalize">Staff</Th>
											<Th textTransform="capitalize">Designation</Th>
											<Th textTransform="capitalize">Phone</Th>
											<Th textTransform="capitalize">Email</Th>
											<Th textTransform="capitalize">Linked since</Th>
										</Tr>
									</Thead>
									<Tbody>
										{data?.whitelistedStaffsWithoutPagination.map((staff) => (
											<Tr
												key={staff._id}
												_hover={{ bg: "gray.100" }}
												transition="background-color 200ms ease-in"
												cursor="pointer"
												fontSize="sm"
											>
												<Td>
													<StaffTag staff={staff} />
												</Td>
												<Td>{staff.designation}</Td>

												<Td>{staff.phone || "-"}</Td>
												<Td>{staff.email || "-"}</Td>
												<Td>{staff.createdAt && format(new Date(staff.createdAt), "PP")}</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							</Box>
						) : (
							<Center py="4">
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any staff
								</Text>
							</Center>
						)}
					</VStack>
				</VStack>
			</HStack>
		</Container>
	)
}
