import { Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import React from "react"
import { X } from "react-feather"
import { useDeleteWorkshopSessionTrainerMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type DeleteWorkshopTrainerButtonProps = {
	workshopSessionId: string
	trainerId: string
}

export const DeleteWorkshopTrainerButton: React.FC<DeleteWorkshopTrainerButtonProps> = ({ workshopSessionId, trainerId }) => {
	const [{ fetching }, deleteTrainer] = useDeleteWorkshopSessionTrainerMutation()

	const toast = useToast()

	const handledeleteTrainer = async () => {
		const { error } = await deleteTrainer({ workshopSessionId, trainerId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		onClose()
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<Tooltip label="Delete trainer" fontSize="sm">
				<IconButton aria-label="delete-trainer" position="absolute" top="-1" right="-1" colorScheme="red" size="xs" zIndex={1} {...getButtonProps()}>
					<Icon as={X} />
				</IconButton>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Delete trainer"
				message="Are you sure you want to delete this trainer?"
				confirmLabel="Delete"
				onConfirm={handledeleteTrainer}
				isLoading={fetching}
			/>
		</>
	)
}
