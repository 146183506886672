import {
	AspectRatio,
	Avatar,
	Button,
	Center,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	Icon,
	Input,
	Progress,
	SlideFade,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faUpload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { AddShowcaseAuthorMutationVariables, useAddShowcaseAuthorMutation } from "../../graphql"
import { useUpload } from "../../hooks"
import { UploadPhoto } from "../upload"

const validationSchema = Yup.object({
	name: Yup.string().label("name"),
	description: Yup.string().label("Description"),
})

export type AddShowcaseAuthorFormProps = {
	showcaseId: string
}

export const AddShowcaseAuthorForm: React.FC<AddShowcaseAuthorFormProps> = ({ showcaseId }) => {
	const [{ fetching }, addAuthor] = useAddShowcaseAuthorMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const { clearAllUploads, uploads } = useUpload()

	const handleSubmit = async ({ name }: Omit<AddShowcaseAuthorMutationVariables["input"], "picture">) => {
		if (!uploads.length || !uploads[0].isUploaded || !uploads[0].key || !uploads[0].url) {
			return toast({
				description: "Photo is required",
				status: "error",
			})
		}

		const { error } = await addAuthor({ showcaseId, input: { name, picture: { key: uploads[0].key, url: uploads[0].url } } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		clearAllUploads()
		navigate(`/showcases/${showcaseId}`)
	}

	const formik = useFormik<Omit<AddShowcaseAuthorMutationVariables["input"], "picture">>({
		initialValues: { name: "" },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" maxW="md" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<UploadPhoto max={1}>
				<AspectRatio w={24} cursor="pointer" ratio={1}>
					{uploads[0]?.url ? (
						<Avatar src={uploads[0].url} />
					) : (
						<Center pos="relative" w={24} rounded="lg" bgColor="whiteAlpha.800">
							<Icon as={(props: any) => <FontAwesomeIcon icon={faUpload} {...props} />} />
							{uploads[0]?.progress && !uploads[0]?.isUploaded ? (
								<Center pos="absolute" top="0" left="0" right="0" bottom="0">
									<Progress rounded="xl" w="full" marginX="8" h="2" value={uploads[0]?.progress} colorScheme="primary" />
								</Center>
							) : (
								<></>
							)}
						</Center>
					)}
				</AspectRatio>
			</UploadPhoto>
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)}>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="name" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Add
			</Button>
		</VStack>
	)
}
