import { Center, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useSearchPaytankOrdersQuery } from "../../../graphql"
import { PaytankOrdersTable } from "./PaytankOrdersTable"

export type SearchResultPaytankOrdersProps = {
	searchKeyword: string
}

export const SearchResultPaytankOrders: React.FC<SearchResultPaytankOrdersProps> = ({ searchKeyword }) => {
	const [{ data, fetching }] = useSearchPaytankOrdersQuery({
		variables: { keyword: searchKeyword },
	})

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="md" fontWeight="normal" color="text.500">
				Showing paytank transactions for search keyword <strong>&quot;{searchKeyword}&quot;</strong>
			</Heading>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.searchPaytankOrders && data?.searchPaytankOrders.length > 0 ? (
				<PaytankOrdersTable
					paytankOrders={data.searchPaytankOrders}
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					prevPage={() => {}}
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					nextPage={() => {}}
					hasPrevPage={false}
					hasNextPage={false}
					currentPage={1}
					totalPages={1}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any paytank transaction.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
