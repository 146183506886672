import { Checkbox, Heading, Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import React, { useCallback, useEffect, useMemo } from "react"
import { CafeteriaRegistrationStatus } from "../../graphql"

export type CafeteriaRegistrationStatusSelectorProps = {
	statuses: CafeteriaRegistrationStatus[]
	selectedStatuses: CafeteriaRegistrationStatus[]
	onChange: (status: CafeteriaRegistrationStatus[]) => void
	removeHeading?: boolean
	isDefaultAllSelected?: boolean
}

export const CafeteriaRegistrationStatusSelector: React.FC<CafeteriaRegistrationStatusSelectorProps> = ({
	statuses,
	selectedStatuses,
	onChange,
	removeHeading,
	isDefaultAllSelected,
}) => {
	const selectStatus = (status: CafeteriaRegistrationStatus) => {
		onChange([...selectedStatuses, status])
	}

	const deselectStatus = (status: CafeteriaRegistrationStatus) => {
		onChange(selectedStatuses.filter((_status) => _status !== status))
	}

	const isStatusSelected = useCallback((status: CafeteriaRegistrationStatus) => selectedStatuses.includes(status), [selectedStatuses])

	const selectAllStatuses = () => {
		onChange(statuses ?? [])
	}

	const isAllChecked = useMemo(
		() => Boolean(selectedStatuses.length) && statuses.every((_status) => selectedStatuses.includes(_status)),
		[selectedStatuses, statuses]
	)

	const isIndeterminate = useMemo(() => selectedStatuses.some((_status) => statuses.includes(_status)) && !isAllChecked, [selectedStatuses, statuses])

	useEffect(() => {
		if (isDefaultAllSelected) onChange(statuses)
	}, [JSON.stringify(statuses), isDefaultAllSelected])

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
				{removeHeading ? "" : "Select the cafeteria registration status"}
			</Heading>
			<VStack w="full" align="stretch" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th>
								<Checkbox
									colorScheme="blackAlpha"
									borderColor="black"
									isChecked={isAllChecked}
									isIndeterminate={isIndeterminate}
									onChange={(e) => (e.target.checked ? selectAllStatuses() : onChange([]))}
								/>
							</Th>
							<Th textTransform="capitalize">Status</Th>
						</Tr>
					</Thead>
					<Tbody>
						{statuses.map((status) => (
							<Tr key={status} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td>
									<Checkbox
										colorScheme="blackAlpha"
										borderColor="black"
										isChecked={isStatusSelected(status)}
										onChange={(e) => (e.target.checked ? selectStatus(status) : deselectStatus(status))}
									/>
								</Td>

								<Td>{status}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</VStack>
		</VStack>
	)
}
