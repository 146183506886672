import { Box, Container, Heading, HStack, Icon, Spinner, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, useBreakpointValue, VStack } from "@chakra-ui/react"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React, { useState } from "react"
import DateTimePicker from "react-datetime-picker"
import { DashboardHeader, Nav } from "../../components"
import { StaffTag } from "../../components/staff"
import { GRADIENT } from "../../constants"
import { useStaffAttendanceStatsQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const StaffAbsent: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [date, setDate] = useState(new Date(format(new Date(), "MM/dd/yyyy")))

	const [{ data, fetching, error }] = useStaffAttendanceStatsQuery({ variables: { date } })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Absent Staff
							</Heading>
						</VStack>
						<Stack alignSelf="flex-end" maxW="xs" mr="5" pr="5">
							<DateTimePicker onChange={setDate} value={date} format="MMM dd, y" maxDate={new Date()} clearIcon={null} />
						</Stack>
					</Stack>
					{fetching ? (
						<VStack flex={8} w="full" maxW="2xl">
							<Spinner />
							<Text textAlign="center">Loading today&apos;s attendance</Text>
						</VStack>
					) : error ? (
						<VStack flex={8} w="full" maxW="2xl" color="red.800">
							<Icon as={(props: any) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />} />
							<Text textAlign="center">{error?.message.replace("[GraphQL] ", "")}</Text>
						</VStack>
					) : data?.staffAttendanceStats.absent.length ? (
						<Box w="full" overflow="auto">
							<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
								<Thead>
									<Tr>
										<Th textTransform="capitalize">Staff</Th>
										<Th textTransform="capitalize">Email</Th>
										<Th textTransform="capitalize">Phone</Th>
										<Th textTransform="capitalize">Created</Th>
										<Th textTransform="capitalize">Updated</Th>
									</Tr>
								</Thead>
								<Tbody>
									{data.staffAttendanceStats.absent.map((staff) => (
										<Tr
											key={staff._id}
											_hover={{ bg: "gray.100" }}
											transition="background-color 200ms ease-in"
											cursor="pointer"
											fontSize="sm"
										>
											<Td>
												<StaffTag staff={staff} />
											</Td>
											<Td>{staff.email || "-"}</Td>
											<Td>{staff.phone}</Td>
											<Td>{format(new Date(staff.createdAt), "PP")}</Td>
											<Td>{format(new Date(staff.updatedAt), "PP")}</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						</Box>
					) : (
						<VStack flex={8} w="full" maxW="2xl" color="red.800">
							<Icon as={(props: any) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />} />
							<Text textAlign="center">No absent teachers found</Text>
						</VStack>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
