import { Button, Text, Icon, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, VStack, useToast } from "@chakra-ui/react"
import React from "react"
import { ArrowRight, Lock } from "react-feather"
import { ExtendedStudentFragment, useResetPasswordForStudentMutation } from "../../graphql"

export type StudentPasswordResetPopoverProps = { student: ExtendedStudentFragment }

export const StudentPasswordResetPopover: React.FC<StudentPasswordResetPopoverProps> = ({ student }) => {
	const toast = useToast()
	const [{ fetching }, resetPassword] = useResetPasswordForStudentMutation()
	const handleResetPassword = async () => {
		await resetPassword({ studentId: student._id, studentUsername: student.username })
		toast({ description: "Password successfully reset", status: "success" })
	}
	return (
		<Popover isLazy lazyBehavior="unmount">
			<PopoverTrigger>
				<Button m="1" size="xs" variant="solid" colorScheme="purple" rightIcon={<Icon as={Lock} />}>
					Reset Password
				</Button>
			</PopoverTrigger>
			<PopoverContent bgColor="white">
				<PopoverArrow bgColor="white" />
				<PopoverBody>
					<VStack w="full">
						<Text fontSize="md" fontWeight="normal" textColor="text.500">
							New password will be same as the student username
						</Text>
						<Button w="full" onClick={handleResetPassword} colorScheme="primary" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
							Reset
						</Button>
					</VStack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
