import { Checkbox, Heading, Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { format } from "date-fns"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { PaytankMerchantFragment } from "../../graphql"
import { usePagination } from "../../hooks"

export type PaytankMerchantSelectorProps = {
	merchants: PaytankMerchantFragment[]
	selectedMerchantIds: string[]
	onChange: (merchantIds: string[]) => void
	removeHeading?: boolean
}

export const PaytankMerchantSelector: React.FC<PaytankMerchantSelectorProps> = ({ merchants, selectedMerchantIds, onChange, removeHeading }) => {
	const isAllChecked = useMemo(
		() => Boolean(selectedMerchantIds.length) && merchants.map(({ _id }) => _id).every((id) => selectedMerchantIds.includes(id)),
		[selectedMerchantIds, merchants]
	)

	const isIndeterminate = useMemo(
		() => selectedMerchantIds.some((id) => merchants.map(({ _id }) => _id).includes(id)) && !isAllChecked,
		[selectedMerchantIds, merchants]
	)

	const selectMerchant = (merchantId: string) => {
		const _selectMerchantIds = new Set(selectedMerchantIds)
		_selectMerchantIds.add(merchantId)

		onChange([..._selectMerchantIds])
	}

	const deselectMerchant = (merchantId: string) => {
		const _selectMerchantIds = [...selectedMerchantIds]

		_selectMerchantIds.splice(_selectMerchantIds.indexOf(merchantId), 1)

		onChange(_selectMerchantIds)
	}

	const selectAllMerchants = () => {
		onChange(merchants.map(({ _id }) => _id) ?? [])
	}

	const isMerchantselected = useCallback((merchantId: string) => selectedMerchantIds.includes(merchantId), [selectedMerchantIds])

	useEffect(() => {
		onChange(selectedMerchantIds)
	}, [selectedMerchantIds])

	const { currentItems, loadMore, hasMore } = usePagination<PaytankMerchantFragment>(merchants, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
				{removeHeading ? "" : "Select the merchants you want to generate the report for"}
			</Heading>
			<VStack w="full" align="stretch" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th>
								<Checkbox
									colorScheme="blackAlpha"
									borderColor="black"
									isChecked={isAllChecked}
									isIndeterminate={isIndeterminate}
									onChange={(e) => (e.target.checked ? selectAllMerchants() : onChange([]))}
								/>
							</Th>
							<Th textTransform="capitalize">Merchant</Th>
							<Th textTransform="capitalize">Created</Th>
							<Th textTransform="capitalize">Updated</Th>
						</Tr>
					</Thead>
					<Tbody>
						{currentItems.map((merchant) => (
							<Tr key={merchant._id} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td>
									<Checkbox
										colorScheme="blackAlpha"
										borderColor="black"
										isChecked={isMerchantselected(merchant._id)}
										onChange={(e) => (e.target.checked ? selectMerchant(merchant._id) : deselectMerchant(merchant._id))}
									/>
								</Td>
								<Td>{merchant.name}</Td>
								<Td>{format(new Date(merchant.createdAt), "PP")}</Td>
								<Td>{format(new Date(merchant.updatedAt), "PP")}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
				{hasMore && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
			</VStack>
		</VStack>
	)
}
