import { Chart as ChartJS, Filler, Legend, LineElement, PointElement, RadialLinearScale, Tooltip } from "chart.js"
import React, { useMemo } from "react"
import { Radar } from "react-chartjs-2"
import { useClassWiseAttendanceStatsQuery } from "../../graphql"

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend)

export type ClassWiseAttendanceProps = {
	date: Date
}

export const ClassWiseAttendance: React.FC<ClassWiseAttendanceProps> = ({ date }) => {
	const [{ data }] = useClassWiseAttendanceStatsQuery({ variables: { date } })

	const zoneData = useMemo(
		() => ({
			labels: data?.classWiseAttendanceStats.map((o) => o.label),
			datasets: [
				{
					label: "Students",
					data: data?.classWiseAttendanceStats.map((o) => o.count),
					backgroundColor: "rgba(39, 209, 96, 0.2)",
					borderColor: "#16a816",
					borderWidth: 1,
				},
			],
		}),
		[data]
	)

	return <Radar data={zoneData} />
}
