import {
	AspectRatio,
	Avatar,
	Button,
	Center,
	Container,
	Heading,
	HStack,
	Icon,
	Spinner,
	Stack,
	Text,
	useBreakpointValue,
	useDisclosure,
	VStack,
} from "@chakra-ui/react"
import { faBowlFood } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useParams } from "react-router-dom"
import { CreateManualCafeteriaRegistrationOrderModal, DashboardHeader, Nav } from "../../components"
import {
	ActivateAndDeactivateStudentPopover,
	AssignNewTagPopover,
	ChangeTelegramMessageStatus,
	EditStudentClass,
	EditStudentHouse,
	SchoolTimingOfStudent,
	StudentDetails,
} from "../../components/student"
import { AllAttendancesOfStudent } from "../../components/student/AllAttendancesOfStudent"
import { StudentPointsModal } from "../../components/student/StudentPointsModal"
import { TodayAttendanceOfStudent } from "../../components/student/TodayAttendanceOfStudent"
import { UploadStudentAvatar } from "../../components/upload"
import { GRADIENT } from "../../constants"
import { useStudentByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const Student: React.FC = () => {
	const { studentId = "" } = useParams<{ studentId: string }>()

	const [{ data, fetching, error }] = useStudentByIdQuery({
		variables: { studentId },
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const { getButtonProps, isOpen, onClose } = useDisclosure()

	const {
		getButtonProps: cafeteriaRegistrationButtonProps,
		isOpen: isCafeteriaRegistrationModalOpen,
		onClose: onCafeteriaRegistrationModalClose,
	} = useDisclosure()

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semisemibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.studentById ? (
						<VStack w="full" align="stretch" spacing="4">
							<HStack w="full" align="flex-start" spacing={4}>
								<UploadStudentAvatar studentId={studentId}>
									<AspectRatio w="full" maxW={{ base: "24", md: "36" }} cursor="pointer" ratio={1}>
										<Avatar src={data.studentById.picture?.url} name={data.studentById.name} />
									</AspectRatio>
								</UploadStudentAvatar>
								<VStack align="flex-start">
									<VStack align="flex-start" spacing="0">
										<Heading as="h1" fontSize="xl" color="text.400">
											{data.studentById.name}
										</Heading>
										<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.400" maxW="xs">
											@{data.studentById.username}
										</Heading>
									</VStack>

									<HStack flexWrap="wrap">
										<EditStudentClass student={data.studentById} />
										<EditStudentHouse student={data.studentById} />
										<AssignNewTagPopover student={data.studentById} />
										<ActivateAndDeactivateStudentPopover student={data.studentById} />
									</HStack>

									<HStack flexWrap="wrap">
										<Button size="xs" variant="outline" colorScheme="purple" {...getButtonProps()}>
											{data.studentById.pointsBalance || 0} points
										</Button>

										<Button size="xs" variant="outline" colorScheme="purple">
											₹{data.studentById.paytankBalance || 0} Paytank balance
										</Button>

										<ChangeTelegramMessageStatus student={data.studentById} />
									</HStack>

									<HStack flexWrap="wrap">
										<SchoolTimingOfStudent student={data.studentById} />

										<Button
											size="xs"
											colorScheme="primary"
											rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faBowlFood} {...props} />} />}
											{...cafeteriaRegistrationButtonProps()}
										>
											Cafeteria
										</Button>
									</HStack>

									{isCafeteriaRegistrationModalOpen && (
										<CreateManualCafeteriaRegistrationOrderModal
											isOpen={isCafeteriaRegistrationModalOpen}
											onClose={onCafeteriaRegistrationModalClose}
											student={data.studentById}
										/>
									)}

									{isOpen && <StudentPointsModal isOpen={isOpen} onClose={onClose} studentId={studentId} />}
								</VStack>
							</HStack>
							<Stack direction={{ base: "column", lg: "row" }} w="full" align="stretch" spacing={6}>
								<StudentDetails student={data.studentById} />
								<VStack w="full" maxW="xl" align="stretch" spacing={6}>
									<TodayAttendanceOfStudent studentId={data.studentById._id} />
									<AllAttendancesOfStudent studentId={data.studentById._id} />
								</VStack>
							</Stack>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semisemibold" color="text.400">
								Couldn&apos;t find the student.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
