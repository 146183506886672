import { Box, Button, Flex, Heading, HStack, Icon, Popover, PopoverBody, PopoverContent, PopoverTrigger, Spinner, Text, VStack } from "@chakra-ui/react"
import { faExclamationCircle, faPencil, faSchoolLock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { useReadersHealthQuery } from "../../graphql"
import { Card } from "../common"
import { AddReaderPopover } from "./AddReaderPopover"
import { EditReaderLabelForm } from "./EditReaderLabelForm"
import { RemoveReader } from "./RemoveReader"

export const ReadersHealth: React.FC = () => {
	const [{ data, fetching, error }] = useReadersHealthQuery()

	return (
		<VStack w="full" align="flex-start" spacing={4}>
			<HStack w="full" maxW="2xl" justify="space-between">
				<Heading as="h2" fontSize="md" fontWeight="semibold" color="text.500">
					Attendance Readers
				</Heading>
				<AddReaderPopover />
			</HStack>

			<Flex flexWrap="wrap" w="full" align="flex-start">
				{fetching ? (
					<VStack flex={8} w="full" maxW="2xl">
						<Spinner />
						<Text textAlign="center">Loading readers health</Text>
					</VStack>
				) : error ? (
					<VStack flex={8} w="full" maxW="2xl" color="red.800">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />} />
						<Text textAlign="center">{error?.message.replace("[GraphQL] ", "")}</Text>
					</VStack>
				) : data?.readersHealth?.length ? (
					data.readersHealth.map((reader) => (
						<Card key={reader._id} w="full" maxW="xs" m="1" cursor="default">
							<VStack w="full" align="stretch" spacing={4}>
								<HStack w="full" justify="space-between">
									{reader.isActive ? (
										<HStack>
											<Box w="3" h="3" rounded="full" bgColor="green.500" />
											<Text fontSize="xs" color="text.400">
												Active
											</Text>
										</HStack>
									) : (
										<HStack>
											<Box w="3" h="3" rounded="full" bgColor="red.500" />
											<Text fontSize="xs" color="text.400">
												Inactive
											</Text>
										</HStack>
									)}
									<RemoveReader readerDecimalId={reader.readerDecimalId} />
								</HStack>

								<HStack w="full" align="center" spacing={4}>
									<Heading as="h5" fontSize="md" color="text.400">
										{reader.label || "No Label"}
									</Heading>

									<Popover isLazy lazyBehavior="unmount">
										<PopoverTrigger>
											<Button size="sm" variant="ghost" colorScheme="purple">
												<Icon as={(props: any) => <FontAwesomeIcon {...props} icon={faPencil} />} />
											</Button>
										</PopoverTrigger>
										<PopoverContent bgColor="white">
											<PopoverBody>
												<EditReaderLabelForm reader={reader} />
											</PopoverBody>
										</PopoverContent>
									</Popover>
								</HStack>

								<VStack w="full" align="stretch" spacing={0}>
									<Text fontSize="xl" fontWeight="semibold" lineHeight="100%">
										{reader.readerHexId}
									</Text>
									<Text fontSize="md" color="text.400">
										{reader.readerDecimalId}
									</Text>
								</VStack>
								<HStack>
									{reader.lastConnectedAt && reader.lastConnectionEndpoint && (
										<VStack w="full" align="stretch" spacing={0}>
											<Text fontSize="sm" fontWeight="semibold">
												{format(new Date(reader.lastConnectedAt), "PPp")}
											</Text>
											<Text fontSize="xs" color="text.400">
												Last Connection
											</Text>
										</VStack>
									)}

									{reader.lastHeartbeatAt && (
										<VStack w="full" align="stretch" spacing={0}>
											<Text fontSize="sm" fontWeight="semibold">
												{format(new Date(reader.lastHeartbeatAt), "PPp")}
											</Text>
											<Text fontSize="xs" color="text.400">
												Last Heartbeat
											</Text>
										</VStack>
									)}
								</HStack>
								{reader.isRestrictedZone && (
									<HStack>
										<Icon as={(props: any) => <FontAwesomeIcon icon={faSchoolLock} {...props} />} />
										<Text fontSize="xs" color="text.400">
											Restricted Zone
										</Text>
									</HStack>
								)}
							</VStack>
						</Card>
					))
				) : (
					<VStack flex={8} w="full" maxW="2xl" color="red.800">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />} />
						<Text textAlign="center">No readers found</Text>
					</VStack>
				)}
			</Flex>
		</VStack>
	)
}
