import { Button, Center, chakra, Container, Flex, Heading, HStack, Icon, Spinner, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import { faExclamationCircle, faFileCsv } from "@fortawesome/free-solid-svg-icons"
import DateTimePicker from "react-datetime-picker"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns"
import React, { useEffect, useState } from "react"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import { ClassesSelector, DashboardHeader, Nav, StudentAttendanceClassWiseStatsTable } from "../../../../components"
import { GRADIENT } from "../../../../constants"
import { LabelledStudentAttendanceClassWiseStatsFragment, useClassesQuery, useGenerateStudentAttendanceClassWiseStatsReportMutation } from "../../../../graphql"
import { useDrawer } from "../../../../hooks"
import { exportCSVData, parseStudentAttendanceClassWiseStatsReportAsCSV } from "../../../../utils"

export const StudentAttendanceClassWiseStatsReport: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [{ data: classesData, fetching: fetchingClasses, error: classesError }] = useClassesQuery()

	const [selectedClassIds, setSelectedClassIds] = useState<string[]>([])

	const [date, setDate] = useState(new Date(format(new Date(), "MM/dd/yyyy")))

	const [{ data: reportData, fetching: reportFetching, error: reportError }, generateReport] = useGenerateStudentAttendanceClassWiseStatsReportMutation()

	const [isGenerated, setIsGenerated] = useState(false)
	const [showReport, setShowReport] = useState(false)

	const handleReportGeneration = async () => {
		setShowReport(true)
		await generateReport({
			input: {
				classIds: selectedClassIds,
				date,
			},
		})
		setIsGenerated(true)
	}

	useEffect(() => {
		setIsGenerated(false)
	}, [date, selectedClassIds])

	const handleCSVExport = (data: LabelledStudentAttendanceClassWiseStatsFragment) => {
		const csv = parseStudentAttendanceClassWiseStatsReportAsCSV(data)

		exportCSVData(csv)
	}

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Student Attendance Class Wise Stats Report
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								{
									// TODO: add sub heading
								}
							</Heading>
						</VStack>
						{fetchingClasses ? (
							<Center>
								<VStack w="full" color="blackAlpha.800">
									<Text>Loading classes</Text>
									<Spinner size="sm" />
								</VStack>
							</Center>
						) : classesError ? (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
									Failed loading classes, try reloading the page
								</Text>
							</Center>
						) : classesData?.classes.length ? (
							<Flex flexWrap="wrap">
								<Button
									m="1"
									size="sm"
									colorScheme="primary"
									isLoading={reportFetching}
									onClick={handleReportGeneration}
									disabled={showReport && isGenerated}
								>
									Generate
								</Button>
								<Button
									m="1"
									size="sm"
									colorScheme="primary"
									variant="outline"
									disabled={!(showReport && isGenerated)}
									rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faFileCsv} {...props} />} />}
									onClick={
										reportData?.generateStudentAttendanceClassWiseStatsReport
											? () => handleCSVExport(reportData.generateStudentAttendanceClassWiseStatsReport)
											: undefined
									}
								>
									Export
								</Button>
								<Button
									m="1"
									size="sm"
									colorScheme="primary"
									variant="link"
									onClick={() => setShowReport(false)}
									disabled={!isGenerated || !showReport}
								>
									Clear
								</Button>

								<DateTimePicker
									onChange={setDate}
									value={date}
									format="MMM dd, y"
									maxDate={new Date()}
									clearIcon={null}
									disabled={showReport && isGenerated}
								/>
							</Flex>
						) : (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any classes.
								</Text>
							</Center>
						)}
					</Stack>
					<VStack w="full" align="center">
						{showReport && reportFetching ? (
							<VStack w="full" maxW="2xl">
								<Spinner />
								<Text textAlign="center">Generating report</Text>
							</VStack>
						) : showReport && reportError ? (
							<VStack w="full" maxW="2xl" color="red.800">
								<Icon as={(props: any) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />} />
								<Text textAlign="center">{reportError?.message.replace("[GraphQL] ", "")}</Text>
							</VStack>
						) : showReport ? (
							reportData?.generateStudentAttendanceClassWiseStatsReport ? (
								reportData.generateStudentAttendanceClassWiseStatsReport.classWiseStats.length ? (
									<VStack key={reportData.generateStudentAttendanceClassWiseStatsReport.label} w="full" align="flex-start" spacing={0}>
										<VStack bgColor="white" roundedTop="xl" p="2">
											<Heading fontSize="sm" color="blackAlpha.600">
												<chakra.span fontWeight="normal">Date</chakra.span> -{" "}
												{reportData.generateStudentAttendanceClassWiseStatsReport.label}
											</Heading>
										</VStack>
										<StudentAttendanceClassWiseStatsTable stats={reportData.generateStudentAttendanceClassWiseStatsReport} />
									</VStack>
								) : (
									<></>
								)
							) : (
								<Center>
									<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
										No data to show
									</Text>
								</Center>
							)
						) : fetchingClasses ? (
							<Center py="4">
								<Spinner color="text.400" />
							</Center>
						) : classesError ? (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
									Failed loading students, try reloading the page
								</Text>
							</Center>
						) : classesData?.classes && classesData?.classes.length > 0 ? (
							<ClassesSelector classes={classesData.classes} selectClassIds={selectedClassIds} onChange={setSelectedClassIds} />
						) : (
							<Center py="4">
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any students.
								</Text>
							</Center>
						)}
					</VStack>
				</VStack>
			</HStack>
		</Container>
	)
}
