import {
	Button,
	Center,
	chakra,
	Container,
	Flex,
	Heading,
	HStack,
	Icon,
	IconButton,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Spinner,
	Stack,
	Tag,
	Text,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react"
import { faCalendar, faExclamationCircle, faFileCsv } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format, subDays } from "date-fns"
import React, { useEffect, useState } from "react"
import { DateRange, Range } from "react-date-range"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import { Nav, DashboardHeader, RestrictedEntriesTable, StaffSelector } from "../../../components"
import { GRADIENT } from "../../../constants"
import {
	useStaffsWithoutPaginationQuery,
	useGenerateStaffRestrictedEntriesReportMutation,
	LabelledRestrictedEntriesFragment,
	AuthorTypes,
} from "../../../graphql"
import { useDrawer } from "../../../hooks"
import { parseRestrictedEntriesReportAsCSV, exportCSVData } from "../../../utils"
export const StaffRestrictedEntriesReport: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [selectedStaffIds, setSelectedStaffIds] = useState<string[]>([])

	const [{ data, fetching, error }] = useStaffsWithoutPaginationQuery()

	const [dateState, setDateState] = useState<Range>({
		startDate: subDays(new Date(), 10),
		endDate: new Date(),
		key: "selection",
	})

	const [{ data: reportData, fetching: reportFetching, error: reportError }, generateReport] = useGenerateStaffRestrictedEntriesReportMutation()

	const [isGenerated, setIsGenerated] = useState(false)
	const [showReport, setShowReport] = useState(false)

	const handleReportGeneration = async () => {
		setShowReport(true)
		await generateReport({
			input: {
				staffIds: selectedStaffIds,
				sinceDate: dateState.startDate,
				untilDate: dateState.endDate,
			},
		})
		setIsGenerated(true)
	}

	useEffect(() => {
		setIsGenerated(false)
	}, [dateState, selectedStaffIds])

	const handleCSVExport = (data: LabelledRestrictedEntriesFragment[]) => {
		const csv = parseRestrictedEntriesReportAsCSV(data, AuthorTypes.Staff)

		exportCSVData(csv)
	}

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Restricted Entries Report
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								For staff
							</Heading>
						</VStack>

						<Flex flexWrap="wrap">
							<Button
								m="1"
								size="sm"
								colorScheme="primary"
								isLoading={reportFetching}
								onClick={handleReportGeneration}
								disabled={showReport && isGenerated}
							>
								Generate
							</Button>
							<Button
								m="1"
								size="sm"
								colorScheme="primary"
								variant="outline"
								disabled={!(showReport && isGenerated)}
								rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faFileCsv} {...props} />} />}
								onClick={
									reportData?.generateStaffRestrictedEntriesReport
										? () => handleCSVExport(reportData?.generateStaffRestrictedEntriesReport)
										: undefined
								}
							>
								Export
							</Button>
							<Button
								m="1"
								size="sm"
								colorScheme="primary"
								variant="link"
								onClick={() => setShowReport(false)}
								disabled={!isGenerated || !showReport}
							>
								Clear
							</Button>

							{!(showReport && isGenerated) ? (
								<Popover placement="auto-end">
									<PopoverTrigger>
										{dateState.startDate && dateState.endDate && (
											<Tag m="1" bg="white.500" px="4" py="2" rounded="xl" cursor="pointer">
												<Text fontSize="sm">
													{format(dateState.startDate, "dd/MM/yyyy")} - {format(dateState.endDate, "dd/MM/yyyy")}
												</Text>
												<IconButton aria-label="calendar-button" size="xs" variant="ghost" colorScheme="whiteAlpha">
													<Icon
														color="blackAlpha.600"
														_hover={{ color: "blackAlpha.700" }}
														as={(props: any) => <FontAwesomeIcon icon={faCalendar} {...props} />}
													/>
												</IconButton>
											</Tag>
										)}
									</PopoverTrigger>
									<PopoverContent w="full" _focus={{ shadow: "none" }}>
										<PopoverBody w="full" p="0">
											<DateRange
												editableDateInputs={true}
												onChange={(item) => setDateState(item.selection)}
												moveRangeOnFirstSelection={false}
												ranges={[dateState]}
												maxDate={new Date()}
											/>
										</PopoverBody>
									</PopoverContent>
								</Popover>
							) : (
								dateState.startDate &&
								dateState.endDate && (
									<Tag m="1" bg="white.500" px="4" py="2" rounded="xl" cursor="pointer" opacity="0.4">
										<Text fontSize="sm">
											{format(dateState.startDate, "dd/MM/yyyy")} - {format(dateState.endDate, "dd/MM/yyyy")}
										</Text>
										<IconButton aria-label="calendar-button" size="xs" variant="ghost" colorScheme="whiteAlpha">
											<Icon
												color="blackAlpha.600"
												_hover={{ color: "blackAlpha.700" }}
												as={(props: any) => <FontAwesomeIcon icon={faCalendar} {...props} />}
											/>
										</IconButton>
									</Tag>
								)
							)}
						</Flex>
					</Stack>
					<VStack w="full" align="center">
						{showReport && reportFetching ? (
							<VStack w="full" maxW="2xl">
								<Spinner />
								<Text textAlign="center">Generating report</Text>
							</VStack>
						) : showReport && reportError ? (
							<VStack w="full" maxW="2xl" color="red.800">
								<Icon as={(props: any) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />} />
								<Text textAlign="center">{reportError?.message.replace("[GraphQL] ", "")}</Text>
							</VStack>
						) : showReport ? (
							reportData?.generateStaffRestrictedEntriesReport && reportData.generateStaffRestrictedEntriesReport.length ? (
								reportData?.generateStaffRestrictedEntriesReport.map(({ label, restrictedEntries }) => (
									<VStack key={label} w="full" align="flex-start" spacing={0}>
										<VStack bgColor="white" roundedTop="xl" p="2">
											<Heading fontSize="sm" color="blackAlpha.600">
												<chakra.span fontWeight="normal">Date</chakra.span> - {label}
											</Heading>
										</VStack>
										<RestrictedEntriesTable restrictedEntries={restrictedEntries} />
									</VStack>
								))
							) : (
								<Center>
									<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
										No data to show
									</Text>
								</Center>
							)
						) : fetching ? (
							<Center py="4">
								<Spinner color="text.400" />
							</Center>
						) : error ? (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
									Failed loading staff, try reloading the page
								</Text>
							</Center>
						) : data?.staffsWithoutPagination && data?.staffsWithoutPagination.length > 0 ? (
							<StaffSelector staffs={data?.staffsWithoutPagination} selectedStaffIds={selectedStaffIds} onChange={setSelectedStaffIds} />
						) : (
							<Center py="4">
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any staff.
								</Text>
							</Center>
						)}
					</VStack>
				</VStack>
			</HStack>
		</Container>
	)
}
