import { AspectRatio, Center, Heading, HStack, Icon, Spinner, Text, VStack } from "@chakra-ui/react"
import { faArrowRight, faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useMemo } from "react"
import { Chart } from "react-charts"
import { useMonthlyAttendanceStatsQuery } from "../../graphql"

export type MonthlyAttendanceGraphProps = {
	classId?: string
	classSectionId?: string
}

export const MonthlyAttendanceGraph: React.FC<MonthlyAttendanceGraphProps> = ({ classId, classSectionId }) => {
	const [{ data, fetching, error }] = useMonthlyAttendanceStatsQuery({
		variables: { classId, classSectionId },
	})

	type MonthlyAttendance = {
		date: string
		present: number
	}

	type Series = {
		label: string
		data: MonthlyAttendance[]
	}

	const graphData: Series[] = useMemo(
		() => [
			{
				label: "Present",
				data:
					data?.monthlyAttendanceStats.map(({ label, count }) => ({
						date: label,
						present: count,
					})) || [],
			},
		],
		[data]
	)

	const primaryAxis = React.useMemo(
		(): any => ({
			getValue: (datum: any) => datum.date,
		}),
		[]
	)

	const secondaryAxes = React.useMemo(
		(): any[] => [
			{
				getValue: (datum: any) => datum.present,
				elementType: "area",
			},
		],
		[]
	)

	return (
		<VStack w="full" align="stretch" px="2">
			<HStack>
				<Heading fontSize="md" fontWeight="semibold">
					This month
				</Heading>
				<Icon fontSize="md" as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} />
			</HStack>
			<Center w="full" p="4" bgColor="blackAplha.200" backdropFilter="blur(16px)" rounded="xl">
				{fetching ? (
					<VStack flex={8} w="full" maxW="2xl">
						<Spinner />
						<Text textAlign="center">Loading monthly attendance</Text>
					</VStack>
				) : error ? (
					<VStack flex={8} w="full" maxW="2xl" color="red.800">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />} />
						<Text textAlign="center">{error?.message.replace("[GraphQL] ", "")}</Text>
					</VStack>
				) : data?.monthlyAttendanceStats.length ? (
					<AspectRatio w="full" ratio={{ base: 16 / 9, lg: 20 / 6 }}>
						<Chart
							options={{
								data: graphData,
								primaryAxis,
								secondaryAxes,
								defaultColors: ["#16a81653"],
							}}
						/>
					</AspectRatio>
				) : (
					<VStack flex={8} w="full" maxW="2xl" color="red.800">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />} />
						<Text textAlign="center">Monthly attendance not available</Text>
					</VStack>
				)}
			</Center>
		</VStack>
	)
}
