import {
	Button,
	Center,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	Icon,
	Input,
	Select,
	SlideFade,
	Spinner,
	Text,
	Textarea,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { CreatePaytankMerchantMutationVariables, useCreatePaytankMerchantMutation, usePaytankMerchantTypesWithoutPaginationQuery } from "../../../graphql"

const validationSchema = Yup.object({
	name: Yup.string().required().label("Name"),
	description: Yup.string().nullable().label("Description"),
	username: Yup.string().required().label("Username"),
	typeId: Yup.string().required().label("Merchant Type"),
})

export const NewPaytankMerchantForm: React.FC = () => {
	const [{ fetching }, createNew] = useCreatePaytankMerchantMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (values: CreatePaytankMerchantMutationVariables["input"]) => {
		const { error } = await createNew({ input: values })

		if (error) {
			return toast({
				description: error.message,
				status: "error",
			})
		}

		return navigate("/paytank/merchants", { replace: true })
	}

	const formik = useFormik<CreatePaytankMerchantMutationVariables["input"]>({
		initialValues: { name: "", typeId: "", username: "", description: "" },
		validationSchema,
		onSubmit: handleSubmit,
	})

	const [{ data: typesData, fetching: fetchingTypes, error: typesError }] = usePaytankMerchantTypesWithoutPaginationQuery()

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)}>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="title" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>

			{fetchingTypes ? (
				<Center w="full" py="1">
					<VStack>
						<Spinner size="sm" color="text.400" />
						<Text fontSize="xs" color="text.400">
							Loading merchant types
						</Text>
					</VStack>
				</Center>
			) : typesError ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							{typesError.message.replace("[GraphQL] ", "")}
						</Text>
					</VStack>
				</Center>
			) : !typesData?.paytankMerchantTypesWithoutPagination ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							Could not load merchant types
						</Text>
					</VStack>
				</Center>
			) : (
				<FormControl isInvalid={Boolean(formik.errors.typeId && formik.touched.typeId)} isRequired>
					<SlideFade in={Boolean(formik.values.typeId)} unmountOnExit>
						<FormLabel htmlFor="typeId" fontSize="sm">
							Merchant Type
						</FormLabel>
					</SlideFade>
					<Select
						w="full"
						maxW="md"
						bg="white.500"
						rounded="xl"
						border="none"
						_focus={{ border: "none" }}
						placeholder="Select Type"
						{...formik.getFieldProps("typeId")}
					>
						{typesData.paytankMerchantTypesWithoutPagination.map((type) => (
							<option key={type._id} value={type._id}>
								{type.name}
							</option>
						))}
					</Select>
					<FormErrorMessage>{formik.errors.typeId}</FormErrorMessage>
				</FormControl>
			)}

			<FormControl isInvalid={Boolean(formik.errors.description && formik.touched.description)}>
				<SlideFade in={Boolean(formik.values.description)} unmountOnExit>
					<FormLabel htmlFor="description" fontSize="sm">
						Description
					</FormLabel>
				</SlideFade>
				<Textarea
					placeholder="Description"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("description")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.description}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.username && formik.touched.username)}>
				<SlideFade in={Boolean(formik.values.username)} unmountOnExit>
					<FormLabel htmlFor="title" fontSize="sm">
						Username
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Username"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("username")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.username}</Text>
				</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
