import { Button, Switch, Icon, Tooltip } from "@chakra-ui/react"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { ExtendedStudentFragment, useToggleNotificationActivityStatusMutation } from "../../graphql"

export type ChangeTelegramMessageStatusProps = { student: ExtendedStudentFragment }

export const ChangeTelegramMessageStatus: React.FC<ChangeTelegramMessageStatusProps> = ({ student }) => {
	const [, toggleNotificationActivityStatus] = useToggleNotificationActivityStatusMutation()

	const toggleSwitch = () => toggleNotificationActivityStatus({ studentId: student._id })

	return (
		<Tooltip rounded="lg" p="2" label={!student.isTelegramMessagesEnabled ? `${student.name} is not recieving any notifications` : ""}>
			<Button size="xs" variant="outline" colorScheme={student.isTelegramMessagesEnabled ? "green" : "red"} onClick={toggleSwitch} zIndex={2}>
				Notifications{" "}
				{
					<Switch
						ml="2"
						mr={student.isTelegramMessagesEnabled ? 0 : 2}
						isChecked={student.isTelegramMessagesEnabled ? true : false}
						colorScheme={student.isTelegramMessagesEnabled ? "green" : "red"}
						size="sm"
						zIndex="0"
						onChange={() => toggleSwitch()}
					/>
				}
				{!student.isTelegramMessagesEnabled && <Icon as={(props: any) => <FontAwesomeIcon icon={faQuestionCircle} {...props} />} />}
			</Button>
		</Tooltip>
	)
}
