import { Container, Heading, HStack, useBreakpointValue, VStack } from "@chakra-ui/react"
import React from "react"
import { useParams } from "react-router-dom"
import { AddShowcaseAuthorForm, DashboardHeader, Nav } from "../../components"
import { GRADIENT } from "../../constants"
import { useDrawer } from "../../hooks"

export const AddShowcaseAuthor: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const { showcaseId = "" } = useParams<{ showcaseId: string }>()

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					<VStack maxW="lg" w="full" align="flex-start">
						<HStack w="full" justify="space-between">
							<Heading as="h1" fontSize="xl" color="text.500">
								Add Author
							</Heading>
						</HStack>
						<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
							{
								// TODO: add sub heading
							}
						</Heading>
					</VStack>

					<AddShowcaseAuthorForm showcaseId={showcaseId} />
				</VStack>
			</HStack>
		</Container>
	)
}
