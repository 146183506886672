import { Icon, IconButton, useDisclosure, useToast } from "@chakra-ui/react"
import React from "react"
import { Trash } from "react-feather"
import { useNavigate } from "react-router-dom"
import { MediaAttachmentsInput, useDeleteAttachmentFromShowcaseMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type DeleteAttachementFromShowcaseProps = {
	showcaseId: string
	attachments: MediaAttachmentsInput
}

export const DeleteAttachementFromShowcase: React.FC<DeleteAttachementFromShowcaseProps> = ({ showcaseId, attachments }) => {
	const { isOpen, onClose, getButtonProps } = useDisclosure()

	const [{ fetching }, deleteAttachments] = useDeleteAttachmentFromShowcaseMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const handleDeleteAttachments = async () => {
		const { error } = await deleteAttachments({ showcaseId, input: attachments })

		if (error) {
			toast({ description: error.message.replace("[GraphQL ]", ""), status: "error" })
		} else {
			onClose()
			navigate(`/showcases/${showcaseId}`)
		}
	}

	return (
		<>
			<IconButton aria-label="delete-attachment-btn" colorScheme="red" variant="ghost" {...getButtonProps()}>
				<Icon as={Trash} />
			</IconButton>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Delete Attachments"
				message="Are you sure you want to delete these attachments?"
				confirmLabel="Delete"
				onConfirm={handleDeleteAttachments}
				isLoading={fetching}
			/>
		</>
	)
}
