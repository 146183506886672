/* eslint-disable @typescript-eslint/indent */
import format from "date-fns/format"
import { ExtendedPaytankOrderFragment } from "../graphql"

export const parsePaytankOrdersReportAsCSV = (orders: ExtendedPaytankOrderFragment[]) => {
	let data = "Transaction ID, Student, Merchant, Amount, Type, Status, Created"

	orders?.forEach(({ _id, amount, description, status, failureReason, student, createdAt, type, merchant }) => {
		data = data.concat("\n")
		data = data.concat(
			`${_id}, ${student.name} @${student.username}, ${merchant?.name || "-"}, ₹${amount} ${description ? `(${description})` : ""}, ${type}, ${status} ${
				failureReason ? `(${failureReason})` : ""
			}, ${format(new Date(createdAt), "dd/MM/yyyy p")}`
		)
	})

	return data
}
