import { Center, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { SearchPenaltyCategoriesForStudentsQueryVariables, useSearchPenaltyCategoriesForStudentsQuery } from "../../graphql"
import { PenaltyCategoriesList } from "./PenaltyCategoriesList"

export type SearchResultPenaltyCategoriesForStudentsProps = {
	searchKeyword: string
}

export const SearchResultPenaltyCategoriesForStudents: React.FC<SearchResultPenaltyCategoriesForStudentsProps> = ({ searchKeyword }) => {
	const [pagination, setPagination] = useState<SearchPenaltyCategoriesForStudentsQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useSearchPenaltyCategoriesForStudentsQuery({
		variables: { pagination, keyword: searchKeyword },
	})

	const prevPage = () => {
		if (data?.searchPenaltyCategoriesForStudents.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.searchPenaltyCategoriesForStudents.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="md" fontWeight="normal" color="text.500">
				Showing {data?.searchPenaltyCategoriesForStudents.total || "all"} penalty categories for students for search keyword{" "}
				<strong>&quot;{searchKeyword}&quot;</strong>
			</Heading>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.searchPenaltyCategoriesForStudents.penaltyCategories && data?.searchPenaltyCategoriesForStudents.penaltyCategories.length > 0 ? (
				<PenaltyCategoriesList
					penaltyCategories={data.searchPenaltyCategoriesForStudents.penaltyCategories}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.searchPenaltyCategoriesForStudents.hasPrevPage}
					hasNextPage={data.searchPenaltyCategoriesForStudents.hasNextPage}
					currentPage={data.searchPenaltyCategoriesForStudents.currentPage}
					totalPages={data.searchPenaltyCategoriesForStudents.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any penalty categories.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
