import {
	Button,
	Center,
	Container,
	Heading,
	HStack,
	Icon,
	Input,
	InputGroup,
	InputRightAddon,
	Select,
	Spinner,
	Stack,
	Text,
	useBreakpointValue,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { DashboardHeader, Nav, StaffSelector } from "../../components"
import { GRADIENT } from "../../constants"
import { DepartmentFragment, useDepartmentsQuery, useNotWhitelistedStaffsWithoutPaginationQuery, useWhitelistStaffsMutation } from "../../graphql"
import { useDrawer } from "../../hooks"

export const WhitelistStaff: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [selectedStaffIds, setSelectedStaffIds] = useState<string[]>([])

	const [{ data: departmentsData, fetching: fetchingDepartments, error: departmentsError }] = useDepartmentsQuery()

	const [department, setDepartment] = useState<DepartmentFragment>()

	const [searchKeyword, setSearchKeyword] = useState("")

	const [{ data, fetching, error }] = useNotWhitelistedStaffsWithoutPaginationQuery({ variables: { keyword: searchKeyword, departmentId: department?._id } })

	const [, whitelistStaffs] = useWhitelistStaffsMutation()

	const toast = useToast()

	const handleWhitelistStaff = async () => {
		if (selectedStaffIds.length > 0) {
			await whitelistStaffs({
				staffIds: selectedStaffIds,
			})
			toast({ title: "Whitelisted", description: "Successfully whitelisted selected staffs", status: "success", duration: 2000 })
			setSelectedStaffIds([])
		} else {
			toast({ title: "Select at least one staff", status: "error", duration: 2000 })
		}
	}

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<VStack align="flex-start">
						<Heading as="h1" fontSize="xl" color="text.500">
							Whitelist Staff
						</Heading>
						<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
							{
								// TODO: add sub heading
							}
						</Heading>
					</VStack>

					<Stack w="full">
						{fetchingDepartments ? (
							<Center>
								<VStack w="full" color="blackAlpha.800">
									<Text>Loading departments</Text>
									<Spinner size="sm" />
								</VStack>
							</Center>
						) : departmentsError ? (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
									Failed loading departments, try reloading the page
								</Text>
							</Center>
						) : departmentsData?.departments.length ? (
							<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
								<Button
									m="1"
									size="sm"
									colorScheme="primary"
									isLoading={fetching}
									onClick={handleWhitelistStaff}
									disabled={selectedStaffIds.length == 0}
								>
									Whitelist
								</Button>

								<HStack spacing={0}>
									{departmentsData && (
										<Select
											bg="white.500"
											rounded="xl"
											border="none"
											_focus={{ border: "none" }}
											w="full"
											maxW="xs"
											placeholder="All"
											value={JSON.stringify(department)}
											onChange={(e) => {
												setDepartment(e.target.value ? JSON.parse(e.target.value) : undefined)
											}}
										>
											{departmentsData.departments.map((d) => (
												<option key={d.name} value={JSON.stringify(d)}>
													{d.name}
												</option>
											))}
										</Select>
									)}
								</HStack>
								<InputGroup maxW="xs" bg="white.500" rounded="xl" py="1">
									<Input
										placeholder="Search staffs"
										border="none"
										_focus={{ border: "none" }}
										onChange={(e) => setSearchKeyword(e.target.value)}
									/>
									<InputRightAddon bg="transparent" border="none">
										<Icon as={(props: any) => <FontAwesomeIcon icon={faSearch} {...props} />} color="text.100" fontSize="lg" />
									</InputRightAddon>
								</InputGroup>
							</Stack>
						) : (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any departments.
								</Text>
							</Center>
						)}
					</Stack>

					<VStack w="full" align="center">
						{fetching ? (
							<Center py="4">
								<Spinner color="text.400" />
							</Center>
						) : error ? (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
									Failed loading staff, try reloading the page
								</Text>
							</Center>
						) : data?.notWhitelistedStaffsWithoutPagination && data?.notWhitelistedStaffsWithoutPagination.length > 0 ? (
							<StaffSelector
								staffs={data?.notWhitelistedStaffsWithoutPagination || []}
								selectedStaffIds={selectedStaffIds}
								onChange={setSelectedStaffIds}
								removeHeading={true}
							/>
						) : (
							<Center py="4">
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any staff.
								</Text>
							</Center>
						)}
					</VStack>
				</VStack>
			</HStack>
		</Container>
	)
}
