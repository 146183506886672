import { Button, Center, FormControl, FormErrorMessage, Icon, Spinner, Text, useToast, VStack } from "@chakra-ui/react"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CUIAutoComplete } from "chakra-ui-autocomplete"
import { useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { AddUnauthorizedDepartmentsMutationVariables, useAddUnauthorizedDepartmentsMutation, useDepartmentsQuery } from "../../graphql"

const validationSchema = Yup.object({
	departmentIds: Yup.array().of(Yup.string()).min(1).required().label("Departments"),
})

export type AddUnauthorizedDepartmentsFormProps = {
	restrictedZoneId: string
}

export const AddUnauthorizedDepartmentsForm: React.FC<AddUnauthorizedDepartmentsFormProps> = ({ restrictedZoneId }) => {
	const [{ fetching }, addUnauthorizedDepartments] = useAddUnauthorizedDepartmentsMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async ({ departmentIds }: Omit<AddUnauthorizedDepartmentsMutationVariables, "restrictedZoneId">) => {
		const { error } = await addUnauthorizedDepartments({ departmentIds, restrictedZoneId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		return navigate(`/restrictedZones/${restrictedZoneId}`, { replace: true })
	}

	const formik = useFormik<Omit<AddUnauthorizedDepartmentsMutationVariables, "restrictedZoneId">>({
		initialValues: { departmentIds: [] },
		validationSchema,
		onSubmit: handleSubmit,
	})

	const [{ data: departmentsData, fetching: fetchingDepartments, error: departmentsError }] = useDepartmentsQuery()

	return (
		<VStack w="full" maxW="md" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			{fetchingDepartments ? (
				<Center w="full" py="1">
					<VStack>
						<Spinner size="sm" color="text.400" />
						<Text fontSize="xs" color="text.400">
							Loading departments
						</Text>
					</VStack>
				</Center>
			) : departmentsError ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							{departmentsError.message.replace("[GraphQL] ", "")}
						</Text>
					</VStack>
				</Center>
			) : !departmentsData?.departments ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							Could not load departments
						</Text>
					</VStack>
				</Center>
			) : (
				<FormControl isInvalid={Boolean(formik.errors.departmentIds && formik.touched.departmentIds)}>
					<CUIAutoComplete
						label=""
						placeholder="Targets"
						disableCreateItem
						hideToggleButton
						selectedItems={departmentsData.departments
							.map((department) => ({ label: department.name, value: department._id }))
							.filter((department) => formik.values.departmentIds?.includes(department.value))}
						items={departmentsData.departments.map((department) => ({
							label: department.name,
							value: department._id,
						}))}
						onSelectedItemsChange={(changes) =>
							formik.setFieldValue(
								"departmentIds",
								changes.selectedItems?.map(({ value }) => value)
							)
						}
						inputStyleProps={{
							border: "none",
							_focus: { border: "none" },
							maxW: "md",
							bg: "white.500",
							rounded: "xl",
							py: "1",
						}}
						listStyleProps={{
							maxW: "md",
							bg: "whiteAlpha.400",
							backdropFilter: "blur(4px)",
						}}
					/>
					<FormErrorMessage>{formik.errors.departmentIds}</FormErrorMessage>
				</FormControl>
			)}

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Add
			</Button>
		</VStack>
	)
}
