/* eslint-disable @typescript-eslint/indent */
import format from "date-fns/format"
import { LabelledStudentAttendanceClassWiseStatsFragment } from "../graphql"

export const parseStudentAttendanceClassWiseStatsReportAsCSV = (stats: LabelledStudentAttendanceClassWiseStatsFragment) => {
	const date = format(new Date(stats.label), "P").concat("\n")
	let data = date.concat("Class, Total strength, Present, Absent, Absentee Names")

	stats.classWiseStats?.forEach(({ label, total, present, absent, absentStudents }) => {
		data = data.concat("\n")
		data = data.concat(`${label}, ${total}, ${present}, ${absent}, ${absentStudents.map((s) => s.name).join(" - ")}`)
	})

	data = data.concat("\n")
	data = data.concat(
		`Total Strength => ${stats.classWiseStats.map((s) => s.total).reduce((a, c) => a + c, 0)}, Total Present => ${stats.classWiseStats
			.map((s) => s.present)
			.reduce((a, c) => a + c, 0)}, Present % => ${(
			(stats.classWiseStats.map((s) => s.present).reduce((a, c) => a + c, 0) / stats.classWiseStats.map((s) => s.total).reduce((a, c) => a + c, 0)) *
			100
		).toFixed(2)}, Total Absent => ${stats.classWiseStats.map((s) => s.absent).reduce((a, c) => a + c, 0)}, Absent % => ${(
			(stats.classWiseStats.map((s) => s.absent).reduce((a, c) => a + c, 0) / stats.classWiseStats.map((s) => s.total).reduce((a, c) => a + c, 0)) *
			100
		).toFixed(2)}`
	)

	return data
}
