import { Button, Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { AllFeedPostsQueryVariables, useAllFeedPostsQuery } from "../../graphql"
import { FeedPost } from "./FeedPost"

export const AllFeedPosts: React.FC = () => {
	const [pagination, setPagination] = useState<AllFeedPostsQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useAllFeedPostsQuery({
		variables: { pagination },
	})

	const nextPage = () => {
		setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
	}

	return (
		<VStack w="full" maxW="lg" align="stretch">
			<VStack w="full" align="stretch">
				{data?.allFeedPosts?.feedPosts?.map((post) => (
					<FeedPost key={post._id} post={post} />
				))}
			</VStack>
			{fetching ? (
				<Center w="full" maxW="lg" py="8">
					<Spinner color="text.400" />
				</Center>
			) : data?.allFeedPosts?.feedPosts && data.allFeedPosts.feedPosts.length <= 0 ? (
				<Center w="full" maxW="lg" py="8">
					<Text fontSize="sm" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any posts.
					</Text>
				</Center>
			) : data?.allFeedPosts?.hasNextPage ? (
				<Center w="full" maxW="lg" py="8">
					<Button variant="link" size="sm" colorScheme="blackAlpha" onClick={nextPage}>
						Show more
					</Button>
				</Center>
			) : error?.message ? (
				<Center w="full" maxW="lg" minH="8">
					<Text fontSize="sm" fontWeight="semibold" color="red.400">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : (
				<Center w="full" maxW="lg" py="8">
					<Text fontSize="sm" fontWeight="semibold" color="text.400">
						You have seen it all
					</Text>
				</Center>
			)}
		</VStack>
	)
}
