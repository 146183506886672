import { Container, HStack, useBreakpointValue, VStack } from "@chakra-ui/react"
import React from "react"
import { useParams } from "react-router-dom"
import { AddUnauthorizedDepartmentsForm, DashboardHeader, Nav } from "../../components"
import { GRADIENT } from "../../constants"
import { useDrawer } from "../../hooks"

export const AddUnauthorizedDepartments: React.FC = () => {
	const { restrictedZoneId = "" } = useParams<{ restrictedZoneId: string }>()

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<AddUnauthorizedDepartmentsForm restrictedZoneId={restrictedZoneId} />
				</VStack>
			</HStack>
		</Container>
	)
}
