import React, { createContext, Dispatch, useReducer } from "react"
import { drawerReducer, initialDrawerState } from "./reducer"
import { DrawerActions, DrawerState } from "./types"

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const DrawerContext = createContext<[DrawerState, Dispatch<DrawerActions>]>([initialDrawerState, () => {}])

export const DrawerContextProvider: React.FC<{ children: any }> = ({ children }) => {
	const [state, dispatch] = useReducer(drawerReducer, initialDrawerState)

	return <DrawerContext.Provider value={[state, dispatch]}>{children}</DrawerContext.Provider>
}

export * from "./actions"
export * from "./reducer"
export * from "./types"
