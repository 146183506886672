import {
	AspectRatio,
	Button,
	Center,
	chakra,
	Container,
	Heading,
	HStack,
	Icon,
	Image,
	Img,
	Link,
	LinkBox,
	LinkOverlay,
	Spinner,
	Stack,
	Text,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatDistanceToNow } from "date-fns"
import React from "react"
import { NavLink, useParams } from "react-router-dom"
import { DashboardHeader, Nav } from "../../components"
import { GRADIENT } from "../../constants"
import { CardTypes, useGeneratedCardByIdQuery, useGeneratedCardUpdateSubscription } from "../../graphql"
import { useDrawer } from "../../hooks"

export const GeneratedCard: React.FC = () => {
	const { generatedCardId = "" } = useParams<{ generatedCardId: string }>()

	const [{ data, fetching, error }] = useGeneratedCardByIdQuery({
		variables: { generatedCardId },
	})

	useGeneratedCardUpdateSubscription({ variables: { generatedCardId } })

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.generatedCardById ? (
						<VStack w="full" align="flex-start" spacing="4">
							<VStack w="full" align="flex-start" spacing={0}>
								<HStack w="full" justify="space-between">
									<HStack flex={1} as={LinkBox} spacing={0}>
										<LinkOverlay as={NavLink} to={`/students/${data.generatedCardById?.student?._id}` || ""} />
										<AspectRatio ratio={1} w="12" rounded="xl" shadow="xl">
											<Image rounded="xl" src={data.generatedCardById?.student?.picture?.url} alt={"Student Image"} />
										</AspectRatio>
										<VStack w="full" align="flex-start" spacing={0} pl={2}>
											<Heading fontSize="sm" fontWeight="semibold">
												{data.generatedCardById.student?.name}
											</Heading>
											<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.400" maxW="xs">
												@{data.generatedCardById.student?.username}
											</Heading>
										</VStack>
									</HStack>
								</HStack>
							</VStack>

							<VStack w="full" align="stretch">
								<HStack w="sm" maxW="sm" align="flex-start" justify="space-between">
									<VStack align="flex-start" spacing={0}>
										<Text fontSize="xs" color="text.500">
											Card Type
										</Text>
										<Text fontSize="sm" color="text.500" fontWeight="semibold">
											{data.generatedCardById.cardType}
										</Text>
									</VStack>

									{data.generatedCardById.isGenerated && data.generatedCardById.cardPdf?.url && (
										<Button
											size="xs"
											onClick={() => window.open(data.generatedCardById?.cardPdf?.url, "_black")}
											rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faDownload} {...props} />} />}
										>
											PDF
										</Button>
									)}
								</HStack>

								<Text fontSize="12" color="blackAlpha.700">
									<chakra.span fontWeight="bold"> • </chakra.span>
									<chakra.span>
										{formatDistanceToNow(new Date(data.generatedCardById.createdAt), {
											addSuffix: true,
										})}
									</chakra.span>
								</Text>

								{!data.generatedCardById.isInProgress && data.generatedCardById.isGenerated ? (
									<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start">
										{data.generatedCardById.cardType === CardTypes.Student ? (
											<Link href={data.generatedCardById.cardImage?.url} isExternal>
												<Img w="425px" h="675px" src={data.generatedCardById?.cardImage?.url} />
											</Link>
										) : data.generatedCardById.cardType === CardTypes.StudentExeat && data.generatedCardById.exeatCardImages ? (
											data.generatedCardById.exeatCardImages.map((card) => (
												<Link key={card.key} href={card.url} isExternal>
													<Img w="425px" h="675px" src={card.url} />
												</Link>
											))
										) : (
											<></>
										)}
									</Stack>
								) : (
									<HStack w="full" maxW="2xl">
										<Spinner />
										<Text textAlign="center">Generating card</Text>
									</HStack>
								)}
							</VStack>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the generated card.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
