import { Button, FormControl, FormErrorIcon, FormErrorMessage, FormLabel, Icon, Input, SlideFade, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { EditRewardCategoryMutationVariables, RewardCategory, useEditRewardCategoryMutation } from "../../graphql"

const validationSchema = Yup.object({
	name: Yup.string().required().label("Name"),
	description: Yup.string().required().label("Description"),
})

export type EditRewardCategoryFormProps = {
	rewardCategory: RewardCategory
}

export const EditRewardCategoryForm: React.FC<EditRewardCategoryFormProps> = ({ rewardCategory }) => {
	const [{ fetching }, edit] = useEditRewardCategoryMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (
		{ name, description }: EditRewardCategoryMutationVariables["input"],
		helpers: FormikHelpers<EditRewardCategoryMutationVariables["input"]>
	) => {
		const { error, data } = await edit({
			rewardCategoryId: rewardCategory._id,
			input: { name, description },
		})

		if (error) {
			return toast({
				description: error.message,
				status: "error",
			})
		}

		if (data?.editRewardCategory.errors) {
			return data?.editRewardCategory.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		return navigate("/points/reward/categories", { replace: true })
	}

	const formik = useFormik<EditRewardCategoryMutationVariables["input"]>({
		initialValues: { name: rewardCategory.name, description: rewardCategory.description },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)}>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="name" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={Boolean(formik.errors.description && formik.touched.description)}>
				<SlideFade in={Boolean(formik.values.description)} unmountOnExit>
					<FormLabel htmlFor="description" fontSize="sm">
						Description
					</FormLabel>
				</SlideFade>
				<Textarea
					placeholder="Description"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("description")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.description}</Text>
				</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Save
			</Button>
		</VStack>
	)
}
