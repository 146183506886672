import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Heading,
	Icon,
	IconButton,
	Tooltip,
	useDisclosure,
	useToast,
} from "@chakra-ui/react"
import { FocusableElement } from "@chakra-ui/utils"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { RefObject, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { CafeteriaMealTypeFragment, useDeleteCafeteriaMealTypeMutation } from "../../../graphql"

export type DeleteCafeteriaMealTypeButtonProps = {
	cafeteriaMealType: CafeteriaMealTypeFragment
}

export const DeleteCafeteriaMealTypeButton: React.FC<DeleteCafeteriaMealTypeButtonProps> = ({ cafeteriaMealType }) => {
	const [, deleteCafeteriaMealType] = useDeleteCafeteriaMealTypeMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleDeleteCafeteriaMealType = async () => {
		const { error } = await deleteCafeteriaMealType({ cafeteriaMealTypeId: cafeteriaMealType._id })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		return navigate("/cafeteria/mealTypes", { replace: true })
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	const leastDestructiveRef = useRef() as RefObject<FocusableElement>

	return (
		<>
			<Tooltip label={`Delete Cafeteria Meal Type ${cafeteriaMealType.name}`} fontSize="sm">
				<IconButton aria-label="delete-cafeteria-meal-type-btn" variant="ghost" colorScheme="blackAlpha" size="sm" {...getButtonProps()}>
					<Icon as={(props: any) => <FontAwesomeIcon icon={faTrash} {...props} />} />
				</IconButton>
			</Tooltip>
			<AlertDialog leastDestructiveRef={leastDestructiveRef} isOpen={isOpen} onClose={onClose}>
				<AlertDialogOverlay backdropFilter="blur(16px)">
					<AlertDialogContent>
						<AlertDialogHeader>
							<Heading fontSize="lg" color="text.500">
								Are you sure?
							</Heading>
							<AlertDialogCloseButton colorScheme="text" />
						</AlertDialogHeader>

						<AlertDialogBody fontSize="sm">Removing this cafeteria meal type may affect logs associated with this.</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={leastDestructiveRef as React.LegacyRef<HTMLButtonElement>} size="sm" onClick={onClose}>
								Cancel
							</Button>
							<Button colorScheme="red" size="sm" onClick={handleDeleteCafeteriaMealType} ml={3}>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	)
}
