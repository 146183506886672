import { Checkbox, Heading, Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { format } from "date-fns"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { ExtendedStudentFragment } from "../../graphql"
import { usePagination } from "../../hooks"
import { StudentTag } from "../student"

export type StudentsSelectorProps = {
	students: ExtendedStudentFragment[]
	selectStudentIds: string[]
	onChange: (studentIds: string[]) => void
	removeHeading?: boolean
	hideTimestamps?: boolean
}

export const StudentsSelector: React.FC<StudentsSelectorProps> = ({ students, selectStudentIds, onChange, removeHeading, hideTimestamps }) => {
	const isAllChecked = useMemo(
		() => Boolean(selectStudentIds.length) && students.map(({ _id }) => _id).every((id) => selectStudentIds.includes(id)),
		[selectStudentIds, students]
	)

	const isIndeterminate = useMemo(
		() => selectStudentIds.some((id) => students.map(({ _id }) => _id).includes(id)) && !isAllChecked,
		[selectStudentIds, students]
	)

	const selectStudent = (studentId: string) => {
		const _selectStudentIds = new Set(selectStudentIds)
		_selectStudentIds.add(studentId)

		onChange(Array.from(_selectStudentIds))
	}

	const deselectStudent = (studentId: string) => {
		const _selectStudentIds = [...selectStudentIds]

		_selectStudentIds.splice(_selectStudentIds.indexOf(studentId), 1)

		onChange(_selectStudentIds)
	}

	const selectAllStudents = () => {
		onChange(students.map(({ _id }) => _id) ?? [])
	}

	const isStudentSelected = useCallback((studentId: string) => selectStudentIds.includes(studentId), [selectStudentIds])

	useEffect(() => {
		onChange(selectStudentIds)
	}, [selectStudentIds])

	const { currentItems, loadMore, hasMore } = usePagination<ExtendedStudentFragment>(students, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
				{removeHeading ? "" : "Select the student you want to generate the report for"}
			</Heading>
			<VStack w="full" align="stretch" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th>
								<Checkbox
									colorScheme="blackAlpha"
									borderColor="black"
									isChecked={isAllChecked}
									isIndeterminate={isIndeterminate}
									onChange={(e) => (e.target.checked ? selectAllStudents() : onChange([]))}
								/>
							</Th>
							<Th textTransform="capitalize">Student</Th>
							<Th textTransform="capitalize">Class</Th>
							{!hideTimestamps && <Th textTransform="capitalize">Created</Th>}
							{!hideTimestamps && <Th textTransform="capitalize">Updated</Th>}
						</Tr>
					</Thead>
					<Tbody>
						{currentItems.map((student) => (
							<Tr key={student._id} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td>
									<Checkbox
										colorScheme="blackAlpha"
										borderColor="black"
										isChecked={isStudentSelected(student._id)}
										onChange={(e) => (e.target.checked ? selectStudent(student._id) : deselectStudent(student._id))}
									/>
								</Td>
								<Td>
									<StudentTag student={student} />
								</Td>
								<Td>
									{student.class.name} - {student.classSection.name}
								</Td>
								{!hideTimestamps && <Td>{format(new Date(student.createdAt), "PP")}</Td>}
								{!hideTimestamps && <Td>{format(new Date(student.updatedAt), "PP")}</Td>}
							</Tr>
						))}
					</Tbody>
				</Table>
				{hasMore && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
			</VStack>
		</VStack>
	)
}
