import { AspectRatio, Heading, HStack, Img, Link, VStack } from "@chakra-ui/react"
import React from "react"

export const Logo: React.FC = () => {
	return (
		<Link href="/">
			<VStack align="stretch" cursor="pointer">
				<HStack align="flex-end" spacing={2}>
					<AspectRatio w={{ base: "80px", md: "120px" }} ratio={22 / 9}>
						<Img src="/logo-192x192.png" w="full" />
					</AspectRatio>
					<Heading color="primary.400" fontSize={{ base: "xs", md: "lg" }}>
						School
					</Heading>
				</HStack>
			</VStack>
		</Link>
	)
}
