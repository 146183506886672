import { ButtonGroup, Center, Container, Heading, HStack, Icon, IconButton, Spinner, Text, Tooltip, useBreakpointValue, VStack } from "@chakra-ui/react"
import { faPencil } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { NavLink, useParams } from "react-router-dom"
import { DashboardHeader, DeleteSchoolSessionButton, Nav } from "../../components"
import { GRADIENT } from "../../constants"
import { useSchoolSessionByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const SchoolSession: React.FC = () => {
	const { schoolSessionId = "" } = useParams<{ schoolSessionId: string }>()

	const [{ data, fetching, error }] = useSchoolSessionByIdQuery({
		variables: { schoolSessionId },
		pause: !schoolSessionId,
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.schoolSessionById ? (
						<VStack w="full" align="flex-start" spacing="4">
							<HStack w="full" py="4" align="flex-start" justify="space-between">
								<VStack align="flex-start">
									<HStack spacing={6}>
										<Heading as="h1" fontSize="xl" color="text.500">
											{data.schoolSessionById.name}
										</Heading>

										<ButtonGroup>
											<Tooltip label={`Edit School Session ${data.schoolSessionById.name}`} fontSize="sm">
												<IconButton
													aria-label="edit-school-session-btn"
													as={NavLink}
													to="edit"
													variant="ghost"
													colorScheme="blackAlpha"
													size="sm"
												>
													<Icon as={(props: any) => <FontAwesomeIcon icon={faPencil} {...props} />} />
												</IconButton>
											</Tooltip>
											<DeleteSchoolSessionButton schoolSession={data.schoolSessionById} />
										</ButtonGroup>
									</HStack>
									<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
										Created {format(new Date(data.schoolSessionById.createdAt), "PPPp")}
									</Heading>
								</VStack>
							</HStack>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the school session.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
