import { Button, Center, Flex, Heading, HStack, Icon, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useNavigate } from "react-router-dom"
import { useUnauthorizedClassesQuery } from "../../graphql"
import { UnauthorizedClassCard } from "./UnauthorizedClassCard"

export type UnauthorizedClassesProps = {
	restrictedZoneId: string
}

export const UnauthorizedClasses: React.FC<UnauthorizedClassesProps> = ({ restrictedZoneId }) => {
	const [{ fetching, error, data }] = useUnauthorizedClassesQuery({ variables: { restrictedZoneId } })

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const btnSize = isMobile ? "sm" : "md"

	const navigate = useNavigate()

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" justify="space-between">
				<Heading as="h2" fontSize={isMobile ? "sm" : "md"} fontWeight="normal" color="text.500">
					Showing {data?.unauthorizedClasses.length || "all"} unauthorized classes
				</Heading>
				<Button
					colorScheme="purple"
					size={btnSize}
					rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faAdd} {...props} />} />}
					onClick={() => navigate(`/restrictedZones/${restrictedZoneId}/addUnauthorizedClass`)}
				>
					Add New
				</Button>
			</HStack>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.unauthorizedClasses && data?.unauthorizedClasses.length > 0 ? (
				<Flex flexWrap="wrap" mx="-4px !important">
					{data.unauthorizedClasses.map(({ class: _class, classSection }, index) => (
						<UnauthorizedClassCard key={index} restrictedZoneId={restrictedZoneId} class={_class} classSection={classSection} m="1" />
					))}
				</Flex>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any unauthorized classes.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
