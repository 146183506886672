import { Center, Container, Flex, Heading, HStack, Icon, Link, Spinner, Tag, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React from "react"
import { Edit2 } from "react-feather"
import { NavLink, useParams } from "react-router-dom"
import { DashboardHeader, Nav, RemoveDepartmentButton } from "../../components"
import { GRADIENT } from "../../constants"
import { useDepartmentByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const Department: React.FC = () => {
	const { departmentId = "" } = useParams<{ departmentId: string }>()

	const [{ data, fetching, error }] = useDepartmentByIdQuery({
		variables: { departmentId },
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.departmentById ? (
						<VStack w="full" align="flex-start" spacing="4">
							<HStack w="full" py="4" align="flex-start" justify="space-between">
								<VStack align="flex-start">
									<HStack w="full" justify="space-between">
										<Heading fontSize="md" fontWeight="semibold">
											{data.departmentById.name}
										</Heading>
										<RemoveDepartmentButton department={data.departmentById} />
									</HStack>
									<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
										Created {format(new Date(data.departmentById.createdAt), "PPPp")}
									</Heading>
								</VStack>
							</HStack>
							<VStack
								w="full"
								align="start"
								spacing={0}
								as={(props: any) => <Link as={NavLink} {...props} />}
								to={`/departments/${data.departmentById._id}/updateAttendanceTiming`}
							>
								<HStack>
									<Text fontSize="xs">Timing</Text>
									<Icon fontSize="xs" as={Edit2} />
								</HStack>
								<Text fontSize="xs" color="blackAlpha.700" fontWeight="semibold">
									{data.departmentById.attendanceTiming.inAt} - {data.departmentById.attendanceTiming.outAt}
								</Text>
							</VStack>
							<VStack
								w="full"
								align="start"
								spacing={0}
								as={(props: any) => <Link as={NavLink} {...props} />}
								to={`/departments/${data.departmentById._id}/updateWorkingWeekdays`}
							>
								<HStack>
									<Text fontSize="xs">Working weekdays</Text>
									<Icon fontSize="xs" as={Edit2} />
								</HStack>
								<Flex flexWrap="wrap">
									{data.departmentById.workingWeekdays?.length ? (
										data.departmentById.workingWeekdays.map((day) => (
											<Tag key={day} m="0.5" size="sm" colorScheme="purple">
												{day}
											</Tag>
										))
									) : (
										<Text fontSize="xs" color="blackAlpha.700" fontWeight="semibold">
											Not set
										</Text>
									)}
								</Flex>
							</VStack>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the department.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
