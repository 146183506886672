import { Button, Center, Heading, HStack, Icon, Spinner, Text, VStack } from "@chakra-ui/react"
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { AllBusRoutesQueryVariables, useAllBusRoutesQuery } from "../../graphql"
import { BusRoutesTable } from "./BusRoutesTable"

export const AllBusRoutes: React.FC = () => {
	const [pagination, setPagination] = useState<AllBusRoutesQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useAllBusRoutesQuery({
		variables: { pagination },
	})

	const navigate = useNavigate()

	const prevPage = () => {
		if (data?.allBusRoutes.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.allBusRoutes.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" justify="space-between">
				<Heading as="h2" fontSize="md" fontWeight="normal" color="text.500">
					Showing {data?.allBusRoutes.total || "all"} bus routes of your school
				</Heading>
				<Button
					colorScheme="whiteAlpha"
					color="text.500"
					rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faAdd} {...props} />} />}
					onClick={() => navigate("/busRoutes/new")}
				>
					Create New
				</Button>
			</HStack>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.allBusRoutes.busRoutes && data?.allBusRoutes.busRoutes.length > 0 ? (
				<BusRoutesTable
					busRoutes={data.allBusRoutes.busRoutes}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.allBusRoutes.hasPrevPage}
					hasNextPage={data.allBusRoutes.hasNextPage}
					currentPage={data.allBusRoutes.currentPage}
					totalPages={data.allBusRoutes.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any bus routes.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
