import {
	Button,
	Center,
	Checkbox,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Icon,
	Input,
	Select,
	SlideFade,
	Spinner,
	Text,
	Textarea,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import DatePicker from "react-date-picker"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import toWords from "split-camelcase-to-words"
import * as Yup from "yup"
import {
	AdmissionTypes,
	BloodGroups,
	EditStudentMutationVariables,
	ExtendedStudentFragment,
	Genders,
	ParentInput,
	useBusRoutesQuery,
	useEditStudentMutation,
} from "../../graphql"

const validationSchema = Yup.object({
	name: Yup.string().min(3).required().label("Name"),
	email: Yup.string().email().nullable().label("Email"),
	phone: Yup.string().length(10).nullable().label("Phone"),
})

export type EditStudentFormProps = { student: ExtendedStudentFragment }

export const EditStudentForm: React.FC<EditStudentFormProps> = ({ student }) => {
	const [{ fetching }, editStudent] = useEditStudentMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (values: EditStudentMutationVariables["input"], helpers: FormikHelpers<EditStudentMutationVariables["input"]>) => {
		if (values.transportMode != "School Bus") values.busRouteId = null
		values.father?.nfcTagId ? (values.father.nfcTagId = values.father.nfcTagId.toUpperCase()) : ""
		values.mother?.nfcTagId ? (values.mother.nfcTagId = values.mother.nfcTagId.toUpperCase()) : ""
		values.guardian1?.nfcTagId ? (values.guardian1.nfcTagId = values.guardian1.nfcTagId.toUpperCase()) : ""
		values.guardian2?.nfcTagId ? (values.guardian2.nfcTagId = values.guardian2.nfcTagId.toUpperCase()) : ""

		const { error, data } = await editStudent({ studentId: student._id, input: { ...values } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		if (data?.editStudent.errors?.length) {
			return data?.editStudent.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		if (!data?.editStudent.student) {
			return toast({
				description: "Could not edit the student",
				status: "error",
			})
		}

		return navigate("/students/" + data.editStudent.student._id, { replace: true })
	}

	const formik = useFormik<EditStudentMutationVariables["input"]>({
		initialValues: {
			name: student.name,
			email: student.email,
			phone: student.phone,
			gender: student.gender,
			dateOfBirth: student.dateOfBirth ? new Date(student.dateOfBirth) : undefined,
			bloodGroup: student.bloodGroup,
			address: student.address,
			admissionDate: student.admissionDate ? new Date(student.admissionDate) : undefined,
			admissionType: student.admissionType,
			isALS: student.isALS,
			isELS: student.isELS,
			nationality: student.nationality,
			father: { name: student.father?.name, phone: student.father?.phone, email: student.father?.email, nfcTagId: student.father?.nfcTagId },
			mother: { name: student.mother?.name, phone: student.mother?.phone, email: student.mother?.email, nfcTagId: student.mother?.nfcTagId },
			guardian1: {
				name: student.guardian1?.name,
				phone: student.guardian1?.phone,
				email: student.guardian1?.email,
				nfcTagId: student.guardian1?.nfcTagId,
			},
			guardian2: {
				name: student.guardian2?.name,
				phone: student.guardian2?.phone,
				email: student.guardian2?.email,
				nfcTagId: student.guardian2?.nfcTagId,
			},
			transportMode: student.transportMode,
			busRouteId: student.busRouteId,
		},
		validationSchema,
		onSubmit: handleSubmit,
	})

	const [{ data: busRoutesData, fetching: fetchingBusRoutes, error: busRoutesError }] = useBusRoutesQuery()

	return (
		<VStack w="full" maxW="md" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)}>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="name" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.email && formik.touched.email)}>
				<SlideFade in={Boolean(formik.values.email)} unmountOnExit>
					<FormLabel htmlFor="email" fontSize="sm">
						Email
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Email"
					type="email"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("email")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.email}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.phone && formik.touched.phone)}>
				<SlideFade in={Boolean(formik.values.phone)} unmountOnExit>
					<FormLabel htmlFor="phone" fontSize="sm">
						Phone
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Phone"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("phone")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.phone}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.admissionDate && formik.touched.admissionDate)}>
				<FormLabel htmlFor="admissionDate" fontSize="sm">
					Admission Date
				</FormLabel>
				<DatePicker
					format="dd-MM-yyyy"
					{...formik.getFieldProps("admissionDate")}
					value={formik.values.admissionDate}
					onChange={(date: Date) => formik.setFieldValue("admissionDate", date)}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.admissionDate as string}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.admissionType && formik.touched.admissionType)}>
				<SlideFade in={Boolean(formik.values.admissionType)} unmountOnExit>
					<FormLabel htmlFor="admissionType" fontSize="sm">
						Admission Type
					</FormLabel>
				</SlideFade>
				<Select
					placeholder="Admission Type"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("admissionType")}
				>
					{Object.values(AdmissionTypes).map((type) => (
						<option key={type} value={type}>
							{toWords(type)}
						</option>
					))}
				</Select>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.admissionType}</Text>
				</FormErrorMessage>
			</FormControl>

			<HStack spacing={12}>
				<FormControl isInvalid={Boolean(formik.errors.isALS && formik.touched.isALS)}>
					<Checkbox borderColor="purple.300" colorScheme="purple" isChecked={formik.values.isALS || false} {...formik.getFieldProps("isALS")}>
						ALS
					</Checkbox>
					<FormErrorMessage>
						<FormErrorIcon />
						<Text>{formik.errors.isALS}</Text>
					</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.errors.isELS && formik.touched.isELS)}>
					<Checkbox borderColor="purple.300" colorScheme="purple" isChecked={formik.values.isELS || false} {...formik.getFieldProps("isELS")}>
						ELS
					</Checkbox>
					<FormErrorMessage>
						<FormErrorIcon />
						<Text>{formik.errors.isELS}</Text>
					</FormErrorMessage>
				</FormControl>
			</HStack>

			<FormControl isInvalid={Boolean(formik.errors.nationality && formik.touched.nationality)}>
				<SlideFade in={Boolean(formik.values.nationality)} unmountOnExit>
					<FormLabel htmlFor="nationality" fontSize="sm">
						Nationality
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Nationality"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("nationality")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.nationality}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.address && formik.touched.address)}>
				<SlideFade in={Boolean(formik.values.address)} unmountOnExit>
					<FormLabel htmlFor="address" fontSize="sm">
						Address
					</FormLabel>
				</SlideFade>
				<Textarea
					placeholder="Address"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("address")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.address}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.dateOfBirth && formik.touched.dateOfBirth)}>
				<FormLabel htmlFor="dateOfBirth" fontSize="sm">
					Date of birth
				</FormLabel>
				<DatePicker
					format="dd-MM-yyyy"
					{...formik.getFieldProps("dateOfBirth")}
					value={formik.values.dateOfBirth}
					onChange={(date: Date) => formik.setFieldValue("dateOfBirth", date)}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.dateOfBirth as string}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.bloodGroup && formik.touched.bloodGroup)}>
				<SlideFade in={Boolean(formik.values.bloodGroup)} unmountOnExit>
					<FormLabel htmlFor="bloodGroup" fontSize="sm">
						Blood Group
					</FormLabel>
				</SlideFade>
				<Select
					placeholder="Blood Group"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("bloodGroup")}
				>
					{Object.values(BloodGroups).map((bg) => (
						<option key={bg} value={bg}>
							{bg.replace("_NEG", "-").replace("_POS", "+")}
						</option>
					))}
				</Select>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.bloodGroup}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.gender && formik.touched.gender)}>
				<SlideFade in={Boolean(formik.values.gender)} unmountOnExit>
					<FormLabel htmlFor="gender" fontSize="sm">
						Gender
					</FormLabel>
				</SlideFade>
				<Select
					placeholder="Gender"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("gender")}
				>
					{Object.values(Genders).map((g) => (
						<option key={g} value={g}>
							{g}
						</option>
					))}
				</Select>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.gender}</Text>
				</FormErrorMessage>
			</FormControl>

			<VStack w="full" align="stretch">
				<Heading fontSize="sm">Father</Heading>
				<VStack w="full" align="stretch" pl="4">
					<FormControl
						isInvalid={Boolean((formik.errors.father as unknown as ParentInput)?.name && (formik.touched.father as unknown as ParentInput)?.name)}
					>
						<SlideFade in={Boolean((formik.values.father as unknown as ParentInput)?.name)} unmountOnExit>
							<FormLabel htmlFor="father.name" fontSize="sm">
								Name
							</FormLabel>
						</SlideFade>
						<Input
							placeholder="Name"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("father.name")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{(formik.errors.father as unknown as ParentInput)?.name}</Text>
						</FormErrorMessage>
					</FormControl>

					<FormControl
						isInvalid={Boolean((formik.errors.father as unknown as ParentInput)?.phone && (formik.touched.father as unknown as ParentInput)?.phone)}
					>
						<SlideFade in={Boolean((formik.values.father as unknown as ParentInput)?.phone)} unmountOnExit>
							<FormLabel htmlFor="father.phone" fontSize="sm">
								Phone
							</FormLabel>
						</SlideFade>
						<Input
							placeholder="Phone"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("father.phone")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{(formik.errors.father as unknown as ParentInput)?.phone}</Text>
						</FormErrorMessage>
					</FormControl>
					<FormControl
						isInvalid={Boolean((formik.errors.father as unknown as ParentInput)?.email && (formik.touched.father as unknown as ParentInput)?.email)}
					>
						<SlideFade in={Boolean((formik.values.father as unknown as ParentInput)?.email)} unmountOnExit>
							<FormLabel htmlFor="father.email" fontSize="sm">
								Email
							</FormLabel>
						</SlideFade>
						<Input
							placeholder="Email"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("father.email")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{(formik.errors.father as unknown as ParentInput)?.email}</Text>
						</FormErrorMessage>
					</FormControl>
					<FormControl
						isInvalid={Boolean(
							(formik.errors.father as unknown as ParentInput)?.nfcTagId && (formik.touched.father as unknown as ParentInput)?.nfcTagId
						)}
					>
						<SlideFade in={Boolean((formik.values.father as unknown as ParentInput)?.nfcTagId)} unmountOnExit>
							<FormLabel htmlFor="father.nfcTagId" fontSize="sm">
								NFC Tag Id
							</FormLabel>
						</SlideFade>
						<Input
							placeholder="NFC Tag Id"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("father.nfcTagId")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{(formik.errors.father as unknown as ParentInput)?.nfcTagId}</Text>
						</FormErrorMessage>
					</FormControl>
				</VStack>
			</VStack>

			<VStack w="full" align="stretch">
				<Heading fontSize="sm">Mother</Heading>
				<VStack w="full" align="stretch" pl="4">
					<FormControl
						isInvalid={Boolean((formik.errors.mother as unknown as ParentInput)?.name && (formik.touched.mother as unknown as ParentInput)?.name)}
					>
						<SlideFade in={Boolean((formik.values.mother as unknown as ParentInput)?.name)} unmountOnExit>
							<FormLabel htmlFor="mother.name" fontSize="sm">
								Name
							</FormLabel>
						</SlideFade>
						<Input
							placeholder="Name"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("mother.name")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{(formik.errors.mother as unknown as ParentInput)?.name}</Text>
						</FormErrorMessage>
					</FormControl>

					<FormControl
						isInvalid={Boolean((formik.errors.mother as unknown as ParentInput)?.phone && (formik.touched.mother as unknown as ParentInput)?.phone)}
					>
						<SlideFade in={Boolean((formik.values.mother as ParentInput)?.phone)} unmountOnExit>
							<FormLabel htmlFor="mother.phone" fontSize="sm">
								Phone
							</FormLabel>
						</SlideFade>
						<Input
							placeholder="Phone"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("mother.phone")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{(formik.errors.mother as unknown as ParentInput)?.phone}</Text>
						</FormErrorMessage>
					</FormControl>

					<FormControl
						isInvalid={Boolean((formik.errors.mother as unknown as ParentInput)?.email && (formik.touched.mother as unknown as ParentInput)?.email)}
					>
						<SlideFade in={Boolean((formik.values.mother as unknown as ParentInput)?.email)} unmountOnExit>
							<FormLabel htmlFor="mother.email" fontSize="sm">
								Email
							</FormLabel>
						</SlideFade>
						<Input
							placeholder="Email"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("mother.email")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{(formik.errors.mother as unknown as ParentInput)?.email}</Text>
						</FormErrorMessage>
					</FormControl>
					<FormControl
						isInvalid={Boolean(
							(formik.errors.mother as unknown as ParentInput)?.nfcTagId && (formik.touched.mother as unknown as ParentInput)?.nfcTagId
						)}
					>
						<SlideFade in={Boolean((formik.values.mother as unknown as ParentInput)?.nfcTagId)} unmountOnExit>
							<FormLabel htmlFor="mother.nfcTagId" fontSize="sm">
								NFC Tag Id
							</FormLabel>
						</SlideFade>
						<Input
							placeholder="NFC Tag Id"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("mother.nfcTagId")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{(formik.errors.mother as unknown as ParentInput)?.nfcTagId}</Text>
						</FormErrorMessage>
					</FormControl>
				</VStack>
			</VStack>

			<VStack w="full" align="stretch">
				<Heading fontSize="sm">Guardian 1</Heading>
				<VStack w="full" align="stretch" pl="4">
					<FormControl
						isInvalid={Boolean(
							(formik.errors.guardian1 as unknown as ParentInput)?.name && (formik.touched.guardian1 as unknown as ParentInput)?.name
						)}
					>
						<SlideFade in={Boolean((formik.values.guardian1 as unknown as ParentInput)?.name)} unmountOnExit>
							<FormLabel htmlFor="guardian1.name" fontSize="sm">
								Name
							</FormLabel>
						</SlideFade>
						<Input
							placeholder="Name"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("guardian1.name")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{(formik.errors.guardian1 as unknown as ParentInput)?.name}</Text>
						</FormErrorMessage>
					</FormControl>

					<FormControl
						isInvalid={Boolean(
							(formik.errors.guardian1 as unknown as ParentInput)?.phone && (formik.touched.guardian1 as unknown as ParentInput)?.phone
						)}
					>
						<SlideFade in={Boolean((formik.values.guardian1 as unknown as ParentInput)?.phone)} unmountOnExit>
							<FormLabel htmlFor="guardian1.phone" fontSize="sm">
								Phone
							</FormLabel>
						</SlideFade>
						<Input
							placeholder="Phone"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("guardian1.phone")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{(formik.errors.guardian1 as unknown as ParentInput)?.phone}</Text>
						</FormErrorMessage>
					</FormControl>

					<FormControl
						isInvalid={Boolean(
							(formik.errors.guardian1 as unknown as ParentInput)?.email && (formik.touched.guardian1 as unknown as ParentInput)?.email
						)}
					>
						<SlideFade in={Boolean((formik.values.guardian1 as unknown as ParentInput)?.email)} unmountOnExit>
							<FormLabel htmlFor="guardian1.email" fontSize="sm">
								Email
							</FormLabel>
						</SlideFade>
						<Input
							placeholder="Email"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("guardian1.email")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{(formik.errors.guardian1 as unknown as ParentInput)?.email}</Text>
						</FormErrorMessage>
					</FormControl>
					<FormControl
						isInvalid={Boolean(
							(formik.errors.guardian1 as unknown as ParentInput)?.nfcTagId && (formik.touched.guardian1 as unknown as ParentInput)?.nfcTagId
						)}
					>
						<SlideFade in={Boolean((formik.values.guardian1 as unknown as ParentInput)?.nfcTagId)} unmountOnExit>
							<FormLabel htmlFor="guardian1.nfcTagId" fontSize="sm">
								NFC Tag Id
							</FormLabel>
						</SlideFade>
						<Input
							placeholder="NFC Tag Id"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("guardian1.nfcTagId")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{(formik.errors.guardian1 as unknown as ParentInput)?.nfcTagId}</Text>
						</FormErrorMessage>
					</FormControl>
				</VStack>
			</VStack>

			<VStack w="full" align="stretch">
				<Heading fontSize="sm">Guardian 2</Heading>
				<VStack w="full" align="stretch" pl="4">
					<FormControl
						isInvalid={Boolean(
							(formik.errors.guardian2 as unknown as ParentInput)?.name && (formik.touched.guardian2 as unknown as ParentInput)?.name
						)}
					>
						<SlideFade in={Boolean((formik.values.guardian2 as unknown as ParentInput)?.name)} unmountOnExit>
							<FormLabel htmlFor="guardian2.name" fontSize="sm">
								Name
							</FormLabel>
						</SlideFade>
						<Input
							placeholder="Name"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("guardian2.name")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{(formik.errors.guardian2 as unknown as ParentInput)?.name}</Text>
						</FormErrorMessage>
					</FormControl>

					<FormControl
						isInvalid={Boolean(
							(formik.errors.guardian2 as unknown as ParentInput)?.phone && (formik.touched.guardian2 as unknown as ParentInput)?.phone
						)}
					>
						<SlideFade in={Boolean((formik.values.guardian2 as unknown as ParentInput)?.phone)} unmountOnExit>
							<FormLabel htmlFor="guardian2.phone" fontSize="sm">
								Phone
							</FormLabel>
						</SlideFade>
						<Input
							placeholder="Phone"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("guardian2.phone")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{(formik.errors.guardian2 as unknown as ParentInput)?.phone}</Text>
						</FormErrorMessage>
					</FormControl>

					<FormControl
						isInvalid={Boolean(
							(formik.errors.guardian2 as unknown as ParentInput)?.email && (formik.touched.guardian2 as unknown as ParentInput)?.email
						)}
					>
						<SlideFade in={Boolean((formik.values.guardian2 as unknown as ParentInput)?.email)} unmountOnExit>
							<FormLabel htmlFor="guardian2.email" fontSize="sm">
								Email
							</FormLabel>
						</SlideFade>
						<Input
							placeholder="Email"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("guardian2.email")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{(formik.errors.guardian2 as unknown as ParentInput)?.email}</Text>
						</FormErrorMessage>
					</FormControl>
					<FormControl
						isInvalid={Boolean(
							(formik.errors.guardian2 as unknown as ParentInput)?.nfcTagId && (formik.touched.guardian2 as unknown as ParentInput)?.nfcTagId
						)}
					>
						<SlideFade in={Boolean((formik.values.guardian2 as unknown as ParentInput)?.nfcTagId)} unmountOnExit>
							<FormLabel htmlFor="guardian2.nfcTagId" fontSize="sm">
								NFC Tag Id
							</FormLabel>
						</SlideFade>
						<Input
							placeholder="NFC Tag Id"
							border="none"
							_focus={{ border: "none" }}
							maxW="md"
							bg="white.500"
							rounded="xl"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("guardian2.nfcTagId")}
						/>
						<FormErrorMessage>
							<FormErrorIcon />
							<Text>{(formik.errors.guardian2 as unknown as ParentInput)?.nfcTagId}</Text>
						</FormErrorMessage>
					</FormControl>
				</VStack>
			</VStack>

			<HStack w="full" spacing={0} align="flex-end">
				<FormControl isInvalid={Boolean(formik.errors.transportMode && formik.touched.transportMode)}>
					<SlideFade in={Boolean(formik.values.transportMode)} unmountOnExit>
						<FormLabel htmlFor="transportMode" fontSize="sm" fontWeight="bold">
							Transport Mode
						</FormLabel>
					</SlideFade>
					<Select
						placeholder="Select Transport Mode"
						border="none"
						_focus={{
							border: "none",
							borderRight: ".5px solid",
							borderRightColor: "blackAlpha.200",
						}}
						borderRight=".5px solid"
						borderRightColor="blackAlpha.200"
						bg="white.500"
						roundedLeft="xl"
						roundedRight={formik.values.transportMode == "School Bus" ? "none" : "xl"}
						py="1"
						autoComplete="off"
						{...formik.getFieldProps("transportMode")}
					>
						<option>School Bus</option>
						<option>Private</option>
					</Select>
					<FormErrorMessage>{formik.errors.transportMode}</FormErrorMessage>
				</FormControl>

				{fetchingBusRoutes ? (
					<Center w="full" py="1">
						<VStack>
							<Spinner size="sm" color="text.400" />
							<Text fontSize="xs" color="text.400">
								Loading bus routes
							</Text>
						</VStack>
					</Center>
				) : busRoutesError ? (
					<Center w="full" py="1">
						<VStack>
							<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
							<Text fontSize="xs" color="text.400">
								{busRoutesError.message.replace("[GraphQL] ", "")}
							</Text>
						</VStack>
					</Center>
				) : (
					<FormControl
						isInvalid={Boolean(formik.errors.busRouteId && formik.touched.busRouteId)}
						display={formik.values.transportMode == "School Bus" ? "block" : "none"}
					>
						<SlideFade in={Boolean(formik.values.busRouteId)} unmountOnExit>
							<FormLabel htmlFor="busRouteId" fontSize="sm" fontWeight="bold">
								Bus Route
							</FormLabel>
						</SlideFade>
						<Select
							placeholder="Select Bus Route"
							border="none"
							_focus={{
								border: "none",
								borderLeft: ".5px solid",
								borderLeftColor: "blackAlpha.200",
							}}
							borderLeft=".5px solid"
							borderLeftColor="blackAlpha.200"
							bg="white.500"
							roundedRight="xl"
							roundedLeft="none"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("busRouteId")}
						>
							{busRoutesData?.busRoutes.map((route) => (
								<option key={route.routeName} value={route._id}>
									{route.routeName}
								</option>
							))}
						</Select>
						<FormErrorMessage>{formik.errors.busRouteId}</FormErrorMessage>
					</FormControl>
				)}
			</HStack>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Save
			</Button>
		</VStack>
	)
}
