import { Center, Container, Heading, HStack, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React from "react"
import { useParams } from "react-router-dom"
import { Nav, DashboardHeader, AddClassTeacherForm } from "../../components"
import { GRADIENT } from "../../constants"
import { useClassByIdQuery, useClassSectionByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const AddClassTeacher: React.FC = () => {
	const { classId = "", classSectionId = "" } = useParams<{
		classId: string
		classSectionId: string
	}>()

	const [classResult] = useClassByIdQuery({
		variables: { classId },
	})

	const [classSectionResult] = useClassSectionByIdQuery({
		variables: { classSectionId },
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{classResult.fetching || classSectionResult.fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : classResult.error || classSectionResult.error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{(classResult.error || classSectionResult.error)!.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : classResult.data?.classById && classSectionResult.data?.classSectionById ? (
						<HStack w="full" py="4" align="flex-start" justify="space-between">
							<VStack align="flex-start">
								<Heading as="h1" fontSize="xl" color="text.500">
									Add Class Teacher | {classResult.data.classById.name} {classSectionResult.data.classSectionById.name}
								</Heading>
								<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
									{
										// TODO: add sub heading
									}
								</Heading>
							</VStack>
						</HStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the Class or Section.
							</Text>
						</Center>
					)}
					<AddClassTeacherForm classId={classId} classSectionId={classSectionId} />
				</VStack>
			</HStack>
		</Container>
	)
}
