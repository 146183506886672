import {
	Box,
	Center,
	Container,
	Flex,
	Heading,
	HStack,
	Icon,
	IconButton,
	Spinner,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react"
import { faExclamationCircle, faPencil } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
	AddReaderToRestrictedZonePopover,
	Card,
	DashboardHeader,
	Nav,
	RemoveReaderFromRestrictedZone,
	UnauthorizedClasses,
	UnauthorizedDepartments,
} from "../../components"
import { GRADIENT } from "../../constants"
import { useRestrictedZoneByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const RestrictedZone: React.FC = () => {
	const { restrictedZoneId = "" } = useParams<{ restrictedZoneId: string }>()

	const [{ data, fetching, error }] = useRestrictedZoneByIdQuery({
		variables: { restrictedZoneId },
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const navigate = useNavigate()

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.restrictedZoneById ? (
						<VStack w="full" align="flex-start" spacing="6">
							<HStack w="full" py="4" align="flex-start" justify="space-between">
								<VStack align="flex-start">
									<HStack spacing={6}>
										<Heading as="h1" fontSize="xl" color="text.500">
											{data.restrictedZoneById.name}
										</Heading>
										<IconButton
											aria-label="edit-restricted-zone"
											size="xs"
											colorScheme="purple"
											onClick={() => navigate(`/restrictedZones/${data.restrictedZoneById!._id}/edit`)}
										>
											<Icon as={(props: any) => <FontAwesomeIcon icon={faPencil} {...props} />} />
										</IconButton>
									</HStack>
									<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
										Created {format(new Date(data.restrictedZoneById.createdAt), "PPPp")}
									</Heading>
								</VStack>
							</HStack>
							<VStack w="full" maxW="2xl" align="flex-start" spacing={4}>
								<HStack w="full" justify="space-between">
									<Heading as="h2" fontSize="md" fontWeight="semibold" color="text.500">
										Readers
									</Heading>
									<AddReaderToRestrictedZonePopover restrictedZoneId={restrictedZoneId} />
								</HStack>
								<Flex flexWrap="wrap" w="full" align="flex-start">
									{data.restrictedZoneById.readers.length ? (
										data.restrictedZoneById.readers.map((reader) => (
											<Card key={reader._id} w="full" cursor="default">
												<VStack w="full" align="stretch" spacing={4}>
													<HStack w="full" justify="space-between">
														{reader.isActive ? (
															<HStack>
																<Box w="3" h="3" rounded="full" bgColor="green.500" />
																<Text fontSize="xs" color="text.400">
																	Active
																</Text>
															</HStack>
														) : (
															<HStack>
																<Box w="3" h="3" rounded="full" bgColor="red.500" />
																<Text fontSize="xs" color="text.400">
																	Inactive
																</Text>
															</HStack>
														)}
														<RemoveReaderFromRestrictedZone restrictedZoneId={restrictedZoneId} readerId={reader._id} />
													</HStack>

													<VStack w="full" align="stretch" spacing={0}>
														<Text fontSize="xl" fontWeight="semibold" lineHeight="100%">
															{reader.readerHexId}
														</Text>
														<Text fontSize="md" color="text.400">
															{reader.readerDecimalId}
														</Text>
													</VStack>
													<HStack>
														{reader.lastConnectedAt && reader.lastConnectionEndpoint && (
															<VStack w="full" align="stretch" spacing={0}>
																<Text fontSize="sm" fontWeight="semibold">
																	{format(new Date(reader.lastConnectedAt), "PPp")}
																</Text>
																<Text fontSize="xs" color="text.400">
																	Last Connection
																</Text>
															</VStack>
														)}

														{reader.lastHeartbeatAt && (
															<VStack w="full" align="stretch" spacing={0}>
																<Text fontSize="sm" fontWeight="semibold">
																	{format(new Date(reader.lastHeartbeatAt), "PPp")}
																</Text>
																<Text fontSize="xs" color="text.400">
																	Last Heartbeat
																</Text>
															</VStack>
														)}
													</HStack>
												</VStack>
											</Card>
										))
									) : (
										<VStack flex={8} w="full" maxW="2xl" color="red.800">
											<Icon as={(props: any) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />} />
											<Text textAlign="center">No readers found</Text>
										</VStack>
									)}
								</Flex>
							</VStack>
							<Tabs w="full" size="sm" colorScheme="purple">
								<TabList>
									<Tab>Classes</Tab>
									<Tab>Departments</Tab>
								</TabList>
								<TabPanels>
									<TabPanel px="0">
										<VStack w="full">
											<UnauthorizedClasses restrictedZoneId={restrictedZoneId} />
										</VStack>
									</TabPanel>
									<TabPanel px="0">
										<VStack>
											<UnauthorizedDepartments restrictedZoneId={restrictedZoneId} />
										</VStack>
									</TabPanel>
								</TabPanels>
							</Tabs>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the restricted zone.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
