import { List } from "@chakra-ui/react"
import React from "react"
import { ExtendedWorkshopFragment } from "../../graphql"
import { SessionListItem } from "./SessionListItem"

type WorkshopSessionsListProps = {
	workshop: ExtendedWorkshopFragment
}

export const WorkshopSessionsList: React.FC<WorkshopSessionsListProps> = ({ workshop }) => {
	return (
		<List w="full" overflowY="auto">
			{[...(workshop.sessions || []), ...(workshop.sessions || [])]?.map((session, index) => (
				<SessionListItem key={session._id} session={session} index={index} />
			))}
		</List>
	)
}
