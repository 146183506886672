/* eslint-disable @typescript-eslint/indent */
import { Box, ButtonGroup, HStack, Icon, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { CafeteriaPaymentLogFragment, CafeteriaPaymentStatus, CafeteriaRegistrationStatus } from "../../../graphql"
import { StudentTag } from "../../student"

export type CafeteriaTransactionsTableProps = {
	cafeteriaTransactions: CafeteriaPaymentLogFragment[]
	prevPage: () => void
	nextPage: () => void
	hasPrevPage: boolean
	hasNextPage: boolean
	currentPage: number
	totalPages: number
}

export const CafeteriaTransactionsTable: React.FC<CafeteriaTransactionsTableProps> = ({
	cafeteriaTransactions,
	prevPage,
	nextPage,
	hasPrevPage,
	hasNextPage,
	currentPage,
	totalPages,
}) => {
	return (
		<VStack w="full" spacing="0">
			<HStack alignSelf="flex-end">
				<Text fontSize="xs" color="text.400">
					{currentPage}/{totalPages}
				</Text>
				<ButtonGroup size="xs" p="4">
					<IconButton aria-label="prev-btn" onClick={prevPage} isDisabled={!hasPrevPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowLeft} {...props} />} color="text.500" />
					</IconButton>
					<IconButton aria-label="next-btn" onClick={nextPage} isDisabled={!hasNextPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} color="text.500" />
					</IconButton>
				</ButtonGroup>
			</HStack>
			<Box w="full" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th textTransform="capitalize">Student</Th>
							<Th textTransform="capitalize">Merchant</Th>
							<Th textTransform="capitalize">Meal Type</Th>
							<Th textTransform="capitalize">Status</Th>
							<Th textTransform="capitalize">Registration</Th>
							<Th textTransform="capitalize">Created</Th>
						</Tr>
					</Thead>
					<Tbody>
						{cafeteriaTransactions.map((transaction) => (
							<Tr key={transaction._id} cursor="pointer" fontSize="sm">
								<Td py="0">
									<StudentTag student={transaction.student} />
								</Td>
								<Td>
									{transaction.paytankMerchant.name} @{transaction.paytankMerchant.username}
								</Td>
								<Td>{transaction.cafeteriaMealType.name}</Td>
								<Td>
									<VStack align="flex-start" spacing={0}>
										<Text color={transaction.status === CafeteriaPaymentStatus.Denied ? "red.600" : "green.600"} fontWeight="bold">
											{transaction.status}
										</Text>
										{transaction.denialRemarks ? (
											<Text color="red.600" fontSize="xs">
												{transaction.denialRemarks}
											</Text>
										) : (
											<></>
										)}
									</VStack>
								</Td>
								<Td>
									<Text
										color={transaction.cafeteriaRegistrationStatus === CafeteriaRegistrationStatus.Inactive ? "red.600" : "green.600"}
										fontWeight="bold"
									>
										{transaction.cafeteriaRegistrationStatus}
									</Text>
								</Td>
								<Td>{format(new Date(transaction.createdAt), "PPp")}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
		</VStack>
	)
}
