/* eslint-disable @typescript-eslint/indent */
import { Box, ButtonGroup, HStack, Icon, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { faArrowLeft, faArrowRight, faCheckCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { StudentAttendanceFragment } from "../../graphql"

export type StudentAttendancesTableProps = {
	attendances: StudentAttendanceFragment[]
	prevPage: () => void
	nextPage: () => void
	hasPrevPage: boolean
	hasNextPage: boolean
	currentPage: number
	totalPages: number
}

export const StudentAttendancesTable: React.FC<StudentAttendancesTableProps> = ({
	attendances,
	prevPage,
	nextPage,
	hasPrevPage,
	hasNextPage,
	currentPage,
	totalPages,
}) => {
	return (
		<VStack w="full" spacing="0">
			<HStack alignSelf="flex-end">
				<Text fontSize="xs" color="text.400">
					{currentPage}/{totalPages}
				</Text>
				<ButtonGroup size="xs" p="4">
					<IconButton aria-label="prev-btn" onClick={prevPage} isDisabled={!hasPrevPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowLeft} {...props} />} color="text.500" />
					</IconButton>
					<IconButton aria-label="next-btn" onClick={nextPage} isDisabled={!hasNextPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} color="text.500" />
					</IconButton>
				</ButtonGroup>
			</HStack>
			<Box w="full" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th textTransform="capitalize">Date</Th>
							<Th textTransform="capitalize">First Seen</Th>
							<Th textTransform="capitalize">Last Seen</Th>
							<Th textTransform="capitalize">Status</Th>
						</Tr>
					</Thead>
					<Tbody>
						{attendances.map((attendance) => (
							<Tr key={attendance._id} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td>{format(new Date(attendance.inAt), "dd-MM-yyyy")}</Td>
								<Td>{format(new Date(attendance.inAt), "p")}</Td>
								<Td>{format(new Date(attendance.outAt), "p")}</Td>
								<Td>
									{attendance.isLate !== null ? (
										<HStack>
											{" "}
											<Icon
												color={attendance.isLate ? "red" : "green"}
												as={(props: any) => <FontAwesomeIcon icon={attendance!.isLate ? faXmarkCircle : faCheckCircle} {...props} />}
											/>
											<Text fontSize="sm" color="text.400" fontWeight="semibold">
												{attendance!.isLate
													? `Late ${
															attendance.lateByMinutes && attendance.lateByMinutes >= 0
																? `by ${attendance.lateByMinutes || 0} minutes`
																: ""
													  }`
													: "On time"}
											</Text>
										</HStack>
									) : (
										<Text>-</Text>
									)}
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
		</VStack>
	)
}
