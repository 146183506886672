import { Center, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { SearchHousesQueryVariables, useSearchHousesQuery } from "../../graphql"
import { HousesTable } from "./HousesTable"

export type SearchResultHousesProps = {
	searchKeyword: string
}

export const SearchResultHouses: React.FC<SearchResultHousesProps> = ({ searchKeyword }) => {
	const [pagination, setPagination] = useState<SearchHousesQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useSearchHousesQuery({
		variables: { pagination, keyword: searchKeyword },
	})

	const prevPage = () => {
		if (data?.searchHouses.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.searchHouses.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="md" fontWeight="normal" color="text.500">
				Showing {data?.searchHouses.total || "all"} houses for search keyword <strong>&quot;{searchKeyword}&quot;</strong>
			</Heading>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.searchHouses.houses && data?.searchHouses.houses.length > 0 ? (
				<HousesTable
					houses={data.searchHouses.houses}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.searchHouses.hasPrevPage}
					hasNextPage={data.searchHouses.hasNextPage}
					currentPage={data.searchHouses.currentPage}
					totalPages={data.searchHouses.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any houses.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
