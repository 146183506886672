import {
	AspectRatio,
	Button,
	ButtonGroup,
	Center,
	Container,
	Grid,
	Heading,
	HStack,
	Icon,
	Img,
	Spinner,
	Text,
	useBreakpointValue,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faFilePdf } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { DashboardHeader, Nav } from "../../components"
import { ResourceAttachments } from "../../components/resource"
import { UploadMediaButton } from "../../components/upload"
import { GRADIENT } from "../../constants"
import { AddAttachmentToResourceMutationVariables, MediaTypes, useAddAttachmentToResourceMutation, useResourceByIdQuery } from "../../graphql"
import { useDrawer, useUpload } from "../../hooks"

export const AddResourceAttachments: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const { resourceId = "" } = useParams<{ resourceId: string }>()

	const [{ data, fetching, error }] = useResourceByIdQuery({
		variables: { resourceId },
	})

	const { clearUpload, clearAllUploads, uploads } = useUpload()

	const allowedMediaTypes = useMemo(() => {
		if (data?.resourceById?.attachments?.pdfs?.length) {
			return [MediaTypes.Pdf]
		}

		if (data?.resourceById?.attachments?.videos?.length) {
			return [MediaTypes.Video]
		}

		if (data?.resourceById?.attachments?.pictures?.length) {
			return [MediaTypes.Image]
		}

		if (uploads.length > 0) {
			return [uploads[0].type]
		}

		return [MediaTypes.Image, MediaTypes.Pdf, MediaTypes.Video]
	}, [uploads])

	const navigate = useNavigate()

	const [{ fetching: addingAttachments }, addAttachments] = useAddAttachmentToResourceMutation()
	const toast = useToast()

	const handleAddAttachments = async () => {
		const attachments: AddAttachmentToResourceMutationVariables["input"] = {}

		if (uploads?.length) {
			if (uploads[0].type === MediaTypes.Image) {
				attachments.pictures = uploads
					.filter(({ type, key, url, isUploaded }) => isUploaded && type === MediaTypes.Image && typeof key === "string" && typeof url === "string")
					.map(({ key, url }) => ({ key: key!, url: url! }))
			}

			if (uploads[0].type === MediaTypes.Video) {
				attachments.videos = uploads
					.filter(({ type, key, url, isUploaded }) => isUploaded && type === MediaTypes.Video && typeof key === "string" && typeof url === "string")
					.map(({ key, url }) => ({ key: key!, url: url! }))
			}

			if (uploads[0].type === MediaTypes.Pdf) {
				attachments.pdfs = uploads
					.filter(({ type, key, url, isUploaded }) => isUploaded && type === MediaTypes.Pdf && typeof key === "string" && typeof url === "string")
					.map(({ key, url }) => ({ key: key!, url: url! }))
			}
		}

		const { error } = await addAttachments({ resourceId, input: attachments })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		clearAllUploads()
		navigate(`/resources/${resourceId}`)
	}

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					<VStack maxW="lg" w="full" align="flex-start">
						<HStack w="full" justify="space-between">
							<Heading as="h1" fontSize="xl" color="text.500">
								Add Attachments
							</Heading>
							<ButtonGroup>
								<UploadMediaButton maxUploads={4} allowedTypes={allowedMediaTypes} />
								{uploads.length ? (
									<Button colorScheme="purple" size="sm" isLoading={addingAttachments} onClick={handleAddAttachments}>
										Add Attachments
									</Button>
								) : (
									<></>
								)}
							</ButtonGroup>
						</HStack>
						<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
							{
								// TODO: add sub heading
							}
						</Heading>
					</VStack>

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.resourceById ? (
						<VStack w="full" align="stretch" maxW="lg">
							<ResourceAttachments onRemove={(id) => clearUpload({ id })} />

							{data.resourceById.attachments?.pdfs?.length ? (
								<Grid templateColumns={getGridTemplateColumns(data.resourceById.attachments.pdfs.length || 0)} gap={1}>
									{data.resourceById.attachments.pdfs.slice(0, 4).map((pdf, index) => (
										<AspectRatio
											pos="relative"
											key={index}
											bg="primary.100"
											rounded="xl"
											ratio={1}
											cursor="pointer"
											onClick={() => window.open(pdf.url, "#")}
											zIndex={1}
										>
											<>
												<Center rounded="xl" bg="primary.100">
													<Icon
														color="primary.600"
														fontSize="5xl"
														as={(props: any) => <FontAwesomeIcon icon={faFilePdf} {...props} />}
													/>
												</Center>

												{index === 3 && (data.resourceById?.attachments?.pdfs?.length || 0) > 4 && (
													<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
														<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
															+{(data.resourceById?.attachments?.pdfs?.length || 0) - 4}
														</Text>
													</Center>
												)}
											</>
										</AspectRatio>
									))}
								</Grid>
							) : data.resourceById.attachments?.videos?.length ? (
								<Grid templateColumns={getGridTemplateColumns(data.resourceById.attachments.videos.length)} gap={1}>
									{data.resourceById.attachments.videos.slice(0, 4).map((video, index) => (
										<AspectRatio pos="relative" key={index} bg="primary.100" rounded="xl" ratio={1} zIndex={1}>
											<>
												<video style={{ borderRadius: "12px" }} width="100%" src={video.url} controls />
												{index === 3 && (data.resourceById?.attachments?.videos?.length || 0) > 4 && (
													<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
														<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
															+{(data.resourceById?.attachments?.videos?.length || 0) - 4}
														</Text>
													</Center>
												)}
											</>
										</AspectRatio>
									))}
								</Grid>
							) : data.resourceById.attachments?.pictures?.length ? (
								<Grid templateColumns={getGridTemplateColumns(data.resourceById.attachments.pictures.length)} gap={1}>
									{data.resourceById.attachments.pictures.slice(0, 4).map((picture, index) => (
										<AspectRatio key={index} pos="relative" bg="primary.100" rounded="xl" ratio={1} zIndex={1}>
											<>
												<Img rounded="xl" src={picture.url} />
												{index === 3 && (data.resourceById?.attachments?.pictures?.length || 0) > 4 && (
													<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
														<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
															+{(data.resourceById?.attachments?.pictures?.length || 0) - 4}
														</Text>
													</Center>
												)}
											</>
										</AspectRatio>
									))}
								</Grid>
							) : (
								<Center w="full" py="4">
									<Text fontSize="md" fontWeight="semibold" color="text.400">
										There are no attachments
									</Text>
								</Center>
							)}
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the resource.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}

const getGridTemplateColumns = (length: number) => {
	if (length < 2) {
		return "1fr"
	}

	return "repeat(2, 1fr)"
}
