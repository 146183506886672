import { AspectRatio } from "@chakra-ui/react"
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js"
import { flatten } from "lodash"
import React, { useMemo } from "react"
import { Bar, ChartProps } from "react-chartjs-2"
import { PaymentOrderStatus, useMerchantTypeWisePaytankOrdersQuery } from "../../../graphql"
import { Card } from "../../common"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export type MerchantTypeWiseOrdersProps = {
	sinceDate: Date
	untilDate: Date
}

export const MerchantTypeWiseOrders: React.FC<MerchantTypeWiseOrdersProps> = ({ sinceDate, untilDate }) => {
	const [{ data }] = useMerchantTypeWisePaytankOrdersQuery({ variables: { sinceDate, untilDate } })

	const chartData = useMemo(
		() =>
			({
				labels: data?.merchantTypeWisePaytankOrders.map((o) => o.merchantType.name),
				datasets: [
					{
						label: "Successful",
						data:
							flatten(
								data?.merchantTypeWisePaytankOrders.map((s) =>
									s.orders
										.filter((o) => o.status === PaymentOrderStatus.Success)
										.map((o) => o.amount)
										.reduce((a, b) => a + b, 0)
								)
							) || [],
						backgroundColor: "#95D76B",
					},
					{
						label: "Failed",
						data:
							flatten(
								data?.merchantTypeWisePaytankOrders.map((s) =>
									s.orders
										.filter((o) => o.status === PaymentOrderStatus.Failed)
										.map((o) => o.amount)
										.reduce((a, b) => a + b, 0)
								)
							) || [],
						backgroundColor: "#EA655E",
					},
					{
						label: "Initiated",
						data:
							flatten(
								data?.merchantTypeWisePaytankOrders.map((s) =>
									s.orders
										.filter((o) => o.status === PaymentOrderStatus.Initiated)
										.map((o) => o.amount)
										.reduce((a, b) => a + b, 0)
								)
							) || [],
						backgroundColor: "#FFA500",
					},
				],
			} as ChartProps<"bar">["data"]),
		[data]
	)

	return (
		<Card maxW="full" disableAnimation>
			<AspectRatio ratio={16 / 9} w="full" alignSelf="center">
				<Bar
					options={{
						indexAxis: "y" as const,
						responsive: true,
						plugins: {
							legend: {
								position: "bottom" as const,
							},
							title: {
								display: true,
								text: "Merchant Type wise transactions (in ₹)",
							},
						},
						scales: {
							x: {
								stacked: true,
							},
							y: {
								stacked: true,
							},
						},
					}}
					data={chartData}
				/>
			</AspectRatio>
		</Card>
	)
}
