import { Center, Container, Heading, HStack, Select, Spinner, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { Nav, DashboardHeader } from "../../../components"
import { StaffsSelector } from "../../../components/penalty"
import { GRADIENT } from "../../../constants"
import { useDepartmentsQuery, DepartmentFragment, useStaffsWithoutPaginationQuery } from "../../../graphql"
import { useDrawer } from "../../../hooks"

export const AddPenaltyToStaff: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [{ data: departmentsData, fetching: fetchingDepartments, error: departmentsError }] = useDepartmentsQuery()

	const [department, setDepartment] = useState<DepartmentFragment>()

	const [{ data, fetching, error }] = useStaffsWithoutPaginationQuery({ variables: { departmentId: department?._id } })

	useEffect(() => {
		setSelectedStaffIds([])
	}, [department])

	const [selectedStaffIds, setSelectedStaffIds] = useState<string[]>([])

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Add penalty to staff
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								Showing data for <strong>{department?.name || "all"}</strong>
							</Heading>
						</VStack>
						{fetchingDepartments ? (
							<Center>
								<VStack w="full" color="blackAlpha.800">
									<Text>Loading departments</Text>
									<Spinner size="sm" />
								</VStack>
							</Center>
						) : departmentsError ? (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
									Failed loading departments, try reloading the page
								</Text>
							</Center>
						) : departmentsData?.departments.length ? (
							<Select
								bg="white.500"
								rounded="xl"
								border="none"
								_focus={{ border: "none" }}
								w="full"
								maxW="xs"
								placeholder="All"
								value={JSON.stringify(department)}
								onChange={(e) => {
									setDepartment(e.target.value ? JSON.parse(e.target.value) : undefined)
								}}
							>
								{departmentsData.departments.map((d) => (
									<option key={d.name} value={JSON.stringify(d)}>
										{d.name}
									</option>
								))}
							</Select>
						) : (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any departments.
								</Text>
							</Center>
						)}
					</Stack>
					{fetching ? (
						<Center py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center>
							<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
								Failed loading staffsWithoutPagination, try reloading the page
							</Text>
						</Center>
					) : data?.staffsWithoutPagination && data?.staffsWithoutPagination.length > 0 ? (
						<StaffsSelector staffs={data?.staffsWithoutPagination} selectStaffIds={selectedStaffIds} onChange={setSelectedStaffIds} />
					) : (
						<Center py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find any staff.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
