import { SubscriptionClient } from "subscriptions-transport-ws"
import { createClient, dedupExchange, fetchExchange, subscriptionExchange } from "urql"
import { GRAPHQL_WS_SERVER_ENDPOINT, GRAPHQL_SERVER_ENDPOINT } from "../constants"
import { authExchange, cacheExchange } from "./exchanges"

const subscriptionClient = new SubscriptionClient(GRAPHQL_WS_SERVER_ENDPOINT, {
	reconnect: true,
})

export const createUrqlClient = () =>
	createClient({
		url: GRAPHQL_SERVER_ENDPOINT,
		exchanges: [
			dedupExchange,
			cacheExchange,
			authExchange,
			fetchExchange,
			subscriptionExchange({
				forwardSubscription: (operation) => subscriptionClient?.request(operation) as any,
			}),
		],
	})
