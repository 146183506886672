import { Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useNavigate } from "react-router-dom"
import { ExtendedResourceFragment, useDeleteResourceMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type DeleteResourceButtonProps = {
	resource: ExtendedResourceFragment
}

export const DeleteResourceButton: React.FC<DeleteResourceButtonProps> = ({ resource }) => {
	const [{ fetching }, deleteresource] = useDeleteResourceMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleDeleteResource = async () => {
		const { error } = await deleteresource({ resourceId: resource._id })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		return navigate("/resources", { replace: true })
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<Tooltip label={`Delete resource ${resource.name}`} fontSize="sm">
				<IconButton aria-label="delete-resource-btn" variant="ghost" colorScheme="blackAlpha" size="sm" {...getButtonProps()}>
					<Icon as={(props: any) => <FontAwesomeIcon icon={faTrash} {...props} />} />
				</IconButton>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Delete Resource"
				message="Removing this resource may affect students and/or teachers associated with this resource."
				confirmLabel="Remove"
				onConfirm={handleDeleteResource}
				isLoading={fetching}
			/>
		</>
	)
}
