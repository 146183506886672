import { Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { StudentFragment, useRemoveHouseCaptainMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type RemoveHouseCaptainButtonProps = {
	houseId: string
	captain: StudentFragment
}

export const RemoveHouseCaptainButton: React.FC<RemoveHouseCaptainButtonProps> = ({ houseId, captain }) => {
	const [{ fetching }, removeCaptain] = useRemoveHouseCaptainMutation()

	const toast = useToast()

	const handleRemoveCaptain = async () => {
		const { error } = await removeCaptain({
			houseId,
			studentId: captain._id,
		})

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		return onClose()
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<Tooltip label={`Remove ${captain.name}`} fontSize="xs">
				<IconButton aria-label="remove-house-captain-btn" variant="ghost" colorScheme="blackAlpha" size="xs" {...getButtonProps()} isLoading={fetching}>
					<Icon as={(props: any) => <FontAwesomeIcon icon={faTrash} {...props} />} />
				</IconButton>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Remove House Captain"
				message="This will remove this captain from the role of house captain of this house."
				confirmLabel="Remove"
				onConfirm={handleRemoveCaptain}
				isLoading={fetching}
			/>
		</>
	)
}
