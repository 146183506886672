import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useAllCreditPaytankOrdersQuery } from "../../../graphql"
import { Card } from "../../common"

export type AllCreditOrdersProps = {
	sinceDate: Date
	untilDate: Date
}

const INR = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "INR",
})

export const AllCreditOrders: React.FC<AllCreditOrdersProps> = ({ sinceDate, untilDate }) => {
	const [{ data, fetching }] = useAllCreditPaytankOrdersQuery({ variables: { sinceDate, untilDate } })

	return (
		<Card>
			<VStack align="flex-start" spacing="6">
				<VStack align="flex-start" spacing="0">
					{fetching ? (
						<Center py="2">
							<Spinner size="md" />
						</Center>
					) : data?.allCreditPaytankOrders ? (
						<Text fontSize="3xl" fontWeight="semibold">
							{INR.format(data.allCreditPaytankOrders.map((o) => o.amount).reduce((a, b) => a + b, 0))}
						</Text>
					) : (
						<Text fontSize="3xl" fontWeight="semibold">
							No data
						</Text>
					)}
					<Text fontSize="sm" color="text.400">
						<strong>{data?.allCreditPaytankOrders.length || 0}</strong> Credit transactions
					</Text>
				</VStack>
			</VStack>
		</Card>
	)
}
