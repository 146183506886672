import { Box, Button, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from "@chakra-ui/react"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { ExtendedStudentFragment } from "../../graphql"
import { EditStudentSchoolTimingForm } from "./EditStudentSchoolTimingForm"

export type SchoolTimingOfStudentProps = { student: ExtendedStudentFragment }

export const SchoolTimingOfStudent: React.FC<SchoolTimingOfStudentProps> = ({ student }) => {
	return (
		<Popover isLazy lazyBehavior="unmount">
			<PopoverTrigger>
				<Box pos="relative">
					<Button size="xs" variant="solid" colorScheme="primary" rightIcon={!student.schoolTiming ? <FontAwesomeIcon icon={faPlus} /> : undefined}>
						School Timing {student.schoolTiming?.name || ""}
					</Button>
				</Box>
			</PopoverTrigger>
			<PopoverContent bgColor="white">
				<PopoverArrow bgColor="white" />
				<PopoverBody>
					<EditStudentSchoolTimingForm student={student} />
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
