import { Avatar, AvatarGroup, Button, Heading, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack } from "@chakra-ui/react"
import React, { useMemo, useState } from "react"
import { RewardItemFragment, StudentFragment, useRewardStudentsMutation } from "../../graphql"
import { RewardItemsSelectorForStudents } from "./RewardItemsSelectorForStudents"

export type RewardStudentsModalProps = {
	isOpen: boolean
	onClose: () => void
	students: StudentFragment[]
}

export const RewardStudentsModal: React.FC<RewardStudentsModalProps> = ({ isOpen, onClose, students }) => {
	const [selectedItems, setSelectedItems] = useState<RewardItemFragment[]>([])

	const totalPoints = useMemo(() => selectedItems.map((i) => i.points).reduce((a, b) => a + b, 0), [selectedItems])

	const [{ fetching: rewarding, error: rewardError, data: rewardData }, reward] = useRewardStudentsMutation()

	const handleReward = async () => {
		await reward({ studentIds: students.map((s) => s._id), rewardItemIds: selectedItems.map((i) => i._id) })
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered scrollBehavior="inside">
			<ModalOverlay />
			{rewardData?.rewardStudents ? (
				<ModalContent bgColor="white.400">
					<ModalBody>
						<VStack alignItems="center" py="6">
							<Heading fontSize="xl">Rewarded</Heading>
							<Heading fontSize="3xl" color="purple.500" textAlign="center">
								+{rewardData.rewardStudents.totalPoints} points
							</Heading>
							<Text fontWeight="semibold" color="blackAlpha.600">
								to
							</Text>
							<AvatarGroup max={3} gap={2}>
								{rewardData.rewardStudents.to.map(
									({ student }) => student && <Avatar rounded="2xl" key={student._id} src={student.picture?.url} name={student.name} />
								)}
							</AvatarGroup>
							<Text fontWeight="semibold" color="blackAlpha.600">
								for
							</Text>
							<Heading fontSize="xl" color="purple.500" textAlign="center">
								{rewardData.rewardStudents.rewardItems.map((i) => i.name).join(", ")}
							</Heading>
						</VStack>
					</ModalBody>
				</ModalContent>
			) : (
				<ModalContent bgColor="white.400">
					<ModalHeader>
						<VStack alignItems="center" py="2">
							<AvatarGroup max={3} gap={2}>
								{students.map((student) => (
									<Avatar rounded="2xl" key={student._id} src={student.picture?.url} name={student.name} />
								))}
							</AvatarGroup>
							<Heading fontSize="2xl" color="purple.500">
								+{totalPoints} points
							</Heading>
						</VStack>
					</ModalHeader>
					<ModalBody>
						<VStack alignItems="center">
							<VStack w="full" align="stretch">
								<RewardItemsSelectorForStudents onChange={setSelectedItems} />
								{rewardError ? (
									<Text fontSize="sm" color="red.500" textAlign="center">
										{rewardError.message.replace("[GraphQL] ", "")}
									</Text>
								) : (
									rewardData &&
									!rewardData.rewardStudents && (
										<Text fontSize="sm" color="red.500" textAlign="center">
											Some error occurred
										</Text>
									)
								)}
							</VStack>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<Button w="full" colorScheme="primary" onClick={handleReward} isLoading={rewarding} isDisabled={!selectedItems.length}>
							Reward
						</Button>
					</ModalFooter>
				</ModalContent>
			)}
		</Modal>
	)
}
