import {
	Button,
	Center,
	Container,
	Heading,
	HStack,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Stack,
	Tab,
	TabList,
	Tabs,
	Text,
	useBreakpointValue,
	useDisclosure,
	VStack,
} from "@chakra-ui/react"
import FullCalendar, { DateSelectArg, EventChangeArg, EventClickArg } from "@fullcalendar/react"
import { format } from "date-fns"
import React, { useState } from "react"
import { ConfirmationAlertDialog, DashboardHeader, Nav } from "../../components"
import { GRADIENT } from "../../constants"
import { AllHolidaysQueryVariables, useAllHolidaysQuery, useCreateHolidayMutation, useDeleteHolidayMutation, useEditHolidayMutation } from "../../graphql"
import { useDrawer } from "../../hooks"

import "@fullcalendar/common/main.css"

import "./custom.css"

import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from "@fullcalendar/timegrid"

export const Holidays: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [pagination] = useState<AllHolidaysQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ fetching, data }] = useAllHolidaysQuery({
		variables: { pagination },
	})

	const [, createNewHoliday] = useCreateHolidayMutation()

	const [, deleteHoliday] = useDeleteHolidayMutation()

	const [{ fetching: fethchingHolidays }, editHoliday] = useEditHolidayMutation()

	const [newHolidayName, setNewHoliayName] = useState("")

	const { isOpen, onOpen, onClose } = useDisclosure()

	const { isOpen: isCreateModalOpen, onOpen: onCreateModalOpen, onClose: onCreateModalClose } = useDisclosure()

	const [eventClickInfo, setEventClickInfo] = useState<EventClickArg>()

	const [dateSelectInfo, setDateSelectInfo] = useState<DateSelectArg>()

	const [eventType, setEventType] = useState<string>("Holiday")

	const handleDateSelect = async (selectInfo: DateSelectArg) => {
		setEventType("Holiday")
		onCreateModalOpen()

		const calendarApi = selectInfo.view.calendar
		calendarApi.unselect() // clear date selection

		setDateSelectInfo(selectInfo)
	}

	const createHoliday = async () => {
		await createNewHoliday({
			input: { name: newHolidayName!, eventType: eventType, startDate: dateSelectInfo!.start, endDate: dateSelectInfo!.end },
		})

		setNewHoliayName("")
		onCreateModalClose()
	}

	const handleEventClick = async (clickInfo: EventClickArg) => {
		onOpen()
		setEventClickInfo(clickInfo)
	}

	const deleteSelectedHoliday = async () => {
		await deleteHoliday({ holidayId: eventClickInfo!.event._def.publicId })
		onClose()
	}

	const handleEventChange = async (event: EventChangeArg) => {
		await editHoliday({
			holidayId: event.event._def.publicId,
			input: {
				name: event.event._def.title,
				startDate: format(new Date(event.event._instance!.range.start), "yyyy-MM-dd"),
				endDate: format(new Date(event.event._instance!.range.end), "yyyy-MM-dd"),
			},
		})
	}

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Calendar
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								{
									// TODO: add sub heading
								}
							</Heading>
						</VStack>
					</Stack>
					{fetching ? (
						<Center py="4">
							<Spinner color="text.400" />
						</Center>
					) : data?.allHolidays.holidays ? (
						<VStack>
							<FullCalendar
								headerToolbar={{ left: "prev,next,today", center: "title", right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek" }}
								initialView="dayGridMonth"
								plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
								height="auto"
								events={data.allHolidays.holidays.map(({ name, startDate, endDate, _id, eventType }) => {
									return {
										title: name,
										start: format(new Date(startDate), "yyyy-MM-dd"),
										end: format(new Date(endDate), "yyyy-MM-dd"),
										id: _id,
										backgroundColor: eventType == "Holiday" ? "#3a8f67" : "",
										borderColor: eventType == "Holiday" ? "#3a8f67" : "",
									}
								})}
								eventContent={renderEventContent}
								editable={true}
								selectable={true}
								selectMirror={true}
								dayMaxEvents={true}
								select={handleDateSelect}
								eventClick={handleEventClick}
								eventChange={handleEventChange}
							/>
							<Modal isCentered isOpen={isCreateModalOpen} onClose={onCreateModalClose}>
								<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(10deg)" />
								<ModalContent>
									<ModalHeader>Create {eventType}</ModalHeader>
									<ModalCloseButton />
									<ModalBody>
										<Tabs variant="unstyled" pb={5}>
											<TabList>
												<Tab _selected={{ color: "white", bg: "green.500" }} onClick={() => setEventType("Holiday")} borderRadius="xl">
													Holiday
												</Tab>
												<Tab _selected={{ color: "white", bg: "blue.400" }} onClick={() => setEventType("Event")} borderRadius="xl">
													Event
												</Tab>
											</TabList>
										</Tabs>
										<Input
											placeholder={`Enter name of ${eventType}`}
											border="none"
											_focus={{ border: "none" }}
											maxW="md"
											bg="white.500"
											rounded="xl"
											py="1"
											autoComplete="off"
											onChange={(e) => setNewHoliayName(e.target.value)}
											required
										/>
									</ModalBody>
									<ModalFooter>
										<Button colorScheme="whatsapp" onClick={createHoliday} disabled={newHolidayName.length === 0}>
											Create
										</Button>
									</ModalFooter>
								</ModalContent>
							</Modal>

							<ConfirmationAlertDialog
								isOpen={isOpen}
								onClose={onClose}
								title={eventClickInfo?.event.title ?? "Delete Holiday"}
								message={`Are you sure you want to delete this ${
									eventClickInfo?.event._def.ui.backgroundColor == "#3a8f67" ? "holiday" : "event"
								}`}
								confirmLabel="Delete"
								onConfirm={deleteSelectedHoliday}
								isLoading={fethchingHolidays}
							/>
						</VStack>
					) : (
						<Center py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find any holidays.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}

function renderEventContent(eventInfo: any) {
	return <Text>{eventInfo.event.title}</Text>
}
