import format from "date-fns/format"
import { nanoid } from "nanoid"

export const exportCSVData = (data: string) => {
	const a = document.createElement("a")

	a.href = "data:text/csv;charset=utf-8," + encodeURI(data)
	a.target = "_blank"

	a.download = `${format(new Date(), "P")}-${nanoid()}.csv`

	a.click()
}
