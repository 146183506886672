import { HStack } from "@chakra-ui/react"
import React from "react"
import { SchoolTimingFragment } from "../../graphql"
import { SchoolTimingCard } from "./SchoolTimingCard"

export type SchoolTimingsListProps = {
	schoolTimings: SchoolTimingFragment[]
	prevPage: () => void
	nextPage: () => void
	hasPrevPage: boolean
	hasNextPage: boolean
	currentPage: number
	totalPages: number
}

export const SchoolTimingsList: React.FC<SchoolTimingsListProps> = ({ schoolTimings }) => {
	return (
		<HStack w="full" flexWrap="wrap" spacing="4" align="stretch">
			{schoolTimings.map((schoolTiming) => (
				<SchoolTimingCard key={schoolTiming._id} schoolTiming={schoolTiming} />
			))}
		</HStack>
	)
}
