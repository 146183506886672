import { Avatar, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { TrainerFragment } from "../../graphql"
import { Rating } from "../common"
import { DeleteWorkshopTrainerButton } from "./DeleteWorkshopTrainerButton"

export type WorkshopTrainerProps = {
	workshopId: string
	workshopSessionId?: string
	trainer: TrainerFragment
}

export const WorkshopTrainer: React.FC<WorkshopTrainerProps> = ({ workshopSessionId, trainer }) => {
	const [isHovered, setIsHovered] = useState(false)

	const isMobile = useBreakpointValue({ base: true, md: false })

	return (
		<VStack
			as={NavLink}
			to={`/trainers/${trainer._id}`}
			position="relative"
			key={trainer._id}
			m="1"
			spacing={0}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<Avatar size="lg" src={trainer.picture?.url} name={trainer.name} />
			<Text fontSize="sm" fontWeight="semibold">
				{trainer.name}
			</Text>
			<Rating
				rating={(trainer.ratings?.map((a) => a.rating)?.reduce((a, b) => a + b, 0) || 0) / (trainer.ratings?.length || 0) || 0}
				total={trainer.ratings?.length || 0}
				variant="subtle"
			/>
			{(isMobile || isHovered) && workshopSessionId && <DeleteWorkshopTrainerButton workshopSessionId={workshopSessionId} trainerId={trainer._id} />}
		</VStack>
	)
}
