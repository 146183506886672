import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { usePenaltiesOfStaffQuery } from "../../graphql"
import { StaffPenaltyListItem } from "./StaffPenaltyListItem"

export type StaffPenaltiesListProps = {
	staffId: string
}

export const StaffPenaltiesList: React.FC<StaffPenaltiesListProps> = ({ staffId }) => {
	const [{ data, fetching, error }] = usePenaltiesOfStaffQuery({ variables: { staffId } })

	return (
		<VStack w="full" align="stretch">
			{fetching ? (
				<Center w="full" py="4">
					<Spinner color="text.400" />
				</Center>
			) : error ? (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="text.400">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.penaltiesOfStaff.length ? (
				<VStack spacing={4}>
					{data.penaltiesOfStaff.map((penalty) => (
						<StaffPenaltyListItem key={penalty._id} penalty={penalty} />
					))}
				</VStack>
			) : (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="text.400">
						Couldn&apos;t find any penalties.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
