import { Button, Center, FormControl, FormErrorMessage, FormLabel, Icon, Select, SlideFade, Spinner, Text, useToast, VStack } from "@chakra-ui/react"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { AddHouseCaptainMutationVariables, useAddHouseCaptainMutation, useStudentsQuery } from "../../graphql"

const validationSchema = Yup.object({
	studentId: Yup.string().required().label("Student"),
})

export type AddHouseCaptainFormProps = {
	houseId: string
}

export const AddHouseCaptainForm: React.FC<AddHouseCaptainFormProps> = ({ houseId }) => {
	const [{ fetching }, addCaptain] = useAddHouseCaptainMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async ({ studentId }: Omit<AddHouseCaptainMutationVariables, "houseId">) => {
		const { error } = await addCaptain({ houseId, studentId })

		if (error) {
			return toast({
				description: error.message,
				status: "error",
			})
		}

		return navigate(`/houses/${houseId}`, { replace: true })
	}

	const formik = useFormik<Omit<AddHouseCaptainMutationVariables, "houseId">>({
		initialValues: { studentId: "" },
		validationSchema,
		onSubmit: handleSubmit,
	})

	const [{ data: studentsData, fetching: fetchingStudents, error: studentsError }] = useStudentsQuery()

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			{fetchingStudents ? (
				<Center w="full" py="1">
					<VStack>
						<Spinner size="sm" color="text.400" />
						<Text fontSize="xs" color="text.400">
							Loading Students
						</Text>
					</VStack>
				</Center>
			) : studentsError ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							{studentsError.message.replace("[GraphQL] ", "")}
						</Text>
					</VStack>
				</Center>
			) : (
				<FormControl isInvalid={Boolean(formik.errors.studentId && formik.touched.studentId)}>
					<SlideFade in={Boolean(formik.values.studentId)} unmountOnExit>
						<FormLabel htmlFor="studentId" fontSize="sm">
							Captain
						</FormLabel>
					</SlideFade>
					<Select
						placeholder="Select Student"
						border="none"
						_focus={{
							border: "none",
						}}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						autoComplete="off"
						{...formik.getFieldProps("studentId")}
					>
						{studentsData?.students.map(({ _id, name }) => (
							<option key={_id} value={_id}>
								{name}
							</option>
						))}
					</Select>
					<FormErrorMessage>{formik.errors.studentId}</FormErrorMessage>
				</FormControl>
			)}

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Add
			</Button>
		</VStack>
	)
}
