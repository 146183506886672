import { DrawerActions, DrawerState, HIDE_DRAWER, SHOW_DRAWER } from "./types"

export const initialDrawerState: DrawerState = {
	isShown: true,
}

export const drawerReducer = (state: DrawerState = initialDrawerState, action: DrawerActions): DrawerState => {
	switch (action.type) {
		case SHOW_DRAWER: {
			return {
				isShown: true,
			}
		}

		case HIDE_DRAWER: {
			return {
				isShown: false,
			}
		}

		default:
			return state
	}
}
