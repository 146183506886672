import { Icon, IconButton, useToast } from "@chakra-ui/react"
import React from "react"
import { Check } from "react-feather"
import { ExtendedPointsRedeemTransactionFragment, useApprovePointsRedeemTransactionMutation } from "../../graphql"

export type ApproveTransactionButtonProps = {
	transaction: ExtendedPointsRedeemTransactionFragment
}

export const ApproveTransactionButton: React.FC<ApproveTransactionButtonProps> = ({ transaction }) => {
	const [{ fetching }, approve] = useApprovePointsRedeemTransactionMutation()

	const toast = useToast()

	const handleApprove = async () => {
		const { error, data } = await approve({ transactionId: transaction._id })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.approvePointsRedeemTransaction) {
			return toast({
				description: "Approved successfully",
				status: "success",
			})
		}
	}

	return <IconButton aria-label="approve-btn" size="xs" icon={<Icon as={Check} />} colorScheme="green" onClick={handleApprove} isLoading={fetching} />
}
