import { Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useNavigate } from "react-router-dom"
import { ExtendedHouseFragment, useRemoveHouseMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type RemoveHouseButtonProps = {
	house: ExtendedHouseFragment
}

export const RemoveHouseButton: React.FC<RemoveHouseButtonProps> = ({ house }) => {
	const [{ fetching }, removeHouse] = useRemoveHouseMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	const handleRemoveHouse = async () => {
		const { error } = await removeHouse({ houseId: house._id })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		onClose()
		return navigate("/houses", { replace: true })
	}

	return (
		<>
			<Tooltip label={`Remove house ${house.name}`} fontSize="sm">
				<IconButton aria-label="remove-house-btn" variant="ghost" colorScheme="blackAlpha" size="sm" {...getButtonProps()}>
					<Icon as={(props: any) => <FontAwesomeIcon icon={faTrash} {...props} />} />
				</IconButton>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Remove House"
				message="Removing this house may affect students associated with this house."
				confirmLabel="Remove"
				onConfirm={handleRemoveHouse}
				isLoading={fetching}
			/>
		</>
	)
}
