import {
	Center,
	Flex,
	Heading,
	HStack,
	Icon,
	IconButton,
	LinkBox,
	LinkOverlay,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	VStack,
} from "@chakra-ui/react"
import { faAdd, faCircleInfo, faPencil } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { NavLink } from "react-router-dom"
import { ExtendedRewardCategory } from "../../graphql"

export type RewardCategoriesListProps = {
	rewardCategories: ExtendedRewardCategory[]
	prevPage: () => void
	nextPage: () => void
	hasPrevPage: boolean
	hasNextPage: boolean
	currentPage: number
	totalPages: number
}

export const RewardCategoriesList: React.FC<RewardCategoriesListProps> = ({ rewardCategories, nextPage, hasNextPage }) => {
	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport && hasNextPage) nextPage()
	}, [inViewport, nextPage, hasNextPage])

	return (
		<VStack w="full" align="stretch" spacing={8}>
			{rewardCategories.map((category) => (
				<VStack key={category._id} w="full" h="full" align="stretch">
					<HStack position="relative" maxW="sm" justify="space-between">
						<Heading fontSize="md" color="primary.500">
							{category.name}
						</Heading>
						<HStack spacing={4}>
							<IconButton
								aria-label="description"
								variant="ghost"
								colorScheme="purple"
								as={NavLink}
								to={`/points/reward/categories/${category._id}/edit`}
								size="2xs"
								color="blackAlpha.700"
							>
								<FontAwesomeIcon icon={faPencil} />
							</IconButton>
							<Popover>
								<PopoverTrigger>
									<IconButton
										aria-label="description"
										variant="ghost"
										size="2xs"
										color="blackAlpha.700"
										as={(props: any) => <FontAwesomeIcon icon={faCircleInfo} {...props} />}
									/>
								</PopoverTrigger>
								<PopoverContent bgColor="white">
									<PopoverBody>
										<Text textAlign="center" fontSize="sm">
											{category.description}
										</Text>
									</PopoverBody>
								</PopoverContent>
							</Popover>
						</HStack>
					</HStack>

					<Flex h="full" overflowX="auto" overflowY="hidden" py="4">
						{category.items?.map((item) => (
							<Center
								as={LinkBox}
								h="full"
								key={item._id}
								position="relative"
								m={1}
								bg="white.500"
								rounded="2xl"
								px="8"
								py="6"
								maxW="xs"
								cursor="pointer"
							>
								<Popover>
									<PopoverTrigger>
										<IconButton
											zIndex={10}
											pos="absolute"
											top="2"
											right="2"
											aria-label="description"
											size="2xs"
											color="blackAlpha.700"
											as={(props: any) => <FontAwesomeIcon icon={faCircleInfo} {...props} />}
										/>
									</PopoverTrigger>
									<PopoverContent bgColor="white">
										<PopoverBody>
											<Text textAlign="center" fontSize="sm">
												{item.description}
											</Text>
										</PopoverBody>
									</PopoverContent>
								</Popover>

								<LinkOverlay as={NavLink} to={`/points/reward/categories/${category._id}/items/${item._id}/edit`} />
								<VStack spacing={0}>
									<Text fontSize="2xl" color="purple.500" fontWeight="bold">
										+{item.points}
									</Text>
									<Heading fontSize="sm" color="blackAlpha.700">
										{item.name}
									</Heading>
								</VStack>
							</Center>
						))}
						<Center as={LinkBox} h="full" position="relative" m={1} bg="primary.100" rounded="2xl" px="8" py="6" w="48" cursor="pointer">
							<LinkOverlay as={NavLink} to={`/points/reward/categories/${category._id}/items/new`} />
							<VStack spacing={0}>
								<Icon fontSize="xl" color="primary.500" as={(props: any) => <FontAwesomeIcon icon={faAdd} {...props} />} />{" "}
								<Heading fontSize="sm" color="primary.500">
									Add New Item
								</Heading>
							</VStack>
						</Center>
					</Flex>

					{hasNextPage && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
				</VStack>
			))}
		</VStack>
	)
}
