import { Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import React from "react"
import { X } from "react-feather"
import { useRemoveUnauthorizedClassMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type RemoveUnauthorizedClassButtonProps = {
	restrictedZoneId: string
	classId: string
	classSectionId: string
}

export const RemoveUnauthorizedClassButton: React.FC<RemoveUnauthorizedClassButtonProps> = ({ restrictedZoneId, classId, classSectionId }) => {
	const [{ fetching }, removeClass] = useRemoveUnauthorizedClassMutation()

	const toast = useToast()

	const handleRemoveClass = async () => {
		const { error } = await removeClass({ restrictedZoneId, classId, classSectionId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		onClose()
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<Tooltip label="Remove unauthorized class" fontSize="sm">
				<IconButton aria-label="remove-class" position="absolute" top="-1" right="-1" colorScheme="red" size="xs" zIndex={1} {...getButtonProps()}>
					<Icon as={X} />
				</IconButton>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Remove Class"
				message="Are you sure you want to remove this class and section from unauthorized classes?"
				confirmLabel="Remove"
				onConfirm={handleRemoveClass}
				isLoading={fetching}
			/>
		</>
	)
}
