import {
	Button,
	Center,
	Checkbox,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	HStack,
	Icon,
	Input,
	Select,
	SlideFade,
	Spinner,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import DatePicker from "react-date-picker"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import toWords from "split-camelcase-to-words"
import * as Yup from "yup"
import { AdmissionTypes, CreateStudentMutationVariables, useBusRoutesQuery, useClassesQuery, useCreateStudentMutation, useHousesQuery } from "../../graphql"

const validationSchema = Yup.object({
	name: Yup.string().min(3).required().label("Name"),
	email: Yup.string().email().nullable().label("Email"),
	phone: Yup.string().length(10).nullable().label("Phone"),
	admissionId: Yup.string().required().label("Admission ID"),
	classId: Yup.string().required().label("Class"),
	classSectionId: Yup.string().required().label("Section"),
	tagHexOrDecId: Yup.string().min(8).nullable().label("Tag ID"),
})
export const NewStudentForm: React.FC = () => {
	const [{ fetching }, createNew] = useCreateStudentMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (values: CreateStudentMutationVariables["input"], helpers: FormikHelpers<CreateStudentMutationVariables["input"]>) => {
		if (values.transportMode != "School Bus") values.busRouteId = null
		const { error, data } = await createNew({ input: { ...values } })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		if (data?.createStudent.errors) {
			return data?.createStudent.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		if (!data?.createStudent.student) {
			return toast({
				description: "Could not create the student",
				status: "error",
			})
		}

		return navigate("/students/" + data.createStudent.student._id)
	}

	const formik = useFormik<CreateStudentMutationVariables["input"]>({
		initialValues: {
			name: "",
			admissionId: "",
			email: "",
			phone: "",
			classId: "",
			classSectionId: "",
			tagHexOrDecId: "",
			isALS: false,
			isELS: false,
			transportMode: "",
			busRouteId: "",
		} as any,
		validationSchema,
		onSubmit: handleSubmit,
	})

	const [{ data: classesData, fetching: fetchingClasses, error: classesError }] = useClassesQuery()
	const [{ data: housesData, fetching: fetchingHouses, error: housesError }] = useHousesQuery()
	const [{ data: busRoutesData, fetching: fetchingBusRoutes, error: busRoutesError }] = useBusRoutesQuery()

	return (
		<VStack w="full" maxW="md" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.name && formik.touched.name)} isRequired>
				<SlideFade in={Boolean(formik.values.name)} unmountOnExit>
					<FormLabel htmlFor="name" fontSize="sm">
						Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("name")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.name}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.email && formik.touched.email)}>
				<SlideFade in={Boolean(formik.values.email)} unmountOnExit>
					<FormLabel htmlFor="email" fontSize="sm">
						Email
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Email"
					type="email"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("email")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.email}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.phone && formik.touched.phone)}>
				<SlideFade in={Boolean(formik.values.phone)} unmountOnExit>
					<FormLabel htmlFor="phone" fontSize="sm">
						Phone
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Phone"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("phone")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.phone}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.admissionId && formik.touched.admissionId)} isRequired>
				<SlideFade in={Boolean(formik.values.admissionId)} unmountOnExit>
					<FormLabel htmlFor="admissionId" fontSize="sm">
						Admission ID
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Admission ID"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("admissionId")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.admissionId}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.admissionDate && formik.touched.admissionDate)}>
				<FormLabel htmlFor="admissionDate" fontSize="sm">
					Admission Date
				</FormLabel>
				<DatePicker
					{...formik.getFieldProps("admissionDate")}
					value={formik.values.admissionDate}
					onChange={(date: Date) => formik.setFieldValue("admissionDate", date)}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.admissionDate as string}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.admissionType && formik.touched.admissionType)}>
				<SlideFade in={Boolean(formik.values.admissionType)} unmountOnExit>
					<FormLabel htmlFor="admissionType" fontSize="sm">
						Admission Type
					</FormLabel>
				</SlideFade>
				<Select
					placeholder="Admission Type"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("admissionType")}
				>
					{Object.values(AdmissionTypes).map((type) => (
						<option key={type} value={type}>
							{toWords(type)}
						</option>
					))}
				</Select>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.admissionType}</Text>
				</FormErrorMessage>
			</FormControl>

			<HStack spacing={12}>
				<FormControl isInvalid={Boolean(formik.errors.isALS && formik.touched.isALS)}>
					<Checkbox borderColor="purple.300" colorScheme="purple" isChecked={formik.values.isALS || false} {...formik.getFieldProps("isALS")}>
						ALS
					</Checkbox>
					<FormErrorMessage>
						<FormErrorIcon />
						<Text>{formik.errors.isALS}</Text>
					</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.errors.isELS && formik.touched.isELS)}>
					<Checkbox borderColor="purple.300" colorScheme="purple" isChecked={formik.values.isELS || false} {...formik.getFieldProps("isELS")}>
						ELS
					</Checkbox>
					<FormErrorMessage>
						<FormErrorIcon />
						<Text>{formik.errors.isELS}</Text>
					</FormErrorMessage>
				</FormControl>
			</HStack>

			<FormControl isInvalid={Boolean(formik.errors.nationality && formik.touched.nationality)}>
				<SlideFade in={Boolean(formik.values.nationality)} unmountOnExit>
					<FormLabel htmlFor="nationality" fontSize="sm">
						Nationality
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Nationality"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("nationality")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.nationality}</Text>
				</FormErrorMessage>
			</FormControl>

			{fetchingClasses ? (
				<Center w="full" py="1">
					<VStack>
						<Spinner size="sm" color="text.400" />
						<Text fontSize="xs" color="text.400">
							Loading classes
						</Text>
					</VStack>
				</Center>
			) : classesError ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							{classesError.message.replace("[GraphQL] ", "")}
						</Text>
					</VStack>
				</Center>
			) : (
				<HStack w="full" spacing={0} align="flex-end">
					<FormControl isInvalid={Boolean(formik.errors.classId && formik.touched.classId)} isRequired>
						<SlideFade in={Boolean(formik.values.classId)} unmountOnExit>
							<FormLabel htmlFor="classId" fontSize="sm">
								Class
							</FormLabel>
						</SlideFade>
						<Select
							placeholder="Select Class"
							border="none"
							_focus={{
								border: "none",
								borderRight: ".5px solid",
								borderRightColor: "blackAlpha.200",
							}}
							borderRight=".5px solid"
							borderRightColor="blackAlpha.200"
							bg="white.500"
							roundedLeft="xl"
							roundedRight={formik.values.classId ? "none" : "xl"}
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("classId")}
						>
							{classesData?.classes.map(({ _id, name }) => (
								<option key={_id} value={_id}>
									{name}
								</option>
							))}
						</Select>
						<FormErrorMessage>{formik.errors.classId}</FormErrorMessage>
					</FormControl>

					<FormControl
						isInvalid={Boolean(formik.errors.classSectionId && formik.touched.classSectionId)}
						display={formik.values.classId ? "block" : "none"}
						isRequired
					>
						<SlideFade in={Boolean(formik.values.classSectionId)} unmountOnExit>
							<FormLabel htmlFor="classSectionId" fontSize="sm">
								Section
							</FormLabel>
						</SlideFade>
						<Select
							placeholder="Select Section"
							border="none"
							_focus={{
								border: "none",
								borderLeft: ".5px solid",
								borderLeftColor: "blackAlpha.200",
							}}
							borderLeft=".5px solid"
							borderLeftColor="blackAlpha.200"
							bg="white.500"
							roundedRight="xl"
							roundedLeft="none"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("classSectionId")}
						>
							{classesData?.classes
								?.find((_class) => _class._id === formik.values.classId)
								?.sections?.map((section) => (
									<option key={section.name} value={section._id}>
										{section.name}
									</option>
								))}
						</Select>
						<FormErrorMessage>{formik.errors.classSectionId}</FormErrorMessage>
					</FormControl>
				</HStack>
			)}

			{fetchingHouses ? (
				<Center w="full" py="1">
					<VStack>
						<Spinner size="sm" color="text.400" />
						<Text fontSize="xs" color="text.400">
							Loading houses
						</Text>
					</VStack>
				</Center>
			) : housesError ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							{housesError.message.replace("[GraphQL] ", "")}
						</Text>
					</VStack>
				</Center>
			) : !housesData?.houses ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							Could not load houses
						</Text>
					</VStack>
				</Center>
			) : (
				<FormControl isInvalid={Boolean(formik.errors.houseId && formik.touched.houseId)} isRequired>
					<SlideFade in={Boolean(formik.values.houseId)} unmountOnExit>
						<FormLabel htmlFor="houseId" fontSize="sm">
							House
						</FormLabel>
					</SlideFade>
					<Select
						w="full"
						bg="white.500"
						rounded="xl"
						border="none"
						_focus={{ border: "none" }}
						placeholder="Select House"
						{...formik.getFieldProps("houseId")}
					>
						{housesData.houses.map((house) => (
							<option key={house._id} value={house._id}>
								{house.name}
							</option>
						))}
					</Select>
					<FormErrorMessage>{formik.errors.houseId}</FormErrorMessage>
				</FormControl>
			)}

			<FormControl isInvalid={Boolean(formik.errors.tagHexOrDecId && formik.touched.tagHexOrDecId)}>
				<SlideFade in={Boolean(formik.values.tagHexOrDecId)} unmountOnExit>
					<FormLabel htmlFor="tagHexOrDecId" fontSize="sm">
						Student Tag ID
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Student's Tag ID"
					border="none"
					_focus={{ border: "none" }}
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("tagHexOrDecId")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.tagHexOrDecId}</Text>
				</FormErrorMessage>
			</FormControl>

			<HStack w="full" spacing={0} align="flex-end">
				<FormControl isInvalid={Boolean(formik.errors.transportMode && formik.touched.transportMode)}>
					<SlideFade in={Boolean(formik.values.transportMode)} unmountOnExit>
						<FormLabel htmlFor="transportMode" fontSize="sm">
							Transport Mode
						</FormLabel>
					</SlideFade>
					<Select
						placeholder="Select Transport Mode"
						border="none"
						_focus={{
							border: "none",
							borderRight: ".5px solid",
							borderRightColor: "blackAlpha.200",
						}}
						borderRight=".5px solid"
						borderRightColor="blackAlpha.200"
						bg="white.500"
						roundedLeft="xl"
						roundedRight={formik.values.transportMode == "School Bus" ? "none" : "xl"}
						py="1"
						autoComplete="off"
						{...formik.getFieldProps("transportMode")}
					>
						<option>School Bus</option>
						<option>Private</option>
					</Select>
					<FormErrorMessage>{formik.errors.transportMode}</FormErrorMessage>
				</FormControl>

				{fetchingBusRoutes ? (
					<Center w="full" py="1">
						<VStack>
							<Spinner size="sm" color="text.400" />
							<Text fontSize="xs" color="text.400">
								Loading bus routes
							</Text>
						</VStack>
					</Center>
				) : busRoutesError ? (
					<Center w="full" py="1">
						<VStack>
							<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
							<Text fontSize="xs" color="text.400">
								{busRoutesError.message.replace("[GraphQL] ", "")}
							</Text>
						</VStack>
					</Center>
				) : (
					<FormControl
						isInvalid={Boolean(formik.errors.busRouteId && formik.touched.busRouteId)}
						display={formik.values.transportMode == "School Bus" ? "block" : "none"}
					>
						<SlideFade in={Boolean(formik.values.busRouteId)} unmountOnExit>
							<FormLabel htmlFor="busRouteId" fontSize="sm">
								Bus Route
							</FormLabel>
						</SlideFade>
						<Select
							placeholder="Select Bus Route"
							border="none"
							_focus={{
								border: "none",
								borderLeft: ".5px solid",
								borderLeftColor: "blackAlpha.200",
							}}
							borderLeft=".5px solid"
							borderLeftColor="blackAlpha.200"
							bg="white.500"
							roundedRight="xl"
							roundedLeft="none"
							py="1"
							autoComplete="off"
							{...formik.getFieldProps("busRouteId")}
						>
							{busRoutesData?.busRoutes.map((route) => (
								<option key={route.routeName} value={route._id}>
									{route.routeName}
								</option>
							))}
						</Select>
						<FormErrorMessage>{formik.errors.busRouteId}</FormErrorMessage>
					</FormControl>
				)}
			</HStack>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
