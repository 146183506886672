import { Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { SchoolTimingFragment, useRemoveSchoolTimingsOfClassSectionMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type RemoveSchoolTimingButtonProps = {
	classSectionId: string
	schoolTiming: SchoolTimingFragment
}

export const RemoveSchoolTimingButton: React.FC<RemoveSchoolTimingButtonProps> = ({ classSectionId, schoolTiming }) => {
	const [{ fetching }, removeSchoolTiming] = useRemoveSchoolTimingsOfClassSectionMutation()

	const toast = useToast()

	const handleRemoveSchoolTiming = async () => {
		const { error } = await removeSchoolTiming({
			classSectionId,
		})

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		return onClose()
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<Tooltip label={`Remove ${schoolTiming.name} school timing`} fontSize="xs">
				<IconButton aria-label="remove-school-timing-btn" variant="ghost" colorScheme="blackAlpha" size="xs" {...getButtonProps()} isLoading={fetching}>
					<Icon as={(props: any) => <FontAwesomeIcon icon={faTrash} {...props} />} />
				</IconButton>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Remove School Timing"
				message="This will remove school timings of this class section which may affect marking student attendance."
				confirmLabel="Remove"
				onConfirm={handleRemoveSchoolTiming}
				isLoading={fetching}
			/>
		</>
	)
}
