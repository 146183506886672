import { Button, Center, Heading, HStack, Icon, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { AllSchoolTimingsQueryVariables, useAllSchoolTimingsQuery } from "../../graphql"
import { SchoolTimingsList } from "./SchoolTimingsList"

export const AllSchoolTimings: React.FC = () => {
	const [pagination, setPagination] = useState<AllSchoolTimingsQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useAllSchoolTimingsQuery({
		variables: { pagination },
	})

	const prevPage = () => {
		if (data?.allSchoolTimings.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.allSchoolTimings.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const btnSize = isMobile ? "sm" : "md"

	const navigate = useNavigate()

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" justify="space-between">
				<Heading as="h2" fontSize={isMobile ? "sm" : "md"} fontWeight="normal" color="text.500">
					Showing {data?.allSchoolTimings.total || "all"} school timings of your school
				</Heading>
				<Button
					colorScheme="whiteAlpha"
					color="text.500"
					size={btnSize}
					rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faAdd} {...props} />} />}
					onClick={() => navigate("/schoolTimings/new")}
				>
					Create New
				</Button>
			</HStack>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.allSchoolTimings.schoolTimings && data?.allSchoolTimings.schoolTimings.length > 0 ? (
				<SchoolTimingsList
					schoolTimings={data.allSchoolTimings.schoolTimings}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.allSchoolTimings.hasPrevPage}
					hasNextPage={data.allSchoolTimings.hasNextPage}
					currentPage={data.allSchoolTimings.currentPage}
					totalPages={data.allSchoolTimings.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any school timing.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
