import {
	AspectRatio,
	AspectRatioProps,
	Box,
	Button,
	ButtonGroup,
	Center,
	Container,
	Grid,
	Heading,
	HStack,
	Icon,
	IconButton,
	LinkBox,
	LinkOverlay,
	Spinner,
	Text,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react"
import { faArrowLeft, faArrowRight, faPencil } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion, MotionProps } from "framer-motion"
import React from "react"
import { ArrowRight } from "react-feather"
import { Card, DashboardHeader, Image, Nav, ReadersHealth, SubscribeAsSchoolPopover } from "../components"
import { UploadAvatar, UploadCover } from "../components/upload"
import { GRADIENT } from "../constants"
import { useMeQuery, useStatisticsQuery } from "../graphql"
import { useDrawer } from "../hooks"

const MotionAspectRatio = motion<AspectRatioProps & MotionProps>(AspectRatio)

export const Dashboard: React.FC = () => {
	const { isShown, show, hide } = useDrawer()

	const [{ data, fetching }] = useMeQuery()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [{ data: statisticsData, fetching: fetchingStatistics, error: statisticsError }] = useStatisticsQuery()

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0" overflow="hidden">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					py={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					{fetching ? (
						<Center w="full" py="16">
							<VStack>
								<Spinner size="sm" color="text.400" />
								<Text fontSize="xs" color="text.400">
									Loading dashboard
								</Text>
							</VStack>
						</Center>
					) : data?.me ? (
						<>
							{isMobile ? (
								<DashboardHeader />
							) : (
								<ButtonGroup p="4" pos="absolute" top="2px" left="2px" zIndex={99}>
									<IconButton aria-label="hide-drawer-btn" onClick={hide} isDisabled={!(!isMobile && isShown)} colorScheme="white">
										<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowLeft} {...props} />} color="text.500" />
									</IconButton>
									<IconButton aria-label="show-drawer-btn" onClick={show} isDisabled={!isMobile && isShown} colorScheme="white">
										<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} color="text.500" />
									</IconButton>
								</ButtonGroup>
							)}
							<UploadCover style={{ width: "100%", marginTop: 0, position: "relative" }}>
								<Box mt={isMobile ? "12px !important" : "-16px !important"} w="full" position="relative">
									<AspectRatio
										bg="blackAlpha.300"
										roundedTopLeft={!isMobile && isShown ? "3xl" : "none"}
										ratio={isMobile ? 3 / 1 : 5 / 1}
										transition="all 200ms ease-in-out"
										w="full"
										cursor="pointer"
										zIndex={1}
									>
										<Image
											roundedTopLeft={!isMobile && isShown ? "3xl" : "none"}
											src={data.me.cover?.url}
											alt="cover"
											style={{ objectFit: "cover" }}
										/>
									</AspectRatio>
									<Center pos="absolute" bottom="2" right="2" boxShadow="dark-lg" w="fit-content" rounded="xl" p="2" bg="white" zIndex={1}>
										<Icon as={(props: any) => <FontAwesomeIcon {...props} icon={faPencil} />} />
									</Center>
								</Box>
							</UploadCover>

							<HStack
								pos="relative"
								w="full"
								align={isMobile ? "center" : "flex-end"}
								px={isMobile ? 2 : 4}
								pt="4"
								spacing={4}
								mt={{
									base: "0 !important",
									lg: "-60px !important",
								}}
							>
								<UploadAvatar>
									<Box w="full" position="relative">
										<MotionAspectRatio
											ratio={1}
											w="full"
											maxW={{ base: 32, md: 36, xl: 48 }}
											rounded="2xl"
											shadow="2xl"
											whileHover={{ scale: 1.04 }}
											cursor="pointer"
											bgColor="whiteAlpha.500"
											zIndex={2}
										>
											{data?.me?.picture ? (
												<Image src={data.me.picture.url} alt="picture" rounded="2xl" style={{ objectFit: "contain" }} />
											) : (
												<></>
											)}
										</MotionAspectRatio>

										<Center
											pos="absolute"
											bottom="-2"
											right="-2"
											boxShadow="dark-lg"
											w="fit-content"
											rounded="xl"
											p="2"
											bg="white"
											zIndex={2}
										>
											<Icon as={(props: any) => <FontAwesomeIcon {...props} icon={faPencil} />} />
										</Center>
									</Box>
								</UploadAvatar>
								<VStack align="flex-start" pb="2">
									<Heading as="h1" fontSize={{ base: "lg", md: "xl" }} color="text.500">
										{data?.me?.name}
									</Heading>
									<Heading as="h2" fontSize={{ base: "12", md: "xs" }} fontWeight="normal" color="text.400" maxW="xs">
										{data?.me &&
											`${data?.me?.location.city}, ${data?.me?.location.state}, ${data?.me?.location.address} - ${data?.me?.location.pin}`}
									</Heading>
									<SubscribeAsSchoolPopover />
								</VStack>
							</HStack>

							<VStack w="full" align="flex-start" px="4" spacing={4}>
								<VStack w="full" maxW="xl" align="flex-start" px="4" spacing={4}>
									<Heading as="h2" fontSize="md" fontWeight="semibold" color="text.500">
										Statistics
									</Heading>
									{statisticsError ? (
										<Center py="4">
											<Text fontSize="md" fontWeight="semibold" color="text.400">
												{statisticsError.message.replace("[GraphQL ]", "")}
											</Text>
										</Center>
									) : (
										<Grid
											templateColumns={{
												base: "repeat(2, 1fr)",
												md: isShown ? "repeat(2, 1fr)" : "repeat(4, 1fr)",
												lg: "repeat(4, 1fr)",
												xl: "repeat(5, 1fr)",
											}}
											gap="4"
										>
											<LinkBox>
												<Card>
													<LinkOverlay href="/classes">
														<VStack align="flex-start" spacing="6">
															<VStack align="flex-start" spacing="0">
																{fetchingStatistics ? (
																	<Center py="2">
																		<Spinner size="md" />
																	</Center>
																) : (
																	<Text fontSize="3xl" fontWeight="semibold">
																		{statisticsData?.statistics.classes}
																	</Text>
																)}
																<Text fontSize="xs" color="text.400">
																	Classes
																</Text>
															</VStack>
															<Button colorScheme="primary" size="xs" variant="link" rightIcon={<Icon as={ArrowRight} />}>
																See details
															</Button>
														</VStack>
													</LinkOverlay>
												</Card>
											</LinkBox>

											<LinkBox>
												<Card>
													<LinkOverlay href="/subjects">
														<VStack align="flex-start" spacing="6">
															<VStack align="flex-start" spacing="0">
																{fetchingStatistics ? (
																	<Center py="2">
																		<Spinner size="md" />
																	</Center>
																) : (
																	<Text fontSize="3xl" fontWeight="semibold">
																		{statisticsData?.statistics.subjects}
																	</Text>
																)}
																<Text fontSize="xs" color="text.400">
																	Subjects
																</Text>
															</VStack>
															<Button colorScheme="primary" size="xs" variant="link" rightIcon={<Icon as={ArrowRight} />}>
																See details
															</Button>
														</VStack>
													</LinkOverlay>
												</Card>
											</LinkBox>

											<LinkBox>
												<Card>
													<LinkOverlay href="/staff">
														<VStack align="flex-start" spacing="6">
															<VStack align="flex-start" spacing="0">
																{fetchingStatistics ? (
																	<Center py="2">
																		<Spinner size="md" />
																	</Center>
																) : (
																	<Text fontSize="3xl" fontWeight="semibold">
																		{statisticsData?.statistics.teachingStaff}
																	</Text>
																)}
																<Text fontSize="xs" color="text.400">
																	Teaching Staff
																</Text>
															</VStack>
															<Button colorScheme="primary" size="xs" variant="link" rightIcon={<Icon as={ArrowRight} />}>
																See details
															</Button>
														</VStack>
													</LinkOverlay>
												</Card>
											</LinkBox>

											<LinkBox>
												<Card>
													<LinkOverlay href="/staff">
														<VStack align="flex-start" spacing="6">
															<VStack align="flex-start" spacing="0">
																{fetchingStatistics ? (
																	<Center py="2">
																		<Spinner size="md" />
																	</Center>
																) : (
																	<Text fontSize="3xl" fontWeight="semibold">
																		{statisticsData?.statistics.nonTeachingStaff}
																	</Text>
																)}
																<Text fontSize="xs" color="text.400">
																	Non-Teaching Staff
																</Text>
															</VStack>
															<Button colorScheme="primary" size="xs" variant="link" rightIcon={<Icon as={ArrowRight} />}>
																See details
															</Button>
														</VStack>
													</LinkOverlay>
												</Card>
											</LinkBox>

											<LinkBox>
												<Card>
													<LinkOverlay href="/students">
														<VStack align="flex-start" spacing="6">
															<VStack align="flex-start" spacing="0">
																{fetchingStatistics ? (
																	<Center py="2">
																		<Spinner size="md" />
																	</Center>
																) : (
																	<Text fontSize="3xl" fontWeight="semibold">
																		{statisticsData?.statistics.students}
																	</Text>
																)}
																<Text fontSize="xs" color="text.400">
																	Students
																</Text>
															</VStack>
															<Button colorScheme="primary" size="xs" variant="link" rightIcon={<Icon as={ArrowRight} />}>
																See details
															</Button>
														</VStack>
													</LinkOverlay>
												</Card>
											</LinkBox>
										</Grid>
									)}
								</VStack>
								<ReadersHealth />
							</VStack>
						</>
					) : (
						<Center w="full" py="16">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Could not load your dashboard
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
