import { ListItem, VStack, HStack, Icon, AspectRatio, Heading, Text } from "@chakra-ui/react"
import { faCheckCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { NavLink } from "react-router-dom"
import { WorkshopSessionFragment } from "../../graphql"
import { Image } from "../common"

export type SessionListItemProps = {
	session: WorkshopSessionFragment
	index: number
}

export const SessionListItem: React.FC<SessionListItemProps> = ({ session, index }) => {
	const [isHovered, setIsHovered] = React.useState(false)

	return (
		<ListItem
			w="full"
			key={session._id}
			as={NavLink}
			to={`sessions/${session._id}`}
			onMouseOver={() => setIsHovered(true)}
			onMouseOut={() => setIsHovered(false)}
		>
			<VStack
				w="full"
				align="flex-start"
				py="6"
				px="2"
				_hover={{ bgColor: "purple.100" }}
				transition="background-color 200ms ease-in-out"
				pt={index === 0 ? 10 : 4}
			>
				{session.isActive ? (
					<HStack pl="10">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCheckCircle} {...props} />} color="green.400" fontSize="sm" />
						<Text fontSize="sm">Active</Text>
					</HStack>
				) : (
					<HStack pl="10">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faXmarkCircle} {...props} />} color="red.400" fontSize="sm" />
						<Text fontSize="sm">Inactive</Text>
					</HStack>
				)}

				<HStack w="full" align="flex-start" transition="padding-bottom 200ms ease-in-out">
					<AspectRatio
						w="full"
						maxW="8"
						ratio={1}
						bgColor="purple.100"
						rounded="xl"
						mt={isHovered ? "-2.2rem" : ""}
						transition="margin-top 200ms ease-in-out"
					>
						<Text fontSize="xs" fontWeight="bold">
							{index + 1}
						</Text>
					</AspectRatio>

					<AspectRatio
						w="full"
						maxW="36"
						ratio={16 / 9}
						transform={isHovered ? "scale(1.25) translate(-1rem, .6rem)" : ""}
						transition="all 200ms ease-in-out"
					>
						<Image src={session.thumbnail?.url || `https://picsum.photos/${index * 100}`} rounded="lg" />
					</AspectRatio>
					<VStack w="full" align="stretch" spacing={0}>
						<Heading fontSize={isHovered ? "md" : "sm"} transition="font-size 200ms ease-in-out">
							{session.title}
						</Heading>
						<Text
							fontSize="xs"
							lineHeight="110%"
							style={{ display: "-webkit-box", WebkitLineClamp: isHovered ? 6 : 4, WebkitBoxOrient: "vertical", overflow: "hidden" }}
						>
							{session.description}
						</Text>
					</VStack>
				</HStack>
			</VStack>
		</ListItem>
	)
}
