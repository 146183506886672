/* eslint-disable @typescript-eslint/indent */
import { Alert, Avatar, ButtonGroup, Flex, Heading, HStack, LinkBox, LinkOverlay, Tag, Text, VStack } from "@chakra-ui/react"
import { format } from "date-fns"
import formatDistanceToNow from "date-fns/formatDistanceToNow"
import React, { useCallback, useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { NavLink } from "react-router-dom"
import { AuthorTypes, ExtendedPointsRedeemTransactionFragment } from "../../graphql"
import { ApproveTransactionButton } from "./ApproveTransactionButton"
import { RejectTransactionButton } from "./RejectTransactionButton"

export type PointsRedeemTransactionsGridProps = {
	transactions: ExtendedPointsRedeemTransactionFragment[]
	prevPage: () => void
	nextPage: () => void
	hasPrevPage: boolean
	hasNextPage: boolean
	currentPage: number
	totalPages: number
}

export const PointsRedeemTransactionsGrid: React.FC<PointsRedeemTransactionsGridProps> = ({ transactions, nextPage, hasNextPage }) => {
	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport && hasNextPage) nextPage()
	}, [inViewport, nextPage, hasNextPage])

	const getAuthor = useCallback(
		(transaction: ExtendedPointsRedeemTransactionFragment) => ({
			title:
				transaction.author.authorType === AuthorTypes.Student
					? `${transaction.author.student?.name}`
					: transaction.author.authorType === AuthorTypes.School
					? `${transaction.author.school?.name}`
					: transaction.author.authorType === AuthorTypes.Staff
					? `${transaction.author.staff?.name}`
					: "",

			picture:
				transaction.author.authorType === AuthorTypes.Student
					? transaction.author.student?.picture?.variants?.thumbnail || transaction.author.student?.picture?.url || ""
					: transaction.author.authorType === AuthorTypes.School
					? transaction.author.school?.picture?.variants?.thumbnail || transaction.author.school?.picture?.url || ""
					: transaction.author.authorType === AuthorTypes.Staff
					? transaction.author.staff?.picture?.variants?.thumbnail || transaction.author.staff?.picture?.url || ""
					: "",
			link:
				transaction.author.authorType === AuthorTypes.Student && transaction.author.student
					? `/students/${transaction.author.student._id}`
					: transaction.author.authorType === AuthorTypes.School
					? "/dashboard"
					: transaction.author.authorType === AuthorTypes.Staff
					? `/staff/${transaction.author.staffId}`
					: "",
		}),
		[transactions]
	)

	return (
		<Flex w="full" h="full" flexWrap="wrap" overflow="auto">
			{transactions.map((transaction) => (
				<VStack
					h="max"
					key={transaction._id}
					align="flex-start"
					position="relative"
					m={1}
					bg="white.500"
					rounded="2xl"
					px="8"
					py="6"
					maxW="xs"
					cursor="pointer"
				>
					<HStack w="full" justify="space-between">
						{transaction.isRejected && transaction.rejectedAt ? (
							<Tag colorScheme="red" size="sm">
								Rejected {formatDistanceToNow(new Date(transaction.rejectedAt), { addSuffix: true })}
							</Tag>
						) : transaction.isApproved && transaction.approvedAt ? (
							<Tag colorScheme="green" size="sm">
								Approved {formatDistanceToNow(new Date(transaction.approvedAt), { addSuffix: true })}
							</Tag>
						) : (
							<Tag colorScheme="orange" size="sm">
								Pending
							</Tag>
						)}
						<ButtonGroup>
							{!transaction.isApproved && <ApproveTransactionButton transaction={transaction} />}
							{!transaction.isRejected && <RejectTransactionButton transaction={transaction} />}
						</ButtonGroup>
					</HStack>
					<HStack w="full" flex={1} as={LinkBox} spacing={0}>
						<LinkOverlay as={NavLink} to={getAuthor(transaction).link} />
						<Avatar src={getAuthor(transaction).picture} name={getAuthor(transaction).title} size="sm" />
						<VStack w="full" align="flex-start" spacing={0} pl={2}>
							<Heading fontSize="sm" fontWeight="semibold">
								{getAuthor(transaction).title}
							</Heading>

							<Text fontSize="xs" color="blackAlpha.700">
								{format(new Date(transaction.createdAt), "dd/MM/yyyy p")}
							</Text>
						</VStack>
					</HStack>

					<VStack w="full" spacing={0}>
						<Text fontSize="2xl" color="purple.500" fontWeight="bold">
							{transaction.pointsRedeemItem.points}
						</Text>
						<Heading fontSize="sm" color="blackAlpha.700">
							{transaction.pointsRedeemItem.name}
						</Heading>
					</VStack>

					{transaction.isRejected && transaction.remarks && (
						<Alert status="error" rounded="lg">
							<VStack w="full" align="flex-start" spacing={0}>
								<Text fontSize="xs" fontWeight="bold" color="red.800">
									Points have been reset
								</Text>
								<Text fontSize="xs">{transaction.remarks}</Text>
							</VStack>
						</Alert>
					)}
				</VStack>
			))}

			{hasNextPage && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
		</Flex>
	)
}
