import { Modal, ModalBody, ModalContent, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import React from "react"
import { StudentPenaltiesList } from "./StudentPenaltiesList"
import { StudentRewardsList } from "./StudentRewardsList"

export type StudentPointsModalProps = {
	isOpen: boolean
	onClose: () => void
	studentId: string
}

export const StudentPointsModal: React.FC<StudentPointsModalProps> = ({ isOpen, onClose, studentId }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size="xl">
			<ModalOverlay>
				<ModalContent>
					<ModalBody>
						<Tabs variant="soft-rounded" colorScheme="primary">
							<TabList>
								<Tab>Rewards</Tab>
								<Tab>Penalties</Tab>
							</TabList>
							<TabPanels>
								<TabPanel>
									<StudentRewardsList studentId={studentId} />
								</TabPanel>
								<TabPanel>
									<StudentPenaltiesList studentId={studentId} />
								</TabPanel>
							</TabPanels>
						</Tabs>
					</ModalBody>
				</ModalContent>
			</ModalOverlay>
		</Modal>
	)
}
