import { Button, FormControl, FormErrorIcon, FormErrorMessage, FormLabel, Icon, Input, SlideFade, Text, useToast, VStack } from "@chakra-ui/react"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { BusRouteFragment, BusRouteInput, EditBusRouteMutationVariables, useEditBusRouteMutation } from "../../graphql"

const validationSchema = Yup.object({
	routeName: Yup.string().required().label("Bus Route Name"),
	busDriverName: Yup.string().required().label("Bus Driver Name"),
	busConductorName: Yup.string().required().label("Bus Conductor Name"),
})

export type EditBusRouteFormProps = { busRoute: BusRouteFragment }

export const EditBusRouteForm: React.FC<EditBusRouteFormProps> = ({ busRoute }) => {
	const [{ fetching }, editBusRoute] = useEditBusRouteMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (values: EditBusRouteMutationVariables["input"], helpers: FormikHelpers<EditBusRouteMutationVariables["input"]>) => {
		const { error, data } = await editBusRoute({
			busRouteId: busRoute._id,
			input: { ...values },
		})

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		if (data?.editBusRoute.errors?.length) {
			return data?.editBusRoute.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		if (!data?.editBusRoute.busRoute) {
			return toast({
				description: "Could not edit the bus route",
				status: "error",
			})
		}
		return navigate("/busRoutes/")
	}

	const formik = useFormik<EditBusRouteMutationVariables["input"]>({
		initialValues: {
			routeName: busRoute.routeName,
			vehicleNo: busRoute.vehicleNo,
			busDriverName: busRoute.busDriverName,
			busDriverPhone: busRoute.busDriverPhone,
			busConductorName: busRoute.busConductorName,
			busConductorPhone: busRoute.busConductorPhone,
		} as BusRouteInput,
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" maxW="md" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.routeName && formik.touched.routeName)}>
				<SlideFade in={Boolean(formik.values.routeName)} unmountOnExit>
					<FormLabel htmlFor="routeName" fontSize="sm">
						Route Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Route Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("routeName")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.routeName}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.vehicleNo && formik.touched.vehicleNo)}>
				<SlideFade in={Boolean(formik.values.vehicleNo)} unmountOnExit>
					<FormLabel htmlFor="vehicleNo" fontSize="sm">
						Vehicle Number
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Vehicle Number"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("vehicleNo")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.vehicleNo}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.busDriverName && formik.touched.busDriverName)}>
				<SlideFade in={Boolean(formik.values.busDriverName)} unmountOnExit>
					<FormLabel htmlFor="busDriverName" fontSize="sm">
						Bus Driver Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Bus Driver Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("busDriverName")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.busDriverName}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.busDriverPhone && formik.touched.busDriverPhone)}>
				<SlideFade in={Boolean(formik.values.busDriverPhone)} unmountOnExit>
					<FormLabel htmlFor="busDriverPhone" fontSize="sm">
						Bus Driver Phone
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Bus Driver Phone"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("busDriverPhone")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.busDriverPhone}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.busConductorName && formik.touched.busConductorName)}>
				<SlideFade in={Boolean(formik.values.busConductorName)} unmountOnExit>
					<FormLabel htmlFor="busConductorName" fontSize="sm">
						Bus Conductor Name
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Bus Conductor Name"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("busConductorName")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.busConductorName}</Text>
				</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={Boolean(formik.errors.busConductorPhone && formik.touched.busConductorPhone)}>
				<SlideFade in={Boolean(formik.values.busConductorPhone)} unmountOnExit>
					<FormLabel htmlFor="busConductorPhone" fontSize="sm">
						Bus Conductor Phone
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Bus Conductor Phone"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("busConductorPhone")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.busConductorPhone}</Text>
				</FormErrorMessage>
			</FormControl>

			<Button
				type="submit"
				colorScheme="primary"
				size="md"
				rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} />}
				isLoading={fetching}
			>
				Save
			</Button>
		</VStack>
	)
}
