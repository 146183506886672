import { Button, FormControl, FormErrorIcon, FormErrorMessage, FormLabel, Icon, Input, SlideFade, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { AddWorkshopSessionMutationVariables, useAddWorkshopSessionMutation } from "../../graphql"

const validationSchema = Yup.object({
	title: Yup.string().required().label("Title"),
	description: Yup.string().required().label("Description"),
	benefit: Yup.string().required().label("Benefit"),
})

type NewWorkshopSessionFormProps = {
	workshopId: string
}

export const NewWorkshopSessionForm: React.FC<NewWorkshopSessionFormProps> = ({ workshopId }) => {
	const [{ fetching }, addSession] = useAddWorkshopSessionMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (
		{ title, description, benefit }: AddWorkshopSessionMutationVariables["input"],
		helpers: FormikHelpers<AddWorkshopSessionMutationVariables["input"]>
	) => {
		const { error, data } = await addSession({
			workshopId,
			input: { title, description, benefit },
		})

		if (error) {
			return toast({
				description: error.message,
				status: "error",
			})
		}

		if (data?.addWorkshopSession.errors) {
			return data?.addWorkshopSession.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		return navigate(`/workshops/${workshopId}/sessions/${data?.addWorkshopSession.session?._id}`, { replace: true })
	}

	const formik = useFormik<AddWorkshopSessionMutationVariables["input"]>({
		initialValues: { title: "", description: "", benefit: "" },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			<FormControl isInvalid={Boolean(formik.errors.title && formik.touched.title)}>
				<SlideFade in={Boolean(formik.values.title)} unmountOnExit>
					<FormLabel htmlFor="title" fontSize="sm">
						Title
					</FormLabel>
				</SlideFade>
				<Input
					placeholder="Title"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					autoComplete="off"
					{...formik.getFieldProps("title")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.title}</Text>
				</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={Boolean(formik.errors.description && formik.touched.description)}>
				<SlideFade in={Boolean(formik.values.description)} unmountOnExit>
					<FormLabel htmlFor="description" fontSize="sm">
						Description
					</FormLabel>
				</SlideFade>
				<Textarea
					placeholder="Description"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("description")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.description}</Text>
				</FormErrorMessage>
			</FormControl>
			<FormControl isInvalid={Boolean(formik.errors.benefit && formik.touched.benefit)}>
				<SlideFade in={Boolean(formik.values.benefit)} unmountOnExit>
					<FormLabel htmlFor="benefit" fontSize="sm">
						Benefit
					</FormLabel>
				</SlideFade>
				<Textarea
					placeholder="Benefit"
					border="none"
					_focus={{ border: "none" }}
					maxW="md"
					bg="white.500"
					rounded="xl"
					py="1"
					{...formik.getFieldProps("benefit")}
				/>
				<FormErrorMessage>
					<FormErrorIcon />
					<Text>{formik.errors.benefit}</Text>
				</FormErrorMessage>
			</FormControl>

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Add
			</Button>
		</VStack>
	)
}
