import { Button, Center, FormControl, FormErrorMessage, FormLabel, Heading, Icon, Select, SlideFade, Spinner, Text, useToast, VStack } from "@chakra-ui/react"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import * as Yup from "yup"
import { EditHouseOfStudentMutationVariables, ExtendedStudentFragment, useEditHouseOfStudentMutation, useHousesQuery } from "../../graphql"

const validationSchema = Yup.object({
	houseId: Yup.string().required().label("House"),
})

export type EditStudentHouseFormProps = {
	student: ExtendedStudentFragment
}

export const EditStudentHouseForm: React.FC<EditStudentHouseFormProps> = ({ student }) => {
	const [{ fetching }, editStudentHouse] = useEditHouseOfStudentMutation()

	const toast = useToast()

	const onSubmit = async (
		{ houseId }: Omit<EditHouseOfStudentMutationVariables, "studentId">,
		helpers: FormikHelpers<Omit<EditHouseOfStudentMutationVariables, "studentId">>
	) => {
		const { error, data } = await editStudentHouse({ studentId: student._id, houseId })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL ]", ""),
				status: "error",
			})
		}

		if (data?.editHouseOfStudent.errors) {
			return data?.editHouseOfStudent.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		if (!data?.editHouseOfStudent.student) {
			return toast({
				description: "Could not edit house of the student",
				status: "error",
			})
		}

		toast({ description: "Updated student's house", status: "success" })
	}

	const formik = useFormik<Omit<EditHouseOfStudentMutationVariables, "studentId">>({
		initialValues: { houseId: student.houseId || "" },
		onSubmit,
		validationSchema,
	})

	const [{ data: housesData, fetching: fetchingHouses, error: housesError }] = useHousesQuery()

	return (
		<form onSubmit={formik.handleSubmit}>
			<VStack w="full" align="stretch">
				<Heading fontSize="sm">Update House</Heading>
				{fetchingHouses ? (
					<Center w="full" py="1">
						<VStack>
							<Spinner size="sm" color="text.400" />
							<Text fontSize="xs" color="text.400">
								Loading houses
							</Text>
						</VStack>
					</Center>
				) : housesError ? (
					<Center w="full" py="1">
						<VStack>
							<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
							<Text fontSize="xs" color="text.400">
								{housesError.message.replace("[GraphQL] ", "")}
							</Text>
						</VStack>
					</Center>
				) : !housesData?.houses ? (
					<Center w="full" py="1">
						<VStack>
							<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
							<Text fontSize="xs" color="text.400">
								Could not load houses
							</Text>
						</VStack>
					</Center>
				) : (
					<FormControl isInvalid={Boolean(formik.errors.houseId && formik.touched.houseId)} isRequired>
						<SlideFade in={Boolean(formik.values.houseId)} unmountOnExit>
							<FormLabel htmlFor="houseId" fontSize="sm">
								House
							</FormLabel>
						</SlideFade>
						<Select
							w="full"
							bg="white.500"
							rounded="xl"
							border="none"
							_focus={{ border: "none" }}
							placeholder="Select House"
							{...formik.getFieldProps("houseId")}
						>
							{housesData.houses.map((house) => (
								<option key={house._id} value={house._id}>
									{house.name}
								</option>
							))}
						</Select>
						<FormErrorMessage>{formik.errors.houseId}</FormErrorMessage>
					</FormControl>
				)}

				<Button size="sm" type="submit" colorScheme="primary" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
					Update
				</Button>
			</VStack>
		</form>
	)
}
