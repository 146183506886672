import {
	Button,
	Center,
	Container,
	Flex,
	Heading,
	HStack,
	Icon,
	IconButton,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Select,
	Spinner,
	Stack,
	Tag,
	Text,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react"
import { faCalendar, faExclamationCircle, faFileCsv } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format, subDays } from "date-fns"
import React, { useEffect, useState } from "react"
import { DateRange, Range } from "react-date-range"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import { DashboardHeader, Nav, PaytankMerchantSelector, StudentsSelector } from "../../../components"
import { PaytankOrdersTable } from "../../../components/paytank/orders/PaytankOrdersTable"
import { GRADIENT } from "../../../constants"
import {
	ExtendedClassFragment,
	ClassSectionFragment,
	ExtendedPaytankOrderFragment,
	PaymentOrderStatus,
	PaytankOrderTypes,
	useClassesQuery,
	useGeneratePaytankOrdersReportMutation,
	usePaytankMerchantsWithoutPaginationQuery,
	useStudentsQuery,
} from "../../../graphql"
import { useDrawer } from "../../../hooks"
import { exportCSVData, parsePaytankOrdersReportAsCSV } from "../../../utils"
import { CUIAutoComplete } from "chakra-ui-autocomplete"

export const PaytankTransactionsReport: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [{ data: classesData, fetching: fetchingClasses, error: classesError }] = useClassesQuery()

	const [schoolClass, setSchoolClass] = useState<ExtendedClassFragment | undefined>()

	const [classSection, setClassSection] = useState<ClassSectionFragment>()

	useEffect(() => {
		if (classSection && !schoolClass?.sections.map((s) => s._id).includes(classSection._id)) {
			setClassSection(undefined)
		}
	}, [schoolClass])

	useEffect(() => {
		setSelectedStudentIds([])
	}, [schoolClass, classSection])

	const [{ data, fetching, error }] = useStudentsQuery({
		variables: { classId: schoolClass?._id, classSectionId: classSection?._id },
	})

	const [{ data: merchantsData, fetching: merchantsFetching, error: merchantsError }] = usePaytankMerchantsWithoutPaginationQuery()

	const [selectedStudentIds, setSelectedStudentIds] = useState<string[]>([])
	const [selectedMerchantIds, setSelectedMerchantIds] = useState<string[]>([])
	const [selectedTypes, setSelectedTypes] = useState<PaytankOrderTypes[]>([])
	const [selectedStatus, setSelectedStatus] = useState<PaymentOrderStatus[]>([])

	const [dateState, setDateState] = useState<Range>({
		startDate: subDays(new Date(), 10),
		endDate: new Date(),
		key: "selection",
	})

	const [{ data: reportData, fetching: reportFetching, error: reportError }, generateReport] = useGeneratePaytankOrdersReportMutation()

	const [isGenerated, setIsGenerated] = useState(false)
	const [showReport, setShowReport] = useState(false)

	const handleReportGeneration = async () => {
		setShowReport(true)
		await generateReport({
			input: {
				studentIds: selectedStudentIds,
				merchantIds: selectedMerchantIds,
				sinceDate: dateState.startDate,
				untilDate: dateState.endDate,
				PaymentOrderStatus: selectedStatus,
				paytankOrderTypes: selectedTypes,
			},
		})
		setIsGenerated(true)
	}

	useEffect(() => {
		setIsGenerated(false)
	}, [dateState, selectedStudentIds])

	const handleCSVExport = (data: ExtendedPaytankOrderFragment[]) => {
		const csv = parsePaytankOrdersReportAsCSV(data)

		exportCSVData(csv)
	}

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<VStack w="full" align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Paytank Transactions Report
							</Heading>
						</VStack>
						{fetchingClasses ? (
							<Center>
								<VStack w="full" color="blackAlpha.800">
									<Text>Loading classes</Text>
									<Spinner size="sm" />
								</VStack>
							</Center>
						) : classesError ? (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
									Failed loading classes, try reloading the page
								</Text>
							</Center>
						) : classesData?.classes.length ? (
							<>
								<Flex flexWrap="wrap">
									<Button
										m="1"
										size="sm"
										colorScheme="primary"
										isLoading={reportFetching}
										onClick={handleReportGeneration}
										disabled={showReport && isGenerated}
									>
										Generate
									</Button>
									<Button
										m="1"
										size="sm"
										colorScheme="primary"
										variant="outline"
										disabled={!(showReport && isGenerated)}
										rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faFileCsv} {...props} />} />}
										onClick={
											reportData?.generatePaytankOrdersReport ? () => handleCSVExport(reportData?.generatePaytankOrdersReport) : undefined
										}
									>
										Export
									</Button>
									<Button
										m="1"
										size="sm"
										colorScheme="primary"
										variant="link"
										onClick={() => setShowReport(false)}
										disabled={!isGenerated || !showReport}
									>
										Clear
									</Button>
								</Flex>
								<Flex flexWrap="wrap">
									<HStack spacing={0}>
										<Select
											bg="white.500"
											roundedStart="xl"
											roundedEnd={schoolClass && schoolClass.sections?.length ? "none" : "xl"}
											border="none"
											_focus={{ border: "none" }}
											w="full"
											maxW="xs"
											placeholder="All Classes"
											value={JSON.stringify(schoolClass)}
											onChange={(e) => {
												setSchoolClass(e.target.value ? JSON.parse(e.target.value) : undefined)
											}}
											disabled={showReport && isGenerated}
										>
											{classesData?.classes.map((c) => (
												<option key={c._id} value={JSON.stringify(c)}>
													{c.name}
												</option>
											))}
										</Select>
										{schoolClass && schoolClass.sections?.length && (
											<Select
												bg="white.500"
												roundedStart="none"
												roundedEnd="xl"
												border="none"
												_focus={{ border: "none" }}
												w="full"
												maxW="xs"
												placeholder="All Sections"
												value={JSON.stringify(classSection)}
												onChange={(e) => {
													setClassSection(e.target.value ? JSON.parse(e.target.value) : undefined)
												}}
												disabled={showReport && isGenerated}
											>
												{schoolClass.sections.map((s) => (
													<option key={s.name} value={JSON.stringify(s)}>
														{s.name}
													</option>
												))}
											</Select>
										)}
									</HStack>

									{!(showReport && isGenerated) ? (
										<Popover placement="auto-end">
											<PopoverTrigger>
												{dateState.startDate && dateState.endDate && (
													<Tag m="1" bg="white.500" px="4" py="2" rounded="xl" cursor="pointer">
														<Text fontSize="sm">
															{format(dateState.startDate, "dd/MM/yyyy")} - {format(dateState.endDate, "dd/MM/yyyy")}
														</Text>
														<IconButton aria-label="calendar-button" size="xs" variant="ghost" colorScheme="whiteAlpha">
															<Icon
																color="blackAlpha.600"
																_hover={{ color: "blackAlpha.700" }}
																as={(props: any) => <FontAwesomeIcon icon={faCalendar} {...props} />}
															/>
														</IconButton>
													</Tag>
												)}
											</PopoverTrigger>
											<PopoverContent w="full" _focus={{ shadow: "none" }}>
												<PopoverBody w="full" p="0">
													<DateRange
														editableDateInputs={true}
														onChange={(item) => setDateState(item.selection)}
														moveRangeOnFirstSelection={false}
														ranges={[dateState]}
														maxDate={new Date()}
													/>
												</PopoverBody>
											</PopoverContent>
										</Popover>
									) : (
										dateState.startDate &&
										dateState.endDate && (
											<Tag m="1" bg="white.500" px="4" py="2" rounded="xl" cursor="pointer" opacity="0.4">
												<Text fontSize="sm">
													{format(dateState.startDate, "dd/MM/yyyy")} - {format(dateState.endDate, "dd/MM/yyyy")}
												</Text>
												<IconButton aria-label="calendar-button" size="xs" variant="ghost" colorScheme="whiteAlpha">
													<Icon
														color="blackAlpha.600"
														_hover={{ color: "blackAlpha.700" }}
														as={(props: any) => <FontAwesomeIcon icon={faCalendar} {...props} />}
													/>
												</IconButton>
											</Tag>
										)
									)}
								</Flex>
							</>
						) : (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any classes.
								</Text>
							</Center>
						)}
						<HStack>
							<CUIAutoComplete
								label=""
								placeholder="Status"
								disableCreateItem
								hideToggleButton
								selectedItems={Object.values(PaymentOrderStatus)
									.map((status) => ({ label: status, value: status }))
									.filter((status) => selectedStatus.includes(status.value))}
								items={Object.values(PaymentOrderStatus).map((status) => ({
									label: status,
									value: status,
								}))}
								onSelectedItemsChange={(changes) => setSelectedStatus(changes.selectedItems?.map(({ value }) => value) || [])}
								inputStyleProps={{
									border: "none",
									_focus: { border: "none" },
									maxW: "md",
									bg: "white.500",
									rounded: "xl",
									py: "1",
								}}
								listStyleProps={{
									maxW: "md",
									bg: "whiteAlpha.400",
									backdropFilter: "blur(4px)",
								}}
							/>
							<CUIAutoComplete
								label=""
								placeholder="Type"
								disableCreateItem
								hideToggleButton
								selectedItems={Object.values(PaytankOrderTypes)
									.map((type) => ({ label: type, value: type }))
									.filter((type) => selectedTypes.includes(type.value))}
								items={Object.values(PaytankOrderTypes).map((type) => ({
									label: type,
									value: type,
								}))}
								onSelectedItemsChange={(changes) => setSelectedTypes(changes.selectedItems?.map(({ value }) => value) || [])}
								inputStyleProps={{
									border: "none",
									_focus: { border: "none" },
									maxW: "md",
									bg: "white.500",
									rounded: "xl",
									py: "1",
								}}
								listStyleProps={{
									maxW: "md",
									bg: "whiteAlpha.400",
									backdropFilter: "blur(4px)",
								}}
							/>
						</HStack>
					</VStack>
					<VStack w="full" align="center">
						{showReport && reportFetching ? (
							<VStack w="full" maxW="2xl">
								<Spinner />
								<Text textAlign="center">Generating report</Text>
							</VStack>
						) : showReport && reportError ? (
							<VStack w="full" maxW="2xl" color="red.800">
								<Icon as={(props: any) => <FontAwesomeIcon icon={faExclamationCircle} {...props} />} />
								<Text textAlign="center">{reportError?.message.replace("[GraphQL] ", "")}</Text>
							</VStack>
						) : showReport ? (
							reportData?.generatePaytankOrdersReport && reportData.generatePaytankOrdersReport.length ? (
								<PaytankOrdersTable
									paytankOrders={reportData.generatePaytankOrdersReport}
									currentPage={1}
									hasNextPage={false}
									hasPrevPage={false}
									// eslint-disable-next-line @typescript-eslint/no-empty-function
									nextPage={() => {}}
									// eslint-disable-next-line @typescript-eslint/no-empty-function
									prevPage={() => {}}
									totalPages={1}
								/>
							) : (
								<Center>
									<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
										No data to show
									</Text>
								</Center>
							)
						) : (
							<Stack w="full" direction={{ base: "column", lg: "row" }} maxH="2xl" overflowY="hidden">
								<VStack flex="1" align="stretch" overflowY="auto">
									{fetching ? (
										<Center py="4">
											<Spinner color="text.400" />
										</Center>
									) : error ? (
										<Center>
											<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
												Failed loading students, try reloading the page
											</Text>
										</Center>
									) : data?.students && data?.students.length > 0 ? (
										<StudentsSelector
											students={data?.students}
											selectStudentIds={selectedStudentIds}
											onChange={setSelectedStudentIds}
											hideTimestamps
										/>
									) : (
										<Center py="4">
											<Text fontSize="md" fontWeight="semibold" color="text.400">
												Couldn&apos;t find any students.
											</Text>
										</Center>
									)}
								</VStack>
								<VStack flex="1" align="stretch" overflowY="auto">
									{merchantsFetching ? (
										<Center py="4">
											<Spinner color="text.400" />
										</Center>
									) : merchantsError ? (
										<Center>
											<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
												{merchantsError.message}
											</Text>
										</Center>
									) : merchantsData?.paytankMerchantsWithoutPagination && merchantsData?.paytankMerchantsWithoutPagination.length > 0 ? (
										<PaytankMerchantSelector
											merchants={merchantsData?.paytankMerchantsWithoutPagination}
											selectedMerchantIds={selectedMerchantIds}
											onChange={setSelectedMerchantIds}
										/>
									) : (
										<Center py="4">
											<Text fontSize="md" fontWeight="semibold" color="text.400">
												Couldn&apos;t find any merchants.
											</Text>
										</Center>
									)}
								</VStack>
							</Stack>
						)}
					</VStack>
				</VStack>
			</HStack>
		</Container>
	)
}
