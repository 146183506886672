import { Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useNavigate } from "react-router-dom"
import { Gallery, useDeleteGalleryMutation } from "../../graphql"
import { ConfirmationAlertDialog } from "../common"

export type DeleteGalleryButtonProps = {
	gallery: Gallery
}

export const DeleteGalleryButton: React.FC<DeleteGalleryButtonProps> = ({ gallery }) => {
	const [{ fetching }, deleteGallery] = useDeleteGalleryMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleDeleteBook = async () => {
		const { error } = await deleteGallery({ galleryId: gallery._id })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		return navigate("/gallery", { replace: true })
	}

	const { isOpen, onClose, getButtonProps } = useDisclosure()

	return (
		<>
			<Tooltip label={`Delete gallery ${gallery.title}`} fontSize="sm">
				<IconButton aria-label="delete-book-btn" variant="ghost" colorScheme="blackAlpha" size="sm" {...getButtonProps()}>
					<Icon as={(props: any) => <FontAwesomeIcon icon={faTrash} {...props} />} />
				</IconButton>
			</Tooltip>
			<ConfirmationAlertDialog
				isOpen={isOpen}
				onClose={onClose}
				title="Delete Gallery"
				message=""
				confirmLabel="Delete"
				onConfirm={handleDeleteBook}
				isLoading={fetching}
			/>
		</>
	)
}
