import Icon from "@chakra-ui/icon"
import { HStack, useBreakpointValue } from "@chakra-ui/react"
import { faChalkboardTeacher, faChartColumn, faClipboardList, faGrip, faUsers } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { BottomNavigationTab } from "./BottomNavigationTab"

export const BottomNavigationTabs: React.FC = () => {
	const isMobile = useBreakpointValue({ base: true, sm: false })

	if (!isMobile) return <></>

	return (
		<HStack pos="fixed" bottom={0} left={0} right={0} bg="white" zIndex="sticky" spacing="0.5" boxShadow="dark-lg">
			<BottomNavigationTab to="/attendance">
				<Icon as={(props: any) => <FontAwesomeIcon icon={faClipboardList} {...props} />} fontSize={20} />
			</BottomNavigationTab>

			<BottomNavigationTab to="/feed">
				<Icon as={(props: any) => <FontAwesomeIcon icon={faGrip} {...props} />} fontSize={20} />
			</BottomNavigationTab>

			<BottomNavigationTab to="/students">
				<Icon as={(props: any) => <FontAwesomeIcon icon={faUsers} {...props} />} fontSize={20} />
			</BottomNavigationTab>

			<BottomNavigationTab to="/staff">
				<Icon as={(props: any) => <FontAwesomeIcon icon={faChalkboardTeacher} {...props} />} fontSize={20} />
			</BottomNavigationTab>

			<BottomNavigationTab to="/reports">
				<Icon as={(props: any) => <FontAwesomeIcon icon={faChartColumn} {...props} />} fontSize={20} />
			</BottomNavigationTab>
		</HStack>
	)
}
