import { Img, ImgProps } from "@chakra-ui/react"
import React, { useState } from "react"

export type ImageProps = ImgProps

export const Image: React.FC<ImageProps> = (props) => {
	const [failed, setFailed] = useState(false)

	if (failed) return <></>

	return <Img {...props} onError={() => setFailed(true)} />
}
