import {
	Box,
	Center,
	Flex,
	Heading,
	Icon,
	IconButton,
	LinkBox,
	LinkOverlay,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	VStack,
} from "@chakra-ui/react"
import { faAdd, faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { NavLink } from "react-router-dom"
import { PointsRedeemItemFragment } from "../../graphql"

export type PointsRedeemItemsGridProps = {
	pointsRedeemItems: PointsRedeemItemFragment[]
	prevPage: () => void
	nextPage: () => void
	hasPrevPage: boolean
	hasNextPage: boolean
	currentPage: number
	totalPages: number
	createNewLink?: string
}

export const PointsRedeemItemsGrid: React.FC<PointsRedeemItemsGridProps> = ({ pointsRedeemItems, nextPage, hasNextPage, createNewLink }) => {
	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport && hasNextPage) nextPage()
	}, [inViewport, nextPage, hasNextPage])

	return (
		<Flex flexWrap="wrap" overflowX="hidden">
			{pointsRedeemItems.map((pointsRedeemItem) => (
				<Box key={pointsRedeemItem._id} position="relative" m={1} bg="white.500" rounded="2xl" px="8" py="6" cursor="pointer">
					<Popover>
						<PopoverTrigger>
							<IconButton
								pos="absolute"
								top="2"
								right="2"
								aria-label="description"
								size="2xs"
								color="blackAlpha.700"
								as={(props: any) => <FontAwesomeIcon icon={faCircleInfo} {...props} />}
							/>
						</PopoverTrigger>
						<PopoverContent bgColor="whiteAlpha.900">
							<PopoverBody>
								<Text textAlign="center" fontSize="sm">
									{pointsRedeemItem.description}
								</Text>
							</PopoverBody>
						</PopoverContent>
					</Popover>
					<LinkBox>
						<LinkOverlay as={NavLink} to={`/points/redeem/items/${pointsRedeemItem._id}/edit`} />
						<VStack spacing={0}>
							<Text fontSize="2xl" color="purple.500" fontWeight="bold">
								{pointsRedeemItem.points}
							</Text>
							<Heading fontSize="sm" color="blackAlpha.700">
								{pointsRedeemItem.name}
							</Heading>
						</VStack>
					</LinkBox>
				</Box>
			))}

			{createNewLink && (
				<Center as={LinkBox} position="relative" m={1} bg="primary.100" rounded="2xl" px="8" py="6" w="48" cursor="pointer">
					<LinkOverlay as={NavLink} to={createNewLink} />
					<VStack spacing={0}>
						<Icon fontSize="xl" color="primary.500" as={(props: any) => <FontAwesomeIcon icon={faAdd} {...props} />} />{" "}
						<Heading fontSize="sm" color="primary.500">
							Add New Item
						</Heading>
					</VStack>
				</Center>
			)}

			{hasNextPage && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
		</Flex>
	)
}
