import { Button, Center, FormControl, FormErrorMessage, FormLabel, Icon, Select, SlideFade, Spinner, Text, useToast, VStack } from "@chakra-ui/react"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { AssignSchoolTimingsToClassSectionMutationVariables, useAssignSchoolTimingsToClassSectionMutation, useSchoolTimingsQuery } from "../../graphql"

const validationSchema = Yup.object({
	schoolTimingId: Yup.string().required().label("School Timing"),
})

export type AddSchoolTimingToClassSectionFormProps = {
	classId: string
	classSectionId: string
}

export const AddSchoolTimingToClassSectionForm: React.FC<AddSchoolTimingToClassSectionFormProps> = ({ classId, classSectionId }) => {
	const [{ fetching }, addSchoolTiming] = useAssignSchoolTimingsToClassSectionMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async ({ schoolTimingId }: Omit<AssignSchoolTimingsToClassSectionMutationVariables, "classSectionId">) => {
		const { error } = await addSchoolTiming({ classSectionId, schoolTimingId })

		if (error) {
			return toast({
				title: "Class Error",
				description: error.message,
				status: "error",
			})
		}

		return navigate(`/classes/${classId}`, { replace: true })
	}

	const formik = useFormik<Omit<AssignSchoolTimingsToClassSectionMutationVariables, "classSectionId">>({
		initialValues: { schoolTimingId: "" },
		validationSchema,
		onSubmit: handleSubmit,
	})

	const [{ data: schoolTimingsData, fetching: fetchingschoolTimings, error: schoolTimingsError }] = useSchoolTimingsQuery()

	return (
		<VStack w="full" as="form" onSubmit={formik.handleSubmit as any} align="flex-start" spacing="4">
			{fetchingschoolTimings ? (
				<Center w="full" py="1">
					<VStack>
						<Spinner size="sm" color="text.400" />
						<Text fontSize="xs" color="text.400">
							Loading school timings
						</Text>
					</VStack>
				</Center>
			) : schoolTimingsError ? (
				<Center w="full" py="1">
					<VStack>
						<Icon as={(props: any) => <FontAwesomeIcon icon={faCircleExclamation} {...props} />} />
						<Text fontSize="xs" color="text.400">
							{schoolTimingsError.message.replace("[GraphQL] ", "")}
						</Text>
					</VStack>
				</Center>
			) : (
				<FormControl isInvalid={Boolean(formik.errors.schoolTimingId && formik.touched.schoolTimingId)}>
					<SlideFade in={Boolean(formik.values.schoolTimingId)} unmountOnExit>
						<FormLabel htmlFor="schoolTimingId" fontSize="sm">
							School Timing
						</FormLabel>
					</SlideFade>
					<Select
						placeholder="Select school timing"
						border="none"
						_focus={{
							border: "none",
						}}
						maxW="md"
						bg="white.500"
						rounded="xl"
						py="1"
						autoComplete="off"
						{...formik.getFieldProps("schoolTimingId")}
					>
						{schoolTimingsData?.schoolTimings.map(({ _id, name, attendanceTiming }) => (
							<option key={_id} value={_id}>
								{name} ({attendanceTiming.inAt}-{attendanceTiming.outAt})
							</option>
						))}
					</Select>
					<FormErrorMessage>{formik.errors.schoolTimingId}</FormErrorMessage>
				</FormControl>
			)}

			<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
				Add
			</Button>
		</VStack>
	)
}
