import { Center, Heading, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { SearchRestrictedZonesQueryVariables, useSearchRestrictedZonesQuery } from "../../graphql"
import { RestrictedZonesTable } from "./RestrictedZonesTable"

export type SearchResultRestrictedZonesProps = {
	searchKeyword: string
}

export const SearchResultRestrictedZones: React.FC<SearchResultRestrictedZonesProps> = ({ searchKeyword }) => {
	const [pagination, setPagination] = useState<SearchRestrictedZonesQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useSearchRestrictedZonesQuery({
		variables: { keyword: searchKeyword, pagination },
	})

	const prevPage = () => {
		if (data?.searchRestrictedZones.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.searchRestrictedZones.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize={isMobile ? "sm" : "md"} fontWeight="normal" color="text.500">
				Showing classes for search keyword <strong>&quot;{searchKeyword}&quot;</strong>
			</Heading>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.searchRestrictedZones.restrictedZones && data?.searchRestrictedZones.restrictedZones.length > 0 ? (
				<RestrictedZonesTable
					restrictedZones={data.searchRestrictedZones.restrictedZones}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.searchRestrictedZones.hasPrevPage}
					hasNextPage={data.searchRestrictedZones.hasNextPage}
					currentPage={data.searchRestrictedZones.currentPage}
					totalPages={data.searchRestrictedZones.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any restricted zones.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
