/* eslint-disable @typescript-eslint/indent */
import { Badge, Box, ButtonGroup, HStack, Icon, IconButton, Link, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { faArrowLeft, faArrowRight, faFileDownload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { CafeteriaPaymentsReportFragment, ReportStatus } from "../../../../graphql"
import { useNavigate } from "react-router-dom"

export type CafeteriaPaymentsReportsTableProps = {
	cafeteriaPaymentsReports: CafeteriaPaymentsReportFragment[]
	prevPage: () => void
	nextPage: () => void
	hasPrevPage: boolean
	hasNextPage: boolean
	currentPage: number
	totalPages: number
}

export const CafeteriaPaymentsReportsTable: React.FC<CafeteriaPaymentsReportsTableProps> = ({
	cafeteriaPaymentsReports,
	prevPage,
	nextPage,
	hasPrevPage,
	hasNextPage,
	currentPage,
	totalPages,
}) => {
	const navigate = useNavigate()

	return (
		<VStack w="full" spacing="0">
			<HStack alignSelf="flex-end">
				<Text fontSize="xs" color="text.400">
					{currentPage}/{totalPages}
				</Text>
				<ButtonGroup size="xs" p="4">
					<IconButton aria-label="prev-btn" onClick={prevPage} isDisabled={!hasPrevPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowLeft} {...props} />} color="text.500" />
					</IconButton>
					<IconButton aria-label="next-btn" onClick={nextPage} isDisabled={!hasNextPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} color="text.500" />
					</IconButton>
				</ButtonGroup>
			</HStack>
			<Box w="full" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th textTransform="capitalize">Name</Th>
							<Th textTransform="capitalize">Status</Th>
							<Th textTransform="capitalize">Created</Th>
							<Th textTransform="capitalize">Download</Th>
						</Tr>
					</Thead>
					<Tbody>
						{cafeteriaPaymentsReports.map((report) => (
							<Tr key={report._id} cursor="pointer" fontSize="sm" onClick={() => navigate(`/cafeteria/reports/transactions/${report._id}`)}>
								<Td py="0">{report.name}</Td>
								<Td py="0">
									<Badge
										colorScheme={
											report.status === ReportStatus.Generated ? "green" : report.status === ReportStatus.Failed ? "red" : "yellow"
										}
									>
										{report.status}
									</Badge>
								</Td>
								<Td>{format(new Date(report.createdAt), "PPp")}</Td>
								<Td>
									{report.csv?.url && (
										<IconButton
											aria-label="report-download-btn"
											as={Link}
											href={report.csv?.url}
											icon={<Icon as={(props: any) => <FontAwesomeIcon icon={faFileDownload} {...props} />} />}
										/>
									)}
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
		</VStack>
	)
}
